import React, { ReactNode, useEffect } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import { Header } from 'components/ui/general';
import { GdprConsent } from 'components/ui/modals';
import { useTabAccess } from 'hooks';
import { getLoggedIn } from 'redux/auth';
import { menuOpen, menuOverlay } from 'redux/menu';
import { WebshopHeader } from 'routes/Webshop/components';

import styles from './Layout.module.scss';

interface LayoutProps {
  children: ReactNode;
  className?: string;
}

const Layout = ({ children, className }: LayoutProps) => {
  useTabAccess();
  const match = useRouteMatch('/webshop');
  const isOpen = useSelector(menuOpen);
  const isOverlay = useSelector(menuOverlay);
  const isLoggedIn = useSelector(getLoggedIn);

  useEffect(() => {
    if (isOverlay !== undefined || isOpen === true) {
      document.body.classList.add('menuInstant');
    }
    requestAnimationFrame(() => {
      document.body.classList.remove('menuInstant');
    });
  }, [isOverlay, isOpen]);

  return (
    <div
      className={classNames(
        styles.root,
        {
          menuOpen: isOpen && isLoggedIn,
          menuOverlay: isOverlay
        },
        className
      )}
    >
      {match ? <WebshopHeader /> : <Header />}
      <section className={styles.content}>{children}</section>
      <GdprConsent />
    </div>
  );
};

export default Layout;
