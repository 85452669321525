import React, { FC, useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import Lightbox from 'react-image-lightbox';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';

import noImage from 'assets/images/notFound.png';
import { Text } from 'components/ui/forms';
import { Button } from 'components/ui/general';
import InStockMarker from 'components/ui/Webshop/InStockMarker';
import { WebshopProductSelectors } from 'consts/cypress';
import { usePrevious } from 'hooks';
import { addItem, getSpecificItemCount, getVat, removeItem } from 'redux/cart';
import {
  WebshopProductAmount,
  WebshopProductInfo
} from 'routes/Webshop/components';
import { WebshopUrlProps } from 'routes/Webshop/Webshop';
import { Equipment, StockVehicle } from 'types';
import { toSEK, preventScroll, productResolvers } from 'utils';
import { isStockVehicle } from 'utils/typeGuards';

import styles from './WebshopProduct.module.scss';

interface WebshopProductProps {
  product: StockVehicle | Equipment;
}

const WebshopProduct: FC<WebshopProductProps> = ({ product }) => {
  const {
    params: { id }
  }: WebshopUrlProps = useRouteMatch();
  const vat = useSelector(getVat);
  const dispatch = useDispatch();
  const quantity = useSelector(getSpecificItemCount({ article: product }));
  const [amount, setAmount] = useState<number | null>(null);
  const [lightboxIsOpen, setLightboxIsOpen] = useState<boolean>(false);
  const [updatedAmount, setUpdatedAmount] = useState<number>();
  const previousAmount = usePrevious(amount as number);

  useEffect(() => {
    if (amount) {
      if (amount !== previousAmount && amount !== quantity) {
        setUpdatedAmount(amount);
      }
    }
  }, [amount, previousAmount, quantity]);

  useEffect(() => {
    if (product.id === id) {
      setAmount(quantity === 0 ? null : quantity);
    }
  }, [id, product.id, quantity]);

  useEffect(() => {
    if (typeof amount === 'number') {
      if (amount) {
        dispatch(addItem({ sp: product, quantity: amount }));
      }
      if (!amount) {
        dispatch(removeItem({ article: product, quantity: 0 }));
      }
    }
  }, [amount, dispatch, product]);

  useEffect(() => {
    preventScroll(lightboxIsOpen);
  }, [lightboxIsOpen]);

  const amountOnChange = useCallback(({ target: { value } }) => {
    // Can't order negative values
    if (value <= 0) {
      setAmount(0);
      return;
    }

    // Can't order half products
    setAmount(Math.round(value));
  }, []);

  const renderThumbnail = useCallback(() => {
    const { thumbnail } = product;

    if (thumbnail) {
      const { path, name } = thumbnail;

      return (
        <>
          <button
            type="button"
            className={styles.thumbnailButton}
            onClick={() => setLightboxIsOpen(true)}
          >
            <img className={styles.thumbnailImage} src={path} alt={name} />
          </button>
          {lightboxIsOpen && (
            <Lightbox
              closeLabel="Stäng"
              mainSrc={path}
              onCloseRequest={() => setLightboxIsOpen(false)}
            />
          )}
        </>
      );
    }

    return (
      <img className={styles.thumbnailImage} src={noImage} alt="No thumbnail" />
    );
  }, [lightboxIsOpen, product]);

  const renderPrice = useCallback(() => {
    const { price, discountedPrice } = product;
    const salePrice = discountedPrice || price;

    return (
      <>
        <div
          className={classNames(styles.price, {
            [styles.discount]: !!discountedPrice
          })}
        >
          <span className={styles.priceSale}>
            Rek Ca-pris inkl moms {toSEK(salePrice * (1 + vat))}
          </span>
          {!!discountedPrice && (
            <span className={styles.priceLineThrough}>
              Rek Ca-pris inkl moms {toSEK(price * (1 + vat))}
            </span>
          )}
        </div>
      </>
    );
  }, [product, vat]);

  const renderAddProduct = useCallback(() => {
    if (product.vehicleCampaign) {
      return (
        <div className={styles.vehicleCampaignText}>
          <span>
            Denna produkt är en fordonskampanj och måste bli köpt i samband med
            ett fordon
          </span>
        </div>
      );
    }
    if (!!amount && amount >= 1) {
      return (
        <div className={styles.amount}>
          <button
            data-cy={WebshopProductSelectors.DECREMENT_BUTTON}
            type="button"
            className={styles.amountDecrease}
            onClick={() => setAmount(amount - 1)}
          >
            <i className="material-icons-outlined">remove</i>
          </button>
          <Text
            dataCy={WebshopProductSelectors.PRODUCT_COUNT_INPUT}
            type="number"
            name="amount"
            min="0"
            step="1"
            value={amount}
            className={styles.amountInput}
            onChange={amountOnChange}
          />
          <button
            data-cy={WebshopProductSelectors.INCREMENT_BUTTON}
            type="button"
            className={styles.amountIncrease}
            onClick={() => setAmount(amount + 1)}
          >
            <i className="material-icons-outlined">add</i>
          </button>
          <WebshopProductAmount amount={updatedAmount} />
        </div>
      );
    }

    return (
      <div className={styles.button}>
        <Button
          dataCy={WebshopProductSelectors.ADD_ITEM_BUTTON}
          disabled={
            isStockVehicle(product) && productResolvers.outOfStock(product)
          }
          size="lg"
          fullWidth
          color="alpha"
          onClick={() => setAmount(1)}
        >
          Lägg till i varukorg
        </Button>
      </div>
    );
  }, [amount, amountOnChange, product, updatedAmount]);

  return (
    <>
      <div className={styles.name} data-cy={WebshopProductSelectors.ROOT}>
        <h1 className={styles.nameText}>{productResolvers.getName(product)}</h1>
        {!!product.discountedPrice && (
          <span className={styles.nameDiscount}>Kampanj</span>
        )}
      </div>
      <h2 className={styles.sequence}>
        {isStockVehicle(product)
          ? product.sageId
          : productResolvers.getSequence(product)}
      </h2>
      <div className="grid gutter-left-2xl">
        <div className="grid__item grid__item--width-6/12 gutter__item">
          <div className={styles.information}>
            <WebshopProductInfo article={product} />
            <div className={styles.informationLower}>
              {renderPrice()}
              <InStockMarker
                className={styles.stockText}
                product={product}
                approximateStockBalance
                showAsTooltip={false}
              />
              {renderAddProduct()}
            </div>
          </div>
        </div>
        <div className="grid__item grid__item--width-6/12 gutter__item">
          <div className={styles.thumbnail}>{renderThumbnail()}</div>
        </div>
      </div>
    </>
  );
};

export default WebshopProduct;
