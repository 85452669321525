import React, { useEffect, useCallback } from 'react';

import { useSelector } from 'react-redux';

import { Button } from 'components/ui/general';
import { getFlattenedItems } from 'redux/cart';
import { ItemsInput, useExportOrderMutation } from 'types';

import styles from './WebshopExportButton.module.scss';

const WebshopExportButton = () => {
  const pickedItems = useSelector(getFlattenedItems);

  const [exportOrder, { data }] = useExportOrderMutation();

  const exportData = async () => {
    const items = pickedItems.map(
      (item) =>
        ({
          itemId: item.item.id,
          quantity: item.quantity,
          remark: item.note
        } as ItemsInput)
    );
    await exportOrder({ variables: { items } });
  };

  const download = useCallback(() => {
    if (data?.exportOrder) {
      fetch(data?.exportOrder, {
        method: 'GET',
        headers: {}
      })
        .then((response) => {
          response.arrayBuffer().then(function (buffer) {
            const file = data?.exportOrder.replace(/^.*[\\/]/, '');
            const url = window.URL.createObjectURL(new Blob([buffer]));
            let link = document.getElementById('exceldl') as HTMLAnchorElement;
            if (!link) {
              link = document.createElement('a');
              link.id = 'exceldl';
              document.body.appendChild(link);
            }
            link.href = url;
            link.setAttribute('download', file);
            link.click();
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [data]);

  useEffect(() => {
    if (data?.exportOrder) {
      download();
    }
  }, [data, download]);

  return (
    <Button
      color="primary"
      className={styles.exportButton}
      onClick={() => {
        exportData();
      }}
    >
      Exportera
    </Button>
  );
};

export default WebshopExportButton;
