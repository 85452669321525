export const sortingOptions = {
  vehicles: [
    {
      value: 'createdAt-desc',
      label: 'Nyast'
    },
    {
      value: 'price-asc',
      label: 'Pris stigande'
    },
    {
      value: 'price-desc',
      label: 'Pris fallande'
    },
    {
      value: 'model.name-asc',
      label: 'Alfabetisk'
    },
    {
      value: 'vehicleType-asc',
      label: 'Fordonstyp'
    }
  ],
  accessories: [
    {
      value: 'created_at-desc',
      label: 'Nyast'
    },
    {
      value: 'price-asc',
      label: 'Pris stigande'
    },
    {
      value: 'price-desc',
      label: 'Pris fallande'
    },
    {
      value: 'name-asc',
      label: 'Alfabetisk'
    }
  ]
};
