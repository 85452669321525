import { InvoiceStatus } from '../types';

export const invoiceStatusToColor = (status: InvoiceStatus) => {
  switch (status) {
    case InvoiceStatus.Payed:
      return 'sigma';
    case InvoiceStatus.NotPayed:
      return 'tau';
    default:
      return 'omicron';
  }
};
