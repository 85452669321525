import React, { FC, useCallback, useState } from 'react';

import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';

import FileItem from './components/FileItem';
import { UploadModalType } from 'components/modals/UploadModal/UploadModal';
import { Button } from 'components/ui/general';
import { AcceptedMimeType, MAX_UPLOAD_FILE_SIZE } from 'consts/files';

import styles from './DropZone.module.scss';
import buttonStyles from 'components/ui/general/Button/Button.module.scss';

interface DropZoneFiles {
  closeModal: Function;
  refetch: Function;
  uploadType?: UploadModalType;
  submitHandler?: { handler: Function; loader: boolean };
  multiple?: boolean;
}

interface FileItem {
  file: File;
  name: string;
}

const DropZoneFiles: FC<DropZoneFiles> = ({
  closeModal,
  refetch,
  uploadType,
  submitHandler,
  multiple = true
}) => {
  const acceptedFilesMap: Record<UploadModalType, string> = {
    [UploadModalType.IMAGES]: 'image/jpeg, image/png',
    [UploadModalType.FILES]: Object.values(AcceptedMimeType).join(','),
    [UploadModalType.EXCEL]: '',
    [UploadModalType.EXPLODED_VIEW_IMAGES]: ''
  };

  const [fileItems, setFileItems] = useState<FileItem[]>([]);

  const isLoading = submitHandler?.loader;
  const onDrop = useCallback(
    (acceptedFiles) => {
      if (acceptedFiles.length < 1) {
        return;
      }

      if (!multiple) {
        return setFileItems([
          { file: acceptedFiles[0], name: acceptedFiles[0].name }
        ]);
      }
      setFileItems((previousFileItems) => {
        const dupesRemoved = acceptedFiles.filter(
          (file: File) =>
            !previousFileItems.find(
              (prevFile) => prevFile.file.name === file.name
            )
        );
        return [
          ...previousFileItems,
          ...dupesRemoved.map((file: File) => ({
            file,
            name: file.name
          }))
        ];
      });
    },
    [multiple, setFileItems]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    fileRejections,
    acceptedFiles
  } = useDropzone({
    maxSize: MAX_UPLOAD_FILE_SIZE,
    onDrop,
    accept: uploadType ? acceptedFilesMap[uploadType] : '',
    maxFiles: !multiple ? 1 : undefined,
    multiple
  });

  const isFileTooLarge =
    fileRejections.length > 0 &&
    fileRejections[0].file.size > MAX_UPLOAD_FILE_SIZE;

  const handleRemove = (file: File) => {
    setFileItems(fileItems.filter((fileItem) => fileItem.file !== file));
  };

  const handleChange = (file: File, name: string) => {
    const index = fileItems.findIndex((fileItem) => fileItem.file === file);
    setFileItems([
      ...fileItems.slice(0, index),
      { file, name: name.replace(/^\s+/g, '') },
      ...fileItems.slice(index + 1, fileItems.length)
    ]);
  };

  const handleSubmit = async () => {
    if (submitHandler) {
      await submitHandler.handler(fileItems);
      return refetch();
    }
  };

  const cancelUpload = () => {
    closeModal();
  };

  return (
    <div>
      <div
        className={classNames(styles.inputs, {
          [styles.uploading]: isLoading
        })}
      >
        <div
          {...getRootProps()}
          className={classNames(styles.dropzone, {
            [styles.active]: isDragActive
          })}
        >
          <div className={styles.dropzoneContent}>
            <i
              className={classNames(
                'material-icons-outlined',
                styles.dropzoneCloud
              )}
            >
              cloud_upload
            </i>
            <span className={styles.dropzoneText}>
              {multiple ? 'Dra och släpp filer här' : 'Dra och släpp fil här'}
            </span>
            <span className={styles.dropzoneOr}>eller</span>
            <span
              className={classNames(
                buttonStyles.root,
                buttonStyles.alphaColor,
                buttonStyles.mdSize,
                {
                  [buttonStyles.disabled]: isLoading
                }
              )}
            >
              {multiple ? 'Välj filer' : 'Välj fil'}
            </span>
          </div>
          <input {...getInputProps()} />
        </div>
      </div>
      <div className={classNames(styles.row, styles.fileItems)}>
        {fileItems.map(({ file, name }: FileItem) => (
          <FileItem
            key={file.name}
            file={file}
            name={name}
            onChange={handleChange}
            onRemove={() => handleRemove(file)}
          />
        ))}
      </div>
      <div className={styles.bottomRow}>
        {isFileTooLarge && (
          <span>Maximal filstorlek är {MAX_UPLOAD_FILE_SIZE / 1000000}MB</span>
        )}
        <div className={classNames(styles.row, styles.endRow)}>
          <Button
            className={styles.button}
            color="epsilon"
            size="md"
            onClick={cancelUpload}
          >
            Avbryt
          </Button>
          <Button
            disabled={acceptedFiles.length < 1}
            className={styles.button}
            onClick={handleSubmit}
            color="alpha"
            size="md"
            loading={isLoading}
          >
            Spara
          </Button>
        </div>
      </div>
    </div>
  );
};

export default DropZoneFiles;
