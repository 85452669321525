import React, { useCallback, ChangeEvent } from 'react';

import { sortBy } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';

import { Button, Spinner, Empty } from 'components/ui/general';
import { impersonatedRetailerId } from 'redux/auth';
import {
  selectFilter,
  toggleFilter,
  clearFilter,
  setupFilter,
  updateFilterCategory,
  FilterCategory,
  WebshopFilterChoice
} from 'redux/webshopFilter';
import type { StoredFilters } from 'redux/webshopFilter';
import { WebshopFilterCategory } from 'routes/Webshop/components';
import { WebshopSectionType } from 'routes/Webshop/sections';
import { useGetWebshopFiltersQuery, GetWebshopFiltersQuery } from 'types';
import { buildQueryFilter, vehicleTypeToText } from 'utils';

import styles from './WebshopFilter.module.scss';

const sortFilter = (category: WebshopFilterChoice[], translator?: Function) => {
  return sortBy(category, ({ value }) =>
    translator
      ? translator(value || '').toLowerCase()
      : (value || '').toLowerCase()
  );
};

interface WebshopFilterProps {
  section: WebshopSectionType.VEHICLES | WebshopSectionType.ACCESSORIES;
}

const WebshopFilter = ({ section }: WebshopFilterProps) => {
  const dispatch = useDispatch();
  const allFilters = useSelector(selectFilter);
  const storedFilter = allFilters[section];

  const selectedBrands = storedFilter
    ? buildQueryFilter(storedFilter.brand)
    : [];
  const selectedModelSeries = storedFilter
    ? buildQueryFilter(storedFilter.modelSeries)
    : [];

  const actAsRetailerId = useSelector(impersonatedRetailerId);

  const updateStore = (queryData: GetWebshopFiltersQuery) => {
    if (!storedFilter) {
      dispatch(setupFilter(queryData));
      return;
    }
    const categories: (keyof StoredFilters)[] = [
      FilterCategory.BRAND,
      FilterCategory.MODEL,
      FilterCategory.MODELSERIES
    ];
    categories.forEach((category: keyof StoredFilters) => {
      dispatch(updateFilterCategory({ data: queryData, category, section }));
    });
  };

  const { loading, error } = useGetWebshopFiltersQuery({
    variables: {
      filter: {
        brand: selectedBrands,
        modelSeries: selectedModelSeries,
        actAsRetailerById: actAsRetailerId ?? null,
        forSale: true
      }
    },
    onCompleted: updateStore
  });

  const onChangeFilter = useCallback(
    ({ target }: ChangeEvent<HTMLInputElement>, type: FilterCategory) => {
      const { name, checked } = target;
      dispatch(toggleFilter({ type, name, checked, section }));
    },
    [dispatch, section]
  );

  const handleNoData = useCallback(() => {
    if (loading) {
      return (
        <div className={styles.spinnerHolder}>
          <Spinner visible />
        </div>
      );
    }
    if (error) {
      return (
        <div className="grid__item grid__item--width-12/12 gutter__item">
          <Empty
            icon="list_alt"
            title="Filter kunde inte laddas"
            message="Uppdatera sidan eller prova igen senare"
          />
        </div>
      );
    }

    return null;
  }, [loading, error]);

  return storedFilter ? (
    <>
      {section === WebshopSectionType.VEHICLES
        ? [
            <WebshopFilterCategory
              key={FilterCategory.VEHICLE}
              category={FilterCategory.VEHICLE}
              filterChoices={sortFilter(
                storedFilter?.vehicle,
                vehicleTypeToText
              )}
              onChange={onChangeFilter}
              openOnMount={storedFilter.brand.length > 1}
            />,
            <WebshopFilterCategory
              key={FilterCategory.BRAND}
              category={FilterCategory.BRAND}
              filterChoices={sortFilter(storedFilter?.brand)}
              onChange={onChangeFilter}
            />,
            // Hidden with DEALY-613
            // <WebshopFilterCategory
            //   key={FilterCategory.MODELSERIES}
            //   category={FilterCategory.MODELSERIES}
            //   openOnMount={false}
            //   filterChoices={sortFilter(storedFilter?.modelSeries)}
            //   onChange={onChangeFilter}
            // />,
            // <WebshopFilterCategory
            //   key={FilterCategory.MODEL}
            //   category={FilterCategory.MODEL}
            //   openOnMount={false}
            //   filterChoices={sortFilter(storedFilter?.model)}
            //   onChange={onChangeFilter}
            // />,
            <WebshopFilterCategory
              key={FilterCategory.COLOR}
              category={FilterCategory.COLOR}
              openOnMount={false}
              filterChoices={sortFilter(storedFilter?.color)}
              onChange={onChangeFilter}
            />,
            <WebshopFilterCategory
              category={FilterCategory.STOCK}
              filterChoices={storedFilter.stock}
              onChange={onChangeFilter}
            />
          ]
        : [
            <WebshopFilterCategory
              key={FilterCategory.BRAND}
              category={FilterCategory.BRAND}
              filterChoices={sortFilter(storedFilter?.brand)}
              onChange={onChangeFilter}
            />,
            <WebshopFilterCategory
              category={FilterCategory.EQUIPMENT_GROUP}
              filterChoices={storedFilter.equipmentGroup}
              onChange={onChangeFilter}
            />,
            <WebshopFilterCategory
              category={FilterCategory.STOCK}
              filterChoices={storedFilter.stock}
              onChange={onChangeFilter}
            />
          ]}

      <Button onClick={() => dispatch(clearFilter({ section }))}>
        Rensa filter
      </Button>
    </>
  ) : (
    handleNoData()
  );
};

export default WebshopFilter;
