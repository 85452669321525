import React, { ChangeEvent } from 'react';

import { Checkbox, CheckboxGroup } from 'components/ui/forms';
import { WebshopFilterChoice, FilterCategory } from 'redux/webshopFilter';
import WebshopExpandable from 'routes/Webshop/components';
import {
  vehicleTypeToText,
  stockStatusToText,
  filterCategoryToText
} from 'utils';

import styles from './WebshopFilterCategory.module.scss';

interface WebshopFilterCategoryProps {
  category: FilterCategory;
  filterChoices: Array<WebshopFilterChoice>;
  onChange: (
    { target }: ChangeEvent<HTMLInputElement>,
    type: FilterCategory
  ) => void;
  openOnMount?: boolean;
}

const WebshopFilterCategory: React.FC<WebshopFilterCategoryProps> = ({
  category,
  filterChoices,
  onChange,
  openOnMount = true
}) => {
  let translator:
    | typeof vehicleTypeToText
    | typeof stockStatusToText
    | undefined;
  switch (category) {
    case FilterCategory.VEHICLE:
      translator = vehicleTypeToText;
      break;
    case FilterCategory.STOCK:
      translator = stockStatusToText;
      break;
    default:
      break;
  }

  return (
    <div className={styles.filter}>
      <WebshopExpandable
        label={filterCategoryToText(category)}
        openOnMount={openOnMount}
      >
        <CheckboxGroup>
          {filterChoices.map(({ name, value, checked }) => (
            <Checkbox
              key={name}
              name={name}
              label={translator ? translator(value) : value}
              checked={checked}
              onChange={(event) => onChange(event, category)}
              doNotWrap
            />
          ))}
        </CheckboxGroup>
      </WebshopExpandable>
    </div>
  );
};

export default WebshopFilterCategory;
