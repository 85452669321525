import { ApolloError } from '@apollo/client';

import { UploadAttachementError } from 'types';

export const translateUploadAttachementError = (error: ApolloError) => {
  const errorVariant = error.graphQLErrors[0].extensions?.errorVariant;
  switch (errorVariant) {
    case UploadAttachementError.BadFileFormat:
      return 'Filformatet stöds inte';
    default:
      return errorVariant;
  }
};
