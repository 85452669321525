export const toSEK = (
  n: number | null | undefined,
  options?: Intl.NumberFormatOptions
) =>
  (n === undefined ? 0 : Number(n)).toLocaleString('sv-SE', {
    style: 'currency',
    currency: 'SEK',
    currencyDisplay: 'code',
    ...options
  });
