import { OrderStatus, OrderItemStatus } from 'types';

export const orderStatusToText = (status: OrderStatus | OrderItemStatus) => {
  switch (status) {
    case OrderStatus.Processing:
      return 'Registrerad';
    case OrderStatus.PartiallyAllocated:
      return 'Delvis plockad';
    case OrderStatus.Allocated:
      return 'Plockad';
    case OrderStatus.Blocked:
      return 'Stoppad';
    case OrderStatus.PartiallyDelivered:
      return 'Delvis skickad';
    case OrderStatus.Delivered:
      return 'Skickad';
    case OrderStatus.PartiallyInvoiced:
      return 'Delvis fakturerad';
    case OrderStatus.Invoiced:
      return 'Fakturerad';
    default:
      return 'Okänd';
  }
};

export const orderItemStatusToText = (status: OrderItemStatus) => {
  switch (status) {
    case OrderItemStatus.Processing:
      return 'Registrerad';
    case OrderItemStatus.Allocated:
      return 'Plockad';
    case OrderItemStatus.Blocked:
      return 'Stoppad';
    case OrderItemStatus.Delivered:
      return 'Skickad';
    case OrderItemStatus.Invoiced:
      return 'Fakturerad';
    default:
      return 'Okänd';
  }
};
