import {
  FilterCategory,
  StoredFilters,
  WebshopFilterChoice
} from 'redux/webshopFilter';
import { GetWebshopFiltersQuery, VehicleType, StockStatus } from 'types';

// TODO: This is costly and should be removed when it is no longer needed
const dontDisplay = ['MISSING_SAGE_TYPE', 'SPARE_PART', 'EQUIPMENT', 'ACTIVE'];

// To be used with filter categories that are fetched as arrays of strings
const stringParser = (filterData: Array<VehicleType | StockStatus>) => {
  return filterData
    ?.filter((filterItem) => !dontDisplay.includes(filterItem))
    .map((filterItem) => ({
      name: filterItem,
      value: filterItem,
      checked: false
    }));
};

// To be used with filter categories that are fetched as arrays of objects
const objectParser = (
  filterData: Array<object>,
  name: 'name' | 'description'
) => {
  return filterData.map((filterItem: any) => ({
    name: filterItem.id,
    value: filterItem[name],
    checked: false
  }));
};

/*
Takes in raw data from the webhopFilterQuery and translates it
to a structure that can be stored in the redux filter store
*/
export const filterParser = (
  queryData: GetWebshopFiltersQuery['getWebshopFilters']
): StoredFilters => {
  const filters: StoredFilters = {
    vehicle: stringParser(queryData.vehicleTypes),
    stock: stringParser(queryData.stockStatus),
    brand: objectParser(queryData.makes, 'name'),
    modelSeries: objectParser(queryData.modelSeries, 'name'),
    model: objectParser(queryData.models, 'description'),
    color: objectParser(queryData.colors, 'description'),
    equipmentGroup: objectParser(queryData.equipmentGroups, 'name')
  };
  return filters;
};

export const parserSwitch = (
  target: FilterCategory,
  data: GetWebshopFiltersQuery['getWebshopFilters']
) => {
  switch (target) {
    case FilterCategory.VEHICLE:
      return stringParser(data.vehicleTypes);
    case FilterCategory.BRAND:
      return objectParser(data.makes, 'name');
    case FilterCategory.MODEL:
      return objectParser(data.models, 'name');
    case FilterCategory.MODELSERIES:
      return objectParser(data.modelSeries, 'name');
    case FilterCategory.COLOR:
      return objectParser(data.colors, 'description');
    case FilterCategory.STOCK:
      return stringParser(data.stockStatus);
    case FilterCategory.EQUIPMENT_GROUP:
      return objectParser(data.equipmentGroups, 'name');
    default:
      return [];
  }
};

/*
Takes in a stored filter and returns an array of their names. Only returns options where
checked is stored as true unless "all" parameter is set to true.
*/
export const buildQueryFilter = (
  storedFilter: Array<WebshopFilterChoice>,
  all = false
): Array<string> => {
  if (all) {
    return storedFilter.map(({ name }) => name);
  }
  return storedFilter.filter(({ checked }) => checked).map(({ name }) => name);
};
