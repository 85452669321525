import React from 'react';

import { Collapsible } from 'components/ui/general';

import styles from './Comments.module.scss';

interface CommentsProps {
  workshopRemark?: string | null;
  customerRemark?: string | null;
  freeText?: string | null;
}

const Comments = ({
  workshopRemark = '',
  customerRemark = '',
  freeText = ''
}: CommentsProps) => {
  return (
    <Collapsible
      centerExpand
      expandTextClosed="Visa mer"
      expandTextOpen="Visa mindre"
      minHeight="60px"
      className={styles.collapsible}
      collapsed
      headerStyles={styles.collapsibleHeader}
      contentStyle={styles.contentStyle}
      tabStyle={styles.tabStyle}
    >
      <div className={styles.collapsibleHeader}>
        <div className={styles.bubble}>
          <p className={styles.bold}>Verkstadens anmärkning/kommentar</p>
          <p>{workshopRemark}</p>
        </div>
        <div className={styles.bubble}>
          <p className={styles.bold}>Kundens anmärkning</p>
          <p>{customerRemark}</p>
        </div>
        {freeText && (
          <div className={styles.bubble}>
            <p className={styles.bold}>Fritext</p>
            <p>{freeText}</p>
          </div>
        )}
      </div>
    </Collapsible>
  );
};

export default Comments;
