import React, {
  Children,
  createContext,
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState
} from 'react';

import classNames from 'classnames';

import Button from '../Button';
import { WizardSlide, WizardSlideProps } from './components/WizardSlide';
import { WizardTab, WizardTabProps } from './components/WizardTab';
import { useResize } from 'hooks';

import styles from './Wizard.module.scss';

interface WizardProps {
  readonly children?: ReactNode;
  initial?: number;
  title?: string;
  nav?: ReactNode;
  sticky?: boolean;
  clickable?: boolean;
  onClose?: () => void;
}

interface WizardComposition {
  Tab: FC<WizardTabProps>;
  Slide: FC<WizardSlideProps>;
}

interface WizardContextProps {
  activeSlide: string;
  activeIndex: number;
  previousIndex: number;
  setSlide: (id: string) => void;
  previousSlide: () => void;
  nextSlide: () => void;
}

const WizardContext = createContext<WizardContextProps | undefined>(undefined);

const Wizard: WizardComposition & FC<WizardProps> = ({
  children,
  initial = 0,
  title,
  nav,
  sticky = false,
  clickable = true,
  onClose,
  ...rest
}) => {
  const wizardChildren = Children.toArray(children) as ReactElement[];
  if (!wizardChildren.length) {
    throw new Error('Wizard requires atleast 1 slide');
  }

  if (initial < 0 || initial > wizardChildren.length - 1) {
    throw new Error(`Initial Slide index (${initial}) is out of scope`);
  }

  const progressRef = useRef<HTMLDivElement>(null);
  const tabsRef = useRef<HTMLDivElement>(null);
  const initialSlide = wizardChildren[initial].props.id;
  const [wizardState, setWizardState] = useState(() => {
    return {
      initialIndex: initial,
      activeSlide: initialSlide,
      animating: false,
      activeIndex: initial,
      previousIndex: initial
    };
  });

  const animateProgress = useCallback(() => {
    const el = document.getElementById(wizardState.activeSlide);
    if (el && progressRef.current) {
      const offset = el.parentElement?.getBoundingClientRect().left || 0;
      const { left, width } = el.getBoundingClientRect();
      const scale = (left + width - offset) / progressRef.current.offsetWidth;
      progressRef.current.style.transform = `scaleX(${scale})`;
    }
  }, [wizardState.activeSlide]);

  useResize(() => animateProgress());

  const setSlide = useCallback(
    (id: string) => {
      const index = wizardChildren.findIndex((child) => child.props.id === id);
      setWizardState({
        ...wizardState,
        previousIndex: wizardState.activeIndex,
        activeIndex: index,
        activeSlide: wizardChildren[index].props.id
      });
    },
    [wizardState, wizardChildren]
  );

  const previousSlide = useCallback(() => {
    const index = wizardChildren.findIndex(
      (child) => child.props.id === wizardState.activeSlide
    );
    if (index > 0) {
      setWizardState({
        ...wizardState,
        previousIndex: wizardState.activeIndex,
        activeIndex: index - 1,
        activeSlide: wizardChildren[index - 1].props.id
      });
    }
  }, [wizardState, wizardChildren]);

  const nextSlide = useCallback(() => {
    const index = wizardChildren.findIndex(
      (child) => child.props.id === wizardState.activeSlide
    );
    if (index < wizardChildren.length - 1) {
      setWizardState({
        ...wizardState,
        previousIndex: wizardState.activeIndex,
        activeIndex: index + 1,
        activeSlide: wizardChildren[index + 1].props.id
      });
    }
  }, [wizardState, wizardChildren]);

  const memoizedContext = useMemo(
    () => ({
      activeSlide: wizardState.activeSlide,
      activeIndex: wizardState.activeIndex,
      previousIndex: wizardState.previousIndex,
      setSlide,
      previousSlide,
      nextSlide
    }),
    [
      wizardState.activeSlide,
      wizardState.activeIndex,
      wizardState.previousIndex,
      setSlide,
      previousSlide,
      nextSlide
    ]
  );

  return (
    <WizardContext.Provider value={memoizedContext}>
      <div
        className={classNames(styles.root, {
          [styles.sticky]: sticky
        })}
        {...rest}
      >
        <div className={styles.header}>
          <div className={styles.wrapper}>
            <div className={styles.titleWrapper}>
              {onClose && (
                <Button
                  color="clear"
                  onClick={onClose}
                  className={styles.close}
                >
                  <i className="material-icons">close</i>
                </Button>
              )}
              {title && <h4 className={styles.title}>{title}</h4>}
            </div>
            <div ref={tabsRef} className={styles.tabs}>
              {Children.map(children, (child) => {
                if (!(child as ReactElement)?.props) {
                  return null;
                }
                const { id, label } = (child as ReactElement)?.props;

                return (
                  <WizardTab id={id} label={label} clickable={clickable} />
                );
              })}
            </div>
            <div className={styles.bar}>
              <div ref={progressRef} className={styles.progress} />
            </div>
            <div className={styles.nav}>{nav}</div>
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.slides}>
            {Children.map(children, (child, index) => {
              if (React.isValidElement(child)) {
                const extraProps = { index };
                return React.cloneElement(child, extraProps);
              }
              return child;
            })}
          </div>
        </div>
      </div>
    </WizardContext.Provider>
  );
};

export const useWizard = (): WizardContextProps => {
  const context = useContext(WizardContext);
  if (!context) {
    throw new Error('This component must be used within a <Wizard> component.');
  }
  return context;
};

Wizard.Tab = WizardTab;
Wizard.Slide = WizardSlide;

export default Wizard;
