import React, { FC, ReactNode } from 'react';

import classNames from 'classnames';

import { useWizard } from '../../Wizard';

import styles from '../../Wizard.module.scss';

export interface WizardSlideProps {
  readonly children?: ReactNode;
  id: string;
  index?: number;
  label: string;
}

export const WizardSlide: FC<WizardSlideProps> = ({
  children,
  id,
  index,
  ...rest
}) => {
  const { activeSlide, activeIndex, previousIndex } = useWizard();
  const isCurrent = activeSlide === id;
  const isPrevious = index === previousIndex && !isCurrent;

  const dir = () => {
    return previousIndex > activeIndex ? 'left' : 'right';
  };

  return (
    <div
      className={classNames(styles.slide, {
        [styles[`enter-${dir()}`]]: isCurrent && index !== previousIndex,
        [styles.active]: isCurrent || isPrevious,
        [styles[`exit-${dir()}`]]: isPrevious
      })}
      {...rest}
    >
      {children}
    </div>
  );
};
