import React, {
  Children,
  cloneElement,
  FC,
  isValidElement,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

import classNames from 'classnames';

import { Button } from '..';
import { useClickOutside } from 'hooks';

import styles from './Menu.module.scss';

type MenuOption = {
  action: any;
  label: string;
};

interface MenuProps {
  anchor?: 'left' | 'right';
  autoWidth?: boolean;
  readonly children: ReactNode;
  className?: string;
  label: ReactNode;
  labelColor?: string;
  optionsClass?: string;
}

const Menu: FC<MenuProps> = ({
  anchor = 'left',
  autoWidth,
  children,
  className,
  label,
  labelColor,
  optionsClass
}) => {
  const [open, setMenuState] = useState(false);
  const [rect, setRect] = useState<DOMRect>();
  const menuRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const onResize = useCallback(() => {
    setRect(menuRef?.current?.getBoundingClientRect());
  }, [menuRef]);

  const isInViewport = useCallback(() => {
    const bottom = menuRef?.current?.getBoundingClientRect().bottom || 9999;
    const menuItemsHeight =
      contentRef?.current?.getBoundingClientRect().height || 180;

    return (
      bottom + menuItemsHeight <=
      (window.innerHeight || document.documentElement.clientHeight)
    );
  }, [menuRef, contentRef]);

  useEffect(() => {
    setRect(menuRef?.current?.getBoundingClientRect());
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  useClickOutside(menuRef, () => {
    setMenuState(false);
  });

  const toggle = () => {
    if (!open) {
      setRect(menuRef?.current?.getBoundingClientRect());
    }
    setMenuState(!open);
  };

  return (
    <div
      ref={menuRef}
      className={classNames(
        styles.root,
        {
          [styles.open]: open
        },
        className
      )}
    >
      <Button color={labelColor || 'alpha'} onClick={toggle}>
        {label}
      </Button>
      {rect && (
        <div
          ref={contentRef}
          style={{
            top: isInViewport()
              ? (rect.bottom || 0) + 4
              : rect.top -
                (contentRef?.current?.getBoundingClientRect().height || 180),
            width: autoWidth ? 'auto' : rect.width,
            ...(anchor === 'left' && { left: rect.left }),
            ...(anchor === 'right' && {
              right: window.innerWidth - rect.right
            })
          }}
          className={classNames(styles.options, optionsClass, {
            [styles.hidden]: !open
          })}
        >
          {Children.map(children, (child) => {
            if (isValidElement(child)) {
              const extraProps = { menuOnClick: () => setMenuState(false) };
              return cloneElement(child, extraProps);
            }
            return child;
          })}
        </div>
      )}
    </div>
  );
};

export default Menu;
