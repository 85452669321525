import React, { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import WebshopCheckoutDisplay from './WebshopCheckoutDisplay';
import WebshopCheckoutEdit from './WebshopCheckoutEdit';
import { Error } from 'components/ui/forms';
import { Button } from 'components/ui/general';
import { Drawer } from 'components/ui/navigation';
import { OrderBox, WebshopExportButton } from 'components/ui/Webshop';
import { getActingAsRetailer, getUser } from 'redux/auth';
import {
  getCurrentNumberOfItems,
  getItems,
  getFlattenedItems,
  isVehicleInCart,
  clearCart,
  updateCart
} from 'redux/cart';
import {
  Crumb,
  WebshopBreadcrumb,
  WebshopFooter
} from 'routes/Webshop/components';
import {
  WebshopSectionType,
  webshopSectionTypeToText
} from 'routes/Webshop/sections/WebshopSectionType';
import {
  DeliveryType,
  ItemsInput,
  usePlaceOrderMutation,
  useUpdateCartWithPricesMutation,
  CartItem
} from 'types';

import styles from './WebshopCheckout.module.scss';

interface WebshopCheckoutProps {
  section: string;
}

export enum WebshopCheckoutSection {
  EDIT_SECTION = 'edit',
  DISPLAY_SECTION = 'display',
  CONFIRMATION_SECTION = 'confirm'
}

export enum PartialDelivery {
  PARTIAL_OK = 'Jag är okej med delleverans',
  FULL_ORDER_ONLY = 'Jag vill ha fullständig order'
}

const WebshopCheckout: FC<WebshopCheckoutProps> = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const numberOfArticles = useSelector(getCurrentNumberOfItems);
  const vehicleInCart = useSelector(isVehicleInCart);
  const actingAsRetailer = useSelector(getActingAsRetailer);
  const retailer = actingAsRetailer || user.retailer;
  const orderItems = useSelector(getItems);
  const flattenedOrderItems = useSelector(getFlattenedItems);

  const cartLabel = webshopSectionTypeToText(
    WebshopSectionType.CART.toString() as WebshopSectionType
  );
  const label = webshopSectionTypeToText(WebshopSectionType.CHECKOUT);
  const crumbs: Crumb[] = [
    { label: cartLabel, id: WebshopSectionType.CART.toString() },
    { label, id: WebshopSectionType.CHECKOUT }
  ];

  const { errors, watch, register, getValues, handleSubmit } = useForm({
    defaultValues: {
      retailerName: retailer?.name,
      retailerId: retailer?.externalId,
      address: retailer?.address || 'Address saknas',
      postalCode: retailer?.postalCode || 'Postnummer saknas',
      postalCity: retailer?.city || 'Stad saknas',
      email: user.email,
      phoneNumber: user.phoneNumber,
      partialDelivery: vehicleInCart
        ? PartialDelivery.PARTIAL_OK
        : PartialDelivery.FULL_ORDER_ONLY,
      orderRemark: '',
      dealyInternalComment: null,
      deliveryType: DeliveryType.Standard,
      externallyFinanced: false
    },
    shouldUnregister: false
  });

  const deliveryType = watch('deliveryType');

  const [checkoutSection, setCheckoutSection] = useState(
    WebshopCheckoutSection.EDIT_SECTION
  );
  const [placeOrder, { loading }] = usePlaceOrderMutation({
    onCompleted: (response) => {
      if (!response) {
        history.push({
          pathname: `/webshop/checkout_confirm/`,
          state: { error: true }
        });
      }

      if (response?.placeOrder) {
        dispatch(clearCart());
        history.push({
          pathname: `/webshop/checkout_confirm/`,
          state: { orders: response.placeOrder }
        });
      }
    },
    onError: () => {
      history.push({
        pathname: `/webshop/checkout_confirm/`,
        state: { error: true }
      });
    }
  });

  useEffect(() => {
    if (loading) {
      window.onbeforeunload = () => true;
      return () => {
        window.onbeforeunload = null;
      };
    }
  }, [loading]);

  const [
    updatePrice,
    {
      data: updatedPriceData,
      loading: updatedPriceLoading,
      error: updatePriceError
    }
  ] = useUpdateCartWithPricesMutation({
    onCompleted: (cartResponse) => {
      if (cartResponse?.updateCartWithPrices) {
        dispatch(updateCart(cartResponse));
      }
    }
  });

  useEffect(() => {
    const itemsToBeUpdated = orderItems.map((item) => {
      return {
        itemId: item.item.id.toString(),
        quantity: item.quantity,
        replacedProductId: item.replacedItem?.id,
        campaignOnVehicleId: item?.relatedVehicleId
      } as ItemsInput;
    });

    updatePrice({
      variables: {
        items: itemsToBeUpdated,
        deliveryType,
        onBehalfOfRetailerId: actingAsRetailer?.id
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryType]);

  const confirmOrder = async () => {
    const items = flattenedOrderItems.map((item) => ({
      itemId: item.item.id.toString(),
      quantity: item.quantity,
      remark: item.note || '',
      replacedProductId: item.replacedItem?.id,
      campaignOnVehicleId: item.relatedVehicleId
    }));

    const {
      address,
      postalCity,
      postalCode,
      partialDelivery,
      orderRemark,
      deliveryType: formDeliveryType,
      dealyInternalComment,
      externallyFinanced
    } = getValues();

    await placeOrder({
      variables: {
        input: {
          items,
          address,
          postalCity,
          postalCode,
          partialDelivery: partialDelivery === PartialDelivery.PARTIAL_OK,
          orderRemark,
          deliveryType: formDeliveryType,
          placeOrderOnBehalfOfRetailerId: actingAsRetailer
            ? actingAsRetailer.id
            : null,
          externallyFinanced,
          dealyInternalComment: dealyInternalComment || null
        }
      }
    });
  };

  const handleCheckoutClick = () => {
    switch (checkoutSection) {
      case WebshopCheckoutSection.EDIT_SECTION:
        return setCheckoutSection(WebshopCheckoutSection.DISPLAY_SECTION);
      case WebshopCheckoutSection.DISPLAY_SECTION:
        return confirmOrder();
      default:
    }
  };

  const selectCheckoutSection = (
    checkoutSectionParam: WebshopCheckoutSection
  ) => {
    switch (checkoutSectionParam) {
      case WebshopCheckoutSection.EDIT_SECTION:
        return (
          <WebshopCheckoutEdit
            vehicleInCart={vehicleInCart}
            register={register}
            errors={errors}
            watch={watch}
          />
        );
      case WebshopCheckoutSection.DISPLAY_SECTION:
        return <WebshopCheckoutDisplay getValues={getValues} />;
      default:
    }
  };

  const handleGoBack = () => {
    switch (checkoutSection) {
      case WebshopCheckoutSection.EDIT_SECTION:
        return history.push('/webshop/cart');
      case WebshopCheckoutSection.DISPLAY_SECTION:
        return setCheckoutSection(WebshopCheckoutSection.EDIT_SECTION);
      default:
    }
  };

  return (
    <>
      <Helmet>
        <title>{`Webshop: ${label}`}</title>
      </Helmet>
      <Drawer initial="closed" overlay />
      <section>
        <div className={styles.root}>
          <div className={styles.main}>
            <WebshopBreadcrumb crumbs={crumbs} />
            <div className={styles.flexContainer__space_between}>
              <div className={styles.flexContainer}>
                <Button className={styles.backButton} onClick={handleGoBack}>
                  <i className="material-icons">arrow_back_ios</i>
                </Button>
                <div className={styles.title}>
                  <h3>Checka ut</h3>
                </div>
              </div>
              <WebshopExportButton />
            </div>
            <hr className={styles.line} />
            <form
              onSubmit={handleSubmit(handleCheckoutClick)}
              className={styles.flexContainer}
            >
              <div
                className={classNames(
                  styles.container,
                  styles.container__article
                )}
              >
                <div>{selectCheckoutSection(checkoutSection)}</div>
              </div>
              <div
                className={classNames(
                  styles.container,
                  styles.container__order
                )}
              >
                <p className={styles.title__sub}>Order</p>
                <div>
                  <OrderBox
                    numberOfArticles={numberOfArticles}
                    fees={
                      (updatedPriceData?.updateCartWithPrices
                        .fees as CartItem[]) || []
                    }
                    checkoutSection={checkoutSection}
                    loading={loading || updatedPriceLoading}
                  />
                  <div className={styles.errors}>
                    <Error
                      error={updatePriceError}
                      message="Något blev fel när priset skulle uppdateras, var vänlig och prova igen"
                    />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <WebshopFooter />
    </>
  );
};

export default WebshopCheckout;
