import { InvoiceStatus } from '../types';

export const invoiceStatusToText = (status: InvoiceStatus) => {
  switch (status) {
    case InvoiceStatus.Payed:
      return 'Betalad';
    case InvoiceStatus.NotPayed:
      return 'Inte betalad';
    default:
      return 'Okänd';
  }
};
