import React, { FC } from 'react';

import { Button, Empty, Spinner } from 'components/ui/general';
import { DealyModal, DealyModalAnchor } from 'components/ui/modals';
import { BillOfMaterialRowCard } from 'components/ui/Webshop';
import { BillOfMaterial, BillOfMaterialRow } from 'types';

import styles from './BillOfMaterialRowModal.module.scss';

type BillOfMaterialRowModal = {
  closeModal: Function;
  modalIsOpen: boolean;
  billOfMaterial: BillOfMaterial;
  loading?: boolean;
};

const BillOfMaterialRowModal: FC<BillOfMaterialRowModal> = ({
  closeModal,
  modalIsOpen,
  billOfMaterial,
  loading
}) => {
  return (
    <DealyModal
      anchor={DealyModalAnchor.RIGHT}
      isOpen={modalIsOpen}
      onRequestClose={() => closeModal()}
    >
      <div className={styles.modalHeader}>
        <h4>Paketinformation</h4>
        <Button
          color="clear"
          className={styles.closeButton}
          onClick={() => {
            closeModal();
          }}
        >
          <i className="material-icons">close</i>
        </Button>
      </div>
      {billOfMaterial.bomRows.map((row: BillOfMaterialRow) => {
        return <BillOfMaterialRowCard BillOfMaterialRowParam={row} />;
      })}
      {loading && <Spinner visible={loading} className={styles.spinner} />}
      {!loading && !billOfMaterial?.bomRows.length && (
        <Empty icon="list_alt" message="Paketet innehåller inga föremål" />
      )}
    </DealyModal>
  );
};

export default BillOfMaterialRowModal;
