import React, { FC, ReactElement } from 'react';

import classNames from 'classnames';

import styles from './Empty.module.scss';

interface EmptyProps {
  absolute?: boolean;
  className?: string;
  icon?: string;
  title?: ReactElement | string;
  message?: ReactElement | string;
}

const Empty: FC<EmptyProps> = ({
  absolute,
  className,
  icon,
  title,
  message
}) => {
  return (
    <div
      className={classNames(
        styles.root,
        {
          [styles.absolute]: absolute
        },
        className
      )}
    >
      <div className={styles.content}>
        <div className={styles.glimmer}>
          {icon && <i className="material-icons">{icon}</i>}
        </div>
        {title && <h4 className={styles.title}>{title}</h4>}
        {message && <p className={styles.message}>{message}</p>}
      </div>
    </div>
  );
};

export default Empty;
