import React, {
  InputHTMLAttributes,
  ReactNode,
  useMemo,
  ChangeEvent
} from 'react';

import classNames from 'classnames';
import { ValidationRules, UseFormMethods, FieldErrors } from 'react-hook-form';

import { Error } from '../index';

import styles from './Text.module.scss';

interface TextProps extends InputHTMLAttributes<HTMLInputElement> {
  bordered?: boolean;
  prefixIcon?: ReactNode | string;
  prefixSize?: 'lg' | 'md' | 'sm';
  suffixIcon?: ReactNode | string;
  suffixSize?: 'lg' | 'md' | 'sm';
  label?: string;
  labelSupplement?: string;
  name?: string;
  register?: UseFormMethods['register'];
  validation?: ValidationRules;
  error?: FieldErrors;
  required?: boolean;
  disabled?: boolean;
  onChange?: ((event: ChangeEvent<HTMLInputElement>) => void) | undefined;
  dataCy?: string;
}

const Text = ({
  bordered = false,
  className,
  placeholder,
  dataCy = '',
  prefixIcon,
  prefixSize,
  suffixIcon,
  suffixSize,
  type = 'text',
  label,
  labelSupplement,
  name,
  defaultValue,
  value,
  register,
  error,
  disabled,
  validation = {},
  required,
  onChange,
  ...rest
}: TextProps) => {
  const isRequired = required || validation?.required;
  const input = useMemo(
    () => (
      <input
        type={type}
        data-cy={dataCy}
        ref={register ? register({ ...validation }) : undefined}
        autoComplete="off"
        disabled={disabled}
        placeholder={placeholder}
        aria-label={label}
        className={classNames(styles.root, {
          [styles.bordered]: bordered,
          [styles.error]: !!error
        })}
        onChange={onChange}
        defaultValue={defaultValue}
        name={name}
        value={value}
        {...rest}
      />
    ),
    [
      type,
      register,
      validation,
      disabled,
      placeholder,
      label,
      bordered,
      error,
      onChange,
      defaultValue,
      name,
      value,
      rest,
      dataCy
    ]
  );

  const withFix = () => {
    return (
      <div
        className={classNames(styles.wrapper, {
          [styles.withPrefix]: prefixIcon,
          [styles.txtPrefix]: typeof prefixIcon === 'string',
          [styles.withSuffix]: suffixIcon,
          [styles.txtSuffix]: typeof suffixIcon === 'string',
          [styles[`prefix-${prefixSize}`]]: prefixSize,
          [styles[`suffix-${suffixSize}`]]: suffixSize
        })}
      >
        {prefixIcon && <span className={styles.prefix}>{prefixIcon}</span>}
        {input}
        {suffixIcon && <span className={styles.suffix}>{suffixIcon}</span>}
      </div>
    );
  };

  return (
    <div className={classNames(styles.block, className)}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
          {labelSupplement && (
            <span className={styles.labelSupplement}>{labelSupplement}</span>
          )}
          {isRequired && <span className={styles.required}>*</span>}
        </label>
      )}
      {prefixIcon || suffixIcon ? withFix() : input}
      <Error error={error} message={error?.message} />
    </div>
  );
};

export default Text;
