import React, { FC } from 'react';

import {
  ChevronDuoLeftIcon,
  ChevronDuoRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from 'assets/icons';

import styles from '../table/components/TablePagination.module.scss';

interface paginationProps {
  canPreviousPage: boolean;
  canNextPage: boolean;
  totalNumberOfItems: number;
  pageCount: number;
  gotoPage: (index: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (value: number) => void;
  state: { pageIndex: number; pageSize: number };
  pageSizeSteps: number[];
}

const Pagination: FC<paginationProps> = ({
  canPreviousPage,
  canNextPage,
  totalNumberOfItems,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  state: { pageIndex, pageSize },
  pageSizeSteps
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.column}>
        <span>Visar</span>
        <select
          className={styles.select}
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {pageSizeSteps.map((size) => (
            <option key={pageSize + size} value={size}>
              {size}
            </option>
          ))}
        </select>
        <span>per sida</span>
      </div>
      <div className={styles.column}>
        <button
          onClick={() => gotoPage(0)}
          type="button"
          disabled={!canPreviousPage}
        >
          <ChevronDuoLeftIcon />
        </button>
        <button
          onClick={() => previousPage()}
          type="button"
          disabled={!canPreviousPage}
        >
          <ChevronLeftIcon />
        </button>
        <span className={styles.currentPage}>
          Sida {pageIndex + 1} av {Math.ceil(totalNumberOfItems / pageSize)}
        </span>
        <button
          onClick={() => nextPage()}
          type="button"
          disabled={!canNextPage}
        >
          <ChevronRightIcon />
        </button>
        <button
          onClick={() => gotoPage(pageCount - 1)}
          type="button"
          disabled={!canNextPage}
        >
          <ChevronDuoRightIcon />
        </button>
      </div>
    </div>
  );
};

export default Pagination;
