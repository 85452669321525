import { WarrantyStatus } from 'types';

export const warrantyStatusToColor = (status: WarrantyStatus) => {
  switch (status) {
    case WarrantyStatus.Draft:
      return 'omicron';
    case WarrantyStatus.Processing:
    case WarrantyStatus.Registered:
      return 'rho';
    case WarrantyStatus.Complement:
    case WarrantyStatus.Complemented:
      return 'nu';
    case WarrantyStatus.Credited:
    case WarrantyStatus.Approved:
      return 'sigma';
    case WarrantyStatus.Declined:
      return 'tau';
    case WarrantyStatus.LegacyWarranty:
      return 'epsilon';
    default:
      return 'eta';
  }
};
