import React, { useState, useRef, useEffect, useCallback } from 'react';

import { useResize } from 'hooks';

import styles from './Cells.module.scss';

interface ExpandableCellProps {
  text: string;
}

export const ExpandableCell = ({ text }: ExpandableCellProps) => {
  const [originalWidth, setOriginalWidth] = useState<Number>(0);
  const [isOverflowing, setIsOverflowing] = useState<boolean | undefined>();
  const [isOpen, setIsOpen] = useState(false);
  const textRef = useRef<HTMLParagraphElement | null>(null);

  const calculateOverflow = useCallback(() => {
    if (textRef?.current != null && !!originalWidth) {
      return (
        originalWidth >
        (textRef.current.parentNode as HTMLDivElement).clientWidth
      );
    }
  }, [originalWidth]);

  useEffect(() => {
    if (!originalWidth && textRef?.current != null) {
      setOriginalWidth(textRef.current.scrollWidth);
    }

    setIsOverflowing(calculateOverflow());
  }, [calculateOverflow, originalWidth, textRef]);

  useResize(() => setIsOverflowing(calculateOverflow()), 30);

  return (
    <div
      className={
        isOpen ? styles.expandableCellOpen : styles.expandableCellClosed
      }
    >
      <p ref={textRef}>{text}</p>
      {!!isOverflowing && (
        <div className={styles.expandableCellToggle}>
          <button type="button" onClick={() => setIsOpen(!isOpen)}>
            <i className="material-icons">
              {isOpen ? 'expand_less' : 'expand_more'}
            </i>
          </button>
        </div>
      )}
    </div>
  );
};
