import React, { useMemo } from 'react';

import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { Spinner } from 'components/ui/general';

import styles from './InfoHeader.module.scss';

interface HeaderItem {
  label: string;
  value: any;
  showWhenEmpty?: boolean;
}

interface InfoHeaderProps {
  headerItems: HeaderItem[];
  backButton?: boolean;
  loading?: boolean;
  className?: string;
}

const InfoHeader = ({
  headerItems,
  backButton = false,
  loading,
  className
}: InfoHeaderProps) => {
  const history = useHistory();

  const renderedHeaderItems = useMemo(() => {
    return headerItems.map(({ label, value, showWhenEmpty }) => {
      if (!value && !showWhenEmpty) return undefined;

      return (
        <div className={styles.headerItem} key={label + value}>
          <span className={styles.bold}>{label}</span>
          <span>{value}</span>
        </div>
      );
    });
  }, [headerItems]);

  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.spinnerWrapper}>
        <Spinner className={styles.spinner} visible={loading} />
      </div>
      <div>
        {backButton && (
          <button
            className={styles.backButton}
            type="button"
            onClick={() => {
              history.goBack();
            }}
          >
            <i className="material-icons">chevron_left</i>
            <span>Tillbaka</span>
          </button>
        )}
      </div>
      <div className={styles.headerItems}>{renderedHeaderItems}</div>
    </div>
  );
};

export default InfoHeader;
