import React, { useCallback, useState, useMemo } from 'react';

import { Spinner, Empty } from 'components/ui/general';
import Pagination from 'components/ui/pagination/Pagination';
import { WebshopCard } from 'routes/Webshop/components';
import {
  WebshopSectionType,
  webshopSectionTypeToIdentifier
} from 'routes/Webshop/sections';
import {
  Equipment,
  StockVehicle,
  useArticleSearchQuery,
  useStockVehiclesQuery,
  ArticleSearchQueryVariables,
  StockVehiclesQueryVariables
} from 'types';
import { productResolvers } from 'utils';

import styles from './WebshopList.module.scss';

const initialPageSize = 48;

interface WebshopListProps {
  section: WebshopSectionType.VEHICLES | WebshopSectionType.ACCESSORIES;
  filter?:
    | ArticleSearchQueryVariables['filter']
    | StockVehiclesQueryVariables['filter'];
  sorting: string;
  query: typeof useArticleSearchQuery | typeof useStockVehiclesQuery;
}

const WebshopList = ({ section, filter, sorting, query }: WebshopListProps) => {
  const sectionIdentifier = webshopSectionTypeToIdentifier(section);

  const [pageSize, setPageSize] = useState(initialPageSize);
  const [pageIndex, setPageIndex] = useState(0);
  const [totalNumberOfProducts, setTotalNumberOfProducts] = useState(0);

  const [field, direction] = sorting.split('-');

  const { loading, called, data, error } = query({
    variables: {
      filter: {
        ...filter,
        limit: pageSize,
        offset: pageIndex * pageSize
      },
      sorting: [{ field, direction }]
    },
    skip: !filter,
    onCompleted: (resp: any) => {
      setTotalNumberOfProducts(resp?.[sectionIdentifier]?.meta.total || 0);
    }
  });
  const edges = (data as any)?.[sectionIdentifier]?.edges;

  const pageCount = useMemo(() => {
    return Math.ceil(totalNumberOfProducts / pageSize);
  }, [pageSize, totalNumberOfProducts]);

  const renderProducts = useCallback(() => {
    if (loading) {
      return (
        <div className={styles.spinnerHolder}>
          <Spinner visible className={styles.spinner} />
        </div>
      );
    }

    if (!edges) return;

    if (error) {
      return (
        <div className="grid__item grid__item--width-12/12 gutter__item">
          <Empty
            icon="list_alt"
            title="Något gick fel"
            message="Prova att ladda om sidan eller försök senare"
          />
        </div>
      );
    }
    if (!edges.length && called) {
      return (
        <div className="grid__item grid__item--width-12/12 gutter__item">
          <Empty
            icon="list_alt"
            title="Inga resultat hittades"
            message="Prova att justera din filtrering för att hitta det du letar efter."
          />
        </div>
      );
    }

    return (edges as Array<Equipment | StockVehicle>).map(
      (product: StockVehicle | Equipment) => {
        return (
          <div
            key={product.id}
            className="grid__item grid__item--width-4/12 gutter__item"
          >
            <WebshopCard
              thumbnail={product.thumbnail?.path}
              name={productResolvers.getName(product)}
              sequence={productResolvers.getSequence(product)}
              {...{ product }}
            />
          </div>
        );
      }
    );
  }, [loading, called, edges, error]);
  return (
    <>
      <div className="grid gutter-left-2xl gutter-bottom-2xl">
        {renderProducts()}
      </div>
      {!!edges?.length && (
        <div className={styles.pagination}>
          <Pagination
            canPreviousPage={pageIndex !== 0}
            canNextPage={pageCount !== pageIndex + 1}
            totalNumberOfItems={totalNumberOfProducts}
            pageCount={pageCount}
            gotoPage={(index) => setPageIndex(index)}
            nextPage={() => setPageIndex(pageIndex + 1)}
            previousPage={() => setPageIndex(pageIndex - 1)}
            setPageSize={setPageSize}
            state={{ pageIndex, pageSize }}
            pageSizeSteps={[12, 24, 36, 48]}
          />
        </div>
      )}
    </>
  );
};

export default WebshopList;
