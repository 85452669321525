import { ApolloError } from '@apollo/client';

import { ShoppingListError } from 'types';

export const translateShoppingListError = (error: ApolloError) => {
  const errorVariant = error.graphQLErrors[0].extensions?.errorVariant;
  switch (errorVariant) {
    case ShoppingListError.BadAmount:
      return 'Kunde inte läsa ut antal på artikelsidan';
    case ShoppingListError.BadArticleNumber:
      return 'Kunde inte läsa ut artikelnummer på artikelsidan';
    case ShoppingListError.BadName:
      return 'Kunde inte läsa inköpslistans namn på försättsbladet';
    case ShoppingListError.NoArticleFound:
      return 'Kunde inte hitta artikel';
    default:
      return errorVariant;
  }
};
