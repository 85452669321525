import React from 'react';

import { Helmet } from 'react-helmet';

import OhNo from 'assets/images/oh-no.png';
import { Container } from 'components/ui/general';
import { Drawer } from 'components/ui/navigation';

import styles from './NotFound.module.scss';

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404</title>
      </Helmet>
      <Drawer initial="closed" overlay />
      <section>
        <Container>
          <div className={styles.block}>
            <div>
              <img src={OhNo} width={400} height={300} alt="oh-no 404" />
            </div>
            <h1>404</h1>
            <div className={styles.text}>
              <p>Sidan hittades inte</p>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default NotFound;
