import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { StrictMode } from 'react';

import { ApolloProvider } from '@apollo/client';
import * as Sentry from '@sentry/browser';
import { ExtraErrorData } from '@sentry/integrations';
import { BrowserTracing } from '@sentry/tracing';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { factory } from 'api/apollo';
import { App } from 'components/tools';
import { persistor, store } from 'redux/store';
import { isProduction } from 'utils';

import 'styles/_global.scss';
import 'react-image-lightbox/style.css';

if (isProduction && process.env.REACT_APP_SENTRY_DSN) {
  // Limit of how deep the object serializer should go. Anything deeper than limit will
  // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
  // a primitive value. Defaults to 3.
  const depth = 5;

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_SENTRY_RELEASE,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [new BrowserTracing(), new ExtraErrorData({ depth })],
    normalizeDepth: depth + 1,
    tracesSampleRate: 1.0
  });
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.ready.then((registration) => {
    registration.unregister();
  });
}

render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ApolloProvider client={factory(store)}>
          <App />
        </ApolloProvider>
      </PersistGate>
    </Provider>
  </StrictMode>,
  document.getElementById('root')
);
