import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import Lightbox from 'react-image-lightbox';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Select, { ValueType } from 'react-select';
import { Column } from 'react-table';

import { SpyglassIcon } from 'assets/icons';
import { Text } from 'components/ui/forms';
import { Button, Container, Empty } from 'components/ui/general';
import { Drawer } from 'components/ui/navigation';
import Table from 'components/ui/table';
import { ExpandableCell } from 'components/ui/table/components/Cells/ExpandableCell';
import { StepperCell } from 'components/ui/table/components/Cells/StepperCell';
import InStockMarker from 'components/ui/Webshop/InStockMarker';
import { WebshopHeaderSelectors } from 'consts/cypress';
import { TABLE_LIMIT } from 'consts/defaults';
import { addItem, removeItem, getSpecificItemCount, getVat } from 'redux/cart';
import { WebshopBreadcrumb, Crumb } from 'routes/Webshop/components';
import {
  WebshopSectionType,
  webshopSectionTypeToText
} from 'routes/Webshop/sections';
import { useSparePartsLazyQuery, StockImage, SparePart } from 'types';
import { PageResponse } from 'types/custom/types';
import { toSEK, productResolvers } from 'utils';

import styles from './WebshopSparePart.module.scss';

interface WebshopSectionProps {
  id?: string;
  searchTerm?: string;
  section: string;
}

const WebshopSparePart: FC<WebshopSectionProps> = ({
  searchTerm: urlSearchTerm,
  section
}) => {
  const [image, setImage] = useState<StockImage>();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const vat = useSelector(getVat);
  const label = webshopSectionTypeToText(section as WebshopSectionType);
  const crumbs: Crumb[] = [{ label, id: section }];

  const [
    getSpareParts,
    { data: sparePartData, loading: sparePartLoading }
  ] = useSparePartsLazyQuery({
    variables: { filter: { offset: 0 } }
  });
  const { total = 1, limit = TABLE_LIMIT, offset = 0 } =
    sparePartData?.spareParts?.meta || {};

  const currentQuery = useRef<PageResponse>({
    pageIndex: 0,
    pageSize: limit,
    sortBy: []
  });

  const [sorting, setSorting] = useState<{
    field: string;
    direction: string;
  }>();
  const [sortingOptions, setSortingOptions] = useState<
    Array<{ value: string; label: string }>
  >([]);

  const {
    register,
    setValue,
    watch,
    reset,
    formState: { isDirty }
  } = useForm({
    defaultValues: {
      searchTerm: urlSearchTerm || ''
    }
  });

  const searchTerm = watch('searchTerm');

  useEffect(() => {
    reset({ searchTerm: urlSearchTerm });
  }, [reset, urlSearchTerm]);

  if (isDirty && urlSearchTerm) {
    history.replace(location.pathname);
  }

  const resetSearch = useCallback(() => {
    setValue('searchTerm', '', { shouldDirty: true });
  }, [setValue]);

  const updateSelectedItem = useCallback(
    (sp, quantity, oldQuantity) => {
      if (quantity > oldQuantity) {
        dispatch(addItem({ sp, quantity }));
      } else {
        dispatch(removeItem({ article: sp, quantity }));
      }
    },
    [dispatch]
  );

  const columns: Array<Column<SparePart>> = useMemo(
    () => [
      {
        Header: 'Artikelnummer',
        accessor: 'articleNumber'
      },
      {
        Header: 'Benämning',
        accessor: 'name',
        Cell: ({ row: { original } }) => (
          <ExpandableCell text={productResolvers.getName(original)} />
        )
      },
      {
        Header: 'Lagersaldo',
        Cell: ({ row: { original } }: any) => (
          <InStockMarker product={original} approximateStockBalance />
        )
      },
      {
        Header: 'Ca.pris(inkl. moms)',
        accessor: 'price',
        Cell: ({ row: { original } }) => {
          if (original.discountedPrice) {
            return (
              <div>
                <p className={styles.price__red}>
                  {toSEK(original.discountedPrice * (1 + vat))}
                </p>
                <p className={styles.price__original}>
                  {toSEK(original.price * (1 + vat))}
                </p>
              </div>
            );
          }
          return toSEK(original.price * (1 + vat));
        }
      },
      {
        Header: 'Anmärkning',
        Cell: ({ row: { original } }: any) => {
          return <ExpandableCell text={original?.remark || ''} />;
        }
      },
      {
        Header: 'Bild',
        style: { width: 50 },
        Cell: ({ row: { original } }: any) => {
          if (original.thumbnail) {
            return (
              <div className={styles.showImageWrapper}>
                <Button
                  color="clear"
                  className={styles.showImageButton}
                  onClick={() => setImage(original.thumbnail)}
                >
                  <i className="material-icons-outlined">image</i>
                </Button>
              </div>
            );
          }

          return null;
        }
      },
      {
        Header: 'Lägg till i varukorg',
        Cell: ({ row, column }: any) => {
          const quantity = useSelector(getSpecificItemCount(row.original.id));
          return (
            <StepperCell
              customFunction={(
                rowIndex: number,
                columnName: number,
                value: number
              ) => updateSelectedItem(row.original, value, quantity)}
              initialValue={quantity}
              row={row}
              column={column}
            />
          );
        }
      }
    ],
    [updateSelectedItem, vat]
  );

  const handleChange = (
    value: ValueType<{ value: string; label: string }, false>
  ) => {
    if (!value) return;
    const [field, direction] = value.value.split('_');
    setSorting({ field, direction });
  };

  useEffect(() => {
    setSortingOptions([
      {
        value: 'price_asc',
        label: 'Pris stigande'
      },
      {
        value: 'price_desc',
        label: 'Pris fallande'
      },
      {
        value: 'sparePartName_asc',
        label: 'Alfabetisk'
      },
      {
        value: 'articleNumber_asc',
        label: 'Relevans'
      }
    ]);
  }, []);

  useEffect(() => {
    getSpareParts({
      variables: {
        filter: {
          searchTerm,
          limit,
          offset
        },
        sorting: sorting ? [sorting] : []
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getSpareParts, sorting, searchTerm]);

  const fetchData = React.useCallback(
    ({ pageIndex, pageSize, sortBy }: PageResponse) => {
      if (pageIndex && pageSize) {
        currentQuery.current = { pageIndex, pageSize, sortBy };
      }
      const size = pageSize || currentQuery.current.pageSize;

      let index = currentQuery.current.pageIndex;
      if (pageIndex !== undefined && pageIndex !== null) {
        index = pageIndex;
      }

      getSpareParts({
        variables: {
          filter: {
            limit: size,
            offset: index * size,
            searchTerm
          },
          sorting: sorting ? [sorting] : []
        }
      });
    },
    [getSpareParts, searchTerm, sorting]
  );
  return (
    <>
      <Helmet>
        <title>{`Webshop: ${label}`}</title>
      </Helmet>
      <Drawer initial="closed" overlay />
      <section>
        <Container>
          <WebshopBreadcrumb crumbs={crumbs} />
          <div className={classNames(styles.block, styles.whiteBackground)}>
            <div className={styles.inputWrapper}>
              <Text
                bordered
                className={styles.search}
                name="searchTerm"
                dataCy={WebshopHeaderSelectors.SEARCH_SPARE_PARTS_INPUT}
                prefixIcon={<SpyglassIcon />}
                prefixSize="sm"
                suffixIcon={
                  !!searchTerm && (
                    <Button
                      color="no"
                      className={styles.clear}
                      onClick={resetSearch}
                    >
                      <i className="material-icons">cancel</i>
                    </Button>
                  )
                }
                register={register}
              />
              <div className={styles.sortingContainer}>
                <p>Sortera efter</p>
                <Select
                  onChange={handleChange}
                  placeholder="Relevans"
                  isSearchable={false}
                  className={styles.reactSelectContainer}
                  classNamePrefix="react-select"
                  options={sortingOptions}
                  components={{ IndicatorSeparator: () => null }}
                />
              </div>
            </div>
            <Table
              columns={columns}
              data={sparePartData?.spareParts?.edges || []}
              totalPages={Math.ceil(total / limit)}
              loading={sparePartLoading}
              fetchData={fetchData}
              filterable
              editable
              showPagination
              empty={
                <Empty
                  absolute
                  icon="list_alt"
                  title="Inga resultat hittades"
                  message="Prova att justera din sökning för att hitta det du letar efter."
                />
              }
            />
          </div>
        </Container>
      </section>
      {image && (
        <Lightbox
          closeLabel="Stäng"
          mainSrc={image ? image.path : ''}
          onCloseRequest={() => setImage(undefined)}
        />
      )}
    </>
  );
};

export default WebshopSparePart;
