import { CartItem } from 'redux/cart';
import { isFee } from 'utils/typeGuards';

const hasQuantityInStock = (cartItem: CartItem, quantity: number) => {
  if (isFee(cartItem.item)) {
    return true;
  }

  if (!cartItem.item.stock) {
    return false;
  }

  const { warehouse, atRetailer } = cartItem.item.stock;

  return quantity < warehouse + atRetailer;
};

export const reduxCartResolvers = {
  ...{
    hasQuantityInStock
  }
};
