import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'components/ui/general';
import { openActAsRetailerMenu } from 'redux/actAsRetailerMenu';
import { getActAsRetailerRight, getActingAsRetailer } from 'redux/auth';

import styles from './ActAsRetailerButton.module.scss';

const ActAsRetailerButton = () => {
  const dispatch = useDispatch();

  const hasRightToActAsRetailer = useSelector(getActAsRetailerRight);
  const actingAsRetailer = useSelector(getActingAsRetailer);

  if (hasRightToActAsRetailer) {
    return (
      <div className={styles.buttonWrapper}>
        <span className={styles.buttonLabel}>På uppdrag av:</span>
        <Button
          size="sm"
          color="alpha"
          onClick={() => dispatch(openActAsRetailerMenu())}
        >
          <span>
            {actingAsRetailer
              ? `${actingAsRetailer.externalId} - ${actingAsRetailer.name}`
              : 'Välj ÅF'}
          </span>
        </Button>
      </div>
    );
  }

  return null;
};

export default ActAsRetailerButton;
