import { format, parseISO } from 'date-fns';

export const formatDate = (date: string, formatString = 'yyyy-MM-dd') => {
  let newDate = '';
  try {
    newDate = format(new Date(date), formatString);
  } catch (e) {
    newDate = parseISO(date).toISOString().substr(0, 10);
  }

  return newDate;
};
