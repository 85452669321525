import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { WebshopSectionType } from 'routes/Webshop/sections';
import {
  VehicleType,
  StockStatus,
  GetWebshopFiltersQuery,
  CustomFilterOption
} from 'types';
import { filterParser, parserSwitch, buildQueryFilter } from 'utils';

export enum FilterCategory {
  VEHICLE = 'vehicle',
  BRAND = 'brand',
  MODEL = 'model',
  MODELSERIES = 'modelSeries',
  COLOR = 'color',
  STOCK = 'stock',
  EQUIPMENT_GROUP = 'equipmentGroup'
}

// Types
export interface WebshopFilterChoice {
  name: VehicleType | StockStatus | CustomFilterOption;
  value: string;
  checked: boolean;
}

export type StoredFilters = {
  [key in FilterCategory]: Array<WebshopFilterChoice>;
};

export interface StoredFiltersRoot {
  [WebshopSectionType.VEHICLES]?: StoredFilters;
  [WebshopSectionType.ACCESSORIES]?: StoredFilters;
}

export interface WebshopFilterRootState {
  webshopFilter: StoredFiltersRoot;
}

// Selectors
export const selectFilter = ({ webshopFilter }: WebshopFilterRootState) =>
  webshopFilter;

const initialState: StoredFiltersRoot = {
  [WebshopSectionType.VEHICLES]: undefined,
  [WebshopSectionType.ACCESSORIES]: undefined
};

const noBrandFilterOption = {
  name: CustomFilterOption.NoBrand,
  value: 'Utan varumärke',
  checked: false
};

const webshopFilterSlice = createSlice({
  name: 'webshopFilter',
  initialState,
  reducers: {
    setupFilter: (
      state: StoredFiltersRoot,
      action: PayloadAction<GetWebshopFiltersQuery>
    ) => {
      const parsedData = filterParser(action.payload.getWebshopFilters);

      // Show items from all stock status by default
      parsedData.stock.forEach((item) => {
        item.checked = true;
      });

      state[WebshopSectionType.VEHICLES] = parsedData;

      // Using object destructuring to avoid assigning references to old object
      const equipmentFilters = {
        ...parsedData,
        brand: [...parsedData.brand, noBrandFilterOption]
      };

      state[WebshopSectionType.ACCESSORIES] = equipmentFilters;
    },
    updateFilterCategory: (
      state: StoredFiltersRoot,
      action: PayloadAction<{
        data: GetWebshopFiltersQuery;
        category: FilterCategory;
        section: WebshopSectionType.VEHICLES | WebshopSectionType.ACCESSORIES;
      }>
    ) => {
      const { data, category, section } = action.payload;
      const selectedFilter = state[section];

      if (!selectedFilter) return; // if setupFilter has not run (filter = undefined)
      const queryData = data.getWebshopFilters;
      const checkboxHistory = buildQueryFilter(selectedFilter[category]);

      const newCategory = parserSwitch(category, queryData);

      if (
        section === WebshopSectionType.ACCESSORIES &&
        category === FilterCategory.BRAND
      ) {
        newCategory.push(noBrandFilterOption);
      }

      newCategory
        .filter(({ name }: any) => checkboxHistory.includes(name))
        .forEach((modelItem: any) => {
          modelItem.checked = true;
        });

      selectedFilter[category] = newCategory;
    },
    clearFilter: (
      state: StoredFiltersRoot,
      action: PayloadAction<{
        section: WebshopSectionType.VEHICLES | WebshopSectionType.ACCESSORIES;
      }>
    ) => {
      const selectedFilter = state[action.payload.section];
      if (!selectedFilter) return;
      // Loop over all filterChoices and set to false
      Object.values(selectedFilter).forEach((filterCategory) => {
        filterCategory.forEach((filterChoice) => {
          filterChoice.checked = false;
        });
      });

      // Show items from all stock status by default
      selectedFilter.stock.forEach((item: any) => {
        item.checked = true;
      });
    },
    toggleFilter: (
      state: StoredFiltersRoot,
      action: PayloadAction<{
        type: FilterCategory;
        name: string;
        checked: boolean;
        section: WebshopSectionType.VEHICLES | WebshopSectionType.ACCESSORIES;
      }>
    ) => {
      const { type, name, checked, section } = action.payload;
      const selectedFilter = state[section];
      if (!selectedFilter) return;
      const findFilterIndex = selectedFilter[type].findIndex(
        (object: WebshopFilterChoice) => object.name === name
      );

      selectedFilter[type][findFilterIndex] = {
        ...selectedFilter[type][findFilterIndex],
        checked
      };
    }
  }
});

export default webshopFilterSlice.reducer;

// Actions
export const {
  toggleFilter,
  clearFilter,
  setupFilter,
  updateFilterCategory
} = webshopFilterSlice.actions;
