import { Warranty, WarrantyStatus } from 'types';

export const isWarrantyClosed = (warranty: Warranty) => {
  if (!warranty?.status) {
    return false;
  }

  return [
    WarrantyStatus.Approved,
    WarrantyStatus.Credited,
    WarrantyStatus.Declined
  ].includes(warranty.status);
};
