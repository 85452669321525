/**
 * When selecting elements for Cypress you should use data-cy="". When defining
 * and selecting elements you should use this constants file. Try to follow this
 * naming convention:
 *
 * export enum <ComponentName>Selectors {
 *   HEADING = '<component-name>-heading',
 *   PARAGRAPH = '<component-name>-paragraph'
 * }
 *
 * So lets say you have a Button component where you want to select the content
 * and the spinner, that would result in:
 *
 * export enum ButtonSelectors {
 *   CONTENT = 'button-content',
 *   SPINNER = 'button-spinner'
 * }
 */

export enum GdprConsentSelectors {
  ROOT = 'gdpr-consent-root',
  ALLOW_ALL = 'gdpr-consent-allow-all'
}

export enum RetailerWarrantiesSelectors {
  CREATE_BUTTON = 'retailer-warranties-create-new-warranty-button'
}

export enum DealyWarrantiesSelectors {
  CREATE_BUTTON = 'dealy-warranties-create-legacy-warranty-button'
}

export enum CreateWarrantySelectors {
  RADIO_BUTTONS = 'create-warranty-radio-buttons',
  NEXT_BUTTON = 'create-warranty-next-button',
  SUBMIT_BUTTON = 'create-warranty-submit-button',
  WARRANTY_CREATED_LINK = 'create-warranty-warranty-created-link',
  WARRANTY_ID = 'create-warranty-warranty-id',

  // Wizard slides
  CATEGORY_SLIDE = 'create-warranty-category-slide',
  VEHICLE_SLIDE = 'create-warranty-vehicle-slide',
  SPARE_PART_SLIDE = 'create-warranty-spare-part-slide',
  VEHICLE_DATA_SLIDE = 'create-warranty-vehicle-data-slide',
  ERROR_DESCRIPTION_SLIDE = 'create-warranty-error-description-slide',
  SYMPTOM_CODES_SLIDE = 'create-warranty-symptom-codes-slide',
  ROW_DATA_SLIDE = 'create-warranty-row-data-slide',
  SUBMIT_SLIDE = 'create-warranty-submit-slide',
  ADD_OPERATION_ROW_BUTTON = 'addOperationRow',
  OPERATION_CATEGORY_SELECT = 'operationCategorySelect',
  OPERATION_CODE_SELECT = 'operationCodeSelect',
  OPERATION_SAVE_BUTTON = 'operationsSave',
  ADD_ARTICLE_ROW = 'addArticleRow',
  ARTICLE_SEARCH_FIELD = 'articleSearchField',
  PARTS_SAVE_BUTTON = 'partsSave',
  ADD_EXTERNAL_ROW = 'addExternalRow',
  PURCHASE_REFERS_TYPE_WORK = 'purchaseRefersTypeWork',
  EXTERNAL_SAVE_BUTTON = 'externalSave',

  // Form fields
  METER_READING_INPUT = 'create-warranty-meter-reading-input',
  OPERATING_TIME_INPUT = 'create-warranty-operating-time-input',
  SERVICE_BOOK_INPUT = 'create-warranty-service-book-input',
  DEFECT_PART_NUMBER_INPUT = 'create-warranty-defect-part-number-input',
  METER_READING_IMAGE_INPUT = 'create-warranty-meter-reading-image-input',
  SERVICE_BOOK_IMAGE_INPUT = 'create-warranty-service-book-image-input',
  DAMAGE_IMAGE_INPUT = 'create-warranty-damage-image-input',
  RECEIPT_IMAGE_INPUT = 'create-warranty-receipt-image-input',
  CUSTOMER_REMARK_TEXT_AREA = 'create-warranty-customer-remark-text-area',
  WORKSHOP_REMARK_TEXT_AREA = 'create-warranty-workshop-remark-text-area',
  SYMPTOM_CODE_SELECT = 'create-warranty-symptom-code-select',
  DATE_PICKER = 'create-warranty-date-picker',
  TYPE_CODE = 'create-warranty-type-code',

  SAVE_DRAFT_BUTTON = 'save-warranty-draft-button',
  DELETE_DRAFT_BUTTON = 'trash-warranty-draft-button'
}

export enum WebshopLandingSelectors {
  VEHICLE_SPLASH = 'webshop-landing-vehicle-splash',
  ACCESSORIES_SPLASH = 'webshop-landing-accessories-splash',
  SPARE_PARTS_SPLASH = 'webshop-landing-spare-parts-splash'
}

export enum WebshopCardSelectors {
  ROOT = 'webshop-card-root',
  ADD_ITEM_BUTTON = 'webshop-card-add-item-button',
  INCREMENT_BUTTON = 'webshop-card-increment-button',
  DECREMENT_BUTTON = 'webshop-card-decrement-button',
  PRODUCT_COUNT_INPUT = 'webshop-card-product-count-button'
}

export enum WebshopProductSelectors {
  ROOT = 'webshop-product-root',
  ADD_ITEM_BUTTON = 'webshop-product-add-item-button',
  INCREMENT_BUTTON = 'webshop-product-increment-button',
  DECREMENT_BUTTON = 'webshop-product-decrement-button',
  PRODUCT_COUNT_INPUT = 'webshop-product-product-count-button'
}

export enum TooltipSelectors {
  Trigger = 'tooltip-trigger',
  Tooltip = 'tooltip-tooltip'
}

export enum WebshopHeaderSelectors {
  OPEN_CART_MODAL_BUTTON = 'webshop-header-open-cart-modal-button',
  VEHICLE_LINK = 'webshop-header-vehicle-link',
  EQUIPMENT_LINK = 'webshop-header-equipment-link',
  SPARE_PART_LINK = 'webshop-header-spare-part-link',
  BILL_OF_MATERIAL_LINK = 'webshop-header-bill-of-material-link',
  MY_ORDERS_LINK = 'webshop-header-my-orders-link',
  MY_SHOPPING_LISTS = 'webshop-header-my-shopping-lists',
  STEPPER_ADD_BUTTON = 'stepperAdd',
  SEARCH_SPARE_PARTS_INPUT = 'spare-parts-search-input'
}

export enum CartCardSelectors {
  ROOT = 'cart-card-root'
}

export enum WebshopCartOverlaySelectors {
  ROOT = 'webshop-cart-overlay-root',
  OVERLAY_ARTICLES = 'webshop-cart-overlay-overlay-articles',
  REVIEW_ORDER_BUTTON = 'webshop-cart-overlay-review-order-button',
  ADD_SHOPPING_LIST_BUTTON = 'webshop-cart-overlay-add-shopping-list-button'
}

export enum ShoppingListModalSelectors {
  NAME_INPUT = 'shopping-list-modal-name-input',
  REMARK_INPUT = 'shopping-list-modal-remark-input',
  SUBMIT_BUTTON = 'shopping-list-modal-submit-button'
}

export enum WebshopShoppingListsSelectors {
  IMPORT_BUTTON = 'webshop-shopping-lists-selectors-import-button',
  EDIT_BUTTON = 'webshop-shopping-lists-selectors-edit-button',
  REMOVE_BUTTON = 'webshop-shopping-lists-selectors-remove-button'
}

export enum SearchBarSelectors {
  SEARCH_INPUT = 'search-bar-selectors-search-input',
  CATEGORY_SELECT = 'search-bar-selectors-category-select'
}

export enum SearchResultSelectors {
  RESULT_CARD = 'search-result-result-card',
  PRICE = 'search-result-price',
  DISCOUNTED_PRICE = 'search-result-discounted-price',
  OLD_PRICE = 'search-result-old-price'
}

export enum SearchRowSelectors {
  ROOT = 'search-row-root',
  PRICE = 'search-row-price',
  DISCOUNTED_PRICE = 'search-row-discounted-price'
}

export enum MyOrdersSelectors {
  SEARCH_INPUT = 'my-orders-search-input',
  SHOW_ORDER_BUTTON = 'my-orders-show-order-button'
}

export enum SwitchSelectors {
  Root = 'switch-root',
  Input = 'switch-input',
  Controlled = 'switch-controlled',
  ControlledInput = 'switch-controlled-input'
}

export enum ExplodedViewSelectors {
  ADD_VIEW_BUTTON = 'explodedViewAddButton',
  SHOW_ALTERNATIVE = 'showAlternative',

  MOVE_FOLDER_OR_VIEW = 'moveFolderOrEV',
  MOVE_FOLDER_OR_VIEW_BUTTON = 'moveFolderOrEvBtn',
  CHANGE_NAME_FOLDER_OR_VIEW = 'changeNameFolderOrEv',
  SAVE_RENAME_CHANGES_BUTTON = 'saveRenameChangesBtn',
  REMOVE_FOLDER_OR_VIEW = 'removeFolderOrEv',

  ADD_FOLDER_MODEL_BRAND_BUTTON = 'exploded-view-add-folder-model-brand-button',
  ADD_FOLDER_MODEL_BRAND_SAVE_BUTTON = 'exploded-view-add-folder-model-brand-sabe-button',

  MOVE_MODAL = 'moveModal',
  NEW_BRAND_SPAN = 'newBrandSpan',
  NEW_MODEL_SPAN = 'newModelSpan',
  NEW_FOLDER_SPAN = 'newFolderSpan',
  NEW_EXPLODED_VIEW_SPAN = 'newExplodedViewSpan',
  CONFIRM_REMOVE_BUTTON = 'confirmRemovalBtn'
}

export enum UserResetInfoSelectors {
  CONTACT_INFO_SAVE_BUTTON = 'contactInformationSaveBtn',
  CHANGE_PASSWORD_SAVE_BUTTON = 'changePasswordSaveBtn'
}

export enum UserManagementSelectors {
  USER_EDIT_BUTTON = 'userEditBtn',
  USER_EDIT_SAVE_BUTTON = 'userEditSaveBtn',
  USER_ENABLE_EDIT_BUTTON = 'userEnableEditBtn',
  REMOVE_USER_BUTTON = 'removeUserBtn',
  CONFIRM_REMOVE_BUTTON = 'confirmRemovalBtn',
  USERFORM_SELECT_ROLE = 'userform-select-role',
  USER_ADD_NEW_USER_BUTTON = 'usersAddNewUser',
  USER_ADD_USER_MODAL = 'addUserModal',
  SEND_INVITATION_NEW_USER_BUTTON = 'sendInvitationToNewUserBtn'
}

export enum PaginationSelectors {
  NEXT_PAGE_BUTTON = 'next-page-button',
  PAGE_OF_PAGE_TEXT = 'page-of-page'
}

export enum TableSelectors {
  TABLE_ROOT = 'table-root'
}

export enum WebshopCheckoutSelectors {
  CONTINUE_OR_APPROVE_ORDER_BUTTON = 'webshopContinueOrApproveOrderBtn'
}

export enum PartsRowSelectors {
  ROOT = 'parts-row-root'
}
