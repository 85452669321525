import React, {
  FC,
  Children,
  ReactElement,
  useContext,
  useState,
  useMemo,
  createContext,
  ReactNode,
  useEffect
} from 'react';

import classNames from 'classnames';

import { Panel, PanelProps } from './Panel';
import { Tab, TabProps } from './Tab';

import styles from './Tabs.module.scss';

type TabsContextProps = {
  activeTab: string;
  setActiveTab: (id: string) => void;
};

type TabsComposition = {
  Tab: FC<TabProps>;
  Panel: FC<PanelProps>;
};

type TabsProps = {
  className?: string;
  initial?: number;
  small?: boolean;
  ExtraComponent?: ReactNode | null;
  onActiveTabChange?: (arg0: string) => void;
  resetTriggers?: any[];
};

export const TabsContext = createContext<TabsContextProps | undefined>(
  undefined
);

const Tabs: TabsComposition & FC<TabsProps> = ({
  children,
  initial = 0,
  small,
  ExtraComponent,
  className,
  onActiveTabChange,
  resetTriggers = [],
  ...rest
}) => {
  const c = Children.toArray(children);
  if (!c.length) {
    throw new Error('Tabs requires atleast 1 panel');
  }

  if (initial < 0 || initial > c.length - 1) {
    throw new Error(`Initial Tab index (${initial}) is out of scope`);
  }

  const initialTab = (c[initial] as ReactElement).props.id;
  const [activeTab, setActiveTab] = useState(initialTab);

  useEffect(() => {
    setActiveTab(initialTab);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...resetTriggers, initialTab]);

  useEffect(() => {
    if (!onActiveTabChange) return;

    onActiveTabChange(activeTab);
  }, [activeTab, onActiveTabChange]);

  const memoizedContextValue = useMemo(
    () => ({
      activeTab,
      setActiveTab
    }),
    [activeTab, setActiveTab]
  );

  return (
    <TabsContext.Provider value={memoizedContextValue}>
      <div className={classNames(styles.root, className)} {...rest}>
        <div className={styles.tabs}>
          {Children.map(children, (child) => {
            if (!(child as ReactElement)?.props) {
              return null;
            }
            const { id, label } = (child as ReactElement).props;
            return <Tab id={id} label={label} small={small} />;
          })}
          {ExtraComponent}
        </div>
        <div className={styles.panels}>{children}</div>
      </div>
    </TabsContext.Provider>
  );
};

export const useTabs = (): TabsContextProps => {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error('This component must be used within a <Tabs> component.');
  }
  return context;
};

Tabs.Tab = Tab;
Tabs.Panel = Panel;

export default Tabs;
