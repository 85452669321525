import React, { FC } from 'react';

import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import {
  CircleBlackIcon,
  CircleWhiteIcon,
  CirclePrimaryIcon
} from 'assets/icons';

import styles from './Spinner.module.scss';

interface SpinnerProps {
  className?: string;
  variant?: 'light' | 'dark' | 'primary';
  visible?: boolean;
}

const Spinner: FC<SpinnerProps> = ({
  className,
  variant = 'primary',
  visible
}) => {
  const renderVariant = () => {
    switch (variant) {
      case 'dark':
        return <CircleBlackIcon />;
      case 'light':
        return <CircleWhiteIcon />;
      default:
        return <CirclePrimaryIcon />;
    }
  };
  return (
    <AnimatePresence>
      {visible && (
        <motion.div
          className={classNames(
            styles.root,
            {
              [styles[variant]]: variant
            },
            className
          )}
          initial={{
            opacity: 0,
            transform: 'translate(-50%, -50%) scale(0.6)'
          }}
          animate={{ opacity: 1, transform: 'translate(-50%, -50%) scale(1)' }}
          exit={{ opacity: 0, transform: 'translate(-50%, -50%) scale(0.6)' }}
          transition={{ type: 'spring', stiffness: 500, damping: 30 }}
        >
          {renderVariant()}
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Spinner;
