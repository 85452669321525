import { VehicleType } from 'types';

export const vehicleTypeToText = (type: VehicleType | string) => {
  switch (type) {
    case VehicleType.MopedCar:
      return 'Mopedbilar';
    case VehicleType.Atv:
      return 'ATV';
    case VehicleType.Utv:
      return 'UTV';
    case VehicleType.Ssv:
      return 'SSV';
    case VehicleType.ElectricScooter:
      return 'Elmopeder';
    case VehicleType.WalkingScooter:
      return 'Promenadskoter';
    case VehicleType.ChildWheels:
      return 'Barnhjulingar';
    case VehicleType.LightTruck:
      return 'Lätt lastbil';
    default:
      return type;
  }
};
