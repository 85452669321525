import React, { FC } from 'react';

import classNames from 'classnames';

import { Text } from 'components/ui/forms';

import styles from './InputTextBox.module.scss';

interface InputTextProps {
  register?: any;
  errors?: any;
}

const InputTextBox: FC<InputTextProps> = ({ register, errors }) => {
  return (
    <div>
      <div className={classNames(styles.row, styles.borderRow)}>
        <Text
          label="Företagsnamn"
          name="retailerName"
          disabled
          className={styles.textField}
          register={register}
          error={errors.retailerName}
          type="text"
          required
          validation={{ required: true }}
        />
      </div>
      <div className={classNames(styles.row, styles.borderRow)}>
        <Text
          label="Kundnummer"
          name="retailerId"
          disabled
          className={styles.textField}
          register={register}
          error={errors.retailerId}
          type="text"
          required
          validation={{ required: true }}
        />
      </div>
      <div className={classNames(styles.row, styles.borderRow)}>
        <Text
          label="Adress"
          name="address"
          register={register}
          className={styles.textField}
          type="text"
          error={errors.contactUser}
          required
          validation={{ required: true }}
          disabled
        />
      </div>
      <div className={styles.flexContainer__row}>
        <div
          className={classNames(
            styles.row,
            styles.borderRow,
            styles.address__right_margin
          )}
        >
          <Text
            label="Postnummer"
            name="postalCode"
            register={register}
            type="text"
            className={styles.textField}
            error={errors.contactUser}
            required
            validation={{ required: true }}
            disabled
          />
        </div>
        <div
          className={classNames(
            styles.row,
            styles.borderRow,
            styles.address__left_margin
          )}
        >
          <Text
            label="Stad"
            name="postalCity"
            register={register}
            type="text"
            className={styles.textField}
            error={errors.contactUser}
            required
            validation={{ required: true }}
            disabled
          />
        </div>
      </div>
      <div className={classNames(styles.row, styles.borderRow)}>
        <Text
          label="E-postadress"
          name="email"
          register={register}
          className={styles.textField}
          type="userEmail"
          required
          validation={{ required: true }}
        />
      </div>
      <div className={classNames(styles.row, styles.borderRow)}>
        <Text
          label="Telefon"
          name="phoneNumber"
          className={styles.textField}
          register={register}
          type="text"
          required
          validation={{ required: true }}
        />
      </div>
    </div>
  );
};

export default InputTextBox;
