import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Action {
  readonly type: string;
  payload?: any;
}

interface State {
  open: boolean;
  instant?: boolean;
  overlay: boolean;
}

export interface MenuRootState {
  menu: State;
}

// Selectors
const getMenuState = ({ menu }: MenuRootState) => menu;
export const menuOpen = createSelector([getMenuState], (menu) => menu.open);
export const menuOverlay = createSelector(
  [getMenuState],
  (menu) => menu.overlay
);

// Reducers
const initialState: State = {
  open: true,
  overlay: false
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setOverlay: (state: State, action: PayloadAction<{ overlay: boolean }>) => {
      state.overlay = action.payload.overlay;
    },
    toggleMenu: (state: State) => {
      state.open = !state.open;
    },
    openMenu: (state: State) => {
      state.open = true;
    },
    closeMenu: (state: State) => {
      state.open = false;
    }
  }
});

export default menuSlice.reducer;

// Actions
export const {
  setOverlay,
  toggleMenu,
  openMenu,
  closeMenu
} = menuSlice.actions;
