import React, { FC, useCallback, useState } from 'react';

import classNames from 'classnames';
import { useDropzone } from 'react-dropzone';

import { Button } from 'components/ui/general';

import styles from './DropZone.module.scss';
import buttonStyles from 'components/ui/general/Button/Button.module.scss';

interface DropZoneExcelFile {
  closeModal: Function;
  refetch: Function;
  submitHandler?: { handler: Function; loader: boolean };
}

const DropZoneExcelFile: FC<DropZoneExcelFile> = ({
  closeModal,
  refetch,
  submitHandler
}) => {
  const [selectedFile, setSelectedFile] = useState<File>();
  const isLoading = submitHandler?.loader;

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept:
      'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });

  const handleSubmit = async () => {
    if (submitHandler) {
      await submitHandler.handler(selectedFile);
      return refetch();
    }
  };

  const cancelUpload = () => {
    closeModal();
  };

  return (
    <div>
      <div
        className={classNames(styles.inputs, {
          [styles.uploading]: isLoading
        })}
      >
        <div
          {...getRootProps()}
          className={classNames(styles.dropzone, {
            [styles.active]: isDragActive
          })}
        >
          <div className={styles.dropzoneContent}>
            {!selectedFile && (
              <>
                <i
                  className={classNames(
                    'material-icons-outlined',
                    styles.dropzoneCloud
                  )}
                >
                  cloud_upload
                </i>
                <span className={styles.dropzoneText}>
                  Dra och släpp filen här
                </span>
                <span className={styles.dropzoneOr}>eller</span>
                <span
                  className={classNames(
                    buttonStyles.root,
                    buttonStyles.alphaColor,
                    buttonStyles.mdSize,
                    {
                      [buttonStyles.disabled]: isLoading
                    }
                  )}
                >
                  Välj fil
                </span>
              </>
            )}
            {selectedFile && <span>{selectedFile.name}</span>}
          </div>
          <input {...getInputProps()} />
        </div>
      </div>
      <div className={classNames(styles.row, styles.endRow)}>
        <Button
          className={styles.button}
          color="epsilon"
          size="md"
          onClick={cancelUpload}
        >
          Avbryt
        </Button>
        <Button
          className={styles.button}
          onClick={handleSubmit}
          color="alpha"
          size="md"
          disabled={!selectedFile || isLoading}
          loading={isLoading}
        >
          Spara
        </Button>
      </div>
    </div>
  );
};

export default DropZoneExcelFile;
