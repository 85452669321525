import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';

import { impersonatedRetailerId } from 'redux/auth';
import { selectFilter } from 'redux/webshopFilter';
import { selectSortingVehicles } from 'redux/webshopSorting';
import { WebshopSection } from 'routes/Webshop/components';
import { WebshopSectionType } from 'routes/Webshop/sections';
import { useStockVehiclesQuery, StockVehiclesQueryVariables } from 'types';
import { buildQueryFilter } from 'utils';

const WebshopStockVehicle = () => {
  const allFilters = useSelector(selectFilter);
  const storedFilter = allFilters[WebshopSectionType.VEHICLES];
  const sorting = useSelector(selectSortingVehicles);
  const actAsRetailerById = useSelector(impersonatedRetailerId);

  const filter = useMemo(() => {
    if (!storedFilter) return;

    return {
      actAsRetailerById,
      vehicleType: buildQueryFilter(storedFilter.vehicle),
      brand: buildQueryFilter(storedFilter.brand),
      stockBalance: buildQueryFilter(storedFilter.stock),
      modelSeries: buildQueryFilter(storedFilter.modelSeries),
      model: buildQueryFilter(storedFilter.model),
      color: buildQueryFilter(storedFilter.color)
    };
  }, [storedFilter, actAsRetailerById]);

  return (
    <WebshopSection
      section={WebshopSectionType.VEHICLES}
      filter={filter as StockVehiclesQueryVariables['filter']}
      sorting={sorting}
      query={useStockVehiclesQuery}
    />
  );
};

export default WebshopStockVehicle;
