import {
  isBillOfMaterial,
  isEquipment,
  isStockVehicle,
  isSparePart
} from './typeGuards';
import { WebshopSectionType } from 'routes/Webshop/sections';

export const getWebshopLinkForCard = (product: any, id: string): string => {
  if (isStockVehicle(product)) {
    return `/webshop/${WebshopSectionType.VEHICLES}/${id}`;
  }

  if (isEquipment(product)) {
    return `/webshop/${WebshopSectionType.ACCESSORIES}/${id}`;
  }

  if (isBillOfMaterial(product)) {
    return `/webshop/${WebshopSectionType.BILL_OF_MATERIAL}?searchTerm=${product.articleNumber}`;
  }

  if (isSparePart(product)) {
    return `/webshop/${WebshopSectionType.SPARE_PARTS}?searchTerm=${product.articleNumber}`;
  }

  return `/webshop`;
};
