import React, { FC } from 'react';

import classNames from 'classnames';

import { useWizard } from '../../Wizard';

import styles from '../../Wizard.module.scss';

export interface WizardTabProps {
  id: string;
  label: string;
  clickable?: boolean;
}

export const WizardTab: FC<WizardTabProps> = ({ id, label, clickable }) => {
  const { activeSlide, setSlide } = useWizard();

  return (
    <button
      id={id}
      type="button"
      className={classNames(styles.tab, {
        [styles.active]: activeSlide === id
      })}
      onClick={() => clickable && setSlide(id)}
    >
      <span>{label}</span>
    </button>
  );
};
