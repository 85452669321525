import { combineReducers } from '@reduxjs/toolkit';

import actAsRetailerMenu from './actAsRetailerMenu';
import auth from './auth';
import cart from './cart';
import feedback from './feedback';
import gdpr from './gdpr';
import menu from './menu';
import router from './router';
import warrantyFilter from './warrantyFilter';
import webshopFilter from './webshopFilter';
import webshopSorting from './webshopSorting';

export const reducers = combineReducers({
  router,
  auth,
  menu,
  feedback,
  webshopFilter,
  webshopSorting,
  cart,
  actAsRetailerMenu,
  gdpr,
  warrantyFilter
});

export type RootState = ReturnType<typeof reducers>;
