import { ShoppingListItem } from 'types/graphql';

const getProduct = (shoppingListItem: ShoppingListItem) => {
  return (
    shoppingListItem.vehicle ||
    shoppingListItem.sparePart ||
    shoppingListItem.equipment ||
    shoppingListItem.billOfMaterial
  );
};

export const shoppingListResolvers = {
  ...{ getProduct }
};
