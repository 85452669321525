import { ApolloError } from '@apollo/client';

import {
  MaintenanceTaskStatus,
  MaintenanceRowStatus,
  MaintenanceWorkCategory,
  MaintenanceTaskError,
  CustomPriceError
} from 'types';

export const maintenanceTaskStatusMap = {
  [MaintenanceTaskStatus.Draft]: {
    text: 'Utkast',
    color: 'omicron'
  },
  [MaintenanceTaskStatus.Registered]: {
    text: 'Registrerad',
    color: 'rho'
  },
  [MaintenanceTaskStatus.Processing]: {
    text: 'Hanteras',
    color: 'rho'
  },
  [MaintenanceTaskStatus.Complement]: {
    text: 'Komplettera',
    color: 'nu'
  },
  [MaintenanceTaskStatus.Complemented]: {
    text: 'Kompletterad',
    color: 'nu'
  },
  [MaintenanceTaskStatus.Approved]: {
    text: 'Godkänd',
    color: 'sigma'
  },
  [MaintenanceTaskStatus.Declined]: {
    text: 'Avslagen',
    color: 'eta'
  },
  [MaintenanceTaskStatus.Credited]: {
    text: 'Krediterad',
    color: 'eta'
  },
  [MaintenanceTaskStatus.WaitingForSage]: {
    text: 'WAITING_FOR_SAGE',
    color: 'eta'
  }
};

export const maintenanceRowStatusMap = {
  [MaintenanceRowStatus.Registered]: {
    text: 'Registrerad',
    color: 'rho'
  },
  [MaintenanceRowStatus.Complement]: {
    text: 'Komplettera',
    color: 'nu'
  },
  [MaintenanceRowStatus.Complemented]: {
    text: 'Kompletterad',
    color: 'nu'
  },
  [MaintenanceRowStatus.Approved]: {
    text: 'Godkänd',
    color: 'sigma'
  },
  [MaintenanceRowStatus.Declined]: {
    text: 'Avslagen',
    color: 'eta'
  }
};

export const translateMaintenanceWorkCategory = (valueToTranslate: string) => {
  switch (valueToTranslate) {
    case MaintenanceWorkCategory.DeliveryService:
      return 'Leveransservice';
    case MaintenanceWorkCategory.Service:
      return 'Service';
    case MaintenanceWorkCategory.Repair:
      return 'Reparation';
    case MaintenanceWorkCategory.Collision:
      return 'Kollision';
    default:
      return 'Okänd';
  }
};

export const workCategoryOptions = Object.values(MaintenanceWorkCategory).map(
  (workCategory) => ({
    value: workCategory,
    label: translateMaintenanceWorkCategory(workCategory)
  })
);

export const maintenanceOperationsToText = () => {
  const arrayObjects = [{ value: '', label: 'Välj' }];

  Object.values(MaintenanceWorkCategory).forEach((value) => {
    arrayObjects.push({
      value,
      label: translateMaintenanceWorkCategory(value)
    });
  });

  return arrayObjects;
};

export const translateMaintenanceTaskError = (error: ApolloError) => {
  const errorVariant = error.graphQLErrors[0].extensions?.errorVariant;
  switch (errorVariant) {
    case MaintenanceTaskError.OnlyRetailersAllowed:
      return 'Operation endast tillåten för återförsäljare';
    case MaintenanceTaskError.OnlyOneMileageAllowanceRow:
      return 'Endast en reseersättningsrad är tillåten';
    case MaintenanceTaskError.CanOnlyUpsertDraft:
      return 'Kan bara registrera befintliga storkundsärenden med statusen utkast';
    case MaintenanceTaskError.IncorrectOrMissingField:
      return 'Minst ett fält är inkorrekt eller saknas';
    case MaintenanceTaskError.CanOnlyRemoveDrafts:
      return 'Endast storkundsärenden med statusen utkast kan tas bort';
    case MaintenanceTaskError.MaintenanceTaskNotFound:
      return 'Kunde inte hitta storkundsärende';
    case MaintenanceTaskError.MaintenanceCustomerNotFound:
      return 'Kunde inte hitta storkund';
    case MaintenanceTaskError.DisallowedStatusInBulkOperation:
      return 'Flervalsoperation kan inte utföras på avslutade ärenden eller utkast';
    case MaintenanceTaskError.MissingDeclineReason:
      return 'Orsak till avslag saknas';
    case MaintenanceTaskError.MissingDeclineComment:
      return 'Fritext måste fyllas i om orsak till avslag är annat';
    case MaintenanceTaskError.InvalidStatusChange:
      return 'Otillåten statusändring. Detta kan inträffa om ärendet är avslutat';
    case MaintenanceTaskError.FailedSavingImage:
      return 'Kunde inte spara bild';
    case MaintenanceTaskError.FailedSavingAttachement:
      return 'Kunde inte spara bilaga';
    case MaintenanceTaskError.FromDateAfterToDate:
      return 'Fråndatumet är efter tilldatumet';
    case MaintenanceTaskError.MissingMaintenanceCustomer:
      return 'Storkund krävs för att kunna hämta rätt priser';
    default:
      return errorVariant;
  }
};

export const translateCustomPriceError = (error: ApolloError) => {
  const errorVariant = error.graphQLErrors[0].extensions?.errorVariant;
  const message = error.graphQLErrors[0].message;
  switch (errorVariant) {
    case CustomPriceError.BadPrice:
    case CustomPriceError.NoArticleFound:
      return message;
    default:
      return errorVariant;
  }
};
