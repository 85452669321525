import React, { useCallback, useState } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Empty } from 'components/ui/general';
import { DealyModal, DealyModalAnchor } from 'components/ui/modals';
import { WebshopCartOverlaySelectors } from 'consts/cypress';
import {
  clearCart,
  getCurrentNumberOfItems,
  getItems,
  getPrice,
  getVat
} from 'redux/cart';
import {
  WebshopCartOverlayCard,
  WebshopCreateShoppingList
} from 'routes/Webshop/components';
import { toSEK } from 'utils';

import styles from './WebshopCartOverlay.module.scss';

interface WebshopCartOverlayProps {
  onRequestClose: () => void;
  isOpen: boolean;
}

const WebshopCartOverlayContent = ({
  onRequestClose
}: {
  onRequestClose: WebshopCartOverlayProps['onRequestClose'];
}) => {
  const dispatch = useDispatch();
  const price = useSelector(getPrice);
  const vat = useSelector(getVat);
  const cartItems = useSelector(getItems);
  const numberOfItems = useSelector(getCurrentNumberOfItems);
  const [showCreateShoppingListModal, setShowShoppingListModal] = useState(
    false
  );

  const renderItems = useCallback(() => {
    if (!cartItems?.length) {
      return (
        <Empty
          className={styles.empty}
          icon="list_alt"
          title="Inga artiklar"
          message="Det finns inga artiklar i din varukorg"
        />
      );
    }

    return cartItems.map((cartItem) => (
      <WebshopCartOverlayCard
        key={cartItem.item.id}
        cartItem={cartItem}
        onRequestClose={onRequestClose}
      />
    ));
  }, [cartItems, onRequestClose]);

  return (
    <div className={styles.root}>
      <div className={styles.top}>
        <h3 className={styles.heading}>Varukorg</h3>
        <div className="grid gutter-left-md">
          <div className="gutter__item">
            <Button color="epsilon" onClick={() => dispatch(clearCart())}>
              <span>Rensa varukorg</span>
            </Button>
          </div>
          <div className="gutter__item">
            <Button
              dataCy={WebshopCartOverlaySelectors.ADD_SHOPPING_LIST_BUTTON}
              color="epsilon"
              onClick={() => setShowShoppingListModal(true)}
            >
              <span>Skapa lista</span>
            </Button>
          </div>
          <div className={classNames('gutter__item', styles.buttonContainer)}>
            <button
              type="button"
              className={styles.close}
              onClick={onRequestClose}
            >
              <i className="material-icons">close</i>
            </button>
          </div>
        </div>
      </div>
      <div
        data-cy={WebshopCartOverlaySelectors.OVERLAY_ARTICLES}
        className={styles.middle}
      >
        {renderItems()}
      </div>
      <div className={styles.bottom}>
        <div className={styles.bottomSummarize}>
          <div className={styles.bottomColumn}>
            <div className={styles.bottomColumnText}>
              {numberOfItems} Artiklar
            </div>
            <div className={styles.bottomColumnPrice}>{toSEK(price)}</div>
          </div>
          <div
            className={classNames(
              styles.bottomColumn,
              styles.bottomColumnTotalSale
            )}
          >
            <div className={styles.bottomColumnText}>
              Total Rek Ca-pris (inkl. moms)
            </div>
            <div className={styles.bottomColumnPrice}>
              {toSEK(price * (1 + vat))}
            </div>
          </div>
          <div
            className={classNames(
              styles.bottomColumn,
              styles.bottomColumnTotal
            )}
          >
            <div className={styles.bottomColumnText}>
              Total Rek Ca-pris (exkl. moms)
            </div>
            <div className={styles.bottomColumnPrice}>{toSEK(price)}</div>
          </div>
        </div>
        <div className={styles.bottomButton}>
          <Button
            dataCy={WebshopCartOverlaySelectors.REVIEW_ORDER_BUTTON}
            color="alpha"
            to="/webshop/cart"
            fullWidth
            onClick={() => onRequestClose?.()}
          >
            Granska order
          </Button>
        </div>
      </div>
      <WebshopCreateShoppingList
        isOpen={showCreateShoppingListModal}
        closeModal={() => setShowShoppingListModal(false)}
        closeCartModal={() => onRequestClose?.()}
      />
    </div>
  );
};

const WebshopCartOverlay = ({
  isOpen,
  onRequestClose
}: WebshopCartOverlayProps) => {
  return (
    <DealyModal
      anchor={DealyModalAnchor.RIGHT}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <WebshopCartOverlayContent onRequestClose={onRequestClose} />
    </DealyModal>
  );
};

export default WebshopCartOverlay;
