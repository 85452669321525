import React, { FC } from 'react';

import classNames from 'classnames';

import { Text } from 'components/ui/forms/index';
import { Button } from 'components/ui/general';

import styles from '../DropZone.module.scss';

interface FileItemProps {
  file: File;
  name: string;
  onChange: (file: File, name: string) => void;
  onRemove: () => void;
}

const FileItem: FC<FileItemProps> = ({ file, name, onChange, onRemove }) => {
  return (
    <div className={styles.fileItem}>
      <div className={styles.fileIcon}>
        <i className="material-icons-outlined">insert_drive_file</i>
      </div>
      <div className={styles.fileInfo}>
        <Text
          value={name}
          placeholder={file.name}
          onChange={(e) => onChange(file, e.target.value)}
          suffixIcon={
            <Button
              color="no"
              className={classNames(styles.clear, {
                [styles.showSuffix]: name.length > 0
              })}
              onClick={() => onChange(file, '')}
            >
              <i className="material-icons">cancel</i>
            </Button>
          }
        />
      </div>
      <Button
        color="no"
        className={styles.removeFileButton}
        onClick={() => onRemove()}
      >
        Ta bort
      </Button>
    </div>
  );
};

export default FileItem;
