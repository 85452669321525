import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';

import classNames from 'classnames';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { SpyglassIcon } from 'assets/icons';
import BillOfMaterialRowModal from 'components/modals/BillOfMaterialRowModal';
import { Text } from 'components/ui/forms';
import { Button, Container, Empty } from 'components/ui/general';
import { Drawer } from 'components/ui/navigation';
import Table from 'components/ui/table';
import { StepperCell } from 'components/ui/table/components/Cells/StepperCell';
import InStockMarker from 'components/ui/Webshop/InStockMarker';
import { TABLE_LIMIT } from 'consts/defaults';
import { addItem, removeItem, getSpecificItemCount, getVat } from 'redux/cart';
import { WebshopBreadcrumb, Crumb } from 'routes/Webshop/components';
import {
  WebshopSectionType,
  webshopSectionTypeToText
} from 'routes/Webshop/sections';
import {
  BillOfMaterial,
  useBillOfMaterialLazyQuery,
  BillOfMaterialType
} from 'types';
import { PageResponse } from 'types/custom/types';
import { toSEK } from 'utils';

import styles from './WebshopBillOfMaterial.module.scss';

interface WebshopBillOfMaterialProps {
  searchTerm?: string;
}

const WebshopBillOfMaterial = ({
  searchTerm: urlSearchTerm
}: WebshopBillOfMaterialProps) => {
  const section = WebshopSectionType.BILL_OF_MATERIAL;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const vat = useSelector(getVat);
  const label = webshopSectionTypeToText(section as WebshopSectionType);
  const crumbs: Crumb[] = [{ label, id: section }];
  const [getBillOfMaterial, { data, loading }] = useBillOfMaterialLazyQuery({
    variables: { filter: { offset: 0 } }
  });
  const { total = 1, limit = TABLE_LIMIT, offset = 0 } =
    data?.billOfMaterial?.meta || {};

  const currentQuery = useRef<PageResponse>({
    pageIndex: 0,
    pageSize: limit,
    sortBy: []
  });

  const [selectedItem, setSelectedItem] = useState<BillOfMaterial | null>(null);

  const {
    register,
    setValue,
    watch,
    reset,
    formState: { isDirty }
  } = useForm({
    defaultValues: {
      searchTerm: urlSearchTerm || ''
    }
  });

  const searchTerm = watch('searchTerm');

  useEffect(() => {
    reset({ searchTerm: urlSearchTerm });
  }, [reset, urlSearchTerm]);

  if (isDirty && urlSearchTerm) {
    history.replace(location.pathname);
  }

  const resetSearch = useCallback(() => {
    setValue('searchTerm', '', { shouldDirty: true });
  }, [setValue]);

  const updateSelectedItem = useCallback(
    (sp, quantity, oldQuantity) => {
      if (quantity > oldQuantity) {
        dispatch(addItem({ sp, quantity }));
      } else {
        dispatch(removeItem({ article: sp, quantity }));
      }
    },
    [dispatch]
  );

  function closeModal() {
    setSelectedItem(null);
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Artikelnummer',
        accessor: 'articleNumber'
      },
      {
        Header: 'Beskrivning',
        accessor: 'description'
      },
      {
        Header: 'Ca.pris(inkl. moms)',
        accessor: 'price',
        Cell: ({ row: { original } }: any) => {
          if (original.discountedPrice) {
            return (
              <div>
                <p className={styles.price__red}>
                  {toSEK(original.discountedPrice * (1 + vat))}
                </p>
                <p className={styles.price__original}>
                  {toSEK(original.price * (1 + vat))}
                </p>
              </div>
            );
          }
          return toSEK(original.price * (1 + vat));
        }
      },
      {
        Header: 'Lagersaldo',
        Cell: ({ row: { original } }: any) => (
          <InStockMarker product={original} approximateStockBalance />
        )
      },
      {
        Header: 'Visa info',
        Cell: ({ row: { original } }: any) => (
          <button
            className={styles.link}
            type="button"
            onClick={() => setSelectedItem(original)}
          >
            Visa
          </button>
        )
      },
      {
        Header: 'Lägg till i varukorg',
        Cell: ({ row, column }: any) => {
          const quantity = useSelector(getSpecificItemCount(row.original.id));
          return (
            <StepperCell
              customFunction={(
                rowIndex: number,
                columnName: number,
                value: number
              ) => updateSelectedItem(row.original, value, quantity)}
              initialValue={quantity}
              row={row}
              column={column}
            />
          );
        }
      }
    ],
    [updateSelectedItem, vat]
  );

  useEffect(() => {
    getBillOfMaterial({
      variables: {
        filter: {
          searchTerm,
          limit,
          offset,
          billOfMaterialType: BillOfMaterialType.SparePart
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBillOfMaterial, searchTerm]);

  const fetchData = useCallback(
    ({ pageIndex, pageSize, sortBy }: PageResponse) => {
      if (pageIndex && pageSize) {
        currentQuery.current = { pageIndex, pageSize, sortBy };
      }
      const size = pageSize || currentQuery.current.pageSize;

      let index = currentQuery.current.pageIndex;
      if (pageIndex !== undefined && pageIndex !== null) {
        index = pageIndex;
      }

      getBillOfMaterial({
        variables: {
          filter: {
            limit: size,
            offset: index * size,
            searchTerm,
            billOfMaterialType: BillOfMaterialType.SparePart
          }
        }
      });
    },
    [getBillOfMaterial, searchTerm]
  );

  return (
    <>
      <Helmet>
        <title>{`Webshop: ${label}`}</title>
      </Helmet>
      <Drawer initial="closed" overlay />
      <section>
        <Container>
          <WebshopBreadcrumb crumbs={crumbs} />
          <div className={classNames(styles.block, styles.whiteBackground)}>
            <div className={styles.inputWrapper}>
              <Text
                bordered
                className={styles.search}
                name="searchTerm"
                prefixIcon={<SpyglassIcon />}
                prefixSize="sm"
                suffixIcon={
                  !!searchTerm && (
                    <Button
                      color="no"
                      className={styles.clear}
                      onClick={resetSearch}
                    >
                      <i className="material-icons">cancel</i>
                    </Button>
                  )
                }
                register={register}
              />
            </div>
            <Table
              columns={columns}
              data={data?.billOfMaterial?.edges || []}
              totalPages={Math.ceil(total / limit)}
              loading={loading}
              fetchData={fetchData}
              filterable
              editable
              showPagination
              empty={
                <Empty
                  absolute
                  icon="list_alt"
                  title="Inga resultat hittades"
                  message="Prova att justera din sökning för att hitta det du letar efter."
                />
              }
            />
          </div>
        </Container>
        {selectedItem && (
          <BillOfMaterialRowModal
            billOfMaterial={selectedItem}
            modalIsOpen={!!selectedItem}
            loading={false}
            closeModal={closeModal}
          />
        )}
      </section>
    </>
  );
};

export default WebshopBillOfMaterial;
