import React, { FC, ReactNode } from 'react';

import classNames from 'classnames';

import styles from './MenuItem.module.scss';

interface MenuItemProps {
  className?: string;
  readonly children: ReactNode;
  onClick?: Function;
  closeOnClick?: boolean;
  menuOnClick?: () => void;
}

const MenuItem: FC<MenuItemProps> = ({
  children,
  className,
  onClick,
  closeOnClick = true,
  menuOnClick
}) => {
  return (
    <button
      type="button"
      className={classNames(styles.root, className)}
      onClick={() => {
        if (onClick) onClick();
        if (closeOnClick && menuOnClick) menuOnClick();
      }}
    >
      {children}
    </button>
  );
};

export default MenuItem;
