import React, { useCallback, useEffect, useMemo, useState } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import noImage from 'assets/images/notFound.png';
import { Link } from 'components/tools';
import { Text } from 'components/ui/forms';
import { Button } from 'components/ui/general';
import InStockMarker from 'components/ui/Webshop/InStockMarker';
import { WebshopCardSelectors } from 'consts/cypress';
import { usePrevious } from 'hooks';
import { addItem, getSpecificItemCount, getVat, removeItem } from 'redux/cart';
import { WebshopProductAmount } from 'routes/Webshop/components';
import { BillOfMaterial, Equipment, StockVehicle } from 'types';
import { toSEK, disableAddRelatedEquipment, productResolvers } from 'utils';
import { isStockVehicle } from 'utils/typeGuards';

import styles from './WebshopCard.module.scss';

interface WebshopCardProps {
  thumbnail?: string;
  name: string;
  sequence: string;
  product: StockVehicle | Equipment | BillOfMaterial;
  relatedVehicleId?: StockVehicle['id'];
  stockVehicleIsSelected?: boolean;
  stockVehicleProductCount?: number;
  isCampaignEquipment?: boolean;
}

const WebshopCard = ({
  thumbnail,
  name,
  sequence,
  product,
  relatedVehicleId,
  stockVehicleIsSelected,
  stockVehicleProductCount,
  isCampaignEquipment = false
}: WebshopCardProps) => {
  const { discountedPrice, price } = product;
  const dispatch = useDispatch();
  const vat = useSelector(getVat);
  const quantity = useSelector(
    getSpecificItemCount({ article: product, relatedVehicleId })
  );
  const [amount, setAmount] = useState<number | undefined>();
  const [updatedAmount, setUpdatedAmount] = useState<number>();
  const previousAmount = usePrevious(amount as number);
  useEffect(() => {
    if (amount) {
      if (amount !== previousAmount && amount !== quantity) {
        setUpdatedAmount(amount);
      }
    }
  }, [amount, previousAmount, quantity]);

  useEffect(() => {
    setAmount(quantity === 0 ? undefined : quantity);
  }, [quantity]);

  useEffect(() => {
    if (typeof amount === 'number') {
      if (amount) {
        dispatch(
          addItem({
            sp: product,
            quantity: amount,
            relatedVehicleId,
            stockVehicleProductCount,
            isCampaignEquipment
          })
        );
      }
      if (!amount) {
        dispatch(
          removeItem({
            article: product,
            relatedVehicleId,
            quantity: 0
          })
        );
      }
    }
  }, [
    amount,
    dispatch,
    isCampaignEquipment,
    product,
    relatedVehicleId,
    stockVehicleProductCount
  ]);

  const shouldBeDisabled = useMemo(() => {
    return disableAddRelatedEquipment(
      stockVehicleIsSelected,
      stockVehicleProductCount,
      amount,
      isCampaignEquipment
    );
  }, [
    amount,
    isCampaignEquipment,
    stockVehicleIsSelected,
    stockVehicleProductCount
  ]);

  const renderPrice = useCallback(() => {
    const options = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    };
    if (discountedPrice || (discountedPrice === 0 && product.vehicleCampaign)) {
      return (
        <>
          <div>
            <span>Rek Ca-pris</span>
          </div>
          <div>
            <p className={styles.priceLineThrough}>
              {toSEK(price * (1 + vat), options)}
            </p>
            <p className={styles.priceDiscount}>
              {toSEK(discountedPrice * (1 + vat), options)}
            </p>
          </div>
        </>
      );
    }

    return (
      <>
        <div>
          <span>Rek Ca-pris</span>
        </div>
        <div>{toSEK(price * (1 + vat), options)}</div>
      </>
    );
  }, [product.vehicleCampaign, discountedPrice, price, vat]);

  const amountOnChange = useCallback(({ target: { value } }) => {
    // Can't order negative values
    if (value <= 0) {
      setAmount(0);
      return;
    }

    // Can't order half products
    setAmount(Math.round(value));
  }, []);

  return (
    <div className={styles.root} data-cy={WebshopCardSelectors.ROOT}>
      <div
        className={styles.thumbnail}
        style={{ backgroundImage: `url(${thumbnail || noImage})` }}
      >
        {!!discountedPrice && (
          <div>
            <div className={styles.discount}>Kampanj</div>
          </div>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.name}>{name}</div>
        <div className={styles.middleTextWrapper}>
          <div className={styles.sequence}>{sequence}</div>
          <div className={styles.price}>{renderPrice()}</div>
        </div>
        <div className={styles.status}>
          <InStockMarker product={product} approximateStockBalance />
        </div>
        {!!amount && amount >= 1 && (
          <div className={styles.amount}>
            <button
              type="button"
              data-cy={WebshopCardSelectors.DECREMENT_BUTTON}
              className={styles.amountDecrease}
              onClick={() => setAmount(amount - 1)}
            >
              <i className="material-icons-outlined">remove</i>
            </button>
            <Text
              dataCy={WebshopCardSelectors.PRODUCT_COUNT_INPUT}
              type="number"
              name="amount"
              min="0"
              step="1"
              value={amount}
              className={styles.amountInput}
              onChange={amountOnChange}
            />
            <button
              data-cy={WebshopCardSelectors.INCREMENT_BUTTON}
              type="button"
              className={classNames(styles.amountIncrease, {
                [styles.disabled]: shouldBeDisabled
              })}
              onClick={() => setAmount(amount + 1)}
              disabled={shouldBeDisabled}
            >
              <i className="material-icons-outlined">add</i>
            </button>
            <WebshopProductAmount amount={updatedAmount} size="sm" />
          </div>
        )}
        {!amount && (
          <div className={styles.button}>
            <Button
              dataCy={WebshopCardSelectors.ADD_ITEM_BUTTON}
              color="alpha"
              fullWidth
              onClick={() => setAmount(1)}
              disabled={
                shouldBeDisabled ||
                (isStockVehicle(product) &&
                  productResolvers.outOfStock(product))
              }
            >
              Lägg till i varukorg
            </Button>
          </div>
        )}
      </div>
      <Link to={productResolvers.getUrl(product)} className={styles.link} />
    </div>
  );
};

export default WebshopCard;
