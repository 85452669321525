import React, { useRef } from 'react';

import { useClickOutside } from 'hooks';

import styles from './Dropdown.module.scss';

interface dropdownItem {
  label: string;
  handleClick: Function;
  className?: string;
}

interface DropdownProps {
  closeDropdown: () => void;
  items: dropdownItem[];
}

const Dropdown = ({ closeDropdown, items }: DropdownProps) => {
  const dropdown = useRef(null);

  useClickOutside(dropdown, () => {
    closeDropdown();
  });

  return (
    <div ref={dropdown} className={styles.root}>
      <div className={styles.dropdown}>
        {items.map(({ label, handleClick, className }) => (
          <button
            type="button"
            onClick={() => handleClick()}
            className={className}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
