import React, { ReactNode } from 'react';

import styles from './RadioGroup.module.scss';

interface RadioGroupProps {
  readonly children: ReactNode;
  label?: string;
  required?: boolean;
}

const RadioGroup = ({ children, label, required }: RadioGroupProps) => {
  return (
    <div className={styles.block}>
      {label && (
        <p className={styles.groupLabel}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </p>
      )}
      {children}
    </div>
  );
};

export default RadioGroup;
