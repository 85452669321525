export const MAX_UPLOAD_FILE_SIZE = 100000000;

export enum AcceptedMimeType {
  TextPlain = 'text/plain',
  ImageJpeg = 'image/jpeg',
  ImagePng = 'image/png',
  ImageHeic = 'image/heic',
  VideoMp4 = 'video/mp4',
  VideoMpeg = 'video/mpeg',
  VideoOgg = 'video/ogg',
  VideoWebm = 'video/webm',
  VideoXMsVideo = 'video/x-msvideo',
  VideoQuicktime = 'video/quicktime',
  ApplicationPdf = 'application/pdf',
  ApplicationVndMsExcel = 'application/vnd.ms-excel',
  ApplicationVndOpenxmlformatsOfficedocumentSpreadsheetmlSheet = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ApplicationVndMsPowerpoint = 'application/vnd.ms-powerpoint',
  ApplicationVndOpenxmlformatsOfficedocumentPresentationmlPresentation = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ApplicationMsword = 'application/msword',
  ApplicationVndOpenxmlformatsOfficedocumentWordprocessingmlDocument = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
}
