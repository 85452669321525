import React, { FC, useCallback, useEffect, useState } from 'react';

import classNames from 'classnames';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import Select from 'react-select';

import { Button } from 'components/ui/general';
import { useFeedback } from 'hooks';
import { setActingAsRetailer } from 'redux/auth';
import { Retailer, useRetailersQuery } from 'types';

import styles from './ActAsRetailerModal.module.scss';

type SelectRetailerToActAsProps = {
  closeModal?: Function;
  currentRetailerId?: number;
};

const SelectRetailerToActAs: FC<SelectRetailerToActAsProps> = ({
  closeModal,
  currentRetailerId
}) => {
  const [selectedRetailerOption, setSelectedRetailerOption] = useState<
    | {
        value: string;
        label: string;
      }
    | undefined
  >();

  const { data, loading, error } = useRetailersQuery({
    variables: { filter: { limit: 1000, offset: 0 }, sorting: null }
  });

  useFeedback(false, !!error, '', 'Kunde inte hämta lista med återförsäljare');

  const handleError = useCallback(() => {
    if (error) {
      setSelectedRetailerOption(undefined);
      closeModal?.();
    }
  }, [error, closeModal, setSelectedRetailerOption]);

  const convertRetailerToOption = (retailer: any) => {
    return {
      value: (retailer.id as number).toString(10),
      label: `${retailer.externalId} - ${retailer.name}`
    };
  };

  const getRetailerOptions = useCallback(() => {
    return data?.retailers?.edges?.map((retailer) =>
      convertRetailerToOption(retailer)
    );
  }, [data]);

  const setPreSelectedOption = useCallback(() => {
    if (!currentRetailerId) {
      setSelectedRetailerOption(undefined);
      return;
    }

    const matchingRetailer = data?.retailers?.edges.find(
      (retailer) => retailer.id === currentRetailerId
    );
    if (matchingRetailer) {
      setSelectedRetailerOption(convertRetailerToOption(matchingRetailer));
    }
  }, [currentRetailerId, data]);

  useEffect(() => {
    if (error) {
      handleError();
      return;
    }

    if (loading) {
      setSelectedRetailerOption({ label: 'Laddar ...', value: '' });
      return;
    }

    setPreSelectedOption();
  }, [loading, error, handleError, setPreSelectedOption]);

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    errors,
    clearErrors
  } = useForm({
    reValidateMode: 'onChange'
  });

  register('actAsRetailerId', { required: true });

  const dispatch = useDispatch();

  const onSubmit = useCallback(() => {
    const selectedRetailer = data?.retailers?.edges.find(
      (retailer) => retailer.id.toString() === getValues('actAsRetailerId')
    );

    dispatch(
      setActingAsRetailer({ actingAsRetailer: selectedRetailer as Retailer })
    );

    closeModal?.();
  }, [data, getValues, dispatch, closeModal]);

  return (
    <form
      className={classNames(styles.form, styles.padVertical, styles.fill)}
      onSubmit={handleSubmit(onSubmit)}
      data-cy="selectActAsRetailer"
    >
      <div className={styles.row}>
        <Select
          isDisabled={loading}
          className={styles.reactSelectContainer}
          classNamePrefix="react-select"
          placeholder="Välj återförsäljare"
          styles={{
            control: (provided, state) =>
              state.selectProps.error
                ? {
                    ...provided,
                    border: '2px solid red'
                  }
                : {
                    ...provided,
                    border: 'none'
                  }
          }}
          onChange={(retailer: any) => {
            setValue('actAsRetailerId', retailer.value);
            clearErrors('actAsRetailerId');
            setSelectedRetailerOption(retailer);
          }}
          error={errors.actAsRetailerId}
          value={selectedRetailerOption}
          name="actAsRetailerId"
          label="Återförsäljare"
          options={getRetailerOptions()}
          components={{ IndicatorSeparator: () => null }}
        />
      </div>
      <div className={classNames(styles.row, styles.endRow)}>
        <Button
          dataCy="setActAsRetailerBtn"
          className={styles.button}
          type="submit"
          color="alpha"
          size="md"
        >
          Spara
        </Button>
      </div>
    </form>
  );
};

export default SelectRetailerToActAs;
