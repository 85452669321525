import React, { FC, ReactNode } from 'react';

import classNames from 'classnames';

import styles from './Box.module.scss';

interface BoxProps {
  children?: ReactNode;
  className?: string;
  footer?: ReactNode;
  header?: ReactNode;
  thin?: boolean;
  overflow?: boolean;
}

const Box: FC<BoxProps> = ({
  children,
  className,
  footer,
  header,
  thin,
  overflow = false
}) => {
  return (
    <div
      className={classNames(
        styles.root,
        { [styles.thin]: thin, [styles.overflow]: overflow },
        className
      )}
    >
      {header && <div className={styles.header}>{header}</div>}
      <div className={styles.body}>{children}</div>
      {footer && <div className={styles.footer}>{footer}</div>}
    </div>
  );
};

export default Box;
