import React, { useCallback } from 'react';

import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import ShoppingListModal from 'components/modals/ShoppingListModal';
import { useFeedback } from 'hooks';
import { getActingAsRetailer } from 'redux/auth';
import {
  ShoppingList,
  ShoppingListItemInput,
  useUpdateShoppingListMutation
} from 'types';

interface WebshopUpdateShoppingList {
  isOpen: boolean;
  closeModal: () => void;
  shoppingList: ShoppingList;
}

const WebshopUpdateShoppingList = ({
  isOpen,
  closeModal,
  shoppingList
}: WebshopUpdateShoppingList) => {
  const [
    updateShoppingList,
    { data, error, loading }
  ] = useUpdateShoppingListMutation();

  const actingAsRetailer = useSelector(getActingAsRetailer);
  const defaultValues = {
    name: shoppingList.name,
    remark: shoppingList.remark || ''
  };

  const { handleSubmit, register } = useForm({ defaultValues });

  const onSubmit = useCallback(
    ({ name, remark }) => {
      const shoppingListItem = shoppingList.items.map(
        (item): ShoppingListItemInput => {
          const id =
            item.billOfMaterial?.id.toString() ??
            item.equipment?.id.toString() ??
            item.sparePart?.id.toString() ??
            item.vehicle?.id.toString() ??
            '';
          return {
            id,
            amount: item.amount,
            isCampaignEquipment: item.isCampaignEquipment,
            stockVehicleProductCount: item.stockVehicleProductCount,
            relatedVehicle: item.relatedVehicle?.id.toString()
          };
        }
      );

      updateShoppingList({
        variables: {
          id: shoppingList.id,
          name,
          remark,
          items: shoppingListItem,
          onBehalfOfRetailerId: actingAsRetailer?.id
        }
      });
    },
    [shoppingList, updateShoppingList, actingAsRetailer]
  );

  useFeedback(
    !!data?.updateShoppingList.id,
    !!error,
    'Inköpslistan uppdaterad',
    'Kunde inte uppdatera inköpslistan'
  );

  return (
    <ShoppingListModal
      isOpen={isOpen}
      closeModal={closeModal}
      handleSubmit={handleSubmit(onSubmit)}
      headerText="Döp om inköpslistan"
      register={register}
      loading={loading}
      submitText="Uppdatera"
    />
  );
};

export default WebshopUpdateShoppingList;
