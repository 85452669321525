import React, { useCallback, useState } from 'react';

import { Empty, Button } from 'components/ui/general';
import { WebshopCampaignCard } from 'routes/Webshop/components';
import { Equipment, StockVehicle, BillOfMaterial, RelatedProduct } from 'types';
import { productResolvers } from 'utils';
import { isBillOfMaterial, isEquipment } from 'utils/typeGuards';

import styles from './WebshopCarousel.module.scss';

interface WebshopCarouselProps {
  relatedArticles: RelatedProduct[];
  parentId?: string;
  parentCount: number;
  isCampaignEquipment?: true;
}

const ARTICLE_WIDTH = 4;

const WebshopCarousel = ({
  relatedArticles,
  parentId,
  parentCount,
  isCampaignEquipment
}: WebshopCarouselProps) => {
  const [pageIndex, setPageIndex] = useState(0);

  const buildWebshopCards = useCallback(
    (relatedItems) => {
      return relatedItems.map((relatedArticle: RelatedProduct) => {
        const product = productResolvers.getArticle(relatedArticle);

        if (isEquipment(product) || isBillOfMaterial(product)) {
          const {
            id,
            thumbnail,
            discountedPrice,
            price,
            stock: { status }
          } = product;
          return (
            <div key={id} className={styles.cardWrapper}>
              <WebshopCampaignCard
                thumbnail={thumbnail?.path}
                discount={discountedPrice}
                name={productResolvers.getName(product)}
                sequence={productResolvers.getSequence(product)}
                price={price}
                status={status}
                product={product as StockVehicle | Equipment | BillOfMaterial}
                relatedVehicleId={parentId}
                stockVehicleIsSelected={parentCount > 0}
                stockVehicleProductCount={parentCount}
                isCampaignEquipment={isCampaignEquipment}
              />
            </div>
          );
        }

        return null;
      });
    },
    [isCampaignEquipment, parentId, parentCount]
  );

  if (!relatedArticles?.length) {
    return (
      <div className="grid__item grid__item--width-12/12 gutter__item">
        <Empty
          className={styles.empty}
          icon="list_alt"
          title="Inget resultat hittades"
          message="Inga produkter hittades"
        />
      </div>
    );
  }

  return (
    <div className={styles.main}>
      {relatedArticles.length <= ARTICLE_WIDTH ? (
        <div className={styles.cards}>{buildWebshopCards(relatedArticles)}</div>
      ) : (
        <>
          <div className={styles.buttonWrapper}>
            <Button
              disabled={pageIndex <= 0}
              className={styles.materialIconsButton}
              onClick={() => setPageIndex(pageIndex - ARTICLE_WIDTH)}
            >
              <i className="material-icons">navigate_before</i>
            </Button>
          </div>
          <div className={styles.cards}>
            {buildWebshopCards(
              relatedArticles.slice(pageIndex, pageIndex + ARTICLE_WIDTH)
            )}
          </div>
          <div className={styles.buttonWrapper}>
            <Button
              disabled={pageIndex + ARTICLE_WIDTH >= relatedArticles.length}
              className={styles.materialIconsButton}
              onClick={() => setPageIndex(pageIndex + ARTICLE_WIDTH)}
            >
              <i className="material-icons">navigate_next</i>
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default WebshopCarousel;
