import React, { useMemo } from 'react';

import { ApolloError } from '@apollo/client';

import { Button } from 'components/ui/general';
import { DealyModal, DealyModalAnchor } from 'components/ui/modals';

import styles from './DownloadModal.module.scss';

type DownloadModalProps = {
  modalIsOpen: boolean;
  closeModal: Function;
  downloadLink?: string;
  loading: boolean;
  error?: ApolloError;
};

export const DownloadModal = ({
  modalIsOpen,
  closeModal,
  downloadLink,
  loading,
  error
}: DownloadModalProps) => {
  const downloadFile = () => {
    if (!downloadLink) return;
    window.location.href = downloadLink;
    closeModal();
  };

  const statusMessage = useMemo(() => {
    if (error) {
      return 'Något gick fel när din fil skulle förberedas';
    }

    if (loading || !downloadLink) {
      return 'Vänta medans filen skapas...';
    }

    return 'Din fil är klar att ladda ner!';
  }, [loading, error, downloadLink]);

  return (
    <DealyModal
      className={styles.modal}
      anchor={DealyModalAnchor.CENTER}
      isOpen={modalIsOpen}
      onRequestClose={() => closeModal()}
    >
      <div className={styles.titleRow}>
        <h4>Ladda ner</h4>
        <Button
          color="clear"
          className={styles.closeButton}
          onClick={() => closeModal()}
        >
          <i className="material-icons">close</i>
        </Button>
      </div>
      <p>{statusMessage}</p>
      <Button
        className={styles.downloadButton}
        disabled={loading || !!error || !downloadLink}
        loading={loading}
        color="alpha"
        size="md"
        onClick={() => downloadFile()}
      >
        Ladda ner
      </Button>
    </DealyModal>
  );
};
