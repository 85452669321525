import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import SearchBar from './components/SearchBar';
import dealyLogo from 'assets/images/dealy_logo@2x.png';
import { Link } from 'components/tools';
import { Button, Container } from 'components/ui/general';
import ActAsRetailerButton from 'components/ui/general/ActAsRetailerButton';
import { WebshopHeaderSelectors } from 'consts/cypress';
import { getLoggedIn, getUser } from 'redux/auth';
import { getCurrentNumberOfItems } from 'redux/cart';
import { toggleMenu } from 'redux/menu';
import { WebshopCartOverlay } from 'routes/Webshop/components';
import {
  WebshopSectionType,
  webshopSectionTypeToText
} from 'routes/Webshop/sections';

import styles from './WebshopHeader.module.scss';

const WebshopHeader = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getLoggedIn);
  const user = useSelector(getUser);
  const numberOfItems = useSelector(getCurrentNumberOfItems);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const name = user?.name || '';

  return (
    <>
      <header className={styles.header}>
        <Container fullWidth>
          <div className={styles.container}>
            <div>
              {isLoggedIn && (
                <Button
                  color="no"
                  className={styles.menuButton}
                  onClick={() => dispatch(toggleMenu())}
                >
                  <i className="material-icons">menu</i>
                </Button>
              )}
            </div>
            <div className={styles.logotype}>
              <Link className={styles.logotypeAnchor} to="/">
                <img src={dealyLogo} alt="Dealy" className={styles.logo} />
              </Link>
            </div>
            <SearchBar />
            <div className={styles.shop}>
              <Link className={styles.orders} to="/webshop">
                <i className="material-icons-outlined">list_alt</i>
              </Link>
              <button
                data-cy={WebshopHeaderSelectors.OPEN_CART_MODAL_BUTTON}
                type="button"
                className={styles.cart}
                onClick={() => setModalIsOpen(true)}
              >
                <span>{numberOfItems}</span>
                <i className="material-icons-outlined">shopping_cart</i>
              </button>
              <WebshopCartOverlay
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
              />
            </div>
            <div>
              <button
                type="button"
                className={styles.user}
                onClick={() => console.log('click')}
              >
                <span>{name}</span>
              </button>
            </div>
          </div>
          <div className={styles.actAsRetailerContainer}>
            <ActAsRetailerButton />
          </div>
        </Container>
      </header>
      <div className={styles.navigation}>
        <Container>
          <nav>
            <Link to="/webshop">Start</Link>
            <Link
              data-cy={WebshopHeaderSelectors.VEHICLE_LINK}
              to={`/webshop/${WebshopSectionType.VEHICLES}`}
            >
              {webshopSectionTypeToText(WebshopSectionType.VEHICLES)}
            </Link>
            <Link
              data-cy={WebshopHeaderSelectors.EQUIPMENT_LINK}
              to={`/webshop/${WebshopSectionType.ACCESSORIES}`}
            >
              {webshopSectionTypeToText(WebshopSectionType.ACCESSORIES)}
            </Link>
            <Link
              data-cy={WebshopHeaderSelectors.SPARE_PART_LINK}
              to={`/webshop/${WebshopSectionType.SPARE_PARTS}`}
            >
              {webshopSectionTypeToText(WebshopSectionType.SPARE_PARTS)}
            </Link>
            <Link
              data-cy={WebshopHeaderSelectors.BILL_OF_MATERIAL_LINK}
              to={`/webshop/${WebshopSectionType.BILL_OF_MATERIAL}`}
            >
              {webshopSectionTypeToText(WebshopSectionType.BILL_OF_MATERIAL)}
            </Link>
            <Link
              data-cy={WebshopHeaderSelectors.MY_ORDERS_LINK}
              to="/my-orders"
            >
              {webshopSectionTypeToText(WebshopSectionType.MY_ORDERS)}
            </Link>
            <Link
              data-cy={WebshopHeaderSelectors.MY_SHOPPING_LISTS}
              to={`/webshop/${WebshopSectionType.SHOPPING_LISTS}`}
            >
              {webshopSectionTypeToText(WebshopSectionType.SHOPPING_LISTS)}
            </Link>
          </nav>
        </Container>
      </div>
    </>
  );
};

export default WebshopHeader;
