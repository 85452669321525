import { OperationGroup } from 'types/graphql';

const getCategoryOptions = (operationGroup: OperationGroup) => {
  const categories = operationGroup.operationCodes.map(
    (operationCode) => operationCode.category
  );
  const uniqueCategories = Array.from(new Set(categories));

  return uniqueCategories.map((uniqueCategory) => {
    return {
      value: uniqueCategory,
      label: uniqueCategory
    };
  });
};

const getCodeOptions = (
  operationGroup: OperationGroup,
  selectedCategory: string
) => {
  return operationGroup.operationCodes
    .filter((operationCode) => operationCode.category === selectedCategory)
    .map((operationCode) => {
      return {
        value: operationCode.id,
        label: `${operationCode.code} - ${operationCode.description}`
      };
    });
};

export const operationGroupToOptions = {
  ...{ getCategoryOptions, getCodeOptions }
};
