import React, { InputHTMLAttributes, ReactNode, useState } from 'react';

import classNames from 'classnames';
import ReactDatePicker from 'react-date-picker';
import { FieldErrors, UseFormMethods, ValidationRules } from 'react-hook-form';

import { Error } from '../index';

import './Calendar.scss';
import styles from './DatePicker.module.scss';

interface DatePickerProps extends InputHTMLAttributes<HTMLInputElement> {
  bordered?: boolean;
  className?: string;
  prefixIcon?: ReactNode;
  prefixSize?: 'lg' | 'md' | 'sm';
  suffixIcon?: ReactNode;
  suffixSize?: 'lg' | 'md' | 'sm';
  label?: string;
  labelSupplement?: string;
  name: string;
  register?: UseFormMethods['register'];
  validation?: ValidationRules;
  error?: FieldErrors;
  required?: boolean;
  control: UseFormMethods['control'];
  defaultValue?: any;
  setFormValue: UseFormMethods['setValue'];
  maxDate?: Date;
  minDate?: Date;
  clearErrors?: UseFormMethods['clearErrors'];
  dataCy?: string;
}

const DatePicker = ({
  className,
  name,
  register,
  validation = {},
  label,
  labelSupplement,
  required,
  setFormValue,
  defaultValue,
  maxDate,
  minDate,
  error,
  clearErrors,
  dataCy = '',
  ...rest
}: DatePickerProps) => {
  const isRequired = required || validation?.required;
  const [value, setValue] = useState<Date | null>(defaultValue);

  const dateValue = typeof value === 'string' ? new Date(value) : value;

  return (
    <div data-cy={dataCy}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
          {labelSupplement && (
            <span className={styles.labelSupplement}>{labelSupplement}</span>
          )}
          {isRequired && <span className={styles.required}>*</span>}
        </label>
      )}

      <ReactDatePicker
        dayPlaceholder="DD"
        monthPlaceholder="MM"
        yearPlaceholder="YYYY"
        format="yyyy-MM-dd"
        clearIcon={null}
        locale="sv"
        calendarClassName={['test']}
        className={classNames(styles.root, className, {
          [styles.error]: !!error
        })}
        name={name}
        onChange={(v: Date) => {
          setValue(v);
          setFormValue(name, v);
          clearErrors?.(name);
        }}
        returnValue="end"
        value={dateValue || (defaultValue && new Date(defaultValue))}
        ref={register ? register(name, validation) : undefined}
        maxDate={maxDate}
        minDate={minDate}
        {...rest}
      />
      <Error error={error} message={error?.message} />
    </div>
  );
};

export default DatePicker;
