import { ComplementReason, MaintenanceRowComplementReason } from 'types';

export const complementReasonToText = (
  reason: ComplementReason | MaintenanceRowComplementReason
) => {
  switch (reason) {
    case ComplementReason.ReturnExchangedMaterial:
      return 'Vänligen returnera utbytt material. Maila mått och vikt på paketet till info@dealy.se för fraktsedel. Ange garantinr för smidigare hantering.';
    case ComplementReason.TakePictureOfDamageMaterial:
    case MaintenanceRowComplementReason.NoDamageImage:
      return 'Ta bilder på skadat material och lägg in i ärendet.';
    case MaintenanceRowComplementReason.NoApproval:
      return 'Saknar godkännande';
    case ComplementReason.Other:
    case MaintenanceRowComplementReason.Other:
      return 'Fritext';
    default:
      return 'Okänd';
  }
};
