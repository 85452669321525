import React, { useCallback } from 'react';

import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import ShoppingListModal from 'components/modals/ShoppingListModal';
import { useFeedback } from 'hooks';
import { getActingAsRetailer } from 'redux/auth';
import { clearCart, getItems } from 'redux/cart';
import { ShoppingListItemInput, useCreateShoppingListMutation } from 'types';

interface WebshopCreateShoppingList {
  isOpen: boolean;
  closeModal: () => void;
  closeCartModal: () => void;
}

const WebshopCreateShoppingList = ({
  isOpen,
  closeModal,
  closeCartModal
}: WebshopCreateShoppingList) => {
  const [
    createShoppingList,
    { data, error, loading }
  ] = useCreateShoppingListMutation();
  const items = useSelector(getItems);
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();
  const actingAsRetailer = useSelector(getActingAsRetailer);

  const onSubmit = useCallback(
    ({ name, remark }) => {
      const shoppingListItem = items.map(
        (item): ShoppingListItemInput => {
          return {
            id: item.item.id.toString(),
            amount: item.quantity,
            isCampaignEquipment: item.isCampaignEquipment,
            stockVehicleProductCount: item.stockVehicleProductCount,
            relatedVehicle: item.relatedVehicleId
          };
        }
      );

      createShoppingList({
        variables: {
          name,
          items: shoppingListItem,
          remark,
          onBehalfOfRetailerId: actingAsRetailer?.id
        }
      }).then(() => {
        dispatch(clearCart());
        closeModal();

        if (closeCartModal) {
          closeCartModal();
        }
      });
    },
    [
      closeCartModal,
      closeModal,
      createShoppingList,
      dispatch,
      items,
      actingAsRetailer
    ]
  );

  useFeedback(
    !!data?.createShoppingList.id,
    !!error,
    'Inköpslista skapad',
    'Kunde inte skapa en inköpslista'
  );

  return (
    <ShoppingListModal
      isOpen={isOpen}
      closeModal={closeModal}
      handleSubmit={handleSubmit(onSubmit)}
      headerText="Skapa en ny inköpslista"
      register={register}
      loading={loading}
      submitText="Spara"
    />
  );
};

export default WebshopCreateShoppingList;
