import React, { useCallback, useMemo } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import noImage from 'assets/images/notFound.png';
import { Link } from 'components/tools';
import { Text } from 'components/ui/forms';
import { Button } from 'components/ui/general';
import InStockMarker from 'components/ui/Webshop/InStockMarker';
import { addItem, getSpecificItemCount, getVat, removeItem } from 'redux/cart';
import { BillOfMaterial, Equipment, StockVehicle } from 'types';
import { toSEK, disableAddRelatedEquipment, productResolvers } from 'utils';

import styles from './WebshopCampaignCard.module.scss';

interface WebshopCardProps {
  thumbnail?: string;
  discount?: number | null;
  name: string;
  sequence: string;
  price: number;
  status: string;
  product: StockVehicle | Equipment | BillOfMaterial;
  relatedVehicleId?: StockVehicle['id'];
  stockVehicleIsSelected?: boolean;
  stockVehicleProductCount?: number;
  isCampaignEquipment?: boolean;
}

const WebshopCampaignCard = ({
  thumbnail,
  discount,
  name,
  sequence,
  price,
  product,
  relatedVehicleId,
  stockVehicleIsSelected,
  stockVehicleProductCount,
  isCampaignEquipment = false
}: WebshopCardProps) => {
  const { getUrl } = productResolvers;
  const dispatch = useDispatch();
  const vat = useSelector(getVat);
  const quantity = useSelector(
    getSpecificItemCount({ article: product, relatedVehicleId })
  );

  const handleChangeAmount = useCallback(
    (newValue: Number) => {
      if (typeof newValue === 'number') {
        if (!newValue) {
          dispatch(
            removeItem({
              article: product,
              relatedVehicleId,
              quantity: 0
            })
          );
          return;
        }

        dispatch(
          addItem({
            sp: product,
            quantity: newValue,
            relatedVehicleId,
            stockVehicleProductCount,
            isCampaignEquipment
          })
        );
      }
    },
    [
      dispatch,
      isCampaignEquipment,
      product,
      relatedVehicleId,
      stockVehicleProductCount
    ]
  );

  const shouldBeDisabled = useMemo(() => {
    return disableAddRelatedEquipment(
      stockVehicleIsSelected,
      stockVehicleProductCount,
      quantity,
      isCampaignEquipment
    );
  }, [
    quantity,
    isCampaignEquipment,
    stockVehicleIsSelected,
    stockVehicleProductCount
  ]);

  const renderPrice = useCallback(() => {
    const options = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    };

    if (discount || (discount === 0 && product.vehicleCampaign)) {
      return (
        <>
          <div>
            <span>Rek Ca-pris</span>
          </div>
          <div>
            <span className={styles.priceDiscount}>
              {toSEK(discount * (1 + vat), options)}
            </span>
            <span className={styles.priceLineThrough}>
              {toSEK(price * (1 + vat), options)}
            </span>
          </div>
        </>
      );
    }

    return (
      <>
        <div>
          <span>Rek Ca-pris</span>
        </div>
        <div>{toSEK(price * (1 + vat), options)}</div>
      </>
    );
  }, [product.vehicleCampaign, discount, price, vat]);

  // Sanitizes input from text-component then passes value to handleChangeAmount
  const handleTextInput = useCallback(
    (event) => {
      if (event.target.value === '') return;
      const newValue = parseInt(event.target.value, 10);

      // If campaignItem, Can't order more than associated vehicle amount
      if (
        isCampaignEquipment &&
        stockVehicleProductCount &&
        newValue > stockVehicleProductCount
      ) {
        handleChangeAmount(stockVehicleProductCount);
        return;
      }
      // Can't order negative values
      if (newValue <= 0) {
        handleChangeAmount(0);
        return;
      }

      handleChangeAmount(newValue);
    },
    [isCampaignEquipment, stockVehicleProductCount, handleChangeAmount]
  );

  return (
    <div className={styles.root} data-cy="webshopCard">
      <div
        className={styles.thumbnail}
        style={{ backgroundImage: `url(${thumbnail || noImage})` }}
      >
        {typeof discount === 'number' && (
          <div>
            <div className={styles.discount}>Kampanj</div>
          </div>
        )}
      </div>
      <div className={styles.content}>
        <div className={styles.name}>{name}</div>
        <div className={styles.sequence}>{sequence}</div>
        <div className={styles.price}>{renderPrice()}</div>
        <div className={styles.status}>
          <InStockMarker product={product} approximateStockBalance />
        </div>
        {!!quantity && quantity >= 1 && (
          <div className={styles.amount}>
            <button
              type="button"
              className={styles.amountDecrease}
              onClick={() => handleChangeAmount(quantity - 1)}
            >
              <i className="material-icons-outlined">remove</i>
            </button>
            <Text
              type="number"
              name="amount"
              min="0"
              step="1"
              value={quantity}
              className={styles.amountInput}
              onChange={handleTextInput}
            />
            <button
              type="button"
              className={classNames(styles.amountIncrease, {
                [styles.disabled]: shouldBeDisabled
              })}
              onClick={() => handleChangeAmount(quantity + 1)}
              disabled={shouldBeDisabled}
            >
              <i className="material-icons-outlined">add</i>
            </button>
          </div>
        )}
        {!quantity && (
          <div className={styles.button}>
            <Button
              dataCy="addItemToCart"
              color="alpha"
              fullWidth
              onClick={() => handleChangeAmount(1)}
              disabled={shouldBeDisabled}
            >
              Lägg till i varukorg
            </Button>
          </div>
        )}
      </div>
      <Link to={getUrl(product)} className={styles.link} />
    </div>
  );
};

export default WebshopCampaignCard;
