import React, { FC } from 'react';

import { BillOfMaterialRow } from 'types';

import styles from './BillOfMaterialRowCard.module.scss';

interface ArticleCardProps {
  BillOfMaterialRowParam: BillOfMaterialRow;
}

const BillOfMaterialRowCard: FC<ArticleCardProps> = ({
  BillOfMaterialRowParam
}) => {
  let item = null;
  let description = null;

  if (BillOfMaterialRowParam.sparePart) {
    item = BillOfMaterialRowParam.sparePart;
    description = BillOfMaterialRowParam.sparePart.name;
  }

  if (BillOfMaterialRowParam.equipment) {
    item = BillOfMaterialRowParam.equipment;
    description = BillOfMaterialRowParam.equipment.name;
  }

  if (BillOfMaterialRowParam.stockVehicle) {
    item = BillOfMaterialRowParam.stockVehicle;
    description = BillOfMaterialRowParam.stockVehicle.name;
  }

  return (
    <div className={styles.wrapper} key={item?.id ?? BillOfMaterialRowParam.id}>
      <div className={styles.row}>
        <div className={styles.category}>
          <span className={styles.bold}>Artikelnummer</span>
        </div>
        <span className={styles.info}>{item?.articleNumber}</span>
      </div>
      <div className={styles.row}>
        <div className={styles.category}>
          <span className={styles.bold}>Beskrivning</span>
        </div>
        <span className={styles.info}>{description}</span>
      </div>
      <div className={styles.row}>
        <div className={styles.category}>
          <span className={styles.bold}>Länk kvantitet</span>
        </div>
        <span className={styles.info}>
          {BillOfMaterialRowParam?.linkQuantity}
        </span>
      </div>
      <hr className={styles.line} />
    </div>
  );
};

export default BillOfMaterialRowCard;
