import React, { useState, useRef, useMemo } from 'react';

import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import SearchResult from '../SearchResult';
import { SpyglassIcon } from 'assets/icons';
import { Link } from 'components/tools';
import { Select, Text } from 'components/ui/forms';
import { Spinner } from 'components/ui/general';
import { SearchBarSelectors } from 'consts/cypress';
import { MIN_SEARCH_LENGTH, SEARCH_BAR_LIMIT } from 'consts/webshop';
import { useClickOutside } from 'hooks';
import { impersonatedRetailerId } from 'redux/auth';
import {
  useArticleSearchQuery,
  ProductIdentifier,
  Article,
  BillOfMaterialType
} from 'types';

import styles from './SearchBar.module.scss';

const SearchBar = () => {
  const actAsRetailerId = useSelector(impersonatedRetailerId);
  const searchRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { register, watch } = useForm({
    defaultValues: { searchTerm: '', section: ProductIdentifier.Equipment }
  });
  const { searchTerm, section } = watch(['searchTerm', 'section']);

  const filter = useMemo(() => {
    const baseFilter = {
      searchTerm,
      limit: SEARCH_BAR_LIMIT,
      actAsRetailerId
    };

    if (!section) return baseFilter;

    if (section === ProductIdentifier.Equipment) {
      return {
        ...baseFilter,
        articleType: [
          ProductIdentifier.Equipment,
          ProductIdentifier.BillOfMaterial
        ],
        billOfMaterialType: BillOfMaterialType.Equipment
      };
    }

    return { ...baseFilter, articleType: [section] };
  }, [section, actAsRetailerId, searchTerm]);

  const { data, loading } = useArticleSearchQuery({
    skip: searchTerm.length < MIN_SEARCH_LENGTH,
    variables: { filter }
  });
  const searchResults = data?.articleSearch.edges as Article[];

  useClickOutside(searchRef, () => {
    setIsOpen(false);
  });

  const suffixIcon = () => {
    if (loading) {
      return <Spinner className={styles.spinner} visible={loading} />;
    }
    if (searchResults?.length && searchTerm.length >= MIN_SEARCH_LENGTH) {
      return <SpyglassIcon className={styles.spyglassFound} />;
    }

    return <SpyglassIcon />;
  };

  return (
    <div
      ref={searchRef}
      className={styles.searchBar}
      onFocus={() => setIsOpen(true)}
    >
      <Select
        dataCy={SearchBarSelectors.CATEGORY_SELECT}
        className={styles.select}
        register={register}
        dynamic
        name="section"
        options={[
          { label: 'Fordon', value: ProductIdentifier.StockVehicle },
          { label: 'Tillbehör', value: ProductIdentifier.Equipment },
          {
            label: 'Reservdelar',
            value: ProductIdentifier.SparePart
          }
          // TODO: uncomment when we have BE support
          // {
          //   label: 'Paket',
          //   value: ProductIdentifier.BillOfMaterial
          // }
        ]}
      />
      <Text
        dataCy={SearchBarSelectors.SEARCH_INPUT}
        bordered
        className={styles.search}
        name="searchTerm"
        suffixIcon={suffixIcon()}
        suffixSize="sm"
        register={register}
      />
      {!loading && searchTerm.length >= MIN_SEARCH_LENGTH && isOpen && (
        <div className={styles.searchResults}>
          {searchResults &&
            searchResults.map((article) => (
              <SearchResult {...{ article, setIsOpen }} />
            ))}
          <div className={styles.linkWrapper}>
            {searchResults?.length ? (
              <Link
                onClick={() => setIsOpen(false)}
                className={styles.link}
                to={`/webshop/search?search=${searchTerm}&section=${section}`}
              >
                Visa alla resultat
              </Link>
            ) : (
              <p className={styles.notFound}>Inga sökresultat</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
