import React, { FC, useCallback, useEffect, useMemo } from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';

import { getAvailableLinks } from './roleMappings';
import { Link } from 'components/tools';
import { DealyModal, DealyModalAnchor } from 'components/ui/modals';
import { UserRoleStrings } from 'consts/enums';
import { getUser, hasConsignment, hasExibition, hasMaintenance, logoutStart } from 'redux/auth';
import { closeMenu, openMenu, setOverlay, menuOpen } from 'redux/menu';

import styles from './Drawer.module.scss';

interface DrawerProps {
  initial?: 'open' | 'closed';
  overlay?: boolean;
}

const Drawer: FC<DrawerProps> = ({ initial, overlay = false }) => {
  const user = useSelector(getUser);
  const { pathname } = useLocation();
  const match = useRouteMatch('/webshop');
  const dispatch = useDispatch();
  const showConsignment = useSelector(hasConsignment);
  const showExhibition = useSelector(hasExibition);
  const showMaintenance = useSelector(hasMaintenance);
  const menuIsOpen = useSelector(menuOpen);

  const navigationLinks = useMemo(
    getAvailableLinks(user?.role, showConsignment, showExhibition, showMaintenance),
    [user?.role]
  );

  const settingsLinks = useMemo(
    () => [
      {
        to: '/settings',
        children: 'Inställningar'
      },
      {
        to: '/terms-and-conditions',
        children: 'Allmänna villkor'
      },
      {
        to: '/',
        children: 'Logga ut',
        onClick: () => dispatch(logoutStart())
      }
    ],
    [dispatch]
  );

  useEffect(() => {
    switch (initial) {
      case 'open':
        document.body.classList.add('menuInstant');
        dispatch(openMenu());
        break;
      case 'closed':
        document.body.classList.add('menuInstant');
        dispatch(closeMenu());
        break;
      default:
    }

    dispatch(setOverlay({ overlay: !!overlay }));
    requestAnimationFrame(() => {
      document.body.classList.remove('menuInstant');
    });
  }, [dispatch, initial, overlay]);

  const getContent = useCallback(() => {
    return (
      <>
        <div className={styles.userWrapper}>
          <div className={styles.userInitial}>
            <p>{user?.name?.substr(0, 1)}</p>
          </div>
          <p className={styles.name}>{user?.name}</p>
          {user?.role && (
            <p className={styles.role}>{UserRoleStrings.get(user?.role)}</p>
          )}
        </div>
        <div className={styles.navigation}>
          <nav className={styles.nav}>
            {navigationLinks.map(({ to, children }) => (
              <div
                key={to}
                className={classNames(styles.navItem, {
                  [styles.active]: pathname === to
                })}
              >
                <Link
                  data-cy={`drawerLinkTo${to}`}
                  to={to}
                  className={styles.link}
                  activeClassName={styles.navLinkActive}
                  showIcon
                >
                  {children}
                </Link>
              </div>
            ))}
          </nav>
        </div>
        <div className={styles.settingsWrapper}>
          <nav className={styles.nav}>
            {settingsLinks.map(({ to, onClick, children }) => (
              <div
                key={to}
                className={classNames(styles.navItem, {
                  [styles.active]: pathname === to
                })}
              >
                <Link
                  onClick={onClick}
                  to={to}
                  className={styles.navLink}
                  activeClassName={styles.navLinkActive}
                >
                  {children}
                </Link>
              </div>
            ))}
          </nav>
        </div>
      </>
    );
  }, [navigationLinks, pathname, settingsLinks, user]);

  if (!overlay) {
    return (
      <aside
        className={classNames(styles.root, {
          [styles.shop]: match
        })}
      >
        {getContent()}
      </aside>
    );
  }

  return (
    <DealyModal
      anchor={DealyModalAnchor.LEFT}
      isOpen={menuIsOpen}
      onRequestClose={() => dispatch(closeMenu())}
      className={styles.modal}
    >
      <div className={styles.modalTop}>
        <h3 className={styles.modalHeading}>Meny</h3>
        <button
          type="button"
          className={styles.modalClose}
          onClick={() => dispatch(closeMenu())}
        >
          <i className="material-icons">close</i>
        </button>
      </div>
      {getContent()}
    </DealyModal>
  );
};

export default Drawer;
