import React, { FC, Fragment } from 'react';

import { Link } from 'components/tools';

import styles from './WebshopBreadcrumb.module.scss';

interface WebshopBreadcrumbProps {
  crumbs?: Crumb[];
}

export type Crumb = {
  id?: string;
  label: string;
};

const WebshopBreadcrumb: FC<WebshopBreadcrumbProps> = ({ crumbs }) => {
  return (
    <>
      <div className={styles.root}>
        <Link to="/webshop">Start</Link>
        {crumbs?.map((crumb) => (
          <Fragment key={`crumb-${crumb.id}`}>
            <i className="material-icons">arrow_forward_ios</i>
            <Link to={`/webshop/${crumb.id}`}>{crumb.label}</Link>
          </Fragment>
        ))}
      </div>
    </>
  );
};

export default WebshopBreadcrumb;
