import React, { FC } from 'react';

import classNames from 'classnames';

import { Button } from 'components/ui/general';
import { DealyModal, DealyModalAnchor } from 'components/ui/modals';

import styles from './DeleteModal.module.scss';

interface DeleteModalProps {
  modalIsOpen: boolean;
  closeModal: Function;
  callBack: Function;
  message: string;
}

export const DeleteModal: FC<DeleteModalProps> = ({
  modalIsOpen,
  closeModal,
  callBack,
  message
}) => {
  return (
    <DealyModal
      className={classNames(styles.modal, styles.removeModal)}
      anchor={DealyModalAnchor.CENTER}
      isOpen={modalIsOpen}
      onRequestClose={() => closeModal()}
    >
      <div>
        <div className={classNames(styles.row, styles.titleRow)}>
          <h4>Ta bort</h4>
          <Button
            color="clear"
            className={styles.closeButton}
            onClick={() => closeModal()}
          >
            <i className="material-icons">close</i>
          </Button>
        </div>
        <div className={classNames(styles.row)}>{message}</div>
        <div className={classNames(styles.row, styles.endRow)}>
          <Button
            className={styles.button}
            color="epsilon"
            size="md"
            onClick={() => closeModal()}
          >
            Avbryt
          </Button>
          <Button
            dataCy="confirmRemovalBtn"
            className={styles.button}
            type="submit"
            color="fi"
            size="md"
            onClick={() => {
              callBack();
              closeModal();
            }}
          >
            Ta bort
          </Button>
        </div>
      </div>
    </DealyModal>
  );
};
