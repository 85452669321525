import React from 'react';

import classNames from 'classnames';

import {
  ChevronDuoLeftIcon,
  ChevronDuoRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon
} from 'assets/icons';
import { Text } from 'components/ui/forms';
import { PaginationSelectors } from 'consts/cypress';

import styles from './TablePagination.module.scss';

type Props = {
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageCount: number;
  gotoPage: (index: number) => void;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (value: number) => void;
  state: { pageIndex: number; pageSize: number };
  className?: string;
};

const TablePagination = ({
  canPreviousPage,
  canNextPage,
  pageCount,
  gotoPage,
  nextPage,
  previousPage,
  setPageSize,
  state: { pageIndex, pageSize },
  className
}: Props) => {
  return (
    <div className={classNames(styles.root, className)}>
      <div className={styles.column}>
        <span>Visar</span>
        <select
          className={styles.select}
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 40, 50, 100].map((size) => (
            <option key={pageSize + size} value={size}>
              {size}
            </option>
          ))}
        </select>
        <span>per sida</span>
      </div>
      <div className={styles.column}>
        <button
          onClick={() => gotoPage(0)}
          type="button"
          disabled={!canPreviousPage}
        >
          <ChevronDuoLeftIcon />
        </button>
        <button
          onClick={() => previousPage()}
          type="button"
          disabled={!canPreviousPage}
        >
          <ChevronLeftIcon />
        </button>
        <span
          data-cy={PaginationSelectors.PAGE_OF_PAGE_TEXT}
          className={styles.currentPage}
        >
          Sida {pageIndex + 1} av {pageCount}
        </span>
        <button
          data-cy={PaginationSelectors.NEXT_PAGE_BUTTON}
          onClick={() => nextPage()}
          type="button"
          disabled={!canNextPage}
        >
          <ChevronRightIcon />
        </button>
        <button
          onClick={() => gotoPage(pageCount - 1)}
          type="button"
          disabled={!canNextPage}
        >
          <ChevronDuoRightIcon />
        </button>
        <div className={styles.jumpToPage}>
          <span>Gå till sida: </span>
          <Text
            bordered
            className={styles.pageInput}
            type="number"
            defaultValue={`${pageIndex + 1}`}
            onChange={(e) => {
              const pick = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(pick);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default TablePagination;
