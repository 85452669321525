import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ofType, Epic, combineEpics } from 'redux-observable';
import { of, from } from 'rxjs';
import { switchMap, map, catchError, takeUntil, mapTo } from 'rxjs/operators';

import { apolloClient } from 'api/apollo';
import { setLocation } from 'redux/router';
import { LoginDocument, MutationLoginArgs, Retailer, User } from 'types';
import { isDealyUser, isRetailerUser } from 'utils/userUtils';

interface Action {
  readonly type: string;
  payload?: any;
}

interface AuthState {
  token?: string | null;
  user?: User | null;
  actAsRetailerRight?: boolean | null;
  actingAsRetailer?: Retailer | null;
  loading: boolean;
  error: boolean;
}

export interface AuthRootState {
  auth: AuthState;
}

// Selectors
export const getUser = ({ auth }: AuthRootState) => auth.user as User;
export const getUserRole = ({ auth }: AuthRootState) => auth.user?.role;
export const getIsDealyUser = ({ auth }: AuthRootState) =>
  isDealyUser(auth.user?.role);
export const getIsRetailerUser = ({ auth }: AuthRootState) =>
  isRetailerUser(auth.user?.role);
export const getActAsRetailerRight = ({ auth }: AuthRootState) =>
  auth.actAsRetailerRight;
export const getActingAsRetailer = ({ auth }: AuthRootState) =>
  auth.actingAsRetailer;
export const isMe = (user?: User | null) => ({ auth }: AuthRootState) =>
  auth.user?.id === user?.id;
export const getToken = ({ auth }: AuthRootState) => auth.token;
export const getUserRetailer = ({ auth }: AuthRootState) => auth.user?.retailer;

export const getLoginError = ({ auth }: AuthRootState) => auth.error;

export const retailerName = createSelector(
  [getUser, getActingAsRetailer],
  (user, impersonatedRetailer) => {
    return impersonatedRetailer?.name || user?.retailer?.name;
  }
);

export const impersonatedRetailerId = ({ auth }: AuthRootState) =>
  auth.actingAsRetailer?.id || null;

export const hasConsignment = createSelector(
  [getUser, getActAsRetailerRight],
  (user, actAsRetailerRight) =>
    user &&
    (!!user?.retailer?.hasConsignmentVehicles || isDealyUser(user.role)) &&
    !actAsRetailerRight
);
export const hasExibition = createSelector(
  [getUser, getActAsRetailerRight],
  (user, actAsRetailerRight) =>
    user &&
    (!!user?.retailer?.hasExhibitionVehicles || isDealyUser(user.role)) &&
    !actAsRetailerRight
);
export const hasMaintenance = createSelector(
  [getUser, getActAsRetailerRight],
  (user, actAsRetailerRight) =>
    user &&
    (!!user?.retailer?.hasMaintenanceCustomers || isDealyUser(user.role)) &&
    !actAsRetailerRight
);

export const selectActive = createSelector([getUser], (user) => user);
export const getLoggedIn = createSelector([getToken], (token) => !!token);

// Reducers
const initialState: AuthState = {
  token: null,
  user: null,
  actAsRetailerRight: null,
  actingAsRetailer: null,
  loading: false,
  error: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginStart: (state, action: PayloadAction<MutationLoginArgs>) => {
      state.token = null;
      state.user = null;
      state.actAsRetailerRight = null;
      state.actingAsRetailer = null;
      state.loading = true;
      state.error = false;
    },
    loginSuccess: (
      state,
      action: PayloadAction<{
        token: string;
        user: User;
        actAsRetailerRight: boolean;
      }>
    ) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.actAsRetailerRight = action.payload.actAsRetailerRight;
      state.actingAsRetailer = null;
      state.loading = false;
      state.error = false;
    },
    loginError: (state) => {
      state.token = null;
      state.user = null;
      state.actAsRetailerRight = null;
      state.actingAsRetailer = null;
      state.loading = false;
      state.error = true;
    },
    logoutStart: (state) => {
      state.loading = true;
    },
    logoutSuccess: (state) => {
      state.token = null;
      state.user = null;
      state.actAsRetailerRight = null;
      state.actingAsRetailer = null;
      state.loading = false;
      state.error = false;
    },
    setUser: (state, action: PayloadAction<{ user: User }>) => {
      state.user = action.payload.user;
    },
    setActAsRetailerRight: (
      state,
      action: PayloadAction<{ actAsRetailerRight: boolean }>
    ) => {
      state.actAsRetailerRight = action.payload.actAsRetailerRight;
    },
    setActingAsRetailer: (
      state,
      action: PayloadAction<{ actingAsRetailer: Retailer }>
    ) => {
      state.actingAsRetailer = action.payload.actingAsRetailer;
    }
  }
});

export default authSlice.reducer;

// Actions
export const {
  loginStart,
  loginSuccess,
  loginError,
  logoutStart,
  logoutSuccess,
  setUser,
  setActAsRetailerRight,
  setActingAsRetailer
} = authSlice.actions;

// Epics
const loginEpic: Epic<Action, Action, AuthState> = (action$) =>
  action$.pipe(
    ofType(loginStart.type),
    switchMap(({ payload: { email, password } }) =>
      from(
        apolloClient.mutate({
          mutation: LoginDocument,
          variables: { email, password }
        })
      ).pipe(
        map((response) =>
          loginSuccess({
            token: response.data.login.token,
            user: response.data.login.user,
            actAsRetailerRight: response.data.login.actAsRetailerRight
          })
        ),
        takeUntil(action$.pipe(ofType(setLocation.type))),
        catchError(() => of(loginError()))
      )
    )
  );

const logoutEpic: Epic<Action, Action, AuthState> = (action$) =>
  action$.pipe(ofType(logoutStart.type), mapTo(logoutSuccess()));

export const authEpics = combineEpics(loginEpic, logoutEpic);
