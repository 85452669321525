import { UserRole } from 'types';

const UserRoleStrings = new Map<UserRole, string>([
  [UserRole.DealyAdmin, 'Kundsupport'],
  [UserRole.DealySeller, 'Dealysäljare'],
  [UserRole.RetailerAdmin, 'Admin'],
  [UserRole.RetailerSeller, 'Försäljning'],
  [UserRole.RetailerUser, 'Superuser'],
  [UserRole.RetailerWorkshop, 'Eftermarknad'],
  [UserRole.SuperAdmin, 'Superadmin'],
  [UserRole.InsuranceUser, 'Försäkringsanvändare']
]);

export { UserRoleStrings };
