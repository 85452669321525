import React, { FC, useEffect, useState } from 'react';

import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import styles from './WebshopProductAmount.module.scss';

interface WebshopProductAmountProps {
  amount?: number;
  size?: 'sm' | 'md';
}

const WebshopProductAmount: FC<WebshopProductAmountProps> = ({
  amount,
  size = 'md'
}) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    setShouldRender(!!amount);
  }, [amount]);

  useEffect(() => {
    if (amount) {
      const timer = setTimeout(() => setShouldRender(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [amount]);

  return (
    <AnimatePresence>
      {shouldRender && (
        <motion.div
          className={classNames(styles.root, {
            [styles[`${size}Size`]]: size
          })}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ type: 'tween' }}
        >
          {amount} st i varukorgen
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default WebshopProductAmount;
