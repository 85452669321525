import React, { InputHTMLAttributes, useMemo } from 'react';

import classNames from 'classnames';
import { ValidationRules, UseFormMethods, FieldErrors } from 'react-hook-form';

import { Error } from '../index';

import styles from './TextArea.module.scss';

interface TextProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  bordered?: boolean;
  label?: string;
  name?: string;
  register?: UseFormMethods['register'];
  required?: boolean;
  validation?: ValidationRules;
  error?: FieldErrors;
  dataCy?: string;
}

const TextArea = ({
  bordered = false,
  className,
  placeholder,
  label,
  name,
  defaultValue,
  value,
  register,
  required,
  error,
  validation = {},
  dataCy = '',
  ...rest
}: TextProps) => {
  const isRequired = required || validation?.required;
  const input = useMemo(
    () => (
      <textarea
        data-cy={dataCy}
        ref={register ? register({ ...validation }) : undefined}
        placeholder={placeholder}
        aria-label={label}
        className={classNames(
          styles.root,
          {
            [styles.bordered]: bordered,
            [styles.error]: !!error
          },
          className
        )}
        defaultValue={defaultValue}
        name={name}
        value={value}
        {...rest}
      />
    ),
    [
      register,
      validation,
      placeholder,
      label,
      bordered,
      error,
      className,
      defaultValue,
      name,
      value,
      rest,
      dataCy
    ]
  );

  return (
    <div className={classNames(styles.block, className)}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
          {isRequired && <span className={styles.required}>*</span>}
        </label>
      )}
      {input}
      <Error error={error} message={error?.message} />
    </div>
  );
};

export default TextArea;
