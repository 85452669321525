import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { Portal } from 'components/tools';
import { Button } from 'components/ui/general';
import { GdprConsentSelectors } from 'consts/cypress';
import { Consents } from 'consts/gdpr';
import { selectMadeDecision, setConsent } from 'redux/gdpr';

import styles from './GdprConsent.module.scss';

export const GdprConsent = () => {
  const dispatch = useDispatch();
  const selectorMadeDecision = useSelector(selectMadeDecision);

  if (!selectorMadeDecision) {
    return (
      <Portal>
        <div className={styles.root} data-cy={GdprConsentSelectors.ROOT}>
          <h6>Information om cookies</h6>
          <p>
            För att ge dig den bästa användarupplevelse av vår tjänst lagrar vi
            information. Genom att klicka &quot;Tillåt alla&quot; godkänner du
            att vi sparar data för dessa syften.
          </p>
          <Button
            dataCy={GdprConsentSelectors.ALLOW_ALL}
            onClick={() => dispatch(setConsent([Consents.All]))}
            color="alpha"
          >
            Tillåt alla
          </Button>
        </div>
      </Portal>
    );
  }

  return null;
};
