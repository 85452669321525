import { UserRoleStrings } from '../consts/enums';
import { UserRole } from 'types';

export const isDealyUser = (role?: UserRole) =>
  role === UserRole.SuperAdmin ||
  role === UserRole.DealyAdmin ||
  role === UserRole.DealySeller;

export const isRetailerUser = (role?: UserRole) =>
  role === UserRole.RetailerAdmin ||
  role === UserRole.RetailerSeller ||
  role === UserRole.RetailerUser ||
  role === UserRole.RetailerWorkshop;

export const getRoleFromString = (roleString: string) => {
  const foundRole = Array.from(UserRoleStrings).find(
    (element) => element[1] === roleString
  );
  return foundRole ? foundRole[0] : '';
};

export const getRoleText = (role: UserRole) => {
  return UserRoleStrings.get(role);
};
