import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { validate as isUUID } from 'uuid';

import { impersonatedRetailerId } from 'redux/auth';
import { selectFilter } from 'redux/webshopFilter';
import { selectSortingAccessories } from 'redux/webshopSorting';
import { WebshopSection } from 'routes/Webshop/components';
import { WebshopSectionType } from 'routes/Webshop/sections';
import {
  useArticleSearchQuery,
  ProductIdentifier,
  BillOfMaterialType,
  StockStatus
} from 'types';
import { buildQueryFilter } from 'utils';

const WebshopEquipment = () => {
  const allFilters = useSelector(selectFilter);
  const storedFilter = allFilters[WebshopSectionType.ACCESSORIES];
  const sorting = useSelector(selectSortingAccessories);
  const actAsRetailerId = useSelector(impersonatedRetailerId);

  const filter = useMemo(() => {
    if (!storedFilter) return;

    const makeFilter = storedFilter.brand
      .filter(({ checked }) => checked)
      .map((brandFilter) =>
        isUUID(brandFilter.name)
          ? { uuid: brandFilter.name }
          : { custom: brandFilter.name }
      );

    return {
      stockBalance: buildQueryFilter(storedFilter.stock) as StockStatus[],
      makes: makeFilter,
      equipmentGroups: buildQueryFilter(storedFilter.equipmentGroup),
      actAsRetailerId,
      articleType: [
        ProductIdentifier.Equipment,
        ProductIdentifier.BillOfMaterial
      ],
      billOfMaterialType: BillOfMaterialType.Equipment
    };
  }, [storedFilter, actAsRetailerId]);

  const translatedSorting = useMemo(() => {
    return sorting;
  }, [sorting]);

  return (
    <WebshopSection
      section={WebshopSectionType.ACCESSORIES}
      filter={filter}
      sorting={translatedSorting}
      query={useArticleSearchQuery}
    />
  );
};

export default WebshopEquipment;
