import React, {
  forwardRef,
  ReactNode,
  TableHTMLAttributes,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from 'react';

import classNames from 'classnames';
import {
  usePagination,
  useTable,
  useSortBy,
  useRowSelect,
  useGlobalFilter,
  useRowState,
  useExpanded
} from 'react-table';

import { Spinner } from '../general';
import TablePagination from './components/TablePagination';
import { TableSelectors } from 'consts/cypress';
import { TABLE_LIMIT } from 'consts/defaults';

import styles from './Table.module.scss';

interface Row {
  getRowProps: () => JSX.IntrinsicAttributes &
    React.ClassAttributes<HTMLTableRowElement> &
    React.HTMLAttributes<HTMLTableRowElement>;
  cells: any[];
  isExpanded: boolean;
}

interface TableProps extends TableHTMLAttributes<HTMLTableElement> {
  className?: string;
  columns: any[];
  data: any[];
  empty?: ReactNode;
  fetchData?: Function;
  refetchTriggers?: any[];
  loading: boolean;
  totalPages?: number;
  manualSorting?: boolean;
  selectableRows?: boolean;
  sticky?: boolean;
  borderTop?: boolean;
  hooks?: any;
  paginated?: boolean;
  paginationClassName?: string;
  customFunction?: Function;
  autoResetPage?: boolean;
  setSelectedRows?: Function;
  showHeaders?: boolean;
  filterable?: boolean;
  defaultFilterMethod?: Function;
  showPagination?: boolean;
  editable?: boolean;
  renderExpandedRow?: (row: Row) => any;
  initialState?: {
    pageIndex?: number;
    pageSize?: number;
    selectedRowIds?: any;
  };
}

const Table = forwardRef(
  (
    {
      columns,
      className,
      data,
      empty,
      fetchData,
      refetchTriggers = [],
      loading,
      totalPages,
      manualSorting,
      hooks,
      selectableRows,
      sticky,
      borderTop,
      showHeaders = true,
      paginated = true,
      paginationClassName,
      customFunction,
      autoResetPage = true,
      setSelectedRows,
      filterable,
      showPagination,
      editable,
      renderExpandedRow,
      initialState,
      ...rest
    }: TableProps,
    ref
  ) => {
    const instance = useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: TABLE_LIMIT, ...initialState },
        manualPagination: !!paginated,
        manualSortBy: !!manualSorting,
        pageCount: totalPages,
        autoResetPage,
        customFunction
      },
      // Conditionally adding plugins
      ...(manualSorting ? [useSortBy] : []),
      ...(filterable ? [useGlobalFilter] : []),
      ...(paginated ? [usePagination] : []),
      ...(selectableRows ? [useRowSelect] : []),
      ...(editable ? [useRowState] : []),
      ...(renderExpandedRow ? [useExpanded] : []),
      ...(hooks ? [hooks] : [])
    );
    const initiated = useRef(false);
    useImperativeHandle(ref, () => instance);

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      prepareRow,
      page,
      columns: tableColumns,
      rows,
      canPreviousPage,
      canNextPage,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      selectedFlatRows,
      state: { pageIndex, pageSize, sortBy }
    } = instance;

    useEffect(() => {
      if (setSelectedRows) {
        setSelectedRows(selectedFlatRows.map((row: any) => row.original));
      }
    }, [setSelectedRows, selectedFlatRows]);

    useEffect(() => {
      fetchData?.({ pageIndex, pageSize, sortBy });
      initiated.current = true;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageIndex, pageSize, sortBy, ...refetchTriggers]);

    const [lastPage, setLastPage] = useState(pageCount || 1);

    useEffect(() => {
      if (loading) return;
      // if after filtering, we have pages and current page is out of range, goto first page
      if (pageCount > 0 && pageIndex >= pageCount) {
        gotoPage(0);
      }
      setLastPage(pageCount);
    }, [loading, pageCount, pageIndex, gotoPage, setLastPage]);

    const pageRender = page || rows;
    // todo: let each component handle react table stuff and just use prepepared styled copmonents
    return (
      <>
        <div className={styles.wrapper}>
          <Spinner visible={loading} className={styles.spinner} />
          <table
            {...getTableProps()}
            data-cy={TableSelectors.TABLE_ROOT}
            className={classNames(
              styles.root,
              {
                [styles.sticky]: sticky,
                [styles.borderTop]: borderTop
              },
              className
            )}
            {...rest}
          >
            {showHeaders && (
              <thead>
                {headerGroups.map(
                  (headerGroup: {
                    getHeaderGroupProps: () => JSX.IntrinsicAttributes &
                      React.ClassAttributes<HTMLTableRowElement> &
                      React.HTMLAttributes<HTMLTableRowElement>;
                    headers: any[];
                  }) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            manualSorting && column.getSortByToggleProps()
                          )}
                        >
                          {column.render('Header')}
                        </th>
                      ))}
                    </tr>
                  )
                )}
              </thead>
            )}
            <tbody {...getTableBodyProps()}>
              {!loading &&
                pageRender.map((row: Row) => {
                  // @ts-ignore
                  prepareRow(row);
                  return (
                    <>
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              style={{ ...cell?.column?.style }}
                              {...cell.getCellProps()}
                            >
                              {cell.render('Cell')}
                            </td>
                          );
                        })}
                      </tr>
                      {row.isExpanded && renderExpandedRow && (
                        <tr>
                          <td
                            colSpan={tableColumns.length}
                            className={styles.noPadding}
                          >
                            {renderExpandedRow(row)}
                          </td>
                        </tr>
                      )}
                    </>
                  );
                })}
            </tbody>
          </table>
          {!loading && initiated.current && pageRender.length === 0 && empty}
        </div>
        {(paginated || showPagination) && (
          <TablePagination
            {...{
              canPreviousPage,
              canNextPage,
              pageCount: lastPage,
              gotoPage,
              nextPage,
              previousPage,
              setPageSize,
              state: { pageIndex, pageSize },
              className: paginationClassName
            }}
          />
        )}
      </>
    );
  }
);

export default Table;
