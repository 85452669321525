import { configureStore } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import { epics } from './epics';
import { reducers } from './reducers';
import { isProduction } from 'utils';

const epicMiddleware = createEpicMiddleware();

const persistConfig = {
  key: '__dealy__',
  storage,
  whitelist: ['auth', 'menu', 'cart', 'gdpr', 'warrantyFilter']
};

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: !isProduction,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: {
        // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    }),
    epicMiddleware
  ]
});

epicMiddleware.run(epics);

const persistor = persistStore(store);

export { store, persistor };
