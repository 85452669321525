import { WarrantyStatus } from 'types';

export const warrantyStatusToText = (status: WarrantyStatus) => {
  switch (status) {
    case WarrantyStatus.Draft:
      return 'Utkast';
    case WarrantyStatus.Approved:
      return 'Godkänd';
    case WarrantyStatus.Complement:
      return 'Komplettera';
    case WarrantyStatus.Complemented:
      return 'Kompletterad';
    case WarrantyStatus.Registered:
      return 'Registrerad';
    case WarrantyStatus.Credited:
      return 'Avslutad';
    case WarrantyStatus.Processing:
      return 'Behandlas';
    case WarrantyStatus.Declined:
      return 'Avslagen';
    case WarrantyStatus.LegacyWarranty:
      return 'Legacy';
    default:
      return 'Okänd';
  }
};

export const dealyWarrantyStatusToText = (status: WarrantyStatus) => {
  switch (status) {
    case WarrantyStatus.Draft:
      return 'Utkast';
    case WarrantyStatus.Approved:
      return 'Godkänd';
    case WarrantyStatus.Complement:
      return 'Retur';
    case WarrantyStatus.Complemented:
      return 'Kompletterad';
    case WarrantyStatus.Registered:
      return 'Registrerad';
    case WarrantyStatus.Credited:
      return 'Avslutad';
    case WarrantyStatus.Processing:
      return 'Behandlas';
    case WarrantyStatus.Declined:
      return 'Avslagen';
    case WarrantyStatus.LegacyWarranty:
      return 'Legacy';
    default:
      return 'Okänd';
  }
};
