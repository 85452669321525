import React, {
  SelectHTMLAttributes,
  ReactNode,
  useMemo,
  ChangeEvent,
  useCallback,
  useEffect,
  useRef
} from 'react';

import classNames from 'classnames';
import { ValidationRules, UseFormMethods, FieldErrors } from 'react-hook-form';

import { Error } from '../index';

import styles from './Select.module.scss';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  bordered?: boolean;
  dynamic?: boolean;
  prefixIcon?: ReactNode;
  prefixSize?: 'lg' | 'md' | 'sm';
  suffixIcon?: ReactNode;
  suffixSize?: 'lg' | 'md' | 'sm';
  label?: string;
  name?: string;
  register?: UseFormMethods['register'];
  validation?: ValidationRules;
  error?: FieldErrors;
  required?: boolean;
  options: Array<{ value: any; label: string }>;
  dataCy?: string;
  disabled?: boolean;
}

const Select = ({
  bordered = false,
  className,
  placeholder,
  prefixIcon,
  prefixSize,
  suffixIcon,
  suffixSize,
  label,
  name,
  defaultValue,
  dynamic,
  value,
  register,
  error,
  options,
  required,
  validation = {},
  dataCy = '',
  disabled = false,
  ...rest
}: SelectProps) => {
  const isRequired = required || validation?.required;
  const selectRef = useRef<HTMLSelectElement | null>(null);

  const resize = (el: HTMLSelectElement) => {
    const str = el.querySelector('option:checked')?.innerHTML;
    if (str) {
      const tmp = document.createElement('div');
      tmp.style.display = 'inline-block';
      document.body.appendChild(tmp);
      tmp.innerHTML = str;
      el.style.width = `${tmp.offsetWidth + 60}px`;
      document.body.removeChild(tmp);
    } else {
      el.style.width = '';
    }
  };

  const onChange = useCallback(
    (e: ChangeEvent<HTMLSelectElement>) => {
      resize(e.target);
      if (rest.onChange) {
        rest.onChange(e);
      }
    },
    [rest]
  );

  useEffect(() => {
    if (selectRef.current && dynamic) {
      resize(selectRef.current);
    }
  }, [dynamic, register, selectRef]);

  const input = useMemo(
    () => (
      <select
        data-cy={dataCy}
        {...(dynamic ? { onChange } : {})}
        ref={(e) => {
          selectRef.current = e;
          if (register) {
            register(e, { ...validation });
          }
        }}
        placeholder={placeholder}
        aria-label={label}
        className={classNames(styles.root, {
          [styles.bordered]: bordered,
          [styles.error]: !!error
        })}
        defaultValue={defaultValue}
        name={name}
        value={value}
        disabled={disabled}
        style={{ cursor: disabled ? 'not-allowed' : undefined }}
        {...rest}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value} label={option.label}>
            {option.label}
          </option>
        ))}
      </select>
    ),
    [
      register,
      validation,
      placeholder,
      label,
      bordered,
      error,
      defaultValue,
      name,
      value,
      rest,
      options,
      dynamic,
      onChange,
      dataCy,
      disabled
    ]
  );

  const withFix = () => {
    return (
      <div
        className={classNames(styles.wrapper, {
          [styles.withPrefix]: prefixIcon,
          [styles.withSuffix]: suffixIcon,
          [styles[`prefix-${prefixSize}`]]: prefixSize,
          [styles[`prefix-${suffixSize}`]]: suffixSize
        })}
      >
        {prefixIcon && <span className={styles.prefix}>{prefixIcon}</span>}
        {input}
        {suffixIcon && <span className={styles.suffix}>{suffixIcon}</span>}
      </div>
    );
  };

  return (
    <div className={classNames(styles.block, className)}>
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
          {isRequired && <span className={styles.required}>*</span>}
        </label>
      )}
      {prefixIcon || suffixIcon ? withFix() : input}
      <Error error={error} message={error?.message} />
    </div>
  );
};

export default Select;
