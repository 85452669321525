import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { WebshopSectionType } from 'routes/Webshop/sections';

// Types
interface WebshopSortingState {
  vehicles: string;
  accessories: string;
}

export interface WebshopSortingRootState {
  webshopSorting: WebshopSortingState;
}

// Selectors
export const selectSortingVehicles = ({
  webshopSorting
}: WebshopSortingRootState) => webshopSorting.vehicles;

export const selectSortingAccessories = ({
  webshopSorting
}: WebshopSortingRootState) => webshopSorting.accessories;

// Reducers
const initialState: WebshopSortingState = {
  vehicles: 'createdAt-desc',
  accessories: 'created_at-desc'
};

const webshopSortingSlice = createSlice({
  name: 'webshopSorting',
  initialState,
  reducers: {
    setSorting: (
      state,
      action: PayloadAction<{
        type: WebshopSectionType.VEHICLES | WebshopSectionType.ACCESSORIES;
        sorting: string;
      }>
    ) => {
      const { type, sorting } = action.payload;
      switch (type) {
        case WebshopSectionType.VEHICLES:
          state.vehicles = sorting;
          break;
        case WebshopSectionType.ACCESSORIES:
          state.accessories = sorting;
          break;
        default:
      }
    }
  }
});

export default webshopSortingSlice.reducer;

// Actions
export const { setSorting } = webshopSortingSlice.actions;
