import { ArticleSearchQuery } from 'types/graphql';
import { productResolvers } from 'utils/resolvers';

export const getArticleIndices = (
  articles: ArticleSearchQuery['articleSearch']['edges'],
  rows: any[]
) => {
  const { getArticle } = productResolvers;
  const selectedParts = rows.map((row) => getArticle(row)?.id);

  const indices: number[] = [];
  articles.forEach((article, index) => {
    if (selectedParts.includes(article.id)) {
      indices.push(index);
    }
  });

  return indices;
};
