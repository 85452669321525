import React, { FC, ReactNode } from 'react';

import classNames from 'classnames';

import { useTabs } from '../Tabs';

import styles from './Panel.module.scss';

export type PanelProps = {
  readonly children?: ReactNode;
  className?: string;
  id: string;
  keep?: boolean;
  label: string;
};

export const Panel: FC<PanelProps> = ({
  className,
  children,
  id,
  keep,
  ...rest
}) => {
  const { activeTab } = useTabs();

  return activeTab === id || keep ? (
    <div
      className={classNames(
        styles.root,
        {
          [styles.active]: activeTab === id
        },
        className
      )}
      {...rest}
    >
      {children}
    </div>
  ) : null;
};
