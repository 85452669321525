import React from 'react';

import classNames from 'classnames';
import { ValidationRules, UseFormMethods, FieldErrors } from 'react-hook-form';

import { Error } from '../index';

import styles from './Radio.module.scss';

interface TextProps {
  name: string;
  label?: string;
  id?: string;
  register?: UseFormMethods['register'];
  validation?: ValidationRules;
  error?: FieldErrors;
  disabled?: boolean;
  value?: any;
  control?: UseFormMethods['control'];
  onChange?: Function;
  checked?: any;
  defaultChecked?: any;
}

const Radio = ({
  name,
  label,
  id,
  register,
  value,
  error,
  validation = {},
  disabled,
  onChange,
  checked,
  defaultChecked,
  ...rest
}: TextProps) => {
  return (
    <div className={styles.block}>
      <label htmlFor={id || name}>
        <input
          disabled={disabled}
          value={value}
          type="radio"
          ref={register?.({ ...validation })}
          name={name}
          checked={checked}
          id={id}
          defaultChecked={defaultChecked}
          onChange={() => {
            onChange?.(value);
          }}
          aria-label={label}
          className={classNames(styles.root, { [styles.error]: !!error })}
          {...rest}
        />
        {label && <span className={styles.label}>{label}</span>}
      </label>
      <Error error={error} message={error?.message} />
    </div>
  );
};

export default Radio;
