import React from 'react';

import { useSelector } from 'react-redux';

import UploadModal, { UploadModalType } from 'components/modals/UploadModal';
import { useFeedback } from 'hooks';
import { getActingAsRetailer } from 'redux/auth';
import { useUploadShoppingListExcelMutation } from 'types/graphql';
import { translateShoppingListError } from 'utils/translations';

interface UploadShoppingListModalProps {
  isOpen: boolean;
  onUpload: () => void;
  closeModal: () => void;
}

export const UploadShoppingListModal = ({
  isOpen,
  onUpload,
  closeModal
}: UploadShoppingListModalProps) => {
  const actingAsRetailer = useSelector(getActingAsRetailer);

  const [
    uploadShoppingListExcel,
    { data, loading, error }
  ] = useUploadShoppingListExcelMutation();

  useFeedback(
    !!data?.uploadShoppingListExcel,
    error ?? false,
    'Inköpslistan uppladdad',
    'Kunde inte ladda upp inköpslistan',
    undefined,
    translateShoppingListError
  );

  const submitUploadShoppingListExcel = {
    handler: async (file: File) => {
      await uploadShoppingListExcel({
        variables: {
          spreadSheet: file,
          onBehalfOfRetailerId: actingAsRetailer?.id
        }
      });
    },
    loader: loading
  };

  return (
    <UploadModal
      modalIsOpen={isOpen}
      closeModal={closeModal}
      multiple={false}
      refetch={() => {
        onUpload();
        closeModal();
      }}
      type={UploadModalType.EXCEL}
      submitHandler={submitUploadShoppingListExcel}
      description={
        actingAsRetailer
          ? `Du laddar upp som ${actingAsRetailer.name}`
          : undefined
      }
    />
  );
};
