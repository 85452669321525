import React, { ChangeEvent } from 'react';

import classNames from 'classnames';
import { ValidationRules, UseFormMethods, FieldErrors } from 'react-hook-form';

import { Error } from '../index';

import styles from './Checkbox.module.scss';

export enum CheckboxLabel {
  ABOVE = 'above',
  DEFAULT = 'default',
  LEFT = 'left',
  RIGHT = 'right',
  UNDER = 'under'
}

interface TextProps {
  name: string;
  label?: string;
  labelPos?: CheckboxLabel;
  checked?: boolean;
  register?: UseFormMethods['register'];
  validation?: ValidationRules;
  error?: FieldErrors;
  disabled?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  doNotWrap?: boolean;
  id?: string;
}

const Checkbox = ({
  name,
  checked,
  label,
  labelPos = CheckboxLabel.DEFAULT,
  register,
  error,
  validation = {},
  disabled,
  onChange,
  doNotWrap,
  ...rest
}: TextProps) => {
  return (
    <div
      className={classNames(styles.block, {
        [styles[`label-${labelPos}`]]: labelPos,
        [styles.doNotWrap]: !!doNotWrap
      })}
    >
      {label && (
        <label className={styles.label} htmlFor={name}>
          {label}
        </label>
      )}
      <input
        disabled={disabled}
        defaultChecked={register ? checked : undefined}
        checked={!register ? checked : undefined}
        // @ts-ignore
        type="checkbox"
        ref={register?.({ ...validation })}
        name={name}
        aria-label={label}
        className={classNames(styles.root, { [styles.error]: !!error })}
        onChange={onChange}
        {...rest}
      />
      <Error error={error} message={error?.message} />
    </div>
  );
};

export default Checkbox;
