import React, { FC } from 'react';

import { useSelector } from 'react-redux';

import { Link } from 'components/tools';
import { SearchResultSelectors } from 'consts/cypress';
import { getVat } from 'redux/cart';
import { Article } from 'types';
import { toSEK, productResolvers } from 'utils';

import styles from './SearchResult.module.scss';

interface Props {
  article: Article;
  setIsOpen: (isOpen: boolean) => void;
}

const options = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0
};

const SearchResult: FC<Props> = ({ article, setIsOpen }) => {
  const vat = useSelector(getVat);
  const { getName, getUrl, getThumbnail } = productResolvers;
  const thumbnail = getThumbnail(article);

  return (
    <Link
      dataCy={SearchResultSelectors.RESULT_CARD}
      key={article?.id}
      className={styles.item}
      to={getUrl(article)}
      onClick={() => setIsOpen(false)}
    >
      <picture className={styles.image}>
        {thumbnail ? (
          <img src={thumbnail} alt="Article" />
        ) : (
          <i className="material-icons-outlined">hide_image</i>
        )}
      </picture>
      <div className={styles.info}>
        <span>{getName(article)}</span>
        <span className={styles.articleNumber}>{article.articleNumber}</span>
      </div>
      <div className={styles.price}>
        {article.discountedPrice ? (
          <>
            <span
              data-cy={SearchResultSelectors.DISCOUNTED_PRICE}
              className={styles.discounted}
            >
              {toSEK(article.discountedPrice * (1 + vat), options)}
            </span>
            <span
              data-cy={SearchResultSelectors.OLD_PRICE}
              className={styles.oldPrice}
            >
              {toSEK(article.price * (1 + vat), options)}
            </span>
          </>
        ) : (
          <span data-cy={SearchResultSelectors.PRICE}>
            {toSEK(article.price * (1 + vat), options)}
          </span>
        )}
      </div>
    </Link>
  );
};

export default SearchResult;
