import { StockStatus } from 'types';

export const stockStatusToText = (
  status: StockStatus | string,
  isStockVehicle = false
) => {
  switch (status) {
    case StockStatus.Incoming:
      return 'Ankommande';
    case StockStatus.Orderable:
      if (isStockVehicle) return 'Tillfälligt slut';
      return 'Beställningsvara';
    case StockStatus.SwedenWarehouse:
      return 'Sverigelager';
    default:
      return 'Alla';
  }
};
