import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  concat,
  DefaultOptions
} from '@apollo/client';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { Store } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/browser';
import { createUploadLink } from 'apollo-upload-client';

import apiEnvironment from './environment';
import introspectionResult from 'generated/introspection-result.json';
import { getToken, logoutStart } from 'redux/auth';

export const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {
    Retailer: {
      fields: {
        users: {
          merge(_, incoming: any[]) {
            return incoming;
          }
        }
      },
      merge: true
    },
    User: {
      merge: true
    }
  }
});

const uploadHttpLink = createUploadLink({ uri: `${apiEnvironment.apiUrl}` });

/**
 * Inject the authentication token into the requests made by apollo
 *
 * @type {ApolloLink}
 */
const authMiddleware = (store: Store) =>
  new ApolloLink((operation, forward) => {
    const token = getToken(store.getState());
    if (token) {
      operation.setContext({
        headers: {
          authorization: `jwt ${token}`
        }
      });
    }

    return forward(operation);
  });

/**
 * Checks the incoming error from the Apollo client and if it's a 401 attempts to refresh the token
 *
 * @type {ApolloLink}
 */

const errorHandler = (reduxStore: Store) => {
  return onError((err: ErrorResponse) => {
    const { networkError, operation, graphQLErrors } = err;

    // Notify via sentry if something breaks
    if (networkError?.message !== 'Network request failed') {
      Sentry.withScope((scope) => {
        scope.setExtra('exception', err);
        scope.setExtra('operation', operation);
        scope.setExtra('graphQLErrors', graphQLErrors);

        const opName = operation.operationName
          ? operation.operationName
          : 'unknown operation';

        Sentry.captureMessage(`Apollo error: ${opName}`, 'warning');
      });
    }
    // @ts-ignore todo: fix typing
    if (networkError?.statusCode === 401) {
      // don't import the logout action since that will cause a circular dependency
      reduxStore.dispatch({ type: logoutStart.type });
    }
  });
};

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all'
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all'
  },
  mutate: {
    errorPolicy: 'all'
  }
};

// eslint-disable-next-line import/no-mutable-exports
export let apolloClient: ApolloClient<any>;
export const factory = (reduxStore: Store) => {
  apolloClient = new ApolloClient({
    cache,
    link: errorHandler(reduxStore).concat(
      concat(
        authMiddleware(reduxStore),
        (uploadHttpLink as unknown) as ApolloLink
      )
    ),
    defaultOptions
  });

  return apolloClient;
};
