import React from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { getActingAsRetailer } from 'redux/auth';
import { DeliveryType } from 'types';

import styles from './WebshopCheckout.module.scss';

const WebshopCheckoutDisplay = ({ getValues }: any) => {
  const actingAsRetailer = useSelector(getActingAsRetailer);
  const getDeliveryTypeStr = (): string => {
    switch (getValues('deliveryType')) {
      case DeliveryType.Express:
        return 'Flyg';
      case DeliveryType.Pre12:
        return 'DHL PRE12';
      default:
        return 'Normal';
    }
  };

  return (
    <div>
      <span className={styles.title__sub}>Ordertyp</span>
      <div className={styles.cardBox__small}>
        <span>{getDeliveryTypeStr()}</span>
      </div>
      <span className={styles.title__sub}>Adress</span>
      <div className={styles.cardBox}>
        <div
          className={classNames(styles.flexContainer__column, styles.grey_text)}
        >
          <span>Företagsnamn: {getValues('retailerName')}</span>
          <span>Kundnummer: {getValues('retailerId')}</span>
          <span>
            {`Adress: ${getValues('address')}. ${getValues(
              'postalCode'
            )}, ${getValues('postalCity')}`}
          </span>
          <span>E-post: {getValues('email')}</span>
          <span>Telefon: {getValues('phoneNumber')}</span>
        </div>
      </div>

      <span className={styles.title__sub}>Delleverans</span>
      <div className={styles.cardBox__small}>
        <span>{getValues('partialDelivery')}</span>
      </div>
      {getValues('orderRemark') && (
        <div>
          <span className={styles.title__sub}>Orderref</span>
          <div className={styles.cardBox__small}>
            <span>{getValues('orderRemark')}</span>
          </div>
        </div>
      )}
      {actingAsRetailer && getValues('dealyInternalComment') && (
        <div>
          <span className={styles.title__sub}>Intern kommentar</span>
          <div className={styles.cardBox}>
            <span>{getValues('dealyInternalComment')}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default WebshopCheckoutDisplay;
