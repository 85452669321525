import { FilterCategory } from 'redux/webshopFilter';

export const filterCategoryToText = (type: FilterCategory | string) => {
  switch (type) {
    case FilterCategory.VEHICLE:
      return 'Fordonstyp';
    case FilterCategory.BRAND:
      return 'Varumärke';
    case FilterCategory.MODEL:
      return 'Modell';
    case FilterCategory.MODELSERIES:
      return 'Modellserie';
    case FilterCategory.COLOR:
      return 'Färg';
    case FilterCategory.STOCK:
      return 'Lagersaldo';
    case FilterCategory.EQUIPMENT_GROUP:
      return 'Typ av tillbehör';
    default:
      return type;
  }
};
