import React, { FC, ReactNode, useState } from 'react';

import styles from './WebshopExpandable.module.scss';

interface WebshopExpandableProps {
  children?: ReactNode;
  openOnMount?: boolean;
  label: string;
}

const WebshopExpandable: FC<WebshopExpandableProps> = ({
  children,
  openOnMount = true,
  label
}) => {
  const [open, setOpen] = useState(openOnMount);

  return (
    <div className={styles.root}>
      <button
        type="button"
        className={styles.header}
        onClick={() => setOpen(!open)}
      >
        <span>{label}</span>
        <i className="material-icons">{open ? 'expand_less' : 'expand_more'}</i>
      </button>
      {children && open && <div className={styles.content}>{children}</div>}
    </div>
  );
};

export default WebshopExpandable;
