import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import dealyLogo from 'assets/images/dealy_logo@2x.png';
import { Link } from 'components/tools';
import { Button, Container } from 'components/ui/general';
import ActAsRetailerButton from 'components/ui/general/ActAsRetailerButton';
import { getLoggedIn, getUser } from 'redux/auth';
import { toggleMenu } from 'redux/menu';

import styles from './Header.module.scss';

const Header = () => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(getLoggedIn);
  const user = useSelector(getUser);

  const name = user?.name || '';

  return (
    <header className={styles.root}>
      <Container fullWidth>
        <div className="grid grid--fill grid--center">
          <div className="grid__item">
            {isLoggedIn && (
              <Button
                color="no"
                className={styles.menuButton}
                onClick={() => dispatch(toggleMenu())}
              >
                <i className="material-icons">menu</i>
              </Button>
            )}
          </div>
          <div className={styles.logotype}>
            <Link className={styles.logotypeAnchor} to="/">
              <img src={dealyLogo} alt="Dealy" className={styles.logo} />
            </Link>
          </div>
          <div className="grid__item">
            <div className={styles.nameWrapper}>
              <span>{name}</span>
              <ActAsRetailerButton />
            </div>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
