import React, { FC } from 'react';

import classNames from 'classnames';

import { Link } from 'components/tools';
import {
  WebshopSectionType,
  webshopSectionTypeToText
} from 'routes/Webshop/sections';

import styles from './WebshopLandingSplash.module.scss';

interface WebshopLandingSplashProps {
  type: WebshopSectionType;
  imageUrl: string;
  size?: 'half' | 'full';
  dataCy: string;
}

const WebshopLandingSplash: FC<WebshopLandingSplashProps> = ({
  imageUrl,
  type,
  size = 'full',
  dataCy
}) => {
  return (
    <Link
      to={`/webshop/${type}`}
      className={classNames(styles.root, {
        [styles[size]]: size
      })}
    >
      <div data-cy={dataCy}>
        <picture className={styles.image}>
          <img src={imageUrl} alt={webshopSectionTypeToText(type)} />
        </picture>
        <div className={styles.label}>
          <span>{webshopSectionTypeToText(type)}</span>
          <i className="material-icons">chevron_right</i>
        </div>
      </div>
    </Link>
  );
};

export default WebshopLandingSplash;
