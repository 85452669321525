import { isSSR } from 'utils';

export const isDemo = (): boolean => {
  if (!isSSR) {
    const { hostname } = window.location;
    return Boolean(hostname.includes('utveckling.oob.se'));
  }

  return false;
};
