import React from 'react';

import { Helmet } from 'react-helmet';

import { Container } from 'components/ui/general';
import { Drawer } from 'components/ui/navigation';
import {
  WebshopBreadcrumb,
  Crumb,
  WebshopFilter,
  WebshopSorting,
  WebshopList
} from 'routes/Webshop/components';
import {
  WebshopSectionType,
  webshopSectionTypeToText
} from 'routes/Webshop/sections';
import {
  useArticleSearchQuery,
  useStockVehiclesQuery,
  ArticleSearchQueryVariables,
  StockVehiclesQueryVariables
} from 'types';

import styles from './WebshopSection.module.scss';

interface WebshopSectionProps {
  section: WebshopSectionType.VEHICLES | WebshopSectionType.ACCESSORIES;
  filter?:
    | ArticleSearchQueryVariables['filter']
    | StockVehiclesQueryVariables['filter'];
  sorting: string;
  query: typeof useArticleSearchQuery | typeof useStockVehiclesQuery;
}

const WebshopSection = ({
  section,
  filter,
  sorting,
  query
}: WebshopSectionProps) => {
  const label = webshopSectionTypeToText(section);
  const crumbs: Crumb[] = [{ label, id: section }];

  return (
    <>
      <Helmet>
        <title>Webshop: {label}</title>
      </Helmet>
      <Drawer initial="closed" overlay />
      <section>
        <Container className={styles.root}>
          <div className={styles.main}>
            <WebshopBreadcrumb crumbs={crumbs} />
            <div className="grid grid--middle gutter-left-2xl">
              <div className="grid__item grid__item--width-3/12 gutter__item">
                <div className={styles.filter}>Filter</div>
              </div>
              <div className="grid__item grid__item--width-9/12 gutter__item">
                <div className={styles.header}>
                  <span>Visar alla {label} (priser visas inkl moms)</span>
                  <WebshopSorting section={section} />
                </div>
              </div>
            </div>
            <div className="grid gutter-left-2xl gutter-bottom-2xl">
              <div className="grid__item grid__item--width-3/12 gutter__item">
                <WebshopFilter section={section} />
              </div>
              <div className="grid__item grid__item--width-9/12 gutter__item">
                <WebshopList {...{ section, filter, sorting, query }} />
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default WebshopSection;
