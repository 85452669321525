import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import noImage from 'assets/images/notFound.png';
import { Link } from 'components/tools';
import { Stepper } from 'components/ui/forms';
import InStockMarker from 'components/ui/Webshop/InStockMarker';
import { addItem, getVat, removeItem, CartItem } from 'redux/cart';
import { getWebshopLinkForCard, toSEK, productResolvers } from 'utils';

import styles from './WebshopCartOverlayCard.module.scss';

interface WebshopCartOverlayCardProps {
  cartItem: CartItem;
  onRequestClose?: () => void;
}

const WebshopCartOverlayCard = ({
  cartItem,
  onRequestClose
}: WebshopCartOverlayCardProps) => {
  const {
    item,
    quantity,
    stockVehicleProductCount,
    isCampaignEquipment,
    relatedVehicleId,
    productRuleItems
  } = cartItem;
  const dispatch = useDispatch();
  const vat = useSelector(getVat);
  const thumbnail = 'thumbnail' in item ? item.thumbnail : undefined;

  const updateSelectedItem = useCallback(
    (newQuantity: number) => {
      if (newQuantity > quantity) {
        dispatch(
          addItem({
            sp: item,
            quantity: newQuantity,
            relatedVehicleId,
            stockVehicleProductCount,
            isCampaignEquipment
          })
        );
      } else {
        dispatch(
          removeItem({
            article: item,
            relatedVehicleId,
            quantity: newQuantity
          })
        );
      }
    },
    [
      dispatch,
      isCampaignEquipment,
      item,
      quantity,
      relatedVehicleId,
      stockVehicleProductCount
    ]
  );

  const renderPrice = useCallback(() => {
    const { price, discountedPrice } = item;

    if (
      (discountedPrice && discountedPrice !== price) ||
      discountedPrice === 0
    ) {
      return (
        <>
          <span className={styles.discountedPrice}>
            {toSEK(discountedPrice * (1 + vat))}
          </span>
          <span className={styles.priceLineThrough}>
            {toSEK(price * (1 + vat))}
          </span>
        </>
      );
    }

    return toSEK(price * (1 + vat));
  }, [item, vat]);

  return (
    <div className={styles.root}>
      <Link
        to={getWebshopLinkForCard(item, item.id.toString())}
        className={styles.link}
        onClick={() => onRequestClose?.()}
      >
        <div
          className={styles.thumbnail}
          style={{ backgroundImage: `url(${thumbnail?.path || noImage})` }}
        />
        <div className={styles.summarize}>
          <div className={styles.title}>{productResolvers.getName(item)}</div>
          <div className={styles.articleNumber}>{item.articleNumber}</div>
          {productRuleItems && productRuleItems.length > 0 && (
            <div>{productRuleItems.length} kopplade artiklar tillkommer</div>
          )}
          <div className={styles.status}>
            <InStockMarker product={item} approximateStockBalance />
          </div>
        </div>
      </Link>
      <div className={styles.rightFlex}>
        <div className={styles.price}>{renderPrice()}</div>
        <Stepper
          className={styles.stepper}
          value={quantity}
          minValue={0}
          maxValue={isCampaignEquipment ? stockVehicleProductCount : undefined}
          onChange={updateSelectedItem}
        />
      </div>
    </div>
  );
};

export default WebshopCartOverlayCard;
