import React, { FormEvent } from 'react';

import classNames from 'classnames';
import { UseFormMethods } from 'react-hook-form';

import { Text } from 'components/ui/forms';
import { Button } from 'components/ui/general';
import { DealyModal } from 'components/ui/modals';
import { ShoppingListModalSelectors } from 'consts/cypress';

import styles from './ShoppingListModal.module.scss';

interface ShoppingListModalProps {
  isOpen: boolean;
  closeModal: () => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  headerText: string;
  register: UseFormMethods['register'];
  loading: boolean;
  submitText: string;
}

const ShoppingListModal = ({
  isOpen,
  closeModal,
  handleSubmit,
  headerText,
  register,
  loading,
  submitText
}: ShoppingListModalProps) => {
  return (
    <DealyModal isOpen={isOpen} onRequestClose={closeModal}>
      <form onSubmit={handleSubmit}>
        <div className={styles.modalRoot}>
          <div className={classNames(styles.modalTop, 'grid', 'grid--between')}>
            <h4>{headerText}</h4>
            <Button
              color="clear"
              className={styles.closeButton}
              onClick={closeModal}
            >
              <i className="material-icons">close</i>
            </Button>
          </div>
          <div className="gutter-bottom-md">
            <div className="gutter__item">
              <Text
                dataCy={ShoppingListModalSelectors.NAME_INPUT}
                register={register}
                label="Namn"
                className={styles.input}
                name="name"
                required
              />
            </div>
            <div className="gutter__item">
              <Text
                dataCy={ShoppingListModalSelectors.REMARK_INPUT}
                register={register}
                label="Beskrivning"
                className={styles.input}
                name="remark"
              />
            </div>
            <div className="gutter__item">
              <Button
                dataCy={ShoppingListModalSelectors.SUBMIT_BUTTON}
                color="alpha"
                type="submit"
                fullWidth
                loading={loading}
              >
                <span>{submitText}</span>
              </Button>
            </div>
          </div>
        </div>
      </form>
    </DealyModal>
  );
};

export default ShoppingListModal;
