import { Order, OrderItem, StockStatus } from 'types';

// Function that returns order status regardless of product type
const getOrderStatus = (product: any) => {
  if (product.vehicle) {
    return product.vehicle.status;
  }

  if (product.equipment) {
    return product.equipment.status;
  }

  if (product.sparePart) {
    return product.sparePart.status;
  }
};

/*
Takes in an array of orders and sorts their items into two arrays
representing different delivery status. All items in orders containing
a vehicle and a varying level of delivery readiness should be set to
the lowest readiness
*/
export const arrangeByStockStatus = (
  orders: Order[],
  partialDelivery: boolean | undefined
) => {
  const orderableItems: Array<OrderItem> = [];
  const orderedItems: Array<OrderItem> = [];
  orders.forEach((order) => {
    const hasOrderableVehicle = order.products.some(
      (orderItem) =>
        orderItem.vehicle && getOrderStatus(orderItem) === StockStatus.Orderable
    );

    if (hasOrderableVehicle) {
      orderableItems.push(...order.products);
      return;
    }

    order.products.forEach((orderedItem) => {
      if (getOrderStatus(orderedItem) === StockStatus.Orderable) {
        orderableItems.push(orderedItem);
        return;
      }
      orderedItems.push(orderedItem);
    });
  });
  if (!partialDelivery && orderedItems.length && orderableItems.length) {
    return [[], [...orderedItems, ...orderableItems]];
  }

  return [orderedItems, orderableItems];
};
