import { DeclineReason, MaintenanceRowDeclineReason } from 'types';

export const declineReasonToText = (
  reason: DeclineReason | MaintenanceRowDeclineReason
) => {
  switch (reason) {
    case DeclineReason.BasisNotCorrect:
    case MaintenanceRowDeclineReason.Incorrect:
      return 'Underlag ej korrekt.';
    case DeclineReason.NotWithinWarrantyTime:
      return 'Ej inom garantitid.';
    case DeclineReason.NotCoveredByWarranty:
      return 'Felet ej inom garanti.';
    case DeclineReason.WarrantyNotAppliedInTime:
    case MaintenanceRowDeclineReason.Overdue:
      return 'Ansökan ej gjord i tid.';
    case MaintenanceRowDeclineReason.LackingApproval:
      return 'Saknar godkännande';
    case DeclineReason.Other:
      return 'Fritext';
    default:
      return 'Okänd';
  }
};
