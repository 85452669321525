import { CartItem, CartItemWithKey, CartRootState } from 'redux/cart';
import { isStockVehicle } from 'utils/typeGuards';

/*
Creates an array containing identical
vehicle items of length "quantity"
*/
const multiplyVehicles = (vehicleItem: CartItem) => {
  const duplicatedVehicles = [];
  // Create shallow copy since quantity and note has to be reassigned
  for (let i = 0; i < vehicleItem.quantity; i += 1) {
    const clonedVehicle: CartItemWithKey = {
      ...(vehicleItem as CartItemWithKey)
    };
    clonedVehicle.internalIndex = i;
    clonedVehicle.quantity = 1;
    clonedVehicle.note = vehicleItem.note[i];

    duplicatedVehicles.push(clonedVehicle);
  }
  return duplicatedVehicles;
};

/*
"Breaks up" vehicle items where quantity > 1 into separate elements
by splicing array of vehicle duplicates into array containing cart items
*/
export const separateVehicles = (
  cartItems: CartRootState['cart']['items']
): Array<CartItemWithKey | CartItem> => {
  // Create shallow clone because ordering changes
  const newCartItems = [...cartItems];
  newCartItems
    .filter((item) => isStockVehicle(item.item))
    .forEach((item) => {
      newCartItems.splice(
        newCartItems.indexOf(item),
        1,
        ...multiplyVehicles(item)
      );
    });
  return newCartItems;
};
