import { createSlice } from '@reduxjs/toolkit';

interface Action {
  readonly type: string;
  payload?: any;
}

interface DropDownOption {
  key: string;
  value: string;
}

interface State {
  layout?: string;
  status?: DropDownOption;
}

export interface WarrantyFilterRootState {
  warrantyFilters: State;
}

// Selectors
export const selectFilter = ({ warrantyFilter }: any) =>
  warrantyFilter.warrantyFilters;

// Reducers
const initialState: WarrantyFilterRootState = {
  warrantyFilters: {
    layout: undefined,
    status: undefined
  }
};

const warrantyFilterSlice = createSlice({
  name: 'warrantyFilter',
  initialState,
  reducers: {
    upsertStatus: (state: WarrantyFilterRootState, action) => {
      state.warrantyFilters.status = action.payload;
    },
    upsertLayout: (state: WarrantyFilterRootState, action) => {
      state.warrantyFilters.layout = action.payload;
    }
  }
});

export default warrantyFilterSlice.reducer;

// Actions
export const { upsertStatus, upsertLayout } = warrantyFilterSlice.actions;
