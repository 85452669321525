import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Action, Location } from 'history';

// Types
interface History {
  action: Action;
  location: Location;
}

interface RouterState {
  location: Location | null;
  history: History | null;
}

interface RouterRootState {
  router: RouterState;
}

// Selectors
export const selectRouter = ({ router }: RouterRootState) => router;
export const selectLocation = ({ router }: RouterRootState) => router.location;
export const selectHistory = ({ router }: RouterRootState) => router.history;

// Reducers
const initialState: RouterState = {
  location: null,
  history: null
};

const routerSlice = createSlice({
  name: 'router',
  initialState,
  reducers: {
    setLocation: (state, action: PayloadAction<Location>) => {
      state.location = action.payload;
    },
    setHistory: (state, action: PayloadAction<History>) => {
      state.history = action.payload;
    }
  }
});

export default routerSlice.reducer;

// Actions
export const { setLocation, setHistory } = routerSlice.actions;
