import React, { useState, useEffect } from 'react';

import { Stepper } from 'components/ui/forms';

interface StepperCellProps {
  initialValue: number | string;
  row: { index: number; original: any };
  column: { id: string };
  customFunction: Function;
  disabled?: boolean;
}

export const StepperCell = ({
  initialValue,
  row: { index, original },
  column: { id },
  disabled = false,
  customFunction // This is a custom function that we supplied to our table instance
}: StepperCellProps) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = useState(initialValue);

  const onChange = (stepValue: number) => {
    setValue(stepValue);
    customFunction?.(index, id, stepValue);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    customFunction?.(index, id, value);
  };

  // If the initialValue is changed external, sync it up with our state
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return (
    <Stepper
      disabled={disabled}
      value={value}
      maxValue={original.freeQuantity}
      minValue="0"
      onChange={onChange}
      onBlur={onBlur}
    />
  );
};
