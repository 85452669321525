import React from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { DealyModal, DealyModalAnchor } from '../DealyModal';
import { Button } from 'components/ui/general';
import { closeFeedback, hasFeedback, FeedbackStatus } from 'redux/feedback';

import styles from './ConfirmationModal.module.scss';

export const ConfirmationModal = () => {
  const { open, message, status, extendedMessage } = useSelector(hasFeedback);
  const dispatch = useDispatch();

  const icon =
    status === FeedbackStatus.Success
      ? 'check_circle_outline'
      : 'error_outline';

  return (
    <DealyModal
      isOpen={open}
      className={styles.root}
      anchor={DealyModalAnchor.CENTER}
      onRequestClose={() => dispatch(closeFeedback())}
    >
      {status === FeedbackStatus.Error && (
        <Button
          color="clear"
          className={styles.closeButton}
          onClick={() => dispatch(closeFeedback())}
        >
          <i className="material-icons">close</i>
        </Button>
      )}
      <div className={styles.body}>
        <i
          className={classNames(
            'material-icons',
            styles[status as FeedbackStatus]
          )}
        >
          {icon}
        </i>
        <div className={styles.messages}>
          <span className={styles.primaryMessage}>{message}</span>
          {extendedMessage && (
            <span className={styles.secondaryMessage}>{extendedMessage}</span>
          )}
        </div>
      </div>
    </DealyModal>
  );
};
