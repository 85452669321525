import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "Warranty": [
      "VehicleWarranty",
      "GoodWillWarranty",
      "PartWarranty",
      "LegacyWarranty"
    ],
    "Row": [
      "OperationRow",
      "ExternRow",
      "ArticleRow",
      "LegacyRow"
    ],
    "Article": [
      "StockVehicle",
      "Equipment",
      "SparePart",
      "BillOfMaterial",
      "Fee"
    ],
    "MaintenanceRow": [
      "ArticleMaintenanceRow",
      "ExternalMaintenanceRow",
      "OperationMaintenanceRow",
      "MileageAllowanceMaintenanceRow",
      "ShippingMaintenanceRow"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Warranty": [
      "VehicleWarranty",
      "GoodWillWarranty",
      "PartWarranty",
      "LegacyWarranty"
    ],
    "Row": [
      "OperationRow",
      "ExternRow",
      "ArticleRow",
      "LegacyRow"
    ],
    "Article": [
      "StockVehicle",
      "Equipment",
      "SparePart",
      "BillOfMaterial",
      "Fee"
    ],
    "MaintenanceRow": [
      "ArticleMaintenanceRow",
      "ExternalMaintenanceRow",
      "OperationMaintenanceRow",
      "MileageAllowanceMaintenanceRow",
      "ShippingMaintenanceRow"
    ]
  }
};
      export default result;
    
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string | number;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  /** UUID v4 */
  UUID: any;
  UploadFile: any;
};

/** Dealy queries */
export type Query = {
  __typename?: 'Query';
  /** Fetch specific user */
  userById?: Maybe<User>;
  /** Fetch specific users by their uuids */
  usersByIds: Array<User>;
  /** Fetch login user from token */
  userByToken?: Maybe<UserByTokenReturnType>;
  /** Fetch all the users */
  users?: Maybe<UserCollection>;
  /** Fetch all makes (brands) */
  makes: MakeCollection;
  /** Fetch all makes (brands) by their ids */
  makesByIds: Array<Make>;
  /** Fetch one make (brand) by their id */
  makeById?: Maybe<Make>;
  /** Fetch all makes that the logged in user have */
  allMakesByLoggedInUser: Array<Make>;
  /** Fetch all Retailers */
  retailers: RetailerCollection;
  /** Fetch all retailers by their ids */
  retailersByIds: Array<Retailer>;
  /** Fetch one retailer by their id */
  retailerById?: Maybe<Retailer>;
  /** Fetch all warranties */
  warranties?: Maybe<WarrantyCollection>;
  /** Fetch on warranty by its id */
  warrantyById?: Maybe<Warranty>;
  /** Fetch vehicle warranty history from warranty */
  vehicleWarrantyHistory: Array<Warranty>;
  /** Filterable list of vehicles under warranty */
  warrantyVehicles: WarrantyVehicleCollection;
  /** get warrantyVehicle by vin or reg number */
  warrantyVehicleByVinOrRegNum?: Maybe<WarrantyVehicleAllDataWithFolder>;
  /** Filterable list of vehicles under warranty */
  warrantyVehicleById: Vehicle;
  /** Fetch all the users views */
  warrantyDisplayView: Array<WarrantyDisplayView>;
  /** Fetch all explodedViews with some filters */
  explodedViews: ExplodedViewCollection;
  /** Fetch exploded view rows for a specific exploded view */
  explodedViewRows: ExplodedViewRowCollection;
  /** Fetch explodedView by its id */
  explodedViewById: ExplodedView;
  /** Fetch all exploded views that might match a specific vehicle */
  foldersByVehicleId: FoldersByVehicleId;
  /** Fetch all folders */
  folders: Array<Folder>;
  /** Fetch only the folders which have no parent */
  parentFolders: Array<Folder>;
  /** Fetch only one folder by its id */
  folderById: Folder;
  /** Filterable list of spare parts */
  spareParts: SparePartCollection;
  /** Get a specific order */
  orderById: Order;
  /** Returns an array of orders matching the serial number provided */
  ordersBySerialNumber: Array<Order>;
  /** All orders */
  orders: OrderCollection;
  /** Filterable list of order items */
  orderItems: OrderItemCollection;
  /** Order item by id */
  orderItemById: OrderItem;
  /** Search order items on a specific order */
  orderItemsByOrder: OrderItemCollection;
  /** All stockVehicles */
  stockVehicles: StockVehicleCollection;
  /** Get specific stockVehicle by id */
  stockVehicleById: StockVehicle;
  /** All equipments */
  equipments: EquipmentCollection;
  /** Get specific equipment by id */
  equipmentById: Equipment;
  /** Get all campaign items */
  campaignItems: FullTextSearchCollection;
  /** Get all related items */
  relatedEquipments: RelatedProducts;
  /** An array with all the webshop filters */
  getWebshopFilters: WebshopFilters;
  /** Get all bill of materials */
  billOfMaterial: BillOfMaterialCollection;
  /** Get a bill of material by id */
  billOfMaterialById: BillOfMaterial;
  /** Get a shopping list by its id */
  shoppingListById: ShoppingList;
  /** Get all shopping lists */
  shoppingLists: ShoppingListCollection;
  /** Get specific vehicle by input */
  vehicle?: Maybe<Vehicle>;
  /** Filterable list of consignment vehicles */
  consignmentVehicles: VehicleCollection;
  /** Get specific consignmentVehicle */
  consignmentVehicleById: Vehicle;
  /** Filterable list of exhibition vehicles */
  exhibitionVehicles: VehicleCollection;
  /** Get specific exhibitionVehicle */
  exhibitionVehicleById: Vehicle;
  /** Fetch A specific Order */
  apiOrderById: ApiOrder;
  /** Fetch All orders */
  apiOrders?: Maybe<NoContent>;
  /** Fetch A specific customer */
  customerById?: Maybe<Customer>;
  /** Fetch all customers */
  customers?: Maybe<NoContent>;
  /** Fetch A specific Product */
  apiProductById: Product;
  /** Fetch All Products */
  apiProducts?: Maybe<NoContent>;
  /** Fetch All ModelSeries */
  apiModelSeries?: Maybe<NoContent>;
  /** Fetch All Models */
  apiModels?: Maybe<NoContent>;
  /** Fetch All Brands */
  apiBrands?: Maybe<NoContent>;
  /** Fetch All Invoices */
  apiInvoices?: Maybe<NoContent>;
  /** Fetch all invoices */
  invoices: InvoiceCollection;
  /** Fetch an invoice by id */
  invoiceById: Invoice;
  /** Get operation codes associated to a specific operation group */
  operationCodes: OperationCodeCollection;
  /** Get an operation code group */
  operationGroupById?: Maybe<OperationGroup>;
  /** Get an operation code group and warranty costs. Will return operation group for manual (spare part) work if no vehicleId is provided */
  operationGroupAndCosts?: Maybe<OperationGroupAndCosts>;
  /** Get operation code groups */
  operationGroups?: Maybe<OperationGroupCollection>;
  /** Fetch a specific product rule by id */
  productRuleById: ProductRule;
  /** Fetch multiple product rules */
  productRules: ProductRuleCollection;
  /** Search different product identifiers. Searched fields are listed in response type */
  identifierSearch: SearchResultCollection;
  /** Fetch a specific volume discount by id */
  volumeDiscountById: VolumeDiscount;
  /** Fetch multiple volume discounts */
  volumeDiscounts: VolumeDiscountCollection;
  /** Search for articles by the fields name (description for bill of materials), article number and sage id */
  articleSearch: ArticleSearchCollection;
  /** Fetch maintenance task by id */
  maintenanceTaskById?: Maybe<MaintenanceTask>;
  /** Fetch all maintenance customers ordered by name. Note that offset and limit is not used in the returned meta property */
  maintenanceCustomers?: Maybe<MaintenanceCustomerCollection>;
  /** Fetch multiple maintenance tasks. Automatically filters tasks on retailer if used by a retailer user */
  maintenanceTasks: MaintenanceTaskCollection;
  /** Fetch custom prices. Searchable on article number and maintenance customer name. Sorts by updated at in descending order */
  customPrices: CustomPriceCollection;
  /** Fetch article price specified by contract with maintenance customer */
  customPrice?: Maybe<CustomPrice>;
};


/** Dealy queries */
export type QueryUserByIdArgs = {
  id: Scalars['String'];
};


/** Dealy queries */
export type QueryUsersByIdsArgs = {
  ids: Array<Scalars['String']>;
};


/** Dealy queries */
export type QueryUsersArgs = {
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
};


/** Dealy queries */
export type QueryMakesArgs = {
  filter?: Maybe<MakeFilter>;
};


/** Dealy queries */
export type QueryMakesByIdsArgs = {
  ids: Array<Scalars['ID']>;
};


/** Dealy queries */
export type QueryMakeByIdArgs = {
  id: Scalars['ID'];
};


/** Dealy queries */
export type QueryRetailersArgs = {
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
};


/** Dealy queries */
export type QueryRetailersByIdsArgs = {
  ids: Array<Scalars['Int']>;
};


/** Dealy queries */
export type QueryRetailerByIdArgs = {
  id: Scalars['Int'];
};


/** Dealy queries */
export type QueryWarrantiesArgs = {
  filter?: Maybe<WarrantyFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
};


/** Dealy queries */
export type QueryWarrantyByIdArgs = {
  id: Scalars['ID'];
};


/** Dealy queries */
export type QueryVehicleWarrantyHistoryArgs = {
  warrantyId: Scalars['ID'];
};


/** Dealy queries */
export type QueryWarrantyVehiclesArgs = {
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
  fetchOnBehalfOfRetailerId?: Maybe<Scalars['Int']>;
};


/** Dealy queries */
export type QueryWarrantyVehicleByVinOrRegNumArgs = {
  searchTerm: Scalars['String'];
};


/** Dealy queries */
export type QueryWarrantyVehicleByIdArgs = {
  id: Scalars['String'];
};


/** Dealy queries */
export type QueryExplodedViewsArgs = {
  filter?: Maybe<ExplodedViewFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
};


/** Dealy queries */
export type QueryExplodedViewRowsArgs = {
  filter?: Maybe<ExplodedViewRowFilter>;
};


/** Dealy queries */
export type QueryExplodedViewByIdArgs = {
  id: Scalars['String'];
};


/** Dealy queries */
export type QueryFoldersByVehicleIdArgs = {
  id: Scalars['String'];
};


/** Dealy queries */
export type QueryParentFoldersArgs = {
  fetchOnBehalfOfRetailerId?: Maybe<Scalars['Int']>;
};


/** Dealy queries */
export type QueryFolderByIdArgs = {
  id: Scalars['String'];
  depth?: Maybe<Scalars['Int']>;
};


/** Dealy queries */
export type QuerySparePartsArgs = {
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
};


/** Dealy queries */
export type QueryOrderByIdArgs = {
  id: Scalars['String'];
};


/** Dealy queries */
export type QueryOrdersBySerialNumberArgs = {
  id: Scalars['String'];
};


/** Dealy queries */
export type QueryOrdersArgs = {
  filter?: Maybe<OrderFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
  fetchOrdersOnBehalfOfRetailerId?: Maybe<Scalars['Int']>;
};


/** Dealy queries */
export type QueryOrderItemsArgs = {
  filter?: Maybe<OrderItemFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
};


/** Dealy queries */
export type QueryOrderItemByIdArgs = {
  id: Scalars['ID'];
};


/** Dealy queries */
export type QueryOrderItemsByOrderArgs = {
  orderId: Scalars['String'];
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
};


/** Dealy queries */
export type QueryStockVehiclesArgs = {
  filter?: Maybe<WebshopFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
};


/** Dealy queries */
export type QueryStockVehicleByIdArgs = {
  id: Scalars['String'];
};


/** Dealy queries */
export type QueryEquipmentsArgs = {
  filter?: Maybe<WebshopFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
};


/** Dealy queries */
export type QueryEquipmentByIdArgs = {
  id: Scalars['String'];
};


/** Dealy queries */
export type QueryCampaignItemsArgs = {
  filter?: Maybe<BaseFilter>;
};


/** Dealy queries */
export type QueryRelatedEquipmentsArgs = {
  id: Scalars['String'];
};


/** Dealy queries */
export type QueryGetWebshopFiltersArgs = {
  filter?: Maybe<WebshopFilter>;
};


/** Dealy queries */
export type QueryBillOfMaterialArgs = {
  filter?: Maybe<BillOfMaterialsFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
};


/** Dealy queries */
export type QueryBillOfMaterialByIdArgs = {
  id: Scalars['String'];
};


/** Dealy queries */
export type QueryShoppingListByIdArgs = {
  id: Scalars['String'];
};


/** Dealy queries */
export type QueryShoppingListsArgs = {
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
  onBehalfOfRetailerId?: Maybe<Scalars['Int']>;
};


/** Dealy queries */
export type QueryVehicleArgs = {
  input?: Maybe<VehicleInput>;
};


/** Dealy queries */
export type QueryConsignmentVehiclesArgs = {
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
};


/** Dealy queries */
export type QueryConsignmentVehicleByIdArgs = {
  id: Scalars['String'];
};


/** Dealy queries */
export type QueryExhibitionVehiclesArgs = {
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
};


/** Dealy queries */
export type QueryExhibitionVehicleByIdArgs = {
  id: Scalars['String'];
};


/** Dealy queries */
export type QueryApiOrderByIdArgs = {
  id: Scalars['String'];
};


/** Dealy queries */
export type QueryCustomerByIdArgs = {
  id: Scalars['String'];
};


/** Dealy queries */
export type QueryApiProductByIdArgs = {
  id: Scalars['String'];
};


/** Dealy queries */
export type QueryInvoicesArgs = {
  filter?: Maybe<InvoiceFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
};


/** Dealy queries */
export type QueryInvoiceByIdArgs = {
  id: Scalars['String'];
};


/** Dealy queries */
export type QueryOperationCodesArgs = {
  filter?: Maybe<OperationCodeFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
};


/** Dealy queries */
export type QueryOperationGroupByIdArgs = {
  id: Scalars['String'];
};


/** Dealy queries */
export type QueryOperationGroupAndCostsArgs = {
  vehicleId?: Maybe<Scalars['String']>;
};


/** Dealy queries */
export type QueryProductRuleByIdArgs = {
  id: Scalars['ID'];
};


/** Dealy queries */
export type QueryProductRulesArgs = {
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
};


/** Dealy queries */
export type QueryIdentifierSearchArgs = {
  filter: IdentifierSearchFilter;
};


/** Dealy queries */
export type QueryVolumeDiscountByIdArgs = {
  id: Scalars['ID'];
};


/** Dealy queries */
export type QueryVolumeDiscountsArgs = {
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
};


/** Dealy queries */
export type QueryArticleSearchArgs = {
  filter?: Maybe<ArticleSearchFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
};


/** Dealy queries */
export type QueryMaintenanceTaskByIdArgs = {
  id: Scalars['ID'];
};


/** Dealy queries */
export type QueryMaintenanceTasksArgs = {
  filter?: Maybe<MaintenanceTaskFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
};


/** Dealy queries */
export type QueryCustomPricesArgs = {
  filter?: Maybe<BaseFilter>;
};


/** Dealy queries */
export type QueryCustomPriceArgs = {
  articleId: Scalars['ID'];
  maintenanceCustomerId: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  freeText?: Maybe<Scalars['String']>;
  role: UserRole;
  roleDescription?: Maybe<Scalars['String']>;
  retailer?: Maybe<Retailer>;
  active: Scalars['Boolean'];
  acceptsEmailReminders: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum UserRole {
  SuperAdmin = 'SUPER_ADMIN',
  DealyAdmin = 'DEALY_ADMIN',
  DealySeller = 'DEALY_SELLER',
  RetailerAdmin = 'RETAILER_ADMIN',
  RetailerUser = 'RETAILER_USER',
  RetailerSeller = 'RETAILER_SELLER',
  RetailerWorkshop = 'RETAILER_WORKSHOP',
  Legacy = 'LEGACY',
  InsuranceUser = 'INSURANCE_USER'
}

export type Retailer = {
  __typename?: 'Retailer';
  id: Scalars['Int'];
  externalId: Scalars['String'];
  name: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  telephoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  makes: Array<RetailerMake>;
  users: Array<RetailerUser>;
  contactUser: RetailerUser;
  hasConsignmentVehicles: Scalars['Boolean'];
  hasExhibitionVehicles: Scalars['Boolean'];
  hasMaintenanceCustomers: Scalars['Boolean'];
  hasExternalFinancing: Scalars['Boolean'];
};

export type RetailerMake = {
  __typename?: 'RetailerMake';
  id: Scalars['ID'];
  make: Make;
  accessToSell: Scalars['Boolean'];
  accessToWorkshop: Scalars['Boolean'];
  warrantyHourlyPayment: Scalars['Float'];
};

export type Make = {
  __typename?: 'Make';
  id: Scalars['ID'];
  externalId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type RetailerUser = {
  __typename?: 'RetailerUser';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  freeText?: Maybe<Scalars['String']>;
  role: UserRole;
  roleDescription?: Maybe<Scalars['String']>;
  retailer?: Maybe<Retailer>;
  active: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};


export type UserByTokenReturnType = {
  __typename?: 'UserByTokenReturnType';
  /** Fetch logged in user fetched from token */
  user?: Maybe<User>;
  /** Boolean telling if user has right to act as any retailer to place orders */
  actAsRetailerRight?: Maybe<Scalars['Boolean']>;
};

export type BaseFilter = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
};

export type BaseSorting = {
  field: Scalars['String'];
  /** asc | desc */
  direction: Scalars['String'];
};

export type UserCollection = {
  __typename?: 'UserCollection';
  meta: PageInfoCustom;
  edges: Array<User>;
};

export type PageInfoCustom = {
  __typename?: 'PageInfoCustom';
  total: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type MakeFilter = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type MakeCollection = {
  __typename?: 'MakeCollection';
  meta: PageInfoCustom;
  edges: Array<Make>;
};

export type RetailerCollection = {
  __typename?: 'RetailerCollection';
  meta: PageInfoCustom;
  edges: Array<Retailer>;
};

export type WarrantyFilter = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  status?: Maybe<WarrantyStatus>;
  createdAt?: Maybe<Scalars['DateTime']>;
  repairedAt?: Maybe<Scalars['DateTime']>;
  fromDate?: Maybe<Scalars['DateTime']>;
  toDate?: Maybe<Scalars['DateTime']>;
};

export enum WarrantyStatus {
  Credited = 'CREDITED',
  Draft = 'DRAFT',
  Registered = 'REGISTERED',
  Complement = 'COMPLEMENT',
  Complemented = 'COMPLEMENTED',
  Approved = 'APPROVED',
  Processing = 'PROCESSING',
  Declined = 'DECLINED',
  LegacyWarranty = 'LEGACY_WARRANTY',
  WaitingForSage = 'WAITING_FOR_SAGE'
}

export type WarrantyCollection = {
  __typename?: 'WarrantyCollection';
  meta: PageInfoCustom;
  edges: Array<Warranty>;
};

export type Warranty = {
  id: Scalars['ID'];
  status: WarrantyStatus;
  declineReason?: Maybe<DeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
  retailer: Retailer;
  customerRemark?: Maybe<Scalars['String']>;
  workshopRemark?: Maybe<Scalars['String']>;
  rows: Array<Row>;
  attachements: Array<WorkshopAttachement>;
  registeredAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  repairedAt?: Maybe<Scalars['DateTime']>;
  sumRequested?: Maybe<Scalars['Float']>;
  sumCredited?: Maybe<Scalars['Float']>;
  type: WarrantyType;
  internalMessages: Array<WorkshopMessage>;
  externalMessages: Array<WorkshopMessage>;
  createdBy: User;
  damageImage?: Maybe<WorkshopImage>;
  shippingCost?: Maybe<Scalars['Float']>;
};

export enum DeclineReason {
  BasisNotCorrect = 'BASIS_NOT_CORRECT',
  NotWithinWarrantyTime = 'NOT_WITHIN_WARRANTY_TIME',
  NotCoveredByWarranty = 'NOT_COVERED_BY_WARRANTY',
  WarrantyNotAppliedInTime = 'WARRANTY_NOT_APPLIED_IN_TIME',
  Other = 'OTHER'
}

export type Row = {
  id: Scalars['ID'];
  quantity?: Maybe<Scalars['Float']>;
  originalQuantity?: Maybe<Scalars['Float']>;
  price: Scalars['Float'];
  percentage: Scalars['Float'];
  originalPercentage?: Maybe<Scalars['Float']>;
  total: Scalars['Float'];
  originalTotal?: Maybe<Scalars['Float']>;
  referenceName: Scalars['String'];
  description: Scalars['String'];
  status: RowStatus;
  complementReason?: Maybe<ComplementReason>;
  complementComment?: Maybe<Scalars['String']>;
  declineReason?: Maybe<DeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
  type?: Maybe<RowTypes>;
  originalHours?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  handledBy?: Maybe<User>;
  createdBy: User;
};

export enum RowStatus {
  Registered = 'REGISTERED',
  Approved = 'APPROVED',
  Complement = 'COMPLEMENT',
  Complemented = 'COMPLEMENTED',
  Declined = 'DECLINED',
  WaitingForSparePart = 'WAITING_FOR_SPARE_PART',
  SparePartSent = 'SPARE_PART_SENT',
  WaitingForWorkshop = 'WAITING_FOR_WORKSHOP',
  Updated = 'UPDATED'
}

export enum ComplementReason {
  ReturnExchangedMaterial = 'RETURN_EXCHANGED_MATERIAL',
  TakePictureOfDamageMaterial = 'TAKE_PICTURE_OF_DAMAGE_MATERIAL',
  Other = 'OTHER'
}

export enum RowTypes {
  Operation = 'OPERATION',
  Article = 'ARTICLE',
  Extern = 'EXTERN',
  Legacy = 'LEGACY'
}

export type WorkshopAttachement = {
  __typename?: 'WorkshopAttachement';
  id: Scalars['ID'];
  name: Scalars['String'];
  path: Scalars['String'];
  mimeType: Scalars['String'];
  isImage: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
};

export enum WarrantyType {
  Vehicle = 'VEHICLE',
  Part = 'PART',
  GoodWill = 'GOOD_WILL',
  PriorApproval = 'PRIOR_APPROVAL',
  Legacy = 'LEGACY'
}

export type WorkshopMessage = {
  __typename?: 'WorkshopMessage';
  id: Scalars['ID'];
  type: WorkshopMessageType;
  text: Scalars['String'];
  author: User;
  isRead: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
};

export enum WorkshopMessageType {
  Internal = 'INTERNAL',
  External = 'EXTERNAL'
}

export type WorkshopImage = {
  __typename?: 'WorkshopImage';
  id: Scalars['ID'];
  name: Scalars['String'];
  path: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type WarrantyVehicleCollection = {
  __typename?: 'WarrantyVehicleCollection';
  meta: PageInfoCustom;
  edges: Array<Vehicle>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  id: Scalars['String'];
  stockVehicle?: Maybe<StockVehicle>;
  vehicleIdentificationNumber: Scalars['String'];
  registrationNumber?: Maybe<Scalars['String']>;
  brand?: Maybe<Make>;
  modelSeries?: Maybe<ModelSeries>;
  model?: Maybe<Model>;
  startedAt?: Maybe<Scalars['DateTime']>;
  retailer?: Maybe<Retailer>;
  showDetailedInformation: Scalars['Boolean'];
  typeOfInstallation?: Maybe<TypeOfInstallation>;
  nonColorFactoryOptions?: Maybe<Array<FactoryOption>>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  engineNumber?: Maybe<Scalars['String']>;
  factoryCode?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  firstArrivedToSweden?: Maybe<Scalars['DateTime']>;
  producedAt?: Maybe<Scalars['DateTime']>;
  firstRegisteredAt?: Maybe<Scalars['DateTime']>;
  replacementText?: Maybe<Scalars['String']>;
  showBfiAndKey: Scalars['Boolean'];
  warranties: Array<Warranty>;
};

export type StockVehicle = {
  __typename?: 'StockVehicle';
  id: Scalars['UUID'];
  sageId: Scalars['String'];
  name: Scalars['String'];
  brand?: Maybe<Make>;
  vehicleType: VehicleType;
  modelSeries?: Maybe<ModelSeries>;
  model?: Maybe<Model>;
  color: Scalars['String'];
  colorCode: Scalars['String'];
  createdAt: Scalars['DateTime'];
  price: Scalars['Float'];
  vat: Scalars['Float'];
  discountedPrice?: Maybe<Scalars['Float']>;
  thumbnail?: Maybe<StockImage>;
  images: Array<StockImage>;
  stock: Stock;
  equipments: Array<Equipment>;
  articleNumber: Scalars['String'];
  factoryOptions: Array<FactoryOption>;
  vehicleCampaign: Scalars['Boolean'];
  active: Scalars['Boolean'];
};


export enum VehicleType {
  Atv = 'ATV',
  Utv = 'UTV',
  Ssv = 'SSV',
  Eatv = 'EATV',
  Essv = 'ESSV',
  Eutv = 'EUTV',
  ElectricScooter = 'ELECTRIC_SCOOTER',
  MopedCar = 'MOPED_CAR',
  WalkingScooter = 'WALKING_SCOOTER',
  ChildWheels = 'CHILD_WHEELS',
  MissingSageType = 'MISSING_SAGE_TYPE',
  SparePart = 'SPARE_PART',
  Equipment = 'EQUIPMENT',
  LightTruck = 'LIGHT_TRUCK'
}

export type ModelSeries = {
  __typename?: 'ModelSeries';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  brand?: Maybe<Make>;
  models: Array<Model>;
};

export type Model = {
  __typename?: 'Model';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  modelSeries?: Maybe<ModelSeries>;
};

export type StockImage = {
  __typename?: 'StockImage';
  id: Scalars['String'];
  name: Scalars['String'];
  path: Scalars['String'];
  equipment?: Maybe<Equipment>;
  stockVehicle?: Maybe<StockVehicle>;
  createdAt: Scalars['DateTime'];
};

export type Equipment = {
  __typename?: 'Equipment';
  id: Scalars['UUID'];
  name: Scalars['String'];
  articleNumber: Scalars['String'];
  modelSeries?: Maybe<ModelSeries>;
  model?: Maybe<Model>;
  vehicleType: VehicleType;
  price: Scalars['Float'];
  vat: Scalars['Float'];
  discountedPrice?: Maybe<Scalars['Float']>;
  stock: Stock;
  brand?: Maybe<Make>;
  thumbnail?: Maybe<StockImage>;
  images: Array<StockImage>;
  createdAt: Scalars['DateTime'];
  vehicleCampaign: Scalars['Boolean'];
  remark?: Maybe<Scalars['String']>;
  active: Scalars['Boolean'];
  equipmentGroup?: Maybe<EquipmentGroup>;
};

/** Stock information related to a specific article. */
export type Stock = {
  __typename?: 'Stock';
  warehouse: Scalars['Int'];
  /** Existing stock currently at retailers (konsignationslager) */
  atRetailer: Scalars['Int'];
  incoming: Scalars['Int'];
  status: StockStatus;
  incomingDeliveries: Array<IncomingDelivery>;
  updatedAt: Scalars['DateTime'];
};

export enum StockStatus {
  Incoming = 'INCOMING',
  Orderable = 'ORDERABLE',
  SwedenWarehouse = 'SWEDEN_WAREHOUSE'
}

/** An incoming delivery to Dealy */
export type IncomingDelivery = {
  __typename?: 'IncomingDelivery';
  /** Expected delivery date */
  deliveryDate: Scalars['DateTime'];
  /** Delivered quantity that will be made available to customers */
  quantity: Scalars['Int'];
};

export type EquipmentGroup = {
  __typename?: 'EquipmentGroup';
  id: Scalars['UUID'];
  name: Scalars['String'];
  externalId: Scalars['String'];
};

export type FactoryOption = {
  __typename?: 'FactoryOption';
  id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  factoryOptionType?: Maybe<FactoryOptionType>;
};

export type FactoryOptionType = {
  __typename?: 'FactoryOptionType';
  id: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export enum TypeOfInstallation {
  AtEndUser = 'AT_END_USER',
  AtRetailer = 'AT_RETAILER',
  AtGross = 'AT_GROSS',
  InStock = 'IN_STOCK',
  Declined = 'DECLINED'
}

export type WarrantyVehicleAllDataWithFolder = {
  __typename?: 'WarrantyVehicleAllDataWithFolder';
  brandFolder?: Maybe<Folder>;
  modelFolder?: Maybe<Folder>;
  vehicle?: Maybe<Vehicle>;
};

export type Folder = {
  __typename?: 'Folder';
  id: Scalars['String'];
  title: Scalars['String'];
  parent?: Maybe<Folder>;
  active: Scalars['Boolean'];
  children: Array<Folder>;
  explodedViews: Array<ExplodedView>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExplodedView = {
  __typename?: 'ExplodedView';
  id: Scalars['String'];
  title: Scalars['String'];
  explodedViewImages: Array<ExplodedViewImage>;
  explodedViewRows: Array<ExplodedViewRow>;
  brandFolder: Folder;
  modelFolder?: Maybe<Folder>;
  mainCategoryFolder?: Maybe<Folder>;
  subCategoryFolder?: Maybe<Folder>;
  folder: Folder;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ExplodedViewImage = {
  __typename?: 'ExplodedViewImage';
  id: Scalars['String'];
  name: Scalars['String'];
  path: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type ExplodedViewRow = {
  __typename?: 'ExplodedViewRow';
  id: Scalars['String'];
  referenceNumber: Scalars['String'];
  stockVehicle?: Maybe<StockVehicle>;
  equipment?: Maybe<Equipment>;
  sparePart?: Maybe<SparePart>;
  billOfMaterial?: Maybe<BillOfMaterial>;
};

export type SparePart = {
  __typename?: 'SparePart';
  id: Scalars['UUID'];
  articleNumber: Scalars['String'];
  name: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  discountedPrice?: Maybe<Scalars['Float']>;
  vat: Scalars['Float'];
  modelSeries?: Maybe<ModelSeries>;
  thumbnail?: Maybe<StockImage>;
  images: Array<StockImage>;
  model?: Maybe<Model>;
  explodedViewRows: Array<ExplodedViewRow>;
  stock: Stock;
  active: Scalars['Boolean'];
  vehicleCampaign: Scalars['Boolean'];
};

export type BillOfMaterial = {
  __typename?: 'BillOfMaterial';
  id: Scalars['UUID'];
  articleNumber: Scalars['String'];
  active: Scalars['Boolean'];
  bomCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fromDate?: Maybe<Scalars['DateTime']>;
  toDate?: Maybe<Scalars['DateTime']>;
  bomRows: Array<BillOfMaterialRow>;
  price: Scalars['Float'];
  stock: Stock;
  discountedPrice?: Maybe<Scalars['Float']>;
  images: Array<StockImage>;
  thumbnail?: Maybe<StockImage>;
  vehicleCampaign: Scalars['Boolean'];
  brand?: Maybe<Make>;
  equipmentGroup?: Maybe<EquipmentGroup>;
};

export type BillOfMaterialRow = {
  __typename?: 'BillOfMaterialRow';
  id: Scalars['ID'];
  linkQuantity?: Maybe<Scalars['Int']>;
  fromDate?: Maybe<Scalars['DateTime']>;
  toDate?: Maybe<Scalars['DateTime']>;
  stockVehicle?: Maybe<StockVehicle>;
  equipment?: Maybe<Equipment>;
  sparePart?: Maybe<SparePart>;
};

export type WarrantyDisplayView = {
  __typename?: 'WarrantyDisplayView';
  id: Scalars['ID'];
  name: Scalars['String'];
  columnData: Array<ColumnData>;
  user: User;
  active: Scalars['Boolean'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ColumnData = {
  __typename?: 'ColumnData';
  position: Scalars['Int'];
  name: ColumnNames;
  active: Scalars['Boolean'];
};

export enum ColumnNames {
  WarrantyNumber = 'WARRANTY_NUMBER',
  LegacyWarrantyNumber = 'LEGACY_WARRANTY_NUMBER',
  Retailer = 'RETAILER',
  LegacyRetailer = 'LEGACY_RETAILER',
  VehicleIdentificationNumber = 'VEHICLE_IDENTIFICATION_NUMBER',
  RegistrationNumber = 'REGISTRATION_NUMBER',
  WarrantyStartedAt = 'WARRANTY_STARTED_AT',
  Status = 'STATUS',
  RepairedAt = 'REPAIRED_AT',
  CreatedAt = 'CREATED_AT',
  Requested = 'REQUESTED',
  Credited = 'CREDITED',
  DefectPartNumber = 'DEFECT_PART_NUMBER',
  DefectPartTypeCode = 'DEFECT_PART_TYPE_CODE',
  SymptomCode = 'SYMPTOM_CODE',
  CustomerRemark = 'CUSTOMER_REMARK',
  WorkshopRemark = 'WORKSHOP_REMARK',
  Type = 'TYPE',
  Messages = 'MESSAGES',
  ShippingCost = 'SHIPPING_COST'
}

export type ExplodedViewFilter = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  brandFolderId?: Maybe<Scalars['UUID']>;
  modelFolderId?: Maybe<Scalars['UUID']>;
  mainCategoryFolderId?: Maybe<Scalars['UUID']>;
  subCategoryFolderId?: Maybe<Scalars['UUID']>;
  searchTerm?: Maybe<Scalars['String']>;
  searchForSpecificItemTerm?: Maybe<Scalars['String']>;
  showInactive?: Maybe<Scalars['Boolean']>;
};

export type ExplodedViewCollection = {
  __typename?: 'ExplodedViewCollection';
  meta: PageInfoCustom;
  edges: Array<ExplodedView>;
};

export type ExplodedViewRowFilter = {
  explodedViewId: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
};

export type ExplodedViewRowCollection = {
  __typename?: 'ExplodedViewRowCollection';
  meta: PageInfoCustom;
  edges: Array<ExplodedViewRow>;
};

export type FoldersByVehicleId = {
  __typename?: 'FoldersByVehicleId';
  folderByMake?: Maybe<Folder>;
  foldersByModelSeries: Array<Folder>;
};

export type SparePartCollection = {
  __typename?: 'SparePartCollection';
  meta: PageInfoCustom;
  edges: Array<SparePart>;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['String'];
  serialNumber: Scalars['String'];
  externalId: Scalars['String'];
  status: OrderStatus;
  invoice?: Maybe<Scalars['String']>;
  items: Array<OrderItem>;
  /** All order items except fees */
  products: Array<OrderItem>;
  /** Only order items that represent fees */
  fees: Array<OrderItem>;
  createdAt: Scalars['DateTime'];
  invoicedAt?: Maybe<Scalars['DateTime']>;
  referenceUser?: Maybe<User>;
  createdBy?: Maybe<User>;
  retailer?: Maybe<Retailer>;
  total: Scalars['Float'];
  vat?: Maybe<Scalars['Float']>;
  totalQuantity: Scalars['Int'];
  invoices: Array<Maybe<Invoice>>;
  deliveryType: DeliveryType;
  address: Scalars['String'];
  postalCode: Scalars['String'];
  postalCity: Scalars['String'];
  partialDelivery: Scalars['Boolean'];
  orderReferenceMessage: Scalars['String'];
  externallyFinanced: Scalars['Boolean'];
};

export enum OrderStatus {
  Processing = 'PROCESSING',
  PartiallyAllocated = 'PARTIALLY_ALLOCATED',
  Allocated = 'ALLOCATED',
  Blocked = 'BLOCKED',
  PartiallyDelivered = 'PARTIALLY_DELIVERED',
  Delivered = 'DELIVERED',
  PartiallyInvoiced = 'PARTIALLY_INVOICED',
  Invoiced = 'INVOICED'
}

export type OrderItem = {
  __typename?: 'OrderItem';
  id: Scalars['String'];
  vehicle?: Maybe<StockVehicle>;
  equipment?: Maybe<Equipment>;
  sparePart?: Maybe<SparePart>;
  billOfMaterial?: Maybe<BillOfMaterial>;
  fee?: Maybe<Fee>;
  replacedProduct?: Maybe<ReplacedProduct>;
  deliveredQuantity: Scalars['Int'];
  freeQuantity: Scalars['Int'];
  /** Describes how many of each individual item correspond with the different order statuses. Sums to total order item quantity */
  detailedQuantities?: Maybe<DetailedOrderItemQuantities>;
  remark: Scalars['String'];
  order: Order;
  status: OrderItemStatus;
  finalPrice: Scalars['Float'];
  unitPrice: Scalars['Float'];
  stockArrivalDate?: Maybe<Scalars['DateTime']>;
  connection?: Maybe<OrderItem>;
  invoices: Array<Invoice>;
  connectionType?: Maybe<OrderItemConnection>;
};

export type Fee = {
  __typename?: 'Fee';
  id: Scalars['UUID'];
  name: Scalars['String'];
  articleNumber: Scalars['String'];
  price: Scalars['Float'];
  vat: Scalars['Float'];
  discountedPrice?: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  isAllowedOnVehicles: Scalars['Boolean'];
  active: Scalars['Boolean'];
};

export type ReplacedProduct = {
  __typename?: 'ReplacedProduct';
  id: Scalars['String'];
  name: Scalars['String'];
  articleNumber: Scalars['String'];
};

export type DetailedOrderItemQuantities = {
  __typename?: 'DetailedOrderItemQuantities';
  processing: Scalars['Int'];
  allocated?: Maybe<Scalars['Int']>;
  blocked?: Maybe<Scalars['Int']>;
  delivered?: Maybe<Scalars['Int']>;
  invoiced?: Maybe<Scalars['Int']>;
};

export enum OrderItemStatus {
  Processing = 'PROCESSING',
  Allocated = 'ALLOCATED',
  Blocked = 'BLOCKED',
  Delivered = 'DELIVERED',
  Invoiced = 'INVOICED'
}

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['String'];
  invoiceDate: Scalars['DateTime'];
  buyer?: Maybe<Retailer>;
  deliveryAddress?: Maybe<Scalars['String']>;
  dueDate?: Maybe<Scalars['DateTime']>;
  priceExclVat?: Maybe<Scalars['Float']>;
  priceInclVat?: Maybe<Scalars['Float']>;
  invoiceRows: Array<InvoiceRow>;
  status: InvoiceStatus;
  invoiceType?: Maybe<InvoiceType>;
  paymentCondition?: Maybe<PaymentCondition>;
  orderReferences?: Maybe<Scalars['String']>;
  orderReferenceMessage?: Maybe<Scalars['String']>;
};

export type InvoiceRow = {
  __typename?: 'InvoiceRow';
  id: Scalars['String'];
  sageId: Scalars['String'];
  articleNumber?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  quantity: Scalars['Int'];
  netPriceExclVat: Scalars['Float'];
  grossPriceExclVat: Scalars['Float'];
};

export enum InvoiceStatus {
  Payed = 'PAYED',
  NotPayed = 'NOT_PAYED'
}

export enum InvoiceType {
  ProForma = 'PRO_FORMA',
  CreditNote = 'CREDIT_NOTE',
  Invoice = 'INVOICE',
  CreditNoteWarranty = 'CREDIT_NOTE_WARRANTY',
  InterestInvoice = 'INTEREST_INVOICE',
  NotSetInSage = 'NOT_SET_IN_SAGE'
}

export enum PaymentCondition {
  Inv60 = 'INV60',
  Inv30 = 'INV30',
  Inv20 = 'INV20',
  Inv10 = 'INV10',
  Inv0 = 'INV0',
  Eom = 'EOM'
}

export enum OrderItemConnection {
  HiddenProductReference = 'HIDDEN_PRODUCT_REFERENCE',
  VisibleProductReference = 'VISIBLE_PRODUCT_REFERENCE',
  CampaignVehicleAccessory = 'CAMPAIGN_VEHICLE_ACCESSORY',
  VehicleAccessory = 'VEHICLE_ACCESSORY'
}

export enum DeliveryType {
  Standard = 'STANDARD',
  Pre12 = 'PRE12',
  Express = 'EXPRESS',
  Pickup = 'PICKUP'
}

export type OrderFilter = {
  searchTerm?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type OrderCollection = {
  __typename?: 'OrderCollection';
  meta: PageInfoCustom;
  edges: Array<Order>;
};

export type OrderItemFilter = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  actAsRetailerId?: Maybe<Scalars['Int']>;
};

export type OrderItemCollection = {
  __typename?: 'OrderItemCollection';
  meta: PageInfoCustom;
  edges: Array<OrderItem>;
};

export type WebshopFilter = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  actAsRetailerById?: Maybe<Scalars['Int']>;
  /** Only return filters that are for sale */
  forSale?: Maybe<Scalars['Boolean']>;
  vehicleType?: Maybe<Array<VehicleType>>;
  brand?: Maybe<Array<Scalars['String']>>;
  modelSeries?: Maybe<Array<Scalars['String']>>;
  model?: Maybe<Array<Scalars['String']>>;
  color?: Maybe<Array<Scalars['String']>>;
  stockBalance?: Maybe<Array<StockStatus>>;
};

export type StockVehicleCollection = {
  __typename?: 'StockVehicleCollection';
  meta: PageInfoCustom;
  edges: Array<StockVehicle>;
};

export type EquipmentCollection = {
  __typename?: 'EquipmentCollection';
  meta: PageInfoCustom;
  edges: Array<Equipment>;
};

export type FullTextSearchCollection = {
  __typename?: 'FullTextSearchCollection';
  meta: PageInfoCustom;
  edges: Array<FullTextSearch>;
};

export type FullTextSearch = {
  __typename?: 'FullTextSearch';
  id: Scalars['ID'];
  title: Scalars['String'];
  articleNumber: Scalars['String'];
  price: Scalars['Float'];
  discountedPrice?: Maybe<Scalars['Float']>;
  thumbnail?: Maybe<StockImage>;
  type: OrderItemCategory;
  vehicle?: Maybe<StockVehicle>;
  equipment?: Maybe<Equipment>;
  sparePart?: Maybe<SparePart>;
};

export enum OrderItemCategory {
  Vehicle = 'VEHICLE',
  SparePart = 'SPARE_PART',
  Equipment = 'EQUIPMENT',
  BillOfMaterial = 'BILL_OF_MATERIAL'
}

export type RelatedProducts = {
  __typename?: 'RelatedProducts';
  campaigns: Array<RelatedProduct>;
  relatedEquipments: Array<RelatedProduct>;
};

export type RelatedProduct = {
  __typename?: 'RelatedProduct';
  billOfMaterial?: Maybe<BillOfMaterial>;
  equipment?: Maybe<Equipment>;
};

export type WebshopFilters = {
  __typename?: 'WebshopFilters';
  vehicleTypes: Array<VehicleType>;
  makes: Array<Make>;
  modelSeries: Array<ModelSeries>;
  models: Array<Model>;
  colors: Array<FactoryOption>;
  equipmentGroups: Array<EquipmentGroup>;
  stockStatus: Array<StockStatus>;
};

export type BillOfMaterialsFilter = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  billOfMaterialType?: Maybe<BillOfMaterialType>;
};

export enum BillOfMaterialType {
  Equipment = 'EQUIPMENT',
  SparePart = 'SPARE_PART'
}

export type BillOfMaterialCollection = {
  __typename?: 'BillOfMaterialCollection';
  meta: PageInfoCustom;
  edges: Array<BillOfMaterial>;
};

export type ShoppingList = {
  __typename?: 'ShoppingList';
  id: Scalars['String'];
  name: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  items: Array<ShoppingListItem>;
  createdBy: User;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type ShoppingListItem = {
  __typename?: 'ShoppingListItem';
  id: Scalars['String'];
  amount: Scalars['Int'];
  vehicle?: Maybe<StockVehicle>;
  equipment?: Maybe<Equipment>;
  sparePart?: Maybe<SparePart>;
  billOfMaterial?: Maybe<BillOfMaterial>;
  isCampaignEquipment?: Maybe<Scalars['Boolean']>;
  stockVehicleProductCount?: Maybe<Scalars['Int']>;
  relatedVehicle?: Maybe<StockVehicle>;
};

export type ShoppingListCollection = {
  __typename?: 'ShoppingListCollection';
  meta: PageInfoCustom;
  edges: Array<ShoppingList>;
};

export type VehicleInput = {
  id?: Maybe<Scalars['ID']>;
  /** Requires exact match on vehicle identification number or registration number */
  vinOrRegNum?: Maybe<Scalars['String']>;
};

export type VehicleCollection = {
  __typename?: 'VehicleCollection';
  meta: PageInfoCustom;
  edges: Array<Vehicle>;
};

export type ApiOrder = {
  __typename?: 'ApiOrder';
  id: Scalars['String'];
  customerReference?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  orderDate?: Maybe<Scalars['DateTime']>;
  deliveryContactNumber?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  payBy?: Maybe<Scalars['String']>;
  deliveryAddress?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  invoiceReference?: Maybe<Scalars['String']>;
  orderStatus?: Maybe<Scalars['String']>;
  deliveryStatus?: Maybe<Scalars['String']>;
  invoiceStatus?: Maybe<Scalars['String']>;
  creditStatus?: Maybe<Scalars['String']>;
  partialDelivery?: Maybe<Scalars['Int']>;
  priceExcludingVAT?: Maybe<Scalars['Float']>;
  priceIncludingVAT?: Maybe<Scalars['Float']>;
  orderRows: Array<OrderRow>;
};

export type OrderRow = {
  __typename?: 'OrderRow';
  productId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  priceExcludingVAT?: Maybe<Scalars['Float']>;
  priceIncludingVAT?: Maybe<Scalars['Float']>;
  requestedDeliverDate?: Maybe<Scalars['DateTime']>;
  expectedDeliveryDate?: Maybe<Scalars['DateTime']>;
  rowStatus?: Maybe<Scalars['Int']>;
  tenderId?: Maybe<Scalars['String']>;
  closed?: Maybe<Scalars['Int']>;
  closingReason?: Maybe<Scalars['String']>;
};

export type NoContent = {
  __typename?: 'NoContent';
  /** if the action was successful or not */
  success: Scalars['Boolean'];
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['ID'];
  companyName: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryName?: Maybe<Scalars['String']>;
  telephoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  organizationNumber?: Maybe<Scalars['String']>;
  contacts: Array<Contact>;
  trademarks: Array<Trademark>;
};

export type Contact = {
  __typename?: 'Contact';
  contactCode?: Maybe<Scalars['String']>;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  contactCountry?: Maybe<Scalars['String']>;
  contactTelephone?: Maybe<Scalars['String']>;
  contactEmail?: Maybe<Scalars['String']>;
  contactRole?: Maybe<Scalars['String']>;
};

export type Trademark = {
  __typename?: 'Trademark';
  trademark: Make;
  trademarkId: Scalars['String'];
  trademarkName: Scalars['String'];
  accessToSell: SageRetailerEnum;
  accessToWorkshop: SageWorkshopEnum;
  defaultHourlyPayment: Scalars['Float'];
};

export enum SageRetailerEnum {
  No = 'NO',
  Yes = 'YES',
  Store = 'STORE',
  PowerStore = 'POWER_STORE',
  StoreHybrid = 'STORE_HYBRID',
  PowerStoreHybrid = 'POWER_STORE_HYBRID'
}

export enum SageWorkshopEnum {
  No = 'NO',
  Yes = 'YES',
  HybridYes = 'HYBRID_YES'
}

export type Product = {
  __typename?: 'Product';
  id: Scalars['String'];
  articleNumber: Scalars['String'];
  category: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  make?: Maybe<Make>;
  type?: Maybe<Scalars['String']>;
  modelSeries?: Maybe<Scalars['String']>;
  model?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  colorCode?: Maybe<Scalars['String']>;
  stockUnits?: Maybe<Scalars['Int']>;
  stockStatus?: Maybe<Scalars['String']>;
  alternativeProducts?: Maybe<Array<AlternativeProducts>>;
};

export type AlternativeProducts = {
  __typename?: 'AlternativeProducts';
  type: Scalars['String'];
  description: Scalars['String'];
};

export type InvoiceFilter = {
  searchTerm?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type InvoiceCollection = {
  __typename?: 'InvoiceCollection';
  meta: PageInfoCustom;
  edges: Array<Invoice>;
};

export type OperationCodeFilter = {
  operationGroupId: Scalars['ID'];
  searchTerm?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type OperationCodeCollection = {
  __typename?: 'OperationCodeCollection';
  meta: PageInfoCustom;
  edges: Array<OperationCode>;
};

export type OperationCode = {
  __typename?: 'OperationCode';
  id: Scalars['String'];
  code: Scalars['String'];
  category: OperationCodeCategory;
  description: Scalars['String'];
  time?: Maybe<Scalars['Float']>;
};

export enum OperationCodeCategory {
  Engine = 'ENGINE',
  EngineBrakets = 'ENGINE_BRAKETS',
  AdmissionExhaust = 'ADMISSION_EXHAUST',
  Variators = 'VARIATORS',
  Reverser = 'REVERSER',
  HalfShafts = 'HALF_SHAFTS',
  FrontTrain = 'FRONT_TRAIN',
  RearTrain = 'REAR_TRAIN',
  Brakes = 'BRAKES',
  Steering = 'STEERING',
  ElectricalEquipment = 'ELECTRICAL_EQUIPMENT',
  EquipmentRadio = 'EQUIPMENT_RADIO',
  CentralLocking = 'CENTRAL_LOCKING',
  Preheating = 'PREHEATING',
  ElectricalStarter = 'ELECTRICAL_STARTER',
  LoadCircuit = 'LOAD_CIRCUIT',
  LightsHorn = 'LIGHTS_HORN',
  WindscreenWiperOrWasher = 'WINDSCREEN_WIPER_OR_WASHER',
  DashboardAndInstruments = 'DASHBOARD_AND_INSTRUMENTS',
  InternalHeaterUnitEngineCooling = 'INTERNAL_HEATER_UNIT_ENGINE_COOLING',
  Glasses = 'GLASSES',
  BodyChassisOrFrame = 'BODY_CHASSIS_OR_FRAME',
  InternalTrimAndBody = 'INTERNAL_TRIM_AND_BODY',
  Doors = 'DOORS',
  Hatchback = 'HATCHBACK',
  EngineBonnet = 'ENGINE_BONNET',
  Seats = 'SEATS',
  Miscellaneous = 'MISCELLANEOUS',
  Ingredients = 'INGREDIENTS',
  AcSystem = 'AC_SYSTEM',
  Karossdelar = 'KAROSSDELAR',
  Styrning = 'STYRNING',
  Hjulupphangning = 'HJULUPPHANGNING',
  Avgassystem = 'AVGASSYSTEM',
  Bromssystem = 'BROMSSYSTEM',
  Tandsystem = 'TANDSYSTEM',
  BranslesystemForgasare = 'BRANSLESYSTEM_FORGASARE',
  BranslesystemEfi = 'BRANSLESYSTEM_EFI',
  Elsystem = 'ELSYSTEM',
  Belysning = 'BELYSNING',
  Drivsystem = 'DRIVSYSTEM',
  Kylsystem = 'KYLSYSTEM',
  Ovrigt = 'OVRIGT',
  MotorEllerVaxellada = 'MOTOR_ELLER_VAXELLADA',
  AccessoriesAndSoundProofing = 'ACCESSORIES_AND_SOUND_PROOFING',
  Body = 'BODY',
  Electricity = 'ELECTRICITY',
  Gearbox = 'GEARBOX',
  Axle = 'AXLE',
  Variator = 'VARIATOR',
  ManuelltArbete = 'MANUELLT_ARBETE',
  Chassi = 'CHASSI',
  Drivechain = 'DRIVECHAIN',
  Electrical = 'ELECTRICAL',
  Suspension = 'SUSPENSION',
  Wheel = 'WHEEL',
  SteeringAndSuspension = 'STEERING_AND_SUSPENSION',
  CoolingSystem = 'COOLING_SYSTEM',
  FuelSystem = 'FUEL_SYSTEM',
  Cvt = 'CVT',
  TransmissionAndGearShift = 'TRANSMISSION_AND_GEAR_SHIFT',
  BrakesIncludesBleeding = 'BRAKES_INCLUDES_BLEEDING',
  BodyAndFrame = 'BODY_AND_FRAME',
  FrontAndFinalDrive = 'FRONT_AND_FINAL_DRIVE',
  Chassis = 'CHASSIS'
}

export type OperationGroup = {
  __typename?: 'OperationGroup';
  id: Scalars['String'];
  name: Scalars['String'];
  make?: Maybe<Make>;
  modelSeries?: Maybe<ModelSeries>;
  model?: Maybe<Model>;
  operationCodes: Array<OperationCode>;
};

export type OperationGroupAndCosts = {
  __typename?: 'OperationGroupAndCosts';
  operationGroup: OperationGroup;
  warrantyHourlyCost?: Maybe<Scalars['Float']>;
  manualHourlyCost?: Maybe<Scalars['Float']>;
};

export type OperationGroupCollection = {
  __typename?: 'OperationGroupCollection';
  meta: PageInfoCustom;
  edges: Array<ConciseOperationGroup>;
};

export type ConciseOperationGroup = {
  __typename?: 'ConciseOperationGroup';
  id: Scalars['String'];
  name: Scalars['String'];
  make?: Maybe<Make>;
  modelSeries?: Maybe<ModelSeries>;
  model?: Maybe<Model>;
  operationCodeCount: Scalars['Int'];
};

export type ProductRule = {
  __typename?: 'ProductRule';
  id: Scalars['ID'];
  name: Scalars['String'];
  showInCart: Scalars['Boolean'];
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  discountTwo?: Maybe<Scalars['Float']>;
  discountThree?: Maybe<Scalars['Float']>;
  discountCode?: Maybe<DiscountCode>;
  stockVehicle?: Maybe<StockVehicle>;
  equipment?: Maybe<Equipment>;
  sparePart?: Maybe<SparePart>;
  billOfMaterial?: Maybe<BillOfMaterial>;
  productReferences: Array<ProductReference>;
};

export enum DiscountCode {
  /** Demouttag */
  Demo = 'DEMO',
  /** Mängdrabatt */
  QuantityDiscount = 'QUANTITY_DISCOUNT',
  /** Fraktskada */
  FreightDamage = 'FREIGHT_DAMAGE',
  /** Garantier */
  Warranties = 'WARRANTIES',
  /** Gåva */
  Gift = 'GIFT',
  /** Kampanj */
  Campaign = 'CAMPAIGN',
  /** Marknad */
  Market = 'MARKET',
  /** Saknas vid leverans */
  MissingOnDelivery = 'MISSING_ON_DELIVERY',
  /** Sampack */
  Sampack = 'SAMPACK',
  /** Utförsäljning */
  ClearanceSale = 'CLEARANCE_SALE'
}

export type ProductReference = {
  __typename?: 'ProductReference';
  id: Scalars['ID'];
  vehicleType?: Maybe<VehicleType>;
  make?: Maybe<Make>;
  modelSeries?: Maybe<ModelSeries>;
  model?: Maybe<Model>;
  stockVehicle?: Maybe<StockVehicle>;
  equipment?: Maybe<Equipment>;
  sparePart?: Maybe<SparePart>;
  billOfMaterial?: Maybe<BillOfMaterial>;
};

export type ProductRuleCollection = {
  __typename?: 'ProductRuleCollection';
  meta: PageInfoCustom;
  edges: Array<ProductRule>;
};

export type IdentifierSearchFilter = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm: Scalars['String'];
  productIdentifier: ProductIdentifier;
};

export enum ProductIdentifier {
  VehicleType = 'VEHICLE_TYPE',
  Make = 'MAKE',
  ModelSeries = 'MODEL_SERIES',
  Model = 'MODEL',
  StockVehicle = 'STOCK_VEHICLE',
  Equipment = 'EQUIPMENT',
  SparePart = 'SPARE_PART',
  BillOfMaterial = 'BILL_OF_MATERIAL'
}

export type SearchResultCollection = {
  __typename?: 'SearchResultCollection';
  meta: PageInfoCustom;
  edges?: Maybe<SearchResponseEdges>;
};

export type SearchResponseEdges = {
  __typename?: 'SearchResponseEdges';
  /** Searches by name, articleNumber modelSeries.name and model.name */
  stockVehicles?: Maybe<Array<StockVehicle>>;
  /** Searches by name, articleNumber modelSeries.name and model.name */
  equipments?: Maybe<Array<Equipment>>;
  /** Searches by sparePartName and articleNumber */
  spareParts?: Maybe<Array<SparePart>>;
  /** Searches by articleNumber and description */
  billOfMaterials?: Maybe<Array<BillOfMaterial>>;
  /** Searches by name */
  makes?: Maybe<Array<Make>>;
  /** Searches by name */
  modelSeries?: Maybe<Array<ModelSeries>>;
  /** Searches by name and description */
  models?: Maybe<Array<Model>>;
  /** Searches by key and value of enum */
  vehicleTypes?: Maybe<Array<VehicleType>>;
};

export type VolumeDiscount = {
  __typename?: 'VolumeDiscount';
  id: Scalars['ID'];
  name: Scalars['String'];
  volume: Scalars['Int'];
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  discountOne?: Maybe<Scalars['Float']>;
  discountTwo?: Maybe<Scalars['Float']>;
  discountThree?: Maybe<Scalars['Float']>;
  mainProductIdentifier: ProductIdentifierEntities;
  level: ProductIdentifier;
};

export type ProductIdentifierEntities = {
  __typename?: 'ProductIdentifierEntities';
  stockVehicle?: Maybe<StockVehicle>;
  equipment?: Maybe<Equipment>;
  sparePart?: Maybe<SparePart>;
  billOfMaterial?: Maybe<BillOfMaterial>;
  make?: Maybe<Make>;
  modelSeries?: Maybe<ModelSeries>;
  model?: Maybe<Model>;
  vehicleType?: Maybe<VehicleType>;
};

export type VolumeDiscountCollection = {
  __typename?: 'VolumeDiscountCollection';
  meta: PageInfoCustom;
  edges: Array<VolumeDiscount>;
};

export type ArticleSearchFilter = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  /** Specifies which type to search for. Searches all by default */
  articleType?: Maybe<Array<ProductIdentifier>>;
  actAsRetailerId?: Maybe<Scalars['Int']>;
  showInactive?: Maybe<Scalars['Boolean']>;
  billOfMaterialType?: Maybe<BillOfMaterialType>;
  stockBalance?: Maybe<Array<StockStatus>>;
  makes?: Maybe<Array<FilterOption>>;
  equipmentGroups?: Maybe<Array<Scalars['UUID']>>;
};

export type FilterOption = {
  uuid?: Maybe<Scalars['UUID']>;
  custom?: Maybe<CustomFilterOption>;
};

export enum CustomFilterOption {
  NoBrand = 'NO_BRAND'
}

export type ArticleSearchCollection = {
  __typename?: 'ArticleSearchCollection';
  meta: PageInfoCustom;
  edges: Array<Article>;
};

export type Article = StockVehicle | Equipment | SparePart | BillOfMaterial | Fee;

/** Maintenance work on vehicles owned by maintenance customers */
export type MaintenanceTask = {
  __typename?: 'MaintenanceTask';
  id: Scalars['ID'];
  serviceId?: Maybe<Scalars['String']>;
  maintenanceCustomer?: Maybe<MaintenanceCustomer>;
  vehicle?: Maybe<Vehicle>;
  retailer: Retailer;
  status: MaintenanceTaskStatus;
  createdBy: User;
  meterReading?: Maybe<Scalars['Float']>;
  customerRemark?: Maybe<Scalars['String']>;
  workshopRemark?: Maybe<Scalars['String']>;
  freeText?: Maybe<Scalars['String']>;
  customerReference?: Maybe<Scalars['String']>;
  workPerformedAt?: Maybe<Scalars['DateTime']>;
  sumRequested: Scalars['Float'];
  sumCredited: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  customerApprovalBy?: Maybe<Scalars['String']>;
  customerApprovalAt?: Maybe<Scalars['DateTime']>;
  meterReadingImage?: Maybe<WorkshopImage>;
  damageImage?: Maybe<WorkshopImage>;
  deliveryServiceImage?: Maybe<WorkshopImage>;
  attachements: Array<WorkshopAttachement>;
  rows: Array<MaintenanceRow>;
  internalMessages: Array<WorkshopMessage>;
  externalMessages: Array<WorkshopMessage>;
};

/** Customers that have a maintenance contract with dealy */
export type MaintenanceCustomer = {
  __typename?: 'MaintenanceCustomer';
  id: Scalars['ID'];
  name: Scalars['String'];
  hourlyMaintenanceRate: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  externalId: Scalars['String'];
};

export enum MaintenanceTaskStatus {
  Draft = 'DRAFT',
  Registered = 'REGISTERED',
  Complement = 'COMPLEMENT',
  Complemented = 'COMPLEMENTED',
  Processing = 'PROCESSING',
  Approved = 'APPROVED',
  Declined = 'DECLINED',
  Credited = 'CREDITED',
  WaitingForSage = 'WAITING_FOR_SAGE'
}

/** Row attached to a maintenance task. Has various types depending on type of work */
export type MaintenanceRow = {
  id: Scalars['UUID'];
  workCategory: MaintenanceWorkCategory;
  status: MaintenanceRowStatus;
  complementReason?: Maybe<MaintenanceRowComplementReason>;
  complementComment?: Maybe<Scalars['String']>;
  declineReason?: Maybe<MaintenanceRowDeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
  createdBy: User;
  handledBy?: Maybe<User>;
  /** Cost per unit */
  price: Scalars['Float'];
  /** Requested price by retailer at time of task registration or row creation */
  requestedPrice: Scalars['Float'];
  /** Time in hours or number of units */
  quantity: Scalars['Float'];
  /** Requested quantity by retailer at time of task registration or row creation */
  requestedQuantity: Scalars['Float'];
};

export enum MaintenanceWorkCategory {
  DeliveryService = 'DELIVERY_SERVICE',
  Service = 'SERVICE',
  Repair = 'REPAIR',
  Collision = 'COLLISION'
}

export enum MaintenanceRowStatus {
  Registered = 'REGISTERED',
  Complement = 'COMPLEMENT',
  Complemented = 'COMPLEMENTED',
  Approved = 'APPROVED',
  Declined = 'DECLINED'
}

export enum MaintenanceRowComplementReason {
  NoDamageImage = 'NO_DAMAGE_IMAGE',
  NoApproval = 'NO_APPROVAL',
  Other = 'OTHER'
}

export enum MaintenanceRowDeclineReason {
  Overdue = 'OVERDUE',
  Incorrect = 'INCORRECT',
  LackingApproval = 'LACKING_APPROVAL',
  Other = 'OTHER'
}

export type MaintenanceCustomerCollection = {
  __typename?: 'MaintenanceCustomerCollection';
  meta: PageInfoCustom;
  edges: Array<MaintenanceCustomer>;
};

export type MaintenanceTaskFilter = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  searchTerm?: Maybe<Scalars['String']>;
  status?: Maybe<MaintenanceTaskStatus>;
};

export type MaintenanceTaskCollection = {
  __typename?: 'MaintenanceTaskCollection';
  meta: PageInfoCustom;
  edges: Array<MaintenanceTask>;
};

export type CustomPriceCollection = {
  __typename?: 'CustomPriceCollection';
  meta: PageInfoCustom;
  edges: Array<CustomPrice>;
};

/** Custom price per maintenance customer */
export type CustomPrice = {
  __typename?: 'CustomPrice';
  id: Scalars['ID'];
  price: Scalars['Float'];
  article: Article;
  maintenanceCustomer: MaintenanceCustomer;
  updatedAt: Scalars['DateTime'];
};

/** All Dealy mutations */
export type Mutation = {
  __typename?: 'Mutation';
  login: LoginReturnType;
  resetPasswordAction: NoContent;
  resetPasswordConfirm: LoginReturnType;
  resetOwnPassword: NoContent;
  createRetailerAccount: UserOrError;
  createDealyAccount: UserOrError;
  deleteDealyUser?: Maybe<NoContent>;
  deleteRetailerUser?: Maybe<NoContent>;
  deleteMyself?: Maybe<NoContent>;
  updateUser: UserOrError;
  updateAcceptsEmailReminders: User;
  createRetailer: RetailerOrError;
  updateRetailer: RetailerOrError;
  updateMaintenanceCustomerAccess: Retailer;
  deleteRetailer: NoContent;
  updateShippingCost: Warranty;
  /** Possible named errors are listed in the enum WarrantyError */
  vehicleWarrantyUpsert: VehicleWarranty;
  /** Possible named errors are listed in the enum WarrantyError */
  partWarrantyUpsert: PartWarranty;
  /** Possible named errors are listed in the enum WarrantyError */
  goodWillWarrantyUpsert: GoodWillWarranty;
  /** Possible named errors are listed in the enum WarrantyError */
  legacyWarrantyUpsert: NoContent;
  /** A user should be able to delete a draft */
  deleteDraftWarranty: NoContent;
  /** Used by a dealy user to change a rows status */
  warrantyChangeRowStatus: Warranty;
  /** Used by a dealy admin to add an operation row to an already registered warranty */
  warrantyAddOperationRow: Warranty;
  /** Used by a dealy admin to add an article row to an already registered warranty */
  warrantyAddArticleRow: Warranty;
  /** Used by a dealy admin to add an external cost row to an already registered warranty */
  warrantyAddExternalCostRow: Warranty;
  /** Used by a dealy user to change many or a single warranty status */
  warrantyChangeStatusMassAction: Array<Warranty>;
  /** Used by a retailer to update a warranty after the status have been changed to complement */
  complementWarrantyRow: Row;
  /** A reatailer should be able to upload complemented images */
  complementWarranty: Warranty;
  vehicleBFI?: Maybe<NoContent>;
  vehicleKeys?: Maybe<NoContent>;
  /** Used by a dealy user to create a vehicle warranty draft from an approved prior approval warranty */
  operationalizePriorApprovalWarranty: VehicleWarranty;
  updateMakePermissions?: Maybe<Retailer>;
  warrantyDisplayViewUpsert: WarrantyDisplayView;
  deleteWarrantyDisplayView: NoContent;
  switchWarrantyDisplayView: WarrantyDisplayView;
  createExplodedViews: NoContent;
  deleteExplodedView?: Maybe<NoContent>;
  updateExplodedView: ExplodedView;
  deleteExplodedViewRow: NoContent;
  /** Creates an excel ark and add it to minio. Returns the path to the excel ark */
  createExcelExportSkeleton: Scalars['String'];
  loadSpreedSheet: Array<ExplodedView>;
  createFolders: Array<Folder>;
  deleteFolder?: Maybe<NoContent>;
  updateFolderActiveState: Folder;
  changeFolder?: Maybe<Folder>;
  placeOrder: Array<Order>;
  exportOrder: Scalars['String'];
  /** Update the cart with all the correct prices, retailerDiscount price, campaignPrices */
  updateCartWithPrices: Cart;
  /** Create a shopping list */
  createShoppingList: ShoppingList;
  /** Update a shopping list */
  updateShoppingList: ShoppingList;
  /** delete a shopping list */
  deleteShoppingList: NoContent;
  createShoppingListExcelTemplate: Scalars['String'];
  uploadShoppingListExcel?: Maybe<ShoppingList>;
  placeVehicleConsignmentOrder: Vehicle;
  placeVehicleExhibitionOrder: Vehicle;
  /** Requires one of four optional arguments. Returns path to newly created file. Creates a pre-filled excel file if operationGroupId is passed and an empty template if any of the other args are used */
  createOperationGroupExcel: Scalars['String'];
  /** Requires an excel document. Will create or update existing operation group including associated operation codes. Note that this will overwrite operation codes on already existing operation groups */
  uploadOperationGroupExcel?: Maybe<NoContent>;
  /** Deletes operation group and associated operation codes */
  deleteOperationGroup?: Maybe<NoContent>;
  /** Create or update product rule. Requires one of stockVehicleId, equipmentId, sparePartId or billOfMaterialId to be passed */
  upsertProductRule: ProductRule;
  /** Deletes product rule and associated product references */
  deleteProductRule?: Maybe<NoContent>;
  /** Creates a product reference connected to an existing product rule */
  createProductReference: ProductRule;
  /** Deletes a product reference connected to an existing product rule */
  deleteProductReference: NoContent;
  /** Create or update volume discount. Requires a product identifier to be passed */
  upsertVolumeDiscount: VolumeDiscount;
  /** Deletes a volume discount */
  deleteVolumeDiscount: NoContent;
  /** Create or update maintenance task. Attempts update if id is provided. Leaves non-passed fields untouched. Possible named errors are listed in the enum MaintenanceTaskError */
  upsertMaintenanceTask: MaintenanceTask;
  /** Used by a retailer user to remove a draft maintenance task */
  deleteMaintenanceTask: NoContent;
  /** Used by a dealy user to change one or many maintenance task status. */
  updateMaintenanceTaskStatus: NoContent;
  /** Used by a dealy user to add a maintenance row to an existing maintenance task */
  addMaintenanceRow: MaintenanceTask;
  /** Used by a dealy user to update maintenance rows on an existing maintenance task */
  handleMaintenanceTaskRows: MaintenanceTask;
  /** Used by a retailer user to complement a maintenance row */
  complementMaintenanceRow: MaintenanceTask;
  /** Used by a dealy user to create and download an excel table with maintenance row from approved maintenance tasks */
  createMaintenanceRowExcel: Scalars['String'];
  /** Returns path to newly created file */
  createCustomPriceExcel: Scalars['String'];
  /** Requires an excel document. Sets custom prices for a maintenance customer. Possible named errors are listed in the enum CustomPriceError */
  uploadCustomPriceExcel?: Maybe<NoContent>;
  sendWorkshopMessage: WorkshopMessage;
  setWorkshopMessageReadState: WorkshopMessage;
  uploadWorkshopAttachement: WorkshopAttachement;
  removeWorkshopAttachement: NoContent;
};


/** All Dealy mutations */
export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


/** All Dealy mutations */
export type MutationResetPasswordActionArgs = {
  email: Scalars['String'];
};


/** All Dealy mutations */
export type MutationResetPasswordConfirmArgs = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  newPasswordConfirm: Scalars['String'];
  nonce: Scalars['String'];
};


/** All Dealy mutations */
export type MutationResetOwnPasswordArgs = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
  newPasswordConfirm: Scalars['String'];
};


/** All Dealy mutations */
export type MutationCreateRetailerAccountArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  freeText?: Maybe<Scalars['String']>;
  retailerId: Scalars['Int'];
  role: RetailerUserRole;
  roleDescription?: Maybe<Scalars['String']>;
};


/** All Dealy mutations */
export type MutationCreateDealyAccountArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  freeText?: Maybe<Scalars['String']>;
  role: DealyUserRole;
  roleDescription?: Maybe<Scalars['String']>;
};


/** All Dealy mutations */
export type MutationDeleteDealyUserArgs = {
  id: Scalars['ID'];
};


/** All Dealy mutations */
export type MutationDeleteRetailerUserArgs = {
  id: Scalars['ID'];
};


/** All Dealy mutations */
export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  freeText?: Maybe<Scalars['String']>;
  role: UserRole;
  active: Scalars['Boolean'];
  email: Scalars['String'];
  roleDescription?: Maybe<Scalars['String']>;
};


/** All Dealy mutations */
export type MutationUpdateAcceptsEmailRemindersArgs = {
  id: Scalars['UUID'];
  acceptsEmailReminders: Scalars['Boolean'];
};


/** All Dealy mutations */
export type MutationCreateRetailerArgs = {
  customerId: Scalars['String'];
  email: Scalars['String'];
  contactPersonName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  retailerName: Scalars['String'];
  makePermissions?: Maybe<Array<UpdateMakePermissionsInput>>;
};


/** All Dealy mutations */
export type MutationUpdateRetailerArgs = {
  retailerId: Scalars['Int'];
  retailerName: Scalars['String'];
  userName: Scalars['String'];
  userEmail: Scalars['String'];
  userPhoneNumber?: Maybe<Scalars['String']>;
};


/** All Dealy mutations */
export type MutationUpdateMaintenanceCustomerAccessArgs = {
  retailerId: Scalars['Int'];
  maintenanceCustomerAccess: Scalars['Boolean'];
};


/** All Dealy mutations */
export type MutationDeleteRetailerArgs = {
  id: Scalars['Int'];
};


/** All Dealy mutations */
export type MutationUpdateShippingCostArgs = {
  id: Scalars['ID'];
  shippingCost: Scalars['Float'];
};


/** All Dealy mutations */
export type MutationVehicleWarrantyUpsertArgs = {
  id?: Maybe<Scalars['ID']>;
  status: WarrantyStatus;
  customerRemark?: Maybe<Scalars['String']>;
  workshopRemark?: Maybe<Scalars['String']>;
  approvedRowsInput?: Maybe<Array<ApprovedRowInput>>;
  articleRowsInput?: Maybe<Array<ArticleRowInput>>;
  operationRowsInput?: Maybe<Array<OperationRowInput>>;
  externRowsInput?: Maybe<Array<ExternRowInput>>;
  imageInputs?: Maybe<Array<ImageUpload>>;
  repairedAt?: Maybe<Scalars['DateTime']>;
  meterReading?: Maybe<Scalars['Float']>;
  operatingTime?: Maybe<Scalars['Float']>;
  meterReadingImage?: Maybe<ImageUpload>;
  serviceBookImage?: Maybe<ImageUpload>;
  damageImage?: Maybe<ImageUpload>;
  defectPartNumber?: Maybe<Scalars['String']>;
  defectPartTypeCode?: Maybe<Array<Scalars['String']>>;
  symptomCode?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['ID']>;
};


/** All Dealy mutations */
export type MutationPartWarrantyUpsertArgs = {
  id?: Maybe<Scalars['ID']>;
  status: WarrantyStatus;
  customerRemark?: Maybe<Scalars['String']>;
  workshopRemark?: Maybe<Scalars['String']>;
  articleRowsInput?: Maybe<Array<ArticleRowInput>>;
  operationRowsInput?: Maybe<Array<OperationRowInput>>;
  externRowsInput?: Maybe<Array<ExternRowInput>>;
  imageInputs?: Maybe<Array<ImageUpload>>;
  repairedAt?: Maybe<Scalars['DateTime']>;
  purchaseReceiptImage?: Maybe<ImageUpload>;
  damageImage?: Maybe<ImageUpload>;
};


/** All Dealy mutations */
export type MutationGoodWillWarrantyUpsertArgs = {
  id?: Maybe<Scalars['ID']>;
  status: WarrantyStatus;
  customerRemark?: Maybe<Scalars['String']>;
  workshopRemark?: Maybe<Scalars['String']>;
  articleRowsInput?: Maybe<Array<ArticleRowInput>>;
  operationRowsInput?: Maybe<Array<OperationRowInput>>;
  externRowsInput?: Maybe<Array<ExternRowInput>>;
  imageInputs?: Maybe<Array<ImageUpload>>;
  repairedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<GoodWillType>;
  vehicleId?: Maybe<Scalars['ID']>;
  meterReading?: Maybe<Scalars['Float']>;
  operatingTime?: Maybe<Scalars['Float']>;
  serviceBookImage?: Maybe<ImageUpload>;
  damageImage?: Maybe<ImageUpload>;
};


/** All Dealy mutations */
export type MutationLegacyWarrantyUpsertArgs = {
  spreedSheet: Scalars['UploadFile'];
};


/** All Dealy mutations */
export type MutationDeleteDraftWarrantyArgs = {
  id: Scalars['ID'];
};


/** All Dealy mutations */
export type MutationWarrantyChangeRowStatusArgs = {
  warrantyId: Scalars['ID'];
  rowsChangeStatus: Array<RowChangeStatusInput>;
};


/** All Dealy mutations */
export type MutationWarrantyAddOperationRowArgs = {
  warrantyId: Scalars['ID'];
  operationRowInput: OperationRowInput;
};


/** All Dealy mutations */
export type MutationWarrantyAddArticleRowArgs = {
  warrantyId: Scalars['ID'];
  articleRowInput: ArticleRowInput;
};


/** All Dealy mutations */
export type MutationWarrantyAddExternalCostRowArgs = {
  warrantyId: Scalars['ID'];
  externalRowInput: ExternRowInput;
};


/** All Dealy mutations */
export type MutationWarrantyChangeStatusMassActionArgs = {
  warrantiesIds: Array<Scalars['ID']>;
  status: WarrantyStatus;
  declineReason?: Maybe<DeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
};


/** All Dealy mutations */
export type MutationComplementWarrantyRowArgs = {
  rowId: Scalars['ID'];
  articleRowsInput?: Maybe<ArticleRowInput>;
  operationRowsInput?: Maybe<OperationRowInput>;
  externRowsInput?: Maybe<ExternRowInput>;
  sparePartSent?: Maybe<Scalars['Boolean']>;
};


/** All Dealy mutations */
export type MutationComplementWarrantyArgs = {
  warrantyId: Scalars['ID'];
  rowId: Scalars['ID'];
  complementaryImages?: Maybe<Array<WarrantyImageInput>>;
};


/** All Dealy mutations */
export type MutationVehicleBfiArgs = {
  vehicleId: Scalars['ID'];
  meterReading: Scalars['Float'];
  meterReadingImage?: Maybe<Scalars['UploadFile']>;
};


/** All Dealy mutations */
export type MutationVehicleKeysArgs = {
  vehicleId: Scalars['ID'];
  registrationProofImage: Scalars['UploadFile'];
  alternativeKeyNumber?: Maybe<Scalars['String']>;
  spareKeyQuantity?: Maybe<Scalars['Int']>;
  remoteControlQuantity?: Maybe<Scalars['Int']>;
  remoteControlFlipQuantity?: Maybe<Scalars['Int']>;
};


/** All Dealy mutations */
export type MutationOperationalizePriorApprovalWarrantyArgs = {
  warrantyId: Scalars['ID'];
};


/** All Dealy mutations */
export type MutationUpdateMakePermissionsArgs = {
  retailerId?: Maybe<Scalars['Int']>;
  makePermissions: Array<UpdateMakePermissionsInput>;
};


/** All Dealy mutations */
export type MutationWarrantyDisplayViewUpsertArgs = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  columnData: Array<ColumnDataInput>;
  active: Scalars['Boolean'];
};


/** All Dealy mutations */
export type MutationDeleteWarrantyDisplayViewArgs = {
  id?: Maybe<Scalars['ID']>;
};


/** All Dealy mutations */
export type MutationSwitchWarrantyDisplayViewArgs = {
  id: Scalars['ID'];
};


/** All Dealy mutations */
export type MutationCreateExplodedViewsArgs = {
  currentFolderId: Scalars['String'];
  brandFolderId: Scalars['String'];
  modelFolderId: Scalars['String'];
  mainCategoryFolderId?: Maybe<Scalars['String']>;
  subCategoryFolderId?: Maybe<Scalars['String']>;
  explodedViews: Array<ExplodedViewInput>;
};


/** All Dealy mutations */
export type MutationDeleteExplodedViewArgs = {
  explodedViewId: Scalars['String'];
};


/** All Dealy mutations */
export type MutationUpdateExplodedViewArgs = {
  explodedViewId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['UploadFile']>>;
  rows?: Maybe<Array<ExplodedViewRowInput>>;
};


/** All Dealy mutations */
export type MutationDeleteExplodedViewRowArgs = {
  id: Scalars['ID'];
};


/** All Dealy mutations */
export type MutationCreateExcelExportSkeletonArgs = {
  folderId?: Maybe<Scalars['String']>;
};


/** All Dealy mutations */
export type MutationLoadSpreedSheetArgs = {
  spreedSheet: Scalars['UploadFile'];
};


/** All Dealy mutations */
export type MutationCreateFoldersArgs = {
  folders: Array<FolderCreateInput>;
};


/** All Dealy mutations */
export type MutationDeleteFolderArgs = {
  id: Scalars['String'];
};


/** All Dealy mutations */
export type MutationUpdateFolderActiveStateArgs = {
  id: Scalars['UUID'];
  active: Scalars['Boolean'];
};


/** All Dealy mutations */
export type MutationChangeFolderArgs = {
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  newParentId?: Maybe<Scalars['String']>;
};


/** All Dealy mutations */
export type MutationPlaceOrderArgs = {
  input: PlaceOrderInput;
};


/** All Dealy mutations */
export type MutationExportOrderArgs = {
  items: Array<ItemsInput>;
};


/** All Dealy mutations */
export type MutationUpdateCartWithPricesArgs = {
  items: Array<ItemsInput>;
  onBehalfOfRetailerId?: Maybe<Scalars['Int']>;
  deliveryType?: Maybe<DeliveryType>;
};


/** All Dealy mutations */
export type MutationCreateShoppingListArgs = {
  name: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  onBehalfOfRetailerId?: Maybe<Scalars['Int']>;
  items: Array<ShoppingListItemInput>;
};


/** All Dealy mutations */
export type MutationUpdateShoppingListArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  onBehalfOfRetailerId?: Maybe<Scalars['Int']>;
  items: Array<ShoppingListItemInput>;
};


/** All Dealy mutations */
export type MutationDeleteShoppingListArgs = {
  id: Scalars['String'];
};


/** All Dealy mutations */
export type MutationUploadShoppingListExcelArgs = {
  spreadSheet: Scalars['UploadFile'];
  onBehalfOfRetailerId?: Maybe<Scalars['Int']>;
};


/** All Dealy mutations */
export type MutationPlaceVehicleConsignmentOrderArgs = {
  vehicleId: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};


/** All Dealy mutations */
export type MutationPlaceVehicleExhibitionOrderArgs = {
  vehicleId: Scalars['String'];
  message?: Maybe<Scalars['String']>;
};


/** All Dealy mutations */
export type MutationCreateOperationGroupExcelArgs = {
  operationGroupId?: Maybe<Scalars['String']>;
  makeId?: Maybe<Scalars['String']>;
  modelSeriesId?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['String']>;
};


/** All Dealy mutations */
export type MutationUploadOperationGroupExcelArgs = {
  spreadSheet: Scalars['UploadFile'];
};


/** All Dealy mutations */
export type MutationDeleteOperationGroupArgs = {
  operationGroupId: Scalars['String'];
};


/** All Dealy mutations */
export type MutationUpsertProductRuleArgs = {
  input: ProductRuleInput;
};


/** All Dealy mutations */
export type MutationDeleteProductRuleArgs = {
  id: Scalars['ID'];
};


/** All Dealy mutations */
export type MutationCreateProductReferenceArgs = {
  input: ProductReferenceInput;
};


/** All Dealy mutations */
export type MutationDeleteProductReferenceArgs = {
  id: Scalars['ID'];
};


/** All Dealy mutations */
export type MutationUpsertVolumeDiscountArgs = {
  input: VolumeDiscountInput;
};


/** All Dealy mutations */
export type MutationDeleteVolumeDiscountArgs = {
  id: Scalars['ID'];
};


/** All Dealy mutations */
export type MutationUpsertMaintenanceTaskArgs = {
  input: UpsertMaintenanceTaskInput;
};


/** All Dealy mutations */
export type MutationDeleteMaintenanceTaskArgs = {
  id: Scalars['ID'];
};


/** All Dealy mutations */
export type MutationUpdateMaintenanceTaskStatusArgs = {
  maintenanceTaskIds: Array<Scalars['ID']>;
  status: MaintenanceTaskStatus;
  declineReason?: Maybe<MaintenanceRowDeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
};


/** All Dealy mutations */
export type MutationAddMaintenanceRowArgs = {
  maintenanceTaskId: Scalars['ID'];
  rowInput: MaintenanceRowInput;
};


/** All Dealy mutations */
export type MutationHandleMaintenanceTaskRowsArgs = {
  maintenanceTaskId: Scalars['ID'];
  rowsInput: Array<UpdateMaintenanceRowsInput>;
};


/** All Dealy mutations */
export type MutationComplementMaintenanceRowArgs = {
  rowId: Scalars['UUID'];
  rowInput?: Maybe<MaintenanceRowInput>;
  image?: Maybe<ImageUpload>;
};


/** All Dealy mutations */
export type MutationCreateMaintenanceRowExcelArgs = {
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
  workCategories: Array<MaintenanceWorkCategory>;
  maintenanceCustomerId: Scalars['UUID'];
};


/** All Dealy mutations */
export type MutationCreateCustomPriceExcelArgs = {
  maintenanceCustomerId: Scalars['UUID'];
};


/** All Dealy mutations */
export type MutationUploadCustomPriceExcelArgs = {
  spreadSheet: Scalars['UploadFile'];
};


/** All Dealy mutations */
export type MutationSendWorkshopMessageArgs = {
  maintenanceTaskId?: Maybe<Scalars['ID']>;
  warrantyId?: Maybe<Scalars['ID']>;
  text: Scalars['String'];
  type: WorkshopMessageType;
};


/** All Dealy mutations */
export type MutationSetWorkshopMessageReadStateArgs = {
  id: Scalars['UUID'];
  isRead: Scalars['Boolean'];
};


/** All Dealy mutations */
export type MutationUploadWorkshopAttachementArgs = {
  fileInput: FileUpload;
  maintenanceTaskId?: Maybe<Scalars['ID']>;
  warrantyId?: Maybe<Scalars['ID']>;
};


/** All Dealy mutations */
export type MutationRemoveWorkshopAttachementArgs = {
  id: Scalars['UUID'];
};

export type LoginReturnType = {
  __typename?: 'LoginReturnType';
  /** The token, with id and roles in the payload */
  token: Scalars['String'];
  /** The login user */
  user: User;
  /** Boolean telling if user has right to act as any retailer to place orders */
  actAsRetailerRight: Scalars['Boolean'];
};

export enum RetailerUserRole {
  RetailerAdmin = 'RETAILER_ADMIN',
  RetailerUser = 'RETAILER_USER',
  RetailerSeller = 'RETAILER_SELLER',
  RetailerWorkshop = 'RETAILER_WORKSHOP'
}

export type UserOrError = {
  __typename?: 'UserOrError';
  user?: Maybe<User>;
  error?: Maybe<ErrorType>;
  message?: Maybe<Scalars['String']>;
};

export enum ErrorType {
  EmailTaken = 'EMAIL_TAKEN',
  AccessDeniedRole = 'ACCESS_DENIED_ROLE',
  RetailerNotFound = 'RETAILER_NOT_FOUND',
  AccessDeniedWrongRetailer = 'ACCESS_DENIED_WRONG_RETAILER',
  UserNotFound = 'USER_NOT_FOUND',
  NotAllowedToUpdateRole = 'NOT_ALLOWED_TO_UPDATE_ROLE',
  RetailerNameTaken = 'RETAILER_NAME_TAKEN',
  RetailerCustomerIdTaken = 'RETAILER_CUSTOMER_ID_TAKEN',
  Other = 'OTHER'
}

export enum DealyUserRole {
  SuperAdmin = 'SUPER_ADMIN',
  DealyAdmin = 'DEALY_ADMIN',
  DealySeller = 'DEALY_SELLER',
  InsuranceUser = 'INSURANCE_USER'
}

export type UpdateMakePermissionsInput = {
  /** The id of the make */
  id: Scalars['ID'];
  /** Should the sell department have access to this mark */
  accessToSell: Scalars['Boolean'];
  /** Should the workshop have access to this mark */
  accessToWorkshop: Scalars['Boolean'];
  /** The hourly payment the retailer takes for warranty work */
  warrantyHourlyPayment: Scalars['Float'];
};

export type RetailerOrError = {
  __typename?: 'RetailerOrError';
  retailer?: Maybe<Retailer>;
  error?: Maybe<ErrorType>;
  message?: Maybe<Scalars['String']>;
};

export type ApprovedRowInput = {
  id: Scalars['String'];
  /** Used to connect a previously approved article row to an order placed after the approval */
  orderItemId?: Maybe<Scalars['String']>;
};

export type ArticleRowInput = {
  orderItemId?: Maybe<Scalars['String']>;
  sparePartId?: Maybe<Scalars['String']>;
  billOfMaterialId?: Maybe<Scalars['String']>;
  legacyArticleNumber?: Maybe<Scalars['String']>;
  legacyOrderNumber?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  quantity: Scalars['Int'];
};

export type OperationRowInput = {
  vehicleMakeId?: Maybe<Scalars['String']>;
  operationCodeId: Scalars['String'];
  hours?: Maybe<Scalars['Float']>;
  motivation?: Maybe<Scalars['String']>;
};

export type ExternRowInput = {
  purchaseRefersType: PurchaseRefersType;
  description: Scalars['String'];
  price: Scalars['Float'];
  receiptImage: ImageUpload;
};

export enum PurchaseRefersType {
  Work = 'WORK',
  Part = 'PART'
}

export type ImageUpload = {
  uploadFile?: Maybe<Scalars['UploadFile']>;
  imageObjectId?: Maybe<Scalars['ID']>;
};


export type VehicleWarranty = Warranty & {
  __typename?: 'VehicleWarranty';
  id: Scalars['ID'];
  status: WarrantyStatus;
  declineReason?: Maybe<DeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
  retailer: Retailer;
  customerRemark?: Maybe<Scalars['String']>;
  workshopRemark?: Maybe<Scalars['String']>;
  rows: Array<Row>;
  attachements: Array<WorkshopAttachement>;
  registeredAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  repairedAt?: Maybe<Scalars['DateTime']>;
  sumRequested?: Maybe<Scalars['Float']>;
  sumCredited?: Maybe<Scalars['Float']>;
  type: WarrantyType;
  internalMessages: Array<WorkshopMessage>;
  externalMessages: Array<WorkshopMessage>;
  createdBy: User;
  damageImage?: Maybe<WorkshopImage>;
  shippingCost?: Maybe<Scalars['Float']>;
  vehicle?: Maybe<Vehicle>;
  meterReading?: Maybe<Scalars['Float']>;
  operatingTime?: Maybe<Scalars['Float']>;
  meterReadingImage?: Maybe<WorkshopImage>;
  serviceBookImage?: Maybe<WorkshopImage>;
  defectPartNumber?: Maybe<Scalars['String']>;
  defectPartTypeCode?: Maybe<Array<Scalars['String']>>;
  symptomCode?: Maybe<Scalars['String']>;
  operationalizedFrom?: Maybe<GoodWillWarranty>;
};

export type GoodWillWarranty = Warranty & {
  __typename?: 'GoodWillWarranty';
  id: Scalars['ID'];
  status: WarrantyStatus;
  declineReason?: Maybe<DeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
  retailer: Retailer;
  customerRemark?: Maybe<Scalars['String']>;
  workshopRemark?: Maybe<Scalars['String']>;
  rows: Array<Row>;
  attachements: Array<WorkshopAttachement>;
  registeredAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  repairedAt?: Maybe<Scalars['DateTime']>;
  sumRequested?: Maybe<Scalars['Float']>;
  sumCredited?: Maybe<Scalars['Float']>;
  type: WarrantyType;
  internalMessages: Array<WorkshopMessage>;
  externalMessages: Array<WorkshopMessage>;
  createdBy: User;
  damageImage?: Maybe<WorkshopImage>;
  shippingCost?: Maybe<Scalars['Float']>;
  vehicle?: Maybe<Vehicle>;
  meterReading?: Maybe<Scalars['Float']>;
  operatingTime?: Maybe<Scalars['Float']>;
  serviceBookImage?: Maybe<WorkshopImage>;
  operationalizedTo?: Maybe<VehicleWarranty>;
};

export type PartWarranty = Warranty & {
  __typename?: 'PartWarranty';
  id: Scalars['ID'];
  status: WarrantyStatus;
  declineReason?: Maybe<DeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
  retailer: Retailer;
  customerRemark?: Maybe<Scalars['String']>;
  workshopRemark?: Maybe<Scalars['String']>;
  rows: Array<Row>;
  attachements: Array<WorkshopAttachement>;
  registeredAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  repairedAt?: Maybe<Scalars['DateTime']>;
  sumRequested?: Maybe<Scalars['Float']>;
  sumCredited?: Maybe<Scalars['Float']>;
  type: WarrantyType;
  internalMessages: Array<WorkshopMessage>;
  externalMessages: Array<WorkshopMessage>;
  createdBy: User;
  damageImage?: Maybe<WorkshopImage>;
  shippingCost?: Maybe<Scalars['Float']>;
  purchaseReceiptImage?: Maybe<WorkshopImage>;
};

export enum GoodWillType {
  GoodWill = 'GOOD_WILL',
  PriorApproval = 'PRIOR_APPROVAL'
}

export type RowChangeStatusInput = {
  rowId: Scalars['ID'];
  status: RowStatus;
  quantity?: Maybe<Scalars['Float']>;
  percentage?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  complementReason?: Maybe<ComplementReason>;
  complementComment?: Maybe<Scalars['String']>;
  declineReason?: Maybe<DeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
};

export type WarrantyImageInput = {
  name: Scalars['String'];
  image: Scalars['UploadFile'];
};

export type ColumnDataInput = {
  position: Scalars['Int'];
  name: ColumnNames;
  active: Scalars['Boolean'];
};

export type ExplodedViewInput = {
  title: Scalars['String'];
  explodedViewImages?: Maybe<Array<Scalars['UploadFile']>>;
  explodedViewRows?: Maybe<Array<ExplodedViewRowInput>>;
};

export type ExplodedViewRowInput = {
  /** Will be interpreted as create if not passed */
  id?: Maybe<Scalars['String']>;
  /** Required on create */
  referenceNumber?: Maybe<Scalars['String']>;
  /** Required on create */
  articleNumber?: Maybe<Scalars['String']>;
};

export type FolderCreateInput = {
  title: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
};

export type PlaceOrderInput = {
  items: Array<ItemsInput>;
  deliveryType: DeliveryType;
  address: Scalars['String'];
  postalCode: Scalars['String'];
  postalCity: Scalars['String'];
  partialDelivery: Scalars['Boolean'];
  orderRemark?: Maybe<Scalars['String']>;
  placeOrderOnBehalfOfRetailerId?: Maybe<Scalars['Int']>;
  dealyInternalComment?: Maybe<Scalars['String']>;
  externallyFinanced?: Maybe<Scalars['Boolean']>;
};

export type ItemsInput = {
  itemId: Scalars['String'];
  quantity: Scalars['Int'];
  remark?: Maybe<Scalars['String']>;
  campaignOnVehicleId?: Maybe<Scalars['String']>;
  replacedProductId?: Maybe<Scalars['String']>;
};

export type Cart = {
  __typename?: 'Cart';
  /** All cart items */
  items: Array<CartItem>;
  /** All cart items except fees */
  products: Array<CartItem>;
  /** Only cart items that represent fees */
  fees: Array<CartItem>;
  totalQuantity: Scalars['Int'];
  totalExclVAT: Scalars['Float'];
  totalInclVAT: Scalars['Float'];
};

export type CartItem = {
  __typename?: 'CartItem';
  stockVehicle?: Maybe<StockVehicle>;
  equipment?: Maybe<Equipment>;
  sparePart?: Maybe<SparePart>;
  billOfMaterial?: Maybe<BillOfMaterial>;
  fee?: Maybe<Fee>;
  quantity: Scalars['Int'];
  price: CartPrice;
  replacedProduct?: Maybe<ReplacedProduct>;
  campaignOnVehicleId?: Maybe<Scalars['String']>;
  connectionType?: Maybe<OrderItemConnection>;
  volumeDiscount?: Maybe<VolumeDiscount>;
  productRule?: Maybe<ProductRule>;
  productRuleItems?: Maybe<Array<CartItem>>;
};

export type CartPrice = {
  __typename?: 'CartPrice';
  price: Scalars['Float'];
  retailerPrice: Scalars['Float'];
  discountedPrice?: Maybe<Scalars['Float']>;
};

export type ShoppingListItemInput = {
  id: Scalars['String'];
  amount: Scalars['Int'];
  isCampaignEquipment?: Maybe<Scalars['Boolean']>;
  stockVehicleProductCount?: Maybe<Scalars['Int']>;
  relatedVehicle?: Maybe<Scalars['String']>;
};

export type ProductRuleInput = {
  /** Will attempt update if this argument is passed */
  id?: Maybe<Scalars['ID']>;
  /** Unique name */
  name: Scalars['String'];
  /** 100% discount is implied if this argument is set to false */
  showInCart: Scalars['Boolean'];
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  discountTwo?: Maybe<Scalars['Float']>;
  discountThree?: Maybe<Scalars['Float']>;
  discountCode?: Maybe<DiscountCode>;
  stockVehicleId?: Maybe<Scalars['ID']>;
  equipmentId?: Maybe<Scalars['ID']>;
  sparePartId?: Maybe<Scalars['ID']>;
  billOfMaterialId?: Maybe<Scalars['ID']>;
};

export type ProductReferenceInput = {
  productRuleId: Scalars['ID'];
  vehicleType?: Maybe<VehicleType>;
  makeId?: Maybe<Scalars['ID']>;
  modelSeriesId?: Maybe<Scalars['ID']>;
  modelId?: Maybe<Scalars['ID']>;
  stockVehicleId?: Maybe<Scalars['ID']>;
  equipmentId?: Maybe<Scalars['ID']>;
  sparePartId?: Maybe<Scalars['ID']>;
  billOfMaterialId?: Maybe<Scalars['ID']>;
};

export type VolumeDiscountInput = {
  /** Will attempt update if this argument is passed */
  id?: Maybe<Scalars['ID']>;
  /** Unique name */
  name: Scalars['String'];
  /** Minimum amount needed for the discount to be applied */
  volume: Scalars['Int'];
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  discountOne?: Maybe<Scalars['Float']>;
  discountTwo?: Maybe<Scalars['Float']>;
  discountThree?: Maybe<Scalars['Float']>;
  productIdentifierId: ProductIdentifierIdInput;
};

export type ProductIdentifierIdInput = {
  stockVehicleId?: Maybe<Scalars['ID']>;
  equipmentId?: Maybe<Scalars['ID']>;
  sparePartId?: Maybe<Scalars['ID']>;
  billOfMaterialId?: Maybe<Scalars['ID']>;
  makeId?: Maybe<Scalars['ID']>;
  modelSeriesId?: Maybe<Scalars['ID']>;
  modelId?: Maybe<Scalars['ID']>;
  vehicleType?: Maybe<VehicleType>;
};

export type UpsertMaintenanceTaskInput = {
  /** Will attempt update if this is provided */
  id?: Maybe<Scalars['ID']>;
  /** Unique */
  serviceId?: Maybe<Scalars['String']>;
  maintenanceCustomerId?: Maybe<Scalars['UUID']>;
  vehicleId?: Maybe<Scalars['UUID']>;
  /** Accepts DRAFT or REGISTERED */
  status: MaintenanceTaskStatus;
  meterReading?: Maybe<Scalars['Float']>;
  customerRemark?: Maybe<Scalars['String']>;
  workshopRemark?: Maybe<Scalars['String']>;
  freeText?: Maybe<Scalars['String']>;
  customerReference?: Maybe<Scalars['String']>;
  workPerformedAt?: Maybe<Scalars['DateTime']>;
  customerApprovalBy?: Maybe<Scalars['String']>;
  customerApprovalAt?: Maybe<Scalars['DateTime']>;
  deliveryServiceImage?: Maybe<ImageUpload>;
  meterReadingImage?: Maybe<ImageUpload>;
  damageImage?: Maybe<ImageUpload>;
  images?: Maybe<Array<ImageUpload>>;
  rows?: Maybe<Array<MaintenanceRowInput>>;
};

/** Exactly one of the fields are required */
export type MaintenanceRowInput = {
  articleRow?: Maybe<ArticleMaintenanceRowInput>;
  externalRow?: Maybe<ExternalMaintenanceRowInput>;
  operationRow?: Maybe<OperationMaintenanceRowInput>;
  mileageAllowanceRow?: Maybe<MileageAllowanceMaintenanceRowInput>;
  shippingRow?: Maybe<ShippingMaintenanceRowInput>;
};

/** Article(s) used in the maintenance task. One of orderItemId or billOfMaterialId/sparePartId is required */
export type ArticleMaintenanceRowInput = {
  workCategory: MaintenanceWorkCategory;
  /** Number of articles */
  quantity: Scalars['Float'];
  orderItemId?: Maybe<Scalars['UUID']>;
  billOfMaterialId?: Maybe<Scalars['UUID']>;
  sparePartId?: Maybe<Scalars['UUID']>;
};

export type ExternalMaintenanceRowInput = {
  workCategory: MaintenanceWorkCategory;
  /** Total cost */
  price: Scalars['Float'];
  externalCostType: ExternalCostType;
  receiptImage?: Maybe<ImageUpload>;
  externalCostDescription: Scalars['String'];
};

export enum ExternalCostType {
  Work = 'WORK',
  Part = 'PART'
}

/** Manual work performed during the maintenance task. Price is derived using maintenance customer on parent maintenance task */
export type OperationMaintenanceRowInput = {
  workCategory: MaintenanceWorkCategory;
  /** Time in hours. Only applicable when manual operation code */
  quantity?: Maybe<Scalars['Float']>;
  operationCodeId: Scalars['UUID'];
  /** Required if manual operation code is used */
  customOperationDescription?: Maybe<Scalars['String']>;
};

/** Price is derived using maintenance customer on parent maintenance task */
export type MileageAllowanceMaintenanceRowInput = {
  workCategory: MaintenanceWorkCategory;
  quantity: Scalars['Float'];
};

/** Shipping cost */
export type ShippingMaintenanceRowInput = {
  workCategory: MaintenanceWorkCategory;
  price: Scalars['Float'];
};

/** Will perform one of three actions depending on if status, complementReason or declineReason is passed. Price and quantity can be changed regardless */
export type UpdateMaintenanceRowsInput = {
  id: Scalars['UUID'];
  /** SEK per unit (as opposed to percent) */
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  /** Is only used by passing the APPROVED status */
  status?: Maybe<MaintenanceRowStatus>;
  /** Using this will set the row status to COMPLEMENT */
  complementReason?: Maybe<MaintenanceRowComplementReason>;
  /** Required if the complement reason is OTHER */
  complementComment?: Maybe<Scalars['String']>;
  /** Using this will set the row status to DECLINED */
  declineReason?: Maybe<MaintenanceRowDeclineReason>;
  /** Required if the decline reason is OTHER */
  declineComment?: Maybe<Scalars['String']>;
};

export type FileUpload = {
  uploadFile?: Maybe<Scalars['UploadFile']>;
  fileId?: Maybe<Scalars['ID']>;
};

export type LegacyWarranty = Warranty & {
  __typename?: 'LegacyWarranty';
  id: Scalars['ID'];
  status: WarrantyStatus;
  declineReason?: Maybe<DeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
  retailer: Retailer;
  customerRemark?: Maybe<Scalars['String']>;
  workshopRemark?: Maybe<Scalars['String']>;
  rows: Array<Row>;
  attachements: Array<WorkshopAttachement>;
  registeredAt?: Maybe<Scalars['DateTime']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  repairedAt?: Maybe<Scalars['DateTime']>;
  sumRequested?: Maybe<Scalars['Float']>;
  sumCredited?: Maybe<Scalars['Float']>;
  type: WarrantyType;
  internalMessages: Array<WorkshopMessage>;
  externalMessages: Array<WorkshopMessage>;
  createdBy: User;
  damageImage?: Maybe<WorkshopImage>;
  shippingCost?: Maybe<Scalars['Float']>;
  legacyClaimNumber?: Maybe<Scalars['String']>;
  vehicle?: Maybe<Vehicle>;
  legacyDealer?: Maybe<Scalars['Int']>;
  causeOfDamage?: Maybe<Scalars['String']>;
  warrantyStartedAt?: Maybe<Scalars['DateTime']>;
  receivedAt?: Maybe<Scalars['DateTime']>;
};

export type OperationRow = Row & {
  __typename?: 'OperationRow';
  id: Scalars['ID'];
  quantity?: Maybe<Scalars['Float']>;
  originalQuantity?: Maybe<Scalars['Float']>;
  price: Scalars['Float'];
  percentage: Scalars['Float'];
  originalPercentage?: Maybe<Scalars['Float']>;
  total: Scalars['Float'];
  originalTotal?: Maybe<Scalars['Float']>;
  referenceName: Scalars['String'];
  description: Scalars['String'];
  status: RowStatus;
  complementReason?: Maybe<ComplementReason>;
  complementComment?: Maybe<Scalars['String']>;
  declineReason?: Maybe<DeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
  type?: Maybe<RowTypes>;
  originalHours?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  handledBy?: Maybe<User>;
  createdBy: User;
  operationCode?: Maybe<OperationCode>;
};

export type ExternRow = Row & {
  __typename?: 'ExternRow';
  id: Scalars['ID'];
  quantity?: Maybe<Scalars['Float']>;
  originalQuantity?: Maybe<Scalars['Float']>;
  price: Scalars['Float'];
  percentage: Scalars['Float'];
  originalPercentage?: Maybe<Scalars['Float']>;
  total: Scalars['Float'];
  originalTotal?: Maybe<Scalars['Float']>;
  referenceName: Scalars['String'];
  description: Scalars['String'];
  status: RowStatus;
  complementReason?: Maybe<ComplementReason>;
  complementComment?: Maybe<Scalars['String']>;
  declineReason?: Maybe<DeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
  type?: Maybe<RowTypes>;
  originalHours?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  handledBy?: Maybe<User>;
  createdBy: User;
  purchaseRefersType: PurchaseRefersType;
  receiptImage: WorkshopImage;
};

export type ArticleRow = Row & {
  __typename?: 'ArticleRow';
  id: Scalars['ID'];
  quantity?: Maybe<Scalars['Float']>;
  originalQuantity?: Maybe<Scalars['Float']>;
  price: Scalars['Float'];
  percentage: Scalars['Float'];
  originalPercentage?: Maybe<Scalars['Float']>;
  total: Scalars['Float'];
  originalTotal?: Maybe<Scalars['Float']>;
  referenceName: Scalars['String'];
  description: Scalars['String'];
  status: RowStatus;
  complementReason?: Maybe<ComplementReason>;
  complementComment?: Maybe<Scalars['String']>;
  declineReason?: Maybe<DeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
  type?: Maybe<RowTypes>;
  originalHours?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  handledBy?: Maybe<User>;
  createdBy: User;
  orderItem?: Maybe<OrderItem>;
  sparePart?: Maybe<SparePart>;
  billOfMaterial?: Maybe<BillOfMaterial>;
  legacyArticleNumber?: Maybe<Scalars['String']>;
  legacyOrderNumber?: Maybe<Scalars['String']>;
};

export type LegacyRow = Row & {
  __typename?: 'LegacyRow';
  id: Scalars['ID'];
  quantity?: Maybe<Scalars['Float']>;
  originalQuantity?: Maybe<Scalars['Float']>;
  price: Scalars['Float'];
  percentage: Scalars['Float'];
  originalPercentage?: Maybe<Scalars['Float']>;
  total: Scalars['Float'];
  originalTotal?: Maybe<Scalars['Float']>;
  referenceName: Scalars['String'];
  description: Scalars['String'];
  status: RowStatus;
  complementReason?: Maybe<ComplementReason>;
  complementComment?: Maybe<Scalars['String']>;
  declineReason?: Maybe<DeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
  type?: Maybe<RowTypes>;
  originalHours?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  handledBy?: Maybe<User>;
  createdBy: User;
  code?: Maybe<Scalars['String']>;
  originalComp?: Maybe<Scalars['Float']>;
  comp?: Maybe<Scalars['Float']>;
  originalPrice?: Maybe<Scalars['Float']>;
  legacyType?: Maybe<Scalars['String']>;
};

/** Articles used when performing the maintenance task. Is connected to either an order item (orderItem) or an article (billOfMaterial/sparePart). Expects exactly one connection */
export type ArticleMaintenanceRow = MaintenanceRow & {
  __typename?: 'ArticleMaintenanceRow';
  id: Scalars['UUID'];
  workCategory: MaintenanceWorkCategory;
  status: MaintenanceRowStatus;
  complementReason?: Maybe<MaintenanceRowComplementReason>;
  complementComment?: Maybe<Scalars['String']>;
  declineReason?: Maybe<MaintenanceRowDeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
  createdBy: User;
  handledBy?: Maybe<User>;
  /** Cost per unit */
  price: Scalars['Float'];
  /** Requested price by retailer at time of task registration or row creation */
  requestedPrice: Scalars['Float'];
  /** Time in hours or number of units */
  quantity: Scalars['Float'];
  /** Requested quantity by retailer at time of task registration or row creation */
  requestedQuantity: Scalars['Float'];
  orderItem?: Maybe<OrderItem>;
  billOfMaterial?: Maybe<BillOfMaterial>;
  sparePart?: Maybe<SparePart>;
};

/** External costs incurred when performing the maintenance task. Quantity will always be one */
export type ExternalMaintenanceRow = MaintenanceRow & {
  __typename?: 'ExternalMaintenanceRow';
  id: Scalars['UUID'];
  workCategory: MaintenanceWorkCategory;
  status: MaintenanceRowStatus;
  complementReason?: Maybe<MaintenanceRowComplementReason>;
  complementComment?: Maybe<Scalars['String']>;
  declineReason?: Maybe<MaintenanceRowDeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
  createdBy: User;
  handledBy?: Maybe<User>;
  /** Cost per unit */
  price: Scalars['Float'];
  /** Requested price by retailer at time of task registration or row creation */
  requestedPrice: Scalars['Float'];
  /** Time in hours or number of units */
  quantity: Scalars['Float'];
  /** Requested quantity by retailer at time of task registration or row creation */
  requestedQuantity: Scalars['Float'];
  externalCostType: ExternalCostType;
  receiptImage?: Maybe<WorkshopImage>;
  externalCostDescription: Scalars['String'];
};

/** Work operations performed on the maintenance task */
export type OperationMaintenanceRow = MaintenanceRow & {
  __typename?: 'OperationMaintenanceRow';
  id: Scalars['UUID'];
  workCategory: MaintenanceWorkCategory;
  status: MaintenanceRowStatus;
  complementReason?: Maybe<MaintenanceRowComplementReason>;
  complementComment?: Maybe<Scalars['String']>;
  declineReason?: Maybe<MaintenanceRowDeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
  createdBy: User;
  handledBy?: Maybe<User>;
  /** Cost per unit */
  price: Scalars['Float'];
  /** Requested price by retailer at time of task registration or row creation */
  requestedPrice: Scalars['Float'];
  /** Time in hours or number of units */
  quantity: Scalars['Float'];
  /** Requested quantity by retailer at time of task registration or row creation */
  requestedQuantity: Scalars['Float'];
  operationCode?: Maybe<OperationCode>;
  customOperationDescription?: Maybe<Scalars['String']>;
};

/** Mileage allowance (Milersättning). Disregards passed price and quantity, instead sets a fixed value */
export type MileageAllowanceMaintenanceRow = MaintenanceRow & {
  __typename?: 'MileageAllowanceMaintenanceRow';
  id: Scalars['UUID'];
  workCategory: MaintenanceWorkCategory;
  status: MaintenanceRowStatus;
  complementReason?: Maybe<MaintenanceRowComplementReason>;
  complementComment?: Maybe<Scalars['String']>;
  declineReason?: Maybe<MaintenanceRowDeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
  createdBy: User;
  handledBy?: Maybe<User>;
  /** Cost per unit */
  price: Scalars['Float'];
  /** Requested price by retailer at time of task registration or row creation */
  requestedPrice: Scalars['Float'];
  /** Time in hours or number of units */
  quantity: Scalars['Float'];
  /** Requested quantity by retailer at time of task registration or row creation */
  requestedQuantity: Scalars['Float'];
};

/** ShippingCost */
export type ShippingMaintenanceRow = MaintenanceRow & {
  __typename?: 'ShippingMaintenanceRow';
  id: Scalars['UUID'];
  workCategory: MaintenanceWorkCategory;
  status: MaintenanceRowStatus;
  complementReason?: Maybe<MaintenanceRowComplementReason>;
  complementComment?: Maybe<Scalars['String']>;
  declineReason?: Maybe<MaintenanceRowDeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
  createdBy: User;
  handledBy?: Maybe<User>;
  /** Cost per unit */
  price: Scalars['Float'];
  /** Requested price by retailer at time of task registration or row creation */
  requestedPrice: Scalars['Float'];
  /** Time in hours or number of units */
  quantity: Scalars['Float'];
  /** Requested quantity by retailer at time of task registration or row creation */
  requestedQuantity: Scalars['Float'];
};

export enum MaintenanceTaskError {
  OnlyRetailersAllowed = 'ONLY_RETAILERS_ALLOWED',
  OnlyOneMileageAllowanceRow = 'ONLY_ONE_MILEAGE_ALLOWANCE_ROW',
  CanOnlyUpsertDraft = 'CAN_ONLY_UPSERT_DRAFT',
  IncorrectOrMissingField = 'INCORRECT_OR_MISSING_FIELD',
  CanOnlyRemoveDrafts = 'CAN_ONLY_REMOVE_DRAFTS',
  MaintenanceTaskNotFound = 'MAINTENANCE_TASK_NOT_FOUND',
  MaintenanceCustomerNotFound = 'MAINTENANCE_CUSTOMER_NOT_FOUND',
  DisallowedStatusInBulkOperation = 'DISALLOWED_STATUS_IN_BULK_OPERATION',
  MissingDeclineReason = 'MISSING_DECLINE_REASON',
  MissingDeclineComment = 'MISSING_DECLINE_COMMENT',
  InvalidStatusChange = 'INVALID_STATUS_CHANGE',
  FailedSavingImage = 'FAILED_SAVING_IMAGE',
  FailedSavingAttachement = 'FAILED_SAVING_ATTACHEMENT',
  FromDateAfterToDate = 'FROM_DATE_AFTER_TO_DATE',
  MissingMaintenanceCustomer = 'MISSING_MAINTENANCE_CUSTOMER'
}

export enum CustomPriceError {
  NoArticleFound = 'NO_ARTICLE_FOUND',
  BadPrice = 'BAD_PRICE'
}

export enum WarrantyError {
  CannotChangeRegistered = 'CANNOT_CHANGE_REGISTERED',
  CannotChangeApproved = 'CANNOT_CHANGE_APPROVED',
  NoRows = 'NO_ROWS',
  WarrantyNotFound = 'WARRANTY_NOT_FOUND',
  RowNotConnectedToOrder = 'ROW_NOT_CONNECTED_TO_ORDER'
}

export enum ShoppingListError {
  BadName = 'BAD_NAME',
  BadArticleNumber = 'BAD_ARTICLE_NUMBER',
  BadAmount = 'BAD_AMOUNT',
  NoArticleFound = 'NO_ARTICLE_FOUND'
}

export enum UploadAttachementError {
  BadFileFormat = 'BAD_FILE_FORMAT'
}

export type WarrantyMessagesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WarrantyMessagesQuery = (
  { __typename?: 'Query' }
  & { warrantyById?: Maybe<(
    { __typename?: 'VehicleWarranty' }
    & { internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'name' | 'role'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'name' | 'role'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id'>
        )> }
      ) }
    )> }
  ) | (
    { __typename?: 'GoodWillWarranty' }
    & { internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'name' | 'role'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'name' | 'role'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id'>
        )> }
      ) }
    )> }
  ) | (
    { __typename?: 'PartWarranty' }
    & { internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'name' | 'role'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'name' | 'role'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id'>
        )> }
      ) }
    )> }
  ) | (
    { __typename?: 'LegacyWarranty' }
    & { internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'name' | 'role'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'name' | 'role'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id'>
        )> }
      ) }
    )> }
  )> }
);

export type MaintenanceTaskMessagesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MaintenanceTaskMessagesQuery = (
  { __typename?: 'Query' }
  & { maintenanceTaskById?: Maybe<(
    { __typename?: 'MaintenanceTask' }
    & { internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'name' | 'role'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'name' | 'role'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id'>
        )> }
      ) }
    )> }
  )> }
);

export type VehicleInfoViewQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type VehicleInfoViewQuery = (
  { __typename?: 'Query' }
  & { warrantyVehicleById: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'typeOfInstallation' | 'engineNumber' | 'factoryCode' | 'status' | 'producedAt' | 'firstRegisteredAt' | 'replacementText'>
    & { stockVehicle?: Maybe<(
      { __typename?: 'StockVehicle' }
      & Pick<StockVehicle, 'color' | 'colorCode'>
    )>, brand?: Maybe<(
      { __typename?: 'Make' }
      & Pick<Make, 'id' | 'externalId' | 'name'>
    )>, modelSeries?: Maybe<(
      { __typename?: 'ModelSeries' }
      & Pick<ModelSeries, 'name'>
    )>, model?: Maybe<(
      { __typename?: 'Model' }
      & Pick<Model, 'name'>
    )>, retailer?: Maybe<(
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'city'>
    )>, nonColorFactoryOptions?: Maybe<Array<(
      { __typename?: 'FactoryOption' }
      & Pick<FactoryOption, 'description'>
    )>> }
  ) }
);

export type CustomVehiclesQueryVariables = Exact<{
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
  fetchOnBehalfOfRetailerId?: Maybe<Scalars['Int']>;
}>;


export type CustomVehiclesQuery = (
  { __typename?: 'Query' }
  & { warrantyVehicles: (
    { __typename?: 'WarrantyVehicleCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>> }
    )> }
  ) }
);

export type CustomWarrantyByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CustomWarrantyByIdQuery = (
  { __typename?: 'Query' }
  & { warrantyById?: Maybe<(
    { __typename?: 'VehicleWarranty' }
    & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { operationalizedFrom?: Maybe<(
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'id'>
    )>, vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'createdAt' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'showBfiAndKey' | 'replacementText'>
      & { nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'description'>
      )>> }
    )>, meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'externalId' | 'name'>
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      )> }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      )> }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'active' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'vehicleCampaign'>
        & { stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
          & { incomingDeliveries: Array<(
            { __typename?: 'IncomingDelivery' }
            & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
          )> }
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
          & { incomingDeliveries: Array<(
            { __typename?: 'IncomingDelivery' }
            & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
          )> }
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )>, orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'name' | 'vehicleType' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'stockArrivalDate' | 'connectionType'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      )> }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      )> }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'role'>
      ) }
    )>, damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'GoodWillWarranty' }
    & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { operationalizedTo?: Maybe<(
      { __typename?: 'VehicleWarranty' }
      & Pick<VehicleWarranty, 'id' | 'status'>
    )>, vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'createdAt' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'showBfiAndKey' | 'replacementText'>
      & { nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'description'>
      )>> }
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'externalId' | 'name'>
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      )> }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      )> }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'active' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'vehicleCampaign'>
        & { stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
          & { incomingDeliveries: Array<(
            { __typename?: 'IncomingDelivery' }
            & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
          )> }
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
          & { incomingDeliveries: Array<(
            { __typename?: 'IncomingDelivery' }
            & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
          )> }
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )>, orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'name' | 'vehicleType' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'stockArrivalDate' | 'connectionType'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      )> }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      )> }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'role'>
      ) }
    )>, damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'PartWarranty' }
    & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { purchaseReceiptImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'externalId' | 'name'>
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      )> }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      )> }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'active' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'vehicleCampaign'>
        & { stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
          & { incomingDeliveries: Array<(
            { __typename?: 'IncomingDelivery' }
            & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
          )> }
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
          & { incomingDeliveries: Array<(
            { __typename?: 'IncomingDelivery' }
            & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
          )> }
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )>, orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'name' | 'vehicleType' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'stockArrivalDate' | 'connectionType'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      )> }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      )> }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'role'>
      ) }
    )>, damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'LegacyWarranty' }
    & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'createdAt' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'showBfiAndKey' | 'replacementText'>
      & { nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'description'>
      )>> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'externalId' | 'name'>
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      )> }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      )> }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'active' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'vehicleCampaign'>
        & { stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
          & { incomingDeliveries: Array<(
            { __typename?: 'IncomingDelivery' }
            & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
          )> }
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
          & { incomingDeliveries: Array<(
            { __typename?: 'IncomingDelivery' }
            & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
          )> }
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )>, orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'name' | 'vehicleType' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'stockArrivalDate' | 'connectionType'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      )> }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      )> }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'role'>
      ) }
    )>, damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  )> }
);

export type FolderNamesByVehicleIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FolderNamesByVehicleIdQuery = (
  { __typename?: 'Query' }
  & { foldersByVehicleId: (
    { __typename?: 'FoldersByVehicleId' }
    & { folderByMake?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title'>
    )>, foldersByModelSeries: Array<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title'>
      )> }
    )> }
  ) }
);

export type UserByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type UserByIdQuery = (
  { __typename?: 'Query' }
  & { userById?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
    & { retailer?: Maybe<(
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )> }
  )> }
);

export type UsersByIdsQueryVariables = Exact<{
  ids: Array<Scalars['String']>;
}>;


export type UsersByIdsQuery = (
  { __typename?: 'Query' }
  & { usersByIds: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
    & { retailer?: Maybe<(
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )> }
  )> }
);

export type UserByTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type UserByTokenQuery = (
  { __typename?: 'Query' }
  & { userByToken?: Maybe<(
    { __typename?: 'UserByTokenReturnType' }
    & Pick<UserByTokenReturnType, 'actAsRetailerRight'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )> }
  )> }
);

export type UsersQueryVariables = Exact<{
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users?: Maybe<(
    { __typename?: 'UserCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )> }
  )> }
);

export type MakesQueryVariables = Exact<{
  filter?: Maybe<MakeFilter>;
}>;


export type MakesQuery = (
  { __typename?: 'Query' }
  & { makes: (
    { __typename?: 'MakeCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'Make' }
      & Pick<Make, 'id' | 'externalId' | 'name'>
    )> }
  ) }
);

export type MakesByIdsQueryVariables = Exact<{
  ids: Array<Scalars['ID']>;
}>;


export type MakesByIdsQuery = (
  { __typename?: 'Query' }
  & { makesByIds: Array<(
    { __typename?: 'Make' }
    & Pick<Make, 'id' | 'externalId' | 'name'>
  )> }
);

export type MakeByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MakeByIdQuery = (
  { __typename?: 'Query' }
  & { makeById?: Maybe<(
    { __typename?: 'Make' }
    & Pick<Make, 'id' | 'externalId' | 'name'>
  )> }
);

export type AllMakesByLoggedInUserQueryVariables = Exact<{ [key: string]: never; }>;


export type AllMakesByLoggedInUserQuery = (
  { __typename?: 'Query' }
  & { allMakesByLoggedInUser: Array<(
    { __typename?: 'Make' }
    & Pick<Make, 'id' | 'externalId' | 'name'>
  )> }
);

export type RetailersQueryVariables = Exact<{
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type RetailersQuery = (
  { __typename?: 'Query' }
  & { retailers: (
    { __typename?: 'RetailerCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )> }
  ) }
);

export type RetailersByIdsQueryVariables = Exact<{
  ids: Array<Scalars['Int']>;
}>;


export type RetailersByIdsQuery = (
  { __typename?: 'Query' }
  & { retailersByIds: Array<(
    { __typename?: 'Retailer' }
    & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
    & { makes: Array<(
      { __typename?: 'RetailerMake' }
      & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
      & { make: (
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      ) }
    )>, users: Array<(
      { __typename?: 'RetailerUser' }
      & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, contactUser: (
      { __typename?: 'RetailerUser' }
      & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  )> }
);

export type RetailerByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RetailerByIdQuery = (
  { __typename?: 'Query' }
  & { retailerById?: Maybe<(
    { __typename?: 'Retailer' }
    & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
    & { makes: Array<(
      { __typename?: 'RetailerMake' }
      & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
      & { make: (
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      ) }
    )>, users: Array<(
      { __typename?: 'RetailerUser' }
      & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, contactUser: (
      { __typename?: 'RetailerUser' }
      & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  )> }
);

export type WarrantiesQueryVariables = Exact<{
  filter?: Maybe<WarrantyFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type WarrantiesQuery = (
  { __typename?: 'Query' }
  & { warranties?: Maybe<(
    { __typename?: 'WarrantyCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'VehicleWarranty' }
      & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, meterReadingImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedFrom?: Maybe<(
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedTo?: Maybe<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'PartWarranty' }
      & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { purchaseReceiptImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'LegacyWarranty' }
      & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    )> }
  )> }
);

export type WarrantyByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WarrantyByIdQuery = (
  { __typename?: 'Query' }
  & { warrantyById?: Maybe<(
    { __typename?: 'VehicleWarranty' }
    & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedFrom?: Maybe<(
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedTo?: Maybe<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'GoodWillWarranty' }
    & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedTo?: Maybe<(
      { __typename?: 'VehicleWarranty' }
      & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, meterReadingImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedFrom?: Maybe<(
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'PartWarranty' }
    & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { purchaseReceiptImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'LegacyWarranty' }
    & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  )> }
);

export type VehicleWarrantyHistoryQueryVariables = Exact<{
  warrantyId: Scalars['ID'];
}>;


export type VehicleWarrantyHistoryQuery = (
  { __typename?: 'Query' }
  & { vehicleWarrantyHistory: Array<(
    { __typename?: 'VehicleWarranty' }
    & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedFrom?: Maybe<(
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedTo?: Maybe<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'GoodWillWarranty' }
    & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedTo?: Maybe<(
      { __typename?: 'VehicleWarranty' }
      & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, meterReadingImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedFrom?: Maybe<(
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'PartWarranty' }
    & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { purchaseReceiptImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'LegacyWarranty' }
    & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  )> }
);

export type WarrantyVehiclesQueryVariables = Exact<{
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
  fetchOnBehalfOfRetailerId?: Maybe<Scalars['Int']>;
}>;


export type WarrantyVehiclesQuery = (
  { __typename?: 'Query' }
  & { warrantyVehicles: (
    { __typename?: 'WarrantyVehicleCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )> }
  ) }
);

export type WarrantyVehicleByVinOrRegNumQueryVariables = Exact<{
  searchTerm: Scalars['String'];
}>;


export type WarrantyVehicleByVinOrRegNumQuery = (
  { __typename?: 'Query' }
  & { warrantyVehicleByVinOrRegNum?: Maybe<(
    { __typename?: 'WarrantyVehicleAllDataWithFolder' }
    & { brandFolder?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, modelFolder?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )> }
  )> }
);

export type WarrantyVehicleByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type WarrantyVehicleByIdQuery = (
  { __typename?: 'Query' }
  & { warrantyVehicleById: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
    & { stockVehicle?: Maybe<(
      { __typename?: 'StockVehicle' }
      & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), equipments: Array<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, factoryOptions: Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )> }
    )>, brand?: Maybe<(
      { __typename?: 'Make' }
      & Pick<Make, 'id' | 'externalId' | 'name'>
    )>, modelSeries?: Maybe<(
      { __typename?: 'ModelSeries' }
      & Pick<ModelSeries, 'id' | 'name'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, models: Array<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )> }
    )>, model?: Maybe<(
      { __typename?: 'Model' }
      & Pick<Model, 'id' | 'name' | 'description'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )> }
    )>, retailer?: Maybe<(
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, nonColorFactoryOptions?: Maybe<Array<(
      { __typename?: 'FactoryOption' }
      & Pick<FactoryOption, 'id' | 'description'>
      & { factoryOptionType?: Maybe<(
        { __typename?: 'FactoryOptionType' }
        & Pick<FactoryOptionType, 'id' | 'description'>
      )> }
    )>>, warranties: Array<(
      { __typename?: 'VehicleWarranty' }
      & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, meterReadingImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedFrom?: Maybe<(
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedTo?: Maybe<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'PartWarranty' }
      & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { purchaseReceiptImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'LegacyWarranty' }
      & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    )> }
  ) }
);

export type WarrantyDisplayViewQueryVariables = Exact<{ [key: string]: never; }>;


export type WarrantyDisplayViewQuery = (
  { __typename?: 'Query' }
  & { warrantyDisplayView: Array<(
    { __typename?: 'WarrantyDisplayView' }
    & Pick<WarrantyDisplayView, 'id' | 'name' | 'active' | 'createdAt' | 'updatedAt'>
    & { columnData: Array<(
      { __typename?: 'ColumnData' }
      & Pick<ColumnData, 'position' | 'name' | 'active'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  )> }
);

export type ExplodedViewsQueryVariables = Exact<{
  filter?: Maybe<ExplodedViewFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type ExplodedViewsQuery = (
  { __typename?: 'Query' }
  & { explodedViews: (
    { __typename?: 'ExplodedViewCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'ExplodedView' }
      & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
      & { explodedViewImages: Array<(
        { __typename?: 'ExplodedViewImage' }
        & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, explodedViewRows: Array<(
        { __typename?: 'ExplodedViewRow' }
        & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )> }
      )>, brandFolder: (
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      ), modelFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, mainCategoryFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, subCategoryFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, folder: (
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      ) }
    )> }
  ) }
);

export type ExplodedViewRowsQueryVariables = Exact<{
  filter?: Maybe<ExplodedViewRowFilter>;
}>;


export type ExplodedViewRowsQuery = (
  { __typename?: 'Query' }
  & { explodedViewRows: (
    { __typename?: 'ExplodedViewRowCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'ExplodedViewRow' }
      & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )> }
    )> }
  ) }
);

export type ExplodedViewByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ExplodedViewByIdQuery = (
  { __typename?: 'Query' }
  & { explodedViewById: (
    { __typename?: 'ExplodedView' }
    & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
    & { explodedViewImages: Array<(
      { __typename?: 'ExplodedViewImage' }
      & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, explodedViewRows: Array<(
      { __typename?: 'ExplodedViewRow' }
      & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )> }
    )>, brandFolder: (
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), modelFolder?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, mainCategoryFolder?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, subCategoryFolder?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, folder: (
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  ) }
);

export type FoldersByVehicleIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type FoldersByVehicleIdQuery = (
  { __typename?: 'Query' }
  & { foldersByVehicleId: (
    { __typename?: 'FoldersByVehicleId' }
    & { folderByMake?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, foldersByModelSeries: Array<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )> }
  ) }
);

export type FoldersQueryVariables = Exact<{ [key: string]: never; }>;


export type FoldersQuery = (
  { __typename?: 'Query' }
  & { folders: Array<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
    & { parent?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, children: Array<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, explodedViews: Array<(
      { __typename?: 'ExplodedView' }
      & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
      & { explodedViewImages: Array<(
        { __typename?: 'ExplodedViewImage' }
        & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, explodedViewRows: Array<(
        { __typename?: 'ExplodedViewRow' }
        & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )> }
      )>, brandFolder: (
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      ), modelFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, mainCategoryFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, subCategoryFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, folder: (
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      ) }
    )> }
  )> }
);

export type ParentFoldersQueryVariables = Exact<{
  fetchOnBehalfOfRetailerId?: Maybe<Scalars['Int']>;
}>;


export type ParentFoldersQuery = (
  { __typename?: 'Query' }
  & { parentFolders: Array<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
    & { parent?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, children: Array<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, explodedViews: Array<(
      { __typename?: 'ExplodedView' }
      & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
      & { explodedViewImages: Array<(
        { __typename?: 'ExplodedViewImage' }
        & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, explodedViewRows: Array<(
        { __typename?: 'ExplodedViewRow' }
        & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )> }
      )>, brandFolder: (
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      ), modelFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, mainCategoryFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, subCategoryFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, folder: (
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      ) }
    )> }
  )> }
);

export type FolderByIdQueryVariables = Exact<{
  id: Scalars['String'];
  depth?: Maybe<Scalars['Int']>;
}>;


export type FolderByIdQuery = (
  { __typename?: 'Query' }
  & { folderById: (
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
    & { parent?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, children: Array<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, explodedViews: Array<(
      { __typename?: 'ExplodedView' }
      & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
      & { explodedViewImages: Array<(
        { __typename?: 'ExplodedViewImage' }
        & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, explodedViewRows: Array<(
        { __typename?: 'ExplodedViewRow' }
        & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )> }
      )>, brandFolder: (
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      ), modelFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, mainCategoryFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, subCategoryFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, folder: (
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      ) }
    )> }
  ) }
);

export type SparePartsQueryVariables = Exact<{
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type SparePartsQuery = (
  { __typename?: 'Query' }
  & { spareParts: (
    { __typename?: 'SparePartCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'SparePart' }
      & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, explodedViewRows: Array<(
        { __typename?: 'ExplodedViewRow' }
        & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ) }
    )> }
  ) }
);

export type OrderByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type OrderByIdQuery = (
  { __typename?: 'Query' }
  & { orderById: (
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
    & { items: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
      & { vehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, fee?: Maybe<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
      )>, replacedProduct?: Maybe<(
        { __typename?: 'ReplacedProduct' }
        & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
      )>, detailedQuantities?: Maybe<(
        { __typename?: 'DetailedOrderItemQuantities' }
        & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
      )>, order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        & { items: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, products: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, fees: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, referenceUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoices: Array<Maybe<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )>> }
      ), connection?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, invoices: Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        & { buyer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoiceRows: Array<(
          { __typename?: 'InvoiceRow' }
          & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
        )> }
      )> }
    )>, products: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
      & { vehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, fee?: Maybe<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
      )>, replacedProduct?: Maybe<(
        { __typename?: 'ReplacedProduct' }
        & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
      )>, detailedQuantities?: Maybe<(
        { __typename?: 'DetailedOrderItemQuantities' }
        & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
      )>, order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        & { items: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, products: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, fees: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, referenceUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoices: Array<Maybe<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )>> }
      ), connection?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, invoices: Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        & { buyer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoiceRows: Array<(
          { __typename?: 'InvoiceRow' }
          & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
        )> }
      )> }
    )>, fees: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
      & { vehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, fee?: Maybe<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
      )>, replacedProduct?: Maybe<(
        { __typename?: 'ReplacedProduct' }
        & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
      )>, detailedQuantities?: Maybe<(
        { __typename?: 'DetailedOrderItemQuantities' }
        & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
      )>, order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        & { items: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, products: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, fees: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, referenceUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoices: Array<Maybe<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )>> }
      ), connection?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, invoices: Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        & { buyer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoiceRows: Array<(
          { __typename?: 'InvoiceRow' }
          & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
        )> }
      )> }
    )>, referenceUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, retailer?: Maybe<(
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, invoices: Array<Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
      & { buyer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, invoiceRows: Array<(
        { __typename?: 'InvoiceRow' }
        & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
      )> }
    )>> }
  ) }
);

export type OrdersBySerialNumberQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type OrdersBySerialNumberQuery = (
  { __typename?: 'Query' }
  & { ordersBySerialNumber: Array<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
    & { items: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
      & { vehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, fee?: Maybe<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
      )>, replacedProduct?: Maybe<(
        { __typename?: 'ReplacedProduct' }
        & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
      )>, detailedQuantities?: Maybe<(
        { __typename?: 'DetailedOrderItemQuantities' }
        & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
      )>, order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        & { items: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, products: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, fees: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, referenceUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoices: Array<Maybe<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )>> }
      ), connection?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, invoices: Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        & { buyer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoiceRows: Array<(
          { __typename?: 'InvoiceRow' }
          & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
        )> }
      )> }
    )>, products: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
      & { vehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, fee?: Maybe<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
      )>, replacedProduct?: Maybe<(
        { __typename?: 'ReplacedProduct' }
        & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
      )>, detailedQuantities?: Maybe<(
        { __typename?: 'DetailedOrderItemQuantities' }
        & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
      )>, order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        & { items: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, products: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, fees: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, referenceUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoices: Array<Maybe<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )>> }
      ), connection?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, invoices: Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        & { buyer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoiceRows: Array<(
          { __typename?: 'InvoiceRow' }
          & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
        )> }
      )> }
    )>, fees: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
      & { vehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, fee?: Maybe<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
      )>, replacedProduct?: Maybe<(
        { __typename?: 'ReplacedProduct' }
        & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
      )>, detailedQuantities?: Maybe<(
        { __typename?: 'DetailedOrderItemQuantities' }
        & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
      )>, order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        & { items: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, products: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, fees: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, referenceUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoices: Array<Maybe<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )>> }
      ), connection?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, invoices: Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        & { buyer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoiceRows: Array<(
          { __typename?: 'InvoiceRow' }
          & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
        )> }
      )> }
    )>, referenceUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, retailer?: Maybe<(
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, invoices: Array<Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
      & { buyer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, invoiceRows: Array<(
        { __typename?: 'InvoiceRow' }
        & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
      )> }
    )>> }
  )> }
);

export type OrdersQueryVariables = Exact<{
  filter?: Maybe<OrderFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
  fetchOrdersOnBehalfOfRetailerId?: Maybe<Scalars['Int']>;
}>;


export type OrdersQuery = (
  { __typename?: 'Query' }
  & { orders: (
    { __typename?: 'OrderCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
      & { items: Array<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, products: Array<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, fees: Array<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, referenceUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, invoices: Array<Maybe<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        & { buyer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoiceRows: Array<(
          { __typename?: 'InvoiceRow' }
          & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
        )> }
      )>> }
    )> }
  ) }
);

export type OrderItemsQueryVariables = Exact<{
  filter?: Maybe<OrderItemFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type OrderItemsQuery = (
  { __typename?: 'Query' }
  & { orderItems: (
    { __typename?: 'OrderItemCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
      & { vehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, fee?: Maybe<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
      )>, replacedProduct?: Maybe<(
        { __typename?: 'ReplacedProduct' }
        & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
      )>, detailedQuantities?: Maybe<(
        { __typename?: 'DetailedOrderItemQuantities' }
        & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
      )>, order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        & { items: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, products: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, fees: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, referenceUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoices: Array<Maybe<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )>> }
      ), connection?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, invoices: Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        & { buyer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoiceRows: Array<(
          { __typename?: 'InvoiceRow' }
          & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
        )> }
      )> }
    )> }
  ) }
);

export type OrderItemByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type OrderItemByIdQuery = (
  { __typename?: 'Query' }
  & { orderItemById: (
    { __typename?: 'OrderItem' }
    & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
    & { vehicle?: Maybe<(
      { __typename?: 'StockVehicle' }
      & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), equipments: Array<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, factoryOptions: Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )> }
    )>, equipment?: Maybe<(
      { __typename?: 'Equipment' }
      & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, equipmentGroup?: Maybe<(
        { __typename?: 'EquipmentGroup' }
        & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
      )> }
    )>, sparePart?: Maybe<(
      { __typename?: 'SparePart' }
      & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, explodedViewRows: Array<(
        { __typename?: 'ExplodedViewRow' }
        & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ) }
    )>, billOfMaterial?: Maybe<(
      { __typename?: 'BillOfMaterial' }
      & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
      & { bomRows: Array<(
        { __typename?: 'BillOfMaterialRow' }
        & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, equipmentGroup?: Maybe<(
        { __typename?: 'EquipmentGroup' }
        & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
      )> }
    )>, fee?: Maybe<(
      { __typename?: 'Fee' }
      & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
    )>, replacedProduct?: Maybe<(
      { __typename?: 'ReplacedProduct' }
      & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
    )>, detailedQuantities?: Maybe<(
      { __typename?: 'DetailedOrderItemQuantities' }
      & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
    )>, order: (
      { __typename?: 'Order' }
      & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
      & { items: Array<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, products: Array<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, fees: Array<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, referenceUser?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, invoices: Array<Maybe<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        & { buyer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoiceRows: Array<(
          { __typename?: 'InvoiceRow' }
          & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
        )> }
      )>> }
    ), connection?: Maybe<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
      & { vehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, fee?: Maybe<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
      )>, replacedProduct?: Maybe<(
        { __typename?: 'ReplacedProduct' }
        & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
      )>, detailedQuantities?: Maybe<(
        { __typename?: 'DetailedOrderItemQuantities' }
        & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
      )>, order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        & { items: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, products: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, fees: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, referenceUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoices: Array<Maybe<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )>> }
      ), connection?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, invoices: Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        & { buyer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoiceRows: Array<(
          { __typename?: 'InvoiceRow' }
          & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
        )> }
      )> }
    )>, invoices: Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
      & { buyer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, invoiceRows: Array<(
        { __typename?: 'InvoiceRow' }
        & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
      )> }
    )> }
  ) }
);

export type OrderItemsByOrderQueryVariables = Exact<{
  orderId: Scalars['String'];
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type OrderItemsByOrderQuery = (
  { __typename?: 'Query' }
  & { orderItemsByOrder: (
    { __typename?: 'OrderItemCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
      & { vehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, fee?: Maybe<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
      )>, replacedProduct?: Maybe<(
        { __typename?: 'ReplacedProduct' }
        & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
      )>, detailedQuantities?: Maybe<(
        { __typename?: 'DetailedOrderItemQuantities' }
        & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
      )>, order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        & { items: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, products: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, fees: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, referenceUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoices: Array<Maybe<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )>> }
      ), connection?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, invoices: Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        & { buyer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoiceRows: Array<(
          { __typename?: 'InvoiceRow' }
          & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
        )> }
      )> }
    )> }
  ) }
);

export type StockVehiclesQueryVariables = Exact<{
  filter?: Maybe<WebshopFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type StockVehiclesQuery = (
  { __typename?: 'Query' }
  & { stockVehicles: (
    { __typename?: 'StockVehicleCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'StockVehicle' }
      & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), equipments: Array<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, factoryOptions: Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )> }
    )> }
  ) }
);

export type StockVehicleByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type StockVehicleByIdQuery = (
  { __typename?: 'Query' }
  & { stockVehicleById: (
    { __typename?: 'StockVehicle' }
    & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
    & { brand?: Maybe<(
      { __typename?: 'Make' }
      & Pick<Make, 'id' | 'externalId' | 'name'>
    )>, modelSeries?: Maybe<(
      { __typename?: 'ModelSeries' }
      & Pick<ModelSeries, 'id' | 'name'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, models: Array<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )> }
    )>, model?: Maybe<(
      { __typename?: 'Model' }
      & Pick<Model, 'id' | 'name' | 'description'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )> }
    )>, thumbnail?: Maybe<(
      { __typename?: 'StockImage' }
      & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
      & { equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )> }
    )>, images: Array<(
      { __typename?: 'StockImage' }
      & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
      & { equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )> }
    )>, stock: (
      { __typename?: 'Stock' }
      & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
      & { incomingDeliveries: Array<(
        { __typename?: 'IncomingDelivery' }
        & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
      )> }
    ), equipments: Array<(
      { __typename?: 'Equipment' }
      & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, equipmentGroup?: Maybe<(
        { __typename?: 'EquipmentGroup' }
        & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
      )> }
    )>, factoryOptions: Array<(
      { __typename?: 'FactoryOption' }
      & Pick<FactoryOption, 'id' | 'description'>
      & { factoryOptionType?: Maybe<(
        { __typename?: 'FactoryOptionType' }
        & Pick<FactoryOptionType, 'id' | 'description'>
      )> }
    )> }
  ) }
);

export type EquipmentsQueryVariables = Exact<{
  filter?: Maybe<WebshopFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type EquipmentsQuery = (
  { __typename?: 'Query' }
  & { equipments: (
    { __typename?: 'EquipmentCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'Equipment' }
      & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, equipmentGroup?: Maybe<(
        { __typename?: 'EquipmentGroup' }
        & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
      )> }
    )> }
  ) }
);

export type EquipmentByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type EquipmentByIdQuery = (
  { __typename?: 'Query' }
  & { equipmentById: (
    { __typename?: 'Equipment' }
    & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
    & { modelSeries?: Maybe<(
      { __typename?: 'ModelSeries' }
      & Pick<ModelSeries, 'id' | 'name'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, models: Array<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )> }
    )>, model?: Maybe<(
      { __typename?: 'Model' }
      & Pick<Model, 'id' | 'name' | 'description'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )> }
    )>, stock: (
      { __typename?: 'Stock' }
      & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
      & { incomingDeliveries: Array<(
        { __typename?: 'IncomingDelivery' }
        & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
      )> }
    ), brand?: Maybe<(
      { __typename?: 'Make' }
      & Pick<Make, 'id' | 'externalId' | 'name'>
    )>, thumbnail?: Maybe<(
      { __typename?: 'StockImage' }
      & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
      & { equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )> }
    )>, images: Array<(
      { __typename?: 'StockImage' }
      & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
      & { equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )> }
    )>, equipmentGroup?: Maybe<(
      { __typename?: 'EquipmentGroup' }
      & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
    )> }
  ) }
);

export type CampaignItemsQueryVariables = Exact<{
  filter?: Maybe<BaseFilter>;
}>;


export type CampaignItemsQuery = (
  { __typename?: 'Query' }
  & { campaignItems: (
    { __typename?: 'FullTextSearchCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'FullTextSearch' }
      & Pick<FullTextSearch, 'id' | 'title' | 'articleNumber' | 'price' | 'discountedPrice' | 'type'>
      & { thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, vehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )> }
    )> }
  ) }
);

export type RelatedEquipmentsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type RelatedEquipmentsQuery = (
  { __typename?: 'Query' }
  & { relatedEquipments: (
    { __typename?: 'RelatedProducts' }
    & { campaigns: Array<(
      { __typename?: 'RelatedProduct' }
      & { billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )> }
    )>, relatedEquipments: Array<(
      { __typename?: 'RelatedProduct' }
      & { billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )> }
    )> }
  ) }
);

export type GetWebshopFiltersQueryVariables = Exact<{
  filter?: Maybe<WebshopFilter>;
}>;


export type GetWebshopFiltersQuery = (
  { __typename?: 'Query' }
  & { getWebshopFilters: (
    { __typename?: 'WebshopFilters' }
    & Pick<WebshopFilters, 'vehicleTypes' | 'stockStatus'>
    & { makes: Array<(
      { __typename?: 'Make' }
      & Pick<Make, 'id' | 'externalId' | 'name'>
    )>, modelSeries: Array<(
      { __typename?: 'ModelSeries' }
      & Pick<ModelSeries, 'id' | 'name'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, models: Array<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )> }
    )>, models: Array<(
      { __typename?: 'Model' }
      & Pick<Model, 'id' | 'name' | 'description'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )> }
    )>, colors: Array<(
      { __typename?: 'FactoryOption' }
      & Pick<FactoryOption, 'id' | 'description'>
      & { factoryOptionType?: Maybe<(
        { __typename?: 'FactoryOptionType' }
        & Pick<FactoryOptionType, 'id' | 'description'>
      )> }
    )>, equipmentGroups: Array<(
      { __typename?: 'EquipmentGroup' }
      & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
    )> }
  ) }
);

export type BillOfMaterialQueryVariables = Exact<{
  filter?: Maybe<BillOfMaterialsFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type BillOfMaterialQuery = (
  { __typename?: 'Query' }
  & { billOfMaterial: (
    { __typename?: 'BillOfMaterialCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'BillOfMaterial' }
      & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
      & { bomRows: Array<(
        { __typename?: 'BillOfMaterialRow' }
        & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, equipmentGroup?: Maybe<(
        { __typename?: 'EquipmentGroup' }
        & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
      )> }
    )> }
  ) }
);

export type BillOfMaterialByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type BillOfMaterialByIdQuery = (
  { __typename?: 'Query' }
  & { billOfMaterialById: (
    { __typename?: 'BillOfMaterial' }
    & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
    & { bomRows: Array<(
      { __typename?: 'BillOfMaterialRow' }
      & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )> }
    )>, stock: (
      { __typename?: 'Stock' }
      & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
      & { incomingDeliveries: Array<(
        { __typename?: 'IncomingDelivery' }
        & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
      )> }
    ), images: Array<(
      { __typename?: 'StockImage' }
      & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
      & { equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )> }
    )>, thumbnail?: Maybe<(
      { __typename?: 'StockImage' }
      & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
      & { equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )> }
    )>, brand?: Maybe<(
      { __typename?: 'Make' }
      & Pick<Make, 'id' | 'externalId' | 'name'>
    )>, equipmentGroup?: Maybe<(
      { __typename?: 'EquipmentGroup' }
      & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
    )> }
  ) }
);

export type ShoppingListByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ShoppingListByIdQuery = (
  { __typename?: 'Query' }
  & { shoppingListById: (
    { __typename?: 'ShoppingList' }
    & Pick<ShoppingList, 'id' | 'name' | 'remark' | 'createdAt' | 'updatedAt'>
    & { items: Array<(
      { __typename?: 'ShoppingListItem' }
      & Pick<ShoppingListItem, 'id' | 'amount' | 'isCampaignEquipment' | 'stockVehicleProductCount'>
      & { vehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, relatedVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  ) }
);

export type ShoppingListsQueryVariables = Exact<{
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
  onBehalfOfRetailerId?: Maybe<Scalars['Int']>;
}>;


export type ShoppingListsQuery = (
  { __typename?: 'Query' }
  & { shoppingLists: (
    { __typename?: 'ShoppingListCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'ShoppingList' }
      & Pick<ShoppingList, 'id' | 'name' | 'remark' | 'createdAt' | 'updatedAt'>
      & { items: Array<(
        { __typename?: 'ShoppingListItem' }
        & Pick<ShoppingListItem, 'id' | 'amount' | 'isCampaignEquipment' | 'stockVehicleProductCount'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, relatedVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )> }
  ) }
);

export type VehicleQueryVariables = Exact<{
  input?: Maybe<VehicleInput>;
}>;


export type VehicleQuery = (
  { __typename?: 'Query' }
  & { vehicle?: Maybe<(
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
    & { stockVehicle?: Maybe<(
      { __typename?: 'StockVehicle' }
      & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), equipments: Array<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, factoryOptions: Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )> }
    )>, brand?: Maybe<(
      { __typename?: 'Make' }
      & Pick<Make, 'id' | 'externalId' | 'name'>
    )>, modelSeries?: Maybe<(
      { __typename?: 'ModelSeries' }
      & Pick<ModelSeries, 'id' | 'name'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, models: Array<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )> }
    )>, model?: Maybe<(
      { __typename?: 'Model' }
      & Pick<Model, 'id' | 'name' | 'description'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )> }
    )>, retailer?: Maybe<(
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, nonColorFactoryOptions?: Maybe<Array<(
      { __typename?: 'FactoryOption' }
      & Pick<FactoryOption, 'id' | 'description'>
      & { factoryOptionType?: Maybe<(
        { __typename?: 'FactoryOptionType' }
        & Pick<FactoryOptionType, 'id' | 'description'>
      )> }
    )>>, warranties: Array<(
      { __typename?: 'VehicleWarranty' }
      & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, meterReadingImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedFrom?: Maybe<(
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedTo?: Maybe<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'PartWarranty' }
      & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { purchaseReceiptImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'LegacyWarranty' }
      & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    )> }
  )> }
);

export type ConsignmentVehiclesQueryVariables = Exact<{
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type ConsignmentVehiclesQuery = (
  { __typename?: 'Query' }
  & { consignmentVehicles: (
    { __typename?: 'VehicleCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )> }
  ) }
);

export type ConsignmentVehicleByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ConsignmentVehicleByIdQuery = (
  { __typename?: 'Query' }
  & { consignmentVehicleById: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
    & { stockVehicle?: Maybe<(
      { __typename?: 'StockVehicle' }
      & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), equipments: Array<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, factoryOptions: Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )> }
    )>, brand?: Maybe<(
      { __typename?: 'Make' }
      & Pick<Make, 'id' | 'externalId' | 'name'>
    )>, modelSeries?: Maybe<(
      { __typename?: 'ModelSeries' }
      & Pick<ModelSeries, 'id' | 'name'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, models: Array<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )> }
    )>, model?: Maybe<(
      { __typename?: 'Model' }
      & Pick<Model, 'id' | 'name' | 'description'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )> }
    )>, retailer?: Maybe<(
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, nonColorFactoryOptions?: Maybe<Array<(
      { __typename?: 'FactoryOption' }
      & Pick<FactoryOption, 'id' | 'description'>
      & { factoryOptionType?: Maybe<(
        { __typename?: 'FactoryOptionType' }
        & Pick<FactoryOptionType, 'id' | 'description'>
      )> }
    )>>, warranties: Array<(
      { __typename?: 'VehicleWarranty' }
      & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, meterReadingImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedFrom?: Maybe<(
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedTo?: Maybe<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'PartWarranty' }
      & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { purchaseReceiptImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'LegacyWarranty' }
      & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    )> }
  ) }
);

export type ExhibitionVehiclesQueryVariables = Exact<{
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type ExhibitionVehiclesQuery = (
  { __typename?: 'Query' }
  & { exhibitionVehicles: (
    { __typename?: 'VehicleCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )> }
  ) }
);

export type ExhibitionVehicleByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ExhibitionVehicleByIdQuery = (
  { __typename?: 'Query' }
  & { exhibitionVehicleById: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
    & { stockVehicle?: Maybe<(
      { __typename?: 'StockVehicle' }
      & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), equipments: Array<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, factoryOptions: Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )> }
    )>, brand?: Maybe<(
      { __typename?: 'Make' }
      & Pick<Make, 'id' | 'externalId' | 'name'>
    )>, modelSeries?: Maybe<(
      { __typename?: 'ModelSeries' }
      & Pick<ModelSeries, 'id' | 'name'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, models: Array<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )> }
    )>, model?: Maybe<(
      { __typename?: 'Model' }
      & Pick<Model, 'id' | 'name' | 'description'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )> }
    )>, retailer?: Maybe<(
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, nonColorFactoryOptions?: Maybe<Array<(
      { __typename?: 'FactoryOption' }
      & Pick<FactoryOption, 'id' | 'description'>
      & { factoryOptionType?: Maybe<(
        { __typename?: 'FactoryOptionType' }
        & Pick<FactoryOptionType, 'id' | 'description'>
      )> }
    )>>, warranties: Array<(
      { __typename?: 'VehicleWarranty' }
      & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, meterReadingImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedFrom?: Maybe<(
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedTo?: Maybe<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'PartWarranty' }
      & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { purchaseReceiptImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'LegacyWarranty' }
      & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    )> }
  ) }
);

export type ApiOrderByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ApiOrderByIdQuery = (
  { __typename?: 'Query' }
  & { apiOrderById: (
    { __typename?: 'ApiOrder' }
    & Pick<ApiOrder, 'id' | 'customerReference' | 'type' | 'orderDate' | 'deliveryContactNumber' | 'companyName' | 'payBy' | 'deliveryAddress' | 'email' | 'invoiceReference' | 'orderStatus' | 'deliveryStatus' | 'invoiceStatus' | 'creditStatus' | 'partialDelivery' | 'priceExcludingVAT' | 'priceIncludingVAT'>
    & { orderRows: Array<(
      { __typename?: 'OrderRow' }
      & Pick<OrderRow, 'productId' | 'description' | 'quantity' | 'priceExcludingVAT' | 'priceIncludingVAT' | 'requestedDeliverDate' | 'expectedDeliveryDate' | 'rowStatus' | 'tenderId' | 'closed' | 'closingReason'>
    )> }
  ) }
);

export type ApiOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiOrdersQuery = (
  { __typename?: 'Query' }
  & { apiOrders?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type CustomerByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type CustomerByIdQuery = (
  { __typename?: 'Query' }
  & { customerById?: Maybe<(
    { __typename?: 'Customer' }
    & Pick<Customer, 'id' | 'companyName' | 'address' | 'postalCode' | 'city' | 'country' | 'countryName' | 'telephoneNumber' | 'email' | 'organizationNumber'>
    & { contacts: Array<(
      { __typename?: 'Contact' }
      & Pick<Contact, 'contactCode' | 'firstname' | 'lastname' | 'contactCountry' | 'contactTelephone' | 'contactEmail' | 'contactRole'>
    )>, trademarks: Array<(
      { __typename?: 'Trademark' }
      & Pick<Trademark, 'trademarkId' | 'trademarkName' | 'accessToSell' | 'accessToWorkshop' | 'defaultHourlyPayment'>
      & { trademark: (
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      ) }
    )> }
  )> }
);

export type CustomersQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomersQuery = (
  { __typename?: 'Query' }
  & { customers?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type ApiProductByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type ApiProductByIdQuery = (
  { __typename?: 'Query' }
  & { apiProductById: (
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'articleNumber' | 'category' | 'description' | 'status' | 'image' | 'type' | 'modelSeries' | 'model' | 'color' | 'colorCode' | 'stockUnits' | 'stockStatus'>
    & { make?: Maybe<(
      { __typename?: 'Make' }
      & Pick<Make, 'id' | 'externalId' | 'name'>
    )>, alternativeProducts?: Maybe<Array<(
      { __typename?: 'AlternativeProducts' }
      & Pick<AlternativeProducts, 'type' | 'description'>
    )>> }
  ) }
);

export type ApiProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiProductsQuery = (
  { __typename?: 'Query' }
  & { apiProducts?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type ApiModelSeriesQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiModelSeriesQuery = (
  { __typename?: 'Query' }
  & { apiModelSeries?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type ApiModelsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiModelsQuery = (
  { __typename?: 'Query' }
  & { apiModels?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type ApiBrandsQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiBrandsQuery = (
  { __typename?: 'Query' }
  & { apiBrands?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type ApiInvoicesQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiInvoicesQuery = (
  { __typename?: 'Query' }
  & { apiInvoices?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type InvoicesQueryVariables = Exact<{
  filter?: Maybe<InvoiceFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type InvoicesQuery = (
  { __typename?: 'Query' }
  & { invoices: (
    { __typename?: 'InvoiceCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
      & { buyer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, invoiceRows: Array<(
        { __typename?: 'InvoiceRow' }
        & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
      )> }
    )> }
  ) }
);

export type InvoiceByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type InvoiceByIdQuery = (
  { __typename?: 'Query' }
  & { invoiceById: (
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
    & { buyer?: Maybe<(
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, invoiceRows: Array<(
      { __typename?: 'InvoiceRow' }
      & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
    )> }
  ) }
);

export type OperationCodesQueryVariables = Exact<{
  filter?: Maybe<OperationCodeFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type OperationCodesQuery = (
  { __typename?: 'Query' }
  & { operationCodes: (
    { __typename?: 'OperationCodeCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'OperationCode' }
      & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
    )> }
  ) }
);

export type OperationGroupByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type OperationGroupByIdQuery = (
  { __typename?: 'Query' }
  & { operationGroupById?: Maybe<(
    { __typename?: 'OperationGroup' }
    & Pick<OperationGroup, 'id' | 'name'>
    & { make?: Maybe<(
      { __typename?: 'Make' }
      & Pick<Make, 'id' | 'externalId' | 'name'>
    )>, modelSeries?: Maybe<(
      { __typename?: 'ModelSeries' }
      & Pick<ModelSeries, 'id' | 'name'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, models: Array<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )> }
    )>, model?: Maybe<(
      { __typename?: 'Model' }
      & Pick<Model, 'id' | 'name' | 'description'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )> }
    )>, operationCodes: Array<(
      { __typename?: 'OperationCode' }
      & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
    )> }
  )> }
);

export type OperationGroupAndCostsQueryVariables = Exact<{
  vehicleId?: Maybe<Scalars['String']>;
}>;


export type OperationGroupAndCostsQuery = (
  { __typename?: 'Query' }
  & { operationGroupAndCosts?: Maybe<(
    { __typename?: 'OperationGroupAndCosts' }
    & Pick<OperationGroupAndCosts, 'warrantyHourlyCost' | 'manualHourlyCost'>
    & { operationGroup: (
      { __typename?: 'OperationGroup' }
      & Pick<OperationGroup, 'id' | 'name'>
      & { make?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, operationCodes: Array<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )> }
    ) }
  )> }
);

export type OperationGroupsQueryVariables = Exact<{ [key: string]: never; }>;


export type OperationGroupsQuery = (
  { __typename?: 'Query' }
  & { operationGroups?: Maybe<(
    { __typename?: 'OperationGroupCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'ConciseOperationGroup' }
      & Pick<ConciseOperationGroup, 'id' | 'name' | 'operationCodeCount'>
      & { make?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type ProductRuleByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ProductRuleByIdQuery = (
  { __typename?: 'Query' }
  & { productRuleById: (
    { __typename?: 'ProductRule' }
    & Pick<ProductRule, 'id' | 'name' | 'showInCart' | 'startTime' | 'endTime' | 'discountTwo' | 'discountThree' | 'discountCode'>
    & { stockVehicle?: Maybe<(
      { __typename?: 'StockVehicle' }
      & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), equipments: Array<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, factoryOptions: Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )> }
    )>, equipment?: Maybe<(
      { __typename?: 'Equipment' }
      & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, equipmentGroup?: Maybe<(
        { __typename?: 'EquipmentGroup' }
        & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
      )> }
    )>, sparePart?: Maybe<(
      { __typename?: 'SparePart' }
      & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, explodedViewRows: Array<(
        { __typename?: 'ExplodedViewRow' }
        & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ) }
    )>, billOfMaterial?: Maybe<(
      { __typename?: 'BillOfMaterial' }
      & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
      & { bomRows: Array<(
        { __typename?: 'BillOfMaterialRow' }
        & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, equipmentGroup?: Maybe<(
        { __typename?: 'EquipmentGroup' }
        & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
      )> }
    )>, productReferences: Array<(
      { __typename?: 'ProductReference' }
      & Pick<ProductReference, 'id' | 'vehicleType'>
      & { make?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )> }
    )> }
  ) }
);

export type ProductRulesQueryVariables = Exact<{
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type ProductRulesQuery = (
  { __typename?: 'Query' }
  & { productRules: (
    { __typename?: 'ProductRuleCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'ProductRule' }
      & Pick<ProductRule, 'id' | 'name' | 'showInCart' | 'startTime' | 'endTime' | 'discountTwo' | 'discountThree' | 'discountCode'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, productReferences: Array<(
        { __typename?: 'ProductReference' }
        & Pick<ProductReference, 'id' | 'vehicleType'>
        & { make?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )> }
      )> }
    )> }
  ) }
);

export type IdentifierSearchQueryVariables = Exact<{
  filter: IdentifierSearchFilter;
}>;


export type IdentifierSearchQuery = (
  { __typename?: 'Query' }
  & { identifierSearch: (
    { __typename?: 'SearchResultCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges?: Maybe<(
      { __typename?: 'SearchResponseEdges' }
      & Pick<SearchResponseEdges, 'vehicleTypes'>
      & { stockVehicles?: Maybe<Array<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>>, equipments?: Maybe<Array<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>>, spareParts?: Maybe<Array<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>>, billOfMaterials?: Maybe<Array<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>>, makes?: Maybe<Array<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>>, modelSeries?: Maybe<Array<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>>, models?: Maybe<Array<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>> }
    )> }
  ) }
);

export type VolumeDiscountByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type VolumeDiscountByIdQuery = (
  { __typename?: 'Query' }
  & { volumeDiscountById: (
    { __typename?: 'VolumeDiscount' }
    & Pick<VolumeDiscount, 'id' | 'name' | 'volume' | 'startTime' | 'endTime' | 'discountOne' | 'discountTwo' | 'discountThree' | 'level'>
    & { mainProductIdentifier: (
      { __typename?: 'ProductIdentifierEntities' }
      & Pick<ProductIdentifierEntities, 'vehicleType'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, make?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )> }
    ) }
  ) }
);

export type VolumeDiscountsQueryVariables = Exact<{
  filter?: Maybe<BaseFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type VolumeDiscountsQuery = (
  { __typename?: 'Query' }
  & { volumeDiscounts: (
    { __typename?: 'VolumeDiscountCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'VolumeDiscount' }
      & Pick<VolumeDiscount, 'id' | 'name' | 'volume' | 'startTime' | 'endTime' | 'discountOne' | 'discountTwo' | 'discountThree' | 'level'>
      & { mainProductIdentifier: (
        { __typename?: 'ProductIdentifierEntities' }
        & Pick<ProductIdentifierEntities, 'vehicleType'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, make?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      ) }
    )> }
  ) }
);

export type ArticleSearchQueryVariables = Exact<{
  filter?: Maybe<ArticleSearchFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type ArticleSearchQuery = (
  { __typename?: 'Query' }
  & { articleSearch: (
    { __typename?: 'ArticleSearchCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'StockVehicle' }
      & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), equipments: Array<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, factoryOptions: Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )> }
    ) | (
      { __typename?: 'Equipment' }
      & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, equipmentGroup?: Maybe<(
        { __typename?: 'EquipmentGroup' }
        & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
      )> }
    ) | (
      { __typename?: 'SparePart' }
      & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, explodedViewRows: Array<(
        { __typename?: 'ExplodedViewRow' }
        & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ) }
    ) | (
      { __typename?: 'BillOfMaterial' }
      & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
      & { bomRows: Array<(
        { __typename?: 'BillOfMaterialRow' }
        & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, equipmentGroup?: Maybe<(
        { __typename?: 'EquipmentGroup' }
        & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
      )> }
    ) | (
      { __typename?: 'Fee' }
      & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
    )> }
  ) }
);

export type MaintenanceTaskByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MaintenanceTaskByIdQuery = (
  { __typename?: 'Query' }
  & { maintenanceTaskById?: Maybe<(
    { __typename?: 'MaintenanceTask' }
    & Pick<MaintenanceTask, 'id' | 'serviceId' | 'status' | 'meterReading' | 'customerRemark' | 'workshopRemark' | 'freeText' | 'customerReference' | 'workPerformedAt' | 'sumRequested' | 'sumCredited' | 'createdAt' | 'updatedAt' | 'customerApprovalBy' | 'customerApprovalAt'>
    & { maintenanceCustomer?: Maybe<(
      { __typename?: 'MaintenanceCustomer' }
      & Pick<MaintenanceCustomer, 'id' | 'name' | 'hourlyMaintenanceRate' | 'createdAt' | 'updatedAt' | 'externalId'>
    )>, vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, deliveryServiceImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, rows: Array<(
      { __typename?: 'ArticleMaintenanceRow' }
      & Pick<ArticleMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'ExternalMaintenanceRow' }
      & Pick<ExternalMaintenanceRow, 'externalCostType' | 'externalCostDescription' | 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { receiptImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'OperationMaintenanceRow' }
      & Pick<OperationMaintenanceRow, 'customOperationDescription' | 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'MileageAllowanceMaintenanceRow' }
      & Pick<MileageAllowanceMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'ShippingMaintenanceRow' }
      & Pick<ShippingMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )> }
  )> }
);

export type MaintenanceCustomersQueryVariables = Exact<{ [key: string]: never; }>;


export type MaintenanceCustomersQuery = (
  { __typename?: 'Query' }
  & { maintenanceCustomers?: Maybe<(
    { __typename?: 'MaintenanceCustomerCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'MaintenanceCustomer' }
      & Pick<MaintenanceCustomer, 'id' | 'name' | 'hourlyMaintenanceRate' | 'createdAt' | 'updatedAt' | 'externalId'>
    )> }
  )> }
);

export type MaintenanceTasksQueryVariables = Exact<{
  filter?: Maybe<MaintenanceTaskFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type MaintenanceTasksQuery = (
  { __typename?: 'Query' }
  & { maintenanceTasks: (
    { __typename?: 'MaintenanceTaskCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'MaintenanceTask' }
      & Pick<MaintenanceTask, 'id' | 'serviceId' | 'status' | 'meterReading' | 'customerRemark' | 'workshopRemark' | 'freeText' | 'customerReference' | 'workPerformedAt' | 'sumRequested' | 'sumCredited' | 'createdAt' | 'updatedAt' | 'customerApprovalBy' | 'customerApprovalAt'>
      & { maintenanceCustomer?: Maybe<(
        { __typename?: 'MaintenanceCustomer' }
        & Pick<MaintenanceCustomer, 'id' | 'name' | 'hourlyMaintenanceRate' | 'createdAt' | 'updatedAt' | 'externalId'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), meterReadingImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, deliveryServiceImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, rows: Array<(
        { __typename?: 'ArticleMaintenanceRow' }
        & Pick<ArticleMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )> }
      ) | (
        { __typename?: 'ExternalMaintenanceRow' }
        & Pick<ExternalMaintenanceRow, 'externalCostType' | 'externalCostDescription' | 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
        & { receiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )> }
      ) | (
        { __typename?: 'OperationMaintenanceRow' }
        & Pick<OperationMaintenanceRow, 'customOperationDescription' | 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )> }
      ) | (
        { __typename?: 'MileageAllowanceMaintenanceRow' }
        & Pick<MileageAllowanceMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
        & { createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )> }
      ) | (
        { __typename?: 'ShippingMaintenanceRow' }
        & Pick<ShippingMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
        & { createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )> }
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )> }
  ) }
);

export type CustomPricesQueryVariables = Exact<{
  filter?: Maybe<BaseFilter>;
}>;


export type CustomPricesQuery = (
  { __typename?: 'Query' }
  & { customPrices: (
    { __typename?: 'CustomPriceCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'CustomPrice' }
      & Pick<CustomPrice, 'id' | 'price' | 'updatedAt'>
      & { article: (
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      ) | (
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      ) | (
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      ) | (
        { __typename?: 'Fee' }
        & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
      ), maintenanceCustomer: (
        { __typename?: 'MaintenanceCustomer' }
        & Pick<MaintenanceCustomer, 'id' | 'name' | 'hourlyMaintenanceRate' | 'createdAt' | 'updatedAt' | 'externalId'>
      ) }
    )> }
  ) }
);

export type CustomPriceQueryVariables = Exact<{
  articleId: Scalars['ID'];
  maintenanceCustomerId: Scalars['ID'];
}>;


export type CustomPriceQuery = (
  { __typename?: 'Query' }
  & { customPrice?: Maybe<(
    { __typename?: 'CustomPrice' }
    & Pick<CustomPrice, 'id' | 'price' | 'updatedAt'>
    & { article: (
      { __typename?: 'StockVehicle' }
      & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), equipments: Array<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, factoryOptions: Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )> }
    ) | (
      { __typename?: 'Equipment' }
      & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, equipmentGroup?: Maybe<(
        { __typename?: 'EquipmentGroup' }
        & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
      )> }
    ) | (
      { __typename?: 'SparePart' }
      & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, explodedViewRows: Array<(
        { __typename?: 'ExplodedViewRow' }
        & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ) }
    ) | (
      { __typename?: 'BillOfMaterial' }
      & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
      & { bomRows: Array<(
        { __typename?: 'BillOfMaterialRow' }
        & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, equipmentGroup?: Maybe<(
        { __typename?: 'EquipmentGroup' }
        & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
      )> }
    ) | (
      { __typename?: 'Fee' }
      & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
    ), maintenanceCustomer: (
      { __typename?: 'MaintenanceCustomer' }
      & Pick<MaintenanceCustomer, 'id' | 'name' | 'hourlyMaintenanceRate' | 'createdAt' | 'updatedAt' | 'externalId'>
    ) }
  )> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'LoginReturnType' }
    & Pick<LoginReturnType, 'token' | 'actAsRetailerRight'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  ) }
);

export type ResetPasswordActionMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordActionMutation = (
  { __typename?: 'Mutation' }
  & { resetPasswordAction: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type ResetPasswordConfirmMutationVariables = Exact<{
  email: Scalars['String'];
  newPassword: Scalars['String'];
  newPasswordConfirm: Scalars['String'];
  nonce: Scalars['String'];
}>;


export type ResetPasswordConfirmMutation = (
  { __typename?: 'Mutation' }
  & { resetPasswordConfirm: (
    { __typename?: 'LoginReturnType' }
    & Pick<LoginReturnType, 'token' | 'actAsRetailerRight'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  ) }
);

export type ResetOwnPasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
  newPasswordConfirm: Scalars['String'];
}>;


export type ResetOwnPasswordMutation = (
  { __typename?: 'Mutation' }
  & { resetOwnPassword: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type CreateRetailerAccountMutationVariables = Exact<{
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  freeText?: Maybe<Scalars['String']>;
  retailerId: Scalars['Int'];
  role: RetailerUserRole;
  roleDescription?: Maybe<Scalars['String']>;
}>;


export type CreateRetailerAccountMutation = (
  { __typename?: 'Mutation' }
  & { createRetailerAccount: (
    { __typename?: 'UserOrError' }
    & Pick<UserOrError, 'error' | 'message'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )> }
  ) }
);

export type CreateDealyAccountMutationVariables = Exact<{
  email: Scalars['String'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  freeText?: Maybe<Scalars['String']>;
  role: DealyUserRole;
  roleDescription?: Maybe<Scalars['String']>;
}>;


export type CreateDealyAccountMutation = (
  { __typename?: 'Mutation' }
  & { createDealyAccount: (
    { __typename?: 'UserOrError' }
    & Pick<UserOrError, 'error' | 'message'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )> }
  ) }
);

export type DeleteDealyUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDealyUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteDealyUser?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type DeleteRetailerUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteRetailerUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteRetailerUser?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type DeleteMyselfMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteMyselfMutation = (
  { __typename?: 'Mutation' }
  & { deleteMyself?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
  freeText?: Maybe<Scalars['String']>;
  role: UserRole;
  active: Scalars['Boolean'];
  email: Scalars['String'];
  roleDescription?: Maybe<Scalars['String']>;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser: (
    { __typename?: 'UserOrError' }
    & Pick<UserOrError, 'error' | 'message'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )> }
  ) }
);

export type UpdateAcceptsEmailRemindersMutationVariables = Exact<{
  id: Scalars['UUID'];
  acceptsEmailReminders: Scalars['Boolean'];
}>;


export type UpdateAcceptsEmailRemindersMutation = (
  { __typename?: 'Mutation' }
  & { updateAcceptsEmailReminders: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
    & { retailer?: Maybe<(
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )> }
  ) }
);

export type CreateRetailerMutationVariables = Exact<{
  customerId: Scalars['String'];
  email: Scalars['String'];
  contactPersonName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  retailerName: Scalars['String'];
  makePermissions?: Maybe<Array<UpdateMakePermissionsInput>>;
}>;


export type CreateRetailerMutation = (
  { __typename?: 'Mutation' }
  & { createRetailer: (
    { __typename?: 'RetailerOrError' }
    & Pick<RetailerOrError, 'error' | 'message'>
    & { retailer?: Maybe<(
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )> }
  ) }
);

export type UpdateRetailerMutationVariables = Exact<{
  retailerId: Scalars['Int'];
  retailerName: Scalars['String'];
  userName: Scalars['String'];
  userEmail: Scalars['String'];
  userPhoneNumber?: Maybe<Scalars['String']>;
}>;


export type UpdateRetailerMutation = (
  { __typename?: 'Mutation' }
  & { updateRetailer: (
    { __typename?: 'RetailerOrError' }
    & Pick<RetailerOrError, 'error' | 'message'>
    & { retailer?: Maybe<(
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )> }
  ) }
);

export type UpdateMaintenanceCustomerAccessMutationVariables = Exact<{
  retailerId: Scalars['Int'];
  maintenanceCustomerAccess: Scalars['Boolean'];
}>;


export type UpdateMaintenanceCustomerAccessMutation = (
  { __typename?: 'Mutation' }
  & { updateMaintenanceCustomerAccess: (
    { __typename?: 'Retailer' }
    & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
    & { makes: Array<(
      { __typename?: 'RetailerMake' }
      & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
      & { make: (
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      ) }
    )>, users: Array<(
      { __typename?: 'RetailerUser' }
      & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, contactUser: (
      { __typename?: 'RetailerUser' }
      & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  ) }
);

export type DeleteRetailerMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteRetailerMutation = (
  { __typename?: 'Mutation' }
  & { deleteRetailer: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type UpdateShippingCostMutationVariables = Exact<{
  id: Scalars['ID'];
  shippingCost: Scalars['Float'];
}>;


export type UpdateShippingCostMutation = (
  { __typename?: 'Mutation' }
  & { updateShippingCost: (
    { __typename?: 'VehicleWarranty' }
    & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedFrom?: Maybe<(
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedTo?: Maybe<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'GoodWillWarranty' }
    & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedTo?: Maybe<(
      { __typename?: 'VehicleWarranty' }
      & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, meterReadingImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedFrom?: Maybe<(
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'PartWarranty' }
    & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { purchaseReceiptImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'LegacyWarranty' }
    & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) }
);

export type VehicleWarrantyUpsertMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  status: WarrantyStatus;
  customerRemark?: Maybe<Scalars['String']>;
  workshopRemark?: Maybe<Scalars['String']>;
  approvedRowsInput?: Maybe<Array<ApprovedRowInput>>;
  articleRowsInput?: Maybe<Array<ArticleRowInput>>;
  operationRowsInput?: Maybe<Array<OperationRowInput>>;
  externRowsInput?: Maybe<Array<ExternRowInput>>;
  imageInputs?: Maybe<Array<ImageUpload>>;
  repairedAt?: Maybe<Scalars['DateTime']>;
  meterReading?: Maybe<Scalars['Float']>;
  operatingTime?: Maybe<Scalars['Float']>;
  meterReadingImage?: Maybe<ImageUpload>;
  serviceBookImage?: Maybe<ImageUpload>;
  damageImage?: Maybe<ImageUpload>;
  defectPartNumber?: Maybe<Scalars['String']>;
  defectPartTypeCode?: Maybe<Array<Scalars['String']>>;
  symptomCode?: Maybe<Scalars['String']>;
  vehicleId?: Maybe<Scalars['ID']>;
}>;


export type VehicleWarrantyUpsertMutation = (
  { __typename?: 'Mutation' }
  & { vehicleWarrantyUpsert: (
    { __typename?: 'VehicleWarranty' }
    & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
    & { retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedFrom?: Maybe<(
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedTo?: Maybe<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )> }
      )> }
    )> }
  ) }
);

export type PartWarrantyUpsertMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  status: WarrantyStatus;
  customerRemark?: Maybe<Scalars['String']>;
  workshopRemark?: Maybe<Scalars['String']>;
  articleRowsInput?: Maybe<Array<ArticleRowInput>>;
  operationRowsInput?: Maybe<Array<OperationRowInput>>;
  externRowsInput?: Maybe<Array<ExternRowInput>>;
  imageInputs?: Maybe<Array<ImageUpload>>;
  repairedAt?: Maybe<Scalars['DateTime']>;
  purchaseReceiptImage?: Maybe<ImageUpload>;
  damageImage?: Maybe<ImageUpload>;
}>;


export type PartWarrantyUpsertMutation = (
  { __typename?: 'Mutation' }
  & { partWarrantyUpsert: (
    { __typename?: 'PartWarranty' }
    & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, purchaseReceiptImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) }
);

export type GoodWillWarrantyUpsertMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  status: WarrantyStatus;
  customerRemark?: Maybe<Scalars['String']>;
  workshopRemark?: Maybe<Scalars['String']>;
  articleRowsInput?: Maybe<Array<ArticleRowInput>>;
  operationRowsInput?: Maybe<Array<OperationRowInput>>;
  externRowsInput?: Maybe<Array<ExternRowInput>>;
  imageInputs?: Maybe<Array<ImageUpload>>;
  repairedAt?: Maybe<Scalars['DateTime']>;
  type?: Maybe<GoodWillType>;
  vehicleId?: Maybe<Scalars['ID']>;
  meterReading?: Maybe<Scalars['Float']>;
  operatingTime?: Maybe<Scalars['Float']>;
  serviceBookImage?: Maybe<ImageUpload>;
  damageImage?: Maybe<ImageUpload>;
}>;


export type GoodWillWarrantyUpsertMutation = (
  { __typename?: 'Mutation' }
  & { goodWillWarrantyUpsert: (
    { __typename?: 'GoodWillWarranty' }
    & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
    & { retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedTo?: Maybe<(
      { __typename?: 'VehicleWarranty' }
      & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, meterReadingImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedFrom?: Maybe<(
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )> }
      )> }
    )> }
  ) }
);

export type LegacyWarrantyUpsertMutationVariables = Exact<{
  spreedSheet: Scalars['UploadFile'];
}>;


export type LegacyWarrantyUpsertMutation = (
  { __typename?: 'Mutation' }
  & { legacyWarrantyUpsert: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type DeleteDraftWarrantyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteDraftWarrantyMutation = (
  { __typename?: 'Mutation' }
  & { deleteDraftWarranty: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type WarrantyChangeRowStatusMutationVariables = Exact<{
  warrantyId: Scalars['ID'];
  rowsChangeStatus: Array<RowChangeStatusInput>;
}>;


export type WarrantyChangeRowStatusMutation = (
  { __typename?: 'Mutation' }
  & { warrantyChangeRowStatus: (
    { __typename?: 'VehicleWarranty' }
    & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedFrom?: Maybe<(
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedTo?: Maybe<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'GoodWillWarranty' }
    & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedTo?: Maybe<(
      { __typename?: 'VehicleWarranty' }
      & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, meterReadingImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedFrom?: Maybe<(
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'PartWarranty' }
    & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { purchaseReceiptImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'LegacyWarranty' }
    & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) }
);

export type WarrantyAddOperationRowMutationVariables = Exact<{
  warrantyId: Scalars['ID'];
  operationRowInput: OperationRowInput;
}>;


export type WarrantyAddOperationRowMutation = (
  { __typename?: 'Mutation' }
  & { warrantyAddOperationRow: (
    { __typename?: 'VehicleWarranty' }
    & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedFrom?: Maybe<(
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedTo?: Maybe<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'GoodWillWarranty' }
    & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedTo?: Maybe<(
      { __typename?: 'VehicleWarranty' }
      & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, meterReadingImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedFrom?: Maybe<(
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'PartWarranty' }
    & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { purchaseReceiptImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'LegacyWarranty' }
    & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) }
);

export type WarrantyAddArticleRowMutationVariables = Exact<{
  warrantyId: Scalars['ID'];
  articleRowInput: ArticleRowInput;
}>;


export type WarrantyAddArticleRowMutation = (
  { __typename?: 'Mutation' }
  & { warrantyAddArticleRow: (
    { __typename?: 'VehicleWarranty' }
    & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedFrom?: Maybe<(
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedTo?: Maybe<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'GoodWillWarranty' }
    & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedTo?: Maybe<(
      { __typename?: 'VehicleWarranty' }
      & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, meterReadingImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedFrom?: Maybe<(
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'PartWarranty' }
    & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { purchaseReceiptImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'LegacyWarranty' }
    & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) }
);

export type WarrantyAddExternalCostRowMutationVariables = Exact<{
  warrantyId: Scalars['ID'];
  externalRowInput: ExternRowInput;
}>;


export type WarrantyAddExternalCostRowMutation = (
  { __typename?: 'Mutation' }
  & { warrantyAddExternalCostRow: (
    { __typename?: 'VehicleWarranty' }
    & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedFrom?: Maybe<(
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedTo?: Maybe<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'GoodWillWarranty' }
    & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedTo?: Maybe<(
      { __typename?: 'VehicleWarranty' }
      & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, meterReadingImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedFrom?: Maybe<(
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'PartWarranty' }
    & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { purchaseReceiptImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'LegacyWarranty' }
    & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) }
);

export type WarrantyChangeStatusMassActionMutationVariables = Exact<{
  warrantiesIds: Array<Scalars['ID']>;
  status: WarrantyStatus;
  declineReason?: Maybe<DeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
}>;


export type WarrantyChangeStatusMassActionMutation = (
  { __typename?: 'Mutation' }
  & { warrantyChangeStatusMassAction: Array<(
    { __typename?: 'VehicleWarranty' }
    & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedFrom?: Maybe<(
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedTo?: Maybe<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'GoodWillWarranty' }
    & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedTo?: Maybe<(
      { __typename?: 'VehicleWarranty' }
      & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, meterReadingImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedFrom?: Maybe<(
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'PartWarranty' }
    & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { purchaseReceiptImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'LegacyWarranty' }
    & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  )> }
);

export type ComplementWarrantyRowMutationVariables = Exact<{
  rowId: Scalars['ID'];
  articleRowsInput?: Maybe<ArticleRowInput>;
  operationRowsInput?: Maybe<OperationRowInput>;
  externRowsInput?: Maybe<ExternRowInput>;
  sparePartSent?: Maybe<Scalars['Boolean']>;
}>;


export type ComplementWarrantyRowMutation = (
  { __typename?: 'Mutation' }
  & { complementWarrantyRow: (
    { __typename?: 'OperationRow' }
    & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
    & { operationCode?: Maybe<(
      { __typename?: 'OperationCode' }
      & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
    )>, handledBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  ) | (
    { __typename?: 'ExternRow' }
    & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
    & { receiptImage: (
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    ), handledBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  ) | (
    { __typename?: 'ArticleRow' }
    & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
    & { orderItem?: Maybe<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
      & { vehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, fee?: Maybe<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
      )>, replacedProduct?: Maybe<(
        { __typename?: 'ReplacedProduct' }
        & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
      )>, detailedQuantities?: Maybe<(
        { __typename?: 'DetailedOrderItemQuantities' }
        & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
      )>, order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        & { items: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, products: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, fees: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, referenceUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoices: Array<Maybe<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )>> }
      ), connection?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, invoices: Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        & { buyer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoiceRows: Array<(
          { __typename?: 'InvoiceRow' }
          & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
        )> }
      )> }
    )>, sparePart?: Maybe<(
      { __typename?: 'SparePart' }
      & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, explodedViewRows: Array<(
        { __typename?: 'ExplodedViewRow' }
        & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ) }
    )>, billOfMaterial?: Maybe<(
      { __typename?: 'BillOfMaterial' }
      & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
      & { bomRows: Array<(
        { __typename?: 'BillOfMaterialRow' }
        & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, equipmentGroup?: Maybe<(
        { __typename?: 'EquipmentGroup' }
        & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
      )> }
    )>, handledBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  ) | (
    { __typename?: 'LegacyRow' }
    & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
    & { handledBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  ) }
);

export type ComplementWarrantyMutationVariables = Exact<{
  warrantyId: Scalars['ID'];
  rowId: Scalars['ID'];
  complementaryImages?: Maybe<Array<WarrantyImageInput>>;
}>;


export type ComplementWarrantyMutation = (
  { __typename?: 'Mutation' }
  & { complementWarranty: (
    { __typename?: 'VehicleWarranty' }
    & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedFrom?: Maybe<(
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedTo?: Maybe<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'GoodWillWarranty' }
    & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedTo?: Maybe<(
      { __typename?: 'VehicleWarranty' }
      & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, meterReadingImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedFrom?: Maybe<(
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'PartWarranty' }
    & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { purchaseReceiptImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) | (
    { __typename?: 'LegacyWarranty' }
    & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
    & { vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )> }
  ) }
);

export type VehicleBfiMutationVariables = Exact<{
  vehicleId: Scalars['ID'];
  meterReading: Scalars['Float'];
  meterReadingImage?: Maybe<Scalars['UploadFile']>;
}>;


export type VehicleBfiMutation = (
  { __typename?: 'Mutation' }
  & { vehicleBFI?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type VehicleKeysMutationVariables = Exact<{
  vehicleId: Scalars['ID'];
  registrationProofImage: Scalars['UploadFile'];
  alternativeKeyNumber?: Maybe<Scalars['String']>;
  spareKeyQuantity?: Maybe<Scalars['Int']>;
  remoteControlQuantity?: Maybe<Scalars['Int']>;
  remoteControlFlipQuantity?: Maybe<Scalars['Int']>;
}>;


export type VehicleKeysMutation = (
  { __typename?: 'Mutation' }
  & { vehicleKeys?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type OperationalizePriorApprovalWarrantyMutationVariables = Exact<{
  warrantyId: Scalars['ID'];
}>;


export type OperationalizePriorApprovalWarrantyMutation = (
  { __typename?: 'Mutation' }
  & { operationalizePriorApprovalWarranty: (
    { __typename?: 'VehicleWarranty' }
    & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
    & { retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), rows: Array<(
      { __typename?: 'OperationRow' }
      & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ExternRow' }
      & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { receiptImage: (
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'ArticleRow' }
      & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ) | (
      { __typename?: 'LegacyRow' }
      & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, serviceBookImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, operationalizedFrom?: Maybe<(
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedTo?: Maybe<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )> }
      )> }
    )> }
  ) }
);

export type UpdateMakePermissionsMutationVariables = Exact<{
  retailerId?: Maybe<Scalars['Int']>;
  makePermissions: Array<UpdateMakePermissionsInput>;
}>;


export type UpdateMakePermissionsMutation = (
  { __typename?: 'Mutation' }
  & { updateMakePermissions?: Maybe<(
    { __typename?: 'Retailer' }
    & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
    & { makes: Array<(
      { __typename?: 'RetailerMake' }
      & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
      & { make: (
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      ) }
    )>, users: Array<(
      { __typename?: 'RetailerUser' }
      & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, contactUser: (
      { __typename?: 'RetailerUser' }
      & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  )> }
);

export type WarrantyDisplayViewUpsertMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  columnData: Array<ColumnDataInput>;
  active: Scalars['Boolean'];
}>;


export type WarrantyDisplayViewUpsertMutation = (
  { __typename?: 'Mutation' }
  & { warrantyDisplayViewUpsert: (
    { __typename?: 'WarrantyDisplayView' }
    & Pick<WarrantyDisplayView, 'id' | 'name' | 'active' | 'createdAt' | 'updatedAt'>
    & { columnData: Array<(
      { __typename?: 'ColumnData' }
      & Pick<ColumnData, 'position' | 'name' | 'active'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  ) }
);

export type DeleteWarrantyDisplayViewMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type DeleteWarrantyDisplayViewMutation = (
  { __typename?: 'Mutation' }
  & { deleteWarrantyDisplayView: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type SwitchWarrantyDisplayViewMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SwitchWarrantyDisplayViewMutation = (
  { __typename?: 'Mutation' }
  & { switchWarrantyDisplayView: (
    { __typename?: 'WarrantyDisplayView' }
    & Pick<WarrantyDisplayView, 'id' | 'name' | 'active' | 'createdAt' | 'updatedAt'>
    & { columnData: Array<(
      { __typename?: 'ColumnData' }
      & Pick<ColumnData, 'position' | 'name' | 'active'>
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  ) }
);

export type CreateExplodedViewsMutationVariables = Exact<{
  currentFolderId: Scalars['String'];
  brandFolderId: Scalars['String'];
  modelFolderId: Scalars['String'];
  mainCategoryFolderId?: Maybe<Scalars['String']>;
  subCategoryFolderId?: Maybe<Scalars['String']>;
  explodedViews: Array<ExplodedViewInput>;
}>;


export type CreateExplodedViewsMutation = (
  { __typename?: 'Mutation' }
  & { createExplodedViews: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type DeleteExplodedViewMutationVariables = Exact<{
  explodedViewId: Scalars['String'];
}>;


export type DeleteExplodedViewMutation = (
  { __typename?: 'Mutation' }
  & { deleteExplodedView?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type UpdateExplodedViewMutationVariables = Exact<{
  explodedViewId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  folderId?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['UploadFile']>>;
  rows?: Maybe<Array<ExplodedViewRowInput>>;
}>;


export type UpdateExplodedViewMutation = (
  { __typename?: 'Mutation' }
  & { updateExplodedView: (
    { __typename?: 'ExplodedView' }
    & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
    & { explodedViewImages: Array<(
      { __typename?: 'ExplodedViewImage' }
      & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, explodedViewRows: Array<(
      { __typename?: 'ExplodedViewRow' }
      & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )> }
    )>, brandFolder: (
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), modelFolder?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, mainCategoryFolder?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, subCategoryFolder?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, folder: (
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  ) }
);

export type DeleteExplodedViewRowMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteExplodedViewRowMutation = (
  { __typename?: 'Mutation' }
  & { deleteExplodedViewRow: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type CreateExcelExportSkeletonMutationVariables = Exact<{
  folderId?: Maybe<Scalars['String']>;
}>;


export type CreateExcelExportSkeletonMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createExcelExportSkeleton'>
);

export type LoadSpreedSheetMutationVariables = Exact<{
  spreedSheet: Scalars['UploadFile'];
}>;


export type LoadSpreedSheetMutation = (
  { __typename?: 'Mutation' }
  & { loadSpreedSheet: Array<(
    { __typename?: 'ExplodedView' }
    & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
    & { explodedViewImages: Array<(
      { __typename?: 'ExplodedViewImage' }
      & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, explodedViewRows: Array<(
      { __typename?: 'ExplodedViewRow' }
      & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )> }
    )>, brandFolder: (
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), modelFolder?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, mainCategoryFolder?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, subCategoryFolder?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, folder: (
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  )> }
);

export type CreateFoldersMutationVariables = Exact<{
  folders: Array<FolderCreateInput>;
}>;


export type CreateFoldersMutation = (
  { __typename?: 'Mutation' }
  & { createFolders: Array<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
    & { parent?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, children: Array<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, explodedViews: Array<(
      { __typename?: 'ExplodedView' }
      & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
      & { explodedViewImages: Array<(
        { __typename?: 'ExplodedViewImage' }
        & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, explodedViewRows: Array<(
        { __typename?: 'ExplodedViewRow' }
        & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )> }
      )>, brandFolder: (
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      ), modelFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, mainCategoryFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, subCategoryFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, folder: (
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      ) }
    )> }
  )> }
);

export type DeleteFolderMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteFolderMutation = (
  { __typename?: 'Mutation' }
  & { deleteFolder?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type UpdateFolderActiveStateMutationVariables = Exact<{
  id: Scalars['UUID'];
  active: Scalars['Boolean'];
}>;


export type UpdateFolderActiveStateMutation = (
  { __typename?: 'Mutation' }
  & { updateFolderActiveState: (
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
    & { parent?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, children: Array<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, explodedViews: Array<(
      { __typename?: 'ExplodedView' }
      & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
      & { explodedViewImages: Array<(
        { __typename?: 'ExplodedViewImage' }
        & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, explodedViewRows: Array<(
        { __typename?: 'ExplodedViewRow' }
        & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )> }
      )>, brandFolder: (
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      ), modelFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, mainCategoryFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, subCategoryFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, folder: (
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      ) }
    )> }
  ) }
);

export type ChangeFolderMutationVariables = Exact<{
  id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  newParentId?: Maybe<Scalars['String']>;
}>;


export type ChangeFolderMutation = (
  { __typename?: 'Mutation' }
  & { changeFolder?: Maybe<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
    & { parent?: Maybe<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, children: Array<(
      { __typename?: 'Folder' }
      & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
      & { parent?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, children: Array<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, explodedViews: Array<(
        { __typename?: 'ExplodedView' }
        & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        & { explodedViewImages: Array<(
          { __typename?: 'ExplodedViewImage' }
          & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, brandFolder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ), modelFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, mainCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, subCategoryFolder?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, folder: (
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, explodedViews: Array<(
      { __typename?: 'ExplodedView' }
      & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
      & { explodedViewImages: Array<(
        { __typename?: 'ExplodedViewImage' }
        & Pick<ExplodedViewImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, explodedViewRows: Array<(
        { __typename?: 'ExplodedViewRow' }
        & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )> }
      )>, brandFolder: (
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      ), modelFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, mainCategoryFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, subCategoryFolder?: Maybe<(
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      )>, folder: (
        { __typename?: 'Folder' }
        & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        & { parent?: Maybe<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, children: Array<(
          { __typename?: 'Folder' }
          & Pick<Folder, 'id' | 'title' | 'active' | 'createdAt' | 'updatedAt'>
        )>, explodedViews: Array<(
          { __typename?: 'ExplodedView' }
          & Pick<ExplodedView, 'id' | 'title' | 'createdAt' | 'updatedAt'>
        )> }
      ) }
    )> }
  )> }
);

export type PlaceOrderMutationVariables = Exact<{
  input: PlaceOrderInput;
}>;


export type PlaceOrderMutation = (
  { __typename?: 'Mutation' }
  & { placeOrder: Array<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
    & { items: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
      & { vehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, fee?: Maybe<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
      )>, replacedProduct?: Maybe<(
        { __typename?: 'ReplacedProduct' }
        & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
      )>, detailedQuantities?: Maybe<(
        { __typename?: 'DetailedOrderItemQuantities' }
        & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
      )>, order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        & { items: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, products: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, fees: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, referenceUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoices: Array<Maybe<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )>> }
      ), connection?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, invoices: Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        & { buyer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoiceRows: Array<(
          { __typename?: 'InvoiceRow' }
          & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
        )> }
      )> }
    )>, products: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
      & { vehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, fee?: Maybe<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
      )>, replacedProduct?: Maybe<(
        { __typename?: 'ReplacedProduct' }
        & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
      )>, detailedQuantities?: Maybe<(
        { __typename?: 'DetailedOrderItemQuantities' }
        & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
      )>, order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        & { items: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, products: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, fees: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, referenceUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoices: Array<Maybe<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )>> }
      ), connection?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, invoices: Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        & { buyer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoiceRows: Array<(
          { __typename?: 'InvoiceRow' }
          & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
        )> }
      )> }
    )>, fees: Array<(
      { __typename?: 'OrderItem' }
      & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
      & { vehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, fee?: Maybe<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
      )>, replacedProduct?: Maybe<(
        { __typename?: 'ReplacedProduct' }
        & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
      )>, detailedQuantities?: Maybe<(
        { __typename?: 'DetailedOrderItemQuantities' }
        & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
      )>, order: (
        { __typename?: 'Order' }
        & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        & { items: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, products: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, fees: Array<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, referenceUser?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoices: Array<Maybe<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )>> }
      ), connection?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, invoices: Array<(
        { __typename?: 'Invoice' }
        & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        & { buyer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, invoiceRows: Array<(
          { __typename?: 'InvoiceRow' }
          & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
        )> }
      )> }
    )>, referenceUser?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    )>, retailer?: Maybe<(
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, invoices: Array<Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
      & { buyer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, invoiceRows: Array<(
        { __typename?: 'InvoiceRow' }
        & Pick<InvoiceRow, 'id' | 'sageId' | 'articleNumber' | 'description' | 'quantity' | 'netPriceExclVat' | 'grossPriceExclVat'>
      )> }
    )>> }
  )> }
);

export type ExportOrderMutationVariables = Exact<{
  items: Array<ItemsInput>;
}>;


export type ExportOrderMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'exportOrder'>
);

export type UpdateCartWithPricesMutationVariables = Exact<{
  items: Array<ItemsInput>;
  onBehalfOfRetailerId?: Maybe<Scalars['Int']>;
  deliveryType?: Maybe<DeliveryType>;
}>;


export type UpdateCartWithPricesMutation = (
  { __typename?: 'Mutation' }
  & { updateCartWithPrices: (
    { __typename?: 'Cart' }
    & Pick<Cart, 'totalQuantity' | 'totalExclVAT' | 'totalInclVAT'>
    & { items: Array<(
      { __typename?: 'CartItem' }
      & Pick<CartItem, 'quantity' | 'campaignOnVehicleId' | 'connectionType'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, fee?: Maybe<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
      )>, price: (
        { __typename?: 'CartPrice' }
        & Pick<CartPrice, 'price' | 'retailerPrice' | 'discountedPrice'>
      ), replacedProduct?: Maybe<(
        { __typename?: 'ReplacedProduct' }
        & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
      )>, volumeDiscount?: Maybe<(
        { __typename?: 'VolumeDiscount' }
        & Pick<VolumeDiscount, 'id' | 'name' | 'volume' | 'startTime' | 'endTime' | 'discountOne' | 'discountTwo' | 'discountThree' | 'level'>
        & { mainProductIdentifier: (
          { __typename?: 'ProductIdentifierEntities' }
          & Pick<ProductIdentifierEntities, 'vehicleType'>
        ) }
      )>, productRule?: Maybe<(
        { __typename?: 'ProductRule' }
        & Pick<ProductRule, 'id' | 'name' | 'showInCart' | 'startTime' | 'endTime' | 'discountTwo' | 'discountThree' | 'discountCode'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, productReferences: Array<(
          { __typename?: 'ProductReference' }
          & Pick<ProductReference, 'id' | 'vehicleType'>
        )> }
      )>, productRuleItems?: Maybe<Array<(
        { __typename?: 'CartItem' }
        & Pick<CartItem, 'quantity' | 'campaignOnVehicleId' | 'connectionType'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, price: (
          { __typename?: 'CartPrice' }
          & Pick<CartPrice, 'price' | 'retailerPrice' | 'discountedPrice'>
        ), replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, volumeDiscount?: Maybe<(
          { __typename?: 'VolumeDiscount' }
          & Pick<VolumeDiscount, 'id' | 'name' | 'volume' | 'startTime' | 'endTime' | 'discountOne' | 'discountTwo' | 'discountThree' | 'level'>
        )>, productRule?: Maybe<(
          { __typename?: 'ProductRule' }
          & Pick<ProductRule, 'id' | 'name' | 'showInCart' | 'startTime' | 'endTime' | 'discountTwo' | 'discountThree' | 'discountCode'>
        )>, productRuleItems?: Maybe<Array<(
          { __typename?: 'CartItem' }
          & Pick<CartItem, 'quantity' | 'campaignOnVehicleId' | 'connectionType'>
        )>> }
      )>> }
    )>, products: Array<(
      { __typename?: 'CartItem' }
      & Pick<CartItem, 'quantity' | 'campaignOnVehicleId' | 'connectionType'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, fee?: Maybe<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
      )>, price: (
        { __typename?: 'CartPrice' }
        & Pick<CartPrice, 'price' | 'retailerPrice' | 'discountedPrice'>
      ), replacedProduct?: Maybe<(
        { __typename?: 'ReplacedProduct' }
        & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
      )>, volumeDiscount?: Maybe<(
        { __typename?: 'VolumeDiscount' }
        & Pick<VolumeDiscount, 'id' | 'name' | 'volume' | 'startTime' | 'endTime' | 'discountOne' | 'discountTwo' | 'discountThree' | 'level'>
        & { mainProductIdentifier: (
          { __typename?: 'ProductIdentifierEntities' }
          & Pick<ProductIdentifierEntities, 'vehicleType'>
        ) }
      )>, productRule?: Maybe<(
        { __typename?: 'ProductRule' }
        & Pick<ProductRule, 'id' | 'name' | 'showInCart' | 'startTime' | 'endTime' | 'discountTwo' | 'discountThree' | 'discountCode'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, productReferences: Array<(
          { __typename?: 'ProductReference' }
          & Pick<ProductReference, 'id' | 'vehicleType'>
        )> }
      )>, productRuleItems?: Maybe<Array<(
        { __typename?: 'CartItem' }
        & Pick<CartItem, 'quantity' | 'campaignOnVehicleId' | 'connectionType'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, price: (
          { __typename?: 'CartPrice' }
          & Pick<CartPrice, 'price' | 'retailerPrice' | 'discountedPrice'>
        ), replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, volumeDiscount?: Maybe<(
          { __typename?: 'VolumeDiscount' }
          & Pick<VolumeDiscount, 'id' | 'name' | 'volume' | 'startTime' | 'endTime' | 'discountOne' | 'discountTwo' | 'discountThree' | 'level'>
        )>, productRule?: Maybe<(
          { __typename?: 'ProductRule' }
          & Pick<ProductRule, 'id' | 'name' | 'showInCart' | 'startTime' | 'endTime' | 'discountTwo' | 'discountThree' | 'discountCode'>
        )>, productRuleItems?: Maybe<Array<(
          { __typename?: 'CartItem' }
          & Pick<CartItem, 'quantity' | 'campaignOnVehicleId' | 'connectionType'>
        )>> }
      )>> }
    )>, fees: Array<(
      { __typename?: 'CartItem' }
      & Pick<CartItem, 'quantity' | 'campaignOnVehicleId' | 'connectionType'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, fee?: Maybe<(
        { __typename?: 'Fee' }
        & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
      )>, price: (
        { __typename?: 'CartPrice' }
        & Pick<CartPrice, 'price' | 'retailerPrice' | 'discountedPrice'>
      ), replacedProduct?: Maybe<(
        { __typename?: 'ReplacedProduct' }
        & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
      )>, volumeDiscount?: Maybe<(
        { __typename?: 'VolumeDiscount' }
        & Pick<VolumeDiscount, 'id' | 'name' | 'volume' | 'startTime' | 'endTime' | 'discountOne' | 'discountTwo' | 'discountThree' | 'level'>
        & { mainProductIdentifier: (
          { __typename?: 'ProductIdentifierEntities' }
          & Pick<ProductIdentifierEntities, 'vehicleType'>
        ) }
      )>, productRule?: Maybe<(
        { __typename?: 'ProductRule' }
        & Pick<ProductRule, 'id' | 'name' | 'showInCart' | 'startTime' | 'endTime' | 'discountTwo' | 'discountThree' | 'discountCode'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, productReferences: Array<(
          { __typename?: 'ProductReference' }
          & Pick<ProductReference, 'id' | 'vehicleType'>
        )> }
      )>, productRuleItems?: Maybe<Array<(
        { __typename?: 'CartItem' }
        & Pick<CartItem, 'quantity' | 'campaignOnVehicleId' | 'connectionType'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, price: (
          { __typename?: 'CartPrice' }
          & Pick<CartPrice, 'price' | 'retailerPrice' | 'discountedPrice'>
        ), replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, volumeDiscount?: Maybe<(
          { __typename?: 'VolumeDiscount' }
          & Pick<VolumeDiscount, 'id' | 'name' | 'volume' | 'startTime' | 'endTime' | 'discountOne' | 'discountTwo' | 'discountThree' | 'level'>
        )>, productRule?: Maybe<(
          { __typename?: 'ProductRule' }
          & Pick<ProductRule, 'id' | 'name' | 'showInCart' | 'startTime' | 'endTime' | 'discountTwo' | 'discountThree' | 'discountCode'>
        )>, productRuleItems?: Maybe<Array<(
          { __typename?: 'CartItem' }
          & Pick<CartItem, 'quantity' | 'campaignOnVehicleId' | 'connectionType'>
        )>> }
      )>> }
    )> }
  ) }
);

export type CreateShoppingListMutationVariables = Exact<{
  name: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  onBehalfOfRetailerId?: Maybe<Scalars['Int']>;
  items: Array<ShoppingListItemInput>;
}>;


export type CreateShoppingListMutation = (
  { __typename?: 'Mutation' }
  & { createShoppingList: (
    { __typename?: 'ShoppingList' }
    & Pick<ShoppingList, 'id' | 'name' | 'remark' | 'createdAt' | 'updatedAt'>
    & { items: Array<(
      { __typename?: 'ShoppingListItem' }
      & Pick<ShoppingListItem, 'id' | 'amount' | 'isCampaignEquipment' | 'stockVehicleProductCount'>
      & { vehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, relatedVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  ) }
);

export type UpdateShoppingListMutationVariables = Exact<{
  id: Scalars['String'];
  name: Scalars['String'];
  remark?: Maybe<Scalars['String']>;
  onBehalfOfRetailerId?: Maybe<Scalars['Int']>;
  items: Array<ShoppingListItemInput>;
}>;


export type UpdateShoppingListMutation = (
  { __typename?: 'Mutation' }
  & { updateShoppingList: (
    { __typename?: 'ShoppingList' }
    & Pick<ShoppingList, 'id' | 'name' | 'remark' | 'createdAt' | 'updatedAt'>
    & { items: Array<(
      { __typename?: 'ShoppingListItem' }
      & Pick<ShoppingListItem, 'id' | 'amount' | 'isCampaignEquipment' | 'stockVehicleProductCount'>
      & { vehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, relatedVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  ) }
);

export type DeleteShoppingListMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteShoppingListMutation = (
  { __typename?: 'Mutation' }
  & { deleteShoppingList: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type CreateShoppingListExcelTemplateMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateShoppingListExcelTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createShoppingListExcelTemplate'>
);

export type UploadShoppingListExcelMutationVariables = Exact<{
  spreadSheet: Scalars['UploadFile'];
  onBehalfOfRetailerId?: Maybe<Scalars['Int']>;
}>;


export type UploadShoppingListExcelMutation = (
  { __typename?: 'Mutation' }
  & { uploadShoppingListExcel?: Maybe<(
    { __typename?: 'ShoppingList' }
    & Pick<ShoppingList, 'id' | 'name' | 'remark' | 'createdAt' | 'updatedAt'>
    & { items: Array<(
      { __typename?: 'ShoppingListItem' }
      & Pick<ShoppingListItem, 'id' | 'amount' | 'isCampaignEquipment' | 'stockVehicleProductCount'>
      & { vehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, relatedVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  )> }
);

export type PlaceVehicleConsignmentOrderMutationVariables = Exact<{
  vehicleId: Scalars['String'];
  message?: Maybe<Scalars['String']>;
}>;


export type PlaceVehicleConsignmentOrderMutation = (
  { __typename?: 'Mutation' }
  & { placeVehicleConsignmentOrder: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
    & { stockVehicle?: Maybe<(
      { __typename?: 'StockVehicle' }
      & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), equipments: Array<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, factoryOptions: Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )> }
    )>, brand?: Maybe<(
      { __typename?: 'Make' }
      & Pick<Make, 'id' | 'externalId' | 'name'>
    )>, modelSeries?: Maybe<(
      { __typename?: 'ModelSeries' }
      & Pick<ModelSeries, 'id' | 'name'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, models: Array<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )> }
    )>, model?: Maybe<(
      { __typename?: 'Model' }
      & Pick<Model, 'id' | 'name' | 'description'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )> }
    )>, retailer?: Maybe<(
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, nonColorFactoryOptions?: Maybe<Array<(
      { __typename?: 'FactoryOption' }
      & Pick<FactoryOption, 'id' | 'description'>
      & { factoryOptionType?: Maybe<(
        { __typename?: 'FactoryOptionType' }
        & Pick<FactoryOptionType, 'id' | 'description'>
      )> }
    )>>, warranties: Array<(
      { __typename?: 'VehicleWarranty' }
      & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, meterReadingImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedFrom?: Maybe<(
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedTo?: Maybe<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'PartWarranty' }
      & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { purchaseReceiptImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'LegacyWarranty' }
      & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    )> }
  ) }
);

export type PlaceVehicleExhibitionOrderMutationVariables = Exact<{
  vehicleId: Scalars['String'];
  message?: Maybe<Scalars['String']>;
}>;


export type PlaceVehicleExhibitionOrderMutation = (
  { __typename?: 'Mutation' }
  & { placeVehicleExhibitionOrder: (
    { __typename?: 'Vehicle' }
    & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
    & { stockVehicle?: Maybe<(
      { __typename?: 'StockVehicle' }
      & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), equipments: Array<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, factoryOptions: Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )> }
    )>, brand?: Maybe<(
      { __typename?: 'Make' }
      & Pick<Make, 'id' | 'externalId' | 'name'>
    )>, modelSeries?: Maybe<(
      { __typename?: 'ModelSeries' }
      & Pick<ModelSeries, 'id' | 'name'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, models: Array<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )> }
    )>, model?: Maybe<(
      { __typename?: 'Model' }
      & Pick<Model, 'id' | 'name' | 'description'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )> }
    )>, retailer?: Maybe<(
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, nonColorFactoryOptions?: Maybe<Array<(
      { __typename?: 'FactoryOption' }
      & Pick<FactoryOption, 'id' | 'description'>
      & { factoryOptionType?: Maybe<(
        { __typename?: 'FactoryOptionType' }
        & Pick<FactoryOptionType, 'id' | 'description'>
      )> }
    )>>, warranties: Array<(
      { __typename?: 'VehicleWarranty' }
      & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, meterReadingImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedFrom?: Maybe<(
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, serviceBookImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, operationalizedTo?: Maybe<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        & { retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'PartWarranty' }
      & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { purchaseReceiptImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    ) | (
      { __typename?: 'LegacyWarranty' }
      & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )>, nonColorFactoryOptions?: Maybe<Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )>>, warranties: Array<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'PartWarranty' }
          & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        ) | (
          { __typename?: 'LegacyWarranty' }
          & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        )> }
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      ), rows: Array<(
        { __typename?: 'OperationRow' }
        & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { operationCode?: Maybe<(
          { __typename?: 'OperationCode' }
          & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ExternRow' }
        & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { receiptImage: (
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        ), handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'ArticleRow' }
        & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { orderItem?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      ) | (
        { __typename?: 'LegacyRow' }
        & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        & { handledBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, attachements: Array<(
        { __typename?: 'WorkshopAttachement' }
        & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), damageImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )> }
    )> }
  ) }
);

export type CreateOperationGroupExcelMutationVariables = Exact<{
  operationGroupId?: Maybe<Scalars['String']>;
  makeId?: Maybe<Scalars['String']>;
  modelSeriesId?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['String']>;
}>;


export type CreateOperationGroupExcelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createOperationGroupExcel'>
);

export type UploadOperationGroupExcelMutationVariables = Exact<{
  spreadSheet: Scalars['UploadFile'];
}>;


export type UploadOperationGroupExcelMutation = (
  { __typename?: 'Mutation' }
  & { uploadOperationGroupExcel?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type DeleteOperationGroupMutationVariables = Exact<{
  operationGroupId: Scalars['String'];
}>;


export type DeleteOperationGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteOperationGroup?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type UpsertProductRuleMutationVariables = Exact<{
  input: ProductRuleInput;
}>;


export type UpsertProductRuleMutation = (
  { __typename?: 'Mutation' }
  & { upsertProductRule: (
    { __typename?: 'ProductRule' }
    & Pick<ProductRule, 'id' | 'name' | 'showInCart' | 'startTime' | 'endTime' | 'discountTwo' | 'discountThree' | 'discountCode'>
    & { stockVehicle?: Maybe<(
      { __typename?: 'StockVehicle' }
      & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), equipments: Array<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, factoryOptions: Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )> }
    )>, equipment?: Maybe<(
      { __typename?: 'Equipment' }
      & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, equipmentGroup?: Maybe<(
        { __typename?: 'EquipmentGroup' }
        & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
      )> }
    )>, sparePart?: Maybe<(
      { __typename?: 'SparePart' }
      & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, explodedViewRows: Array<(
        { __typename?: 'ExplodedViewRow' }
        & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ) }
    )>, billOfMaterial?: Maybe<(
      { __typename?: 'BillOfMaterial' }
      & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
      & { bomRows: Array<(
        { __typename?: 'BillOfMaterialRow' }
        & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, equipmentGroup?: Maybe<(
        { __typename?: 'EquipmentGroup' }
        & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
      )> }
    )>, productReferences: Array<(
      { __typename?: 'ProductReference' }
      & Pick<ProductReference, 'id' | 'vehicleType'>
      & { make?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )> }
    )> }
  ) }
);

export type DeleteProductRuleMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteProductRuleMutation = (
  { __typename?: 'Mutation' }
  & { deleteProductRule?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type CreateProductReferenceMutationVariables = Exact<{
  input: ProductReferenceInput;
}>;


export type CreateProductReferenceMutation = (
  { __typename?: 'Mutation' }
  & { createProductReference: (
    { __typename?: 'ProductRule' }
    & Pick<ProductRule, 'id' | 'name' | 'showInCart' | 'startTime' | 'endTime' | 'discountTwo' | 'discountThree' | 'discountCode'>
    & { stockVehicle?: Maybe<(
      { __typename?: 'StockVehicle' }
      & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
      & { brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), equipments: Array<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, factoryOptions: Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )> }
    )>, equipment?: Maybe<(
      { __typename?: 'Equipment' }
      & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, equipmentGroup?: Maybe<(
        { __typename?: 'EquipmentGroup' }
        & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
      )> }
    )>, sparePart?: Maybe<(
      { __typename?: 'SparePart' }
      & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
      & { modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, explodedViewRows: Array<(
        { __typename?: 'ExplodedViewRow' }
        & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ) }
    )>, billOfMaterial?: Maybe<(
      { __typename?: 'BillOfMaterial' }
      & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
      & { bomRows: Array<(
        { __typename?: 'BillOfMaterialRow' }
        & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        & { stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )> }
      )>, stock: (
        { __typename?: 'Stock' }
        & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        & { incomingDeliveries: Array<(
          { __typename?: 'IncomingDelivery' }
          & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
        )> }
      ), images: Array<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, thumbnail?: Maybe<(
        { __typename?: 'StockImage' }
        & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        & { equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, stockVehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, equipmentGroup?: Maybe<(
        { __typename?: 'EquipmentGroup' }
        & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
      )> }
    )>, productReferences: Array<(
      { __typename?: 'ProductReference' }
      & Pick<ProductReference, 'id' | 'vehicleType'>
      & { make?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )> }
    )> }
  ) }
);

export type DeleteProductReferenceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteProductReferenceMutation = (
  { __typename?: 'Mutation' }
  & { deleteProductReference: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type UpsertVolumeDiscountMutationVariables = Exact<{
  input: VolumeDiscountInput;
}>;


export type UpsertVolumeDiscountMutation = (
  { __typename?: 'Mutation' }
  & { upsertVolumeDiscount: (
    { __typename?: 'VolumeDiscount' }
    & Pick<VolumeDiscount, 'id' | 'name' | 'volume' | 'startTime' | 'endTime' | 'discountOne' | 'discountTwo' | 'discountThree' | 'level'>
    & { mainProductIdentifier: (
      { __typename?: 'ProductIdentifierEntities' }
      & Pick<ProductIdentifierEntities, 'vehicleType'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, make?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )> }
    ) }
  ) }
);

export type DeleteVolumeDiscountMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteVolumeDiscountMutation = (
  { __typename?: 'Mutation' }
  & { deleteVolumeDiscount: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type UpsertMaintenanceTaskMutationVariables = Exact<{
  input: UpsertMaintenanceTaskInput;
}>;


export type UpsertMaintenanceTaskMutation = (
  { __typename?: 'Mutation' }
  & { upsertMaintenanceTask: (
    { __typename?: 'MaintenanceTask' }
    & Pick<MaintenanceTask, 'id' | 'serviceId' | 'status' | 'meterReading' | 'customerRemark' | 'workshopRemark' | 'freeText' | 'customerReference' | 'workPerformedAt' | 'sumRequested' | 'sumCredited' | 'createdAt' | 'updatedAt' | 'customerApprovalBy' | 'customerApprovalAt'>
    & { maintenanceCustomer?: Maybe<(
      { __typename?: 'MaintenanceCustomer' }
      & Pick<MaintenanceCustomer, 'id' | 'name' | 'hourlyMaintenanceRate' | 'createdAt' | 'updatedAt' | 'externalId'>
    )>, vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, deliveryServiceImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, rows: Array<(
      { __typename?: 'ArticleMaintenanceRow' }
      & Pick<ArticleMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'ExternalMaintenanceRow' }
      & Pick<ExternalMaintenanceRow, 'externalCostType' | 'externalCostDescription' | 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { receiptImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'OperationMaintenanceRow' }
      & Pick<OperationMaintenanceRow, 'customOperationDescription' | 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'MileageAllowanceMaintenanceRow' }
      & Pick<MileageAllowanceMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'ShippingMaintenanceRow' }
      & Pick<ShippingMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )> }
  ) }
);

export type DeleteMaintenanceTaskMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteMaintenanceTaskMutation = (
  { __typename?: 'Mutation' }
  & { deleteMaintenanceTask: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type UpdateMaintenanceTaskStatusMutationVariables = Exact<{
  maintenanceTaskIds: Array<Scalars['ID']>;
  status: MaintenanceTaskStatus;
  declineReason?: Maybe<MaintenanceRowDeclineReason>;
  declineComment?: Maybe<Scalars['String']>;
}>;


export type UpdateMaintenanceTaskStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateMaintenanceTaskStatus: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type AddMaintenanceRowMutationVariables = Exact<{
  maintenanceTaskId: Scalars['ID'];
  rowInput: MaintenanceRowInput;
}>;


export type AddMaintenanceRowMutation = (
  { __typename?: 'Mutation' }
  & { addMaintenanceRow: (
    { __typename?: 'MaintenanceTask' }
    & Pick<MaintenanceTask, 'id' | 'serviceId' | 'status' | 'meterReading' | 'customerRemark' | 'workshopRemark' | 'freeText' | 'customerReference' | 'workPerformedAt' | 'sumRequested' | 'sumCredited' | 'createdAt' | 'updatedAt' | 'customerApprovalBy' | 'customerApprovalAt'>
    & { maintenanceCustomer?: Maybe<(
      { __typename?: 'MaintenanceCustomer' }
      & Pick<MaintenanceCustomer, 'id' | 'name' | 'hourlyMaintenanceRate' | 'createdAt' | 'updatedAt' | 'externalId'>
    )>, vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, deliveryServiceImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, rows: Array<(
      { __typename?: 'ArticleMaintenanceRow' }
      & Pick<ArticleMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'ExternalMaintenanceRow' }
      & Pick<ExternalMaintenanceRow, 'externalCostType' | 'externalCostDescription' | 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { receiptImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'OperationMaintenanceRow' }
      & Pick<OperationMaintenanceRow, 'customOperationDescription' | 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'MileageAllowanceMaintenanceRow' }
      & Pick<MileageAllowanceMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'ShippingMaintenanceRow' }
      & Pick<ShippingMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )> }
  ) }
);

export type HandleMaintenanceTaskRowsMutationVariables = Exact<{
  maintenanceTaskId: Scalars['ID'];
  rowsInput: Array<UpdateMaintenanceRowsInput>;
}>;


export type HandleMaintenanceTaskRowsMutation = (
  { __typename?: 'Mutation' }
  & { handleMaintenanceTaskRows: (
    { __typename?: 'MaintenanceTask' }
    & Pick<MaintenanceTask, 'id' | 'serviceId' | 'status' | 'meterReading' | 'customerRemark' | 'workshopRemark' | 'freeText' | 'customerReference' | 'workPerformedAt' | 'sumRequested' | 'sumCredited' | 'createdAt' | 'updatedAt' | 'customerApprovalBy' | 'customerApprovalAt'>
    & { maintenanceCustomer?: Maybe<(
      { __typename?: 'MaintenanceCustomer' }
      & Pick<MaintenanceCustomer, 'id' | 'name' | 'hourlyMaintenanceRate' | 'createdAt' | 'updatedAt' | 'externalId'>
    )>, vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, deliveryServiceImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, rows: Array<(
      { __typename?: 'ArticleMaintenanceRow' }
      & Pick<ArticleMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'ExternalMaintenanceRow' }
      & Pick<ExternalMaintenanceRow, 'externalCostType' | 'externalCostDescription' | 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { receiptImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'OperationMaintenanceRow' }
      & Pick<OperationMaintenanceRow, 'customOperationDescription' | 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'MileageAllowanceMaintenanceRow' }
      & Pick<MileageAllowanceMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'ShippingMaintenanceRow' }
      & Pick<ShippingMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )> }
  ) }
);

export type ComplementMaintenanceRowMutationVariables = Exact<{
  rowId: Scalars['UUID'];
  rowInput?: Maybe<MaintenanceRowInput>;
  image?: Maybe<ImageUpload>;
}>;


export type ComplementMaintenanceRowMutation = (
  { __typename?: 'Mutation' }
  & { complementMaintenanceRow: (
    { __typename?: 'MaintenanceTask' }
    & Pick<MaintenanceTask, 'id' | 'serviceId' | 'status' | 'meterReading' | 'customerRemark' | 'workshopRemark' | 'freeText' | 'customerReference' | 'workPerformedAt' | 'sumRequested' | 'sumCredited' | 'createdAt' | 'updatedAt' | 'customerApprovalBy' | 'customerApprovalAt'>
    & { maintenanceCustomer?: Maybe<(
      { __typename?: 'MaintenanceCustomer' }
      & Pick<MaintenanceCustomer, 'id' | 'name' | 'hourlyMaintenanceRate' | 'createdAt' | 'updatedAt' | 'externalId'>
    )>, vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), equipments: Array<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, factoryOptions: Array<(
          { __typename?: 'FactoryOption' }
          & Pick<FactoryOption, 'id' | 'description'>
        )> }
      )>, brand?: Maybe<(
        { __typename?: 'Make' }
        & Pick<Make, 'id' | 'externalId' | 'name'>
      )>, modelSeries?: Maybe<(
        { __typename?: 'ModelSeries' }
        & Pick<ModelSeries, 'id' | 'name'>
        & { brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, models: Array<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )> }
      )>, model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'id' | 'name' | 'description'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )> }
      )>, retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )>, nonColorFactoryOptions?: Maybe<Array<(
        { __typename?: 'FactoryOption' }
        & Pick<FactoryOption, 'id' | 'description'>
        & { factoryOptionType?: Maybe<(
          { __typename?: 'FactoryOptionType' }
          & Pick<FactoryOptionType, 'id' | 'description'>
        )> }
      )>>, warranties: Array<(
        { __typename?: 'VehicleWarranty' }
        & Pick<VehicleWarranty, 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, meterReadingImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedFrom?: Maybe<(
          { __typename?: 'GoodWillWarranty' }
          & Pick<GoodWillWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'GoodWillWarranty' }
        & Pick<GoodWillWarranty, 'meterReading' | 'operatingTime' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, serviceBookImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, operationalizedTo?: Maybe<(
          { __typename?: 'VehicleWarranty' }
          & Pick<VehicleWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost' | 'meterReading' | 'operatingTime' | 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'PartWarranty' }
        & Pick<PartWarranty, 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { purchaseReceiptImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      ) | (
        { __typename?: 'LegacyWarranty' }
        & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'causeOfDamage' | 'warrantyStartedAt' | 'receivedAt' | 'id' | 'status' | 'declineReason' | 'declineComment' | 'customerRemark' | 'workshopRemark' | 'registeredAt' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
        & { vehicle?: Maybe<(
          { __typename?: 'Vehicle' }
          & Pick<Vehicle, 'id' | 'vehicleIdentificationNumber' | 'registrationNumber' | 'startedAt' | 'showDetailedInformation' | 'typeOfInstallation' | 'createdAt' | 'updatedAt' | 'engineNumber' | 'factoryCode' | 'status' | 'firstArrivedToSweden' | 'producedAt' | 'firstRegisteredAt' | 'replacementText' | 'showBfiAndKey'>
        )>, retailer: (
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        ), rows: Array<(
          { __typename?: 'OperationRow' }
          & Pick<OperationRow, 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ExternRow' }
          & Pick<ExternRow, 'purchaseRefersType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'ArticleRow' }
          & Pick<ArticleRow, 'legacyArticleNumber' | 'legacyOrderNumber' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        ) | (
          { __typename?: 'LegacyRow' }
          & Pick<LegacyRow, 'code' | 'originalComp' | 'comp' | 'originalPrice' | 'legacyType' | 'id' | 'quantity' | 'originalQuantity' | 'price' | 'percentage' | 'originalPercentage' | 'total' | 'originalTotal' | 'referenceName' | 'description' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'type' | 'originalHours' | 'hours'>
        )>, attachements: Array<(
          { __typename?: 'WorkshopAttachement' }
          & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
        )>, internalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, externalMessages: Array<(
          { __typename?: 'WorkshopMessage' }
          & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        ), damageImage?: Maybe<(
          { __typename?: 'WorkshopImage' }
          & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
        )> }
      )> }
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
      & { makes: Array<(
        { __typename?: 'RetailerMake' }
        & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        & { make: (
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        ) }
      )>, users: Array<(
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )>, contactUser: (
        { __typename?: 'RetailerUser' }
        & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    ), createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ), meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, deliveryServiceImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, rows: Array<(
      { __typename?: 'ArticleMaintenanceRow' }
      & Pick<ArticleMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        & { vehicle?: Maybe<(
          { __typename?: 'StockVehicle' }
          & Pick<StockVehicle, 'id' | 'sageId' | 'name' | 'vehicleType' | 'color' | 'colorCode' | 'createdAt' | 'price' | 'vat' | 'discountedPrice' | 'articleNumber' | 'vehicleCampaign' | 'active'>
        )>, equipment?: Maybe<(
          { __typename?: 'Equipment' }
          & Pick<Equipment, 'id' | 'name' | 'articleNumber' | 'vehicleType' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'vehicleCampaign' | 'remark' | 'active'>
        )>, sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        )>, fee?: Maybe<(
          { __typename?: 'Fee' }
          & Pick<Fee, 'id' | 'name' | 'articleNumber' | 'price' | 'vat' | 'discountedPrice' | 'createdAt' | 'updatedAt' | 'isAllowedOnVehicles' | 'active'>
        )>, replacedProduct?: Maybe<(
          { __typename?: 'ReplacedProduct' }
          & Pick<ReplacedProduct, 'id' | 'name' | 'articleNumber'>
        )>, detailedQuantities?: Maybe<(
          { __typename?: 'DetailedOrderItemQuantities' }
          & Pick<DetailedOrderItemQuantities, 'processing' | 'allocated' | 'blocked' | 'delivered' | 'invoiced'>
        )>, order: (
          { __typename?: 'Order' }
          & Pick<Order, 'id' | 'serialNumber' | 'externalId' | 'status' | 'invoice' | 'createdAt' | 'invoicedAt' | 'total' | 'vat' | 'totalQuantity' | 'deliveryType' | 'address' | 'postalCode' | 'postalCity' | 'partialDelivery' | 'orderReferenceMessage' | 'externallyFinanced'>
        ), connection?: Maybe<(
          { __typename?: 'OrderItem' }
          & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity' | 'remark' | 'status' | 'finalPrice' | 'unitPrice' | 'stockArrivalDate' | 'connectionType'>
        )>, invoices: Array<(
          { __typename?: 'Invoice' }
          & Pick<Invoice, 'id' | 'invoiceDate' | 'deliveryAddress' | 'dueDate' | 'priceExclVat' | 'priceInclVat' | 'status' | 'invoiceType' | 'paymentCondition' | 'orderReferences' | 'orderReferenceMessage'>
        )> }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'articleNumber' | 'active' | 'bomCode' | 'description' | 'fromDate' | 'toDate' | 'price' | 'discountedPrice' | 'vehicleCampaign'>
        & { bomRows: Array<(
          { __typename?: 'BillOfMaterialRow' }
          & Pick<BillOfMaterialRow, 'id' | 'linkQuantity' | 'fromDate' | 'toDate'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ), images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, brand?: Maybe<(
          { __typename?: 'Make' }
          & Pick<Make, 'id' | 'externalId' | 'name'>
        )>, equipmentGroup?: Maybe<(
          { __typename?: 'EquipmentGroup' }
          & Pick<EquipmentGroup, 'id' | 'name' | 'externalId'>
        )> }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'articleNumber' | 'name' | 'remark' | 'price' | 'discountedPrice' | 'vat' | 'active' | 'vehicleCampaign'>
        & { modelSeries?: Maybe<(
          { __typename?: 'ModelSeries' }
          & Pick<ModelSeries, 'id' | 'name'>
        )>, thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, images: Array<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path' | 'createdAt'>
        )>, model?: Maybe<(
          { __typename?: 'Model' }
          & Pick<Model, 'id' | 'name' | 'description'>
        )>, explodedViewRows: Array<(
          { __typename?: 'ExplodedViewRow' }
          & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status' | 'updatedAt'>
        ) }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'ExternalMaintenanceRow' }
      & Pick<ExternalMaintenanceRow, 'externalCostType' | 'externalCostDescription' | 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { receiptImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'OperationMaintenanceRow' }
      & Pick<OperationMaintenanceRow, 'customOperationDescription' | 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'category' | 'description' | 'time'>
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'MileageAllowanceMaintenanceRow' }
      & Pick<MileageAllowanceMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    ) | (
      { __typename?: 'ShippingMaintenanceRow' }
      & Pick<ShippingMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ), handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      )> }
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        )> }
      ) }
    )> }
  ) }
);

export type CreateMaintenanceRowExcelMutationVariables = Exact<{
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
  workCategories: Array<MaintenanceWorkCategory>;
  maintenanceCustomerId: Scalars['UUID'];
}>;


export type CreateMaintenanceRowExcelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createMaintenanceRowExcel'>
);

export type CreateCustomPriceExcelMutationVariables = Exact<{
  maintenanceCustomerId: Scalars['UUID'];
}>;


export type CreateCustomPriceExcelMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createCustomPriceExcel'>
);

export type UploadCustomPriceExcelMutationVariables = Exact<{
  spreadSheet: Scalars['UploadFile'];
}>;


export type UploadCustomPriceExcelMutation = (
  { __typename?: 'Mutation' }
  & { uploadCustomPriceExcel?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type SendWorkshopMessageMutationVariables = Exact<{
  maintenanceTaskId?: Maybe<Scalars['ID']>;
  warrantyId?: Maybe<Scalars['ID']>;
  text: Scalars['String'];
  type: WorkshopMessageType;
}>;


export type SendWorkshopMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendWorkshopMessage: (
    { __typename?: 'WorkshopMessage' }
    & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
    & { author: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  ) }
);

export type SetWorkshopMessageReadStateMutationVariables = Exact<{
  id: Scalars['UUID'];
  isRead: Scalars['Boolean'];
}>;


export type SetWorkshopMessageReadStateMutation = (
  { __typename?: 'Mutation' }
  & { setWorkshopMessageReadState: (
    { __typename?: 'WorkshopMessage' }
    & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'isRead' | 'createdAt'>
    & { author: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'acceptsEmailReminders' | 'createdAt' | 'updatedAt'>
      & { retailer?: Maybe<(
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'id' | 'externalId' | 'name' | 'address' | 'postalCode' | 'city' | 'country' | 'telephoneNumber' | 'email' | 'hasConsignmentVehicles' | 'hasExhibitionVehicles' | 'hasMaintenanceCustomers' | 'hasExternalFinancing'>
        & { makes: Array<(
          { __typename?: 'RetailerMake' }
          & Pick<RetailerMake, 'id' | 'accessToSell' | 'accessToWorkshop' | 'warrantyHourlyPayment'>
        )>, users: Array<(
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        )>, contactUser: (
          { __typename?: 'RetailerUser' }
          & Pick<RetailerUser, 'id' | 'email' | 'name' | 'phoneNumber' | 'freeText' | 'role' | 'roleDescription' | 'active' | 'createdAt' | 'updatedAt'>
        ) }
      )> }
    ) }
  ) }
);

export type UploadWorkshopAttachementMutationVariables = Exact<{
  fileInput: FileUpload;
  maintenanceTaskId?: Maybe<Scalars['ID']>;
  warrantyId?: Maybe<Scalars['ID']>;
}>;


export type UploadWorkshopAttachementMutation = (
  { __typename?: 'Mutation' }
  & { uploadWorkshopAttachement: (
    { __typename?: 'WorkshopAttachement' }
    & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
  ) }
);

export type RemoveWorkshopAttachementMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type RemoveWorkshopAttachementMutation = (
  { __typename?: 'Mutation' }
  & { removeWorkshopAttachement: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type ParentFoldersWithoutChildrenQueryVariables = Exact<{ [key: string]: never; }>;


export type ParentFoldersWithoutChildrenQuery = (
  { __typename?: 'Query' }
  & { parentFolders: Array<(
    { __typename?: 'Folder' }
    & Pick<Folder, 'id' | 'title' | 'createdAt' | 'updatedAt' | 'active'>
  )> }
);

export type TerseVehicleWarrantyHistoryQueryVariables = Exact<{
  warrantyId: Scalars['ID'];
}>;


export type TerseVehicleWarrantyHistoryQuery = (
  { __typename?: 'Query' }
  & { vehicleWarrantyHistory: Array<(
    { __typename?: 'VehicleWarranty' }
    & Pick<VehicleWarranty, 'defectPartNumber' | 'id' | 'status' | 'customerRemark' | 'workshopRemark' | 'sumCredited' | 'shippingCost'>
    & { retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'externalId'>
    ) }
  ) | (
    { __typename?: 'GoodWillWarranty' }
    & Pick<GoodWillWarranty, 'id' | 'status' | 'customerRemark' | 'workshopRemark' | 'sumCredited' | 'shippingCost'>
    & { retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'externalId'>
    ) }
  ) | (
    { __typename?: 'PartWarranty' }
    & Pick<PartWarranty, 'id' | 'status' | 'customerRemark' | 'workshopRemark' | 'sumCredited' | 'shippingCost'>
    & { retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'externalId'>
    ) }
  ) | (
    { __typename?: 'LegacyWarranty' }
    & Pick<LegacyWarranty, 'id' | 'status' | 'customerRemark' | 'workshopRemark' | 'sumCredited' | 'shippingCost'>
    & { retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'externalId'>
    ) }
  )> }
);

export type TerseWarrantiesQueryVariables = Exact<{
  filter?: Maybe<WarrantyFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type TerseWarrantiesQuery = (
  { __typename?: 'Query' }
  & { warranties?: Maybe<(
    { __typename?: 'WarrantyCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'VehicleWarranty' }
      & Pick<VehicleWarranty, 'defectPartNumber' | 'defectPartTypeCode' | 'symptomCode' | 'id' | 'status' | 'customerRemark' | 'workshopRemark' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'vehicleIdentificationNumber' | 'registrationNumber' | 'firstRegisteredAt'>
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'externalId'>
      ), internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'name' | 'role'>
          & { retailer?: Maybe<(
            { __typename?: 'Retailer' }
            & Pick<Retailer, 'id'>
          )> }
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'name' | 'role'>
          & { retailer?: Maybe<(
            { __typename?: 'Retailer' }
            & Pick<Retailer, 'id'>
          )> }
        ) }
      )> }
    ) | (
      { __typename?: 'GoodWillWarranty' }
      & Pick<GoodWillWarranty, 'id' | 'status' | 'customerRemark' | 'workshopRemark' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'vehicleIdentificationNumber' | 'registrationNumber' | 'firstRegisteredAt'>
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'externalId'>
      ), internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'name' | 'role'>
          & { retailer?: Maybe<(
            { __typename?: 'Retailer' }
            & Pick<Retailer, 'id'>
          )> }
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'name' | 'role'>
          & { retailer?: Maybe<(
            { __typename?: 'Retailer' }
            & Pick<Retailer, 'id'>
          )> }
        ) }
      )> }
    ) | (
      { __typename?: 'PartWarranty' }
      & Pick<PartWarranty, 'id' | 'status' | 'customerRemark' | 'workshopRemark' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'externalId'>
      ), internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'name' | 'role'>
          & { retailer?: Maybe<(
            { __typename?: 'Retailer' }
            & Pick<Retailer, 'id'>
          )> }
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'name' | 'role'>
          & { retailer?: Maybe<(
            { __typename?: 'Retailer' }
            & Pick<Retailer, 'id'>
          )> }
        ) }
      )> }
    ) | (
      { __typename?: 'LegacyWarranty' }
      & Pick<LegacyWarranty, 'legacyClaimNumber' | 'legacyDealer' | 'id' | 'status' | 'customerRemark' | 'workshopRemark' | 'createdAt' | 'repairedAt' | 'sumRequested' | 'sumCredited' | 'type' | 'shippingCost'>
      & { vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'vehicleIdentificationNumber' | 'registrationNumber' | 'firstRegisteredAt'>
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'externalId'>
      ), internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'name' | 'role'>
          & { retailer?: Maybe<(
            { __typename?: 'Retailer' }
            & Pick<Retailer, 'id'>
          )> }
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'name' | 'role'>
          & { retailer?: Maybe<(
            { __typename?: 'Retailer' }
            & Pick<Retailer, 'id'>
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type MaintenanceTasksTableQueryVariables = Exact<{
  filter?: Maybe<MaintenanceTaskFilter>;
  sorting?: Maybe<Array<BaseSorting>>;
}>;


export type MaintenanceTasksTableQuery = (
  { __typename?: 'Query' }
  & { maintenanceTasks: (
    { __typename?: 'MaintenanceTaskCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'MaintenanceTask' }
      & Pick<MaintenanceTask, 'id' | 'serviceId' | 'status' | 'workPerformedAt' | 'createdAt' | 'sumRequested' | 'workshopRemark'>
      & { maintenanceCustomer?: Maybe<(
        { __typename?: 'MaintenanceCustomer' }
        & Pick<MaintenanceCustomer, 'name'>
      )>, retailer: (
        { __typename?: 'Retailer' }
        & Pick<Retailer, 'name'>
      ), vehicle?: Maybe<(
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'registrationNumber' | 'vehicleIdentificationNumber'>
      )>, internalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'name' | 'role'>
          & { retailer?: Maybe<(
            { __typename?: 'Retailer' }
            & Pick<Retailer, 'id'>
          )> }
        ) }
      )>, externalMessages: Array<(
        { __typename?: 'WorkshopMessage' }
        & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
        & { author: (
          { __typename?: 'User' }
          & Pick<User, 'name' | 'role'>
          & { retailer?: Maybe<(
            { __typename?: 'Retailer' }
            & Pick<Retailer, 'id'>
          )> }
        ) }
      )> }
    )> }
  ) }
);

export type ExplodedViewRowsTableQueryVariables = Exact<{
  filter?: Maybe<ExplodedViewRowFilter>;
}>;


export type ExplodedViewRowsTableQuery = (
  { __typename?: 'Query' }
  & { explodedViewRows: (
    { __typename?: 'ExplodedViewRowCollection' }
    & { meta: (
      { __typename?: 'PageInfoCustom' }
      & Pick<PageInfoCustom, 'total' | 'limit' | 'offset'>
    ), edges: Array<(
      { __typename?: 'ExplodedViewRow' }
      & Pick<ExplodedViewRow, 'id' | 'referenceNumber'>
      & { stockVehicle?: Maybe<(
        { __typename?: 'StockVehicle' }
        & Pick<StockVehicle, 'id' | 'active' | 'name' | 'articleNumber' | 'price' | 'discountedPrice'>
        & { thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status'>
          & { incomingDeliveries: Array<(
            { __typename?: 'IncomingDelivery' }
            & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
          )> }
        ) }
      )>, equipment?: Maybe<(
        { __typename?: 'Equipment' }
        & Pick<Equipment, 'id' | 'active' | 'remark' | 'name' | 'articleNumber' | 'price' | 'discountedPrice'>
        & { thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status'>
          & { incomingDeliveries: Array<(
            { __typename?: 'IncomingDelivery' }
            & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
          )> }
        ) }
      )>, sparePart?: Maybe<(
        { __typename?: 'SparePart' }
        & Pick<SparePart, 'id' | 'active' | 'remark' | 'name' | 'articleNumber' | 'price' | 'discountedPrice'>
        & { thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status'>
          & { incomingDeliveries: Array<(
            { __typename?: 'IncomingDelivery' }
            & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
          )> }
        ) }
      )>, billOfMaterial?: Maybe<(
        { __typename?: 'BillOfMaterial' }
        & Pick<BillOfMaterial, 'id' | 'active' | 'description' | 'articleNumber' | 'price' | 'discountedPrice'>
        & { thumbnail?: Maybe<(
          { __typename?: 'StockImage' }
          & Pick<StockImage, 'id' | 'name' | 'path'>
        )>, stock: (
          { __typename?: 'Stock' }
          & Pick<Stock, 'warehouse' | 'atRetailer' | 'incoming' | 'status'>
          & { incomingDeliveries: Array<(
            { __typename?: 'IncomingDelivery' }
            & Pick<IncomingDelivery, 'deliveryDate' | 'quantity'>
          )> }
        ) }
      )> }
    )> }
  ) }
);

export type MaintenanceTaskViewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type MaintenanceTaskViewQuery = (
  { __typename?: 'Query' }
  & { maintenanceTaskById?: Maybe<(
    { __typename?: 'MaintenanceTask' }
    & Pick<MaintenanceTask, 'id' | 'serviceId' | 'meterReading' | 'status' | 'createdAt' | 'workshopRemark' | 'customerRemark' | 'freeText' | 'workPerformedAt' | 'customerApprovalBy' | 'customerApprovalAt'>
    & { meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, deliveryServiceImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'name' | 'role'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'name' | 'role'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id'>
        )> }
      ) }
    )>, maintenanceCustomer?: Maybe<(
      { __typename?: 'MaintenanceCustomer' }
      & Pick<MaintenanceCustomer, 'name'>
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'name'>
    ), vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'registrationNumber'>
      & { model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'name'>
      )> }
    )>, rows: Array<(
      { __typename?: 'ArticleMaintenanceRow' }
      & Pick<ArticleMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity'>
        & { order: (
          { __typename?: 'Order' }
          & Pick<Order, 'externalId' | 'deliveryType' | 'createdAt' | 'orderReferenceMessage'>
        ), sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'articleNumber' | 'name'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'articleNumber' | 'description'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )> }
    ) | (
      { __typename?: 'ExternalMaintenanceRow' }
      & Pick<ExternalMaintenanceRow, 'externalCostType' | 'externalCostDescription' | 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { receiptImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )> }
    ) | (
      { __typename?: 'OperationMaintenanceRow' }
      & Pick<OperationMaintenanceRow, 'customOperationDescription' | 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'description' | 'category' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )> }
    ) | (
      { __typename?: 'MileageAllowanceMaintenanceRow' }
      & Pick<MileageAllowanceMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )> }
    ) | (
      { __typename?: 'ShippingMaintenanceRow' }
      & Pick<ShippingMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )> }
    )> }
  )> }
);

export type CustomAddMaintenanceRowMutationVariables = Exact<{
  maintenanceTaskId: Scalars['ID'];
  rowInput: MaintenanceRowInput;
}>;


export type CustomAddMaintenanceRowMutation = (
  { __typename?: 'Mutation' }
  & { addMaintenanceRow: (
    { __typename?: 'MaintenanceTask' }
    & Pick<MaintenanceTask, 'id' | 'serviceId' | 'meterReading' | 'status' | 'createdAt' | 'workshopRemark' | 'customerRemark' | 'freeText' | 'workPerformedAt' | 'customerApprovalBy' | 'customerApprovalAt'>
    & { meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, deliveryServiceImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'name' | 'role'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'name' | 'role'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id'>
        )> }
      ) }
    )>, maintenanceCustomer?: Maybe<(
      { __typename?: 'MaintenanceCustomer' }
      & Pick<MaintenanceCustomer, 'name'>
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'name'>
    ), vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'registrationNumber'>
      & { model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'name'>
      )> }
    )>, rows: Array<(
      { __typename?: 'ArticleMaintenanceRow' }
      & Pick<ArticleMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity'>
        & { order: (
          { __typename?: 'Order' }
          & Pick<Order, 'externalId' | 'deliveryType' | 'createdAt' | 'orderReferenceMessage'>
        ), sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'articleNumber' | 'name'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'articleNumber' | 'description'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )> }
    ) | (
      { __typename?: 'ExternalMaintenanceRow' }
      & Pick<ExternalMaintenanceRow, 'externalCostType' | 'externalCostDescription' | 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { receiptImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )> }
    ) | (
      { __typename?: 'OperationMaintenanceRow' }
      & Pick<OperationMaintenanceRow, 'customOperationDescription' | 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'description' | 'category' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )> }
    ) | (
      { __typename?: 'MileageAllowanceMaintenanceRow' }
      & Pick<MileageAllowanceMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )> }
    ) | (
      { __typename?: 'ShippingMaintenanceRow' }
      & Pick<ShippingMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )> }
    )> }
  ) }
);

export type CustomHandleMaintenanceRowsMutationVariables = Exact<{
  maintenanceTaskId: Scalars['ID'];
  rowsInput: Array<UpdateMaintenanceRowsInput>;
}>;


export type CustomHandleMaintenanceRowsMutation = (
  { __typename?: 'Mutation' }
  & { handleMaintenanceTaskRows: (
    { __typename?: 'MaintenanceTask' }
    & Pick<MaintenanceTask, 'id' | 'serviceId' | 'meterReading' | 'status' | 'createdAt' | 'workshopRemark' | 'customerRemark' | 'freeText' | 'workPerformedAt' | 'customerApprovalBy' | 'customerApprovalAt'>
    & { meterReadingImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, damageImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, deliveryServiceImage?: Maybe<(
      { __typename?: 'WorkshopImage' }
      & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
    )>, attachements: Array<(
      { __typename?: 'WorkshopAttachement' }
      & Pick<WorkshopAttachement, 'id' | 'name' | 'path' | 'mimeType' | 'isImage' | 'createdAt'>
    )>, internalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'name' | 'role'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id'>
        )> }
      ) }
    )>, externalMessages: Array<(
      { __typename?: 'WorkshopMessage' }
      & Pick<WorkshopMessage, 'id' | 'type' | 'text' | 'createdAt' | 'isRead'>
      & { author: (
        { __typename?: 'User' }
        & Pick<User, 'name' | 'role'>
        & { retailer?: Maybe<(
          { __typename?: 'Retailer' }
          & Pick<Retailer, 'id'>
        )> }
      ) }
    )>, maintenanceCustomer?: Maybe<(
      { __typename?: 'MaintenanceCustomer' }
      & Pick<MaintenanceCustomer, 'name'>
    )>, retailer: (
      { __typename?: 'Retailer' }
      & Pick<Retailer, 'id' | 'name'>
    ), vehicle?: Maybe<(
      { __typename?: 'Vehicle' }
      & Pick<Vehicle, 'id' | 'registrationNumber'>
      & { model?: Maybe<(
        { __typename?: 'Model' }
        & Pick<Model, 'name'>
      )> }
    )>, rows: Array<(
      { __typename?: 'ArticleMaintenanceRow' }
      & Pick<ArticleMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { orderItem?: Maybe<(
        { __typename?: 'OrderItem' }
        & Pick<OrderItem, 'id' | 'deliveredQuantity' | 'freeQuantity'>
        & { order: (
          { __typename?: 'Order' }
          & Pick<Order, 'externalId' | 'deliveryType' | 'createdAt' | 'orderReferenceMessage'>
        ), sparePart?: Maybe<(
          { __typename?: 'SparePart' }
          & Pick<SparePart, 'articleNumber' | 'name'>
        )>, billOfMaterial?: Maybe<(
          { __typename?: 'BillOfMaterial' }
          & Pick<BillOfMaterial, 'articleNumber' | 'description'>
        )> }
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )> }
    ) | (
      { __typename?: 'ExternalMaintenanceRow' }
      & Pick<ExternalMaintenanceRow, 'externalCostType' | 'externalCostDescription' | 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { receiptImage?: Maybe<(
        { __typename?: 'WorkshopImage' }
        & Pick<WorkshopImage, 'id' | 'name' | 'path' | 'createdAt'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )> }
    ) | (
      { __typename?: 'OperationMaintenanceRow' }
      & Pick<OperationMaintenanceRow, 'customOperationDescription' | 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { operationCode?: Maybe<(
        { __typename?: 'OperationCode' }
        & Pick<OperationCode, 'id' | 'code' | 'description' | 'category' | 'time'>
      )>, handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )> }
    ) | (
      { __typename?: 'MileageAllowanceMaintenanceRow' }
      & Pick<MileageAllowanceMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )> }
    ) | (
      { __typename?: 'ShippingMaintenanceRow' }
      & Pick<ShippingMaintenanceRow, 'id' | 'workCategory' | 'status' | 'complementReason' | 'complementComment' | 'declineReason' | 'declineComment' | 'price' | 'requestedPrice' | 'quantity' | 'requestedQuantity'>
      & { handledBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )> }
    )> }
  ) }
);

export const PartialWorkshopMessageFragmentDoc = gql`
    fragment PartialWorkshopMessage on WorkshopMessage {
  id
  type
  text
  author {
    name
    role
    retailer {
      id
    }
  }
  createdAt
  isRead
}
    `;
export const PartialMaintenanceTaskFragmentDoc = gql`
    fragment PartialMaintenanceTask on MaintenanceTask {
  id
  serviceId
  meterReading
  status
  createdAt
  workshopRemark
  customerRemark
  freeText
  workPerformedAt
  customerApprovalBy
  customerApprovalAt
  meterReadingImage {
    id
    name
    path
    createdAt
  }
  damageImage {
    id
    name
    path
    createdAt
  }
  deliveryServiceImage {
    id
    name
    path
    createdAt
  }
  attachements {
    id
    name
    path
    mimeType
    isImage
    createdAt
  }
  internalMessages {
    ...PartialWorkshopMessage
  }
  externalMessages {
    ...PartialWorkshopMessage
  }
  maintenanceCustomer {
    name
  }
  retailer {
    id
    name
  }
  vehicle {
    id
    registrationNumber
    model {
      name
    }
  }
  rows {
    id
    workCategory
    status
    complementReason
    complementComment
    declineReason
    declineComment
    price
    requestedPrice
    quantity
    handledBy {
      name
    }
    requestedQuantity
    ... on ExternalMaintenanceRow {
      externalCostType
      externalCostDescription
      receiptImage {
        id
        name
        path
        createdAt
      }
    }
    ... on ArticleMaintenanceRow {
      orderItem {
        id
        order {
          externalId
          deliveryType
          createdAt
          orderReferenceMessage
        }
        sparePart {
          articleNumber
          name
        }
        billOfMaterial {
          articleNumber
          description
        }
        deliveredQuantity
        freeQuantity
      }
    }
    ... on OperationMaintenanceRow {
      customOperationDescription
      operationCode {
        id
        code
        description
        category
        time
      }
    }
  }
}
    ${PartialWorkshopMessageFragmentDoc}`;
export const WarrantyMessagesDocument = gql`
    query WarrantyMessages($id: ID!) {
  warrantyById(id: $id) {
    internalMessages {
      ...PartialWorkshopMessage
    }
    externalMessages {
      ...PartialWorkshopMessage
    }
  }
}
    ${PartialWorkshopMessageFragmentDoc}`;

/**
 * __useWarrantyMessagesQuery__
 *
 * To run a query within a React component, call `useWarrantyMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarrantyMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarrantyMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWarrantyMessagesQuery(baseOptions: Apollo.QueryHookOptions<WarrantyMessagesQuery, WarrantyMessagesQueryVariables>) {
        return Apollo.useQuery<WarrantyMessagesQuery, WarrantyMessagesQueryVariables>(WarrantyMessagesDocument, baseOptions);
      }
export function useWarrantyMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WarrantyMessagesQuery, WarrantyMessagesQueryVariables>) {
          return Apollo.useLazyQuery<WarrantyMessagesQuery, WarrantyMessagesQueryVariables>(WarrantyMessagesDocument, baseOptions);
        }
export type WarrantyMessagesQueryHookResult = ReturnType<typeof useWarrantyMessagesQuery>;
export type WarrantyMessagesLazyQueryHookResult = ReturnType<typeof useWarrantyMessagesLazyQuery>;
export type WarrantyMessagesQueryResult = Apollo.QueryResult<WarrantyMessagesQuery, WarrantyMessagesQueryVariables>;
export const MaintenanceTaskMessagesDocument = gql`
    query MaintenanceTaskMessages($id: ID!) {
  maintenanceTaskById(id: $id) {
    internalMessages {
      ...PartialWorkshopMessage
    }
    externalMessages {
      ...PartialWorkshopMessage
    }
  }
}
    ${PartialWorkshopMessageFragmentDoc}`;

/**
 * __useMaintenanceTaskMessagesQuery__
 *
 * To run a query within a React component, call `useMaintenanceTaskMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceTaskMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceTaskMessagesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMaintenanceTaskMessagesQuery(baseOptions: Apollo.QueryHookOptions<MaintenanceTaskMessagesQuery, MaintenanceTaskMessagesQueryVariables>) {
        return Apollo.useQuery<MaintenanceTaskMessagesQuery, MaintenanceTaskMessagesQueryVariables>(MaintenanceTaskMessagesDocument, baseOptions);
      }
export function useMaintenanceTaskMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaintenanceTaskMessagesQuery, MaintenanceTaskMessagesQueryVariables>) {
          return Apollo.useLazyQuery<MaintenanceTaskMessagesQuery, MaintenanceTaskMessagesQueryVariables>(MaintenanceTaskMessagesDocument, baseOptions);
        }
export type MaintenanceTaskMessagesQueryHookResult = ReturnType<typeof useMaintenanceTaskMessagesQuery>;
export type MaintenanceTaskMessagesLazyQueryHookResult = ReturnType<typeof useMaintenanceTaskMessagesLazyQuery>;
export type MaintenanceTaskMessagesQueryResult = Apollo.QueryResult<MaintenanceTaskMessagesQuery, MaintenanceTaskMessagesQueryVariables>;
export const VehicleInfoViewDocument = gql`
    query VehicleInfoView($id: String!) {
  warrantyVehicleById(id: $id) {
    id
    stockVehicle {
      color
      colorCode
    }
    vehicleIdentificationNumber
    registrationNumber
    brand {
      id
      externalId
      name
    }
    modelSeries {
      name
    }
    model {
      name
    }
    retailer {
      city
    }
    nonColorFactoryOptions {
      description
    }
    typeOfInstallation
    engineNumber
    factoryCode
    status
    producedAt
    firstRegisteredAt
    replacementText
  }
}
    `;

/**
 * __useVehicleInfoViewQuery__
 *
 * To run a query within a React component, call `useVehicleInfoViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleInfoViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleInfoViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVehicleInfoViewQuery(baseOptions: Apollo.QueryHookOptions<VehicleInfoViewQuery, VehicleInfoViewQueryVariables>) {
        return Apollo.useQuery<VehicleInfoViewQuery, VehicleInfoViewQueryVariables>(VehicleInfoViewDocument, baseOptions);
      }
export function useVehicleInfoViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleInfoViewQuery, VehicleInfoViewQueryVariables>) {
          return Apollo.useLazyQuery<VehicleInfoViewQuery, VehicleInfoViewQueryVariables>(VehicleInfoViewDocument, baseOptions);
        }
export type VehicleInfoViewQueryHookResult = ReturnType<typeof useVehicleInfoViewQuery>;
export type VehicleInfoViewLazyQueryHookResult = ReturnType<typeof useVehicleInfoViewLazyQuery>;
export type VehicleInfoViewQueryResult = Apollo.QueryResult<VehicleInfoViewQuery, VehicleInfoViewQueryVariables>;
export const CustomVehiclesDocument = gql`
    query customVehicles($filter: BaseFilter, $sorting: [BaseSorting!], $fetchOnBehalfOfRetailerId: Int) {
  warrantyVehicles(
    filter: $filter
    sorting: $sorting
    fetchOnBehalfOfRetailerId: $fetchOnBehalfOfRetailerId
  ) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      stockVehicle {
        id
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      vehicleIdentificationNumber
      registrationNumber
      brand {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      startedAt
      showDetailedInformation
      typeOfInstallation
      nonColorFactoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      createdAt
      updatedAt
      engineNumber
      factoryCode
      status
      firstArrivedToSweden
      producedAt
      firstRegisteredAt
      replacementText
      showBfiAndKey
    }
  }
}
    `;

/**
 * __useCustomVehiclesQuery__
 *
 * To run a query within a React component, call `useCustomVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomVehiclesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      fetchOnBehalfOfRetailerId: // value for 'fetchOnBehalfOfRetailerId'
 *   },
 * });
 */
export function useCustomVehiclesQuery(baseOptions?: Apollo.QueryHookOptions<CustomVehiclesQuery, CustomVehiclesQueryVariables>) {
        return Apollo.useQuery<CustomVehiclesQuery, CustomVehiclesQueryVariables>(CustomVehiclesDocument, baseOptions);
      }
export function useCustomVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomVehiclesQuery, CustomVehiclesQueryVariables>) {
          return Apollo.useLazyQuery<CustomVehiclesQuery, CustomVehiclesQueryVariables>(CustomVehiclesDocument, baseOptions);
        }
export type CustomVehiclesQueryHookResult = ReturnType<typeof useCustomVehiclesQuery>;
export type CustomVehiclesLazyQueryHookResult = ReturnType<typeof useCustomVehiclesLazyQuery>;
export type CustomVehiclesQueryResult = Apollo.QueryResult<CustomVehiclesQuery, CustomVehiclesQueryVariables>;
export const CustomWarrantyByIdDocument = gql`
    query CustomWarrantyById($id: ID!) {
  warrantyById(id: $id) {
    id
    status
    declineReason
    declineComment
    retailer {
      externalId
      name
    }
    customerRemark
    workshopRemark
    rows {
      id
      quantity
      originalQuantity
      price
      percentage
      originalPercentage
      total
      originalTotal
      referenceName
      description
      status
      complementReason
      complementComment
      declineReason
      declineComment
      type
      originalHours
      hours
      handledBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
        }
        active
        createdAt
        updatedAt
      }
      ... on OperationRow {
        operationCode {
          id
          code
          category
          description
          time
        }
      }
      ... on ExternRow {
        purchaseRefersType
        receiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on ArticleRow {
        sparePart {
          id
          articleNumber
          active
          name
          remark
          price
          discountedPrice
          vat
          stock {
            warehouse
            atRetailer
            incoming
            status
            incomingDeliveries {
              deliveryDate
              quantity
            }
            updatedAt
          }
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            incomingDeliveries {
              deliveryDate
              quantity
            }
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
        }
        orderItem {
          id
          vehicle {
            id
            name
            vehicleType
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
          }
          status
          finalPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            stockArrivalDate
            connectionType
          }
          connectionType
        }
        legacyArticleNumber
        legacyOrderNumber
      }
      ... on LegacyRow {
        code
        originalComp
        comp
        originalPrice
        legacyType
      }
    }
    attachements {
      id
      name
      path
      mimeType
      isImage
      createdAt
    }
    registeredAt
    createdAt
    repairedAt
    sumRequested
    sumCredited
    type
    internalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
        }
        active
        createdAt
        updatedAt
      }
      createdAt
    }
    externalMessages {
      id
      type
      text
      isRead
      author {
        id
        role
      }
      createdAt
    }
    damageImage {
      id
      name
      path
      createdAt
    }
    shippingCost
    ... on VehicleWarranty {
      operationalizedFrom {
        id
      }
      vehicle {
        id
        vehicleIdentificationNumber
        registrationNumber
        createdAt
        startedAt
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          description
        }
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        showBfiAndKey
        replacementText
      }
      meterReading
      operatingTime
      meterReadingImage {
        id
        name
        path
        createdAt
      }
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      defectPartNumber
      defectPartTypeCode
      symptomCode
    }
    ... on PartWarranty {
      purchaseReceiptImage {
        id
        name
        path
        createdAt
      }
    }
    ... on GoodWillWarranty {
      operationalizedTo {
        id
        status
      }
      vehicle {
        id
        vehicleIdentificationNumber
        registrationNumber
        createdAt
        startedAt
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          description
        }
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        showBfiAndKey
        replacementText
      }
      meterReading
      operatingTime
      serviceBookImage {
        id
        name
        path
        createdAt
      }
    }
    ... on LegacyWarranty {
      legacyClaimNumber
      vehicle {
        id
        vehicleIdentificationNumber
        registrationNumber
        createdAt
        startedAt
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          description
        }
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        showBfiAndKey
        replacementText
      }
      legacyDealer
      causeOfDamage
      warrantyStartedAt
      receivedAt
    }
  }
}
    `;

/**
 * __useCustomWarrantyByIdQuery__
 *
 * To run a query within a React component, call `useCustomWarrantyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomWarrantyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomWarrantyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomWarrantyByIdQuery(baseOptions: Apollo.QueryHookOptions<CustomWarrantyByIdQuery, CustomWarrantyByIdQueryVariables>) {
        return Apollo.useQuery<CustomWarrantyByIdQuery, CustomWarrantyByIdQueryVariables>(CustomWarrantyByIdDocument, baseOptions);
      }
export function useCustomWarrantyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomWarrantyByIdQuery, CustomWarrantyByIdQueryVariables>) {
          return Apollo.useLazyQuery<CustomWarrantyByIdQuery, CustomWarrantyByIdQueryVariables>(CustomWarrantyByIdDocument, baseOptions);
        }
export type CustomWarrantyByIdQueryHookResult = ReturnType<typeof useCustomWarrantyByIdQuery>;
export type CustomWarrantyByIdLazyQueryHookResult = ReturnType<typeof useCustomWarrantyByIdLazyQuery>;
export type CustomWarrantyByIdQueryResult = Apollo.QueryResult<CustomWarrantyByIdQuery, CustomWarrantyByIdQueryVariables>;
export const FolderNamesByVehicleIdDocument = gql`
    query FolderNamesByVehicleId($id: String!) {
  foldersByVehicleId(id: $id) {
    folderByMake {
      id
      title
    }
    foldersByModelSeries {
      id
      title
      parent {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useFolderNamesByVehicleIdQuery__
 *
 * To run a query within a React component, call `useFolderNamesByVehicleIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderNamesByVehicleIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderNamesByVehicleIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFolderNamesByVehicleIdQuery(baseOptions: Apollo.QueryHookOptions<FolderNamesByVehicleIdQuery, FolderNamesByVehicleIdQueryVariables>) {
        return Apollo.useQuery<FolderNamesByVehicleIdQuery, FolderNamesByVehicleIdQueryVariables>(FolderNamesByVehicleIdDocument, baseOptions);
      }
export function useFolderNamesByVehicleIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FolderNamesByVehicleIdQuery, FolderNamesByVehicleIdQueryVariables>) {
          return Apollo.useLazyQuery<FolderNamesByVehicleIdQuery, FolderNamesByVehicleIdQueryVariables>(FolderNamesByVehicleIdDocument, baseOptions);
        }
export type FolderNamesByVehicleIdQueryHookResult = ReturnType<typeof useFolderNamesByVehicleIdQuery>;
export type FolderNamesByVehicleIdLazyQueryHookResult = ReturnType<typeof useFolderNamesByVehicleIdLazyQuery>;
export type FolderNamesByVehicleIdQueryResult = Apollo.QueryResult<FolderNamesByVehicleIdQuery, FolderNamesByVehicleIdQueryVariables>;
export const UserByIdDocument = gql`
    query UserById($id: String!) {
  userById(id: $id) {
    id
    email
    name
    phoneNumber
    freeText
    role
    roleDescription
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    active
    acceptsEmailReminders
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useUserByIdQuery__
 *
 * To run a query within a React component, call `useUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserByIdQuery(baseOptions: Apollo.QueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
        return Apollo.useQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, baseOptions);
      }
export function useUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByIdQuery, UserByIdQueryVariables>) {
          return Apollo.useLazyQuery<UserByIdQuery, UserByIdQueryVariables>(UserByIdDocument, baseOptions);
        }
export type UserByIdQueryHookResult = ReturnType<typeof useUserByIdQuery>;
export type UserByIdLazyQueryHookResult = ReturnType<typeof useUserByIdLazyQuery>;
export type UserByIdQueryResult = Apollo.QueryResult<UserByIdQuery, UserByIdQueryVariables>;
export const UsersByIdsDocument = gql`
    query UsersByIds($ids: [String!]!) {
  usersByIds(ids: $ids) {
    id
    email
    name
    phoneNumber
    freeText
    role
    roleDescription
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    active
    acceptsEmailReminders
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useUsersByIdsQuery__
 *
 * To run a query within a React component, call `useUsersByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUsersByIdsQuery(baseOptions: Apollo.QueryHookOptions<UsersByIdsQuery, UsersByIdsQueryVariables>) {
        return Apollo.useQuery<UsersByIdsQuery, UsersByIdsQueryVariables>(UsersByIdsDocument, baseOptions);
      }
export function useUsersByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersByIdsQuery, UsersByIdsQueryVariables>) {
          return Apollo.useLazyQuery<UsersByIdsQuery, UsersByIdsQueryVariables>(UsersByIdsDocument, baseOptions);
        }
export type UsersByIdsQueryHookResult = ReturnType<typeof useUsersByIdsQuery>;
export type UsersByIdsLazyQueryHookResult = ReturnType<typeof useUsersByIdsLazyQuery>;
export type UsersByIdsQueryResult = Apollo.QueryResult<UsersByIdsQuery, UsersByIdsQueryVariables>;
export const UserByTokenDocument = gql`
    query UserByToken {
  userByToken {
    user {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    actAsRetailerRight
  }
}
    `;

/**
 * __useUserByTokenQuery__
 *
 * To run a query within a React component, call `useUserByTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserByTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserByTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserByTokenQuery(baseOptions?: Apollo.QueryHookOptions<UserByTokenQuery, UserByTokenQueryVariables>) {
        return Apollo.useQuery<UserByTokenQuery, UserByTokenQueryVariables>(UserByTokenDocument, baseOptions);
      }
export function useUserByTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserByTokenQuery, UserByTokenQueryVariables>) {
          return Apollo.useLazyQuery<UserByTokenQuery, UserByTokenQueryVariables>(UserByTokenDocument, baseOptions);
        }
export type UserByTokenQueryHookResult = ReturnType<typeof useUserByTokenQuery>;
export type UserByTokenLazyQueryHookResult = ReturnType<typeof useUserByTokenLazyQuery>;
export type UserByTokenQueryResult = Apollo.QueryResult<UserByTokenQuery, UserByTokenQueryVariables>;
export const UsersDocument = gql`
    query Users($filter: BaseFilter, $sorting: [BaseSorting!]) {
  users(filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, baseOptions);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const MakesDocument = gql`
    query Makes($filter: MakeFilter) {
  makes(filter: $filter) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      externalId
      name
    }
  }
}
    `;

/**
 * __useMakesQuery__
 *
 * To run a query within a React component, call `useMakesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMakesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMakesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMakesQuery(baseOptions?: Apollo.QueryHookOptions<MakesQuery, MakesQueryVariables>) {
        return Apollo.useQuery<MakesQuery, MakesQueryVariables>(MakesDocument, baseOptions);
      }
export function useMakesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MakesQuery, MakesQueryVariables>) {
          return Apollo.useLazyQuery<MakesQuery, MakesQueryVariables>(MakesDocument, baseOptions);
        }
export type MakesQueryHookResult = ReturnType<typeof useMakesQuery>;
export type MakesLazyQueryHookResult = ReturnType<typeof useMakesLazyQuery>;
export type MakesQueryResult = Apollo.QueryResult<MakesQuery, MakesQueryVariables>;
export const MakesByIdsDocument = gql`
    query MakesByIds($ids: [ID!]!) {
  makesByIds(ids: $ids) {
    id
    externalId
    name
  }
}
    `;

/**
 * __useMakesByIdsQuery__
 *
 * To run a query within a React component, call `useMakesByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMakesByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMakesByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useMakesByIdsQuery(baseOptions: Apollo.QueryHookOptions<MakesByIdsQuery, MakesByIdsQueryVariables>) {
        return Apollo.useQuery<MakesByIdsQuery, MakesByIdsQueryVariables>(MakesByIdsDocument, baseOptions);
      }
export function useMakesByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MakesByIdsQuery, MakesByIdsQueryVariables>) {
          return Apollo.useLazyQuery<MakesByIdsQuery, MakesByIdsQueryVariables>(MakesByIdsDocument, baseOptions);
        }
export type MakesByIdsQueryHookResult = ReturnType<typeof useMakesByIdsQuery>;
export type MakesByIdsLazyQueryHookResult = ReturnType<typeof useMakesByIdsLazyQuery>;
export type MakesByIdsQueryResult = Apollo.QueryResult<MakesByIdsQuery, MakesByIdsQueryVariables>;
export const MakeByIdDocument = gql`
    query MakeById($id: ID!) {
  makeById(id: $id) {
    id
    externalId
    name
  }
}
    `;

/**
 * __useMakeByIdQuery__
 *
 * To run a query within a React component, call `useMakeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMakeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMakeByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMakeByIdQuery(baseOptions: Apollo.QueryHookOptions<MakeByIdQuery, MakeByIdQueryVariables>) {
        return Apollo.useQuery<MakeByIdQuery, MakeByIdQueryVariables>(MakeByIdDocument, baseOptions);
      }
export function useMakeByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MakeByIdQuery, MakeByIdQueryVariables>) {
          return Apollo.useLazyQuery<MakeByIdQuery, MakeByIdQueryVariables>(MakeByIdDocument, baseOptions);
        }
export type MakeByIdQueryHookResult = ReturnType<typeof useMakeByIdQuery>;
export type MakeByIdLazyQueryHookResult = ReturnType<typeof useMakeByIdLazyQuery>;
export type MakeByIdQueryResult = Apollo.QueryResult<MakeByIdQuery, MakeByIdQueryVariables>;
export const AllMakesByLoggedInUserDocument = gql`
    query AllMakesByLoggedInUser {
  allMakesByLoggedInUser {
    id
    externalId
    name
  }
}
    `;

/**
 * __useAllMakesByLoggedInUserQuery__
 *
 * To run a query within a React component, call `useAllMakesByLoggedInUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllMakesByLoggedInUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllMakesByLoggedInUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllMakesByLoggedInUserQuery(baseOptions?: Apollo.QueryHookOptions<AllMakesByLoggedInUserQuery, AllMakesByLoggedInUserQueryVariables>) {
        return Apollo.useQuery<AllMakesByLoggedInUserQuery, AllMakesByLoggedInUserQueryVariables>(AllMakesByLoggedInUserDocument, baseOptions);
      }
export function useAllMakesByLoggedInUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllMakesByLoggedInUserQuery, AllMakesByLoggedInUserQueryVariables>) {
          return Apollo.useLazyQuery<AllMakesByLoggedInUserQuery, AllMakesByLoggedInUserQueryVariables>(AllMakesByLoggedInUserDocument, baseOptions);
        }
export type AllMakesByLoggedInUserQueryHookResult = ReturnType<typeof useAllMakesByLoggedInUserQuery>;
export type AllMakesByLoggedInUserLazyQueryHookResult = ReturnType<typeof useAllMakesByLoggedInUserLazyQuery>;
export type AllMakesByLoggedInUserQueryResult = Apollo.QueryResult<AllMakesByLoggedInUserQuery, AllMakesByLoggedInUserQueryVariables>;
export const RetailersDocument = gql`
    query Retailers($filter: BaseFilter, $sorting: [BaseSorting!]) {
  retailers(filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
  }
}
    `;

/**
 * __useRetailersQuery__
 *
 * To run a query within a React component, call `useRetailersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useRetailersQuery(baseOptions?: Apollo.QueryHookOptions<RetailersQuery, RetailersQueryVariables>) {
        return Apollo.useQuery<RetailersQuery, RetailersQueryVariables>(RetailersDocument, baseOptions);
      }
export function useRetailersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetailersQuery, RetailersQueryVariables>) {
          return Apollo.useLazyQuery<RetailersQuery, RetailersQueryVariables>(RetailersDocument, baseOptions);
        }
export type RetailersQueryHookResult = ReturnType<typeof useRetailersQuery>;
export type RetailersLazyQueryHookResult = ReturnType<typeof useRetailersLazyQuery>;
export type RetailersQueryResult = Apollo.QueryResult<RetailersQuery, RetailersQueryVariables>;
export const RetailersByIdsDocument = gql`
    query RetailersByIds($ids: [Int!]!) {
  retailersByIds(ids: $ids) {
    id
    externalId
    name
    address
    postalCode
    city
    country
    telephoneNumber
    email
    makes {
      id
      make {
        id
        externalId
        name
      }
      accessToSell
      accessToWorkshop
      warrantyHourlyPayment
    }
    users {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      createdAt
      updatedAt
    }
    contactUser {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      createdAt
      updatedAt
    }
    hasConsignmentVehicles
    hasExhibitionVehicles
    hasMaintenanceCustomers
    hasExternalFinancing
  }
}
    `;

/**
 * __useRetailersByIdsQuery__
 *
 * To run a query within a React component, call `useRetailersByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailersByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailersByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRetailersByIdsQuery(baseOptions: Apollo.QueryHookOptions<RetailersByIdsQuery, RetailersByIdsQueryVariables>) {
        return Apollo.useQuery<RetailersByIdsQuery, RetailersByIdsQueryVariables>(RetailersByIdsDocument, baseOptions);
      }
export function useRetailersByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetailersByIdsQuery, RetailersByIdsQueryVariables>) {
          return Apollo.useLazyQuery<RetailersByIdsQuery, RetailersByIdsQueryVariables>(RetailersByIdsDocument, baseOptions);
        }
export type RetailersByIdsQueryHookResult = ReturnType<typeof useRetailersByIdsQuery>;
export type RetailersByIdsLazyQueryHookResult = ReturnType<typeof useRetailersByIdsLazyQuery>;
export type RetailersByIdsQueryResult = Apollo.QueryResult<RetailersByIdsQuery, RetailersByIdsQueryVariables>;
export const RetailerByIdDocument = gql`
    query RetailerById($id: Int!) {
  retailerById(id: $id) {
    id
    externalId
    name
    address
    postalCode
    city
    country
    telephoneNumber
    email
    makes {
      id
      make {
        id
        externalId
        name
      }
      accessToSell
      accessToWorkshop
      warrantyHourlyPayment
    }
    users {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      createdAt
      updatedAt
    }
    contactUser {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      createdAt
      updatedAt
    }
    hasConsignmentVehicles
    hasExhibitionVehicles
    hasMaintenanceCustomers
    hasExternalFinancing
  }
}
    `;

/**
 * __useRetailerByIdQuery__
 *
 * To run a query within a React component, call `useRetailerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetailerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetailerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRetailerByIdQuery(baseOptions: Apollo.QueryHookOptions<RetailerByIdQuery, RetailerByIdQueryVariables>) {
        return Apollo.useQuery<RetailerByIdQuery, RetailerByIdQueryVariables>(RetailerByIdDocument, baseOptions);
      }
export function useRetailerByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetailerByIdQuery, RetailerByIdQueryVariables>) {
          return Apollo.useLazyQuery<RetailerByIdQuery, RetailerByIdQueryVariables>(RetailerByIdDocument, baseOptions);
        }
export type RetailerByIdQueryHookResult = ReturnType<typeof useRetailerByIdQuery>;
export type RetailerByIdLazyQueryHookResult = ReturnType<typeof useRetailerByIdLazyQuery>;
export type RetailerByIdQueryResult = Apollo.QueryResult<RetailerByIdQuery, RetailerByIdQueryVariables>;
export const WarrantiesDocument = gql`
    query Warranties($filter: WarrantyFilter, $sorting: [BaseSorting!]) {
  warranties(filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      status
      declineReason
      declineComment
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      customerRemark
      workshopRemark
      rows {
        id
        quantity
        originalQuantity
        price
        percentage
        originalPercentage
        total
        originalTotal
        referenceName
        description
        status
        complementReason
        complementComment
        declineReason
        declineComment
        type
        originalHours
        hours
        handledBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        ... on OperationRow {
          operationCode {
            id
            code
            category
            description
            time
          }
        }
        ... on ExternRow {
          purchaseRefersType
          receiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on ArticleRow {
          orderItem {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          legacyArticleNumber
          legacyOrderNumber
        }
        ... on LegacyRow {
          code
          originalComp
          comp
          originalPrice
          legacyType
        }
      }
      attachements {
        id
        name
        path
        mimeType
        isImage
        createdAt
      }
      registeredAt
      createdAt
      repairedAt
      sumRequested
      sumCredited
      type
      internalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      externalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      damageImage {
        id
        name
        path
        createdAt
      }
      shippingCost
      ... on VehicleWarranty {
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        meterReadingImage {
          id
          name
          path
          createdAt
        }
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        defectPartNumber
        defectPartTypeCode
        symptomCode
        operationalizedFrom {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
      }
      ... on GoodWillWarranty {
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        operationalizedTo {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
      }
      ... on PartWarranty {
        purchaseReceiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on LegacyWarranty {
        legacyClaimNumber
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        legacyDealer
        causeOfDamage
        warrantyStartedAt
        receivedAt
      }
    }
  }
}
    `;

/**
 * __useWarrantiesQuery__
 *
 * To run a query within a React component, call `useWarrantiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarrantiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarrantiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useWarrantiesQuery(baseOptions?: Apollo.QueryHookOptions<WarrantiesQuery, WarrantiesQueryVariables>) {
        return Apollo.useQuery<WarrantiesQuery, WarrantiesQueryVariables>(WarrantiesDocument, baseOptions);
      }
export function useWarrantiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WarrantiesQuery, WarrantiesQueryVariables>) {
          return Apollo.useLazyQuery<WarrantiesQuery, WarrantiesQueryVariables>(WarrantiesDocument, baseOptions);
        }
export type WarrantiesQueryHookResult = ReturnType<typeof useWarrantiesQuery>;
export type WarrantiesLazyQueryHookResult = ReturnType<typeof useWarrantiesLazyQuery>;
export type WarrantiesQueryResult = Apollo.QueryResult<WarrantiesQuery, WarrantiesQueryVariables>;
export const WarrantyByIdDocument = gql`
    query WarrantyById($id: ID!) {
  warrantyById(id: $id) {
    id
    status
    declineReason
    declineComment
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    customerRemark
    workshopRemark
    rows {
      id
      quantity
      originalQuantity
      price
      percentage
      originalPercentage
      total
      originalTotal
      referenceName
      description
      status
      complementReason
      complementComment
      declineReason
      declineComment
      type
      originalHours
      hours
      handledBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      ... on OperationRow {
        operationCode {
          id
          code
          category
          description
          time
        }
      }
      ... on ExternRow {
        purchaseRefersType
        receiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on ArticleRow {
        orderItem {
          id
          vehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          fee {
            id
            name
            articleNumber
            price
            vat
            discountedPrice
            createdAt
            updatedAt
            isAllowedOnVehicles
            active
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          detailedQuantities {
            processing
            allocated
            blocked
            delivered
            invoiced
          }
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
            externallyFinanced
          }
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          connectionType
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        legacyArticleNumber
        legacyOrderNumber
      }
      ... on LegacyRow {
        code
        originalComp
        comp
        originalPrice
        legacyType
      }
    }
    attachements {
      id
      name
      path
      mimeType
      isImage
      createdAt
    }
    registeredAt
    createdAt
    repairedAt
    sumRequested
    sumCredited
    type
    internalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    externalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    damageImage {
      id
      name
      path
      createdAt
    }
    shippingCost
    ... on VehicleWarranty {
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      meterReadingImage {
        id
        name
        path
        createdAt
      }
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      defectPartNumber
      defectPartTypeCode
      symptomCode
      operationalizedFrom {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          handledBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          ... on OperationRow {
            operationCode {
              id
              code
              category
              description
              time
            }
          }
          ... on ExternRow {
            purchaseRefersType
            receiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on ArticleRow {
            orderItem {
              id
              deliveredQuantity
              freeQuantity
              remark
              status
              finalPrice
              unitPrice
              stockArrivalDate
              connectionType
            }
            sparePart {
              id
              articleNumber
              name
              remark
              price
              discountedPrice
              vat
              active
              vehicleCampaign
            }
            billOfMaterial {
              id
              articleNumber
              active
              bomCode
              description
              fromDate
              toDate
              price
              discountedPrice
              vehicleCampaign
            }
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        operationalizedTo {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
      }
    }
    ... on GoodWillWarranty {
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      operationalizedTo {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          handledBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          ... on OperationRow {
            operationCode {
              id
              code
              category
              description
              time
            }
          }
          ... on ExternRow {
            purchaseRefersType
            receiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on ArticleRow {
            orderItem {
              id
              deliveredQuantity
              freeQuantity
              remark
              status
              finalPrice
              unitPrice
              stockArrivalDate
              connectionType
            }
            sparePart {
              id
              articleNumber
              name
              remark
              price
              discountedPrice
              vat
              active
              vehicleCampaign
            }
            billOfMaterial {
              id
              articleNumber
              active
              bomCode
              description
              fromDate
              toDate
              price
              discountedPrice
              vehicleCampaign
            }
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        meterReadingImage {
          id
          name
          path
          createdAt
        }
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        defectPartNumber
        defectPartTypeCode
        symptomCode
        operationalizedFrom {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
      }
    }
    ... on PartWarranty {
      purchaseReceiptImage {
        id
        name
        path
        createdAt
      }
    }
    ... on LegacyWarranty {
      legacyClaimNumber
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      legacyDealer
      causeOfDamage
      warrantyStartedAt
      receivedAt
    }
  }
}
    `;

/**
 * __useWarrantyByIdQuery__
 *
 * To run a query within a React component, call `useWarrantyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarrantyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarrantyByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWarrantyByIdQuery(baseOptions: Apollo.QueryHookOptions<WarrantyByIdQuery, WarrantyByIdQueryVariables>) {
        return Apollo.useQuery<WarrantyByIdQuery, WarrantyByIdQueryVariables>(WarrantyByIdDocument, baseOptions);
      }
export function useWarrantyByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WarrantyByIdQuery, WarrantyByIdQueryVariables>) {
          return Apollo.useLazyQuery<WarrantyByIdQuery, WarrantyByIdQueryVariables>(WarrantyByIdDocument, baseOptions);
        }
export type WarrantyByIdQueryHookResult = ReturnType<typeof useWarrantyByIdQuery>;
export type WarrantyByIdLazyQueryHookResult = ReturnType<typeof useWarrantyByIdLazyQuery>;
export type WarrantyByIdQueryResult = Apollo.QueryResult<WarrantyByIdQuery, WarrantyByIdQueryVariables>;
export const VehicleWarrantyHistoryDocument = gql`
    query VehicleWarrantyHistory($warrantyId: ID!) {
  vehicleWarrantyHistory(warrantyId: $warrantyId) {
    id
    status
    declineReason
    declineComment
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    customerRemark
    workshopRemark
    rows {
      id
      quantity
      originalQuantity
      price
      percentage
      originalPercentage
      total
      originalTotal
      referenceName
      description
      status
      complementReason
      complementComment
      declineReason
      declineComment
      type
      originalHours
      hours
      handledBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      ... on OperationRow {
        operationCode {
          id
          code
          category
          description
          time
        }
      }
      ... on ExternRow {
        purchaseRefersType
        receiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on ArticleRow {
        orderItem {
          id
          vehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          fee {
            id
            name
            articleNumber
            price
            vat
            discountedPrice
            createdAt
            updatedAt
            isAllowedOnVehicles
            active
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          detailedQuantities {
            processing
            allocated
            blocked
            delivered
            invoiced
          }
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
            externallyFinanced
          }
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          connectionType
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        legacyArticleNumber
        legacyOrderNumber
      }
      ... on LegacyRow {
        code
        originalComp
        comp
        originalPrice
        legacyType
      }
    }
    attachements {
      id
      name
      path
      mimeType
      isImage
      createdAt
    }
    registeredAt
    createdAt
    repairedAt
    sumRequested
    sumCredited
    type
    internalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    externalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    damageImage {
      id
      name
      path
      createdAt
    }
    shippingCost
    ... on VehicleWarranty {
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      meterReadingImage {
        id
        name
        path
        createdAt
      }
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      defectPartNumber
      defectPartTypeCode
      symptomCode
      operationalizedFrom {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          handledBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          ... on OperationRow {
            operationCode {
              id
              code
              category
              description
              time
            }
          }
          ... on ExternRow {
            purchaseRefersType
            receiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on ArticleRow {
            orderItem {
              id
              deliveredQuantity
              freeQuantity
              remark
              status
              finalPrice
              unitPrice
              stockArrivalDate
              connectionType
            }
            sparePart {
              id
              articleNumber
              name
              remark
              price
              discountedPrice
              vat
              active
              vehicleCampaign
            }
            billOfMaterial {
              id
              articleNumber
              active
              bomCode
              description
              fromDate
              toDate
              price
              discountedPrice
              vehicleCampaign
            }
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        operationalizedTo {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
      }
    }
    ... on GoodWillWarranty {
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      operationalizedTo {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          handledBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          ... on OperationRow {
            operationCode {
              id
              code
              category
              description
              time
            }
          }
          ... on ExternRow {
            purchaseRefersType
            receiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on ArticleRow {
            orderItem {
              id
              deliveredQuantity
              freeQuantity
              remark
              status
              finalPrice
              unitPrice
              stockArrivalDate
              connectionType
            }
            sparePart {
              id
              articleNumber
              name
              remark
              price
              discountedPrice
              vat
              active
              vehicleCampaign
            }
            billOfMaterial {
              id
              articleNumber
              active
              bomCode
              description
              fromDate
              toDate
              price
              discountedPrice
              vehicleCampaign
            }
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        meterReadingImage {
          id
          name
          path
          createdAt
        }
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        defectPartNumber
        defectPartTypeCode
        symptomCode
        operationalizedFrom {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
      }
    }
    ... on PartWarranty {
      purchaseReceiptImage {
        id
        name
        path
        createdAt
      }
    }
    ... on LegacyWarranty {
      legacyClaimNumber
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      legacyDealer
      causeOfDamage
      warrantyStartedAt
      receivedAt
    }
  }
}
    `;

/**
 * __useVehicleWarrantyHistoryQuery__
 *
 * To run a query within a React component, call `useVehicleWarrantyHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleWarrantyHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleWarrantyHistoryQuery({
 *   variables: {
 *      warrantyId: // value for 'warrantyId'
 *   },
 * });
 */
export function useVehicleWarrantyHistoryQuery(baseOptions: Apollo.QueryHookOptions<VehicleWarrantyHistoryQuery, VehicleWarrantyHistoryQueryVariables>) {
        return Apollo.useQuery<VehicleWarrantyHistoryQuery, VehicleWarrantyHistoryQueryVariables>(VehicleWarrantyHistoryDocument, baseOptions);
      }
export function useVehicleWarrantyHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleWarrantyHistoryQuery, VehicleWarrantyHistoryQueryVariables>) {
          return Apollo.useLazyQuery<VehicleWarrantyHistoryQuery, VehicleWarrantyHistoryQueryVariables>(VehicleWarrantyHistoryDocument, baseOptions);
        }
export type VehicleWarrantyHistoryQueryHookResult = ReturnType<typeof useVehicleWarrantyHistoryQuery>;
export type VehicleWarrantyHistoryLazyQueryHookResult = ReturnType<typeof useVehicleWarrantyHistoryLazyQuery>;
export type VehicleWarrantyHistoryQueryResult = Apollo.QueryResult<VehicleWarrantyHistoryQuery, VehicleWarrantyHistoryQueryVariables>;
export const WarrantyVehiclesDocument = gql`
    query WarrantyVehicles($filter: BaseFilter, $sorting: [BaseSorting!], $fetchOnBehalfOfRetailerId: Int) {
  warrantyVehicles(
    filter: $filter
    sorting: $sorting
    fetchOnBehalfOfRetailerId: $fetchOnBehalfOfRetailerId
  ) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      vehicleIdentificationNumber
      registrationNumber
      brand {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      startedAt
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      showDetailedInformation
      typeOfInstallation
      nonColorFactoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      createdAt
      updatedAt
      engineNumber
      factoryCode
      status
      firstArrivedToSweden
      producedAt
      firstRegisteredAt
      replacementText
      showBfiAndKey
      warranties {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          ... on ExternRow {
            purchaseRefersType
          }
          ... on ArticleRow {
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        ... on VehicleWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
        ... on GoodWillWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
        ... on PartWarranty {
          purchaseReceiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on LegacyWarranty {
          legacyClaimNumber
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          legacyDealer
          causeOfDamage
          warrantyStartedAt
          receivedAt
        }
      }
    }
  }
}
    `;

/**
 * __useWarrantyVehiclesQuery__
 *
 * To run a query within a React component, call `useWarrantyVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarrantyVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarrantyVehiclesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      fetchOnBehalfOfRetailerId: // value for 'fetchOnBehalfOfRetailerId'
 *   },
 * });
 */
export function useWarrantyVehiclesQuery(baseOptions?: Apollo.QueryHookOptions<WarrantyVehiclesQuery, WarrantyVehiclesQueryVariables>) {
        return Apollo.useQuery<WarrantyVehiclesQuery, WarrantyVehiclesQueryVariables>(WarrantyVehiclesDocument, baseOptions);
      }
export function useWarrantyVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WarrantyVehiclesQuery, WarrantyVehiclesQueryVariables>) {
          return Apollo.useLazyQuery<WarrantyVehiclesQuery, WarrantyVehiclesQueryVariables>(WarrantyVehiclesDocument, baseOptions);
        }
export type WarrantyVehiclesQueryHookResult = ReturnType<typeof useWarrantyVehiclesQuery>;
export type WarrantyVehiclesLazyQueryHookResult = ReturnType<typeof useWarrantyVehiclesLazyQuery>;
export type WarrantyVehiclesQueryResult = Apollo.QueryResult<WarrantyVehiclesQuery, WarrantyVehiclesQueryVariables>;
export const WarrantyVehicleByVinOrRegNumDocument = gql`
    query WarrantyVehicleByVinOrRegNum($searchTerm: String!) {
  warrantyVehicleByVinOrRegNum(searchTerm: $searchTerm) {
    brandFolder {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    modelFolder {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    vehicle {
      id
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      vehicleIdentificationNumber
      registrationNumber
      brand {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      startedAt
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      showDetailedInformation
      typeOfInstallation
      nonColorFactoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      createdAt
      updatedAt
      engineNumber
      factoryCode
      status
      firstArrivedToSweden
      producedAt
      firstRegisteredAt
      replacementText
      showBfiAndKey
      warranties {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          ... on ExternRow {
            purchaseRefersType
          }
          ... on ArticleRow {
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        ... on VehicleWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
        ... on GoodWillWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
        ... on PartWarranty {
          purchaseReceiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on LegacyWarranty {
          legacyClaimNumber
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          legacyDealer
          causeOfDamage
          warrantyStartedAt
          receivedAt
        }
      }
    }
  }
}
    `;

/**
 * __useWarrantyVehicleByVinOrRegNumQuery__
 *
 * To run a query within a React component, call `useWarrantyVehicleByVinOrRegNumQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarrantyVehicleByVinOrRegNumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarrantyVehicleByVinOrRegNumQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useWarrantyVehicleByVinOrRegNumQuery(baseOptions: Apollo.QueryHookOptions<WarrantyVehicleByVinOrRegNumQuery, WarrantyVehicleByVinOrRegNumQueryVariables>) {
        return Apollo.useQuery<WarrantyVehicleByVinOrRegNumQuery, WarrantyVehicleByVinOrRegNumQueryVariables>(WarrantyVehicleByVinOrRegNumDocument, baseOptions);
      }
export function useWarrantyVehicleByVinOrRegNumLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WarrantyVehicleByVinOrRegNumQuery, WarrantyVehicleByVinOrRegNumQueryVariables>) {
          return Apollo.useLazyQuery<WarrantyVehicleByVinOrRegNumQuery, WarrantyVehicleByVinOrRegNumQueryVariables>(WarrantyVehicleByVinOrRegNumDocument, baseOptions);
        }
export type WarrantyVehicleByVinOrRegNumQueryHookResult = ReturnType<typeof useWarrantyVehicleByVinOrRegNumQuery>;
export type WarrantyVehicleByVinOrRegNumLazyQueryHookResult = ReturnType<typeof useWarrantyVehicleByVinOrRegNumLazyQuery>;
export type WarrantyVehicleByVinOrRegNumQueryResult = Apollo.QueryResult<WarrantyVehicleByVinOrRegNumQuery, WarrantyVehicleByVinOrRegNumQueryVariables>;
export const WarrantyVehicleByIdDocument = gql`
    query WarrantyVehicleById($id: String!) {
  warrantyVehicleById(id: $id) {
    id
    stockVehicle {
      id
      sageId
      name
      brand {
        id
        externalId
        name
      }
      vehicleType
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      color
      colorCode
      createdAt
      price
      vat
      discountedPrice
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      equipments {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      articleNumber
      factoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      vehicleCampaign
      active
    }
    vehicleIdentificationNumber
    registrationNumber
    brand {
      id
      externalId
      name
    }
    modelSeries {
      id
      name
      brand {
        id
        externalId
        name
      }
      models {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
    }
    model {
      id
      name
      description
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
    }
    startedAt
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    showDetailedInformation
    typeOfInstallation
    nonColorFactoryOptions {
      id
      description
      factoryOptionType {
        id
        description
      }
    }
    createdAt
    updatedAt
    engineNumber
    factoryCode
    status
    firstArrivedToSweden
    producedAt
    firstRegisteredAt
    replacementText
    showBfiAndKey
    warranties {
      id
      status
      declineReason
      declineComment
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      customerRemark
      workshopRemark
      rows {
        id
        quantity
        originalQuantity
        price
        percentage
        originalPercentage
        total
        originalTotal
        referenceName
        description
        status
        complementReason
        complementComment
        declineReason
        declineComment
        type
        originalHours
        hours
        handledBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        ... on OperationRow {
          operationCode {
            id
            code
            category
            description
            time
          }
        }
        ... on ExternRow {
          purchaseRefersType
          receiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on ArticleRow {
          orderItem {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          legacyArticleNumber
          legacyOrderNumber
        }
        ... on LegacyRow {
          code
          originalComp
          comp
          originalPrice
          legacyType
        }
      }
      attachements {
        id
        name
        path
        mimeType
        isImage
        createdAt
      }
      registeredAt
      createdAt
      repairedAt
      sumRequested
      sumCredited
      type
      internalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      externalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      damageImage {
        id
        name
        path
        createdAt
      }
      shippingCost
      ... on VehicleWarranty {
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        meterReadingImage {
          id
          name
          path
          createdAt
        }
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        defectPartNumber
        defectPartTypeCode
        symptomCode
        operationalizedFrom {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
      }
      ... on GoodWillWarranty {
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        operationalizedTo {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
      }
      ... on PartWarranty {
        purchaseReceiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on LegacyWarranty {
        legacyClaimNumber
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        legacyDealer
        causeOfDamage
        warrantyStartedAt
        receivedAt
      }
    }
  }
}
    `;

/**
 * __useWarrantyVehicleByIdQuery__
 *
 * To run a query within a React component, call `useWarrantyVehicleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarrantyVehicleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarrantyVehicleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWarrantyVehicleByIdQuery(baseOptions: Apollo.QueryHookOptions<WarrantyVehicleByIdQuery, WarrantyVehicleByIdQueryVariables>) {
        return Apollo.useQuery<WarrantyVehicleByIdQuery, WarrantyVehicleByIdQueryVariables>(WarrantyVehicleByIdDocument, baseOptions);
      }
export function useWarrantyVehicleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WarrantyVehicleByIdQuery, WarrantyVehicleByIdQueryVariables>) {
          return Apollo.useLazyQuery<WarrantyVehicleByIdQuery, WarrantyVehicleByIdQueryVariables>(WarrantyVehicleByIdDocument, baseOptions);
        }
export type WarrantyVehicleByIdQueryHookResult = ReturnType<typeof useWarrantyVehicleByIdQuery>;
export type WarrantyVehicleByIdLazyQueryHookResult = ReturnType<typeof useWarrantyVehicleByIdLazyQuery>;
export type WarrantyVehicleByIdQueryResult = Apollo.QueryResult<WarrantyVehicleByIdQuery, WarrantyVehicleByIdQueryVariables>;
export const WarrantyDisplayViewDocument = gql`
    query WarrantyDisplayView {
  warrantyDisplayView {
    id
    name
    columnData {
      position
      name
      active
    }
    user {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    active
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useWarrantyDisplayViewQuery__
 *
 * To run a query within a React component, call `useWarrantyDisplayViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useWarrantyDisplayViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWarrantyDisplayViewQuery({
 *   variables: {
 *   },
 * });
 */
export function useWarrantyDisplayViewQuery(baseOptions?: Apollo.QueryHookOptions<WarrantyDisplayViewQuery, WarrantyDisplayViewQueryVariables>) {
        return Apollo.useQuery<WarrantyDisplayViewQuery, WarrantyDisplayViewQueryVariables>(WarrantyDisplayViewDocument, baseOptions);
      }
export function useWarrantyDisplayViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WarrantyDisplayViewQuery, WarrantyDisplayViewQueryVariables>) {
          return Apollo.useLazyQuery<WarrantyDisplayViewQuery, WarrantyDisplayViewQueryVariables>(WarrantyDisplayViewDocument, baseOptions);
        }
export type WarrantyDisplayViewQueryHookResult = ReturnType<typeof useWarrantyDisplayViewQuery>;
export type WarrantyDisplayViewLazyQueryHookResult = ReturnType<typeof useWarrantyDisplayViewLazyQuery>;
export type WarrantyDisplayViewQueryResult = Apollo.QueryResult<WarrantyDisplayViewQuery, WarrantyDisplayViewQueryVariables>;
export const ExplodedViewsDocument = gql`
    query ExplodedViews($filter: ExplodedViewFilter, $sorting: [BaseSorting!]) {
  explodedViews(filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      title
      explodedViewImages {
        id
        name
        path
        createdAt
      }
      explodedViewRows {
        id
        referenceNumber
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
      }
      brandFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      modelFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      mainCategoryFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      subCategoryFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      folder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useExplodedViewsQuery__
 *
 * To run a query within a React component, call `useExplodedViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplodedViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplodedViewsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useExplodedViewsQuery(baseOptions?: Apollo.QueryHookOptions<ExplodedViewsQuery, ExplodedViewsQueryVariables>) {
        return Apollo.useQuery<ExplodedViewsQuery, ExplodedViewsQueryVariables>(ExplodedViewsDocument, baseOptions);
      }
export function useExplodedViewsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExplodedViewsQuery, ExplodedViewsQueryVariables>) {
          return Apollo.useLazyQuery<ExplodedViewsQuery, ExplodedViewsQueryVariables>(ExplodedViewsDocument, baseOptions);
        }
export type ExplodedViewsQueryHookResult = ReturnType<typeof useExplodedViewsQuery>;
export type ExplodedViewsLazyQueryHookResult = ReturnType<typeof useExplodedViewsLazyQuery>;
export type ExplodedViewsQueryResult = Apollo.QueryResult<ExplodedViewsQuery, ExplodedViewsQueryVariables>;
export const ExplodedViewRowsDocument = gql`
    query ExplodedViewRows($filter: ExplodedViewRowFilter) {
  explodedViewRows(filter: $filter) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      referenceNumber
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
    }
  }
}
    `;

/**
 * __useExplodedViewRowsQuery__
 *
 * To run a query within a React component, call `useExplodedViewRowsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplodedViewRowsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplodedViewRowsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExplodedViewRowsQuery(baseOptions?: Apollo.QueryHookOptions<ExplodedViewRowsQuery, ExplodedViewRowsQueryVariables>) {
        return Apollo.useQuery<ExplodedViewRowsQuery, ExplodedViewRowsQueryVariables>(ExplodedViewRowsDocument, baseOptions);
      }
export function useExplodedViewRowsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExplodedViewRowsQuery, ExplodedViewRowsQueryVariables>) {
          return Apollo.useLazyQuery<ExplodedViewRowsQuery, ExplodedViewRowsQueryVariables>(ExplodedViewRowsDocument, baseOptions);
        }
export type ExplodedViewRowsQueryHookResult = ReturnType<typeof useExplodedViewRowsQuery>;
export type ExplodedViewRowsLazyQueryHookResult = ReturnType<typeof useExplodedViewRowsLazyQuery>;
export type ExplodedViewRowsQueryResult = Apollo.QueryResult<ExplodedViewRowsQuery, ExplodedViewRowsQueryVariables>;
export const ExplodedViewByIdDocument = gql`
    query ExplodedViewById($id: String!) {
  explodedViewById(id: $id) {
    id
    title
    explodedViewImages {
      id
      name
      path
      createdAt
    }
    explodedViewRows {
      id
      referenceNumber
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
    }
    brandFolder {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    modelFolder {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    mainCategoryFolder {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    subCategoryFolder {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    folder {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useExplodedViewByIdQuery__
 *
 * To run a query within a React component, call `useExplodedViewByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplodedViewByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplodedViewByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExplodedViewByIdQuery(baseOptions: Apollo.QueryHookOptions<ExplodedViewByIdQuery, ExplodedViewByIdQueryVariables>) {
        return Apollo.useQuery<ExplodedViewByIdQuery, ExplodedViewByIdQueryVariables>(ExplodedViewByIdDocument, baseOptions);
      }
export function useExplodedViewByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExplodedViewByIdQuery, ExplodedViewByIdQueryVariables>) {
          return Apollo.useLazyQuery<ExplodedViewByIdQuery, ExplodedViewByIdQueryVariables>(ExplodedViewByIdDocument, baseOptions);
        }
export type ExplodedViewByIdQueryHookResult = ReturnType<typeof useExplodedViewByIdQuery>;
export type ExplodedViewByIdLazyQueryHookResult = ReturnType<typeof useExplodedViewByIdLazyQuery>;
export type ExplodedViewByIdQueryResult = Apollo.QueryResult<ExplodedViewByIdQuery, ExplodedViewByIdQueryVariables>;
export const FoldersByVehicleIdDocument = gql`
    query FoldersByVehicleId($id: String!) {
  foldersByVehicleId(id: $id) {
    folderByMake {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    foldersByModelSeries {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useFoldersByVehicleIdQuery__
 *
 * To run a query within a React component, call `useFoldersByVehicleIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoldersByVehicleIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoldersByVehicleIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFoldersByVehicleIdQuery(baseOptions: Apollo.QueryHookOptions<FoldersByVehicleIdQuery, FoldersByVehicleIdQueryVariables>) {
        return Apollo.useQuery<FoldersByVehicleIdQuery, FoldersByVehicleIdQueryVariables>(FoldersByVehicleIdDocument, baseOptions);
      }
export function useFoldersByVehicleIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoldersByVehicleIdQuery, FoldersByVehicleIdQueryVariables>) {
          return Apollo.useLazyQuery<FoldersByVehicleIdQuery, FoldersByVehicleIdQueryVariables>(FoldersByVehicleIdDocument, baseOptions);
        }
export type FoldersByVehicleIdQueryHookResult = ReturnType<typeof useFoldersByVehicleIdQuery>;
export type FoldersByVehicleIdLazyQueryHookResult = ReturnType<typeof useFoldersByVehicleIdLazyQuery>;
export type FoldersByVehicleIdQueryResult = Apollo.QueryResult<FoldersByVehicleIdQuery, FoldersByVehicleIdQueryVariables>;
export const FoldersDocument = gql`
    query Folders {
  folders {
    id
    title
    parent {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    active
    children {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    explodedViews {
      id
      title
      explodedViewImages {
        id
        name
        path
        createdAt
      }
      explodedViewRows {
        id
        referenceNumber
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
      }
      brandFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      modelFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      mainCategoryFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      subCategoryFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      folder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useFoldersQuery__
 *
 * To run a query within a React component, call `useFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoldersQuery({
 *   variables: {
 *   },
 * });
 */
export function useFoldersQuery(baseOptions?: Apollo.QueryHookOptions<FoldersQuery, FoldersQueryVariables>) {
        return Apollo.useQuery<FoldersQuery, FoldersQueryVariables>(FoldersDocument, baseOptions);
      }
export function useFoldersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FoldersQuery, FoldersQueryVariables>) {
          return Apollo.useLazyQuery<FoldersQuery, FoldersQueryVariables>(FoldersDocument, baseOptions);
        }
export type FoldersQueryHookResult = ReturnType<typeof useFoldersQuery>;
export type FoldersLazyQueryHookResult = ReturnType<typeof useFoldersLazyQuery>;
export type FoldersQueryResult = Apollo.QueryResult<FoldersQuery, FoldersQueryVariables>;
export const ParentFoldersDocument = gql`
    query ParentFolders($fetchOnBehalfOfRetailerId: Int) {
  parentFolders(fetchOnBehalfOfRetailerId: $fetchOnBehalfOfRetailerId) {
    id
    title
    parent {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    active
    children {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    explodedViews {
      id
      title
      explodedViewImages {
        id
        name
        path
        createdAt
      }
      explodedViewRows {
        id
        referenceNumber
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
      }
      brandFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      modelFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      mainCategoryFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      subCategoryFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      folder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useParentFoldersQuery__
 *
 * To run a query within a React component, call `useParentFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentFoldersQuery({
 *   variables: {
 *      fetchOnBehalfOfRetailerId: // value for 'fetchOnBehalfOfRetailerId'
 *   },
 * });
 */
export function useParentFoldersQuery(baseOptions?: Apollo.QueryHookOptions<ParentFoldersQuery, ParentFoldersQueryVariables>) {
        return Apollo.useQuery<ParentFoldersQuery, ParentFoldersQueryVariables>(ParentFoldersDocument, baseOptions);
      }
export function useParentFoldersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParentFoldersQuery, ParentFoldersQueryVariables>) {
          return Apollo.useLazyQuery<ParentFoldersQuery, ParentFoldersQueryVariables>(ParentFoldersDocument, baseOptions);
        }
export type ParentFoldersQueryHookResult = ReturnType<typeof useParentFoldersQuery>;
export type ParentFoldersLazyQueryHookResult = ReturnType<typeof useParentFoldersLazyQuery>;
export type ParentFoldersQueryResult = Apollo.QueryResult<ParentFoldersQuery, ParentFoldersQueryVariables>;
export const FolderByIdDocument = gql`
    query FolderById($id: String!, $depth: Int) {
  folderById(id: $id, depth: $depth) {
    id
    title
    parent {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    active
    children {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    explodedViews {
      id
      title
      explodedViewImages {
        id
        name
        path
        createdAt
      }
      explodedViewRows {
        id
        referenceNumber
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
      }
      brandFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      modelFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      mainCategoryFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      subCategoryFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      folder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useFolderByIdQuery__
 *
 * To run a query within a React component, call `useFolderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFolderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFolderByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      depth: // value for 'depth'
 *   },
 * });
 */
export function useFolderByIdQuery(baseOptions: Apollo.QueryHookOptions<FolderByIdQuery, FolderByIdQueryVariables>) {
        return Apollo.useQuery<FolderByIdQuery, FolderByIdQueryVariables>(FolderByIdDocument, baseOptions);
      }
export function useFolderByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FolderByIdQuery, FolderByIdQueryVariables>) {
          return Apollo.useLazyQuery<FolderByIdQuery, FolderByIdQueryVariables>(FolderByIdDocument, baseOptions);
        }
export type FolderByIdQueryHookResult = ReturnType<typeof useFolderByIdQuery>;
export type FolderByIdLazyQueryHookResult = ReturnType<typeof useFolderByIdLazyQuery>;
export type FolderByIdQueryResult = Apollo.QueryResult<FolderByIdQuery, FolderByIdQueryVariables>;
export const SparePartsDocument = gql`
    query SpareParts($filter: BaseFilter, $sorting: [BaseSorting!]) {
  spareParts(filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      articleNumber
      name
      remark
      price
      discountedPrice
      vat
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      explodedViewRows {
        id
        referenceNumber
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
      }
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      active
      vehicleCampaign
    }
  }
}
    `;

/**
 * __useSparePartsQuery__
 *
 * To run a query within a React component, call `useSparePartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSparePartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSparePartsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useSparePartsQuery(baseOptions?: Apollo.QueryHookOptions<SparePartsQuery, SparePartsQueryVariables>) {
        return Apollo.useQuery<SparePartsQuery, SparePartsQueryVariables>(SparePartsDocument, baseOptions);
      }
export function useSparePartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SparePartsQuery, SparePartsQueryVariables>) {
          return Apollo.useLazyQuery<SparePartsQuery, SparePartsQueryVariables>(SparePartsDocument, baseOptions);
        }
export type SparePartsQueryHookResult = ReturnType<typeof useSparePartsQuery>;
export type SparePartsLazyQueryHookResult = ReturnType<typeof useSparePartsLazyQuery>;
export type SparePartsQueryResult = Apollo.QueryResult<SparePartsQuery, SparePartsQueryVariables>;
export const OrderByIdDocument = gql`
    query OrderById($id: String!) {
  orderById(id: $id) {
    id
    serialNumber
    externalId
    status
    invoice
    items {
      id
      vehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      fee {
        id
        name
        articleNumber
        price
        vat
        discountedPrice
        createdAt
        updatedAt
        isAllowedOnVehicles
        active
      }
      replacedProduct {
        id
        name
        articleNumber
      }
      deliveredQuantity
      freeQuantity
      detailedQuantities {
        processing
        allocated
        blocked
        delivered
        invoiced
      }
      remark
      order {
        id
        serialNumber
        externalId
        status
        invoice
        items {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        products {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        fees {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        createdAt
        invoicedAt
        referenceUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        total
        vat
        totalQuantity
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        deliveryType
        address
        postalCode
        postalCity
        partialDelivery
        orderReferenceMessage
        externallyFinanced
      }
      status
      finalPrice
      unitPrice
      stockArrivalDate
      connection {
        id
        vehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        deliveredQuantity
        freeQuantity
        detailedQuantities {
          processing
          allocated
          blocked
          delivered
          invoiced
        }
        remark
        order {
          id
          serialNumber
          externalId
          status
          invoice
          createdAt
          invoicedAt
          total
          vat
          totalQuantity
          deliveryType
          address
          postalCode
          postalCity
          partialDelivery
          orderReferenceMessage
          externallyFinanced
        }
        status
        finalPrice
        unitPrice
        stockArrivalDate
        connection {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        connectionType
      }
      invoices {
        id
        invoiceDate
        buyer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        deliveryAddress
        dueDate
        priceExclVat
        priceInclVat
        invoiceRows {
          id
          sageId
          articleNumber
          description
          quantity
          netPriceExclVat
          grossPriceExclVat
        }
        status
        invoiceType
        paymentCondition
        orderReferences
        orderReferenceMessage
      }
      connectionType
    }
    products {
      id
      vehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      fee {
        id
        name
        articleNumber
        price
        vat
        discountedPrice
        createdAt
        updatedAt
        isAllowedOnVehicles
        active
      }
      replacedProduct {
        id
        name
        articleNumber
      }
      deliveredQuantity
      freeQuantity
      detailedQuantities {
        processing
        allocated
        blocked
        delivered
        invoiced
      }
      remark
      order {
        id
        serialNumber
        externalId
        status
        invoice
        items {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        products {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        fees {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        createdAt
        invoicedAt
        referenceUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        total
        vat
        totalQuantity
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        deliveryType
        address
        postalCode
        postalCity
        partialDelivery
        orderReferenceMessage
        externallyFinanced
      }
      status
      finalPrice
      unitPrice
      stockArrivalDate
      connection {
        id
        vehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        deliveredQuantity
        freeQuantity
        detailedQuantities {
          processing
          allocated
          blocked
          delivered
          invoiced
        }
        remark
        order {
          id
          serialNumber
          externalId
          status
          invoice
          createdAt
          invoicedAt
          total
          vat
          totalQuantity
          deliveryType
          address
          postalCode
          postalCity
          partialDelivery
          orderReferenceMessage
          externallyFinanced
        }
        status
        finalPrice
        unitPrice
        stockArrivalDate
        connection {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        connectionType
      }
      invoices {
        id
        invoiceDate
        buyer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        deliveryAddress
        dueDate
        priceExclVat
        priceInclVat
        invoiceRows {
          id
          sageId
          articleNumber
          description
          quantity
          netPriceExclVat
          grossPriceExclVat
        }
        status
        invoiceType
        paymentCondition
        orderReferences
        orderReferenceMessage
      }
      connectionType
    }
    fees {
      id
      vehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      fee {
        id
        name
        articleNumber
        price
        vat
        discountedPrice
        createdAt
        updatedAt
        isAllowedOnVehicles
        active
      }
      replacedProduct {
        id
        name
        articleNumber
      }
      deliveredQuantity
      freeQuantity
      detailedQuantities {
        processing
        allocated
        blocked
        delivered
        invoiced
      }
      remark
      order {
        id
        serialNumber
        externalId
        status
        invoice
        items {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        products {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        fees {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        createdAt
        invoicedAt
        referenceUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        total
        vat
        totalQuantity
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        deliveryType
        address
        postalCode
        postalCity
        partialDelivery
        orderReferenceMessage
        externallyFinanced
      }
      status
      finalPrice
      unitPrice
      stockArrivalDate
      connection {
        id
        vehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        deliveredQuantity
        freeQuantity
        detailedQuantities {
          processing
          allocated
          blocked
          delivered
          invoiced
        }
        remark
        order {
          id
          serialNumber
          externalId
          status
          invoice
          createdAt
          invoicedAt
          total
          vat
          totalQuantity
          deliveryType
          address
          postalCode
          postalCity
          partialDelivery
          orderReferenceMessage
          externallyFinanced
        }
        status
        finalPrice
        unitPrice
        stockArrivalDate
        connection {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        connectionType
      }
      invoices {
        id
        invoiceDate
        buyer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        deliveryAddress
        dueDate
        priceExclVat
        priceInclVat
        invoiceRows {
          id
          sageId
          articleNumber
          description
          quantity
          netPriceExclVat
          grossPriceExclVat
        }
        status
        invoiceType
        paymentCondition
        orderReferences
        orderReferenceMessage
      }
      connectionType
    }
    createdAt
    invoicedAt
    referenceUser {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    total
    vat
    totalQuantity
    invoices {
      id
      invoiceDate
      buyer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      deliveryAddress
      dueDate
      priceExclVat
      priceInclVat
      invoiceRows {
        id
        sageId
        articleNumber
        description
        quantity
        netPriceExclVat
        grossPriceExclVat
      }
      status
      invoiceType
      paymentCondition
      orderReferences
      orderReferenceMessage
    }
    deliveryType
    address
    postalCode
    postalCity
    partialDelivery
    orderReferenceMessage
    externallyFinanced
  }
}
    `;

/**
 * __useOrderByIdQuery__
 *
 * To run a query within a React component, call `useOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderByIdQuery(baseOptions: Apollo.QueryHookOptions<OrderByIdQuery, OrderByIdQueryVariables>) {
        return Apollo.useQuery<OrderByIdQuery, OrderByIdQueryVariables>(OrderByIdDocument, baseOptions);
      }
export function useOrderByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderByIdQuery, OrderByIdQueryVariables>) {
          return Apollo.useLazyQuery<OrderByIdQuery, OrderByIdQueryVariables>(OrderByIdDocument, baseOptions);
        }
export type OrderByIdQueryHookResult = ReturnType<typeof useOrderByIdQuery>;
export type OrderByIdLazyQueryHookResult = ReturnType<typeof useOrderByIdLazyQuery>;
export type OrderByIdQueryResult = Apollo.QueryResult<OrderByIdQuery, OrderByIdQueryVariables>;
export const OrdersBySerialNumberDocument = gql`
    query OrdersBySerialNumber($id: String!) {
  ordersBySerialNumber(id: $id) {
    id
    serialNumber
    externalId
    status
    invoice
    items {
      id
      vehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      fee {
        id
        name
        articleNumber
        price
        vat
        discountedPrice
        createdAt
        updatedAt
        isAllowedOnVehicles
        active
      }
      replacedProduct {
        id
        name
        articleNumber
      }
      deliveredQuantity
      freeQuantity
      detailedQuantities {
        processing
        allocated
        blocked
        delivered
        invoiced
      }
      remark
      order {
        id
        serialNumber
        externalId
        status
        invoice
        items {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        products {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        fees {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        createdAt
        invoicedAt
        referenceUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        total
        vat
        totalQuantity
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        deliveryType
        address
        postalCode
        postalCity
        partialDelivery
        orderReferenceMessage
        externallyFinanced
      }
      status
      finalPrice
      unitPrice
      stockArrivalDate
      connection {
        id
        vehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        deliveredQuantity
        freeQuantity
        detailedQuantities {
          processing
          allocated
          blocked
          delivered
          invoiced
        }
        remark
        order {
          id
          serialNumber
          externalId
          status
          invoice
          createdAt
          invoicedAt
          total
          vat
          totalQuantity
          deliveryType
          address
          postalCode
          postalCity
          partialDelivery
          orderReferenceMessage
          externallyFinanced
        }
        status
        finalPrice
        unitPrice
        stockArrivalDate
        connection {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        connectionType
      }
      invoices {
        id
        invoiceDate
        buyer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        deliveryAddress
        dueDate
        priceExclVat
        priceInclVat
        invoiceRows {
          id
          sageId
          articleNumber
          description
          quantity
          netPriceExclVat
          grossPriceExclVat
        }
        status
        invoiceType
        paymentCondition
        orderReferences
        orderReferenceMessage
      }
      connectionType
    }
    products {
      id
      vehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      fee {
        id
        name
        articleNumber
        price
        vat
        discountedPrice
        createdAt
        updatedAt
        isAllowedOnVehicles
        active
      }
      replacedProduct {
        id
        name
        articleNumber
      }
      deliveredQuantity
      freeQuantity
      detailedQuantities {
        processing
        allocated
        blocked
        delivered
        invoiced
      }
      remark
      order {
        id
        serialNumber
        externalId
        status
        invoice
        items {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        products {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        fees {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        createdAt
        invoicedAt
        referenceUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        total
        vat
        totalQuantity
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        deliveryType
        address
        postalCode
        postalCity
        partialDelivery
        orderReferenceMessage
        externallyFinanced
      }
      status
      finalPrice
      unitPrice
      stockArrivalDate
      connection {
        id
        vehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        deliveredQuantity
        freeQuantity
        detailedQuantities {
          processing
          allocated
          blocked
          delivered
          invoiced
        }
        remark
        order {
          id
          serialNumber
          externalId
          status
          invoice
          createdAt
          invoicedAt
          total
          vat
          totalQuantity
          deliveryType
          address
          postalCode
          postalCity
          partialDelivery
          orderReferenceMessage
          externallyFinanced
        }
        status
        finalPrice
        unitPrice
        stockArrivalDate
        connection {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        connectionType
      }
      invoices {
        id
        invoiceDate
        buyer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        deliveryAddress
        dueDate
        priceExclVat
        priceInclVat
        invoiceRows {
          id
          sageId
          articleNumber
          description
          quantity
          netPriceExclVat
          grossPriceExclVat
        }
        status
        invoiceType
        paymentCondition
        orderReferences
        orderReferenceMessage
      }
      connectionType
    }
    fees {
      id
      vehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      fee {
        id
        name
        articleNumber
        price
        vat
        discountedPrice
        createdAt
        updatedAt
        isAllowedOnVehicles
        active
      }
      replacedProduct {
        id
        name
        articleNumber
      }
      deliveredQuantity
      freeQuantity
      detailedQuantities {
        processing
        allocated
        blocked
        delivered
        invoiced
      }
      remark
      order {
        id
        serialNumber
        externalId
        status
        invoice
        items {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        products {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        fees {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        createdAt
        invoicedAt
        referenceUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        total
        vat
        totalQuantity
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        deliveryType
        address
        postalCode
        postalCity
        partialDelivery
        orderReferenceMessage
        externallyFinanced
      }
      status
      finalPrice
      unitPrice
      stockArrivalDate
      connection {
        id
        vehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        deliveredQuantity
        freeQuantity
        detailedQuantities {
          processing
          allocated
          blocked
          delivered
          invoiced
        }
        remark
        order {
          id
          serialNumber
          externalId
          status
          invoice
          createdAt
          invoicedAt
          total
          vat
          totalQuantity
          deliveryType
          address
          postalCode
          postalCity
          partialDelivery
          orderReferenceMessage
          externallyFinanced
        }
        status
        finalPrice
        unitPrice
        stockArrivalDate
        connection {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        connectionType
      }
      invoices {
        id
        invoiceDate
        buyer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        deliveryAddress
        dueDate
        priceExclVat
        priceInclVat
        invoiceRows {
          id
          sageId
          articleNumber
          description
          quantity
          netPriceExclVat
          grossPriceExclVat
        }
        status
        invoiceType
        paymentCondition
        orderReferences
        orderReferenceMessage
      }
      connectionType
    }
    createdAt
    invoicedAt
    referenceUser {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    total
    vat
    totalQuantity
    invoices {
      id
      invoiceDate
      buyer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      deliveryAddress
      dueDate
      priceExclVat
      priceInclVat
      invoiceRows {
        id
        sageId
        articleNumber
        description
        quantity
        netPriceExclVat
        grossPriceExclVat
      }
      status
      invoiceType
      paymentCondition
      orderReferences
      orderReferenceMessage
    }
    deliveryType
    address
    postalCode
    postalCity
    partialDelivery
    orderReferenceMessage
    externallyFinanced
  }
}
    `;

/**
 * __useOrdersBySerialNumberQuery__
 *
 * To run a query within a React component, call `useOrdersBySerialNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersBySerialNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersBySerialNumberQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrdersBySerialNumberQuery(baseOptions: Apollo.QueryHookOptions<OrdersBySerialNumberQuery, OrdersBySerialNumberQueryVariables>) {
        return Apollo.useQuery<OrdersBySerialNumberQuery, OrdersBySerialNumberQueryVariables>(OrdersBySerialNumberDocument, baseOptions);
      }
export function useOrdersBySerialNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersBySerialNumberQuery, OrdersBySerialNumberQueryVariables>) {
          return Apollo.useLazyQuery<OrdersBySerialNumberQuery, OrdersBySerialNumberQueryVariables>(OrdersBySerialNumberDocument, baseOptions);
        }
export type OrdersBySerialNumberQueryHookResult = ReturnType<typeof useOrdersBySerialNumberQuery>;
export type OrdersBySerialNumberLazyQueryHookResult = ReturnType<typeof useOrdersBySerialNumberLazyQuery>;
export type OrdersBySerialNumberQueryResult = Apollo.QueryResult<OrdersBySerialNumberQuery, OrdersBySerialNumberQueryVariables>;
export const OrdersDocument = gql`
    query Orders($filter: OrderFilter, $sorting: [BaseSorting!], $fetchOrdersOnBehalfOfRetailerId: Int) {
  orders(
    filter: $filter
    sorting: $sorting
    fetchOrdersOnBehalfOfRetailerId: $fetchOrdersOnBehalfOfRetailerId
  ) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      serialNumber
      externalId
      status
      invoice
      items {
        id
        vehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        deliveredQuantity
        freeQuantity
        detailedQuantities {
          processing
          allocated
          blocked
          delivered
          invoiced
        }
        remark
        order {
          id
          serialNumber
          externalId
          status
          invoice
          createdAt
          invoicedAt
          total
          vat
          totalQuantity
          deliveryType
          address
          postalCode
          postalCity
          partialDelivery
          orderReferenceMessage
          externallyFinanced
        }
        status
        finalPrice
        unitPrice
        stockArrivalDate
        connection {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        connectionType
      }
      products {
        id
        vehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        deliveredQuantity
        freeQuantity
        detailedQuantities {
          processing
          allocated
          blocked
          delivered
          invoiced
        }
        remark
        order {
          id
          serialNumber
          externalId
          status
          invoice
          createdAt
          invoicedAt
          total
          vat
          totalQuantity
          deliveryType
          address
          postalCode
          postalCity
          partialDelivery
          orderReferenceMessage
          externallyFinanced
        }
        status
        finalPrice
        unitPrice
        stockArrivalDate
        connection {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        connectionType
      }
      fees {
        id
        vehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        deliveredQuantity
        freeQuantity
        detailedQuantities {
          processing
          allocated
          blocked
          delivered
          invoiced
        }
        remark
        order {
          id
          serialNumber
          externalId
          status
          invoice
          createdAt
          invoicedAt
          total
          vat
          totalQuantity
          deliveryType
          address
          postalCode
          postalCity
          partialDelivery
          orderReferenceMessage
          externallyFinanced
        }
        status
        finalPrice
        unitPrice
        stockArrivalDate
        connection {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        connectionType
      }
      createdAt
      invoicedAt
      referenceUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      total
      vat
      totalQuantity
      invoices {
        id
        invoiceDate
        buyer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        deliveryAddress
        dueDate
        priceExclVat
        priceInclVat
        invoiceRows {
          id
          sageId
          articleNumber
          description
          quantity
          netPriceExclVat
          grossPriceExclVat
        }
        status
        invoiceType
        paymentCondition
        orderReferences
        orderReferenceMessage
      }
      deliveryType
      address
      postalCode
      postalCity
      partialDelivery
      orderReferenceMessage
      externallyFinanced
    }
  }
}
    `;

/**
 * __useOrdersQuery__
 *
 * To run a query within a React component, call `useOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrdersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      fetchOrdersOnBehalfOfRetailerId: // value for 'fetchOrdersOnBehalfOfRetailerId'
 *   },
 * });
 */
export function useOrdersQuery(baseOptions?: Apollo.QueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
        return Apollo.useQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, baseOptions);
      }
export function useOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrdersQuery, OrdersQueryVariables>) {
          return Apollo.useLazyQuery<OrdersQuery, OrdersQueryVariables>(OrdersDocument, baseOptions);
        }
export type OrdersQueryHookResult = ReturnType<typeof useOrdersQuery>;
export type OrdersLazyQueryHookResult = ReturnType<typeof useOrdersLazyQuery>;
export type OrdersQueryResult = Apollo.QueryResult<OrdersQuery, OrdersQueryVariables>;
export const OrderItemsDocument = gql`
    query OrderItems($filter: OrderItemFilter, $sorting: [BaseSorting!]) {
  orderItems(filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      vehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      fee {
        id
        name
        articleNumber
        price
        vat
        discountedPrice
        createdAt
        updatedAt
        isAllowedOnVehicles
        active
      }
      replacedProduct {
        id
        name
        articleNumber
      }
      deliveredQuantity
      freeQuantity
      detailedQuantities {
        processing
        allocated
        blocked
        delivered
        invoiced
      }
      remark
      order {
        id
        serialNumber
        externalId
        status
        invoice
        items {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        products {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        fees {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        createdAt
        invoicedAt
        referenceUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        total
        vat
        totalQuantity
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        deliveryType
        address
        postalCode
        postalCity
        partialDelivery
        orderReferenceMessage
        externallyFinanced
      }
      status
      finalPrice
      unitPrice
      stockArrivalDate
      connection {
        id
        vehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        deliveredQuantity
        freeQuantity
        detailedQuantities {
          processing
          allocated
          blocked
          delivered
          invoiced
        }
        remark
        order {
          id
          serialNumber
          externalId
          status
          invoice
          createdAt
          invoicedAt
          total
          vat
          totalQuantity
          deliveryType
          address
          postalCode
          postalCity
          partialDelivery
          orderReferenceMessage
          externallyFinanced
        }
        status
        finalPrice
        unitPrice
        stockArrivalDate
        connection {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        connectionType
      }
      invoices {
        id
        invoiceDate
        buyer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        deliveryAddress
        dueDate
        priceExclVat
        priceInclVat
        invoiceRows {
          id
          sageId
          articleNumber
          description
          quantity
          netPriceExclVat
          grossPriceExclVat
        }
        status
        invoiceType
        paymentCondition
        orderReferences
        orderReferenceMessage
      }
      connectionType
    }
  }
}
    `;

/**
 * __useOrderItemsQuery__
 *
 * To run a query within a React component, call `useOrderItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useOrderItemsQuery(baseOptions?: Apollo.QueryHookOptions<OrderItemsQuery, OrderItemsQueryVariables>) {
        return Apollo.useQuery<OrderItemsQuery, OrderItemsQueryVariables>(OrderItemsDocument, baseOptions);
      }
export function useOrderItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderItemsQuery, OrderItemsQueryVariables>) {
          return Apollo.useLazyQuery<OrderItemsQuery, OrderItemsQueryVariables>(OrderItemsDocument, baseOptions);
        }
export type OrderItemsQueryHookResult = ReturnType<typeof useOrderItemsQuery>;
export type OrderItemsLazyQueryHookResult = ReturnType<typeof useOrderItemsLazyQuery>;
export type OrderItemsQueryResult = Apollo.QueryResult<OrderItemsQuery, OrderItemsQueryVariables>;
export const OrderItemByIdDocument = gql`
    query OrderItemById($id: ID!) {
  orderItemById(id: $id) {
    id
    vehicle {
      id
      sageId
      name
      brand {
        id
        externalId
        name
      }
      vehicleType
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      color
      colorCode
      createdAt
      price
      vat
      discountedPrice
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      equipments {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      articleNumber
      factoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      vehicleCampaign
      active
    }
    equipment {
      id
      name
      articleNumber
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      vehicleType
      price
      vat
      discountedPrice
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      brand {
        id
        externalId
        name
      }
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      createdAt
      vehicleCampaign
      remark
      active
      equipmentGroup {
        id
        name
        externalId
      }
    }
    sparePart {
      id
      articleNumber
      name
      remark
      price
      discountedPrice
      vat
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      explodedViewRows {
        id
        referenceNumber
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
      }
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      active
      vehicleCampaign
    }
    billOfMaterial {
      id
      articleNumber
      active
      bomCode
      description
      fromDate
      toDate
      bomRows {
        id
        linkQuantity
        fromDate
        toDate
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
      }
      price
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      discountedPrice
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      vehicleCampaign
      brand {
        id
        externalId
        name
      }
      equipmentGroup {
        id
        name
        externalId
      }
    }
    fee {
      id
      name
      articleNumber
      price
      vat
      discountedPrice
      createdAt
      updatedAt
      isAllowedOnVehicles
      active
    }
    replacedProduct {
      id
      name
      articleNumber
    }
    deliveredQuantity
    freeQuantity
    detailedQuantities {
      processing
      allocated
      blocked
      delivered
      invoiced
    }
    remark
    order {
      id
      serialNumber
      externalId
      status
      invoice
      items {
        id
        vehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        deliveredQuantity
        freeQuantity
        detailedQuantities {
          processing
          allocated
          blocked
          delivered
          invoiced
        }
        remark
        order {
          id
          serialNumber
          externalId
          status
          invoice
          createdAt
          invoicedAt
          total
          vat
          totalQuantity
          deliveryType
          address
          postalCode
          postalCity
          partialDelivery
          orderReferenceMessage
          externallyFinanced
        }
        status
        finalPrice
        unitPrice
        stockArrivalDate
        connection {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        connectionType
      }
      products {
        id
        vehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        deliveredQuantity
        freeQuantity
        detailedQuantities {
          processing
          allocated
          blocked
          delivered
          invoiced
        }
        remark
        order {
          id
          serialNumber
          externalId
          status
          invoice
          createdAt
          invoicedAt
          total
          vat
          totalQuantity
          deliveryType
          address
          postalCode
          postalCity
          partialDelivery
          orderReferenceMessage
          externallyFinanced
        }
        status
        finalPrice
        unitPrice
        stockArrivalDate
        connection {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        connectionType
      }
      fees {
        id
        vehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        deliveredQuantity
        freeQuantity
        detailedQuantities {
          processing
          allocated
          blocked
          delivered
          invoiced
        }
        remark
        order {
          id
          serialNumber
          externalId
          status
          invoice
          createdAt
          invoicedAt
          total
          vat
          totalQuantity
          deliveryType
          address
          postalCode
          postalCity
          partialDelivery
          orderReferenceMessage
          externallyFinanced
        }
        status
        finalPrice
        unitPrice
        stockArrivalDate
        connection {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        connectionType
      }
      createdAt
      invoicedAt
      referenceUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      total
      vat
      totalQuantity
      invoices {
        id
        invoiceDate
        buyer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        deliveryAddress
        dueDate
        priceExclVat
        priceInclVat
        invoiceRows {
          id
          sageId
          articleNumber
          description
          quantity
          netPriceExclVat
          grossPriceExclVat
        }
        status
        invoiceType
        paymentCondition
        orderReferences
        orderReferenceMessage
      }
      deliveryType
      address
      postalCode
      postalCity
      partialDelivery
      orderReferenceMessage
      externallyFinanced
    }
    status
    finalPrice
    unitPrice
    stockArrivalDate
    connection {
      id
      vehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      fee {
        id
        name
        articleNumber
        price
        vat
        discountedPrice
        createdAt
        updatedAt
        isAllowedOnVehicles
        active
      }
      replacedProduct {
        id
        name
        articleNumber
      }
      deliveredQuantity
      freeQuantity
      detailedQuantities {
        processing
        allocated
        blocked
        delivered
        invoiced
      }
      remark
      order {
        id
        serialNumber
        externalId
        status
        invoice
        items {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        products {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        fees {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        createdAt
        invoicedAt
        referenceUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        total
        vat
        totalQuantity
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        deliveryType
        address
        postalCode
        postalCity
        partialDelivery
        orderReferenceMessage
        externallyFinanced
      }
      status
      finalPrice
      unitPrice
      stockArrivalDate
      connection {
        id
        vehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        deliveredQuantity
        freeQuantity
        detailedQuantities {
          processing
          allocated
          blocked
          delivered
          invoiced
        }
        remark
        order {
          id
          serialNumber
          externalId
          status
          invoice
          createdAt
          invoicedAt
          total
          vat
          totalQuantity
          deliveryType
          address
          postalCode
          postalCity
          partialDelivery
          orderReferenceMessage
          externallyFinanced
        }
        status
        finalPrice
        unitPrice
        stockArrivalDate
        connection {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        connectionType
      }
      invoices {
        id
        invoiceDate
        buyer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        deliveryAddress
        dueDate
        priceExclVat
        priceInclVat
        invoiceRows {
          id
          sageId
          articleNumber
          description
          quantity
          netPriceExclVat
          grossPriceExclVat
        }
        status
        invoiceType
        paymentCondition
        orderReferences
        orderReferenceMessage
      }
      connectionType
    }
    invoices {
      id
      invoiceDate
      buyer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      deliveryAddress
      dueDate
      priceExclVat
      priceInclVat
      invoiceRows {
        id
        sageId
        articleNumber
        description
        quantity
        netPriceExclVat
        grossPriceExclVat
      }
      status
      invoiceType
      paymentCondition
      orderReferences
      orderReferenceMessage
    }
    connectionType
  }
}
    `;

/**
 * __useOrderItemByIdQuery__
 *
 * To run a query within a React component, call `useOrderItemByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderItemByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderItemByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOrderItemByIdQuery(baseOptions: Apollo.QueryHookOptions<OrderItemByIdQuery, OrderItemByIdQueryVariables>) {
        return Apollo.useQuery<OrderItemByIdQuery, OrderItemByIdQueryVariables>(OrderItemByIdDocument, baseOptions);
      }
export function useOrderItemByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderItemByIdQuery, OrderItemByIdQueryVariables>) {
          return Apollo.useLazyQuery<OrderItemByIdQuery, OrderItemByIdQueryVariables>(OrderItemByIdDocument, baseOptions);
        }
export type OrderItemByIdQueryHookResult = ReturnType<typeof useOrderItemByIdQuery>;
export type OrderItemByIdLazyQueryHookResult = ReturnType<typeof useOrderItemByIdLazyQuery>;
export type OrderItemByIdQueryResult = Apollo.QueryResult<OrderItemByIdQuery, OrderItemByIdQueryVariables>;
export const OrderItemsByOrderDocument = gql`
    query OrderItemsByOrder($orderId: String!, $filter: BaseFilter, $sorting: [BaseSorting!]) {
  orderItemsByOrder(orderId: $orderId, filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      vehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      fee {
        id
        name
        articleNumber
        price
        vat
        discountedPrice
        createdAt
        updatedAt
        isAllowedOnVehicles
        active
      }
      replacedProduct {
        id
        name
        articleNumber
      }
      deliveredQuantity
      freeQuantity
      detailedQuantities {
        processing
        allocated
        blocked
        delivered
        invoiced
      }
      remark
      order {
        id
        serialNumber
        externalId
        status
        invoice
        items {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        products {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        fees {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        createdAt
        invoicedAt
        referenceUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        total
        vat
        totalQuantity
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        deliveryType
        address
        postalCode
        postalCity
        partialDelivery
        orderReferenceMessage
        externallyFinanced
      }
      status
      finalPrice
      unitPrice
      stockArrivalDate
      connection {
        id
        vehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        deliveredQuantity
        freeQuantity
        detailedQuantities {
          processing
          allocated
          blocked
          delivered
          invoiced
        }
        remark
        order {
          id
          serialNumber
          externalId
          status
          invoice
          createdAt
          invoicedAt
          total
          vat
          totalQuantity
          deliveryType
          address
          postalCode
          postalCity
          partialDelivery
          orderReferenceMessage
          externallyFinanced
        }
        status
        finalPrice
        unitPrice
        stockArrivalDate
        connection {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        connectionType
      }
      invoices {
        id
        invoiceDate
        buyer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        deliveryAddress
        dueDate
        priceExclVat
        priceInclVat
        invoiceRows {
          id
          sageId
          articleNumber
          description
          quantity
          netPriceExclVat
          grossPriceExclVat
        }
        status
        invoiceType
        paymentCondition
        orderReferences
        orderReferenceMessage
      }
      connectionType
    }
  }
}
    `;

/**
 * __useOrderItemsByOrderQuery__
 *
 * To run a query within a React component, call `useOrderItemsByOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderItemsByOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrderItemsByOrderQuery({
 *   variables: {
 *      orderId: // value for 'orderId'
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useOrderItemsByOrderQuery(baseOptions: Apollo.QueryHookOptions<OrderItemsByOrderQuery, OrderItemsByOrderQueryVariables>) {
        return Apollo.useQuery<OrderItemsByOrderQuery, OrderItemsByOrderQueryVariables>(OrderItemsByOrderDocument, baseOptions);
      }
export function useOrderItemsByOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrderItemsByOrderQuery, OrderItemsByOrderQueryVariables>) {
          return Apollo.useLazyQuery<OrderItemsByOrderQuery, OrderItemsByOrderQueryVariables>(OrderItemsByOrderDocument, baseOptions);
        }
export type OrderItemsByOrderQueryHookResult = ReturnType<typeof useOrderItemsByOrderQuery>;
export type OrderItemsByOrderLazyQueryHookResult = ReturnType<typeof useOrderItemsByOrderLazyQuery>;
export type OrderItemsByOrderQueryResult = Apollo.QueryResult<OrderItemsByOrderQuery, OrderItemsByOrderQueryVariables>;
export const StockVehiclesDocument = gql`
    query StockVehicles($filter: WebshopFilter, $sorting: [BaseSorting!]) {
  stockVehicles(filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      sageId
      name
      brand {
        id
        externalId
        name
      }
      vehicleType
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      color
      colorCode
      createdAt
      price
      vat
      discountedPrice
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      equipments {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      articleNumber
      factoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      vehicleCampaign
      active
    }
  }
}
    `;

/**
 * __useStockVehiclesQuery__
 *
 * To run a query within a React component, call `useStockVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockVehiclesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useStockVehiclesQuery(baseOptions?: Apollo.QueryHookOptions<StockVehiclesQuery, StockVehiclesQueryVariables>) {
        return Apollo.useQuery<StockVehiclesQuery, StockVehiclesQueryVariables>(StockVehiclesDocument, baseOptions);
      }
export function useStockVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StockVehiclesQuery, StockVehiclesQueryVariables>) {
          return Apollo.useLazyQuery<StockVehiclesQuery, StockVehiclesQueryVariables>(StockVehiclesDocument, baseOptions);
        }
export type StockVehiclesQueryHookResult = ReturnType<typeof useStockVehiclesQuery>;
export type StockVehiclesLazyQueryHookResult = ReturnType<typeof useStockVehiclesLazyQuery>;
export type StockVehiclesQueryResult = Apollo.QueryResult<StockVehiclesQuery, StockVehiclesQueryVariables>;
export const StockVehicleByIdDocument = gql`
    query StockVehicleById($id: String!) {
  stockVehicleById(id: $id) {
    id
    sageId
    name
    brand {
      id
      externalId
      name
    }
    vehicleType
    modelSeries {
      id
      name
      brand {
        id
        externalId
        name
      }
      models {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
    }
    model {
      id
      name
      description
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
    }
    color
    colorCode
    createdAt
    price
    vat
    discountedPrice
    thumbnail {
      id
      name
      path
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      createdAt
    }
    images {
      id
      name
      path
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      createdAt
    }
    stock {
      warehouse
      atRetailer
      incoming
      status
      incomingDeliveries {
        deliveryDate
        quantity
      }
      updatedAt
    }
    equipments {
      id
      name
      articleNumber
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      vehicleType
      price
      vat
      discountedPrice
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      brand {
        id
        externalId
        name
      }
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      createdAt
      vehicleCampaign
      remark
      active
      equipmentGroup {
        id
        name
        externalId
      }
    }
    articleNumber
    factoryOptions {
      id
      description
      factoryOptionType {
        id
        description
      }
    }
    vehicleCampaign
    active
  }
}
    `;

/**
 * __useStockVehicleByIdQuery__
 *
 * To run a query within a React component, call `useStockVehicleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useStockVehicleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStockVehicleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStockVehicleByIdQuery(baseOptions: Apollo.QueryHookOptions<StockVehicleByIdQuery, StockVehicleByIdQueryVariables>) {
        return Apollo.useQuery<StockVehicleByIdQuery, StockVehicleByIdQueryVariables>(StockVehicleByIdDocument, baseOptions);
      }
export function useStockVehicleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StockVehicleByIdQuery, StockVehicleByIdQueryVariables>) {
          return Apollo.useLazyQuery<StockVehicleByIdQuery, StockVehicleByIdQueryVariables>(StockVehicleByIdDocument, baseOptions);
        }
export type StockVehicleByIdQueryHookResult = ReturnType<typeof useStockVehicleByIdQuery>;
export type StockVehicleByIdLazyQueryHookResult = ReturnType<typeof useStockVehicleByIdLazyQuery>;
export type StockVehicleByIdQueryResult = Apollo.QueryResult<StockVehicleByIdQuery, StockVehicleByIdQueryVariables>;
export const EquipmentsDocument = gql`
    query Equipments($filter: WebshopFilter, $sorting: [BaseSorting!]) {
  equipments(filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      name
      articleNumber
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      vehicleType
      price
      vat
      discountedPrice
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      brand {
        id
        externalId
        name
      }
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      createdAt
      vehicleCampaign
      remark
      active
      equipmentGroup {
        id
        name
        externalId
      }
    }
  }
}
    `;

/**
 * __useEquipmentsQuery__
 *
 * To run a query within a React component, call `useEquipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useEquipmentsQuery(baseOptions?: Apollo.QueryHookOptions<EquipmentsQuery, EquipmentsQueryVariables>) {
        return Apollo.useQuery<EquipmentsQuery, EquipmentsQueryVariables>(EquipmentsDocument, baseOptions);
      }
export function useEquipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EquipmentsQuery, EquipmentsQueryVariables>) {
          return Apollo.useLazyQuery<EquipmentsQuery, EquipmentsQueryVariables>(EquipmentsDocument, baseOptions);
        }
export type EquipmentsQueryHookResult = ReturnType<typeof useEquipmentsQuery>;
export type EquipmentsLazyQueryHookResult = ReturnType<typeof useEquipmentsLazyQuery>;
export type EquipmentsQueryResult = Apollo.QueryResult<EquipmentsQuery, EquipmentsQueryVariables>;
export const EquipmentByIdDocument = gql`
    query EquipmentById($id: String!) {
  equipmentById(id: $id) {
    id
    name
    articleNumber
    modelSeries {
      id
      name
      brand {
        id
        externalId
        name
      }
      models {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
    }
    model {
      id
      name
      description
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
    }
    vehicleType
    price
    vat
    discountedPrice
    stock {
      warehouse
      atRetailer
      incoming
      status
      incomingDeliveries {
        deliveryDate
        quantity
      }
      updatedAt
    }
    brand {
      id
      externalId
      name
    }
    thumbnail {
      id
      name
      path
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      createdAt
    }
    images {
      id
      name
      path
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      createdAt
    }
    createdAt
    vehicleCampaign
    remark
    active
    equipmentGroup {
      id
      name
      externalId
    }
  }
}
    `;

/**
 * __useEquipmentByIdQuery__
 *
 * To run a query within a React component, call `useEquipmentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useEquipmentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEquipmentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEquipmentByIdQuery(baseOptions: Apollo.QueryHookOptions<EquipmentByIdQuery, EquipmentByIdQueryVariables>) {
        return Apollo.useQuery<EquipmentByIdQuery, EquipmentByIdQueryVariables>(EquipmentByIdDocument, baseOptions);
      }
export function useEquipmentByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EquipmentByIdQuery, EquipmentByIdQueryVariables>) {
          return Apollo.useLazyQuery<EquipmentByIdQuery, EquipmentByIdQueryVariables>(EquipmentByIdDocument, baseOptions);
        }
export type EquipmentByIdQueryHookResult = ReturnType<typeof useEquipmentByIdQuery>;
export type EquipmentByIdLazyQueryHookResult = ReturnType<typeof useEquipmentByIdLazyQuery>;
export type EquipmentByIdQueryResult = Apollo.QueryResult<EquipmentByIdQuery, EquipmentByIdQueryVariables>;
export const CampaignItemsDocument = gql`
    query CampaignItems($filter: BaseFilter) {
  campaignItems(filter: $filter) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      title
      articleNumber
      price
      discountedPrice
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      type
      vehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
    }
  }
}
    `;

/**
 * __useCampaignItemsQuery__
 *
 * To run a query within a React component, call `useCampaignItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCampaignItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCampaignItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCampaignItemsQuery(baseOptions?: Apollo.QueryHookOptions<CampaignItemsQuery, CampaignItemsQueryVariables>) {
        return Apollo.useQuery<CampaignItemsQuery, CampaignItemsQueryVariables>(CampaignItemsDocument, baseOptions);
      }
export function useCampaignItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CampaignItemsQuery, CampaignItemsQueryVariables>) {
          return Apollo.useLazyQuery<CampaignItemsQuery, CampaignItemsQueryVariables>(CampaignItemsDocument, baseOptions);
        }
export type CampaignItemsQueryHookResult = ReturnType<typeof useCampaignItemsQuery>;
export type CampaignItemsLazyQueryHookResult = ReturnType<typeof useCampaignItemsLazyQuery>;
export type CampaignItemsQueryResult = Apollo.QueryResult<CampaignItemsQuery, CampaignItemsQueryVariables>;
export const RelatedEquipmentsDocument = gql`
    query RelatedEquipments($id: String!) {
  relatedEquipments(id: $id) {
    campaigns {
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
    }
    relatedEquipments {
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
    }
  }
}
    `;

/**
 * __useRelatedEquipmentsQuery__
 *
 * To run a query within a React component, call `useRelatedEquipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelatedEquipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelatedEquipmentsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRelatedEquipmentsQuery(baseOptions: Apollo.QueryHookOptions<RelatedEquipmentsQuery, RelatedEquipmentsQueryVariables>) {
        return Apollo.useQuery<RelatedEquipmentsQuery, RelatedEquipmentsQueryVariables>(RelatedEquipmentsDocument, baseOptions);
      }
export function useRelatedEquipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RelatedEquipmentsQuery, RelatedEquipmentsQueryVariables>) {
          return Apollo.useLazyQuery<RelatedEquipmentsQuery, RelatedEquipmentsQueryVariables>(RelatedEquipmentsDocument, baseOptions);
        }
export type RelatedEquipmentsQueryHookResult = ReturnType<typeof useRelatedEquipmentsQuery>;
export type RelatedEquipmentsLazyQueryHookResult = ReturnType<typeof useRelatedEquipmentsLazyQuery>;
export type RelatedEquipmentsQueryResult = Apollo.QueryResult<RelatedEquipmentsQuery, RelatedEquipmentsQueryVariables>;
export const GetWebshopFiltersDocument = gql`
    query GetWebshopFilters($filter: WebshopFilter) {
  getWebshopFilters(filter: $filter) {
    vehicleTypes
    makes {
      id
      externalId
      name
    }
    modelSeries {
      id
      name
      brand {
        id
        externalId
        name
      }
      models {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
    }
    models {
      id
      name
      description
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
    }
    colors {
      id
      description
      factoryOptionType {
        id
        description
      }
    }
    equipmentGroups {
      id
      name
      externalId
    }
    stockStatus
  }
}
    `;

/**
 * __useGetWebshopFiltersQuery__
 *
 * To run a query within a React component, call `useGetWebshopFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebshopFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebshopFiltersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetWebshopFiltersQuery(baseOptions?: Apollo.QueryHookOptions<GetWebshopFiltersQuery, GetWebshopFiltersQueryVariables>) {
        return Apollo.useQuery<GetWebshopFiltersQuery, GetWebshopFiltersQueryVariables>(GetWebshopFiltersDocument, baseOptions);
      }
export function useGetWebshopFiltersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWebshopFiltersQuery, GetWebshopFiltersQueryVariables>) {
          return Apollo.useLazyQuery<GetWebshopFiltersQuery, GetWebshopFiltersQueryVariables>(GetWebshopFiltersDocument, baseOptions);
        }
export type GetWebshopFiltersQueryHookResult = ReturnType<typeof useGetWebshopFiltersQuery>;
export type GetWebshopFiltersLazyQueryHookResult = ReturnType<typeof useGetWebshopFiltersLazyQuery>;
export type GetWebshopFiltersQueryResult = Apollo.QueryResult<GetWebshopFiltersQuery, GetWebshopFiltersQueryVariables>;
export const BillOfMaterialDocument = gql`
    query BillOfMaterial($filter: BillOfMaterialsFilter, $sorting: [BaseSorting!]) {
  billOfMaterial(filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      articleNumber
      active
      bomCode
      description
      fromDate
      toDate
      bomRows {
        id
        linkQuantity
        fromDate
        toDate
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
      }
      price
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      discountedPrice
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      vehicleCampaign
      brand {
        id
        externalId
        name
      }
      equipmentGroup {
        id
        name
        externalId
      }
    }
  }
}
    `;

/**
 * __useBillOfMaterialQuery__
 *
 * To run a query within a React component, call `useBillOfMaterialQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillOfMaterialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillOfMaterialQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useBillOfMaterialQuery(baseOptions?: Apollo.QueryHookOptions<BillOfMaterialQuery, BillOfMaterialQueryVariables>) {
        return Apollo.useQuery<BillOfMaterialQuery, BillOfMaterialQueryVariables>(BillOfMaterialDocument, baseOptions);
      }
export function useBillOfMaterialLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillOfMaterialQuery, BillOfMaterialQueryVariables>) {
          return Apollo.useLazyQuery<BillOfMaterialQuery, BillOfMaterialQueryVariables>(BillOfMaterialDocument, baseOptions);
        }
export type BillOfMaterialQueryHookResult = ReturnType<typeof useBillOfMaterialQuery>;
export type BillOfMaterialLazyQueryHookResult = ReturnType<typeof useBillOfMaterialLazyQuery>;
export type BillOfMaterialQueryResult = Apollo.QueryResult<BillOfMaterialQuery, BillOfMaterialQueryVariables>;
export const BillOfMaterialByIdDocument = gql`
    query BillOfMaterialById($id: String!) {
  billOfMaterialById(id: $id) {
    id
    articleNumber
    active
    bomCode
    description
    fromDate
    toDate
    bomRows {
      id
      linkQuantity
      fromDate
      toDate
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
    }
    price
    stock {
      warehouse
      atRetailer
      incoming
      status
      incomingDeliveries {
        deliveryDate
        quantity
      }
      updatedAt
    }
    discountedPrice
    images {
      id
      name
      path
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      createdAt
    }
    thumbnail {
      id
      name
      path
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      createdAt
    }
    vehicleCampaign
    brand {
      id
      externalId
      name
    }
    equipmentGroup {
      id
      name
      externalId
    }
  }
}
    `;

/**
 * __useBillOfMaterialByIdQuery__
 *
 * To run a query within a React component, call `useBillOfMaterialByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBillOfMaterialByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBillOfMaterialByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBillOfMaterialByIdQuery(baseOptions: Apollo.QueryHookOptions<BillOfMaterialByIdQuery, BillOfMaterialByIdQueryVariables>) {
        return Apollo.useQuery<BillOfMaterialByIdQuery, BillOfMaterialByIdQueryVariables>(BillOfMaterialByIdDocument, baseOptions);
      }
export function useBillOfMaterialByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BillOfMaterialByIdQuery, BillOfMaterialByIdQueryVariables>) {
          return Apollo.useLazyQuery<BillOfMaterialByIdQuery, BillOfMaterialByIdQueryVariables>(BillOfMaterialByIdDocument, baseOptions);
        }
export type BillOfMaterialByIdQueryHookResult = ReturnType<typeof useBillOfMaterialByIdQuery>;
export type BillOfMaterialByIdLazyQueryHookResult = ReturnType<typeof useBillOfMaterialByIdLazyQuery>;
export type BillOfMaterialByIdQueryResult = Apollo.QueryResult<BillOfMaterialByIdQuery, BillOfMaterialByIdQueryVariables>;
export const ShoppingListByIdDocument = gql`
    query ShoppingListById($id: String!) {
  shoppingListById(id: $id) {
    id
    name
    remark
    items {
      id
      amount
      vehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      isCampaignEquipment
      stockVehicleProductCount
      relatedVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;

/**
 * __useShoppingListByIdQuery__
 *
 * To run a query within a React component, call `useShoppingListByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useShoppingListByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShoppingListByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useShoppingListByIdQuery(baseOptions: Apollo.QueryHookOptions<ShoppingListByIdQuery, ShoppingListByIdQueryVariables>) {
        return Apollo.useQuery<ShoppingListByIdQuery, ShoppingListByIdQueryVariables>(ShoppingListByIdDocument, baseOptions);
      }
export function useShoppingListByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShoppingListByIdQuery, ShoppingListByIdQueryVariables>) {
          return Apollo.useLazyQuery<ShoppingListByIdQuery, ShoppingListByIdQueryVariables>(ShoppingListByIdDocument, baseOptions);
        }
export type ShoppingListByIdQueryHookResult = ReturnType<typeof useShoppingListByIdQuery>;
export type ShoppingListByIdLazyQueryHookResult = ReturnType<typeof useShoppingListByIdLazyQuery>;
export type ShoppingListByIdQueryResult = Apollo.QueryResult<ShoppingListByIdQuery, ShoppingListByIdQueryVariables>;
export const ShoppingListsDocument = gql`
    query ShoppingLists($filter: BaseFilter, $sorting: [BaseSorting!], $onBehalfOfRetailerId: Int) {
  shoppingLists(
    filter: $filter
    sorting: $sorting
    onBehalfOfRetailerId: $onBehalfOfRetailerId
  ) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      name
      remark
      items {
        id
        amount
        vehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        isCampaignEquipment
        stockVehicleProductCount
        relatedVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
}
    `;

/**
 * __useShoppingListsQuery__
 *
 * To run a query within a React component, call `useShoppingListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useShoppingListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShoppingListsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *      onBehalfOfRetailerId: // value for 'onBehalfOfRetailerId'
 *   },
 * });
 */
export function useShoppingListsQuery(baseOptions?: Apollo.QueryHookOptions<ShoppingListsQuery, ShoppingListsQueryVariables>) {
        return Apollo.useQuery<ShoppingListsQuery, ShoppingListsQueryVariables>(ShoppingListsDocument, baseOptions);
      }
export function useShoppingListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShoppingListsQuery, ShoppingListsQueryVariables>) {
          return Apollo.useLazyQuery<ShoppingListsQuery, ShoppingListsQueryVariables>(ShoppingListsDocument, baseOptions);
        }
export type ShoppingListsQueryHookResult = ReturnType<typeof useShoppingListsQuery>;
export type ShoppingListsLazyQueryHookResult = ReturnType<typeof useShoppingListsLazyQuery>;
export type ShoppingListsQueryResult = Apollo.QueryResult<ShoppingListsQuery, ShoppingListsQueryVariables>;
export const VehicleDocument = gql`
    query Vehicle($input: VehicleInput) {
  vehicle(input: $input) {
    id
    stockVehicle {
      id
      sageId
      name
      brand {
        id
        externalId
        name
      }
      vehicleType
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      color
      colorCode
      createdAt
      price
      vat
      discountedPrice
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      equipments {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      articleNumber
      factoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      vehicleCampaign
      active
    }
    vehicleIdentificationNumber
    registrationNumber
    brand {
      id
      externalId
      name
    }
    modelSeries {
      id
      name
      brand {
        id
        externalId
        name
      }
      models {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
    }
    model {
      id
      name
      description
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
    }
    startedAt
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    showDetailedInformation
    typeOfInstallation
    nonColorFactoryOptions {
      id
      description
      factoryOptionType {
        id
        description
      }
    }
    createdAt
    updatedAt
    engineNumber
    factoryCode
    status
    firstArrivedToSweden
    producedAt
    firstRegisteredAt
    replacementText
    showBfiAndKey
    warranties {
      id
      status
      declineReason
      declineComment
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      customerRemark
      workshopRemark
      rows {
        id
        quantity
        originalQuantity
        price
        percentage
        originalPercentage
        total
        originalTotal
        referenceName
        description
        status
        complementReason
        complementComment
        declineReason
        declineComment
        type
        originalHours
        hours
        handledBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        ... on OperationRow {
          operationCode {
            id
            code
            category
            description
            time
          }
        }
        ... on ExternRow {
          purchaseRefersType
          receiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on ArticleRow {
          orderItem {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          legacyArticleNumber
          legacyOrderNumber
        }
        ... on LegacyRow {
          code
          originalComp
          comp
          originalPrice
          legacyType
        }
      }
      attachements {
        id
        name
        path
        mimeType
        isImage
        createdAt
      }
      registeredAt
      createdAt
      repairedAt
      sumRequested
      sumCredited
      type
      internalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      externalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      damageImage {
        id
        name
        path
        createdAt
      }
      shippingCost
      ... on VehicleWarranty {
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        meterReadingImage {
          id
          name
          path
          createdAt
        }
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        defectPartNumber
        defectPartTypeCode
        symptomCode
        operationalizedFrom {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
      }
      ... on GoodWillWarranty {
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        operationalizedTo {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
      }
      ... on PartWarranty {
        purchaseReceiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on LegacyWarranty {
        legacyClaimNumber
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        legacyDealer
        causeOfDamage
        warrantyStartedAt
        receivedAt
      }
    }
  }
}
    `;

/**
 * __useVehicleQuery__
 *
 * To run a query within a React component, call `useVehicleQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVehicleQuery(baseOptions?: Apollo.QueryHookOptions<VehicleQuery, VehicleQueryVariables>) {
        return Apollo.useQuery<VehicleQuery, VehicleQueryVariables>(VehicleDocument, baseOptions);
      }
export function useVehicleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleQuery, VehicleQueryVariables>) {
          return Apollo.useLazyQuery<VehicleQuery, VehicleQueryVariables>(VehicleDocument, baseOptions);
        }
export type VehicleQueryHookResult = ReturnType<typeof useVehicleQuery>;
export type VehicleLazyQueryHookResult = ReturnType<typeof useVehicleLazyQuery>;
export type VehicleQueryResult = Apollo.QueryResult<VehicleQuery, VehicleQueryVariables>;
export const ConsignmentVehiclesDocument = gql`
    query ConsignmentVehicles($filter: BaseFilter, $sorting: [BaseSorting!]) {
  consignmentVehicles(filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      vehicleIdentificationNumber
      registrationNumber
      brand {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      startedAt
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      showDetailedInformation
      typeOfInstallation
      nonColorFactoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      createdAt
      updatedAt
      engineNumber
      factoryCode
      status
      firstArrivedToSweden
      producedAt
      firstRegisteredAt
      replacementText
      showBfiAndKey
      warranties {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          ... on ExternRow {
            purchaseRefersType
          }
          ... on ArticleRow {
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        ... on VehicleWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
        ... on GoodWillWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
        ... on PartWarranty {
          purchaseReceiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on LegacyWarranty {
          legacyClaimNumber
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          legacyDealer
          causeOfDamage
          warrantyStartedAt
          receivedAt
        }
      }
    }
  }
}
    `;

/**
 * __useConsignmentVehiclesQuery__
 *
 * To run a query within a React component, call `useConsignmentVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsignmentVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsignmentVehiclesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useConsignmentVehiclesQuery(baseOptions?: Apollo.QueryHookOptions<ConsignmentVehiclesQuery, ConsignmentVehiclesQueryVariables>) {
        return Apollo.useQuery<ConsignmentVehiclesQuery, ConsignmentVehiclesQueryVariables>(ConsignmentVehiclesDocument, baseOptions);
      }
export function useConsignmentVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsignmentVehiclesQuery, ConsignmentVehiclesQueryVariables>) {
          return Apollo.useLazyQuery<ConsignmentVehiclesQuery, ConsignmentVehiclesQueryVariables>(ConsignmentVehiclesDocument, baseOptions);
        }
export type ConsignmentVehiclesQueryHookResult = ReturnType<typeof useConsignmentVehiclesQuery>;
export type ConsignmentVehiclesLazyQueryHookResult = ReturnType<typeof useConsignmentVehiclesLazyQuery>;
export type ConsignmentVehiclesQueryResult = Apollo.QueryResult<ConsignmentVehiclesQuery, ConsignmentVehiclesQueryVariables>;
export const ConsignmentVehicleByIdDocument = gql`
    query ConsignmentVehicleById($id: String!) {
  consignmentVehicleById(id: $id) {
    id
    stockVehicle {
      id
      sageId
      name
      brand {
        id
        externalId
        name
      }
      vehicleType
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      color
      colorCode
      createdAt
      price
      vat
      discountedPrice
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      equipments {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      articleNumber
      factoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      vehicleCampaign
      active
    }
    vehicleIdentificationNumber
    registrationNumber
    brand {
      id
      externalId
      name
    }
    modelSeries {
      id
      name
      brand {
        id
        externalId
        name
      }
      models {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
    }
    model {
      id
      name
      description
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
    }
    startedAt
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    showDetailedInformation
    typeOfInstallation
    nonColorFactoryOptions {
      id
      description
      factoryOptionType {
        id
        description
      }
    }
    createdAt
    updatedAt
    engineNumber
    factoryCode
    status
    firstArrivedToSweden
    producedAt
    firstRegisteredAt
    replacementText
    showBfiAndKey
    warranties {
      id
      status
      declineReason
      declineComment
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      customerRemark
      workshopRemark
      rows {
        id
        quantity
        originalQuantity
        price
        percentage
        originalPercentage
        total
        originalTotal
        referenceName
        description
        status
        complementReason
        complementComment
        declineReason
        declineComment
        type
        originalHours
        hours
        handledBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        ... on OperationRow {
          operationCode {
            id
            code
            category
            description
            time
          }
        }
        ... on ExternRow {
          purchaseRefersType
          receiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on ArticleRow {
          orderItem {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          legacyArticleNumber
          legacyOrderNumber
        }
        ... on LegacyRow {
          code
          originalComp
          comp
          originalPrice
          legacyType
        }
      }
      attachements {
        id
        name
        path
        mimeType
        isImage
        createdAt
      }
      registeredAt
      createdAt
      repairedAt
      sumRequested
      sumCredited
      type
      internalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      externalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      damageImage {
        id
        name
        path
        createdAt
      }
      shippingCost
      ... on VehicleWarranty {
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        meterReadingImage {
          id
          name
          path
          createdAt
        }
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        defectPartNumber
        defectPartTypeCode
        symptomCode
        operationalizedFrom {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
      }
      ... on GoodWillWarranty {
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        operationalizedTo {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
      }
      ... on PartWarranty {
        purchaseReceiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on LegacyWarranty {
        legacyClaimNumber
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        legacyDealer
        causeOfDamage
        warrantyStartedAt
        receivedAt
      }
    }
  }
}
    `;

/**
 * __useConsignmentVehicleByIdQuery__
 *
 * To run a query within a React component, call `useConsignmentVehicleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useConsignmentVehicleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConsignmentVehicleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConsignmentVehicleByIdQuery(baseOptions: Apollo.QueryHookOptions<ConsignmentVehicleByIdQuery, ConsignmentVehicleByIdQueryVariables>) {
        return Apollo.useQuery<ConsignmentVehicleByIdQuery, ConsignmentVehicleByIdQueryVariables>(ConsignmentVehicleByIdDocument, baseOptions);
      }
export function useConsignmentVehicleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConsignmentVehicleByIdQuery, ConsignmentVehicleByIdQueryVariables>) {
          return Apollo.useLazyQuery<ConsignmentVehicleByIdQuery, ConsignmentVehicleByIdQueryVariables>(ConsignmentVehicleByIdDocument, baseOptions);
        }
export type ConsignmentVehicleByIdQueryHookResult = ReturnType<typeof useConsignmentVehicleByIdQuery>;
export type ConsignmentVehicleByIdLazyQueryHookResult = ReturnType<typeof useConsignmentVehicleByIdLazyQuery>;
export type ConsignmentVehicleByIdQueryResult = Apollo.QueryResult<ConsignmentVehicleByIdQuery, ConsignmentVehicleByIdQueryVariables>;
export const ExhibitionVehiclesDocument = gql`
    query ExhibitionVehicles($filter: BaseFilter, $sorting: [BaseSorting!]) {
  exhibitionVehicles(filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      vehicleIdentificationNumber
      registrationNumber
      brand {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      startedAt
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      showDetailedInformation
      typeOfInstallation
      nonColorFactoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      createdAt
      updatedAt
      engineNumber
      factoryCode
      status
      firstArrivedToSweden
      producedAt
      firstRegisteredAt
      replacementText
      showBfiAndKey
      warranties {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          ... on ExternRow {
            purchaseRefersType
          }
          ... on ArticleRow {
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        ... on VehicleWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
        ... on GoodWillWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
        ... on PartWarranty {
          purchaseReceiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on LegacyWarranty {
          legacyClaimNumber
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          legacyDealer
          causeOfDamage
          warrantyStartedAt
          receivedAt
        }
      }
    }
  }
}
    `;

/**
 * __useExhibitionVehiclesQuery__
 *
 * To run a query within a React component, call `useExhibitionVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useExhibitionVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExhibitionVehiclesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useExhibitionVehiclesQuery(baseOptions?: Apollo.QueryHookOptions<ExhibitionVehiclesQuery, ExhibitionVehiclesQueryVariables>) {
        return Apollo.useQuery<ExhibitionVehiclesQuery, ExhibitionVehiclesQueryVariables>(ExhibitionVehiclesDocument, baseOptions);
      }
export function useExhibitionVehiclesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExhibitionVehiclesQuery, ExhibitionVehiclesQueryVariables>) {
          return Apollo.useLazyQuery<ExhibitionVehiclesQuery, ExhibitionVehiclesQueryVariables>(ExhibitionVehiclesDocument, baseOptions);
        }
export type ExhibitionVehiclesQueryHookResult = ReturnType<typeof useExhibitionVehiclesQuery>;
export type ExhibitionVehiclesLazyQueryHookResult = ReturnType<typeof useExhibitionVehiclesLazyQuery>;
export type ExhibitionVehiclesQueryResult = Apollo.QueryResult<ExhibitionVehiclesQuery, ExhibitionVehiclesQueryVariables>;
export const ExhibitionVehicleByIdDocument = gql`
    query ExhibitionVehicleById($id: String!) {
  exhibitionVehicleById(id: $id) {
    id
    stockVehicle {
      id
      sageId
      name
      brand {
        id
        externalId
        name
      }
      vehicleType
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      color
      colorCode
      createdAt
      price
      vat
      discountedPrice
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      equipments {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      articleNumber
      factoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      vehicleCampaign
      active
    }
    vehicleIdentificationNumber
    registrationNumber
    brand {
      id
      externalId
      name
    }
    modelSeries {
      id
      name
      brand {
        id
        externalId
        name
      }
      models {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
    }
    model {
      id
      name
      description
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
    }
    startedAt
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    showDetailedInformation
    typeOfInstallation
    nonColorFactoryOptions {
      id
      description
      factoryOptionType {
        id
        description
      }
    }
    createdAt
    updatedAt
    engineNumber
    factoryCode
    status
    firstArrivedToSweden
    producedAt
    firstRegisteredAt
    replacementText
    showBfiAndKey
    warranties {
      id
      status
      declineReason
      declineComment
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      customerRemark
      workshopRemark
      rows {
        id
        quantity
        originalQuantity
        price
        percentage
        originalPercentage
        total
        originalTotal
        referenceName
        description
        status
        complementReason
        complementComment
        declineReason
        declineComment
        type
        originalHours
        hours
        handledBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        ... on OperationRow {
          operationCode {
            id
            code
            category
            description
            time
          }
        }
        ... on ExternRow {
          purchaseRefersType
          receiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on ArticleRow {
          orderItem {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          legacyArticleNumber
          legacyOrderNumber
        }
        ... on LegacyRow {
          code
          originalComp
          comp
          originalPrice
          legacyType
        }
      }
      attachements {
        id
        name
        path
        mimeType
        isImage
        createdAt
      }
      registeredAt
      createdAt
      repairedAt
      sumRequested
      sumCredited
      type
      internalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      externalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      damageImage {
        id
        name
        path
        createdAt
      }
      shippingCost
      ... on VehicleWarranty {
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        meterReadingImage {
          id
          name
          path
          createdAt
        }
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        defectPartNumber
        defectPartTypeCode
        symptomCode
        operationalizedFrom {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
      }
      ... on GoodWillWarranty {
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        operationalizedTo {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
      }
      ... on PartWarranty {
        purchaseReceiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on LegacyWarranty {
        legacyClaimNumber
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        legacyDealer
        causeOfDamage
        warrantyStartedAt
        receivedAt
      }
    }
  }
}
    `;

/**
 * __useExhibitionVehicleByIdQuery__
 *
 * To run a query within a React component, call `useExhibitionVehicleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useExhibitionVehicleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExhibitionVehicleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExhibitionVehicleByIdQuery(baseOptions: Apollo.QueryHookOptions<ExhibitionVehicleByIdQuery, ExhibitionVehicleByIdQueryVariables>) {
        return Apollo.useQuery<ExhibitionVehicleByIdQuery, ExhibitionVehicleByIdQueryVariables>(ExhibitionVehicleByIdDocument, baseOptions);
      }
export function useExhibitionVehicleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExhibitionVehicleByIdQuery, ExhibitionVehicleByIdQueryVariables>) {
          return Apollo.useLazyQuery<ExhibitionVehicleByIdQuery, ExhibitionVehicleByIdQueryVariables>(ExhibitionVehicleByIdDocument, baseOptions);
        }
export type ExhibitionVehicleByIdQueryHookResult = ReturnType<typeof useExhibitionVehicleByIdQuery>;
export type ExhibitionVehicleByIdLazyQueryHookResult = ReturnType<typeof useExhibitionVehicleByIdLazyQuery>;
export type ExhibitionVehicleByIdQueryResult = Apollo.QueryResult<ExhibitionVehicleByIdQuery, ExhibitionVehicleByIdQueryVariables>;
export const ApiOrderByIdDocument = gql`
    query ApiOrderById($id: String!) {
  apiOrderById(id: $id) {
    id
    customerReference
    type
    orderDate
    deliveryContactNumber
    companyName
    payBy
    deliveryAddress
    email
    invoiceReference
    orderStatus
    deliveryStatus
    invoiceStatus
    creditStatus
    partialDelivery
    priceExcludingVAT
    priceIncludingVAT
    orderRows {
      productId
      description
      quantity
      priceExcludingVAT
      priceIncludingVAT
      requestedDeliverDate
      expectedDeliveryDate
      rowStatus
      tenderId
      closed
      closingReason
    }
  }
}
    `;

/**
 * __useApiOrderByIdQuery__
 *
 * To run a query within a React component, call `useApiOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiOrderByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiOrderByIdQuery(baseOptions: Apollo.QueryHookOptions<ApiOrderByIdQuery, ApiOrderByIdQueryVariables>) {
        return Apollo.useQuery<ApiOrderByIdQuery, ApiOrderByIdQueryVariables>(ApiOrderByIdDocument, baseOptions);
      }
export function useApiOrderByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiOrderByIdQuery, ApiOrderByIdQueryVariables>) {
          return Apollo.useLazyQuery<ApiOrderByIdQuery, ApiOrderByIdQueryVariables>(ApiOrderByIdDocument, baseOptions);
        }
export type ApiOrderByIdQueryHookResult = ReturnType<typeof useApiOrderByIdQuery>;
export type ApiOrderByIdLazyQueryHookResult = ReturnType<typeof useApiOrderByIdLazyQuery>;
export type ApiOrderByIdQueryResult = Apollo.QueryResult<ApiOrderByIdQuery, ApiOrderByIdQueryVariables>;
export const ApiOrdersDocument = gql`
    query ApiOrders {
  apiOrders {
    success
  }
}
    `;

/**
 * __useApiOrdersQuery__
 *
 * To run a query within a React component, call `useApiOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiOrdersQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiOrdersQuery(baseOptions?: Apollo.QueryHookOptions<ApiOrdersQuery, ApiOrdersQueryVariables>) {
        return Apollo.useQuery<ApiOrdersQuery, ApiOrdersQueryVariables>(ApiOrdersDocument, baseOptions);
      }
export function useApiOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiOrdersQuery, ApiOrdersQueryVariables>) {
          return Apollo.useLazyQuery<ApiOrdersQuery, ApiOrdersQueryVariables>(ApiOrdersDocument, baseOptions);
        }
export type ApiOrdersQueryHookResult = ReturnType<typeof useApiOrdersQuery>;
export type ApiOrdersLazyQueryHookResult = ReturnType<typeof useApiOrdersLazyQuery>;
export type ApiOrdersQueryResult = Apollo.QueryResult<ApiOrdersQuery, ApiOrdersQueryVariables>;
export const CustomerByIdDocument = gql`
    query CustomerById($id: String!) {
  customerById(id: $id) {
    id
    companyName
    address
    postalCode
    city
    country
    countryName
    telephoneNumber
    email
    organizationNumber
    contacts {
      contactCode
      firstname
      lastname
      contactCountry
      contactTelephone
      contactEmail
      contactRole
    }
    trademarks {
      trademark {
        id
        externalId
        name
      }
      trademarkId
      trademarkName
      accessToSell
      accessToWorkshop
      defaultHourlyPayment
    }
  }
}
    `;

/**
 * __useCustomerByIdQuery__
 *
 * To run a query within a React component, call `useCustomerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomerByIdQuery(baseOptions: Apollo.QueryHookOptions<CustomerByIdQuery, CustomerByIdQueryVariables>) {
        return Apollo.useQuery<CustomerByIdQuery, CustomerByIdQueryVariables>(CustomerByIdDocument, baseOptions);
      }
export function useCustomerByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomerByIdQuery, CustomerByIdQueryVariables>) {
          return Apollo.useLazyQuery<CustomerByIdQuery, CustomerByIdQueryVariables>(CustomerByIdDocument, baseOptions);
        }
export type CustomerByIdQueryHookResult = ReturnType<typeof useCustomerByIdQuery>;
export type CustomerByIdLazyQueryHookResult = ReturnType<typeof useCustomerByIdLazyQuery>;
export type CustomerByIdQueryResult = Apollo.QueryResult<CustomerByIdQuery, CustomerByIdQueryVariables>;
export const CustomersDocument = gql`
    query Customers {
  customers {
    success
  }
}
    `;

/**
 * __useCustomersQuery__
 *
 * To run a query within a React component, call `useCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomersQuery(baseOptions?: Apollo.QueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
        return Apollo.useQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, baseOptions);
      }
export function useCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomersQuery, CustomersQueryVariables>) {
          return Apollo.useLazyQuery<CustomersQuery, CustomersQueryVariables>(CustomersDocument, baseOptions);
        }
export type CustomersQueryHookResult = ReturnType<typeof useCustomersQuery>;
export type CustomersLazyQueryHookResult = ReturnType<typeof useCustomersLazyQuery>;
export type CustomersQueryResult = Apollo.QueryResult<CustomersQuery, CustomersQueryVariables>;
export const ApiProductByIdDocument = gql`
    query ApiProductById($id: String!) {
  apiProductById(id: $id) {
    id
    articleNumber
    category
    description
    status
    image
    make {
      id
      externalId
      name
    }
    type
    modelSeries
    model
    color
    colorCode
    stockUnits
    stockStatus
    alternativeProducts {
      type
      description
    }
  }
}
    `;

/**
 * __useApiProductByIdQuery__
 *
 * To run a query within a React component, call `useApiProductByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiProductByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiProductByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApiProductByIdQuery(baseOptions: Apollo.QueryHookOptions<ApiProductByIdQuery, ApiProductByIdQueryVariables>) {
        return Apollo.useQuery<ApiProductByIdQuery, ApiProductByIdQueryVariables>(ApiProductByIdDocument, baseOptions);
      }
export function useApiProductByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiProductByIdQuery, ApiProductByIdQueryVariables>) {
          return Apollo.useLazyQuery<ApiProductByIdQuery, ApiProductByIdQueryVariables>(ApiProductByIdDocument, baseOptions);
        }
export type ApiProductByIdQueryHookResult = ReturnType<typeof useApiProductByIdQuery>;
export type ApiProductByIdLazyQueryHookResult = ReturnType<typeof useApiProductByIdLazyQuery>;
export type ApiProductByIdQueryResult = Apollo.QueryResult<ApiProductByIdQuery, ApiProductByIdQueryVariables>;
export const ApiProductsDocument = gql`
    query ApiProducts {
  apiProducts {
    success
  }
}
    `;

/**
 * __useApiProductsQuery__
 *
 * To run a query within a React component, call `useApiProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiProductsQuery(baseOptions?: Apollo.QueryHookOptions<ApiProductsQuery, ApiProductsQueryVariables>) {
        return Apollo.useQuery<ApiProductsQuery, ApiProductsQueryVariables>(ApiProductsDocument, baseOptions);
      }
export function useApiProductsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiProductsQuery, ApiProductsQueryVariables>) {
          return Apollo.useLazyQuery<ApiProductsQuery, ApiProductsQueryVariables>(ApiProductsDocument, baseOptions);
        }
export type ApiProductsQueryHookResult = ReturnType<typeof useApiProductsQuery>;
export type ApiProductsLazyQueryHookResult = ReturnType<typeof useApiProductsLazyQuery>;
export type ApiProductsQueryResult = Apollo.QueryResult<ApiProductsQuery, ApiProductsQueryVariables>;
export const ApiModelSeriesDocument = gql`
    query ApiModelSeries {
  apiModelSeries {
    success
  }
}
    `;

/**
 * __useApiModelSeriesQuery__
 *
 * To run a query within a React component, call `useApiModelSeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiModelSeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiModelSeriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiModelSeriesQuery(baseOptions?: Apollo.QueryHookOptions<ApiModelSeriesQuery, ApiModelSeriesQueryVariables>) {
        return Apollo.useQuery<ApiModelSeriesQuery, ApiModelSeriesQueryVariables>(ApiModelSeriesDocument, baseOptions);
      }
export function useApiModelSeriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiModelSeriesQuery, ApiModelSeriesQueryVariables>) {
          return Apollo.useLazyQuery<ApiModelSeriesQuery, ApiModelSeriesQueryVariables>(ApiModelSeriesDocument, baseOptions);
        }
export type ApiModelSeriesQueryHookResult = ReturnType<typeof useApiModelSeriesQuery>;
export type ApiModelSeriesLazyQueryHookResult = ReturnType<typeof useApiModelSeriesLazyQuery>;
export type ApiModelSeriesQueryResult = Apollo.QueryResult<ApiModelSeriesQuery, ApiModelSeriesQueryVariables>;
export const ApiModelsDocument = gql`
    query ApiModels {
  apiModels {
    success
  }
}
    `;

/**
 * __useApiModelsQuery__
 *
 * To run a query within a React component, call `useApiModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiModelsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiModelsQuery(baseOptions?: Apollo.QueryHookOptions<ApiModelsQuery, ApiModelsQueryVariables>) {
        return Apollo.useQuery<ApiModelsQuery, ApiModelsQueryVariables>(ApiModelsDocument, baseOptions);
      }
export function useApiModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiModelsQuery, ApiModelsQueryVariables>) {
          return Apollo.useLazyQuery<ApiModelsQuery, ApiModelsQueryVariables>(ApiModelsDocument, baseOptions);
        }
export type ApiModelsQueryHookResult = ReturnType<typeof useApiModelsQuery>;
export type ApiModelsLazyQueryHookResult = ReturnType<typeof useApiModelsLazyQuery>;
export type ApiModelsQueryResult = Apollo.QueryResult<ApiModelsQuery, ApiModelsQueryVariables>;
export const ApiBrandsDocument = gql`
    query ApiBrands {
  apiBrands {
    success
  }
}
    `;

/**
 * __useApiBrandsQuery__
 *
 * To run a query within a React component, call `useApiBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiBrandsQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiBrandsQuery(baseOptions?: Apollo.QueryHookOptions<ApiBrandsQuery, ApiBrandsQueryVariables>) {
        return Apollo.useQuery<ApiBrandsQuery, ApiBrandsQueryVariables>(ApiBrandsDocument, baseOptions);
      }
export function useApiBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiBrandsQuery, ApiBrandsQueryVariables>) {
          return Apollo.useLazyQuery<ApiBrandsQuery, ApiBrandsQueryVariables>(ApiBrandsDocument, baseOptions);
        }
export type ApiBrandsQueryHookResult = ReturnType<typeof useApiBrandsQuery>;
export type ApiBrandsLazyQueryHookResult = ReturnType<typeof useApiBrandsLazyQuery>;
export type ApiBrandsQueryResult = Apollo.QueryResult<ApiBrandsQuery, ApiBrandsQueryVariables>;
export const ApiInvoicesDocument = gql`
    query ApiInvoices {
  apiInvoices {
    success
  }
}
    `;

/**
 * __useApiInvoicesQuery__
 *
 * To run a query within a React component, call `useApiInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiInvoicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<ApiInvoicesQuery, ApiInvoicesQueryVariables>) {
        return Apollo.useQuery<ApiInvoicesQuery, ApiInvoicesQueryVariables>(ApiInvoicesDocument, baseOptions);
      }
export function useApiInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiInvoicesQuery, ApiInvoicesQueryVariables>) {
          return Apollo.useLazyQuery<ApiInvoicesQuery, ApiInvoicesQueryVariables>(ApiInvoicesDocument, baseOptions);
        }
export type ApiInvoicesQueryHookResult = ReturnType<typeof useApiInvoicesQuery>;
export type ApiInvoicesLazyQueryHookResult = ReturnType<typeof useApiInvoicesLazyQuery>;
export type ApiInvoicesQueryResult = Apollo.QueryResult<ApiInvoicesQuery, ApiInvoicesQueryVariables>;
export const InvoicesDocument = gql`
    query Invoices($filter: InvoiceFilter, $sorting: [BaseSorting!]) {
  invoices(filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      invoiceDate
      buyer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      deliveryAddress
      dueDate
      priceExclVat
      priceInclVat
      invoiceRows {
        id
        sageId
        articleNumber
        description
        quantity
        netPriceExclVat
        grossPriceExclVat
      }
      status
      invoiceType
      paymentCondition
      orderReferences
      orderReferenceMessage
    }
  }
}
    `;

/**
 * __useInvoicesQuery__
 *
 * To run a query within a React component, call `useInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoicesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
        return Apollo.useQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, baseOptions);
      }
export function useInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoicesQuery, InvoicesQueryVariables>) {
          return Apollo.useLazyQuery<InvoicesQuery, InvoicesQueryVariables>(InvoicesDocument, baseOptions);
        }
export type InvoicesQueryHookResult = ReturnType<typeof useInvoicesQuery>;
export type InvoicesLazyQueryHookResult = ReturnType<typeof useInvoicesLazyQuery>;
export type InvoicesQueryResult = Apollo.QueryResult<InvoicesQuery, InvoicesQueryVariables>;
export const InvoiceByIdDocument = gql`
    query InvoiceById($id: String!) {
  invoiceById(id: $id) {
    id
    invoiceDate
    buyer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    deliveryAddress
    dueDate
    priceExclVat
    priceInclVat
    invoiceRows {
      id
      sageId
      articleNumber
      description
      quantity
      netPriceExclVat
      grossPriceExclVat
    }
    status
    invoiceType
    paymentCondition
    orderReferences
    orderReferenceMessage
  }
}
    `;

/**
 * __useInvoiceByIdQuery__
 *
 * To run a query within a React component, call `useInvoiceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvoiceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvoiceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInvoiceByIdQuery(baseOptions: Apollo.QueryHookOptions<InvoiceByIdQuery, InvoiceByIdQueryVariables>) {
        return Apollo.useQuery<InvoiceByIdQuery, InvoiceByIdQueryVariables>(InvoiceByIdDocument, baseOptions);
      }
export function useInvoiceByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvoiceByIdQuery, InvoiceByIdQueryVariables>) {
          return Apollo.useLazyQuery<InvoiceByIdQuery, InvoiceByIdQueryVariables>(InvoiceByIdDocument, baseOptions);
        }
export type InvoiceByIdQueryHookResult = ReturnType<typeof useInvoiceByIdQuery>;
export type InvoiceByIdLazyQueryHookResult = ReturnType<typeof useInvoiceByIdLazyQuery>;
export type InvoiceByIdQueryResult = Apollo.QueryResult<InvoiceByIdQuery, InvoiceByIdQueryVariables>;
export const OperationCodesDocument = gql`
    query OperationCodes($filter: OperationCodeFilter, $sorting: [BaseSorting!]) {
  operationCodes(filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      code
      category
      description
      time
    }
  }
}
    `;

/**
 * __useOperationCodesQuery__
 *
 * To run a query within a React component, call `useOperationCodesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationCodesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationCodesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useOperationCodesQuery(baseOptions?: Apollo.QueryHookOptions<OperationCodesQuery, OperationCodesQueryVariables>) {
        return Apollo.useQuery<OperationCodesQuery, OperationCodesQueryVariables>(OperationCodesDocument, baseOptions);
      }
export function useOperationCodesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OperationCodesQuery, OperationCodesQueryVariables>) {
          return Apollo.useLazyQuery<OperationCodesQuery, OperationCodesQueryVariables>(OperationCodesDocument, baseOptions);
        }
export type OperationCodesQueryHookResult = ReturnType<typeof useOperationCodesQuery>;
export type OperationCodesLazyQueryHookResult = ReturnType<typeof useOperationCodesLazyQuery>;
export type OperationCodesQueryResult = Apollo.QueryResult<OperationCodesQuery, OperationCodesQueryVariables>;
export const OperationGroupByIdDocument = gql`
    query OperationGroupById($id: String!) {
  operationGroupById(id: $id) {
    id
    name
    make {
      id
      externalId
      name
    }
    modelSeries {
      id
      name
      brand {
        id
        externalId
        name
      }
      models {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
    }
    model {
      id
      name
      description
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
    }
    operationCodes {
      id
      code
      category
      description
      time
    }
  }
}
    `;

/**
 * __useOperationGroupByIdQuery__
 *
 * To run a query within a React component, call `useOperationGroupByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationGroupByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationGroupByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOperationGroupByIdQuery(baseOptions: Apollo.QueryHookOptions<OperationGroupByIdQuery, OperationGroupByIdQueryVariables>) {
        return Apollo.useQuery<OperationGroupByIdQuery, OperationGroupByIdQueryVariables>(OperationGroupByIdDocument, baseOptions);
      }
export function useOperationGroupByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OperationGroupByIdQuery, OperationGroupByIdQueryVariables>) {
          return Apollo.useLazyQuery<OperationGroupByIdQuery, OperationGroupByIdQueryVariables>(OperationGroupByIdDocument, baseOptions);
        }
export type OperationGroupByIdQueryHookResult = ReturnType<typeof useOperationGroupByIdQuery>;
export type OperationGroupByIdLazyQueryHookResult = ReturnType<typeof useOperationGroupByIdLazyQuery>;
export type OperationGroupByIdQueryResult = Apollo.QueryResult<OperationGroupByIdQuery, OperationGroupByIdQueryVariables>;
export const OperationGroupAndCostsDocument = gql`
    query OperationGroupAndCosts($vehicleId: String) {
  operationGroupAndCosts(vehicleId: $vehicleId) {
    operationGroup {
      id
      name
      make {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      operationCodes {
        id
        code
        category
        description
        time
      }
    }
    warrantyHourlyCost
    manualHourlyCost
  }
}
    `;

/**
 * __useOperationGroupAndCostsQuery__
 *
 * To run a query within a React component, call `useOperationGroupAndCostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationGroupAndCostsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationGroupAndCostsQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useOperationGroupAndCostsQuery(baseOptions?: Apollo.QueryHookOptions<OperationGroupAndCostsQuery, OperationGroupAndCostsQueryVariables>) {
        return Apollo.useQuery<OperationGroupAndCostsQuery, OperationGroupAndCostsQueryVariables>(OperationGroupAndCostsDocument, baseOptions);
      }
export function useOperationGroupAndCostsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OperationGroupAndCostsQuery, OperationGroupAndCostsQueryVariables>) {
          return Apollo.useLazyQuery<OperationGroupAndCostsQuery, OperationGroupAndCostsQueryVariables>(OperationGroupAndCostsDocument, baseOptions);
        }
export type OperationGroupAndCostsQueryHookResult = ReturnType<typeof useOperationGroupAndCostsQuery>;
export type OperationGroupAndCostsLazyQueryHookResult = ReturnType<typeof useOperationGroupAndCostsLazyQuery>;
export type OperationGroupAndCostsQueryResult = Apollo.QueryResult<OperationGroupAndCostsQuery, OperationGroupAndCostsQueryVariables>;
export const OperationGroupsDocument = gql`
    query OperationGroups {
  operationGroups {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      name
      make {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      operationCodeCount
    }
  }
}
    `;

/**
 * __useOperationGroupsQuery__
 *
 * To run a query within a React component, call `useOperationGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOperationGroupsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOperationGroupsQuery(baseOptions?: Apollo.QueryHookOptions<OperationGroupsQuery, OperationGroupsQueryVariables>) {
        return Apollo.useQuery<OperationGroupsQuery, OperationGroupsQueryVariables>(OperationGroupsDocument, baseOptions);
      }
export function useOperationGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OperationGroupsQuery, OperationGroupsQueryVariables>) {
          return Apollo.useLazyQuery<OperationGroupsQuery, OperationGroupsQueryVariables>(OperationGroupsDocument, baseOptions);
        }
export type OperationGroupsQueryHookResult = ReturnType<typeof useOperationGroupsQuery>;
export type OperationGroupsLazyQueryHookResult = ReturnType<typeof useOperationGroupsLazyQuery>;
export type OperationGroupsQueryResult = Apollo.QueryResult<OperationGroupsQuery, OperationGroupsQueryVariables>;
export const ProductRuleByIdDocument = gql`
    query ProductRuleById($id: ID!) {
  productRuleById(id: $id) {
    id
    name
    showInCart
    startTime
    endTime
    discountTwo
    discountThree
    discountCode
    stockVehicle {
      id
      sageId
      name
      brand {
        id
        externalId
        name
      }
      vehicleType
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      color
      colorCode
      createdAt
      price
      vat
      discountedPrice
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      equipments {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      articleNumber
      factoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      vehicleCampaign
      active
    }
    equipment {
      id
      name
      articleNumber
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      vehicleType
      price
      vat
      discountedPrice
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      brand {
        id
        externalId
        name
      }
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      createdAt
      vehicleCampaign
      remark
      active
      equipmentGroup {
        id
        name
        externalId
      }
    }
    sparePart {
      id
      articleNumber
      name
      remark
      price
      discountedPrice
      vat
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      explodedViewRows {
        id
        referenceNumber
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
      }
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      active
      vehicleCampaign
    }
    billOfMaterial {
      id
      articleNumber
      active
      bomCode
      description
      fromDate
      toDate
      bomRows {
        id
        linkQuantity
        fromDate
        toDate
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
      }
      price
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      discountedPrice
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      vehicleCampaign
      brand {
        id
        externalId
        name
      }
      equipmentGroup {
        id
        name
        externalId
      }
    }
    productReferences {
      id
      vehicleType
      make {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
    }
  }
}
    `;

/**
 * __useProductRuleByIdQuery__
 *
 * To run a query within a React component, call `useProductRuleByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductRuleByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductRuleByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProductRuleByIdQuery(baseOptions: Apollo.QueryHookOptions<ProductRuleByIdQuery, ProductRuleByIdQueryVariables>) {
        return Apollo.useQuery<ProductRuleByIdQuery, ProductRuleByIdQueryVariables>(ProductRuleByIdDocument, baseOptions);
      }
export function useProductRuleByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductRuleByIdQuery, ProductRuleByIdQueryVariables>) {
          return Apollo.useLazyQuery<ProductRuleByIdQuery, ProductRuleByIdQueryVariables>(ProductRuleByIdDocument, baseOptions);
        }
export type ProductRuleByIdQueryHookResult = ReturnType<typeof useProductRuleByIdQuery>;
export type ProductRuleByIdLazyQueryHookResult = ReturnType<typeof useProductRuleByIdLazyQuery>;
export type ProductRuleByIdQueryResult = Apollo.QueryResult<ProductRuleByIdQuery, ProductRuleByIdQueryVariables>;
export const ProductRulesDocument = gql`
    query ProductRules($filter: BaseFilter, $sorting: [BaseSorting!]) {
  productRules(filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      name
      showInCart
      startTime
      endTime
      discountTwo
      discountThree
      discountCode
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      productReferences {
        id
        vehicleType
        make {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
      }
    }
  }
}
    `;

/**
 * __useProductRulesQuery__
 *
 * To run a query within a React component, call `useProductRulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductRulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductRulesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useProductRulesQuery(baseOptions?: Apollo.QueryHookOptions<ProductRulesQuery, ProductRulesQueryVariables>) {
        return Apollo.useQuery<ProductRulesQuery, ProductRulesQueryVariables>(ProductRulesDocument, baseOptions);
      }
export function useProductRulesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductRulesQuery, ProductRulesQueryVariables>) {
          return Apollo.useLazyQuery<ProductRulesQuery, ProductRulesQueryVariables>(ProductRulesDocument, baseOptions);
        }
export type ProductRulesQueryHookResult = ReturnType<typeof useProductRulesQuery>;
export type ProductRulesLazyQueryHookResult = ReturnType<typeof useProductRulesLazyQuery>;
export type ProductRulesQueryResult = Apollo.QueryResult<ProductRulesQuery, ProductRulesQueryVariables>;
export const IdentifierSearchDocument = gql`
    query IdentifierSearch($filter: IdentifierSearchFilter!) {
  identifierSearch(filter: $filter) {
    meta {
      total
      limit
      offset
    }
    edges {
      stockVehicles {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipments {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      spareParts {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterials {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      makes {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      models {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      vehicleTypes
    }
  }
}
    `;

/**
 * __useIdentifierSearchQuery__
 *
 * To run a query within a React component, call `useIdentifierSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdentifierSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdentifierSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useIdentifierSearchQuery(baseOptions: Apollo.QueryHookOptions<IdentifierSearchQuery, IdentifierSearchQueryVariables>) {
        return Apollo.useQuery<IdentifierSearchQuery, IdentifierSearchQueryVariables>(IdentifierSearchDocument, baseOptions);
      }
export function useIdentifierSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IdentifierSearchQuery, IdentifierSearchQueryVariables>) {
          return Apollo.useLazyQuery<IdentifierSearchQuery, IdentifierSearchQueryVariables>(IdentifierSearchDocument, baseOptions);
        }
export type IdentifierSearchQueryHookResult = ReturnType<typeof useIdentifierSearchQuery>;
export type IdentifierSearchLazyQueryHookResult = ReturnType<typeof useIdentifierSearchLazyQuery>;
export type IdentifierSearchQueryResult = Apollo.QueryResult<IdentifierSearchQuery, IdentifierSearchQueryVariables>;
export const VolumeDiscountByIdDocument = gql`
    query VolumeDiscountById($id: ID!) {
  volumeDiscountById(id: $id) {
    id
    name
    volume
    startTime
    endTime
    discountOne
    discountTwo
    discountThree
    mainProductIdentifier {
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      make {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      vehicleType
    }
    level
  }
}
    `;

/**
 * __useVolumeDiscountByIdQuery__
 *
 * To run a query within a React component, call `useVolumeDiscountByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useVolumeDiscountByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVolumeDiscountByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVolumeDiscountByIdQuery(baseOptions: Apollo.QueryHookOptions<VolumeDiscountByIdQuery, VolumeDiscountByIdQueryVariables>) {
        return Apollo.useQuery<VolumeDiscountByIdQuery, VolumeDiscountByIdQueryVariables>(VolumeDiscountByIdDocument, baseOptions);
      }
export function useVolumeDiscountByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VolumeDiscountByIdQuery, VolumeDiscountByIdQueryVariables>) {
          return Apollo.useLazyQuery<VolumeDiscountByIdQuery, VolumeDiscountByIdQueryVariables>(VolumeDiscountByIdDocument, baseOptions);
        }
export type VolumeDiscountByIdQueryHookResult = ReturnType<typeof useVolumeDiscountByIdQuery>;
export type VolumeDiscountByIdLazyQueryHookResult = ReturnType<typeof useVolumeDiscountByIdLazyQuery>;
export type VolumeDiscountByIdQueryResult = Apollo.QueryResult<VolumeDiscountByIdQuery, VolumeDiscountByIdQueryVariables>;
export const VolumeDiscountsDocument = gql`
    query VolumeDiscounts($filter: BaseFilter, $sorting: [BaseSorting!]) {
  volumeDiscounts(filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      name
      volume
      startTime
      endTime
      discountOne
      discountTwo
      discountThree
      mainProductIdentifier {
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        make {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
      }
      level
    }
  }
}
    `;

/**
 * __useVolumeDiscountsQuery__
 *
 * To run a query within a React component, call `useVolumeDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVolumeDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVolumeDiscountsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useVolumeDiscountsQuery(baseOptions?: Apollo.QueryHookOptions<VolumeDiscountsQuery, VolumeDiscountsQueryVariables>) {
        return Apollo.useQuery<VolumeDiscountsQuery, VolumeDiscountsQueryVariables>(VolumeDiscountsDocument, baseOptions);
      }
export function useVolumeDiscountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VolumeDiscountsQuery, VolumeDiscountsQueryVariables>) {
          return Apollo.useLazyQuery<VolumeDiscountsQuery, VolumeDiscountsQueryVariables>(VolumeDiscountsDocument, baseOptions);
        }
export type VolumeDiscountsQueryHookResult = ReturnType<typeof useVolumeDiscountsQuery>;
export type VolumeDiscountsLazyQueryHookResult = ReturnType<typeof useVolumeDiscountsLazyQuery>;
export type VolumeDiscountsQueryResult = Apollo.QueryResult<VolumeDiscountsQuery, VolumeDiscountsQueryVariables>;
export const ArticleSearchDocument = gql`
    query ArticleSearch($filter: ArticleSearchFilter, $sorting: [BaseSorting!]) {
  articleSearch(filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      ... on StockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        images {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          incomingDeliveries {
            deliveryDate
            quantity
          }
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          vehicleType
          price
          vat
          discountedPrice
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          brand {
            id
            externalId
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          createdAt
          vehicleCampaign
          remark
          active
          equipmentGroup {
            id
            name
            externalId
          }
        }
        articleNumber
        factoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        vehicleCampaign
        active
      }
      ... on Equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          incomingDeliveries {
            deliveryDate
            quantity
          }
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        images {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      ... on SparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        thumbnail {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        images {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        explodedViewRows {
          id
          referenceNumber
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          incomingDeliveries {
            deliveryDate
            quantity
          }
          updatedAt
        }
        active
        vehicleCampaign
      }
      ... on BillOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          incomingDeliveries {
            deliveryDate
            quantity
          }
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        thumbnail {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      ... on Fee {
        id
        name
        articleNumber
        price
        vat
        discountedPrice
        createdAt
        updatedAt
        isAllowedOnVehicles
        active
      }
    }
  }
}
    `;

/**
 * __useArticleSearchQuery__
 *
 * To run a query within a React component, call `useArticleSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useArticleSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArticleSearchQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useArticleSearchQuery(baseOptions?: Apollo.QueryHookOptions<ArticleSearchQuery, ArticleSearchQueryVariables>) {
        return Apollo.useQuery<ArticleSearchQuery, ArticleSearchQueryVariables>(ArticleSearchDocument, baseOptions);
      }
export function useArticleSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ArticleSearchQuery, ArticleSearchQueryVariables>) {
          return Apollo.useLazyQuery<ArticleSearchQuery, ArticleSearchQueryVariables>(ArticleSearchDocument, baseOptions);
        }
export type ArticleSearchQueryHookResult = ReturnType<typeof useArticleSearchQuery>;
export type ArticleSearchLazyQueryHookResult = ReturnType<typeof useArticleSearchLazyQuery>;
export type ArticleSearchQueryResult = Apollo.QueryResult<ArticleSearchQuery, ArticleSearchQueryVariables>;
export const MaintenanceTaskByIdDocument = gql`
    query MaintenanceTaskById($id: ID!) {
  maintenanceTaskById(id: $id) {
    id
    serviceId
    maintenanceCustomer {
      id
      name
      hourlyMaintenanceRate
      createdAt
      updatedAt
      externalId
    }
    vehicle {
      id
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      vehicleIdentificationNumber
      registrationNumber
      brand {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      startedAt
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      showDetailedInformation
      typeOfInstallation
      nonColorFactoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      createdAt
      updatedAt
      engineNumber
      factoryCode
      status
      firstArrivedToSweden
      producedAt
      firstRegisteredAt
      replacementText
      showBfiAndKey
      warranties {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          ... on ExternRow {
            purchaseRefersType
          }
          ... on ArticleRow {
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        ... on VehicleWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
        ... on GoodWillWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
        ... on PartWarranty {
          purchaseReceiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on LegacyWarranty {
          legacyClaimNumber
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          legacyDealer
          causeOfDamage
          warrantyStartedAt
          receivedAt
        }
      }
    }
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    status
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    meterReading
    customerRemark
    workshopRemark
    freeText
    customerReference
    workPerformedAt
    sumRequested
    sumCredited
    createdAt
    updatedAt
    customerApprovalBy
    customerApprovalAt
    meterReadingImage {
      id
      name
      path
      createdAt
    }
    damageImage {
      id
      name
      path
      createdAt
    }
    deliveryServiceImage {
      id
      name
      path
      createdAt
    }
    attachements {
      id
      name
      path
      mimeType
      isImage
      createdAt
    }
    rows {
      id
      workCategory
      status
      complementReason
      complementComment
      declineReason
      declineComment
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      handledBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      price
      requestedPrice
      quantity
      requestedQuantity
      ... on ArticleMaintenanceRow {
        orderItem {
          id
          vehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          fee {
            id
            name
            articleNumber
            price
            vat
            discountedPrice
            createdAt
            updatedAt
            isAllowedOnVehicles
            active
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          detailedQuantities {
            processing
            allocated
            blocked
            delivered
            invoiced
          }
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
            externallyFinanced
          }
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          connectionType
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
      }
      ... on ExternalMaintenanceRow {
        externalCostType
        receiptImage {
          id
          name
          path
          createdAt
        }
        externalCostDescription
      }
      ... on OperationMaintenanceRow {
        operationCode {
          id
          code
          category
          description
          time
        }
        customOperationDescription
      }
    }
    internalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    externalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
  }
}
    `;

/**
 * __useMaintenanceTaskByIdQuery__
 *
 * To run a query within a React component, call `useMaintenanceTaskByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceTaskByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceTaskByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMaintenanceTaskByIdQuery(baseOptions: Apollo.QueryHookOptions<MaintenanceTaskByIdQuery, MaintenanceTaskByIdQueryVariables>) {
        return Apollo.useQuery<MaintenanceTaskByIdQuery, MaintenanceTaskByIdQueryVariables>(MaintenanceTaskByIdDocument, baseOptions);
      }
export function useMaintenanceTaskByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaintenanceTaskByIdQuery, MaintenanceTaskByIdQueryVariables>) {
          return Apollo.useLazyQuery<MaintenanceTaskByIdQuery, MaintenanceTaskByIdQueryVariables>(MaintenanceTaskByIdDocument, baseOptions);
        }
export type MaintenanceTaskByIdQueryHookResult = ReturnType<typeof useMaintenanceTaskByIdQuery>;
export type MaintenanceTaskByIdLazyQueryHookResult = ReturnType<typeof useMaintenanceTaskByIdLazyQuery>;
export type MaintenanceTaskByIdQueryResult = Apollo.QueryResult<MaintenanceTaskByIdQuery, MaintenanceTaskByIdQueryVariables>;
export const MaintenanceCustomersDocument = gql`
    query MaintenanceCustomers {
  maintenanceCustomers {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      name
      hourlyMaintenanceRate
      createdAt
      updatedAt
      externalId
    }
  }
}
    `;

/**
 * __useMaintenanceCustomersQuery__
 *
 * To run a query within a React component, call `useMaintenanceCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceCustomersQuery({
 *   variables: {
 *   },
 * });
 */
export function useMaintenanceCustomersQuery(baseOptions?: Apollo.QueryHookOptions<MaintenanceCustomersQuery, MaintenanceCustomersQueryVariables>) {
        return Apollo.useQuery<MaintenanceCustomersQuery, MaintenanceCustomersQueryVariables>(MaintenanceCustomersDocument, baseOptions);
      }
export function useMaintenanceCustomersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaintenanceCustomersQuery, MaintenanceCustomersQueryVariables>) {
          return Apollo.useLazyQuery<MaintenanceCustomersQuery, MaintenanceCustomersQueryVariables>(MaintenanceCustomersDocument, baseOptions);
        }
export type MaintenanceCustomersQueryHookResult = ReturnType<typeof useMaintenanceCustomersQuery>;
export type MaintenanceCustomersLazyQueryHookResult = ReturnType<typeof useMaintenanceCustomersLazyQuery>;
export type MaintenanceCustomersQueryResult = Apollo.QueryResult<MaintenanceCustomersQuery, MaintenanceCustomersQueryVariables>;
export const MaintenanceTasksDocument = gql`
    query MaintenanceTasks($filter: MaintenanceTaskFilter, $sorting: [BaseSorting!]) {
  maintenanceTasks(filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      serviceId
      maintenanceCustomer {
        id
        name
        hourlyMaintenanceRate
        createdAt
        updatedAt
        externalId
      }
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          customerRemark
          workshopRemark
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          shippingCost
          ... on VehicleWarranty {
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
          ... on GoodWillWarranty {
            meterReading
            operatingTime
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      status
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      meterReading
      customerRemark
      workshopRemark
      freeText
      customerReference
      workPerformedAt
      sumRequested
      sumCredited
      createdAt
      updatedAt
      customerApprovalBy
      customerApprovalAt
      meterReadingImage {
        id
        name
        path
        createdAt
      }
      damageImage {
        id
        name
        path
        createdAt
      }
      deliveryServiceImage {
        id
        name
        path
        createdAt
      }
      attachements {
        id
        name
        path
        mimeType
        isImage
        createdAt
      }
      rows {
        id
        workCategory
        status
        complementReason
        complementComment
        declineReason
        declineComment
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        handledBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        price
        requestedPrice
        quantity
        requestedQuantity
        ... on ArticleMaintenanceRow {
          orderItem {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
        }
        ... on ExternalMaintenanceRow {
          externalCostType
          receiptImage {
            id
            name
            path
            createdAt
          }
          externalCostDescription
        }
        ... on OperationMaintenanceRow {
          operationCode {
            id
            code
            category
            description
            time
          }
          customOperationDescription
        }
      }
      internalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      externalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
    }
  }
}
    `;

/**
 * __useMaintenanceTasksQuery__
 *
 * To run a query within a React component, call `useMaintenanceTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceTasksQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useMaintenanceTasksQuery(baseOptions?: Apollo.QueryHookOptions<MaintenanceTasksQuery, MaintenanceTasksQueryVariables>) {
        return Apollo.useQuery<MaintenanceTasksQuery, MaintenanceTasksQueryVariables>(MaintenanceTasksDocument, baseOptions);
      }
export function useMaintenanceTasksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaintenanceTasksQuery, MaintenanceTasksQueryVariables>) {
          return Apollo.useLazyQuery<MaintenanceTasksQuery, MaintenanceTasksQueryVariables>(MaintenanceTasksDocument, baseOptions);
        }
export type MaintenanceTasksQueryHookResult = ReturnType<typeof useMaintenanceTasksQuery>;
export type MaintenanceTasksLazyQueryHookResult = ReturnType<typeof useMaintenanceTasksLazyQuery>;
export type MaintenanceTasksQueryResult = Apollo.QueryResult<MaintenanceTasksQuery, MaintenanceTasksQueryVariables>;
export const CustomPricesDocument = gql`
    query CustomPrices($filter: BaseFilter) {
  customPrices(filter: $filter) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      price
      article {
        ... on StockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        ... on Equipment {
          id
          name
          articleNumber
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          vehicleType
          price
          vat
          discountedPrice
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          brand {
            id
            externalId
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          createdAt
          vehicleCampaign
          remark
          active
          equipmentGroup {
            id
            name
            externalId
          }
        }
        ... on SparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
        ... on BillOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        ... on Fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
      }
      maintenanceCustomer {
        id
        name
        hourlyMaintenanceRate
        createdAt
        updatedAt
        externalId
      }
      updatedAt
    }
  }
}
    `;

/**
 * __useCustomPricesQuery__
 *
 * To run a query within a React component, call `useCustomPricesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomPricesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomPricesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCustomPricesQuery(baseOptions?: Apollo.QueryHookOptions<CustomPricesQuery, CustomPricesQueryVariables>) {
        return Apollo.useQuery<CustomPricesQuery, CustomPricesQueryVariables>(CustomPricesDocument, baseOptions);
      }
export function useCustomPricesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomPricesQuery, CustomPricesQueryVariables>) {
          return Apollo.useLazyQuery<CustomPricesQuery, CustomPricesQueryVariables>(CustomPricesDocument, baseOptions);
        }
export type CustomPricesQueryHookResult = ReturnType<typeof useCustomPricesQuery>;
export type CustomPricesLazyQueryHookResult = ReturnType<typeof useCustomPricesLazyQuery>;
export type CustomPricesQueryResult = Apollo.QueryResult<CustomPricesQuery, CustomPricesQueryVariables>;
export const CustomPriceDocument = gql`
    query CustomPrice($articleId: ID!, $maintenanceCustomerId: ID!) {
  customPrice(
    articleId: $articleId
    maintenanceCustomerId: $maintenanceCustomerId
  ) {
    id
    price
    article {
      ... on StockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        images {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          incomingDeliveries {
            deliveryDate
            quantity
          }
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          vehicleType
          price
          vat
          discountedPrice
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          brand {
            id
            externalId
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          createdAt
          vehicleCampaign
          remark
          active
          equipmentGroup {
            id
            name
            externalId
          }
        }
        articleNumber
        factoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        vehicleCampaign
        active
      }
      ... on Equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          incomingDeliveries {
            deliveryDate
            quantity
          }
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        images {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      ... on SparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        thumbnail {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        images {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        explodedViewRows {
          id
          referenceNumber
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          incomingDeliveries {
            deliveryDate
            quantity
          }
          updatedAt
        }
        active
        vehicleCampaign
      }
      ... on BillOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          incomingDeliveries {
            deliveryDate
            quantity
          }
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        thumbnail {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      ... on Fee {
        id
        name
        articleNumber
        price
        vat
        discountedPrice
        createdAt
        updatedAt
        isAllowedOnVehicles
        active
      }
    }
    maintenanceCustomer {
      id
      name
      hourlyMaintenanceRate
      createdAt
      updatedAt
      externalId
    }
    updatedAt
  }
}
    `;

/**
 * __useCustomPriceQuery__
 *
 * To run a query within a React component, call `useCustomPriceQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomPriceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomPriceQuery({
 *   variables: {
 *      articleId: // value for 'articleId'
 *      maintenanceCustomerId: // value for 'maintenanceCustomerId'
 *   },
 * });
 */
export function useCustomPriceQuery(baseOptions: Apollo.QueryHookOptions<CustomPriceQuery, CustomPriceQueryVariables>) {
        return Apollo.useQuery<CustomPriceQuery, CustomPriceQueryVariables>(CustomPriceDocument, baseOptions);
      }
export function useCustomPriceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomPriceQuery, CustomPriceQueryVariables>) {
          return Apollo.useLazyQuery<CustomPriceQuery, CustomPriceQueryVariables>(CustomPriceDocument, baseOptions);
        }
export type CustomPriceQueryHookResult = ReturnType<typeof useCustomPriceQuery>;
export type CustomPriceLazyQueryHookResult = ReturnType<typeof useCustomPriceLazyQuery>;
export type CustomPriceQueryResult = Apollo.QueryResult<CustomPriceQuery, CustomPriceQueryVariables>;
export const LoginDocument = gql`
    mutation Login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    token
    user {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    actAsRetailerRight
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ResetPasswordActionDocument = gql`
    mutation ResetPasswordAction($email: String!) {
  resetPasswordAction(email: $email) {
    success
  }
}
    `;
export type ResetPasswordActionMutationFn = Apollo.MutationFunction<ResetPasswordActionMutation, ResetPasswordActionMutationVariables>;

/**
 * __useResetPasswordActionMutation__
 *
 * To run a mutation, you first call `useResetPasswordActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordActionMutation, { data, loading, error }] = useResetPasswordActionMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordActionMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordActionMutation, ResetPasswordActionMutationVariables>) {
        return Apollo.useMutation<ResetPasswordActionMutation, ResetPasswordActionMutationVariables>(ResetPasswordActionDocument, baseOptions);
      }
export type ResetPasswordActionMutationHookResult = ReturnType<typeof useResetPasswordActionMutation>;
export type ResetPasswordActionMutationResult = Apollo.MutationResult<ResetPasswordActionMutation>;
export type ResetPasswordActionMutationOptions = Apollo.BaseMutationOptions<ResetPasswordActionMutation, ResetPasswordActionMutationVariables>;
export const ResetPasswordConfirmDocument = gql`
    mutation ResetPasswordConfirm($email: String!, $newPassword: String!, $newPasswordConfirm: String!, $nonce: String!) {
  resetPasswordConfirm(
    email: $email
    newPassword: $newPassword
    newPasswordConfirm: $newPasswordConfirm
    nonce: $nonce
  ) {
    token
    user {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    actAsRetailerRight
  }
}
    `;
export type ResetPasswordConfirmMutationFn = Apollo.MutationFunction<ResetPasswordConfirmMutation, ResetPasswordConfirmMutationVariables>;

/**
 * __useResetPasswordConfirmMutation__
 *
 * To run a mutation, you first call `useResetPasswordConfirmMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordConfirmMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordConfirmMutation, { data, loading, error }] = useResetPasswordConfirmMutation({
 *   variables: {
 *      email: // value for 'email'
 *      newPassword: // value for 'newPassword'
 *      newPasswordConfirm: // value for 'newPasswordConfirm'
 *      nonce: // value for 'nonce'
 *   },
 * });
 */
export function useResetPasswordConfirmMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordConfirmMutation, ResetPasswordConfirmMutationVariables>) {
        return Apollo.useMutation<ResetPasswordConfirmMutation, ResetPasswordConfirmMutationVariables>(ResetPasswordConfirmDocument, baseOptions);
      }
export type ResetPasswordConfirmMutationHookResult = ReturnType<typeof useResetPasswordConfirmMutation>;
export type ResetPasswordConfirmMutationResult = Apollo.MutationResult<ResetPasswordConfirmMutation>;
export type ResetPasswordConfirmMutationOptions = Apollo.BaseMutationOptions<ResetPasswordConfirmMutation, ResetPasswordConfirmMutationVariables>;
export const ResetOwnPasswordDocument = gql`
    mutation ResetOwnPassword($oldPassword: String!, $newPassword: String!, $newPasswordConfirm: String!) {
  resetOwnPassword(
    oldPassword: $oldPassword
    newPassword: $newPassword
    newPasswordConfirm: $newPasswordConfirm
  ) {
    success
  }
}
    `;
export type ResetOwnPasswordMutationFn = Apollo.MutationFunction<ResetOwnPasswordMutation, ResetOwnPasswordMutationVariables>;

/**
 * __useResetOwnPasswordMutation__
 *
 * To run a mutation, you first call `useResetOwnPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetOwnPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetOwnPasswordMutation, { data, loading, error }] = useResetOwnPasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *      newPasswordConfirm: // value for 'newPasswordConfirm'
 *   },
 * });
 */
export function useResetOwnPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetOwnPasswordMutation, ResetOwnPasswordMutationVariables>) {
        return Apollo.useMutation<ResetOwnPasswordMutation, ResetOwnPasswordMutationVariables>(ResetOwnPasswordDocument, baseOptions);
      }
export type ResetOwnPasswordMutationHookResult = ReturnType<typeof useResetOwnPasswordMutation>;
export type ResetOwnPasswordMutationResult = Apollo.MutationResult<ResetOwnPasswordMutation>;
export type ResetOwnPasswordMutationOptions = Apollo.BaseMutationOptions<ResetOwnPasswordMutation, ResetOwnPasswordMutationVariables>;
export const CreateRetailerAccountDocument = gql`
    mutation CreateRetailerAccount($email: String!, $name: String!, $phoneNumber: String!, $freeText: String, $retailerId: Int!, $role: RetailerUserRole!, $roleDescription: String) {
  createRetailerAccount(
    email: $email
    name: $name
    phoneNumber: $phoneNumber
    freeText: $freeText
    retailerId: $retailerId
    role: $role
    roleDescription: $roleDescription
  ) {
    user {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    error
    message
  }
}
    `;
export type CreateRetailerAccountMutationFn = Apollo.MutationFunction<CreateRetailerAccountMutation, CreateRetailerAccountMutationVariables>;

/**
 * __useCreateRetailerAccountMutation__
 *
 * To run a mutation, you first call `useCreateRetailerAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRetailerAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRetailerAccountMutation, { data, loading, error }] = useCreateRetailerAccountMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      phoneNumber: // value for 'phoneNumber'
 *      freeText: // value for 'freeText'
 *      retailerId: // value for 'retailerId'
 *      role: // value for 'role'
 *      roleDescription: // value for 'roleDescription'
 *   },
 * });
 */
export function useCreateRetailerAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateRetailerAccountMutation, CreateRetailerAccountMutationVariables>) {
        return Apollo.useMutation<CreateRetailerAccountMutation, CreateRetailerAccountMutationVariables>(CreateRetailerAccountDocument, baseOptions);
      }
export type CreateRetailerAccountMutationHookResult = ReturnType<typeof useCreateRetailerAccountMutation>;
export type CreateRetailerAccountMutationResult = Apollo.MutationResult<CreateRetailerAccountMutation>;
export type CreateRetailerAccountMutationOptions = Apollo.BaseMutationOptions<CreateRetailerAccountMutation, CreateRetailerAccountMutationVariables>;
export const CreateDealyAccountDocument = gql`
    mutation CreateDealyAccount($email: String!, $name: String!, $phoneNumber: String!, $freeText: String, $role: DealyUserRole!, $roleDescription: String) {
  createDealyAccount(
    email: $email
    name: $name
    phoneNumber: $phoneNumber
    freeText: $freeText
    role: $role
    roleDescription: $roleDescription
  ) {
    user {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    error
    message
  }
}
    `;
export type CreateDealyAccountMutationFn = Apollo.MutationFunction<CreateDealyAccountMutation, CreateDealyAccountMutationVariables>;

/**
 * __useCreateDealyAccountMutation__
 *
 * To run a mutation, you first call `useCreateDealyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDealyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDealyAccountMutation, { data, loading, error }] = useCreateDealyAccountMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      phoneNumber: // value for 'phoneNumber'
 *      freeText: // value for 'freeText'
 *      role: // value for 'role'
 *      roleDescription: // value for 'roleDescription'
 *   },
 * });
 */
export function useCreateDealyAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateDealyAccountMutation, CreateDealyAccountMutationVariables>) {
        return Apollo.useMutation<CreateDealyAccountMutation, CreateDealyAccountMutationVariables>(CreateDealyAccountDocument, baseOptions);
      }
export type CreateDealyAccountMutationHookResult = ReturnType<typeof useCreateDealyAccountMutation>;
export type CreateDealyAccountMutationResult = Apollo.MutationResult<CreateDealyAccountMutation>;
export type CreateDealyAccountMutationOptions = Apollo.BaseMutationOptions<CreateDealyAccountMutation, CreateDealyAccountMutationVariables>;
export const DeleteDealyUserDocument = gql`
    mutation DeleteDealyUser($id: ID!) {
  deleteDealyUser(id: $id) {
    success
  }
}
    `;
export type DeleteDealyUserMutationFn = Apollo.MutationFunction<DeleteDealyUserMutation, DeleteDealyUserMutationVariables>;

/**
 * __useDeleteDealyUserMutation__
 *
 * To run a mutation, you first call `useDeleteDealyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDealyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDealyUserMutation, { data, loading, error }] = useDeleteDealyUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDealyUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDealyUserMutation, DeleteDealyUserMutationVariables>) {
        return Apollo.useMutation<DeleteDealyUserMutation, DeleteDealyUserMutationVariables>(DeleteDealyUserDocument, baseOptions);
      }
export type DeleteDealyUserMutationHookResult = ReturnType<typeof useDeleteDealyUserMutation>;
export type DeleteDealyUserMutationResult = Apollo.MutationResult<DeleteDealyUserMutation>;
export type DeleteDealyUserMutationOptions = Apollo.BaseMutationOptions<DeleteDealyUserMutation, DeleteDealyUserMutationVariables>;
export const DeleteRetailerUserDocument = gql`
    mutation DeleteRetailerUser($id: ID!) {
  deleteRetailerUser(id: $id) {
    success
  }
}
    `;
export type DeleteRetailerUserMutationFn = Apollo.MutationFunction<DeleteRetailerUserMutation, DeleteRetailerUserMutationVariables>;

/**
 * __useDeleteRetailerUserMutation__
 *
 * To run a mutation, you first call `useDeleteRetailerUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRetailerUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRetailerUserMutation, { data, loading, error }] = useDeleteRetailerUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRetailerUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRetailerUserMutation, DeleteRetailerUserMutationVariables>) {
        return Apollo.useMutation<DeleteRetailerUserMutation, DeleteRetailerUserMutationVariables>(DeleteRetailerUserDocument, baseOptions);
      }
export type DeleteRetailerUserMutationHookResult = ReturnType<typeof useDeleteRetailerUserMutation>;
export type DeleteRetailerUserMutationResult = Apollo.MutationResult<DeleteRetailerUserMutation>;
export type DeleteRetailerUserMutationOptions = Apollo.BaseMutationOptions<DeleteRetailerUserMutation, DeleteRetailerUserMutationVariables>;
export const DeleteMyselfDocument = gql`
    mutation DeleteMyself {
  deleteMyself {
    success
  }
}
    `;
export type DeleteMyselfMutationFn = Apollo.MutationFunction<DeleteMyselfMutation, DeleteMyselfMutationVariables>;

/**
 * __useDeleteMyselfMutation__
 *
 * To run a mutation, you first call `useDeleteMyselfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMyselfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMyselfMutation, { data, loading, error }] = useDeleteMyselfMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteMyselfMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMyselfMutation, DeleteMyselfMutationVariables>) {
        return Apollo.useMutation<DeleteMyselfMutation, DeleteMyselfMutationVariables>(DeleteMyselfDocument, baseOptions);
      }
export type DeleteMyselfMutationHookResult = ReturnType<typeof useDeleteMyselfMutation>;
export type DeleteMyselfMutationResult = Apollo.MutationResult<DeleteMyselfMutation>;
export type DeleteMyselfMutationOptions = Apollo.BaseMutationOptions<DeleteMyselfMutation, DeleteMyselfMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $name: String!, $phoneNumber: String!, $freeText: String, $role: UserRole!, $active: Boolean!, $email: String!, $roleDescription: String) {
  updateUser(
    id: $id
    name: $name
    phoneNumber: $phoneNumber
    freeText: $freeText
    role: $role
    active: $active
    email: $email
    roleDescription: $roleDescription
  ) {
    user {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    error
    message
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      phoneNumber: // value for 'phoneNumber'
 *      freeText: // value for 'freeText'
 *      role: // value for 'role'
 *      active: // value for 'active'
 *      email: // value for 'email'
 *      roleDescription: // value for 'roleDescription'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateAcceptsEmailRemindersDocument = gql`
    mutation UpdateAcceptsEmailReminders($id: UUID!, $acceptsEmailReminders: Boolean!) {
  updateAcceptsEmailReminders(
    id: $id
    acceptsEmailReminders: $acceptsEmailReminders
  ) {
    id
    email
    name
    phoneNumber
    freeText
    role
    roleDescription
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    active
    acceptsEmailReminders
    createdAt
    updatedAt
  }
}
    `;
export type UpdateAcceptsEmailRemindersMutationFn = Apollo.MutationFunction<UpdateAcceptsEmailRemindersMutation, UpdateAcceptsEmailRemindersMutationVariables>;

/**
 * __useUpdateAcceptsEmailRemindersMutation__
 *
 * To run a mutation, you first call `useUpdateAcceptsEmailRemindersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAcceptsEmailRemindersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAcceptsEmailRemindersMutation, { data, loading, error }] = useUpdateAcceptsEmailRemindersMutation({
 *   variables: {
 *      id: // value for 'id'
 *      acceptsEmailReminders: // value for 'acceptsEmailReminders'
 *   },
 * });
 */
export function useUpdateAcceptsEmailRemindersMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAcceptsEmailRemindersMutation, UpdateAcceptsEmailRemindersMutationVariables>) {
        return Apollo.useMutation<UpdateAcceptsEmailRemindersMutation, UpdateAcceptsEmailRemindersMutationVariables>(UpdateAcceptsEmailRemindersDocument, baseOptions);
      }
export type UpdateAcceptsEmailRemindersMutationHookResult = ReturnType<typeof useUpdateAcceptsEmailRemindersMutation>;
export type UpdateAcceptsEmailRemindersMutationResult = Apollo.MutationResult<UpdateAcceptsEmailRemindersMutation>;
export type UpdateAcceptsEmailRemindersMutationOptions = Apollo.BaseMutationOptions<UpdateAcceptsEmailRemindersMutation, UpdateAcceptsEmailRemindersMutationVariables>;
export const CreateRetailerDocument = gql`
    mutation CreateRetailer($customerId: String!, $email: String!, $contactPersonName: String!, $phoneNumber: String, $retailerName: String!, $makePermissions: [UpdateMakePermissionsInput!]) {
  createRetailer(
    customerId: $customerId
    email: $email
    contactPersonName: $contactPersonName
    phoneNumber: $phoneNumber
    retailerName: $retailerName
    makePermissions: $makePermissions
  ) {
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    error
    message
  }
}
    `;
export type CreateRetailerMutationFn = Apollo.MutationFunction<CreateRetailerMutation, CreateRetailerMutationVariables>;

/**
 * __useCreateRetailerMutation__
 *
 * To run a mutation, you first call `useCreateRetailerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRetailerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRetailerMutation, { data, loading, error }] = useCreateRetailerMutation({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      email: // value for 'email'
 *      contactPersonName: // value for 'contactPersonName'
 *      phoneNumber: // value for 'phoneNumber'
 *      retailerName: // value for 'retailerName'
 *      makePermissions: // value for 'makePermissions'
 *   },
 * });
 */
export function useCreateRetailerMutation(baseOptions?: Apollo.MutationHookOptions<CreateRetailerMutation, CreateRetailerMutationVariables>) {
        return Apollo.useMutation<CreateRetailerMutation, CreateRetailerMutationVariables>(CreateRetailerDocument, baseOptions);
      }
export type CreateRetailerMutationHookResult = ReturnType<typeof useCreateRetailerMutation>;
export type CreateRetailerMutationResult = Apollo.MutationResult<CreateRetailerMutation>;
export type CreateRetailerMutationOptions = Apollo.BaseMutationOptions<CreateRetailerMutation, CreateRetailerMutationVariables>;
export const UpdateRetailerDocument = gql`
    mutation UpdateRetailer($retailerId: Int!, $retailerName: String!, $userName: String!, $userEmail: String!, $userPhoneNumber: String) {
  updateRetailer(
    retailerId: $retailerId
    retailerName: $retailerName
    userName: $userName
    userEmail: $userEmail
    userPhoneNumber: $userPhoneNumber
  ) {
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    error
    message
  }
}
    `;
export type UpdateRetailerMutationFn = Apollo.MutationFunction<UpdateRetailerMutation, UpdateRetailerMutationVariables>;

/**
 * __useUpdateRetailerMutation__
 *
 * To run a mutation, you first call `useUpdateRetailerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRetailerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRetailerMutation, { data, loading, error }] = useUpdateRetailerMutation({
 *   variables: {
 *      retailerId: // value for 'retailerId'
 *      retailerName: // value for 'retailerName'
 *      userName: // value for 'userName'
 *      userEmail: // value for 'userEmail'
 *      userPhoneNumber: // value for 'userPhoneNumber'
 *   },
 * });
 */
export function useUpdateRetailerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRetailerMutation, UpdateRetailerMutationVariables>) {
        return Apollo.useMutation<UpdateRetailerMutation, UpdateRetailerMutationVariables>(UpdateRetailerDocument, baseOptions);
      }
export type UpdateRetailerMutationHookResult = ReturnType<typeof useUpdateRetailerMutation>;
export type UpdateRetailerMutationResult = Apollo.MutationResult<UpdateRetailerMutation>;
export type UpdateRetailerMutationOptions = Apollo.BaseMutationOptions<UpdateRetailerMutation, UpdateRetailerMutationVariables>;
export const UpdateMaintenanceCustomerAccessDocument = gql`
    mutation UpdateMaintenanceCustomerAccess($retailerId: Int!, $maintenanceCustomerAccess: Boolean!) {
  updateMaintenanceCustomerAccess(
    retailerId: $retailerId
    maintenanceCustomerAccess: $maintenanceCustomerAccess
  ) {
    id
    externalId
    name
    address
    postalCode
    city
    country
    telephoneNumber
    email
    makes {
      id
      make {
        id
        externalId
        name
      }
      accessToSell
      accessToWorkshop
      warrantyHourlyPayment
    }
    users {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      createdAt
      updatedAt
    }
    contactUser {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      createdAt
      updatedAt
    }
    hasConsignmentVehicles
    hasExhibitionVehicles
    hasMaintenanceCustomers
    hasExternalFinancing
  }
}
    `;
export type UpdateMaintenanceCustomerAccessMutationFn = Apollo.MutationFunction<UpdateMaintenanceCustomerAccessMutation, UpdateMaintenanceCustomerAccessMutationVariables>;

/**
 * __useUpdateMaintenanceCustomerAccessMutation__
 *
 * To run a mutation, you first call `useUpdateMaintenanceCustomerAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaintenanceCustomerAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMaintenanceCustomerAccessMutation, { data, loading, error }] = useUpdateMaintenanceCustomerAccessMutation({
 *   variables: {
 *      retailerId: // value for 'retailerId'
 *      maintenanceCustomerAccess: // value for 'maintenanceCustomerAccess'
 *   },
 * });
 */
export function useUpdateMaintenanceCustomerAccessMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMaintenanceCustomerAccessMutation, UpdateMaintenanceCustomerAccessMutationVariables>) {
        return Apollo.useMutation<UpdateMaintenanceCustomerAccessMutation, UpdateMaintenanceCustomerAccessMutationVariables>(UpdateMaintenanceCustomerAccessDocument, baseOptions);
      }
export type UpdateMaintenanceCustomerAccessMutationHookResult = ReturnType<typeof useUpdateMaintenanceCustomerAccessMutation>;
export type UpdateMaintenanceCustomerAccessMutationResult = Apollo.MutationResult<UpdateMaintenanceCustomerAccessMutation>;
export type UpdateMaintenanceCustomerAccessMutationOptions = Apollo.BaseMutationOptions<UpdateMaintenanceCustomerAccessMutation, UpdateMaintenanceCustomerAccessMutationVariables>;
export const DeleteRetailerDocument = gql`
    mutation DeleteRetailer($id: Int!) {
  deleteRetailer(id: $id) {
    success
  }
}
    `;
export type DeleteRetailerMutationFn = Apollo.MutationFunction<DeleteRetailerMutation, DeleteRetailerMutationVariables>;

/**
 * __useDeleteRetailerMutation__
 *
 * To run a mutation, you first call `useDeleteRetailerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRetailerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRetailerMutation, { data, loading, error }] = useDeleteRetailerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRetailerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRetailerMutation, DeleteRetailerMutationVariables>) {
        return Apollo.useMutation<DeleteRetailerMutation, DeleteRetailerMutationVariables>(DeleteRetailerDocument, baseOptions);
      }
export type DeleteRetailerMutationHookResult = ReturnType<typeof useDeleteRetailerMutation>;
export type DeleteRetailerMutationResult = Apollo.MutationResult<DeleteRetailerMutation>;
export type DeleteRetailerMutationOptions = Apollo.BaseMutationOptions<DeleteRetailerMutation, DeleteRetailerMutationVariables>;
export const UpdateShippingCostDocument = gql`
    mutation UpdateShippingCost($id: ID!, $shippingCost: Float!) {
  updateShippingCost(id: $id, shippingCost: $shippingCost) {
    id
    status
    declineReason
    declineComment
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    customerRemark
    workshopRemark
    rows {
      id
      quantity
      originalQuantity
      price
      percentage
      originalPercentage
      total
      originalTotal
      referenceName
      description
      status
      complementReason
      complementComment
      declineReason
      declineComment
      type
      originalHours
      hours
      handledBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      ... on OperationRow {
        operationCode {
          id
          code
          category
          description
          time
        }
      }
      ... on ExternRow {
        purchaseRefersType
        receiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on ArticleRow {
        orderItem {
          id
          vehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          fee {
            id
            name
            articleNumber
            price
            vat
            discountedPrice
            createdAt
            updatedAt
            isAllowedOnVehicles
            active
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          detailedQuantities {
            processing
            allocated
            blocked
            delivered
            invoiced
          }
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
            externallyFinanced
          }
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          connectionType
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        legacyArticleNumber
        legacyOrderNumber
      }
      ... on LegacyRow {
        code
        originalComp
        comp
        originalPrice
        legacyType
      }
    }
    attachements {
      id
      name
      path
      mimeType
      isImage
      createdAt
    }
    registeredAt
    createdAt
    repairedAt
    sumRequested
    sumCredited
    type
    internalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    externalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    damageImage {
      id
      name
      path
      createdAt
    }
    shippingCost
    ... on VehicleWarranty {
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      meterReadingImage {
        id
        name
        path
        createdAt
      }
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      defectPartNumber
      defectPartTypeCode
      symptomCode
      operationalizedFrom {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          handledBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          ... on OperationRow {
            operationCode {
              id
              code
              category
              description
              time
            }
          }
          ... on ExternRow {
            purchaseRefersType
            receiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on ArticleRow {
            orderItem {
              id
              deliveredQuantity
              freeQuantity
              remark
              status
              finalPrice
              unitPrice
              stockArrivalDate
              connectionType
            }
            sparePart {
              id
              articleNumber
              name
              remark
              price
              discountedPrice
              vat
              active
              vehicleCampaign
            }
            billOfMaterial {
              id
              articleNumber
              active
              bomCode
              description
              fromDate
              toDate
              price
              discountedPrice
              vehicleCampaign
            }
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        operationalizedTo {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
      }
    }
    ... on GoodWillWarranty {
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      operationalizedTo {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          handledBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          ... on OperationRow {
            operationCode {
              id
              code
              category
              description
              time
            }
          }
          ... on ExternRow {
            purchaseRefersType
            receiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on ArticleRow {
            orderItem {
              id
              deliveredQuantity
              freeQuantity
              remark
              status
              finalPrice
              unitPrice
              stockArrivalDate
              connectionType
            }
            sparePart {
              id
              articleNumber
              name
              remark
              price
              discountedPrice
              vat
              active
              vehicleCampaign
            }
            billOfMaterial {
              id
              articleNumber
              active
              bomCode
              description
              fromDate
              toDate
              price
              discountedPrice
              vehicleCampaign
            }
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        meterReadingImage {
          id
          name
          path
          createdAt
        }
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        defectPartNumber
        defectPartTypeCode
        symptomCode
        operationalizedFrom {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
      }
    }
    ... on PartWarranty {
      purchaseReceiptImage {
        id
        name
        path
        createdAt
      }
    }
    ... on LegacyWarranty {
      legacyClaimNumber
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      legacyDealer
      causeOfDamage
      warrantyStartedAt
      receivedAt
    }
  }
}
    `;
export type UpdateShippingCostMutationFn = Apollo.MutationFunction<UpdateShippingCostMutation, UpdateShippingCostMutationVariables>;

/**
 * __useUpdateShippingCostMutation__
 *
 * To run a mutation, you first call `useUpdateShippingCostMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShippingCostMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShippingCostMutation, { data, loading, error }] = useUpdateShippingCostMutation({
 *   variables: {
 *      id: // value for 'id'
 *      shippingCost: // value for 'shippingCost'
 *   },
 * });
 */
export function useUpdateShippingCostMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShippingCostMutation, UpdateShippingCostMutationVariables>) {
        return Apollo.useMutation<UpdateShippingCostMutation, UpdateShippingCostMutationVariables>(UpdateShippingCostDocument, baseOptions);
      }
export type UpdateShippingCostMutationHookResult = ReturnType<typeof useUpdateShippingCostMutation>;
export type UpdateShippingCostMutationResult = Apollo.MutationResult<UpdateShippingCostMutation>;
export type UpdateShippingCostMutationOptions = Apollo.BaseMutationOptions<UpdateShippingCostMutation, UpdateShippingCostMutationVariables>;
export const VehicleWarrantyUpsertDocument = gql`
    mutation VehicleWarrantyUpsert($id: ID, $status: WarrantyStatus!, $customerRemark: String, $workshopRemark: String, $approvedRowsInput: [ApprovedRowInput!], $articleRowsInput: [ArticleRowInput!], $operationRowsInput: [OperationRowInput!], $externRowsInput: [ExternRowInput!], $imageInputs: [ImageUpload!], $repairedAt: DateTime, $meterReading: Float, $operatingTime: Float, $meterReadingImage: ImageUpload, $serviceBookImage: ImageUpload, $damageImage: ImageUpload, $defectPartNumber: String, $defectPartTypeCode: [String!], $symptomCode: String, $vehicleId: ID) {
  vehicleWarrantyUpsert(
    id: $id
    status: $status
    customerRemark: $customerRemark
    workshopRemark: $workshopRemark
    approvedRowsInput: $approvedRowsInput
    articleRowsInput: $articleRowsInput
    operationRowsInput: $operationRowsInput
    externRowsInput: $externRowsInput
    imageInputs: $imageInputs
    repairedAt: $repairedAt
    meterReading: $meterReading
    operatingTime: $operatingTime
    meterReadingImage: $meterReadingImage
    serviceBookImage: $serviceBookImage
    damageImage: $damageImage
    defectPartNumber: $defectPartNumber
    defectPartTypeCode: $defectPartTypeCode
    symptomCode: $symptomCode
    vehicleId: $vehicleId
  ) {
    id
    status
    declineReason
    declineComment
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    customerRemark
    workshopRemark
    rows {
      id
      quantity
      originalQuantity
      price
      percentage
      originalPercentage
      total
      originalTotal
      referenceName
      description
      status
      complementReason
      complementComment
      declineReason
      declineComment
      type
      originalHours
      hours
      handledBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      ... on OperationRow {
        operationCode {
          id
          code
          category
          description
          time
        }
      }
      ... on ExternRow {
        purchaseRefersType
        receiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on ArticleRow {
        orderItem {
          id
          vehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          fee {
            id
            name
            articleNumber
            price
            vat
            discountedPrice
            createdAt
            updatedAt
            isAllowedOnVehicles
            active
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          detailedQuantities {
            processing
            allocated
            blocked
            delivered
            invoiced
          }
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
            externallyFinanced
          }
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          connectionType
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        legacyArticleNumber
        legacyOrderNumber
      }
      ... on LegacyRow {
        code
        originalComp
        comp
        originalPrice
        legacyType
      }
    }
    attachements {
      id
      name
      path
      mimeType
      isImage
      createdAt
    }
    registeredAt
    createdAt
    repairedAt
    sumRequested
    sumCredited
    type
    internalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    externalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    damageImage {
      id
      name
      path
      createdAt
    }
    shippingCost
    vehicle {
      id
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      vehicleIdentificationNumber
      registrationNumber
      brand {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      startedAt
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      showDetailedInformation
      typeOfInstallation
      nonColorFactoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      createdAt
      updatedAt
      engineNumber
      factoryCode
      status
      firstArrivedToSweden
      producedAt
      firstRegisteredAt
      replacementText
      showBfiAndKey
      warranties {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          ... on ExternRow {
            purchaseRefersType
          }
          ... on ArticleRow {
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        ... on VehicleWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
        ... on GoodWillWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
        ... on PartWarranty {
          purchaseReceiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on LegacyWarranty {
          legacyClaimNumber
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          legacyDealer
          causeOfDamage
          warrantyStartedAt
          receivedAt
        }
      }
    }
    meterReading
    operatingTime
    meterReadingImage {
      id
      name
      path
      createdAt
    }
    serviceBookImage {
      id
      name
      path
      createdAt
    }
    defectPartNumber
    defectPartTypeCode
    symptomCode
    operationalizedFrom {
      id
      status
      declineReason
      declineComment
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      customerRemark
      workshopRemark
      rows {
        id
        quantity
        originalQuantity
        price
        percentage
        originalPercentage
        total
        originalTotal
        referenceName
        description
        status
        complementReason
        complementComment
        declineReason
        declineComment
        type
        originalHours
        hours
        handledBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        ... on OperationRow {
          operationCode {
            id
            code
            category
            description
            time
          }
        }
        ... on ExternRow {
          purchaseRefersType
          receiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on ArticleRow {
          orderItem {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          legacyArticleNumber
          legacyOrderNumber
        }
        ... on LegacyRow {
          code
          originalComp
          comp
          originalPrice
          legacyType
        }
      }
      attachements {
        id
        name
        path
        mimeType
        isImage
        createdAt
      }
      registeredAt
      createdAt
      repairedAt
      sumRequested
      sumCredited
      type
      internalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      externalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      damageImage {
        id
        name
        path
        createdAt
      }
      shippingCost
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          customerRemark
          workshopRemark
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          shippingCost
          ... on VehicleWarranty {
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
          ... on GoodWillWarranty {
            meterReading
            operatingTime
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      operationalizedTo {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          ... on ExternRow {
            purchaseRefersType
          }
          ... on ArticleRow {
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          vehicleIdentificationNumber
          registrationNumber
          startedAt
          showDetailedInformation
          typeOfInstallation
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
        }
        meterReading
        operatingTime
        meterReadingImage {
          id
          name
          path
          createdAt
        }
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        defectPartNumber
        defectPartTypeCode
        symptomCode
        operationalizedFrom {
          id
          status
          declineReason
          declineComment
          customerRemark
          workshopRemark
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          shippingCost
          meterReading
          operatingTime
        }
      }
    }
  }
}
    `;
export type VehicleWarrantyUpsertMutationFn = Apollo.MutationFunction<VehicleWarrantyUpsertMutation, VehicleWarrantyUpsertMutationVariables>;

/**
 * __useVehicleWarrantyUpsertMutation__
 *
 * To run a mutation, you first call `useVehicleWarrantyUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVehicleWarrantyUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vehicleWarrantyUpsertMutation, { data, loading, error }] = useVehicleWarrantyUpsertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      customerRemark: // value for 'customerRemark'
 *      workshopRemark: // value for 'workshopRemark'
 *      approvedRowsInput: // value for 'approvedRowsInput'
 *      articleRowsInput: // value for 'articleRowsInput'
 *      operationRowsInput: // value for 'operationRowsInput'
 *      externRowsInput: // value for 'externRowsInput'
 *      imageInputs: // value for 'imageInputs'
 *      repairedAt: // value for 'repairedAt'
 *      meterReading: // value for 'meterReading'
 *      operatingTime: // value for 'operatingTime'
 *      meterReadingImage: // value for 'meterReadingImage'
 *      serviceBookImage: // value for 'serviceBookImage'
 *      damageImage: // value for 'damageImage'
 *      defectPartNumber: // value for 'defectPartNumber'
 *      defectPartTypeCode: // value for 'defectPartTypeCode'
 *      symptomCode: // value for 'symptomCode'
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useVehicleWarrantyUpsertMutation(baseOptions?: Apollo.MutationHookOptions<VehicleWarrantyUpsertMutation, VehicleWarrantyUpsertMutationVariables>) {
        return Apollo.useMutation<VehicleWarrantyUpsertMutation, VehicleWarrantyUpsertMutationVariables>(VehicleWarrantyUpsertDocument, baseOptions);
      }
export type VehicleWarrantyUpsertMutationHookResult = ReturnType<typeof useVehicleWarrantyUpsertMutation>;
export type VehicleWarrantyUpsertMutationResult = Apollo.MutationResult<VehicleWarrantyUpsertMutation>;
export type VehicleWarrantyUpsertMutationOptions = Apollo.BaseMutationOptions<VehicleWarrantyUpsertMutation, VehicleWarrantyUpsertMutationVariables>;
export const PartWarrantyUpsertDocument = gql`
    mutation PartWarrantyUpsert($id: ID, $status: WarrantyStatus!, $customerRemark: String, $workshopRemark: String, $articleRowsInput: [ArticleRowInput!], $operationRowsInput: [OperationRowInput!], $externRowsInput: [ExternRowInput!], $imageInputs: [ImageUpload!], $repairedAt: DateTime, $purchaseReceiptImage: ImageUpload, $damageImage: ImageUpload) {
  partWarrantyUpsert(
    id: $id
    status: $status
    customerRemark: $customerRemark
    workshopRemark: $workshopRemark
    articleRowsInput: $articleRowsInput
    operationRowsInput: $operationRowsInput
    externRowsInput: $externRowsInput
    imageInputs: $imageInputs
    repairedAt: $repairedAt
    purchaseReceiptImage: $purchaseReceiptImage
    damageImage: $damageImage
  ) {
    id
    status
    declineReason
    declineComment
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    customerRemark
    workshopRemark
    rows {
      id
      quantity
      originalQuantity
      price
      percentage
      originalPercentage
      total
      originalTotal
      referenceName
      description
      status
      complementReason
      complementComment
      declineReason
      declineComment
      type
      originalHours
      hours
      handledBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      ... on OperationRow {
        operationCode {
          id
          code
          category
          description
          time
        }
      }
      ... on ExternRow {
        purchaseRefersType
        receiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on ArticleRow {
        orderItem {
          id
          vehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          fee {
            id
            name
            articleNumber
            price
            vat
            discountedPrice
            createdAt
            updatedAt
            isAllowedOnVehicles
            active
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          detailedQuantities {
            processing
            allocated
            blocked
            delivered
            invoiced
          }
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
            externallyFinanced
          }
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          connectionType
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        legacyArticleNumber
        legacyOrderNumber
      }
      ... on LegacyRow {
        code
        originalComp
        comp
        originalPrice
        legacyType
      }
    }
    attachements {
      id
      name
      path
      mimeType
      isImage
      createdAt
    }
    registeredAt
    createdAt
    repairedAt
    sumRequested
    sumCredited
    type
    internalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    externalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    damageImage {
      id
      name
      path
      createdAt
    }
    shippingCost
    purchaseReceiptImage {
      id
      name
      path
      createdAt
    }
  }
}
    `;
export type PartWarrantyUpsertMutationFn = Apollo.MutationFunction<PartWarrantyUpsertMutation, PartWarrantyUpsertMutationVariables>;

/**
 * __usePartWarrantyUpsertMutation__
 *
 * To run a mutation, you first call `usePartWarrantyUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePartWarrantyUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [partWarrantyUpsertMutation, { data, loading, error }] = usePartWarrantyUpsertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      customerRemark: // value for 'customerRemark'
 *      workshopRemark: // value for 'workshopRemark'
 *      articleRowsInput: // value for 'articleRowsInput'
 *      operationRowsInput: // value for 'operationRowsInput'
 *      externRowsInput: // value for 'externRowsInput'
 *      imageInputs: // value for 'imageInputs'
 *      repairedAt: // value for 'repairedAt'
 *      purchaseReceiptImage: // value for 'purchaseReceiptImage'
 *      damageImage: // value for 'damageImage'
 *   },
 * });
 */
export function usePartWarrantyUpsertMutation(baseOptions?: Apollo.MutationHookOptions<PartWarrantyUpsertMutation, PartWarrantyUpsertMutationVariables>) {
        return Apollo.useMutation<PartWarrantyUpsertMutation, PartWarrantyUpsertMutationVariables>(PartWarrantyUpsertDocument, baseOptions);
      }
export type PartWarrantyUpsertMutationHookResult = ReturnType<typeof usePartWarrantyUpsertMutation>;
export type PartWarrantyUpsertMutationResult = Apollo.MutationResult<PartWarrantyUpsertMutation>;
export type PartWarrantyUpsertMutationOptions = Apollo.BaseMutationOptions<PartWarrantyUpsertMutation, PartWarrantyUpsertMutationVariables>;
export const GoodWillWarrantyUpsertDocument = gql`
    mutation GoodWillWarrantyUpsert($id: ID, $status: WarrantyStatus!, $customerRemark: String, $workshopRemark: String, $articleRowsInput: [ArticleRowInput!], $operationRowsInput: [OperationRowInput!], $externRowsInput: [ExternRowInput!], $imageInputs: [ImageUpload!], $repairedAt: DateTime, $type: GoodWillType, $vehicleId: ID, $meterReading: Float, $operatingTime: Float, $serviceBookImage: ImageUpload, $damageImage: ImageUpload) {
  goodWillWarrantyUpsert(
    id: $id
    status: $status
    customerRemark: $customerRemark
    workshopRemark: $workshopRemark
    articleRowsInput: $articleRowsInput
    operationRowsInput: $operationRowsInput
    externRowsInput: $externRowsInput
    imageInputs: $imageInputs
    repairedAt: $repairedAt
    type: $type
    vehicleId: $vehicleId
    meterReading: $meterReading
    operatingTime: $operatingTime
    serviceBookImage: $serviceBookImage
    damageImage: $damageImage
  ) {
    id
    status
    declineReason
    declineComment
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    customerRemark
    workshopRemark
    rows {
      id
      quantity
      originalQuantity
      price
      percentage
      originalPercentage
      total
      originalTotal
      referenceName
      description
      status
      complementReason
      complementComment
      declineReason
      declineComment
      type
      originalHours
      hours
      handledBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      ... on OperationRow {
        operationCode {
          id
          code
          category
          description
          time
        }
      }
      ... on ExternRow {
        purchaseRefersType
        receiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on ArticleRow {
        orderItem {
          id
          vehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          fee {
            id
            name
            articleNumber
            price
            vat
            discountedPrice
            createdAt
            updatedAt
            isAllowedOnVehicles
            active
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          detailedQuantities {
            processing
            allocated
            blocked
            delivered
            invoiced
          }
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
            externallyFinanced
          }
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          connectionType
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        legacyArticleNumber
        legacyOrderNumber
      }
      ... on LegacyRow {
        code
        originalComp
        comp
        originalPrice
        legacyType
      }
    }
    attachements {
      id
      name
      path
      mimeType
      isImage
      createdAt
    }
    registeredAt
    createdAt
    repairedAt
    sumRequested
    sumCredited
    type
    internalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    externalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    damageImage {
      id
      name
      path
      createdAt
    }
    shippingCost
    vehicle {
      id
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      vehicleIdentificationNumber
      registrationNumber
      brand {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      startedAt
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      showDetailedInformation
      typeOfInstallation
      nonColorFactoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      createdAt
      updatedAt
      engineNumber
      factoryCode
      status
      firstArrivedToSweden
      producedAt
      firstRegisteredAt
      replacementText
      showBfiAndKey
      warranties {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          ... on ExternRow {
            purchaseRefersType
          }
          ... on ArticleRow {
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        ... on VehicleWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
        ... on GoodWillWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
        ... on PartWarranty {
          purchaseReceiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on LegacyWarranty {
          legacyClaimNumber
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          legacyDealer
          causeOfDamage
          warrantyStartedAt
          receivedAt
        }
      }
    }
    meterReading
    operatingTime
    serviceBookImage {
      id
      name
      path
      createdAt
    }
    operationalizedTo {
      id
      status
      declineReason
      declineComment
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      customerRemark
      workshopRemark
      rows {
        id
        quantity
        originalQuantity
        price
        percentage
        originalPercentage
        total
        originalTotal
        referenceName
        description
        status
        complementReason
        complementComment
        declineReason
        declineComment
        type
        originalHours
        hours
        handledBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        ... on OperationRow {
          operationCode {
            id
            code
            category
            description
            time
          }
        }
        ... on ExternRow {
          purchaseRefersType
          receiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on ArticleRow {
          orderItem {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          legacyArticleNumber
          legacyOrderNumber
        }
        ... on LegacyRow {
          code
          originalComp
          comp
          originalPrice
          legacyType
        }
      }
      attachements {
        id
        name
        path
        mimeType
        isImage
        createdAt
      }
      registeredAt
      createdAt
      repairedAt
      sumRequested
      sumCredited
      type
      internalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      externalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      damageImage {
        id
        name
        path
        createdAt
      }
      shippingCost
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          customerRemark
          workshopRemark
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          shippingCost
          ... on VehicleWarranty {
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
          ... on GoodWillWarranty {
            meterReading
            operatingTime
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      meterReadingImage {
        id
        name
        path
        createdAt
      }
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      defectPartNumber
      defectPartTypeCode
      symptomCode
      operationalizedFrom {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          ... on ExternRow {
            purchaseRefersType
          }
          ... on ArticleRow {
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          vehicleIdentificationNumber
          registrationNumber
          startedAt
          showDetailedInformation
          typeOfInstallation
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
        }
        meterReading
        operatingTime
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        operationalizedTo {
          id
          status
          declineReason
          declineComment
          customerRemark
          workshopRemark
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          shippingCost
          meterReading
          operatingTime
          defectPartNumber
          defectPartTypeCode
          symptomCode
        }
      }
    }
  }
}
    `;
export type GoodWillWarrantyUpsertMutationFn = Apollo.MutationFunction<GoodWillWarrantyUpsertMutation, GoodWillWarrantyUpsertMutationVariables>;

/**
 * __useGoodWillWarrantyUpsertMutation__
 *
 * To run a mutation, you first call `useGoodWillWarrantyUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoodWillWarrantyUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [goodWillWarrantyUpsertMutation, { data, loading, error }] = useGoodWillWarrantyUpsertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *      customerRemark: // value for 'customerRemark'
 *      workshopRemark: // value for 'workshopRemark'
 *      articleRowsInput: // value for 'articleRowsInput'
 *      operationRowsInput: // value for 'operationRowsInput'
 *      externRowsInput: // value for 'externRowsInput'
 *      imageInputs: // value for 'imageInputs'
 *      repairedAt: // value for 'repairedAt'
 *      type: // value for 'type'
 *      vehicleId: // value for 'vehicleId'
 *      meterReading: // value for 'meterReading'
 *      operatingTime: // value for 'operatingTime'
 *      serviceBookImage: // value for 'serviceBookImage'
 *      damageImage: // value for 'damageImage'
 *   },
 * });
 */
export function useGoodWillWarrantyUpsertMutation(baseOptions?: Apollo.MutationHookOptions<GoodWillWarrantyUpsertMutation, GoodWillWarrantyUpsertMutationVariables>) {
        return Apollo.useMutation<GoodWillWarrantyUpsertMutation, GoodWillWarrantyUpsertMutationVariables>(GoodWillWarrantyUpsertDocument, baseOptions);
      }
export type GoodWillWarrantyUpsertMutationHookResult = ReturnType<typeof useGoodWillWarrantyUpsertMutation>;
export type GoodWillWarrantyUpsertMutationResult = Apollo.MutationResult<GoodWillWarrantyUpsertMutation>;
export type GoodWillWarrantyUpsertMutationOptions = Apollo.BaseMutationOptions<GoodWillWarrantyUpsertMutation, GoodWillWarrantyUpsertMutationVariables>;
export const LegacyWarrantyUpsertDocument = gql`
    mutation LegacyWarrantyUpsert($spreedSheet: UploadFile!) {
  legacyWarrantyUpsert(spreedSheet: $spreedSheet) {
    success
  }
}
    `;
export type LegacyWarrantyUpsertMutationFn = Apollo.MutationFunction<LegacyWarrantyUpsertMutation, LegacyWarrantyUpsertMutationVariables>;

/**
 * __useLegacyWarrantyUpsertMutation__
 *
 * To run a mutation, you first call `useLegacyWarrantyUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLegacyWarrantyUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [legacyWarrantyUpsertMutation, { data, loading, error }] = useLegacyWarrantyUpsertMutation({
 *   variables: {
 *      spreedSheet: // value for 'spreedSheet'
 *   },
 * });
 */
export function useLegacyWarrantyUpsertMutation(baseOptions?: Apollo.MutationHookOptions<LegacyWarrantyUpsertMutation, LegacyWarrantyUpsertMutationVariables>) {
        return Apollo.useMutation<LegacyWarrantyUpsertMutation, LegacyWarrantyUpsertMutationVariables>(LegacyWarrantyUpsertDocument, baseOptions);
      }
export type LegacyWarrantyUpsertMutationHookResult = ReturnType<typeof useLegacyWarrantyUpsertMutation>;
export type LegacyWarrantyUpsertMutationResult = Apollo.MutationResult<LegacyWarrantyUpsertMutation>;
export type LegacyWarrantyUpsertMutationOptions = Apollo.BaseMutationOptions<LegacyWarrantyUpsertMutation, LegacyWarrantyUpsertMutationVariables>;
export const DeleteDraftWarrantyDocument = gql`
    mutation DeleteDraftWarranty($id: ID!) {
  deleteDraftWarranty(id: $id) {
    success
  }
}
    `;
export type DeleteDraftWarrantyMutationFn = Apollo.MutationFunction<DeleteDraftWarrantyMutation, DeleteDraftWarrantyMutationVariables>;

/**
 * __useDeleteDraftWarrantyMutation__
 *
 * To run a mutation, you first call `useDeleteDraftWarrantyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftWarrantyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftWarrantyMutation, { data, loading, error }] = useDeleteDraftWarrantyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteDraftWarrantyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDraftWarrantyMutation, DeleteDraftWarrantyMutationVariables>) {
        return Apollo.useMutation<DeleteDraftWarrantyMutation, DeleteDraftWarrantyMutationVariables>(DeleteDraftWarrantyDocument, baseOptions);
      }
export type DeleteDraftWarrantyMutationHookResult = ReturnType<typeof useDeleteDraftWarrantyMutation>;
export type DeleteDraftWarrantyMutationResult = Apollo.MutationResult<DeleteDraftWarrantyMutation>;
export type DeleteDraftWarrantyMutationOptions = Apollo.BaseMutationOptions<DeleteDraftWarrantyMutation, DeleteDraftWarrantyMutationVariables>;
export const WarrantyChangeRowStatusDocument = gql`
    mutation WarrantyChangeRowStatus($warrantyId: ID!, $rowsChangeStatus: [RowChangeStatusInput!]!) {
  warrantyChangeRowStatus(
    warrantyId: $warrantyId
    rowsChangeStatus: $rowsChangeStatus
  ) {
    id
    status
    declineReason
    declineComment
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    customerRemark
    workshopRemark
    rows {
      id
      quantity
      originalQuantity
      price
      percentage
      originalPercentage
      total
      originalTotal
      referenceName
      description
      status
      complementReason
      complementComment
      declineReason
      declineComment
      type
      originalHours
      hours
      handledBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      ... on OperationRow {
        operationCode {
          id
          code
          category
          description
          time
        }
      }
      ... on ExternRow {
        purchaseRefersType
        receiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on ArticleRow {
        orderItem {
          id
          vehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          fee {
            id
            name
            articleNumber
            price
            vat
            discountedPrice
            createdAt
            updatedAt
            isAllowedOnVehicles
            active
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          detailedQuantities {
            processing
            allocated
            blocked
            delivered
            invoiced
          }
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
            externallyFinanced
          }
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          connectionType
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        legacyArticleNumber
        legacyOrderNumber
      }
      ... on LegacyRow {
        code
        originalComp
        comp
        originalPrice
        legacyType
      }
    }
    attachements {
      id
      name
      path
      mimeType
      isImage
      createdAt
    }
    registeredAt
    createdAt
    repairedAt
    sumRequested
    sumCredited
    type
    internalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    externalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    damageImage {
      id
      name
      path
      createdAt
    }
    shippingCost
    ... on VehicleWarranty {
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      meterReadingImage {
        id
        name
        path
        createdAt
      }
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      defectPartNumber
      defectPartTypeCode
      symptomCode
      operationalizedFrom {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          handledBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          ... on OperationRow {
            operationCode {
              id
              code
              category
              description
              time
            }
          }
          ... on ExternRow {
            purchaseRefersType
            receiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on ArticleRow {
            orderItem {
              id
              deliveredQuantity
              freeQuantity
              remark
              status
              finalPrice
              unitPrice
              stockArrivalDate
              connectionType
            }
            sparePart {
              id
              articleNumber
              name
              remark
              price
              discountedPrice
              vat
              active
              vehicleCampaign
            }
            billOfMaterial {
              id
              articleNumber
              active
              bomCode
              description
              fromDate
              toDate
              price
              discountedPrice
              vehicleCampaign
            }
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        operationalizedTo {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
      }
    }
    ... on GoodWillWarranty {
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      operationalizedTo {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          handledBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          ... on OperationRow {
            operationCode {
              id
              code
              category
              description
              time
            }
          }
          ... on ExternRow {
            purchaseRefersType
            receiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on ArticleRow {
            orderItem {
              id
              deliveredQuantity
              freeQuantity
              remark
              status
              finalPrice
              unitPrice
              stockArrivalDate
              connectionType
            }
            sparePart {
              id
              articleNumber
              name
              remark
              price
              discountedPrice
              vat
              active
              vehicleCampaign
            }
            billOfMaterial {
              id
              articleNumber
              active
              bomCode
              description
              fromDate
              toDate
              price
              discountedPrice
              vehicleCampaign
            }
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        meterReadingImage {
          id
          name
          path
          createdAt
        }
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        defectPartNumber
        defectPartTypeCode
        symptomCode
        operationalizedFrom {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
      }
    }
    ... on PartWarranty {
      purchaseReceiptImage {
        id
        name
        path
        createdAt
      }
    }
    ... on LegacyWarranty {
      legacyClaimNumber
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      legacyDealer
      causeOfDamage
      warrantyStartedAt
      receivedAt
    }
  }
}
    `;
export type WarrantyChangeRowStatusMutationFn = Apollo.MutationFunction<WarrantyChangeRowStatusMutation, WarrantyChangeRowStatusMutationVariables>;

/**
 * __useWarrantyChangeRowStatusMutation__
 *
 * To run a mutation, you first call `useWarrantyChangeRowStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWarrantyChangeRowStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [warrantyChangeRowStatusMutation, { data, loading, error }] = useWarrantyChangeRowStatusMutation({
 *   variables: {
 *      warrantyId: // value for 'warrantyId'
 *      rowsChangeStatus: // value for 'rowsChangeStatus'
 *   },
 * });
 */
export function useWarrantyChangeRowStatusMutation(baseOptions?: Apollo.MutationHookOptions<WarrantyChangeRowStatusMutation, WarrantyChangeRowStatusMutationVariables>) {
        return Apollo.useMutation<WarrantyChangeRowStatusMutation, WarrantyChangeRowStatusMutationVariables>(WarrantyChangeRowStatusDocument, baseOptions);
      }
export type WarrantyChangeRowStatusMutationHookResult = ReturnType<typeof useWarrantyChangeRowStatusMutation>;
export type WarrantyChangeRowStatusMutationResult = Apollo.MutationResult<WarrantyChangeRowStatusMutation>;
export type WarrantyChangeRowStatusMutationOptions = Apollo.BaseMutationOptions<WarrantyChangeRowStatusMutation, WarrantyChangeRowStatusMutationVariables>;
export const WarrantyAddOperationRowDocument = gql`
    mutation WarrantyAddOperationRow($warrantyId: ID!, $operationRowInput: OperationRowInput!) {
  warrantyAddOperationRow(
    warrantyId: $warrantyId
    operationRowInput: $operationRowInput
  ) {
    id
    status
    declineReason
    declineComment
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    customerRemark
    workshopRemark
    rows {
      id
      quantity
      originalQuantity
      price
      percentage
      originalPercentage
      total
      originalTotal
      referenceName
      description
      status
      complementReason
      complementComment
      declineReason
      declineComment
      type
      originalHours
      hours
      handledBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      ... on OperationRow {
        operationCode {
          id
          code
          category
          description
          time
        }
      }
      ... on ExternRow {
        purchaseRefersType
        receiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on ArticleRow {
        orderItem {
          id
          vehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          fee {
            id
            name
            articleNumber
            price
            vat
            discountedPrice
            createdAt
            updatedAt
            isAllowedOnVehicles
            active
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          detailedQuantities {
            processing
            allocated
            blocked
            delivered
            invoiced
          }
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
            externallyFinanced
          }
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          connectionType
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        legacyArticleNumber
        legacyOrderNumber
      }
      ... on LegacyRow {
        code
        originalComp
        comp
        originalPrice
        legacyType
      }
    }
    attachements {
      id
      name
      path
      mimeType
      isImage
      createdAt
    }
    registeredAt
    createdAt
    repairedAt
    sumRequested
    sumCredited
    type
    internalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    externalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    damageImage {
      id
      name
      path
      createdAt
    }
    shippingCost
    ... on VehicleWarranty {
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      meterReadingImage {
        id
        name
        path
        createdAt
      }
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      defectPartNumber
      defectPartTypeCode
      symptomCode
      operationalizedFrom {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          handledBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          ... on OperationRow {
            operationCode {
              id
              code
              category
              description
              time
            }
          }
          ... on ExternRow {
            purchaseRefersType
            receiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on ArticleRow {
            orderItem {
              id
              deliveredQuantity
              freeQuantity
              remark
              status
              finalPrice
              unitPrice
              stockArrivalDate
              connectionType
            }
            sparePart {
              id
              articleNumber
              name
              remark
              price
              discountedPrice
              vat
              active
              vehicleCampaign
            }
            billOfMaterial {
              id
              articleNumber
              active
              bomCode
              description
              fromDate
              toDate
              price
              discountedPrice
              vehicleCampaign
            }
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        operationalizedTo {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
      }
    }
    ... on GoodWillWarranty {
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      operationalizedTo {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          handledBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          ... on OperationRow {
            operationCode {
              id
              code
              category
              description
              time
            }
          }
          ... on ExternRow {
            purchaseRefersType
            receiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on ArticleRow {
            orderItem {
              id
              deliveredQuantity
              freeQuantity
              remark
              status
              finalPrice
              unitPrice
              stockArrivalDate
              connectionType
            }
            sparePart {
              id
              articleNumber
              name
              remark
              price
              discountedPrice
              vat
              active
              vehicleCampaign
            }
            billOfMaterial {
              id
              articleNumber
              active
              bomCode
              description
              fromDate
              toDate
              price
              discountedPrice
              vehicleCampaign
            }
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        meterReadingImage {
          id
          name
          path
          createdAt
        }
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        defectPartNumber
        defectPartTypeCode
        symptomCode
        operationalizedFrom {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
      }
    }
    ... on PartWarranty {
      purchaseReceiptImage {
        id
        name
        path
        createdAt
      }
    }
    ... on LegacyWarranty {
      legacyClaimNumber
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      legacyDealer
      causeOfDamage
      warrantyStartedAt
      receivedAt
    }
  }
}
    `;
export type WarrantyAddOperationRowMutationFn = Apollo.MutationFunction<WarrantyAddOperationRowMutation, WarrantyAddOperationRowMutationVariables>;

/**
 * __useWarrantyAddOperationRowMutation__
 *
 * To run a mutation, you first call `useWarrantyAddOperationRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWarrantyAddOperationRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [warrantyAddOperationRowMutation, { data, loading, error }] = useWarrantyAddOperationRowMutation({
 *   variables: {
 *      warrantyId: // value for 'warrantyId'
 *      operationRowInput: // value for 'operationRowInput'
 *   },
 * });
 */
export function useWarrantyAddOperationRowMutation(baseOptions?: Apollo.MutationHookOptions<WarrantyAddOperationRowMutation, WarrantyAddOperationRowMutationVariables>) {
        return Apollo.useMutation<WarrantyAddOperationRowMutation, WarrantyAddOperationRowMutationVariables>(WarrantyAddOperationRowDocument, baseOptions);
      }
export type WarrantyAddOperationRowMutationHookResult = ReturnType<typeof useWarrantyAddOperationRowMutation>;
export type WarrantyAddOperationRowMutationResult = Apollo.MutationResult<WarrantyAddOperationRowMutation>;
export type WarrantyAddOperationRowMutationOptions = Apollo.BaseMutationOptions<WarrantyAddOperationRowMutation, WarrantyAddOperationRowMutationVariables>;
export const WarrantyAddArticleRowDocument = gql`
    mutation WarrantyAddArticleRow($warrantyId: ID!, $articleRowInput: ArticleRowInput!) {
  warrantyAddArticleRow(
    warrantyId: $warrantyId
    articleRowInput: $articleRowInput
  ) {
    id
    status
    declineReason
    declineComment
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    customerRemark
    workshopRemark
    rows {
      id
      quantity
      originalQuantity
      price
      percentage
      originalPercentage
      total
      originalTotal
      referenceName
      description
      status
      complementReason
      complementComment
      declineReason
      declineComment
      type
      originalHours
      hours
      handledBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      ... on OperationRow {
        operationCode {
          id
          code
          category
          description
          time
        }
      }
      ... on ExternRow {
        purchaseRefersType
        receiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on ArticleRow {
        orderItem {
          id
          vehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          fee {
            id
            name
            articleNumber
            price
            vat
            discountedPrice
            createdAt
            updatedAt
            isAllowedOnVehicles
            active
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          detailedQuantities {
            processing
            allocated
            blocked
            delivered
            invoiced
          }
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
            externallyFinanced
          }
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          connectionType
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        legacyArticleNumber
        legacyOrderNumber
      }
      ... on LegacyRow {
        code
        originalComp
        comp
        originalPrice
        legacyType
      }
    }
    attachements {
      id
      name
      path
      mimeType
      isImage
      createdAt
    }
    registeredAt
    createdAt
    repairedAt
    sumRequested
    sumCredited
    type
    internalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    externalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    damageImage {
      id
      name
      path
      createdAt
    }
    shippingCost
    ... on VehicleWarranty {
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      meterReadingImage {
        id
        name
        path
        createdAt
      }
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      defectPartNumber
      defectPartTypeCode
      symptomCode
      operationalizedFrom {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          handledBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          ... on OperationRow {
            operationCode {
              id
              code
              category
              description
              time
            }
          }
          ... on ExternRow {
            purchaseRefersType
            receiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on ArticleRow {
            orderItem {
              id
              deliveredQuantity
              freeQuantity
              remark
              status
              finalPrice
              unitPrice
              stockArrivalDate
              connectionType
            }
            sparePart {
              id
              articleNumber
              name
              remark
              price
              discountedPrice
              vat
              active
              vehicleCampaign
            }
            billOfMaterial {
              id
              articleNumber
              active
              bomCode
              description
              fromDate
              toDate
              price
              discountedPrice
              vehicleCampaign
            }
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        operationalizedTo {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
      }
    }
    ... on GoodWillWarranty {
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      operationalizedTo {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          handledBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          ... on OperationRow {
            operationCode {
              id
              code
              category
              description
              time
            }
          }
          ... on ExternRow {
            purchaseRefersType
            receiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on ArticleRow {
            orderItem {
              id
              deliveredQuantity
              freeQuantity
              remark
              status
              finalPrice
              unitPrice
              stockArrivalDate
              connectionType
            }
            sparePart {
              id
              articleNumber
              name
              remark
              price
              discountedPrice
              vat
              active
              vehicleCampaign
            }
            billOfMaterial {
              id
              articleNumber
              active
              bomCode
              description
              fromDate
              toDate
              price
              discountedPrice
              vehicleCampaign
            }
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        meterReadingImage {
          id
          name
          path
          createdAt
        }
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        defectPartNumber
        defectPartTypeCode
        symptomCode
        operationalizedFrom {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
      }
    }
    ... on PartWarranty {
      purchaseReceiptImage {
        id
        name
        path
        createdAt
      }
    }
    ... on LegacyWarranty {
      legacyClaimNumber
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      legacyDealer
      causeOfDamage
      warrantyStartedAt
      receivedAt
    }
  }
}
    `;
export type WarrantyAddArticleRowMutationFn = Apollo.MutationFunction<WarrantyAddArticleRowMutation, WarrantyAddArticleRowMutationVariables>;

/**
 * __useWarrantyAddArticleRowMutation__
 *
 * To run a mutation, you first call `useWarrantyAddArticleRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWarrantyAddArticleRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [warrantyAddArticleRowMutation, { data, loading, error }] = useWarrantyAddArticleRowMutation({
 *   variables: {
 *      warrantyId: // value for 'warrantyId'
 *      articleRowInput: // value for 'articleRowInput'
 *   },
 * });
 */
export function useWarrantyAddArticleRowMutation(baseOptions?: Apollo.MutationHookOptions<WarrantyAddArticleRowMutation, WarrantyAddArticleRowMutationVariables>) {
        return Apollo.useMutation<WarrantyAddArticleRowMutation, WarrantyAddArticleRowMutationVariables>(WarrantyAddArticleRowDocument, baseOptions);
      }
export type WarrantyAddArticleRowMutationHookResult = ReturnType<typeof useWarrantyAddArticleRowMutation>;
export type WarrantyAddArticleRowMutationResult = Apollo.MutationResult<WarrantyAddArticleRowMutation>;
export type WarrantyAddArticleRowMutationOptions = Apollo.BaseMutationOptions<WarrantyAddArticleRowMutation, WarrantyAddArticleRowMutationVariables>;
export const WarrantyAddExternalCostRowDocument = gql`
    mutation WarrantyAddExternalCostRow($warrantyId: ID!, $externalRowInput: ExternRowInput!) {
  warrantyAddExternalCostRow(
    warrantyId: $warrantyId
    externalRowInput: $externalRowInput
  ) {
    id
    status
    declineReason
    declineComment
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    customerRemark
    workshopRemark
    rows {
      id
      quantity
      originalQuantity
      price
      percentage
      originalPercentage
      total
      originalTotal
      referenceName
      description
      status
      complementReason
      complementComment
      declineReason
      declineComment
      type
      originalHours
      hours
      handledBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      ... on OperationRow {
        operationCode {
          id
          code
          category
          description
          time
        }
      }
      ... on ExternRow {
        purchaseRefersType
        receiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on ArticleRow {
        orderItem {
          id
          vehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          fee {
            id
            name
            articleNumber
            price
            vat
            discountedPrice
            createdAt
            updatedAt
            isAllowedOnVehicles
            active
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          detailedQuantities {
            processing
            allocated
            blocked
            delivered
            invoiced
          }
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
            externallyFinanced
          }
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          connectionType
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        legacyArticleNumber
        legacyOrderNumber
      }
      ... on LegacyRow {
        code
        originalComp
        comp
        originalPrice
        legacyType
      }
    }
    attachements {
      id
      name
      path
      mimeType
      isImage
      createdAt
    }
    registeredAt
    createdAt
    repairedAt
    sumRequested
    sumCredited
    type
    internalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    externalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    damageImage {
      id
      name
      path
      createdAt
    }
    shippingCost
    ... on VehicleWarranty {
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      meterReadingImage {
        id
        name
        path
        createdAt
      }
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      defectPartNumber
      defectPartTypeCode
      symptomCode
      operationalizedFrom {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          handledBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          ... on OperationRow {
            operationCode {
              id
              code
              category
              description
              time
            }
          }
          ... on ExternRow {
            purchaseRefersType
            receiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on ArticleRow {
            orderItem {
              id
              deliveredQuantity
              freeQuantity
              remark
              status
              finalPrice
              unitPrice
              stockArrivalDate
              connectionType
            }
            sparePart {
              id
              articleNumber
              name
              remark
              price
              discountedPrice
              vat
              active
              vehicleCampaign
            }
            billOfMaterial {
              id
              articleNumber
              active
              bomCode
              description
              fromDate
              toDate
              price
              discountedPrice
              vehicleCampaign
            }
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        operationalizedTo {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
      }
    }
    ... on GoodWillWarranty {
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      operationalizedTo {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          handledBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          ... on OperationRow {
            operationCode {
              id
              code
              category
              description
              time
            }
          }
          ... on ExternRow {
            purchaseRefersType
            receiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on ArticleRow {
            orderItem {
              id
              deliveredQuantity
              freeQuantity
              remark
              status
              finalPrice
              unitPrice
              stockArrivalDate
              connectionType
            }
            sparePart {
              id
              articleNumber
              name
              remark
              price
              discountedPrice
              vat
              active
              vehicleCampaign
            }
            billOfMaterial {
              id
              articleNumber
              active
              bomCode
              description
              fromDate
              toDate
              price
              discountedPrice
              vehicleCampaign
            }
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        meterReadingImage {
          id
          name
          path
          createdAt
        }
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        defectPartNumber
        defectPartTypeCode
        symptomCode
        operationalizedFrom {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
      }
    }
    ... on PartWarranty {
      purchaseReceiptImage {
        id
        name
        path
        createdAt
      }
    }
    ... on LegacyWarranty {
      legacyClaimNumber
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      legacyDealer
      causeOfDamage
      warrantyStartedAt
      receivedAt
    }
  }
}
    `;
export type WarrantyAddExternalCostRowMutationFn = Apollo.MutationFunction<WarrantyAddExternalCostRowMutation, WarrantyAddExternalCostRowMutationVariables>;

/**
 * __useWarrantyAddExternalCostRowMutation__
 *
 * To run a mutation, you first call `useWarrantyAddExternalCostRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWarrantyAddExternalCostRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [warrantyAddExternalCostRowMutation, { data, loading, error }] = useWarrantyAddExternalCostRowMutation({
 *   variables: {
 *      warrantyId: // value for 'warrantyId'
 *      externalRowInput: // value for 'externalRowInput'
 *   },
 * });
 */
export function useWarrantyAddExternalCostRowMutation(baseOptions?: Apollo.MutationHookOptions<WarrantyAddExternalCostRowMutation, WarrantyAddExternalCostRowMutationVariables>) {
        return Apollo.useMutation<WarrantyAddExternalCostRowMutation, WarrantyAddExternalCostRowMutationVariables>(WarrantyAddExternalCostRowDocument, baseOptions);
      }
export type WarrantyAddExternalCostRowMutationHookResult = ReturnType<typeof useWarrantyAddExternalCostRowMutation>;
export type WarrantyAddExternalCostRowMutationResult = Apollo.MutationResult<WarrantyAddExternalCostRowMutation>;
export type WarrantyAddExternalCostRowMutationOptions = Apollo.BaseMutationOptions<WarrantyAddExternalCostRowMutation, WarrantyAddExternalCostRowMutationVariables>;
export const WarrantyChangeStatusMassActionDocument = gql`
    mutation WarrantyChangeStatusMassAction($warrantiesIds: [ID!]!, $status: WarrantyStatus!, $declineReason: DeclineReason, $declineComment: String) {
  warrantyChangeStatusMassAction(
    warrantiesIds: $warrantiesIds
    status: $status
    declineReason: $declineReason
    declineComment: $declineComment
  ) {
    id
    status
    declineReason
    declineComment
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    customerRemark
    workshopRemark
    rows {
      id
      quantity
      originalQuantity
      price
      percentage
      originalPercentage
      total
      originalTotal
      referenceName
      description
      status
      complementReason
      complementComment
      declineReason
      declineComment
      type
      originalHours
      hours
      handledBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      ... on OperationRow {
        operationCode {
          id
          code
          category
          description
          time
        }
      }
      ... on ExternRow {
        purchaseRefersType
        receiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on ArticleRow {
        orderItem {
          id
          vehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          fee {
            id
            name
            articleNumber
            price
            vat
            discountedPrice
            createdAt
            updatedAt
            isAllowedOnVehicles
            active
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          detailedQuantities {
            processing
            allocated
            blocked
            delivered
            invoiced
          }
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
            externallyFinanced
          }
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          connectionType
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        legacyArticleNumber
        legacyOrderNumber
      }
      ... on LegacyRow {
        code
        originalComp
        comp
        originalPrice
        legacyType
      }
    }
    attachements {
      id
      name
      path
      mimeType
      isImage
      createdAt
    }
    registeredAt
    createdAt
    repairedAt
    sumRequested
    sumCredited
    type
    internalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    externalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    damageImage {
      id
      name
      path
      createdAt
    }
    shippingCost
    ... on VehicleWarranty {
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      meterReadingImage {
        id
        name
        path
        createdAt
      }
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      defectPartNumber
      defectPartTypeCode
      symptomCode
      operationalizedFrom {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          handledBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          ... on OperationRow {
            operationCode {
              id
              code
              category
              description
              time
            }
          }
          ... on ExternRow {
            purchaseRefersType
            receiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on ArticleRow {
            orderItem {
              id
              deliveredQuantity
              freeQuantity
              remark
              status
              finalPrice
              unitPrice
              stockArrivalDate
              connectionType
            }
            sparePart {
              id
              articleNumber
              name
              remark
              price
              discountedPrice
              vat
              active
              vehicleCampaign
            }
            billOfMaterial {
              id
              articleNumber
              active
              bomCode
              description
              fromDate
              toDate
              price
              discountedPrice
              vehicleCampaign
            }
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        operationalizedTo {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
      }
    }
    ... on GoodWillWarranty {
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      operationalizedTo {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          handledBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          ... on OperationRow {
            operationCode {
              id
              code
              category
              description
              time
            }
          }
          ... on ExternRow {
            purchaseRefersType
            receiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on ArticleRow {
            orderItem {
              id
              deliveredQuantity
              freeQuantity
              remark
              status
              finalPrice
              unitPrice
              stockArrivalDate
              connectionType
            }
            sparePart {
              id
              articleNumber
              name
              remark
              price
              discountedPrice
              vat
              active
              vehicleCampaign
            }
            billOfMaterial {
              id
              articleNumber
              active
              bomCode
              description
              fromDate
              toDate
              price
              discountedPrice
              vehicleCampaign
            }
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        meterReadingImage {
          id
          name
          path
          createdAt
        }
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        defectPartNumber
        defectPartTypeCode
        symptomCode
        operationalizedFrom {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
      }
    }
    ... on PartWarranty {
      purchaseReceiptImage {
        id
        name
        path
        createdAt
      }
    }
    ... on LegacyWarranty {
      legacyClaimNumber
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      legacyDealer
      causeOfDamage
      warrantyStartedAt
      receivedAt
    }
  }
}
    `;
export type WarrantyChangeStatusMassActionMutationFn = Apollo.MutationFunction<WarrantyChangeStatusMassActionMutation, WarrantyChangeStatusMassActionMutationVariables>;

/**
 * __useWarrantyChangeStatusMassActionMutation__
 *
 * To run a mutation, you first call `useWarrantyChangeStatusMassActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWarrantyChangeStatusMassActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [warrantyChangeStatusMassActionMutation, { data, loading, error }] = useWarrantyChangeStatusMassActionMutation({
 *   variables: {
 *      warrantiesIds: // value for 'warrantiesIds'
 *      status: // value for 'status'
 *      declineReason: // value for 'declineReason'
 *      declineComment: // value for 'declineComment'
 *   },
 * });
 */
export function useWarrantyChangeStatusMassActionMutation(baseOptions?: Apollo.MutationHookOptions<WarrantyChangeStatusMassActionMutation, WarrantyChangeStatusMassActionMutationVariables>) {
        return Apollo.useMutation<WarrantyChangeStatusMassActionMutation, WarrantyChangeStatusMassActionMutationVariables>(WarrantyChangeStatusMassActionDocument, baseOptions);
      }
export type WarrantyChangeStatusMassActionMutationHookResult = ReturnType<typeof useWarrantyChangeStatusMassActionMutation>;
export type WarrantyChangeStatusMassActionMutationResult = Apollo.MutationResult<WarrantyChangeStatusMassActionMutation>;
export type WarrantyChangeStatusMassActionMutationOptions = Apollo.BaseMutationOptions<WarrantyChangeStatusMassActionMutation, WarrantyChangeStatusMassActionMutationVariables>;
export const ComplementWarrantyRowDocument = gql`
    mutation ComplementWarrantyRow($rowId: ID!, $articleRowsInput: ArticleRowInput, $operationRowsInput: OperationRowInput, $externRowsInput: ExternRowInput, $sparePartSent: Boolean) {
  complementWarrantyRow(
    rowId: $rowId
    articleRowsInput: $articleRowsInput
    operationRowsInput: $operationRowsInput
    externRowsInput: $externRowsInput
    sparePartSent: $sparePartSent
  ) {
    id
    quantity
    originalQuantity
    price
    percentage
    originalPercentage
    total
    originalTotal
    referenceName
    description
    status
    complementReason
    complementComment
    declineReason
    declineComment
    type
    originalHours
    hours
    handledBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    ... on OperationRow {
      operationCode {
        id
        code
        category
        description
        time
      }
    }
    ... on ExternRow {
      purchaseRefersType
      receiptImage {
        id
        name
        path
        createdAt
      }
    }
    ... on ArticleRow {
      orderItem {
        id
        vehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          vehicleType
          price
          vat
          discountedPrice
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          brand {
            id
            externalId
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          createdAt
          vehicleCampaign
          remark
          active
          equipmentGroup {
            id
            name
            externalId
          }
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        deliveredQuantity
        freeQuantity
        detailedQuantities {
          processing
          allocated
          blocked
          delivered
          invoiced
        }
        remark
        order {
          id
          serialNumber
          externalId
          status
          invoice
          items {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          products {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          fees {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          createdAt
          invoicedAt
          referenceUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          total
          vat
          totalQuantity
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          deliveryType
          address
          postalCode
          postalCity
          partialDelivery
          orderReferenceMessage
          externallyFinanced
        }
        status
        finalPrice
        unitPrice
        stockArrivalDate
        connection {
          id
          vehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          fee {
            id
            name
            articleNumber
            price
            vat
            discountedPrice
            createdAt
            updatedAt
            isAllowedOnVehicles
            active
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          detailedQuantities {
            processing
            allocated
            blocked
            delivered
            invoiced
          }
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
            externallyFinanced
          }
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          connectionType
        }
        invoices {
          id
          invoiceDate
          buyer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          invoiceRows {
            id
            sageId
            articleNumber
            description
            quantity
            netPriceExclVat
            grossPriceExclVat
          }
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        connectionType
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        thumbnail {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        images {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        explodedViewRows {
          id
          referenceNumber
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          incomingDeliveries {
            deliveryDate
            quantity
          }
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          incomingDeliveries {
            deliveryDate
            quantity
          }
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        thumbnail {
          id
          name
          path
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      legacyArticleNumber
      legacyOrderNumber
    }
    ... on LegacyRow {
      code
      originalComp
      comp
      originalPrice
      legacyType
    }
  }
}
    `;
export type ComplementWarrantyRowMutationFn = Apollo.MutationFunction<ComplementWarrantyRowMutation, ComplementWarrantyRowMutationVariables>;

/**
 * __useComplementWarrantyRowMutation__
 *
 * To run a mutation, you first call `useComplementWarrantyRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useComplementWarrantyRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [complementWarrantyRowMutation, { data, loading, error }] = useComplementWarrantyRowMutation({
 *   variables: {
 *      rowId: // value for 'rowId'
 *      articleRowsInput: // value for 'articleRowsInput'
 *      operationRowsInput: // value for 'operationRowsInput'
 *      externRowsInput: // value for 'externRowsInput'
 *      sparePartSent: // value for 'sparePartSent'
 *   },
 * });
 */
export function useComplementWarrantyRowMutation(baseOptions?: Apollo.MutationHookOptions<ComplementWarrantyRowMutation, ComplementWarrantyRowMutationVariables>) {
        return Apollo.useMutation<ComplementWarrantyRowMutation, ComplementWarrantyRowMutationVariables>(ComplementWarrantyRowDocument, baseOptions);
      }
export type ComplementWarrantyRowMutationHookResult = ReturnType<typeof useComplementWarrantyRowMutation>;
export type ComplementWarrantyRowMutationResult = Apollo.MutationResult<ComplementWarrantyRowMutation>;
export type ComplementWarrantyRowMutationOptions = Apollo.BaseMutationOptions<ComplementWarrantyRowMutation, ComplementWarrantyRowMutationVariables>;
export const ComplementWarrantyDocument = gql`
    mutation ComplementWarranty($warrantyId: ID!, $rowId: ID!, $complementaryImages: [WarrantyImageInput!]) {
  complementWarranty(
    warrantyId: $warrantyId
    rowId: $rowId
    complementaryImages: $complementaryImages
  ) {
    id
    status
    declineReason
    declineComment
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    customerRemark
    workshopRemark
    rows {
      id
      quantity
      originalQuantity
      price
      percentage
      originalPercentage
      total
      originalTotal
      referenceName
      description
      status
      complementReason
      complementComment
      declineReason
      declineComment
      type
      originalHours
      hours
      handledBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      ... on OperationRow {
        operationCode {
          id
          code
          category
          description
          time
        }
      }
      ... on ExternRow {
        purchaseRefersType
        receiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on ArticleRow {
        orderItem {
          id
          vehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          fee {
            id
            name
            articleNumber
            price
            vat
            discountedPrice
            createdAt
            updatedAt
            isAllowedOnVehicles
            active
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          detailedQuantities {
            processing
            allocated
            blocked
            delivered
            invoiced
          }
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
            externallyFinanced
          }
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          connectionType
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        legacyArticleNumber
        legacyOrderNumber
      }
      ... on LegacyRow {
        code
        originalComp
        comp
        originalPrice
        legacyType
      }
    }
    attachements {
      id
      name
      path
      mimeType
      isImage
      createdAt
    }
    registeredAt
    createdAt
    repairedAt
    sumRequested
    sumCredited
    type
    internalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    externalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    damageImage {
      id
      name
      path
      createdAt
    }
    shippingCost
    ... on VehicleWarranty {
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      meterReadingImage {
        id
        name
        path
        createdAt
      }
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      defectPartNumber
      defectPartTypeCode
      symptomCode
      operationalizedFrom {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          handledBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          ... on OperationRow {
            operationCode {
              id
              code
              category
              description
              time
            }
          }
          ... on ExternRow {
            purchaseRefersType
            receiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on ArticleRow {
            orderItem {
              id
              deliveredQuantity
              freeQuantity
              remark
              status
              finalPrice
              unitPrice
              stockArrivalDate
              connectionType
            }
            sparePart {
              id
              articleNumber
              name
              remark
              price
              discountedPrice
              vat
              active
              vehicleCampaign
            }
            billOfMaterial {
              id
              articleNumber
              active
              bomCode
              description
              fromDate
              toDate
              price
              discountedPrice
              vehicleCampaign
            }
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        operationalizedTo {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
      }
    }
    ... on GoodWillWarranty {
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      operationalizedTo {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          handledBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          ... on OperationRow {
            operationCode {
              id
              code
              category
              description
              time
            }
          }
          ... on ExternRow {
            purchaseRefersType
            receiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on ArticleRow {
            orderItem {
              id
              deliveredQuantity
              freeQuantity
              remark
              status
              finalPrice
              unitPrice
              stockArrivalDate
              connectionType
            }
            sparePart {
              id
              articleNumber
              name
              remark
              price
              discountedPrice
              vat
              active
              vehicleCampaign
            }
            billOfMaterial {
              id
              articleNumber
              active
              bomCode
              description
              fromDate
              toDate
              price
              discountedPrice
              vehicleCampaign
            }
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          author {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        meterReadingImage {
          id
          name
          path
          createdAt
        }
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        defectPartNumber
        defectPartTypeCode
        symptomCode
        operationalizedFrom {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
      }
    }
    ... on PartWarranty {
      purchaseReceiptImage {
        id
        name
        path
        createdAt
      }
    }
    ... on LegacyWarranty {
      legacyClaimNumber
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          brand {
            id
            externalId
            name
          }
          vehicleType
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          equipments {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          articleNumber
          factoryOptions {
            id
            description
          }
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
          brand {
            id
            externalId
            name
          }
          models {
            id
            name
            description
          }
        }
        model {
          id
          name
          description
          modelSeries {
            id
            name
          }
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          makes {
            id
            accessToSell
            accessToWorkshop
            warrantyHourlyPayment
          }
          users {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          contactUser {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            createdAt
            updatedAt
          }
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
          factoryOptionType {
            id
            description
          }
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          ... on VehicleWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            meterReadingImage {
              id
              name
              path
              createdAt
            }
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            defectPartNumber
            defectPartTypeCode
            symptomCode
            operationalizedFrom {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
            }
          }
          ... on GoodWillWarranty {
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            meterReading
            operatingTime
            serviceBookImage {
              id
              name
              path
              createdAt
            }
            operationalizedTo {
              id
              status
              declineReason
              declineComment
              customerRemark
              workshopRemark
              registeredAt
              createdAt
              repairedAt
              sumRequested
              sumCredited
              type
              shippingCost
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
          }
          ... on PartWarranty {
            purchaseReceiptImage {
              id
              name
              path
              createdAt
            }
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            vehicle {
              id
              vehicleIdentificationNumber
              registrationNumber
              startedAt
              showDetailedInformation
              typeOfInstallation
              createdAt
              updatedAt
              engineNumber
              factoryCode
              status
              firstArrivedToSweden
              producedAt
              firstRegisteredAt
              replacementText
              showBfiAndKey
            }
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      legacyDealer
      causeOfDamage
      warrantyStartedAt
      receivedAt
    }
  }
}
    `;
export type ComplementWarrantyMutationFn = Apollo.MutationFunction<ComplementWarrantyMutation, ComplementWarrantyMutationVariables>;

/**
 * __useComplementWarrantyMutation__
 *
 * To run a mutation, you first call `useComplementWarrantyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useComplementWarrantyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [complementWarrantyMutation, { data, loading, error }] = useComplementWarrantyMutation({
 *   variables: {
 *      warrantyId: // value for 'warrantyId'
 *      rowId: // value for 'rowId'
 *      complementaryImages: // value for 'complementaryImages'
 *   },
 * });
 */
export function useComplementWarrantyMutation(baseOptions?: Apollo.MutationHookOptions<ComplementWarrantyMutation, ComplementWarrantyMutationVariables>) {
        return Apollo.useMutation<ComplementWarrantyMutation, ComplementWarrantyMutationVariables>(ComplementWarrantyDocument, baseOptions);
      }
export type ComplementWarrantyMutationHookResult = ReturnType<typeof useComplementWarrantyMutation>;
export type ComplementWarrantyMutationResult = Apollo.MutationResult<ComplementWarrantyMutation>;
export type ComplementWarrantyMutationOptions = Apollo.BaseMutationOptions<ComplementWarrantyMutation, ComplementWarrantyMutationVariables>;
export const VehicleBfiDocument = gql`
    mutation VehicleBfi($vehicleId: ID!, $meterReading: Float!, $meterReadingImage: UploadFile) {
  vehicleBFI(
    vehicleId: $vehicleId
    meterReading: $meterReading
    meterReadingImage: $meterReadingImage
  ) {
    success
  }
}
    `;
export type VehicleBfiMutationFn = Apollo.MutationFunction<VehicleBfiMutation, VehicleBfiMutationVariables>;

/**
 * __useVehicleBfiMutation__
 *
 * To run a mutation, you first call `useVehicleBfiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVehicleBfiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vehicleBfiMutation, { data, loading, error }] = useVehicleBfiMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      meterReading: // value for 'meterReading'
 *      meterReadingImage: // value for 'meterReadingImage'
 *   },
 * });
 */
export function useVehicleBfiMutation(baseOptions?: Apollo.MutationHookOptions<VehicleBfiMutation, VehicleBfiMutationVariables>) {
        return Apollo.useMutation<VehicleBfiMutation, VehicleBfiMutationVariables>(VehicleBfiDocument, baseOptions);
      }
export type VehicleBfiMutationHookResult = ReturnType<typeof useVehicleBfiMutation>;
export type VehicleBfiMutationResult = Apollo.MutationResult<VehicleBfiMutation>;
export type VehicleBfiMutationOptions = Apollo.BaseMutationOptions<VehicleBfiMutation, VehicleBfiMutationVariables>;
export const VehicleKeysDocument = gql`
    mutation VehicleKeys($vehicleId: ID!, $registrationProofImage: UploadFile!, $alternativeKeyNumber: String, $spareKeyQuantity: Int, $remoteControlQuantity: Int, $remoteControlFlipQuantity: Int) {
  vehicleKeys(
    vehicleId: $vehicleId
    registrationProofImage: $registrationProofImage
    alternativeKeyNumber: $alternativeKeyNumber
    spareKeyQuantity: $spareKeyQuantity
    remoteControlQuantity: $remoteControlQuantity
    remoteControlFlipQuantity: $remoteControlFlipQuantity
  ) {
    success
  }
}
    `;
export type VehicleKeysMutationFn = Apollo.MutationFunction<VehicleKeysMutation, VehicleKeysMutationVariables>;

/**
 * __useVehicleKeysMutation__
 *
 * To run a mutation, you first call `useVehicleKeysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVehicleKeysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vehicleKeysMutation, { data, loading, error }] = useVehicleKeysMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      registrationProofImage: // value for 'registrationProofImage'
 *      alternativeKeyNumber: // value for 'alternativeKeyNumber'
 *      spareKeyQuantity: // value for 'spareKeyQuantity'
 *      remoteControlQuantity: // value for 'remoteControlQuantity'
 *      remoteControlFlipQuantity: // value for 'remoteControlFlipQuantity'
 *   },
 * });
 */
export function useVehicleKeysMutation(baseOptions?: Apollo.MutationHookOptions<VehicleKeysMutation, VehicleKeysMutationVariables>) {
        return Apollo.useMutation<VehicleKeysMutation, VehicleKeysMutationVariables>(VehicleKeysDocument, baseOptions);
      }
export type VehicleKeysMutationHookResult = ReturnType<typeof useVehicleKeysMutation>;
export type VehicleKeysMutationResult = Apollo.MutationResult<VehicleKeysMutation>;
export type VehicleKeysMutationOptions = Apollo.BaseMutationOptions<VehicleKeysMutation, VehicleKeysMutationVariables>;
export const OperationalizePriorApprovalWarrantyDocument = gql`
    mutation OperationalizePriorApprovalWarranty($warrantyId: ID!) {
  operationalizePriorApprovalWarranty(warrantyId: $warrantyId) {
    id
    status
    declineReason
    declineComment
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    customerRemark
    workshopRemark
    rows {
      id
      quantity
      originalQuantity
      price
      percentage
      originalPercentage
      total
      originalTotal
      referenceName
      description
      status
      complementReason
      complementComment
      declineReason
      declineComment
      type
      originalHours
      hours
      handledBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      ... on OperationRow {
        operationCode {
          id
          code
          category
          description
          time
        }
      }
      ... on ExternRow {
        purchaseRefersType
        receiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on ArticleRow {
        orderItem {
          id
          vehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          fee {
            id
            name
            articleNumber
            price
            vat
            discountedPrice
            createdAt
            updatedAt
            isAllowedOnVehicles
            active
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          detailedQuantities {
            processing
            allocated
            blocked
            delivered
            invoiced
          }
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
            externallyFinanced
          }
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          connectionType
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        legacyArticleNumber
        legacyOrderNumber
      }
      ... on LegacyRow {
        code
        originalComp
        comp
        originalPrice
        legacyType
      }
    }
    attachements {
      id
      name
      path
      mimeType
      isImage
      createdAt
    }
    registeredAt
    createdAt
    repairedAt
    sumRequested
    sumCredited
    type
    internalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    externalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    damageImage {
      id
      name
      path
      createdAt
    }
    shippingCost
    vehicle {
      id
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      vehicleIdentificationNumber
      registrationNumber
      brand {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      startedAt
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      showDetailedInformation
      typeOfInstallation
      nonColorFactoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      createdAt
      updatedAt
      engineNumber
      factoryCode
      status
      firstArrivedToSweden
      producedAt
      firstRegisteredAt
      replacementText
      showBfiAndKey
      warranties {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          ... on ExternRow {
            purchaseRefersType
          }
          ... on ArticleRow {
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        ... on VehicleWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
        ... on GoodWillWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
        ... on PartWarranty {
          purchaseReceiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on LegacyWarranty {
          legacyClaimNumber
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          legacyDealer
          causeOfDamage
          warrantyStartedAt
          receivedAt
        }
      }
    }
    meterReading
    operatingTime
    meterReadingImage {
      id
      name
      path
      createdAt
    }
    serviceBookImage {
      id
      name
      path
      createdAt
    }
    defectPartNumber
    defectPartTypeCode
    symptomCode
    operationalizedFrom {
      id
      status
      declineReason
      declineComment
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      customerRemark
      workshopRemark
      rows {
        id
        quantity
        originalQuantity
        price
        percentage
        originalPercentage
        total
        originalTotal
        referenceName
        description
        status
        complementReason
        complementComment
        declineReason
        declineComment
        type
        originalHours
        hours
        handledBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        ... on OperationRow {
          operationCode {
            id
            code
            category
            description
            time
          }
        }
        ... on ExternRow {
          purchaseRefersType
          receiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on ArticleRow {
          orderItem {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          legacyArticleNumber
          legacyOrderNumber
        }
        ... on LegacyRow {
          code
          originalComp
          comp
          originalPrice
          legacyType
        }
      }
      attachements {
        id
        name
        path
        mimeType
        isImage
        createdAt
      }
      registeredAt
      createdAt
      repairedAt
      sumRequested
      sumCredited
      type
      internalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      externalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      damageImage {
        id
        name
        path
        createdAt
      }
      shippingCost
      vehicle {
        id
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        vehicleIdentificationNumber
        registrationNumber
        brand {
          id
          externalId
          name
        }
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        startedAt
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        showDetailedInformation
        typeOfInstallation
        nonColorFactoryOptions {
          id
          description
        }
        createdAt
        updatedAt
        engineNumber
        factoryCode
        status
        firstArrivedToSweden
        producedAt
        firstRegisteredAt
        replacementText
        showBfiAndKey
        warranties {
          id
          status
          declineReason
          declineComment
          customerRemark
          workshopRemark
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          shippingCost
          ... on VehicleWarranty {
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
          ... on GoodWillWarranty {
            meterReading
            operatingTime
          }
          ... on LegacyWarranty {
            legacyClaimNumber
            legacyDealer
            causeOfDamage
            warrantyStartedAt
            receivedAt
          }
        }
      }
      meterReading
      operatingTime
      serviceBookImage {
        id
        name
        path
        createdAt
      }
      operationalizedTo {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          ... on ExternRow {
            purchaseRefersType
          }
          ... on ArticleRow {
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        vehicle {
          id
          vehicleIdentificationNumber
          registrationNumber
          startedAt
          showDetailedInformation
          typeOfInstallation
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
        }
        meterReading
        operatingTime
        meterReadingImage {
          id
          name
          path
          createdAt
        }
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        defectPartNumber
        defectPartTypeCode
        symptomCode
        operationalizedFrom {
          id
          status
          declineReason
          declineComment
          customerRemark
          workshopRemark
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          shippingCost
          meterReading
          operatingTime
        }
      }
    }
  }
}
    `;
export type OperationalizePriorApprovalWarrantyMutationFn = Apollo.MutationFunction<OperationalizePriorApprovalWarrantyMutation, OperationalizePriorApprovalWarrantyMutationVariables>;

/**
 * __useOperationalizePriorApprovalWarrantyMutation__
 *
 * To run a mutation, you first call `useOperationalizePriorApprovalWarrantyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOperationalizePriorApprovalWarrantyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [operationalizePriorApprovalWarrantyMutation, { data, loading, error }] = useOperationalizePriorApprovalWarrantyMutation({
 *   variables: {
 *      warrantyId: // value for 'warrantyId'
 *   },
 * });
 */
export function useOperationalizePriorApprovalWarrantyMutation(baseOptions?: Apollo.MutationHookOptions<OperationalizePriorApprovalWarrantyMutation, OperationalizePriorApprovalWarrantyMutationVariables>) {
        return Apollo.useMutation<OperationalizePriorApprovalWarrantyMutation, OperationalizePriorApprovalWarrantyMutationVariables>(OperationalizePriorApprovalWarrantyDocument, baseOptions);
      }
export type OperationalizePriorApprovalWarrantyMutationHookResult = ReturnType<typeof useOperationalizePriorApprovalWarrantyMutation>;
export type OperationalizePriorApprovalWarrantyMutationResult = Apollo.MutationResult<OperationalizePriorApprovalWarrantyMutation>;
export type OperationalizePriorApprovalWarrantyMutationOptions = Apollo.BaseMutationOptions<OperationalizePriorApprovalWarrantyMutation, OperationalizePriorApprovalWarrantyMutationVariables>;
export const UpdateMakePermissionsDocument = gql`
    mutation UpdateMakePermissions($retailerId: Int, $makePermissions: [UpdateMakePermissionsInput!]!) {
  updateMakePermissions(
    retailerId: $retailerId
    makePermissions: $makePermissions
  ) {
    id
    externalId
    name
    address
    postalCode
    city
    country
    telephoneNumber
    email
    makes {
      id
      make {
        id
        externalId
        name
      }
      accessToSell
      accessToWorkshop
      warrantyHourlyPayment
    }
    users {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      createdAt
      updatedAt
    }
    contactUser {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      createdAt
      updatedAt
    }
    hasConsignmentVehicles
    hasExhibitionVehicles
    hasMaintenanceCustomers
    hasExternalFinancing
  }
}
    `;
export type UpdateMakePermissionsMutationFn = Apollo.MutationFunction<UpdateMakePermissionsMutation, UpdateMakePermissionsMutationVariables>;

/**
 * __useUpdateMakePermissionsMutation__
 *
 * To run a mutation, you first call `useUpdateMakePermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMakePermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMakePermissionsMutation, { data, loading, error }] = useUpdateMakePermissionsMutation({
 *   variables: {
 *      retailerId: // value for 'retailerId'
 *      makePermissions: // value for 'makePermissions'
 *   },
 * });
 */
export function useUpdateMakePermissionsMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMakePermissionsMutation, UpdateMakePermissionsMutationVariables>) {
        return Apollo.useMutation<UpdateMakePermissionsMutation, UpdateMakePermissionsMutationVariables>(UpdateMakePermissionsDocument, baseOptions);
      }
export type UpdateMakePermissionsMutationHookResult = ReturnType<typeof useUpdateMakePermissionsMutation>;
export type UpdateMakePermissionsMutationResult = Apollo.MutationResult<UpdateMakePermissionsMutation>;
export type UpdateMakePermissionsMutationOptions = Apollo.BaseMutationOptions<UpdateMakePermissionsMutation, UpdateMakePermissionsMutationVariables>;
export const WarrantyDisplayViewUpsertDocument = gql`
    mutation WarrantyDisplayViewUpsert($id: ID, $name: String!, $columnData: [ColumnDataInput!]!, $active: Boolean!) {
  warrantyDisplayViewUpsert(
    id: $id
    name: $name
    columnData: $columnData
    active: $active
  ) {
    id
    name
    columnData {
      position
      name
      active
    }
    user {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    active
    createdAt
    updatedAt
  }
}
    `;
export type WarrantyDisplayViewUpsertMutationFn = Apollo.MutationFunction<WarrantyDisplayViewUpsertMutation, WarrantyDisplayViewUpsertMutationVariables>;

/**
 * __useWarrantyDisplayViewUpsertMutation__
 *
 * To run a mutation, you first call `useWarrantyDisplayViewUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWarrantyDisplayViewUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [warrantyDisplayViewUpsertMutation, { data, loading, error }] = useWarrantyDisplayViewUpsertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      columnData: // value for 'columnData'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useWarrantyDisplayViewUpsertMutation(baseOptions?: Apollo.MutationHookOptions<WarrantyDisplayViewUpsertMutation, WarrantyDisplayViewUpsertMutationVariables>) {
        return Apollo.useMutation<WarrantyDisplayViewUpsertMutation, WarrantyDisplayViewUpsertMutationVariables>(WarrantyDisplayViewUpsertDocument, baseOptions);
      }
export type WarrantyDisplayViewUpsertMutationHookResult = ReturnType<typeof useWarrantyDisplayViewUpsertMutation>;
export type WarrantyDisplayViewUpsertMutationResult = Apollo.MutationResult<WarrantyDisplayViewUpsertMutation>;
export type WarrantyDisplayViewUpsertMutationOptions = Apollo.BaseMutationOptions<WarrantyDisplayViewUpsertMutation, WarrantyDisplayViewUpsertMutationVariables>;
export const DeleteWarrantyDisplayViewDocument = gql`
    mutation DeleteWarrantyDisplayView($id: ID) {
  deleteWarrantyDisplayView(id: $id) {
    success
  }
}
    `;
export type DeleteWarrantyDisplayViewMutationFn = Apollo.MutationFunction<DeleteWarrantyDisplayViewMutation, DeleteWarrantyDisplayViewMutationVariables>;

/**
 * __useDeleteWarrantyDisplayViewMutation__
 *
 * To run a mutation, you first call `useDeleteWarrantyDisplayViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWarrantyDisplayViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWarrantyDisplayViewMutation, { data, loading, error }] = useDeleteWarrantyDisplayViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWarrantyDisplayViewMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWarrantyDisplayViewMutation, DeleteWarrantyDisplayViewMutationVariables>) {
        return Apollo.useMutation<DeleteWarrantyDisplayViewMutation, DeleteWarrantyDisplayViewMutationVariables>(DeleteWarrantyDisplayViewDocument, baseOptions);
      }
export type DeleteWarrantyDisplayViewMutationHookResult = ReturnType<typeof useDeleteWarrantyDisplayViewMutation>;
export type DeleteWarrantyDisplayViewMutationResult = Apollo.MutationResult<DeleteWarrantyDisplayViewMutation>;
export type DeleteWarrantyDisplayViewMutationOptions = Apollo.BaseMutationOptions<DeleteWarrantyDisplayViewMutation, DeleteWarrantyDisplayViewMutationVariables>;
export const SwitchWarrantyDisplayViewDocument = gql`
    mutation SwitchWarrantyDisplayView($id: ID!) {
  switchWarrantyDisplayView(id: $id) {
    id
    name
    columnData {
      position
      name
      active
    }
    user {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    active
    createdAt
    updatedAt
  }
}
    `;
export type SwitchWarrantyDisplayViewMutationFn = Apollo.MutationFunction<SwitchWarrantyDisplayViewMutation, SwitchWarrantyDisplayViewMutationVariables>;

/**
 * __useSwitchWarrantyDisplayViewMutation__
 *
 * To run a mutation, you first call `useSwitchWarrantyDisplayViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchWarrantyDisplayViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchWarrantyDisplayViewMutation, { data, loading, error }] = useSwitchWarrantyDisplayViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSwitchWarrantyDisplayViewMutation(baseOptions?: Apollo.MutationHookOptions<SwitchWarrantyDisplayViewMutation, SwitchWarrantyDisplayViewMutationVariables>) {
        return Apollo.useMutation<SwitchWarrantyDisplayViewMutation, SwitchWarrantyDisplayViewMutationVariables>(SwitchWarrantyDisplayViewDocument, baseOptions);
      }
export type SwitchWarrantyDisplayViewMutationHookResult = ReturnType<typeof useSwitchWarrantyDisplayViewMutation>;
export type SwitchWarrantyDisplayViewMutationResult = Apollo.MutationResult<SwitchWarrantyDisplayViewMutation>;
export type SwitchWarrantyDisplayViewMutationOptions = Apollo.BaseMutationOptions<SwitchWarrantyDisplayViewMutation, SwitchWarrantyDisplayViewMutationVariables>;
export const CreateExplodedViewsDocument = gql`
    mutation CreateExplodedViews($currentFolderId: String!, $brandFolderId: String!, $modelFolderId: String!, $mainCategoryFolderId: String, $subCategoryFolderId: String, $explodedViews: [ExplodedViewInput!]!) {
  createExplodedViews(
    currentFolderId: $currentFolderId
    brandFolderId: $brandFolderId
    modelFolderId: $modelFolderId
    mainCategoryFolderId: $mainCategoryFolderId
    subCategoryFolderId: $subCategoryFolderId
    explodedViews: $explodedViews
  ) {
    success
  }
}
    `;
export type CreateExplodedViewsMutationFn = Apollo.MutationFunction<CreateExplodedViewsMutation, CreateExplodedViewsMutationVariables>;

/**
 * __useCreateExplodedViewsMutation__
 *
 * To run a mutation, you first call `useCreateExplodedViewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExplodedViewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExplodedViewsMutation, { data, loading, error }] = useCreateExplodedViewsMutation({
 *   variables: {
 *      currentFolderId: // value for 'currentFolderId'
 *      brandFolderId: // value for 'brandFolderId'
 *      modelFolderId: // value for 'modelFolderId'
 *      mainCategoryFolderId: // value for 'mainCategoryFolderId'
 *      subCategoryFolderId: // value for 'subCategoryFolderId'
 *      explodedViews: // value for 'explodedViews'
 *   },
 * });
 */
export function useCreateExplodedViewsMutation(baseOptions?: Apollo.MutationHookOptions<CreateExplodedViewsMutation, CreateExplodedViewsMutationVariables>) {
        return Apollo.useMutation<CreateExplodedViewsMutation, CreateExplodedViewsMutationVariables>(CreateExplodedViewsDocument, baseOptions);
      }
export type CreateExplodedViewsMutationHookResult = ReturnType<typeof useCreateExplodedViewsMutation>;
export type CreateExplodedViewsMutationResult = Apollo.MutationResult<CreateExplodedViewsMutation>;
export type CreateExplodedViewsMutationOptions = Apollo.BaseMutationOptions<CreateExplodedViewsMutation, CreateExplodedViewsMutationVariables>;
export const DeleteExplodedViewDocument = gql`
    mutation DeleteExplodedView($explodedViewId: String!) {
  deleteExplodedView(explodedViewId: $explodedViewId) {
    success
  }
}
    `;
export type DeleteExplodedViewMutationFn = Apollo.MutationFunction<DeleteExplodedViewMutation, DeleteExplodedViewMutationVariables>;

/**
 * __useDeleteExplodedViewMutation__
 *
 * To run a mutation, you first call `useDeleteExplodedViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExplodedViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExplodedViewMutation, { data, loading, error }] = useDeleteExplodedViewMutation({
 *   variables: {
 *      explodedViewId: // value for 'explodedViewId'
 *   },
 * });
 */
export function useDeleteExplodedViewMutation(baseOptions?: Apollo.MutationHookOptions<DeleteExplodedViewMutation, DeleteExplodedViewMutationVariables>) {
        return Apollo.useMutation<DeleteExplodedViewMutation, DeleteExplodedViewMutationVariables>(DeleteExplodedViewDocument, baseOptions);
      }
export type DeleteExplodedViewMutationHookResult = ReturnType<typeof useDeleteExplodedViewMutation>;
export type DeleteExplodedViewMutationResult = Apollo.MutationResult<DeleteExplodedViewMutation>;
export type DeleteExplodedViewMutationOptions = Apollo.BaseMutationOptions<DeleteExplodedViewMutation, DeleteExplodedViewMutationVariables>;
export const UpdateExplodedViewDocument = gql`
    mutation UpdateExplodedView($explodedViewId: String!, $name: String, $folderId: String, $images: [UploadFile!], $rows: [ExplodedViewRowInput!]) {
  updateExplodedView(
    explodedViewId: $explodedViewId
    name: $name
    folderId: $folderId
    images: $images
    rows: $rows
  ) {
    id
    title
    explodedViewImages {
      id
      name
      path
      createdAt
    }
    explodedViewRows {
      id
      referenceNumber
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
    }
    brandFolder {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    modelFolder {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    mainCategoryFolder {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    subCategoryFolder {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    folder {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateExplodedViewMutationFn = Apollo.MutationFunction<UpdateExplodedViewMutation, UpdateExplodedViewMutationVariables>;

/**
 * __useUpdateExplodedViewMutation__
 *
 * To run a mutation, you first call `useUpdateExplodedViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExplodedViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExplodedViewMutation, { data, loading, error }] = useUpdateExplodedViewMutation({
 *   variables: {
 *      explodedViewId: // value for 'explodedViewId'
 *      name: // value for 'name'
 *      folderId: // value for 'folderId'
 *      images: // value for 'images'
 *      rows: // value for 'rows'
 *   },
 * });
 */
export function useUpdateExplodedViewMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExplodedViewMutation, UpdateExplodedViewMutationVariables>) {
        return Apollo.useMutation<UpdateExplodedViewMutation, UpdateExplodedViewMutationVariables>(UpdateExplodedViewDocument, baseOptions);
      }
export type UpdateExplodedViewMutationHookResult = ReturnType<typeof useUpdateExplodedViewMutation>;
export type UpdateExplodedViewMutationResult = Apollo.MutationResult<UpdateExplodedViewMutation>;
export type UpdateExplodedViewMutationOptions = Apollo.BaseMutationOptions<UpdateExplodedViewMutation, UpdateExplodedViewMutationVariables>;
export const DeleteExplodedViewRowDocument = gql`
    mutation DeleteExplodedViewRow($id: ID!) {
  deleteExplodedViewRow(id: $id) {
    success
  }
}
    `;
export type DeleteExplodedViewRowMutationFn = Apollo.MutationFunction<DeleteExplodedViewRowMutation, DeleteExplodedViewRowMutationVariables>;

/**
 * __useDeleteExplodedViewRowMutation__
 *
 * To run a mutation, you first call `useDeleteExplodedViewRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExplodedViewRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExplodedViewRowMutation, { data, loading, error }] = useDeleteExplodedViewRowMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExplodedViewRowMutation(baseOptions?: Apollo.MutationHookOptions<DeleteExplodedViewRowMutation, DeleteExplodedViewRowMutationVariables>) {
        return Apollo.useMutation<DeleteExplodedViewRowMutation, DeleteExplodedViewRowMutationVariables>(DeleteExplodedViewRowDocument, baseOptions);
      }
export type DeleteExplodedViewRowMutationHookResult = ReturnType<typeof useDeleteExplodedViewRowMutation>;
export type DeleteExplodedViewRowMutationResult = Apollo.MutationResult<DeleteExplodedViewRowMutation>;
export type DeleteExplodedViewRowMutationOptions = Apollo.BaseMutationOptions<DeleteExplodedViewRowMutation, DeleteExplodedViewRowMutationVariables>;
export const CreateExcelExportSkeletonDocument = gql`
    mutation CreateExcelExportSkeleton($folderId: String) {
  createExcelExportSkeleton(folderId: $folderId)
}
    `;
export type CreateExcelExportSkeletonMutationFn = Apollo.MutationFunction<CreateExcelExportSkeletonMutation, CreateExcelExportSkeletonMutationVariables>;

/**
 * __useCreateExcelExportSkeletonMutation__
 *
 * To run a mutation, you first call `useCreateExcelExportSkeletonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExcelExportSkeletonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExcelExportSkeletonMutation, { data, loading, error }] = useCreateExcelExportSkeletonMutation({
 *   variables: {
 *      folderId: // value for 'folderId'
 *   },
 * });
 */
export function useCreateExcelExportSkeletonMutation(baseOptions?: Apollo.MutationHookOptions<CreateExcelExportSkeletonMutation, CreateExcelExportSkeletonMutationVariables>) {
        return Apollo.useMutation<CreateExcelExportSkeletonMutation, CreateExcelExportSkeletonMutationVariables>(CreateExcelExportSkeletonDocument, baseOptions);
      }
export type CreateExcelExportSkeletonMutationHookResult = ReturnType<typeof useCreateExcelExportSkeletonMutation>;
export type CreateExcelExportSkeletonMutationResult = Apollo.MutationResult<CreateExcelExportSkeletonMutation>;
export type CreateExcelExportSkeletonMutationOptions = Apollo.BaseMutationOptions<CreateExcelExportSkeletonMutation, CreateExcelExportSkeletonMutationVariables>;
export const LoadSpreedSheetDocument = gql`
    mutation LoadSpreedSheet($spreedSheet: UploadFile!) {
  loadSpreedSheet(spreedSheet: $spreedSheet) {
    id
    title
    explodedViewImages {
      id
      name
      path
      createdAt
    }
    explodedViewRows {
      id
      referenceNumber
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
    }
    brandFolder {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    modelFolder {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    mainCategoryFolder {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    subCategoryFolder {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    folder {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type LoadSpreedSheetMutationFn = Apollo.MutationFunction<LoadSpreedSheetMutation, LoadSpreedSheetMutationVariables>;

/**
 * __useLoadSpreedSheetMutation__
 *
 * To run a mutation, you first call `useLoadSpreedSheetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoadSpreedSheetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loadSpreedSheetMutation, { data, loading, error }] = useLoadSpreedSheetMutation({
 *   variables: {
 *      spreedSheet: // value for 'spreedSheet'
 *   },
 * });
 */
export function useLoadSpreedSheetMutation(baseOptions?: Apollo.MutationHookOptions<LoadSpreedSheetMutation, LoadSpreedSheetMutationVariables>) {
        return Apollo.useMutation<LoadSpreedSheetMutation, LoadSpreedSheetMutationVariables>(LoadSpreedSheetDocument, baseOptions);
      }
export type LoadSpreedSheetMutationHookResult = ReturnType<typeof useLoadSpreedSheetMutation>;
export type LoadSpreedSheetMutationResult = Apollo.MutationResult<LoadSpreedSheetMutation>;
export type LoadSpreedSheetMutationOptions = Apollo.BaseMutationOptions<LoadSpreedSheetMutation, LoadSpreedSheetMutationVariables>;
export const CreateFoldersDocument = gql`
    mutation CreateFolders($folders: [FolderCreateInput!]!) {
  createFolders(folders: $folders) {
    id
    title
    parent {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    active
    children {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    explodedViews {
      id
      title
      explodedViewImages {
        id
        name
        path
        createdAt
      }
      explodedViewRows {
        id
        referenceNumber
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
      }
      brandFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      modelFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      mainCategoryFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      subCategoryFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      folder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type CreateFoldersMutationFn = Apollo.MutationFunction<CreateFoldersMutation, CreateFoldersMutationVariables>;

/**
 * __useCreateFoldersMutation__
 *
 * To run a mutation, you first call `useCreateFoldersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFoldersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFoldersMutation, { data, loading, error }] = useCreateFoldersMutation({
 *   variables: {
 *      folders: // value for 'folders'
 *   },
 * });
 */
export function useCreateFoldersMutation(baseOptions?: Apollo.MutationHookOptions<CreateFoldersMutation, CreateFoldersMutationVariables>) {
        return Apollo.useMutation<CreateFoldersMutation, CreateFoldersMutationVariables>(CreateFoldersDocument, baseOptions);
      }
export type CreateFoldersMutationHookResult = ReturnType<typeof useCreateFoldersMutation>;
export type CreateFoldersMutationResult = Apollo.MutationResult<CreateFoldersMutation>;
export type CreateFoldersMutationOptions = Apollo.BaseMutationOptions<CreateFoldersMutation, CreateFoldersMutationVariables>;
export const DeleteFolderDocument = gql`
    mutation DeleteFolder($id: String!) {
  deleteFolder(id: $id) {
    success
  }
}
    `;
export type DeleteFolderMutationFn = Apollo.MutationFunction<DeleteFolderMutation, DeleteFolderMutationVariables>;

/**
 * __useDeleteFolderMutation__
 *
 * To run a mutation, you first call `useDeleteFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFolderMutation, { data, loading, error }] = useDeleteFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFolderMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFolderMutation, DeleteFolderMutationVariables>) {
        return Apollo.useMutation<DeleteFolderMutation, DeleteFolderMutationVariables>(DeleteFolderDocument, baseOptions);
      }
export type DeleteFolderMutationHookResult = ReturnType<typeof useDeleteFolderMutation>;
export type DeleteFolderMutationResult = Apollo.MutationResult<DeleteFolderMutation>;
export type DeleteFolderMutationOptions = Apollo.BaseMutationOptions<DeleteFolderMutation, DeleteFolderMutationVariables>;
export const UpdateFolderActiveStateDocument = gql`
    mutation UpdateFolderActiveState($id: UUID!, $active: Boolean!) {
  updateFolderActiveState(id: $id, active: $active) {
    id
    title
    parent {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    active
    children {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    explodedViews {
      id
      title
      explodedViewImages {
        id
        name
        path
        createdAt
      }
      explodedViewRows {
        id
        referenceNumber
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
      }
      brandFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      modelFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      mainCategoryFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      subCategoryFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      folder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateFolderActiveStateMutationFn = Apollo.MutationFunction<UpdateFolderActiveStateMutation, UpdateFolderActiveStateMutationVariables>;

/**
 * __useUpdateFolderActiveStateMutation__
 *
 * To run a mutation, you first call `useUpdateFolderActiveStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFolderActiveStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFolderActiveStateMutation, { data, loading, error }] = useUpdateFolderActiveStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useUpdateFolderActiveStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFolderActiveStateMutation, UpdateFolderActiveStateMutationVariables>) {
        return Apollo.useMutation<UpdateFolderActiveStateMutation, UpdateFolderActiveStateMutationVariables>(UpdateFolderActiveStateDocument, baseOptions);
      }
export type UpdateFolderActiveStateMutationHookResult = ReturnType<typeof useUpdateFolderActiveStateMutation>;
export type UpdateFolderActiveStateMutationResult = Apollo.MutationResult<UpdateFolderActiveStateMutation>;
export type UpdateFolderActiveStateMutationOptions = Apollo.BaseMutationOptions<UpdateFolderActiveStateMutation, UpdateFolderActiveStateMutationVariables>;
export const ChangeFolderDocument = gql`
    mutation ChangeFolder($id: String!, $title: String, $newParentId: String) {
  changeFolder(id: $id, title: $title, newParentId: $newParentId) {
    id
    title
    parent {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    active
    children {
      id
      title
      parent {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      active
      children {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      explodedViews {
        id
        title
        explodedViewImages {
          id
          name
          path
          createdAt
        }
        explodedViewRows {
          id
          referenceNumber
        }
        brandFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        modelFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        mainCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        subCategoryFolder {
          id
          title
          active
          createdAt
          updatedAt
        }
        folder {
          id
          title
          active
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    explodedViews {
      id
      title
      explodedViewImages {
        id
        name
        path
        createdAt
      }
      explodedViewRows {
        id
        referenceNumber
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
      }
      brandFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      modelFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      mainCategoryFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      subCategoryFolder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      folder {
        id
        title
        parent {
          id
          title
          active
          createdAt
          updatedAt
        }
        active
        children {
          id
          title
          active
          createdAt
          updatedAt
        }
        explodedViews {
          id
          title
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type ChangeFolderMutationFn = Apollo.MutationFunction<ChangeFolderMutation, ChangeFolderMutationVariables>;

/**
 * __useChangeFolderMutation__
 *
 * To run a mutation, you first call `useChangeFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeFolderMutation, { data, loading, error }] = useChangeFolderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      newParentId: // value for 'newParentId'
 *   },
 * });
 */
export function useChangeFolderMutation(baseOptions?: Apollo.MutationHookOptions<ChangeFolderMutation, ChangeFolderMutationVariables>) {
        return Apollo.useMutation<ChangeFolderMutation, ChangeFolderMutationVariables>(ChangeFolderDocument, baseOptions);
      }
export type ChangeFolderMutationHookResult = ReturnType<typeof useChangeFolderMutation>;
export type ChangeFolderMutationResult = Apollo.MutationResult<ChangeFolderMutation>;
export type ChangeFolderMutationOptions = Apollo.BaseMutationOptions<ChangeFolderMutation, ChangeFolderMutationVariables>;
export const PlaceOrderDocument = gql`
    mutation PlaceOrder($input: PlaceOrderInput!) {
  placeOrder(input: $input) {
    id
    serialNumber
    externalId
    status
    invoice
    items {
      id
      vehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      fee {
        id
        name
        articleNumber
        price
        vat
        discountedPrice
        createdAt
        updatedAt
        isAllowedOnVehicles
        active
      }
      replacedProduct {
        id
        name
        articleNumber
      }
      deliveredQuantity
      freeQuantity
      detailedQuantities {
        processing
        allocated
        blocked
        delivered
        invoiced
      }
      remark
      order {
        id
        serialNumber
        externalId
        status
        invoice
        items {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        products {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        fees {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        createdAt
        invoicedAt
        referenceUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        total
        vat
        totalQuantity
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        deliveryType
        address
        postalCode
        postalCity
        partialDelivery
        orderReferenceMessage
        externallyFinanced
      }
      status
      finalPrice
      unitPrice
      stockArrivalDate
      connection {
        id
        vehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        deliveredQuantity
        freeQuantity
        detailedQuantities {
          processing
          allocated
          blocked
          delivered
          invoiced
        }
        remark
        order {
          id
          serialNumber
          externalId
          status
          invoice
          createdAt
          invoicedAt
          total
          vat
          totalQuantity
          deliveryType
          address
          postalCode
          postalCity
          partialDelivery
          orderReferenceMessage
          externallyFinanced
        }
        status
        finalPrice
        unitPrice
        stockArrivalDate
        connection {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        connectionType
      }
      invoices {
        id
        invoiceDate
        buyer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        deliveryAddress
        dueDate
        priceExclVat
        priceInclVat
        invoiceRows {
          id
          sageId
          articleNumber
          description
          quantity
          netPriceExclVat
          grossPriceExclVat
        }
        status
        invoiceType
        paymentCondition
        orderReferences
        orderReferenceMessage
      }
      connectionType
    }
    products {
      id
      vehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      fee {
        id
        name
        articleNumber
        price
        vat
        discountedPrice
        createdAt
        updatedAt
        isAllowedOnVehicles
        active
      }
      replacedProduct {
        id
        name
        articleNumber
      }
      deliveredQuantity
      freeQuantity
      detailedQuantities {
        processing
        allocated
        blocked
        delivered
        invoiced
      }
      remark
      order {
        id
        serialNumber
        externalId
        status
        invoice
        items {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        products {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        fees {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        createdAt
        invoicedAt
        referenceUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        total
        vat
        totalQuantity
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        deliveryType
        address
        postalCode
        postalCity
        partialDelivery
        orderReferenceMessage
        externallyFinanced
      }
      status
      finalPrice
      unitPrice
      stockArrivalDate
      connection {
        id
        vehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        deliveredQuantity
        freeQuantity
        detailedQuantities {
          processing
          allocated
          blocked
          delivered
          invoiced
        }
        remark
        order {
          id
          serialNumber
          externalId
          status
          invoice
          createdAt
          invoicedAt
          total
          vat
          totalQuantity
          deliveryType
          address
          postalCode
          postalCity
          partialDelivery
          orderReferenceMessage
          externallyFinanced
        }
        status
        finalPrice
        unitPrice
        stockArrivalDate
        connection {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        connectionType
      }
      invoices {
        id
        invoiceDate
        buyer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        deliveryAddress
        dueDate
        priceExclVat
        priceInclVat
        invoiceRows {
          id
          sageId
          articleNumber
          description
          quantity
          netPriceExclVat
          grossPriceExclVat
        }
        status
        invoiceType
        paymentCondition
        orderReferences
        orderReferenceMessage
      }
      connectionType
    }
    fees {
      id
      vehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      fee {
        id
        name
        articleNumber
        price
        vat
        discountedPrice
        createdAt
        updatedAt
        isAllowedOnVehicles
        active
      }
      replacedProduct {
        id
        name
        articleNumber
      }
      deliveredQuantity
      freeQuantity
      detailedQuantities {
        processing
        allocated
        blocked
        delivered
        invoiced
      }
      remark
      order {
        id
        serialNumber
        externalId
        status
        invoice
        items {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        products {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        fees {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        createdAt
        invoicedAt
        referenceUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        total
        vat
        totalQuantity
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        deliveryType
        address
        postalCode
        postalCity
        partialDelivery
        orderReferenceMessage
        externallyFinanced
      }
      status
      finalPrice
      unitPrice
      stockArrivalDate
      connection {
        id
        vehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        deliveredQuantity
        freeQuantity
        detailedQuantities {
          processing
          allocated
          blocked
          delivered
          invoiced
        }
        remark
        order {
          id
          serialNumber
          externalId
          status
          invoice
          createdAt
          invoicedAt
          total
          vat
          totalQuantity
          deliveryType
          address
          postalCode
          postalCity
          partialDelivery
          orderReferenceMessage
          externallyFinanced
        }
        status
        finalPrice
        unitPrice
        stockArrivalDate
        connection {
          id
          deliveredQuantity
          freeQuantity
          remark
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connectionType
        }
        invoices {
          id
          invoiceDate
          deliveryAddress
          dueDate
          priceExclVat
          priceInclVat
          status
          invoiceType
          paymentCondition
          orderReferences
          orderReferenceMessage
        }
        connectionType
      }
      invoices {
        id
        invoiceDate
        buyer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        deliveryAddress
        dueDate
        priceExclVat
        priceInclVat
        invoiceRows {
          id
          sageId
          articleNumber
          description
          quantity
          netPriceExclVat
          grossPriceExclVat
        }
        status
        invoiceType
        paymentCondition
        orderReferences
        orderReferenceMessage
      }
      connectionType
    }
    createdAt
    invoicedAt
    referenceUser {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    total
    vat
    totalQuantity
    invoices {
      id
      invoiceDate
      buyer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      deliveryAddress
      dueDate
      priceExclVat
      priceInclVat
      invoiceRows {
        id
        sageId
        articleNumber
        description
        quantity
        netPriceExclVat
        grossPriceExclVat
      }
      status
      invoiceType
      paymentCondition
      orderReferences
      orderReferenceMessage
    }
    deliveryType
    address
    postalCode
    postalCity
    partialDelivery
    orderReferenceMessage
    externallyFinanced
  }
}
    `;
export type PlaceOrderMutationFn = Apollo.MutationFunction<PlaceOrderMutation, PlaceOrderMutationVariables>;

/**
 * __usePlaceOrderMutation__
 *
 * To run a mutation, you first call `usePlaceOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeOrderMutation, { data, loading, error }] = usePlaceOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePlaceOrderMutation(baseOptions?: Apollo.MutationHookOptions<PlaceOrderMutation, PlaceOrderMutationVariables>) {
        return Apollo.useMutation<PlaceOrderMutation, PlaceOrderMutationVariables>(PlaceOrderDocument, baseOptions);
      }
export type PlaceOrderMutationHookResult = ReturnType<typeof usePlaceOrderMutation>;
export type PlaceOrderMutationResult = Apollo.MutationResult<PlaceOrderMutation>;
export type PlaceOrderMutationOptions = Apollo.BaseMutationOptions<PlaceOrderMutation, PlaceOrderMutationVariables>;
export const ExportOrderDocument = gql`
    mutation ExportOrder($items: [ItemsInput!]!) {
  exportOrder(items: $items)
}
    `;
export type ExportOrderMutationFn = Apollo.MutationFunction<ExportOrderMutation, ExportOrderMutationVariables>;

/**
 * __useExportOrderMutation__
 *
 * To run a mutation, you first call `useExportOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportOrderMutation, { data, loading, error }] = useExportOrderMutation({
 *   variables: {
 *      items: // value for 'items'
 *   },
 * });
 */
export function useExportOrderMutation(baseOptions?: Apollo.MutationHookOptions<ExportOrderMutation, ExportOrderMutationVariables>) {
        return Apollo.useMutation<ExportOrderMutation, ExportOrderMutationVariables>(ExportOrderDocument, baseOptions);
      }
export type ExportOrderMutationHookResult = ReturnType<typeof useExportOrderMutation>;
export type ExportOrderMutationResult = Apollo.MutationResult<ExportOrderMutation>;
export type ExportOrderMutationOptions = Apollo.BaseMutationOptions<ExportOrderMutation, ExportOrderMutationVariables>;
export const UpdateCartWithPricesDocument = gql`
    mutation UpdateCartWithPrices($items: [ItemsInput!]!, $onBehalfOfRetailerId: Int, $deliveryType: DeliveryType) {
  updateCartWithPrices(
    items: $items
    onBehalfOfRetailerId: $onBehalfOfRetailerId
    deliveryType: $deliveryType
  ) {
    items {
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      fee {
        id
        name
        articleNumber
        price
        vat
        discountedPrice
        createdAt
        updatedAt
        isAllowedOnVehicles
        active
      }
      quantity
      price {
        price
        retailerPrice
        discountedPrice
      }
      replacedProduct {
        id
        name
        articleNumber
      }
      campaignOnVehicleId
      connectionType
      volumeDiscount {
        id
        name
        volume
        startTime
        endTime
        discountOne
        discountTwo
        discountThree
        mainProductIdentifier {
          vehicleType
        }
        level
      }
      productRule {
        id
        name
        showInCart
        startTime
        endTime
        discountTwo
        discountThree
        discountCode
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        productReferences {
          id
          vehicleType
        }
      }
      productRuleItems {
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        quantity
        price {
          price
          retailerPrice
          discountedPrice
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        campaignOnVehicleId
        connectionType
        volumeDiscount {
          id
          name
          volume
          startTime
          endTime
          discountOne
          discountTwo
          discountThree
          level
        }
        productRule {
          id
          name
          showInCart
          startTime
          endTime
          discountTwo
          discountThree
          discountCode
        }
        productRuleItems {
          quantity
          campaignOnVehicleId
          connectionType
        }
      }
    }
    products {
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      fee {
        id
        name
        articleNumber
        price
        vat
        discountedPrice
        createdAt
        updatedAt
        isAllowedOnVehicles
        active
      }
      quantity
      price {
        price
        retailerPrice
        discountedPrice
      }
      replacedProduct {
        id
        name
        articleNumber
      }
      campaignOnVehicleId
      connectionType
      volumeDiscount {
        id
        name
        volume
        startTime
        endTime
        discountOne
        discountTwo
        discountThree
        mainProductIdentifier {
          vehicleType
        }
        level
      }
      productRule {
        id
        name
        showInCart
        startTime
        endTime
        discountTwo
        discountThree
        discountCode
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        productReferences {
          id
          vehicleType
        }
      }
      productRuleItems {
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        quantity
        price {
          price
          retailerPrice
          discountedPrice
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        campaignOnVehicleId
        connectionType
        volumeDiscount {
          id
          name
          volume
          startTime
          endTime
          discountOne
          discountTwo
          discountThree
          level
        }
        productRule {
          id
          name
          showInCart
          startTime
          endTime
          discountTwo
          discountThree
          discountCode
        }
        productRuleItems {
          quantity
          campaignOnVehicleId
          connectionType
        }
      }
    }
    fees {
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      fee {
        id
        name
        articleNumber
        price
        vat
        discountedPrice
        createdAt
        updatedAt
        isAllowedOnVehicles
        active
      }
      quantity
      price {
        price
        retailerPrice
        discountedPrice
      }
      replacedProduct {
        id
        name
        articleNumber
      }
      campaignOnVehicleId
      connectionType
      volumeDiscount {
        id
        name
        volume
        startTime
        endTime
        discountOne
        discountTwo
        discountThree
        mainProductIdentifier {
          vehicleType
        }
        level
      }
      productRule {
        id
        name
        showInCart
        startTime
        endTime
        discountTwo
        discountThree
        discountCode
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        productReferences {
          id
          vehicleType
        }
      }
      productRuleItems {
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
        fee {
          id
          name
          articleNumber
          price
          vat
          discountedPrice
          createdAt
          updatedAt
          isAllowedOnVehicles
          active
        }
        quantity
        price {
          price
          retailerPrice
          discountedPrice
        }
        replacedProduct {
          id
          name
          articleNumber
        }
        campaignOnVehicleId
        connectionType
        volumeDiscount {
          id
          name
          volume
          startTime
          endTime
          discountOne
          discountTwo
          discountThree
          level
        }
        productRule {
          id
          name
          showInCart
          startTime
          endTime
          discountTwo
          discountThree
          discountCode
        }
        productRuleItems {
          quantity
          campaignOnVehicleId
          connectionType
        }
      }
    }
    totalQuantity
    totalExclVAT
    totalInclVAT
  }
}
    `;
export type UpdateCartWithPricesMutationFn = Apollo.MutationFunction<UpdateCartWithPricesMutation, UpdateCartWithPricesMutationVariables>;

/**
 * __useUpdateCartWithPricesMutation__
 *
 * To run a mutation, you first call `useUpdateCartWithPricesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCartWithPricesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCartWithPricesMutation, { data, loading, error }] = useUpdateCartWithPricesMutation({
 *   variables: {
 *      items: // value for 'items'
 *      onBehalfOfRetailerId: // value for 'onBehalfOfRetailerId'
 *      deliveryType: // value for 'deliveryType'
 *   },
 * });
 */
export function useUpdateCartWithPricesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCartWithPricesMutation, UpdateCartWithPricesMutationVariables>) {
        return Apollo.useMutation<UpdateCartWithPricesMutation, UpdateCartWithPricesMutationVariables>(UpdateCartWithPricesDocument, baseOptions);
      }
export type UpdateCartWithPricesMutationHookResult = ReturnType<typeof useUpdateCartWithPricesMutation>;
export type UpdateCartWithPricesMutationResult = Apollo.MutationResult<UpdateCartWithPricesMutation>;
export type UpdateCartWithPricesMutationOptions = Apollo.BaseMutationOptions<UpdateCartWithPricesMutation, UpdateCartWithPricesMutationVariables>;
export const CreateShoppingListDocument = gql`
    mutation CreateShoppingList($name: String!, $remark: String, $onBehalfOfRetailerId: Int, $items: [ShoppingListItemInput!]!) {
  createShoppingList(
    name: $name
    remark: $remark
    onBehalfOfRetailerId: $onBehalfOfRetailerId
    items: $items
  ) {
    id
    name
    remark
    items {
      id
      amount
      vehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      isCampaignEquipment
      stockVehicleProductCount
      relatedVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type CreateShoppingListMutationFn = Apollo.MutationFunction<CreateShoppingListMutation, CreateShoppingListMutationVariables>;

/**
 * __useCreateShoppingListMutation__
 *
 * To run a mutation, you first call `useCreateShoppingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShoppingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShoppingListMutation, { data, loading, error }] = useCreateShoppingListMutation({
 *   variables: {
 *      name: // value for 'name'
 *      remark: // value for 'remark'
 *      onBehalfOfRetailerId: // value for 'onBehalfOfRetailerId'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useCreateShoppingListMutation(baseOptions?: Apollo.MutationHookOptions<CreateShoppingListMutation, CreateShoppingListMutationVariables>) {
        return Apollo.useMutation<CreateShoppingListMutation, CreateShoppingListMutationVariables>(CreateShoppingListDocument, baseOptions);
      }
export type CreateShoppingListMutationHookResult = ReturnType<typeof useCreateShoppingListMutation>;
export type CreateShoppingListMutationResult = Apollo.MutationResult<CreateShoppingListMutation>;
export type CreateShoppingListMutationOptions = Apollo.BaseMutationOptions<CreateShoppingListMutation, CreateShoppingListMutationVariables>;
export const UpdateShoppingListDocument = gql`
    mutation UpdateShoppingList($id: String!, $name: String!, $remark: String, $onBehalfOfRetailerId: Int, $items: [ShoppingListItemInput!]!) {
  updateShoppingList(
    id: $id
    name: $name
    remark: $remark
    onBehalfOfRetailerId: $onBehalfOfRetailerId
    items: $items
  ) {
    id
    name
    remark
    items {
      id
      amount
      vehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      isCampaignEquipment
      stockVehicleProductCount
      relatedVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type UpdateShoppingListMutationFn = Apollo.MutationFunction<UpdateShoppingListMutation, UpdateShoppingListMutationVariables>;

/**
 * __useUpdateShoppingListMutation__
 *
 * To run a mutation, you first call `useUpdateShoppingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShoppingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShoppingListMutation, { data, loading, error }] = useUpdateShoppingListMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      remark: // value for 'remark'
 *      onBehalfOfRetailerId: // value for 'onBehalfOfRetailerId'
 *      items: // value for 'items'
 *   },
 * });
 */
export function useUpdateShoppingListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShoppingListMutation, UpdateShoppingListMutationVariables>) {
        return Apollo.useMutation<UpdateShoppingListMutation, UpdateShoppingListMutationVariables>(UpdateShoppingListDocument, baseOptions);
      }
export type UpdateShoppingListMutationHookResult = ReturnType<typeof useUpdateShoppingListMutation>;
export type UpdateShoppingListMutationResult = Apollo.MutationResult<UpdateShoppingListMutation>;
export type UpdateShoppingListMutationOptions = Apollo.BaseMutationOptions<UpdateShoppingListMutation, UpdateShoppingListMutationVariables>;
export const DeleteShoppingListDocument = gql`
    mutation DeleteShoppingList($id: String!) {
  deleteShoppingList(id: $id) {
    success
  }
}
    `;
export type DeleteShoppingListMutationFn = Apollo.MutationFunction<DeleteShoppingListMutation, DeleteShoppingListMutationVariables>;

/**
 * __useDeleteShoppingListMutation__
 *
 * To run a mutation, you first call `useDeleteShoppingListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShoppingListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShoppingListMutation, { data, loading, error }] = useDeleteShoppingListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteShoppingListMutation(baseOptions?: Apollo.MutationHookOptions<DeleteShoppingListMutation, DeleteShoppingListMutationVariables>) {
        return Apollo.useMutation<DeleteShoppingListMutation, DeleteShoppingListMutationVariables>(DeleteShoppingListDocument, baseOptions);
      }
export type DeleteShoppingListMutationHookResult = ReturnType<typeof useDeleteShoppingListMutation>;
export type DeleteShoppingListMutationResult = Apollo.MutationResult<DeleteShoppingListMutation>;
export type DeleteShoppingListMutationOptions = Apollo.BaseMutationOptions<DeleteShoppingListMutation, DeleteShoppingListMutationVariables>;
export const CreateShoppingListExcelTemplateDocument = gql`
    mutation CreateShoppingListExcelTemplate {
  createShoppingListExcelTemplate
}
    `;
export type CreateShoppingListExcelTemplateMutationFn = Apollo.MutationFunction<CreateShoppingListExcelTemplateMutation, CreateShoppingListExcelTemplateMutationVariables>;

/**
 * __useCreateShoppingListExcelTemplateMutation__
 *
 * To run a mutation, you first call `useCreateShoppingListExcelTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateShoppingListExcelTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createShoppingListExcelTemplateMutation, { data, loading, error }] = useCreateShoppingListExcelTemplateMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateShoppingListExcelTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateShoppingListExcelTemplateMutation, CreateShoppingListExcelTemplateMutationVariables>) {
        return Apollo.useMutation<CreateShoppingListExcelTemplateMutation, CreateShoppingListExcelTemplateMutationVariables>(CreateShoppingListExcelTemplateDocument, baseOptions);
      }
export type CreateShoppingListExcelTemplateMutationHookResult = ReturnType<typeof useCreateShoppingListExcelTemplateMutation>;
export type CreateShoppingListExcelTemplateMutationResult = Apollo.MutationResult<CreateShoppingListExcelTemplateMutation>;
export type CreateShoppingListExcelTemplateMutationOptions = Apollo.BaseMutationOptions<CreateShoppingListExcelTemplateMutation, CreateShoppingListExcelTemplateMutationVariables>;
export const UploadShoppingListExcelDocument = gql`
    mutation UploadShoppingListExcel($spreadSheet: UploadFile!, $onBehalfOfRetailerId: Int) {
  uploadShoppingListExcel(
    spreadSheet: $spreadSheet
    onBehalfOfRetailerId: $onBehalfOfRetailerId
  ) {
    id
    name
    remark
    items {
      id
      amount
      vehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      isCampaignEquipment
      stockVehicleProductCount
      relatedVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
    }
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    createdAt
    updatedAt
  }
}
    `;
export type UploadShoppingListExcelMutationFn = Apollo.MutationFunction<UploadShoppingListExcelMutation, UploadShoppingListExcelMutationVariables>;

/**
 * __useUploadShoppingListExcelMutation__
 *
 * To run a mutation, you first call `useUploadShoppingListExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadShoppingListExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadShoppingListExcelMutation, { data, loading, error }] = useUploadShoppingListExcelMutation({
 *   variables: {
 *      spreadSheet: // value for 'spreadSheet'
 *      onBehalfOfRetailerId: // value for 'onBehalfOfRetailerId'
 *   },
 * });
 */
export function useUploadShoppingListExcelMutation(baseOptions?: Apollo.MutationHookOptions<UploadShoppingListExcelMutation, UploadShoppingListExcelMutationVariables>) {
        return Apollo.useMutation<UploadShoppingListExcelMutation, UploadShoppingListExcelMutationVariables>(UploadShoppingListExcelDocument, baseOptions);
      }
export type UploadShoppingListExcelMutationHookResult = ReturnType<typeof useUploadShoppingListExcelMutation>;
export type UploadShoppingListExcelMutationResult = Apollo.MutationResult<UploadShoppingListExcelMutation>;
export type UploadShoppingListExcelMutationOptions = Apollo.BaseMutationOptions<UploadShoppingListExcelMutation, UploadShoppingListExcelMutationVariables>;
export const PlaceVehicleConsignmentOrderDocument = gql`
    mutation PlaceVehicleConsignmentOrder($vehicleId: String!, $message: String) {
  placeVehicleConsignmentOrder(vehicleId: $vehicleId, message: $message) {
    id
    stockVehicle {
      id
      sageId
      name
      brand {
        id
        externalId
        name
      }
      vehicleType
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      color
      colorCode
      createdAt
      price
      vat
      discountedPrice
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      equipments {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      articleNumber
      factoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      vehicleCampaign
      active
    }
    vehicleIdentificationNumber
    registrationNumber
    brand {
      id
      externalId
      name
    }
    modelSeries {
      id
      name
      brand {
        id
        externalId
        name
      }
      models {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
    }
    model {
      id
      name
      description
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
    }
    startedAt
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    showDetailedInformation
    typeOfInstallation
    nonColorFactoryOptions {
      id
      description
      factoryOptionType {
        id
        description
      }
    }
    createdAt
    updatedAt
    engineNumber
    factoryCode
    status
    firstArrivedToSweden
    producedAt
    firstRegisteredAt
    replacementText
    showBfiAndKey
    warranties {
      id
      status
      declineReason
      declineComment
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      customerRemark
      workshopRemark
      rows {
        id
        quantity
        originalQuantity
        price
        percentage
        originalPercentage
        total
        originalTotal
        referenceName
        description
        status
        complementReason
        complementComment
        declineReason
        declineComment
        type
        originalHours
        hours
        handledBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        ... on OperationRow {
          operationCode {
            id
            code
            category
            description
            time
          }
        }
        ... on ExternRow {
          purchaseRefersType
          receiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on ArticleRow {
          orderItem {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          legacyArticleNumber
          legacyOrderNumber
        }
        ... on LegacyRow {
          code
          originalComp
          comp
          originalPrice
          legacyType
        }
      }
      attachements {
        id
        name
        path
        mimeType
        isImage
        createdAt
      }
      registeredAt
      createdAt
      repairedAt
      sumRequested
      sumCredited
      type
      internalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      externalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      damageImage {
        id
        name
        path
        createdAt
      }
      shippingCost
      ... on VehicleWarranty {
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        meterReadingImage {
          id
          name
          path
          createdAt
        }
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        defectPartNumber
        defectPartTypeCode
        symptomCode
        operationalizedFrom {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
      }
      ... on GoodWillWarranty {
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        operationalizedTo {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
      }
      ... on PartWarranty {
        purchaseReceiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on LegacyWarranty {
        legacyClaimNumber
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        legacyDealer
        causeOfDamage
        warrantyStartedAt
        receivedAt
      }
    }
  }
}
    `;
export type PlaceVehicleConsignmentOrderMutationFn = Apollo.MutationFunction<PlaceVehicleConsignmentOrderMutation, PlaceVehicleConsignmentOrderMutationVariables>;

/**
 * __usePlaceVehicleConsignmentOrderMutation__
 *
 * To run a mutation, you first call `usePlaceVehicleConsignmentOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceVehicleConsignmentOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeVehicleConsignmentOrderMutation, { data, loading, error }] = usePlaceVehicleConsignmentOrderMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function usePlaceVehicleConsignmentOrderMutation(baseOptions?: Apollo.MutationHookOptions<PlaceVehicleConsignmentOrderMutation, PlaceVehicleConsignmentOrderMutationVariables>) {
        return Apollo.useMutation<PlaceVehicleConsignmentOrderMutation, PlaceVehicleConsignmentOrderMutationVariables>(PlaceVehicleConsignmentOrderDocument, baseOptions);
      }
export type PlaceVehicleConsignmentOrderMutationHookResult = ReturnType<typeof usePlaceVehicleConsignmentOrderMutation>;
export type PlaceVehicleConsignmentOrderMutationResult = Apollo.MutationResult<PlaceVehicleConsignmentOrderMutation>;
export type PlaceVehicleConsignmentOrderMutationOptions = Apollo.BaseMutationOptions<PlaceVehicleConsignmentOrderMutation, PlaceVehicleConsignmentOrderMutationVariables>;
export const PlaceVehicleExhibitionOrderDocument = gql`
    mutation PlaceVehicleExhibitionOrder($vehicleId: String!, $message: String) {
  placeVehicleExhibitionOrder(vehicleId: $vehicleId, message: $message) {
    id
    stockVehicle {
      id
      sageId
      name
      brand {
        id
        externalId
        name
      }
      vehicleType
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      color
      colorCode
      createdAt
      price
      vat
      discountedPrice
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      equipments {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      articleNumber
      factoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      vehicleCampaign
      active
    }
    vehicleIdentificationNumber
    registrationNumber
    brand {
      id
      externalId
      name
    }
    modelSeries {
      id
      name
      brand {
        id
        externalId
        name
      }
      models {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
    }
    model {
      id
      name
      description
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
    }
    startedAt
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    showDetailedInformation
    typeOfInstallation
    nonColorFactoryOptions {
      id
      description
      factoryOptionType {
        id
        description
      }
    }
    createdAt
    updatedAt
    engineNumber
    factoryCode
    status
    firstArrivedToSweden
    producedAt
    firstRegisteredAt
    replacementText
    showBfiAndKey
    warranties {
      id
      status
      declineReason
      declineComment
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      customerRemark
      workshopRemark
      rows {
        id
        quantity
        originalQuantity
        price
        percentage
        originalPercentage
        total
        originalTotal
        referenceName
        description
        status
        complementReason
        complementComment
        declineReason
        declineComment
        type
        originalHours
        hours
        handledBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        ... on OperationRow {
          operationCode {
            id
            code
            category
            description
            time
          }
        }
        ... on ExternRow {
          purchaseRefersType
          receiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on ArticleRow {
          orderItem {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          legacyArticleNumber
          legacyOrderNumber
        }
        ... on LegacyRow {
          code
          originalComp
          comp
          originalPrice
          legacyType
        }
      }
      attachements {
        id
        name
        path
        mimeType
        isImage
        createdAt
      }
      registeredAt
      createdAt
      repairedAt
      sumRequested
      sumCredited
      type
      internalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      externalMessages {
        id
        type
        text
        author {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        isRead
        createdAt
      }
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      damageImage {
        id
        name
        path
        createdAt
      }
      shippingCost
      ... on VehicleWarranty {
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        meterReadingImage {
          id
          name
          path
          createdAt
        }
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        defectPartNumber
        defectPartTypeCode
        symptomCode
        operationalizedFrom {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
      }
      ... on GoodWillWarranty {
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        meterReading
        operatingTime
        serviceBookImage {
          id
          name
          path
          createdAt
        }
        operationalizedTo {
          id
          status
          declineReason
          declineComment
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          customerRemark
          workshopRemark
          rows {
            id
            quantity
            originalQuantity
            price
            percentage
            originalPercentage
            total
            originalTotal
            referenceName
            description
            status
            complementReason
            complementComment
            declineReason
            declineComment
            type
            originalHours
            hours
            ... on ExternRow {
              purchaseRefersType
            }
            ... on ArticleRow {
              legacyArticleNumber
              legacyOrderNumber
            }
            ... on LegacyRow {
              code
              originalComp
              comp
              originalPrice
              legacyType
            }
          }
          attachements {
            id
            name
            path
            mimeType
            isImage
            createdAt
          }
          registeredAt
          createdAt
          repairedAt
          sumRequested
          sumCredited
          type
          internalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          externalMessages {
            id
            type
            text
            isRead
            createdAt
          }
          createdBy {
            id
            email
            name
            phoneNumber
            freeText
            role
            roleDescription
            active
            acceptsEmailReminders
            createdAt
            updatedAt
          }
          damageImage {
            id
            name
            path
            createdAt
          }
          shippingCost
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
      }
      ... on PartWarranty {
        purchaseReceiptImage {
          id
          name
          path
          createdAt
        }
      }
      ... on LegacyWarranty {
        legacyClaimNumber
        vehicle {
          id
          stockVehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          vehicleIdentificationNumber
          registrationNumber
          brand {
            id
            externalId
            name
          }
          modelSeries {
            id
            name
          }
          model {
            id
            name
            description
          }
          startedAt
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
            hasMaintenanceCustomers
            hasExternalFinancing
          }
          showDetailedInformation
          typeOfInstallation
          nonColorFactoryOptions {
            id
            description
          }
          createdAt
          updatedAt
          engineNumber
          factoryCode
          status
          firstArrivedToSweden
          producedAt
          firstRegisteredAt
          replacementText
          showBfiAndKey
          warranties {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            ... on VehicleWarranty {
              meterReading
              operatingTime
              defectPartNumber
              defectPartTypeCode
              symptomCode
            }
            ... on GoodWillWarranty {
              meterReading
              operatingTime
            }
            ... on LegacyWarranty {
              legacyClaimNumber
              legacyDealer
              causeOfDamage
              warrantyStartedAt
              receivedAt
            }
          }
        }
        legacyDealer
        causeOfDamage
        warrantyStartedAt
        receivedAt
      }
    }
  }
}
    `;
export type PlaceVehicleExhibitionOrderMutationFn = Apollo.MutationFunction<PlaceVehicleExhibitionOrderMutation, PlaceVehicleExhibitionOrderMutationVariables>;

/**
 * __usePlaceVehicleExhibitionOrderMutation__
 *
 * To run a mutation, you first call `usePlaceVehicleExhibitionOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePlaceVehicleExhibitionOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [placeVehicleExhibitionOrderMutation, { data, loading, error }] = usePlaceVehicleExhibitionOrderMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function usePlaceVehicleExhibitionOrderMutation(baseOptions?: Apollo.MutationHookOptions<PlaceVehicleExhibitionOrderMutation, PlaceVehicleExhibitionOrderMutationVariables>) {
        return Apollo.useMutation<PlaceVehicleExhibitionOrderMutation, PlaceVehicleExhibitionOrderMutationVariables>(PlaceVehicleExhibitionOrderDocument, baseOptions);
      }
export type PlaceVehicleExhibitionOrderMutationHookResult = ReturnType<typeof usePlaceVehicleExhibitionOrderMutation>;
export type PlaceVehicleExhibitionOrderMutationResult = Apollo.MutationResult<PlaceVehicleExhibitionOrderMutation>;
export type PlaceVehicleExhibitionOrderMutationOptions = Apollo.BaseMutationOptions<PlaceVehicleExhibitionOrderMutation, PlaceVehicleExhibitionOrderMutationVariables>;
export const CreateOperationGroupExcelDocument = gql`
    mutation CreateOperationGroupExcel($operationGroupId: String, $makeId: String, $modelSeriesId: String, $modelId: String) {
  createOperationGroupExcel(
    operationGroupId: $operationGroupId
    makeId: $makeId
    modelSeriesId: $modelSeriesId
    modelId: $modelId
  )
}
    `;
export type CreateOperationGroupExcelMutationFn = Apollo.MutationFunction<CreateOperationGroupExcelMutation, CreateOperationGroupExcelMutationVariables>;

/**
 * __useCreateOperationGroupExcelMutation__
 *
 * To run a mutation, you first call `useCreateOperationGroupExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOperationGroupExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOperationGroupExcelMutation, { data, loading, error }] = useCreateOperationGroupExcelMutation({
 *   variables: {
 *      operationGroupId: // value for 'operationGroupId'
 *      makeId: // value for 'makeId'
 *      modelSeriesId: // value for 'modelSeriesId'
 *      modelId: // value for 'modelId'
 *   },
 * });
 */
export function useCreateOperationGroupExcelMutation(baseOptions?: Apollo.MutationHookOptions<CreateOperationGroupExcelMutation, CreateOperationGroupExcelMutationVariables>) {
        return Apollo.useMutation<CreateOperationGroupExcelMutation, CreateOperationGroupExcelMutationVariables>(CreateOperationGroupExcelDocument, baseOptions);
      }
export type CreateOperationGroupExcelMutationHookResult = ReturnType<typeof useCreateOperationGroupExcelMutation>;
export type CreateOperationGroupExcelMutationResult = Apollo.MutationResult<CreateOperationGroupExcelMutation>;
export type CreateOperationGroupExcelMutationOptions = Apollo.BaseMutationOptions<CreateOperationGroupExcelMutation, CreateOperationGroupExcelMutationVariables>;
export const UploadOperationGroupExcelDocument = gql`
    mutation UploadOperationGroupExcel($spreadSheet: UploadFile!) {
  uploadOperationGroupExcel(spreadSheet: $spreadSheet) {
    success
  }
}
    `;
export type UploadOperationGroupExcelMutationFn = Apollo.MutationFunction<UploadOperationGroupExcelMutation, UploadOperationGroupExcelMutationVariables>;

/**
 * __useUploadOperationGroupExcelMutation__
 *
 * To run a mutation, you first call `useUploadOperationGroupExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadOperationGroupExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadOperationGroupExcelMutation, { data, loading, error }] = useUploadOperationGroupExcelMutation({
 *   variables: {
 *      spreadSheet: // value for 'spreadSheet'
 *   },
 * });
 */
export function useUploadOperationGroupExcelMutation(baseOptions?: Apollo.MutationHookOptions<UploadOperationGroupExcelMutation, UploadOperationGroupExcelMutationVariables>) {
        return Apollo.useMutation<UploadOperationGroupExcelMutation, UploadOperationGroupExcelMutationVariables>(UploadOperationGroupExcelDocument, baseOptions);
      }
export type UploadOperationGroupExcelMutationHookResult = ReturnType<typeof useUploadOperationGroupExcelMutation>;
export type UploadOperationGroupExcelMutationResult = Apollo.MutationResult<UploadOperationGroupExcelMutation>;
export type UploadOperationGroupExcelMutationOptions = Apollo.BaseMutationOptions<UploadOperationGroupExcelMutation, UploadOperationGroupExcelMutationVariables>;
export const DeleteOperationGroupDocument = gql`
    mutation DeleteOperationGroup($operationGroupId: String!) {
  deleteOperationGroup(operationGroupId: $operationGroupId) {
    success
  }
}
    `;
export type DeleteOperationGroupMutationFn = Apollo.MutationFunction<DeleteOperationGroupMutation, DeleteOperationGroupMutationVariables>;

/**
 * __useDeleteOperationGroupMutation__
 *
 * To run a mutation, you first call `useDeleteOperationGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOperationGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOperationGroupMutation, { data, loading, error }] = useDeleteOperationGroupMutation({
 *   variables: {
 *      operationGroupId: // value for 'operationGroupId'
 *   },
 * });
 */
export function useDeleteOperationGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOperationGroupMutation, DeleteOperationGroupMutationVariables>) {
        return Apollo.useMutation<DeleteOperationGroupMutation, DeleteOperationGroupMutationVariables>(DeleteOperationGroupDocument, baseOptions);
      }
export type DeleteOperationGroupMutationHookResult = ReturnType<typeof useDeleteOperationGroupMutation>;
export type DeleteOperationGroupMutationResult = Apollo.MutationResult<DeleteOperationGroupMutation>;
export type DeleteOperationGroupMutationOptions = Apollo.BaseMutationOptions<DeleteOperationGroupMutation, DeleteOperationGroupMutationVariables>;
export const UpsertProductRuleDocument = gql`
    mutation UpsertProductRule($input: ProductRuleInput!) {
  upsertProductRule(input: $input) {
    id
    name
    showInCart
    startTime
    endTime
    discountTwo
    discountThree
    discountCode
    stockVehicle {
      id
      sageId
      name
      brand {
        id
        externalId
        name
      }
      vehicleType
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      color
      colorCode
      createdAt
      price
      vat
      discountedPrice
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      equipments {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      articleNumber
      factoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      vehicleCampaign
      active
    }
    equipment {
      id
      name
      articleNumber
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      vehicleType
      price
      vat
      discountedPrice
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      brand {
        id
        externalId
        name
      }
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      createdAt
      vehicleCampaign
      remark
      active
      equipmentGroup {
        id
        name
        externalId
      }
    }
    sparePart {
      id
      articleNumber
      name
      remark
      price
      discountedPrice
      vat
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      explodedViewRows {
        id
        referenceNumber
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
      }
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      active
      vehicleCampaign
    }
    billOfMaterial {
      id
      articleNumber
      active
      bomCode
      description
      fromDate
      toDate
      bomRows {
        id
        linkQuantity
        fromDate
        toDate
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
      }
      price
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      discountedPrice
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      vehicleCampaign
      brand {
        id
        externalId
        name
      }
      equipmentGroup {
        id
        name
        externalId
      }
    }
    productReferences {
      id
      vehicleType
      make {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
    }
  }
}
    `;
export type UpsertProductRuleMutationFn = Apollo.MutationFunction<UpsertProductRuleMutation, UpsertProductRuleMutationVariables>;

/**
 * __useUpsertProductRuleMutation__
 *
 * To run a mutation, you first call `useUpsertProductRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProductRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProductRuleMutation, { data, loading, error }] = useUpsertProductRuleMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertProductRuleMutation(baseOptions?: Apollo.MutationHookOptions<UpsertProductRuleMutation, UpsertProductRuleMutationVariables>) {
        return Apollo.useMutation<UpsertProductRuleMutation, UpsertProductRuleMutationVariables>(UpsertProductRuleDocument, baseOptions);
      }
export type UpsertProductRuleMutationHookResult = ReturnType<typeof useUpsertProductRuleMutation>;
export type UpsertProductRuleMutationResult = Apollo.MutationResult<UpsertProductRuleMutation>;
export type UpsertProductRuleMutationOptions = Apollo.BaseMutationOptions<UpsertProductRuleMutation, UpsertProductRuleMutationVariables>;
export const DeleteProductRuleDocument = gql`
    mutation DeleteProductRule($id: ID!) {
  deleteProductRule(id: $id) {
    success
  }
}
    `;
export type DeleteProductRuleMutationFn = Apollo.MutationFunction<DeleteProductRuleMutation, DeleteProductRuleMutationVariables>;

/**
 * __useDeleteProductRuleMutation__
 *
 * To run a mutation, you first call `useDeleteProductRuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductRuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductRuleMutation, { data, loading, error }] = useDeleteProductRuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductRuleMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductRuleMutation, DeleteProductRuleMutationVariables>) {
        return Apollo.useMutation<DeleteProductRuleMutation, DeleteProductRuleMutationVariables>(DeleteProductRuleDocument, baseOptions);
      }
export type DeleteProductRuleMutationHookResult = ReturnType<typeof useDeleteProductRuleMutation>;
export type DeleteProductRuleMutationResult = Apollo.MutationResult<DeleteProductRuleMutation>;
export type DeleteProductRuleMutationOptions = Apollo.BaseMutationOptions<DeleteProductRuleMutation, DeleteProductRuleMutationVariables>;
export const CreateProductReferenceDocument = gql`
    mutation CreateProductReference($input: ProductReferenceInput!) {
  createProductReference(input: $input) {
    id
    name
    showInCart
    startTime
    endTime
    discountTwo
    discountThree
    discountCode
    stockVehicle {
      id
      sageId
      name
      brand {
        id
        externalId
        name
      }
      vehicleType
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      color
      colorCode
      createdAt
      price
      vat
      discountedPrice
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      equipments {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      articleNumber
      factoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      vehicleCampaign
      active
    }
    equipment {
      id
      name
      articleNumber
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      vehicleType
      price
      vat
      discountedPrice
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      brand {
        id
        externalId
        name
      }
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      createdAt
      vehicleCampaign
      remark
      active
      equipmentGroup {
        id
        name
        externalId
      }
    }
    sparePart {
      id
      articleNumber
      name
      remark
      price
      discountedPrice
      vat
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      explodedViewRows {
        id
        referenceNumber
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          price
          discountedPrice
          vehicleCampaign
        }
      }
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      active
      vehicleCampaign
    }
    billOfMaterial {
      id
      articleNumber
      active
      bomCode
      description
      fromDate
      toDate
      bomRows {
        id
        linkQuantity
        fromDate
        toDate
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          active
          vehicleCampaign
        }
      }
      price
      stock {
        warehouse
        atRetailer
        incoming
        status
        incomingDeliveries {
          deliveryDate
          quantity
        }
        updatedAt
      }
      discountedPrice
      images {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      thumbnail {
        id
        name
        path
        equipment {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        stockVehicle {
          id
          sageId
          name
          vehicleType
          color
          colorCode
          createdAt
          price
          vat
          discountedPrice
          articleNumber
          vehicleCampaign
          active
        }
        createdAt
      }
      vehicleCampaign
      brand {
        id
        externalId
        name
      }
      equipmentGroup {
        id
        name
        externalId
      }
    }
    productReferences {
      id
      vehicleType
      make {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
    }
  }
}
    `;
export type CreateProductReferenceMutationFn = Apollo.MutationFunction<CreateProductReferenceMutation, CreateProductReferenceMutationVariables>;

/**
 * __useCreateProductReferenceMutation__
 *
 * To run a mutation, you first call `useCreateProductReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductReferenceMutation, { data, loading, error }] = useCreateProductReferenceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateProductReferenceMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductReferenceMutation, CreateProductReferenceMutationVariables>) {
        return Apollo.useMutation<CreateProductReferenceMutation, CreateProductReferenceMutationVariables>(CreateProductReferenceDocument, baseOptions);
      }
export type CreateProductReferenceMutationHookResult = ReturnType<typeof useCreateProductReferenceMutation>;
export type CreateProductReferenceMutationResult = Apollo.MutationResult<CreateProductReferenceMutation>;
export type CreateProductReferenceMutationOptions = Apollo.BaseMutationOptions<CreateProductReferenceMutation, CreateProductReferenceMutationVariables>;
export const DeleteProductReferenceDocument = gql`
    mutation DeleteProductReference($id: ID!) {
  deleteProductReference(id: $id) {
    success
  }
}
    `;
export type DeleteProductReferenceMutationFn = Apollo.MutationFunction<DeleteProductReferenceMutation, DeleteProductReferenceMutationVariables>;

/**
 * __useDeleteProductReferenceMutation__
 *
 * To run a mutation, you first call `useDeleteProductReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductReferenceMutation, { data, loading, error }] = useDeleteProductReferenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductReferenceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteProductReferenceMutation, DeleteProductReferenceMutationVariables>) {
        return Apollo.useMutation<DeleteProductReferenceMutation, DeleteProductReferenceMutationVariables>(DeleteProductReferenceDocument, baseOptions);
      }
export type DeleteProductReferenceMutationHookResult = ReturnType<typeof useDeleteProductReferenceMutation>;
export type DeleteProductReferenceMutationResult = Apollo.MutationResult<DeleteProductReferenceMutation>;
export type DeleteProductReferenceMutationOptions = Apollo.BaseMutationOptions<DeleteProductReferenceMutation, DeleteProductReferenceMutationVariables>;
export const UpsertVolumeDiscountDocument = gql`
    mutation UpsertVolumeDiscount($input: VolumeDiscountInput!) {
  upsertVolumeDiscount(input: $input) {
    id
    name
    volume
    startTime
    endTime
    discountOne
    discountTwo
    discountThree
    mainProductIdentifier {
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      equipment {
        id
        name
        articleNumber
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        vehicleType
        price
        vat
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        brand {
          id
          externalId
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        createdAt
        vehicleCampaign
        remark
        active
        equipmentGroup {
          id
          name
          externalId
        }
      }
      sparePart {
        id
        articleNumber
        name
        remark
        price
        discountedPrice
        vat
        modelSeries {
          id
          name
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        model {
          id
          name
          description
        }
        explodedViewRows {
          id
          referenceNumber
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        active
        vehicleCampaign
      }
      billOfMaterial {
        id
        articleNumber
        active
        bomCode
        description
        fromDate
        toDate
        bomRows {
          id
          linkQuantity
          fromDate
          toDate
        }
        price
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        discountedPrice
        images {
          id
          name
          path
          createdAt
        }
        thumbnail {
          id
          name
          path
          createdAt
        }
        vehicleCampaign
        brand {
          id
          externalId
          name
        }
        equipmentGroup {
          id
          name
          externalId
        }
      }
      make {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      vehicleType
    }
    level
  }
}
    `;
export type UpsertVolumeDiscountMutationFn = Apollo.MutationFunction<UpsertVolumeDiscountMutation, UpsertVolumeDiscountMutationVariables>;

/**
 * __useUpsertVolumeDiscountMutation__
 *
 * To run a mutation, you first call `useUpsertVolumeDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertVolumeDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertVolumeDiscountMutation, { data, loading, error }] = useUpsertVolumeDiscountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertVolumeDiscountMutation(baseOptions?: Apollo.MutationHookOptions<UpsertVolumeDiscountMutation, UpsertVolumeDiscountMutationVariables>) {
        return Apollo.useMutation<UpsertVolumeDiscountMutation, UpsertVolumeDiscountMutationVariables>(UpsertVolumeDiscountDocument, baseOptions);
      }
export type UpsertVolumeDiscountMutationHookResult = ReturnType<typeof useUpsertVolumeDiscountMutation>;
export type UpsertVolumeDiscountMutationResult = Apollo.MutationResult<UpsertVolumeDiscountMutation>;
export type UpsertVolumeDiscountMutationOptions = Apollo.BaseMutationOptions<UpsertVolumeDiscountMutation, UpsertVolumeDiscountMutationVariables>;
export const DeleteVolumeDiscountDocument = gql`
    mutation DeleteVolumeDiscount($id: ID!) {
  deleteVolumeDiscount(id: $id) {
    success
  }
}
    `;
export type DeleteVolumeDiscountMutationFn = Apollo.MutationFunction<DeleteVolumeDiscountMutation, DeleteVolumeDiscountMutationVariables>;

/**
 * __useDeleteVolumeDiscountMutation__
 *
 * To run a mutation, you first call `useDeleteVolumeDiscountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVolumeDiscountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVolumeDiscountMutation, { data, loading, error }] = useDeleteVolumeDiscountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVolumeDiscountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVolumeDiscountMutation, DeleteVolumeDiscountMutationVariables>) {
        return Apollo.useMutation<DeleteVolumeDiscountMutation, DeleteVolumeDiscountMutationVariables>(DeleteVolumeDiscountDocument, baseOptions);
      }
export type DeleteVolumeDiscountMutationHookResult = ReturnType<typeof useDeleteVolumeDiscountMutation>;
export type DeleteVolumeDiscountMutationResult = Apollo.MutationResult<DeleteVolumeDiscountMutation>;
export type DeleteVolumeDiscountMutationOptions = Apollo.BaseMutationOptions<DeleteVolumeDiscountMutation, DeleteVolumeDiscountMutationVariables>;
export const UpsertMaintenanceTaskDocument = gql`
    mutation UpsertMaintenanceTask($input: UpsertMaintenanceTaskInput!) {
  upsertMaintenanceTask(input: $input) {
    id
    serviceId
    maintenanceCustomer {
      id
      name
      hourlyMaintenanceRate
      createdAt
      updatedAt
      externalId
    }
    vehicle {
      id
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      vehicleIdentificationNumber
      registrationNumber
      brand {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      startedAt
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      showDetailedInformation
      typeOfInstallation
      nonColorFactoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      createdAt
      updatedAt
      engineNumber
      factoryCode
      status
      firstArrivedToSweden
      producedAt
      firstRegisteredAt
      replacementText
      showBfiAndKey
      warranties {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          ... on ExternRow {
            purchaseRefersType
          }
          ... on ArticleRow {
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        ... on VehicleWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
        ... on GoodWillWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
        ... on PartWarranty {
          purchaseReceiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on LegacyWarranty {
          legacyClaimNumber
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          legacyDealer
          causeOfDamage
          warrantyStartedAt
          receivedAt
        }
      }
    }
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    status
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    meterReading
    customerRemark
    workshopRemark
    freeText
    customerReference
    workPerformedAt
    sumRequested
    sumCredited
    createdAt
    updatedAt
    customerApprovalBy
    customerApprovalAt
    meterReadingImage {
      id
      name
      path
      createdAt
    }
    damageImage {
      id
      name
      path
      createdAt
    }
    deliveryServiceImage {
      id
      name
      path
      createdAt
    }
    attachements {
      id
      name
      path
      mimeType
      isImage
      createdAt
    }
    rows {
      id
      workCategory
      status
      complementReason
      complementComment
      declineReason
      declineComment
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      handledBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      price
      requestedPrice
      quantity
      requestedQuantity
      ... on ArticleMaintenanceRow {
        orderItem {
          id
          vehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          fee {
            id
            name
            articleNumber
            price
            vat
            discountedPrice
            createdAt
            updatedAt
            isAllowedOnVehicles
            active
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          detailedQuantities {
            processing
            allocated
            blocked
            delivered
            invoiced
          }
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
            externallyFinanced
          }
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          connectionType
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
      }
      ... on ExternalMaintenanceRow {
        externalCostType
        receiptImage {
          id
          name
          path
          createdAt
        }
        externalCostDescription
      }
      ... on OperationMaintenanceRow {
        operationCode {
          id
          code
          category
          description
          time
        }
        customOperationDescription
      }
    }
    internalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    externalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
  }
}
    `;
export type UpsertMaintenanceTaskMutationFn = Apollo.MutationFunction<UpsertMaintenanceTaskMutation, UpsertMaintenanceTaskMutationVariables>;

/**
 * __useUpsertMaintenanceTaskMutation__
 *
 * To run a mutation, you first call `useUpsertMaintenanceTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMaintenanceTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMaintenanceTaskMutation, { data, loading, error }] = useUpsertMaintenanceTaskMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertMaintenanceTaskMutation(baseOptions?: Apollo.MutationHookOptions<UpsertMaintenanceTaskMutation, UpsertMaintenanceTaskMutationVariables>) {
        return Apollo.useMutation<UpsertMaintenanceTaskMutation, UpsertMaintenanceTaskMutationVariables>(UpsertMaintenanceTaskDocument, baseOptions);
      }
export type UpsertMaintenanceTaskMutationHookResult = ReturnType<typeof useUpsertMaintenanceTaskMutation>;
export type UpsertMaintenanceTaskMutationResult = Apollo.MutationResult<UpsertMaintenanceTaskMutation>;
export type UpsertMaintenanceTaskMutationOptions = Apollo.BaseMutationOptions<UpsertMaintenanceTaskMutation, UpsertMaintenanceTaskMutationVariables>;
export const DeleteMaintenanceTaskDocument = gql`
    mutation DeleteMaintenanceTask($id: ID!) {
  deleteMaintenanceTask(id: $id) {
    success
  }
}
    `;
export type DeleteMaintenanceTaskMutationFn = Apollo.MutationFunction<DeleteMaintenanceTaskMutation, DeleteMaintenanceTaskMutationVariables>;

/**
 * __useDeleteMaintenanceTaskMutation__
 *
 * To run a mutation, you first call `useDeleteMaintenanceTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMaintenanceTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMaintenanceTaskMutation, { data, loading, error }] = useDeleteMaintenanceTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMaintenanceTaskMutation(baseOptions?: Apollo.MutationHookOptions<DeleteMaintenanceTaskMutation, DeleteMaintenanceTaskMutationVariables>) {
        return Apollo.useMutation<DeleteMaintenanceTaskMutation, DeleteMaintenanceTaskMutationVariables>(DeleteMaintenanceTaskDocument, baseOptions);
      }
export type DeleteMaintenanceTaskMutationHookResult = ReturnType<typeof useDeleteMaintenanceTaskMutation>;
export type DeleteMaintenanceTaskMutationResult = Apollo.MutationResult<DeleteMaintenanceTaskMutation>;
export type DeleteMaintenanceTaskMutationOptions = Apollo.BaseMutationOptions<DeleteMaintenanceTaskMutation, DeleteMaintenanceTaskMutationVariables>;
export const UpdateMaintenanceTaskStatusDocument = gql`
    mutation UpdateMaintenanceTaskStatus($maintenanceTaskIds: [ID!]!, $status: MaintenanceTaskStatus!, $declineReason: MaintenanceRowDeclineReason, $declineComment: String) {
  updateMaintenanceTaskStatus(
    maintenanceTaskIds: $maintenanceTaskIds
    status: $status
    declineReason: $declineReason
    declineComment: $declineComment
  ) {
    success
  }
}
    `;
export type UpdateMaintenanceTaskStatusMutationFn = Apollo.MutationFunction<UpdateMaintenanceTaskStatusMutation, UpdateMaintenanceTaskStatusMutationVariables>;

/**
 * __useUpdateMaintenanceTaskStatusMutation__
 *
 * To run a mutation, you first call `useUpdateMaintenanceTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMaintenanceTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMaintenanceTaskStatusMutation, { data, loading, error }] = useUpdateMaintenanceTaskStatusMutation({
 *   variables: {
 *      maintenanceTaskIds: // value for 'maintenanceTaskIds'
 *      status: // value for 'status'
 *      declineReason: // value for 'declineReason'
 *      declineComment: // value for 'declineComment'
 *   },
 * });
 */
export function useUpdateMaintenanceTaskStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMaintenanceTaskStatusMutation, UpdateMaintenanceTaskStatusMutationVariables>) {
        return Apollo.useMutation<UpdateMaintenanceTaskStatusMutation, UpdateMaintenanceTaskStatusMutationVariables>(UpdateMaintenanceTaskStatusDocument, baseOptions);
      }
export type UpdateMaintenanceTaskStatusMutationHookResult = ReturnType<typeof useUpdateMaintenanceTaskStatusMutation>;
export type UpdateMaintenanceTaskStatusMutationResult = Apollo.MutationResult<UpdateMaintenanceTaskStatusMutation>;
export type UpdateMaintenanceTaskStatusMutationOptions = Apollo.BaseMutationOptions<UpdateMaintenanceTaskStatusMutation, UpdateMaintenanceTaskStatusMutationVariables>;
export const AddMaintenanceRowDocument = gql`
    mutation AddMaintenanceRow($maintenanceTaskId: ID!, $rowInput: MaintenanceRowInput!) {
  addMaintenanceRow(maintenanceTaskId: $maintenanceTaskId, rowInput: $rowInput) {
    id
    serviceId
    maintenanceCustomer {
      id
      name
      hourlyMaintenanceRate
      createdAt
      updatedAt
      externalId
    }
    vehicle {
      id
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      vehicleIdentificationNumber
      registrationNumber
      brand {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      startedAt
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      showDetailedInformation
      typeOfInstallation
      nonColorFactoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      createdAt
      updatedAt
      engineNumber
      factoryCode
      status
      firstArrivedToSweden
      producedAt
      firstRegisteredAt
      replacementText
      showBfiAndKey
      warranties {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          ... on ExternRow {
            purchaseRefersType
          }
          ... on ArticleRow {
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        ... on VehicleWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
        ... on GoodWillWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
        ... on PartWarranty {
          purchaseReceiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on LegacyWarranty {
          legacyClaimNumber
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          legacyDealer
          causeOfDamage
          warrantyStartedAt
          receivedAt
        }
      }
    }
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    status
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    meterReading
    customerRemark
    workshopRemark
    freeText
    customerReference
    workPerformedAt
    sumRequested
    sumCredited
    createdAt
    updatedAt
    customerApprovalBy
    customerApprovalAt
    meterReadingImage {
      id
      name
      path
      createdAt
    }
    damageImage {
      id
      name
      path
      createdAt
    }
    deliveryServiceImage {
      id
      name
      path
      createdAt
    }
    attachements {
      id
      name
      path
      mimeType
      isImage
      createdAt
    }
    rows {
      id
      workCategory
      status
      complementReason
      complementComment
      declineReason
      declineComment
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      handledBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      price
      requestedPrice
      quantity
      requestedQuantity
      ... on ArticleMaintenanceRow {
        orderItem {
          id
          vehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          fee {
            id
            name
            articleNumber
            price
            vat
            discountedPrice
            createdAt
            updatedAt
            isAllowedOnVehicles
            active
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          detailedQuantities {
            processing
            allocated
            blocked
            delivered
            invoiced
          }
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
            externallyFinanced
          }
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          connectionType
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
      }
      ... on ExternalMaintenanceRow {
        externalCostType
        receiptImage {
          id
          name
          path
          createdAt
        }
        externalCostDescription
      }
      ... on OperationMaintenanceRow {
        operationCode {
          id
          code
          category
          description
          time
        }
        customOperationDescription
      }
    }
    internalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    externalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
  }
}
    `;
export type AddMaintenanceRowMutationFn = Apollo.MutationFunction<AddMaintenanceRowMutation, AddMaintenanceRowMutationVariables>;

/**
 * __useAddMaintenanceRowMutation__
 *
 * To run a mutation, you first call `useAddMaintenanceRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMaintenanceRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMaintenanceRowMutation, { data, loading, error }] = useAddMaintenanceRowMutation({
 *   variables: {
 *      maintenanceTaskId: // value for 'maintenanceTaskId'
 *      rowInput: // value for 'rowInput'
 *   },
 * });
 */
export function useAddMaintenanceRowMutation(baseOptions?: Apollo.MutationHookOptions<AddMaintenanceRowMutation, AddMaintenanceRowMutationVariables>) {
        return Apollo.useMutation<AddMaintenanceRowMutation, AddMaintenanceRowMutationVariables>(AddMaintenanceRowDocument, baseOptions);
      }
export type AddMaintenanceRowMutationHookResult = ReturnType<typeof useAddMaintenanceRowMutation>;
export type AddMaintenanceRowMutationResult = Apollo.MutationResult<AddMaintenanceRowMutation>;
export type AddMaintenanceRowMutationOptions = Apollo.BaseMutationOptions<AddMaintenanceRowMutation, AddMaintenanceRowMutationVariables>;
export const HandleMaintenanceTaskRowsDocument = gql`
    mutation HandleMaintenanceTaskRows($maintenanceTaskId: ID!, $rowsInput: [UpdateMaintenanceRowsInput!]!) {
  handleMaintenanceTaskRows(
    maintenanceTaskId: $maintenanceTaskId
    rowsInput: $rowsInput
  ) {
    id
    serviceId
    maintenanceCustomer {
      id
      name
      hourlyMaintenanceRate
      createdAt
      updatedAt
      externalId
    }
    vehicle {
      id
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      vehicleIdentificationNumber
      registrationNumber
      brand {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      startedAt
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      showDetailedInformation
      typeOfInstallation
      nonColorFactoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      createdAt
      updatedAt
      engineNumber
      factoryCode
      status
      firstArrivedToSweden
      producedAt
      firstRegisteredAt
      replacementText
      showBfiAndKey
      warranties {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          ... on ExternRow {
            purchaseRefersType
          }
          ... on ArticleRow {
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        ... on VehicleWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
        ... on GoodWillWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
        ... on PartWarranty {
          purchaseReceiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on LegacyWarranty {
          legacyClaimNumber
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          legacyDealer
          causeOfDamage
          warrantyStartedAt
          receivedAt
        }
      }
    }
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    status
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    meterReading
    customerRemark
    workshopRemark
    freeText
    customerReference
    workPerformedAt
    sumRequested
    sumCredited
    createdAt
    updatedAt
    customerApprovalBy
    customerApprovalAt
    meterReadingImage {
      id
      name
      path
      createdAt
    }
    damageImage {
      id
      name
      path
      createdAt
    }
    deliveryServiceImage {
      id
      name
      path
      createdAt
    }
    attachements {
      id
      name
      path
      mimeType
      isImage
      createdAt
    }
    rows {
      id
      workCategory
      status
      complementReason
      complementComment
      declineReason
      declineComment
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      handledBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      price
      requestedPrice
      quantity
      requestedQuantity
      ... on ArticleMaintenanceRow {
        orderItem {
          id
          vehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          fee {
            id
            name
            articleNumber
            price
            vat
            discountedPrice
            createdAt
            updatedAt
            isAllowedOnVehicles
            active
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          detailedQuantities {
            processing
            allocated
            blocked
            delivered
            invoiced
          }
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
            externallyFinanced
          }
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          connectionType
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
      }
      ... on ExternalMaintenanceRow {
        externalCostType
        receiptImage {
          id
          name
          path
          createdAt
        }
        externalCostDescription
      }
      ... on OperationMaintenanceRow {
        operationCode {
          id
          code
          category
          description
          time
        }
        customOperationDescription
      }
    }
    internalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    externalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
  }
}
    `;
export type HandleMaintenanceTaskRowsMutationFn = Apollo.MutationFunction<HandleMaintenanceTaskRowsMutation, HandleMaintenanceTaskRowsMutationVariables>;

/**
 * __useHandleMaintenanceTaskRowsMutation__
 *
 * To run a mutation, you first call `useHandleMaintenanceTaskRowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandleMaintenanceTaskRowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handleMaintenanceTaskRowsMutation, { data, loading, error }] = useHandleMaintenanceTaskRowsMutation({
 *   variables: {
 *      maintenanceTaskId: // value for 'maintenanceTaskId'
 *      rowsInput: // value for 'rowsInput'
 *   },
 * });
 */
export function useHandleMaintenanceTaskRowsMutation(baseOptions?: Apollo.MutationHookOptions<HandleMaintenanceTaskRowsMutation, HandleMaintenanceTaskRowsMutationVariables>) {
        return Apollo.useMutation<HandleMaintenanceTaskRowsMutation, HandleMaintenanceTaskRowsMutationVariables>(HandleMaintenanceTaskRowsDocument, baseOptions);
      }
export type HandleMaintenanceTaskRowsMutationHookResult = ReturnType<typeof useHandleMaintenanceTaskRowsMutation>;
export type HandleMaintenanceTaskRowsMutationResult = Apollo.MutationResult<HandleMaintenanceTaskRowsMutation>;
export type HandleMaintenanceTaskRowsMutationOptions = Apollo.BaseMutationOptions<HandleMaintenanceTaskRowsMutation, HandleMaintenanceTaskRowsMutationVariables>;
export const ComplementMaintenanceRowDocument = gql`
    mutation ComplementMaintenanceRow($rowId: UUID!, $rowInput: MaintenanceRowInput, $image: ImageUpload) {
  complementMaintenanceRow(rowId: $rowId, rowInput: $rowInput, image: $image) {
    id
    serviceId
    maintenanceCustomer {
      id
      name
      hourlyMaintenanceRate
      createdAt
      updatedAt
      externalId
    }
    vehicle {
      id
      stockVehicle {
        id
        sageId
        name
        brand {
          id
          externalId
          name
        }
        vehicleType
        modelSeries {
          id
          name
        }
        model {
          id
          name
          description
        }
        color
        colorCode
        createdAt
        price
        vat
        discountedPrice
        thumbnail {
          id
          name
          path
          createdAt
        }
        images {
          id
          name
          path
          createdAt
        }
        stock {
          warehouse
          atRetailer
          incoming
          status
          updatedAt
        }
        equipments {
          id
          name
          articleNumber
          vehicleType
          price
          vat
          discountedPrice
          createdAt
          vehicleCampaign
          remark
          active
        }
        articleNumber
        factoryOptions {
          id
          description
        }
        vehicleCampaign
        active
      }
      vehicleIdentificationNumber
      registrationNumber
      brand {
        id
        externalId
        name
      }
      modelSeries {
        id
        name
        brand {
          id
          externalId
          name
        }
        models {
          id
          name
          description
        }
      }
      model {
        id
        name
        description
        modelSeries {
          id
          name
        }
      }
      startedAt
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      showDetailedInformation
      typeOfInstallation
      nonColorFactoryOptions {
        id
        description
        factoryOptionType {
          id
          description
        }
      }
      createdAt
      updatedAt
      engineNumber
      factoryCode
      status
      firstArrivedToSweden
      producedAt
      firstRegisteredAt
      replacementText
      showBfiAndKey
      warranties {
        id
        status
        declineReason
        declineComment
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        customerRemark
        workshopRemark
        rows {
          id
          quantity
          originalQuantity
          price
          percentage
          originalPercentage
          total
          originalTotal
          referenceName
          description
          status
          complementReason
          complementComment
          declineReason
          declineComment
          type
          originalHours
          hours
          ... on ExternRow {
            purchaseRefersType
          }
          ... on ArticleRow {
            legacyArticleNumber
            legacyOrderNumber
          }
          ... on LegacyRow {
            code
            originalComp
            comp
            originalPrice
            legacyType
          }
        }
        attachements {
          id
          name
          path
          mimeType
          isImage
          createdAt
        }
        registeredAt
        createdAt
        repairedAt
        sumRequested
        sumCredited
        type
        internalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        externalMessages {
          id
          type
          text
          isRead
          createdAt
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          acceptsEmailReminders
          createdAt
          updatedAt
        }
        damageImage {
          id
          name
          path
          createdAt
        }
        shippingCost
        ... on VehicleWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          meterReadingImage {
            id
            name
            path
            createdAt
          }
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          defectPartNumber
          defectPartTypeCode
          symptomCode
          operationalizedFrom {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
          }
        }
        ... on GoodWillWarranty {
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          meterReading
          operatingTime
          serviceBookImage {
            id
            name
            path
            createdAt
          }
          operationalizedTo {
            id
            status
            declineReason
            declineComment
            customerRemark
            workshopRemark
            registeredAt
            createdAt
            repairedAt
            sumRequested
            sumCredited
            type
            shippingCost
            meterReading
            operatingTime
            defectPartNumber
            defectPartTypeCode
            symptomCode
          }
        }
        ... on PartWarranty {
          purchaseReceiptImage {
            id
            name
            path
            createdAt
          }
        }
        ... on LegacyWarranty {
          legacyClaimNumber
          vehicle {
            id
            vehicleIdentificationNumber
            registrationNumber
            startedAt
            showDetailedInformation
            typeOfInstallation
            createdAt
            updatedAt
            engineNumber
            factoryCode
            status
            firstArrivedToSweden
            producedAt
            firstRegisteredAt
            replacementText
            showBfiAndKey
          }
          legacyDealer
          causeOfDamage
          warrantyStartedAt
          receivedAt
        }
      }
    }
    retailer {
      id
      externalId
      name
      address
      postalCode
      city
      country
      telephoneNumber
      email
      makes {
        id
        make {
          id
          externalId
          name
        }
        accessToSell
        accessToWorkshop
        warrantyHourlyPayment
      }
      users {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      contactUser {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        createdAt
        updatedAt
      }
      hasConsignmentVehicles
      hasExhibitionVehicles
      hasMaintenanceCustomers
      hasExternalFinancing
    }
    status
    createdBy {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    meterReading
    customerRemark
    workshopRemark
    freeText
    customerReference
    workPerformedAt
    sumRequested
    sumCredited
    createdAt
    updatedAt
    customerApprovalBy
    customerApprovalAt
    meterReadingImage {
      id
      name
      path
      createdAt
    }
    damageImage {
      id
      name
      path
      createdAt
    }
    deliveryServiceImage {
      id
      name
      path
      createdAt
    }
    attachements {
      id
      name
      path
      mimeType
      isImage
      createdAt
    }
    rows {
      id
      workCategory
      status
      complementReason
      complementComment
      declineReason
      declineComment
      createdBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      handledBy {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      price
      requestedPrice
      quantity
      requestedQuantity
      ... on ArticleMaintenanceRow {
        orderItem {
          id
          vehicle {
            id
            sageId
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
            active
          }
          equipment {
            id
            name
            articleNumber
            vehicleType
            price
            vat
            discountedPrice
            createdAt
            vehicleCampaign
            remark
            active
          }
          sparePart {
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            active
            vehicleCampaign
          }
          billOfMaterial {
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            price
            discountedPrice
            vehicleCampaign
          }
          fee {
            id
            name
            articleNumber
            price
            vat
            discountedPrice
            createdAt
            updatedAt
            isAllowedOnVehicles
            active
          }
          replacedProduct {
            id
            name
            articleNumber
          }
          deliveredQuantity
          freeQuantity
          detailedQuantities {
            processing
            allocated
            blocked
            delivered
            invoiced
          }
          remark
          order {
            id
            serialNumber
            externalId
            status
            invoice
            createdAt
            invoicedAt
            total
            vat
            totalQuantity
            deliveryType
            address
            postalCode
            postalCity
            partialDelivery
            orderReferenceMessage
            externallyFinanced
          }
          status
          finalPrice
          unitPrice
          stockArrivalDate
          connection {
            id
            deliveredQuantity
            freeQuantity
            remark
            status
            finalPrice
            unitPrice
            stockArrivalDate
            connectionType
          }
          invoices {
            id
            invoiceDate
            deliveryAddress
            dueDate
            priceExclVat
            priceInclVat
            status
            invoiceType
            paymentCondition
            orderReferences
            orderReferenceMessage
          }
          connectionType
        }
        billOfMaterial {
          id
          articleNumber
          active
          bomCode
          description
          fromDate
          toDate
          bomRows {
            id
            linkQuantity
            fromDate
            toDate
          }
          price
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          discountedPrice
          images {
            id
            name
            path
            createdAt
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          vehicleCampaign
          brand {
            id
            externalId
            name
          }
          equipmentGroup {
            id
            name
            externalId
          }
        }
        sparePart {
          id
          articleNumber
          name
          remark
          price
          discountedPrice
          vat
          modelSeries {
            id
            name
          }
          thumbnail {
            id
            name
            path
            createdAt
          }
          images {
            id
            name
            path
            createdAt
          }
          model {
            id
            name
            description
          }
          explodedViewRows {
            id
            referenceNumber
          }
          stock {
            warehouse
            atRetailer
            incoming
            status
            updatedAt
          }
          active
          vehicleCampaign
        }
      }
      ... on ExternalMaintenanceRow {
        externalCostType
        receiptImage {
          id
          name
          path
          createdAt
        }
        externalCostDescription
      }
      ... on OperationMaintenanceRow {
        operationCode {
          id
          code
          category
          description
          time
        }
        customOperationDescription
      }
    }
    internalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
    externalMessages {
      id
      type
      text
      author {
        id
        email
        name
        phoneNumber
        freeText
        role
        roleDescription
        retailer {
          id
          externalId
          name
          address
          postalCode
          city
          country
          telephoneNumber
          email
          hasConsignmentVehicles
          hasExhibitionVehicles
          hasMaintenanceCustomers
          hasExternalFinancing
        }
        active
        acceptsEmailReminders
        createdAt
        updatedAt
      }
      isRead
      createdAt
    }
  }
}
    `;
export type ComplementMaintenanceRowMutationFn = Apollo.MutationFunction<ComplementMaintenanceRowMutation, ComplementMaintenanceRowMutationVariables>;

/**
 * __useComplementMaintenanceRowMutation__
 *
 * To run a mutation, you first call `useComplementMaintenanceRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useComplementMaintenanceRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [complementMaintenanceRowMutation, { data, loading, error }] = useComplementMaintenanceRowMutation({
 *   variables: {
 *      rowId: // value for 'rowId'
 *      rowInput: // value for 'rowInput'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useComplementMaintenanceRowMutation(baseOptions?: Apollo.MutationHookOptions<ComplementMaintenanceRowMutation, ComplementMaintenanceRowMutationVariables>) {
        return Apollo.useMutation<ComplementMaintenanceRowMutation, ComplementMaintenanceRowMutationVariables>(ComplementMaintenanceRowDocument, baseOptions);
      }
export type ComplementMaintenanceRowMutationHookResult = ReturnType<typeof useComplementMaintenanceRowMutation>;
export type ComplementMaintenanceRowMutationResult = Apollo.MutationResult<ComplementMaintenanceRowMutation>;
export type ComplementMaintenanceRowMutationOptions = Apollo.BaseMutationOptions<ComplementMaintenanceRowMutation, ComplementMaintenanceRowMutationVariables>;
export const CreateMaintenanceRowExcelDocument = gql`
    mutation CreateMaintenanceRowExcel($fromDate: DateTime!, $toDate: DateTime!, $workCategories: [MaintenanceWorkCategory!]!, $maintenanceCustomerId: UUID!) {
  createMaintenanceRowExcel(
    fromDate: $fromDate
    toDate: $toDate
    workCategories: $workCategories
    maintenanceCustomerId: $maintenanceCustomerId
  )
}
    `;
export type CreateMaintenanceRowExcelMutationFn = Apollo.MutationFunction<CreateMaintenanceRowExcelMutation, CreateMaintenanceRowExcelMutationVariables>;

/**
 * __useCreateMaintenanceRowExcelMutation__
 *
 * To run a mutation, you first call `useCreateMaintenanceRowExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMaintenanceRowExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMaintenanceRowExcelMutation, { data, loading, error }] = useCreateMaintenanceRowExcelMutation({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *      workCategories: // value for 'workCategories'
 *      maintenanceCustomerId: // value for 'maintenanceCustomerId'
 *   },
 * });
 */
export function useCreateMaintenanceRowExcelMutation(baseOptions?: Apollo.MutationHookOptions<CreateMaintenanceRowExcelMutation, CreateMaintenanceRowExcelMutationVariables>) {
        return Apollo.useMutation<CreateMaintenanceRowExcelMutation, CreateMaintenanceRowExcelMutationVariables>(CreateMaintenanceRowExcelDocument, baseOptions);
      }
export type CreateMaintenanceRowExcelMutationHookResult = ReturnType<typeof useCreateMaintenanceRowExcelMutation>;
export type CreateMaintenanceRowExcelMutationResult = Apollo.MutationResult<CreateMaintenanceRowExcelMutation>;
export type CreateMaintenanceRowExcelMutationOptions = Apollo.BaseMutationOptions<CreateMaintenanceRowExcelMutation, CreateMaintenanceRowExcelMutationVariables>;
export const CreateCustomPriceExcelDocument = gql`
    mutation CreateCustomPriceExcel($maintenanceCustomerId: UUID!) {
  createCustomPriceExcel(maintenanceCustomerId: $maintenanceCustomerId)
}
    `;
export type CreateCustomPriceExcelMutationFn = Apollo.MutationFunction<CreateCustomPriceExcelMutation, CreateCustomPriceExcelMutationVariables>;

/**
 * __useCreateCustomPriceExcelMutation__
 *
 * To run a mutation, you first call `useCreateCustomPriceExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomPriceExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomPriceExcelMutation, { data, loading, error }] = useCreateCustomPriceExcelMutation({
 *   variables: {
 *      maintenanceCustomerId: // value for 'maintenanceCustomerId'
 *   },
 * });
 */
export function useCreateCustomPriceExcelMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomPriceExcelMutation, CreateCustomPriceExcelMutationVariables>) {
        return Apollo.useMutation<CreateCustomPriceExcelMutation, CreateCustomPriceExcelMutationVariables>(CreateCustomPriceExcelDocument, baseOptions);
      }
export type CreateCustomPriceExcelMutationHookResult = ReturnType<typeof useCreateCustomPriceExcelMutation>;
export type CreateCustomPriceExcelMutationResult = Apollo.MutationResult<CreateCustomPriceExcelMutation>;
export type CreateCustomPriceExcelMutationOptions = Apollo.BaseMutationOptions<CreateCustomPriceExcelMutation, CreateCustomPriceExcelMutationVariables>;
export const UploadCustomPriceExcelDocument = gql`
    mutation UploadCustomPriceExcel($spreadSheet: UploadFile!) {
  uploadCustomPriceExcel(spreadSheet: $spreadSheet) {
    success
  }
}
    `;
export type UploadCustomPriceExcelMutationFn = Apollo.MutationFunction<UploadCustomPriceExcelMutation, UploadCustomPriceExcelMutationVariables>;

/**
 * __useUploadCustomPriceExcelMutation__
 *
 * To run a mutation, you first call `useUploadCustomPriceExcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCustomPriceExcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCustomPriceExcelMutation, { data, loading, error }] = useUploadCustomPriceExcelMutation({
 *   variables: {
 *      spreadSheet: // value for 'spreadSheet'
 *   },
 * });
 */
export function useUploadCustomPriceExcelMutation(baseOptions?: Apollo.MutationHookOptions<UploadCustomPriceExcelMutation, UploadCustomPriceExcelMutationVariables>) {
        return Apollo.useMutation<UploadCustomPriceExcelMutation, UploadCustomPriceExcelMutationVariables>(UploadCustomPriceExcelDocument, baseOptions);
      }
export type UploadCustomPriceExcelMutationHookResult = ReturnType<typeof useUploadCustomPriceExcelMutation>;
export type UploadCustomPriceExcelMutationResult = Apollo.MutationResult<UploadCustomPriceExcelMutation>;
export type UploadCustomPriceExcelMutationOptions = Apollo.BaseMutationOptions<UploadCustomPriceExcelMutation, UploadCustomPriceExcelMutationVariables>;
export const SendWorkshopMessageDocument = gql`
    mutation SendWorkshopMessage($maintenanceTaskId: ID, $warrantyId: ID, $text: String!, $type: WorkshopMessageType!) {
  sendWorkshopMessage(
    maintenanceTaskId: $maintenanceTaskId
    warrantyId: $warrantyId
    text: $text
    type: $type
  ) {
    id
    type
    text
    author {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    isRead
    createdAt
  }
}
    `;
export type SendWorkshopMessageMutationFn = Apollo.MutationFunction<SendWorkshopMessageMutation, SendWorkshopMessageMutationVariables>;

/**
 * __useSendWorkshopMessageMutation__
 *
 * To run a mutation, you first call `useSendWorkshopMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendWorkshopMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendWorkshopMessageMutation, { data, loading, error }] = useSendWorkshopMessageMutation({
 *   variables: {
 *      maintenanceTaskId: // value for 'maintenanceTaskId'
 *      warrantyId: // value for 'warrantyId'
 *      text: // value for 'text'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSendWorkshopMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendWorkshopMessageMutation, SendWorkshopMessageMutationVariables>) {
        return Apollo.useMutation<SendWorkshopMessageMutation, SendWorkshopMessageMutationVariables>(SendWorkshopMessageDocument, baseOptions);
      }
export type SendWorkshopMessageMutationHookResult = ReturnType<typeof useSendWorkshopMessageMutation>;
export type SendWorkshopMessageMutationResult = Apollo.MutationResult<SendWorkshopMessageMutation>;
export type SendWorkshopMessageMutationOptions = Apollo.BaseMutationOptions<SendWorkshopMessageMutation, SendWorkshopMessageMutationVariables>;
export const SetWorkshopMessageReadStateDocument = gql`
    mutation SetWorkshopMessageReadState($id: UUID!, $isRead: Boolean!) {
  setWorkshopMessageReadState(id: $id, isRead: $isRead) {
    id
    type
    text
    author {
      id
      email
      name
      phoneNumber
      freeText
      role
      roleDescription
      retailer {
        id
        externalId
        name
        address
        postalCode
        city
        country
        telephoneNumber
        email
        makes {
          id
          accessToSell
          accessToWorkshop
          warrantyHourlyPayment
        }
        users {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        contactUser {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          active
          createdAt
          updatedAt
        }
        hasConsignmentVehicles
        hasExhibitionVehicles
        hasMaintenanceCustomers
        hasExternalFinancing
      }
      active
      acceptsEmailReminders
      createdAt
      updatedAt
    }
    isRead
    createdAt
  }
}
    `;
export type SetWorkshopMessageReadStateMutationFn = Apollo.MutationFunction<SetWorkshopMessageReadStateMutation, SetWorkshopMessageReadStateMutationVariables>;

/**
 * __useSetWorkshopMessageReadStateMutation__
 *
 * To run a mutation, you first call `useSetWorkshopMessageReadStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetWorkshopMessageReadStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setWorkshopMessageReadStateMutation, { data, loading, error }] = useSetWorkshopMessageReadStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isRead: // value for 'isRead'
 *   },
 * });
 */
export function useSetWorkshopMessageReadStateMutation(baseOptions?: Apollo.MutationHookOptions<SetWorkshopMessageReadStateMutation, SetWorkshopMessageReadStateMutationVariables>) {
        return Apollo.useMutation<SetWorkshopMessageReadStateMutation, SetWorkshopMessageReadStateMutationVariables>(SetWorkshopMessageReadStateDocument, baseOptions);
      }
export type SetWorkshopMessageReadStateMutationHookResult = ReturnType<typeof useSetWorkshopMessageReadStateMutation>;
export type SetWorkshopMessageReadStateMutationResult = Apollo.MutationResult<SetWorkshopMessageReadStateMutation>;
export type SetWorkshopMessageReadStateMutationOptions = Apollo.BaseMutationOptions<SetWorkshopMessageReadStateMutation, SetWorkshopMessageReadStateMutationVariables>;
export const UploadWorkshopAttachementDocument = gql`
    mutation UploadWorkshopAttachement($fileInput: FileUpload!, $maintenanceTaskId: ID, $warrantyId: ID) {
  uploadWorkshopAttachement(
    fileInput: $fileInput
    maintenanceTaskId: $maintenanceTaskId
    warrantyId: $warrantyId
  ) {
    id
    name
    path
    mimeType
    isImage
    createdAt
  }
}
    `;
export type UploadWorkshopAttachementMutationFn = Apollo.MutationFunction<UploadWorkshopAttachementMutation, UploadWorkshopAttachementMutationVariables>;

/**
 * __useUploadWorkshopAttachementMutation__
 *
 * To run a mutation, you first call `useUploadWorkshopAttachementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadWorkshopAttachementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadWorkshopAttachementMutation, { data, loading, error }] = useUploadWorkshopAttachementMutation({
 *   variables: {
 *      fileInput: // value for 'fileInput'
 *      maintenanceTaskId: // value for 'maintenanceTaskId'
 *      warrantyId: // value for 'warrantyId'
 *   },
 * });
 */
export function useUploadWorkshopAttachementMutation(baseOptions?: Apollo.MutationHookOptions<UploadWorkshopAttachementMutation, UploadWorkshopAttachementMutationVariables>) {
        return Apollo.useMutation<UploadWorkshopAttachementMutation, UploadWorkshopAttachementMutationVariables>(UploadWorkshopAttachementDocument, baseOptions);
      }
export type UploadWorkshopAttachementMutationHookResult = ReturnType<typeof useUploadWorkshopAttachementMutation>;
export type UploadWorkshopAttachementMutationResult = Apollo.MutationResult<UploadWorkshopAttachementMutation>;
export type UploadWorkshopAttachementMutationOptions = Apollo.BaseMutationOptions<UploadWorkshopAttachementMutation, UploadWorkshopAttachementMutationVariables>;
export const RemoveWorkshopAttachementDocument = gql`
    mutation RemoveWorkshopAttachement($id: UUID!) {
  removeWorkshopAttachement(id: $id) {
    success
  }
}
    `;
export type RemoveWorkshopAttachementMutationFn = Apollo.MutationFunction<RemoveWorkshopAttachementMutation, RemoveWorkshopAttachementMutationVariables>;

/**
 * __useRemoveWorkshopAttachementMutation__
 *
 * To run a mutation, you first call `useRemoveWorkshopAttachementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWorkshopAttachementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWorkshopAttachementMutation, { data, loading, error }] = useRemoveWorkshopAttachementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveWorkshopAttachementMutation(baseOptions?: Apollo.MutationHookOptions<RemoveWorkshopAttachementMutation, RemoveWorkshopAttachementMutationVariables>) {
        return Apollo.useMutation<RemoveWorkshopAttachementMutation, RemoveWorkshopAttachementMutationVariables>(RemoveWorkshopAttachementDocument, baseOptions);
      }
export type RemoveWorkshopAttachementMutationHookResult = ReturnType<typeof useRemoveWorkshopAttachementMutation>;
export type RemoveWorkshopAttachementMutationResult = Apollo.MutationResult<RemoveWorkshopAttachementMutation>;
export type RemoveWorkshopAttachementMutationOptions = Apollo.BaseMutationOptions<RemoveWorkshopAttachementMutation, RemoveWorkshopAttachementMutationVariables>;
export const ParentFoldersWithoutChildrenDocument = gql`
    query ParentFoldersWithoutChildren {
  parentFolders {
    id
    title
    createdAt
    updatedAt
    active
  }
}
    `;

/**
 * __useParentFoldersWithoutChildrenQuery__
 *
 * To run a query within a React component, call `useParentFoldersWithoutChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentFoldersWithoutChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentFoldersWithoutChildrenQuery({
 *   variables: {
 *   },
 * });
 */
export function useParentFoldersWithoutChildrenQuery(baseOptions?: Apollo.QueryHookOptions<ParentFoldersWithoutChildrenQuery, ParentFoldersWithoutChildrenQueryVariables>) {
        return Apollo.useQuery<ParentFoldersWithoutChildrenQuery, ParentFoldersWithoutChildrenQueryVariables>(ParentFoldersWithoutChildrenDocument, baseOptions);
      }
export function useParentFoldersWithoutChildrenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParentFoldersWithoutChildrenQuery, ParentFoldersWithoutChildrenQueryVariables>) {
          return Apollo.useLazyQuery<ParentFoldersWithoutChildrenQuery, ParentFoldersWithoutChildrenQueryVariables>(ParentFoldersWithoutChildrenDocument, baseOptions);
        }
export type ParentFoldersWithoutChildrenQueryHookResult = ReturnType<typeof useParentFoldersWithoutChildrenQuery>;
export type ParentFoldersWithoutChildrenLazyQueryHookResult = ReturnType<typeof useParentFoldersWithoutChildrenLazyQuery>;
export type ParentFoldersWithoutChildrenQueryResult = Apollo.QueryResult<ParentFoldersWithoutChildrenQuery, ParentFoldersWithoutChildrenQueryVariables>;
export const TerseVehicleWarrantyHistoryDocument = gql`
    query TerseVehicleWarrantyHistory($warrantyId: ID!) {
  vehicleWarrantyHistory(warrantyId: $warrantyId) {
    id
    status
    retailer {
      externalId
    }
    customerRemark
    workshopRemark
    sumCredited
    shippingCost
    ... on VehicleWarranty {
      defectPartNumber
    }
  }
}
    `;

/**
 * __useTerseVehicleWarrantyHistoryQuery__
 *
 * To run a query within a React component, call `useTerseVehicleWarrantyHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerseVehicleWarrantyHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerseVehicleWarrantyHistoryQuery({
 *   variables: {
 *      warrantyId: // value for 'warrantyId'
 *   },
 * });
 */
export function useTerseVehicleWarrantyHistoryQuery(baseOptions: Apollo.QueryHookOptions<TerseVehicleWarrantyHistoryQuery, TerseVehicleWarrantyHistoryQueryVariables>) {
        return Apollo.useQuery<TerseVehicleWarrantyHistoryQuery, TerseVehicleWarrantyHistoryQueryVariables>(TerseVehicleWarrantyHistoryDocument, baseOptions);
      }
export function useTerseVehicleWarrantyHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TerseVehicleWarrantyHistoryQuery, TerseVehicleWarrantyHistoryQueryVariables>) {
          return Apollo.useLazyQuery<TerseVehicleWarrantyHistoryQuery, TerseVehicleWarrantyHistoryQueryVariables>(TerseVehicleWarrantyHistoryDocument, baseOptions);
        }
export type TerseVehicleWarrantyHistoryQueryHookResult = ReturnType<typeof useTerseVehicleWarrantyHistoryQuery>;
export type TerseVehicleWarrantyHistoryLazyQueryHookResult = ReturnType<typeof useTerseVehicleWarrantyHistoryLazyQuery>;
export type TerseVehicleWarrantyHistoryQueryResult = Apollo.QueryResult<TerseVehicleWarrantyHistoryQuery, TerseVehicleWarrantyHistoryQueryVariables>;
export const TerseWarrantiesDocument = gql`
    query TerseWarranties($filter: WarrantyFilter, $sorting: [BaseSorting!]) {
  warranties(filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      status
      retailer {
        externalId
      }
      customerRemark
      workshopRemark
      createdAt
      repairedAt
      sumRequested
      sumCredited
      type
      internalMessages {
        ...PartialWorkshopMessage
      }
      externalMessages {
        ...PartialWorkshopMessage
      }
      shippingCost
      ... on VehicleWarranty {
        vehicle {
          vehicleIdentificationNumber
          registrationNumber
          firstRegisteredAt
        }
        defectPartNumber
        defectPartTypeCode
        symptomCode
      }
      ... on GoodWillWarranty {
        vehicle {
          vehicleIdentificationNumber
          registrationNumber
          firstRegisteredAt
        }
      }
      ... on LegacyWarranty {
        legacyClaimNumber
        vehicle {
          vehicleIdentificationNumber
          registrationNumber
          firstRegisteredAt
        }
        legacyDealer
      }
    }
  }
}
    ${PartialWorkshopMessageFragmentDoc}`;

/**
 * __useTerseWarrantiesQuery__
 *
 * To run a query within a React component, call `useTerseWarrantiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTerseWarrantiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTerseWarrantiesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useTerseWarrantiesQuery(baseOptions?: Apollo.QueryHookOptions<TerseWarrantiesQuery, TerseWarrantiesQueryVariables>) {
        return Apollo.useQuery<TerseWarrantiesQuery, TerseWarrantiesQueryVariables>(TerseWarrantiesDocument, baseOptions);
      }
export function useTerseWarrantiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TerseWarrantiesQuery, TerseWarrantiesQueryVariables>) {
          return Apollo.useLazyQuery<TerseWarrantiesQuery, TerseWarrantiesQueryVariables>(TerseWarrantiesDocument, baseOptions);
        }
export type TerseWarrantiesQueryHookResult = ReturnType<typeof useTerseWarrantiesQuery>;
export type TerseWarrantiesLazyQueryHookResult = ReturnType<typeof useTerseWarrantiesLazyQuery>;
export type TerseWarrantiesQueryResult = Apollo.QueryResult<TerseWarrantiesQuery, TerseWarrantiesQueryVariables>;
export const MaintenanceTasksTableDocument = gql`
    query MaintenanceTasksTable($filter: MaintenanceTaskFilter, $sorting: [BaseSorting!]) {
  maintenanceTasks(filter: $filter, sorting: $sorting) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      serviceId
      status
      workPerformedAt
      createdAt
      sumRequested
      workshopRemark
      maintenanceCustomer {
        name
      }
      retailer {
        name
      }
      vehicle {
        registrationNumber
        vehicleIdentificationNumber
      }
      internalMessages {
        ...PartialWorkshopMessage
      }
      externalMessages {
        ...PartialWorkshopMessage
      }
    }
  }
}
    ${PartialWorkshopMessageFragmentDoc}`;

/**
 * __useMaintenanceTasksTableQuery__
 *
 * To run a query within a React component, call `useMaintenanceTasksTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceTasksTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceTasksTableQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useMaintenanceTasksTableQuery(baseOptions?: Apollo.QueryHookOptions<MaintenanceTasksTableQuery, MaintenanceTasksTableQueryVariables>) {
        return Apollo.useQuery<MaintenanceTasksTableQuery, MaintenanceTasksTableQueryVariables>(MaintenanceTasksTableDocument, baseOptions);
      }
export function useMaintenanceTasksTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaintenanceTasksTableQuery, MaintenanceTasksTableQueryVariables>) {
          return Apollo.useLazyQuery<MaintenanceTasksTableQuery, MaintenanceTasksTableQueryVariables>(MaintenanceTasksTableDocument, baseOptions);
        }
export type MaintenanceTasksTableQueryHookResult = ReturnType<typeof useMaintenanceTasksTableQuery>;
export type MaintenanceTasksTableLazyQueryHookResult = ReturnType<typeof useMaintenanceTasksTableLazyQuery>;
export type MaintenanceTasksTableQueryResult = Apollo.QueryResult<MaintenanceTasksTableQuery, MaintenanceTasksTableQueryVariables>;
export const ExplodedViewRowsTableDocument = gql`
    query ExplodedViewRowsTable($filter: ExplodedViewRowFilter) {
  explodedViewRows(filter: $filter) {
    meta {
      total
      limit
      offset
    }
    edges {
      id
      referenceNumber
      stockVehicle {
        id
        thumbnail {
          id
          name
          path
        }
        active
        name
        articleNumber
        price
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          incomingDeliveries {
            deliveryDate
            quantity
          }
        }
      }
      equipment {
        id
        thumbnail {
          id
          name
          path
        }
        active
        remark
        name
        articleNumber
        price
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          incomingDeliveries {
            deliveryDate
            quantity
          }
        }
      }
      sparePart {
        id
        thumbnail {
          id
          name
          path
        }
        active
        remark
        name
        articleNumber
        price
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          incomingDeliveries {
            deliveryDate
            quantity
          }
        }
      }
      billOfMaterial {
        id
        thumbnail {
          id
          name
          path
        }
        active
        description
        articleNumber
        price
        discountedPrice
        stock {
          warehouse
          atRetailer
          incoming
          status
          incomingDeliveries {
            deliveryDate
            quantity
          }
        }
      }
    }
  }
}
    `;

/**
 * __useExplodedViewRowsTableQuery__
 *
 * To run a query within a React component, call `useExplodedViewRowsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useExplodedViewRowsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExplodedViewRowsTableQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useExplodedViewRowsTableQuery(baseOptions?: Apollo.QueryHookOptions<ExplodedViewRowsTableQuery, ExplodedViewRowsTableQueryVariables>) {
        return Apollo.useQuery<ExplodedViewRowsTableQuery, ExplodedViewRowsTableQueryVariables>(ExplodedViewRowsTableDocument, baseOptions);
      }
export function useExplodedViewRowsTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExplodedViewRowsTableQuery, ExplodedViewRowsTableQueryVariables>) {
          return Apollo.useLazyQuery<ExplodedViewRowsTableQuery, ExplodedViewRowsTableQueryVariables>(ExplodedViewRowsTableDocument, baseOptions);
        }
export type ExplodedViewRowsTableQueryHookResult = ReturnType<typeof useExplodedViewRowsTableQuery>;
export type ExplodedViewRowsTableLazyQueryHookResult = ReturnType<typeof useExplodedViewRowsTableLazyQuery>;
export type ExplodedViewRowsTableQueryResult = Apollo.QueryResult<ExplodedViewRowsTableQuery, ExplodedViewRowsTableQueryVariables>;
export const MaintenanceTaskViewDocument = gql`
    query MaintenanceTaskView($id: ID!) {
  maintenanceTaskById(id: $id) {
    ...PartialMaintenanceTask
  }
}
    ${PartialMaintenanceTaskFragmentDoc}`;

/**
 * __useMaintenanceTaskViewQuery__
 *
 * To run a query within a React component, call `useMaintenanceTaskViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useMaintenanceTaskViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMaintenanceTaskViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMaintenanceTaskViewQuery(baseOptions: Apollo.QueryHookOptions<MaintenanceTaskViewQuery, MaintenanceTaskViewQueryVariables>) {
        return Apollo.useQuery<MaintenanceTaskViewQuery, MaintenanceTaskViewQueryVariables>(MaintenanceTaskViewDocument, baseOptions);
      }
export function useMaintenanceTaskViewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MaintenanceTaskViewQuery, MaintenanceTaskViewQueryVariables>) {
          return Apollo.useLazyQuery<MaintenanceTaskViewQuery, MaintenanceTaskViewQueryVariables>(MaintenanceTaskViewDocument, baseOptions);
        }
export type MaintenanceTaskViewQueryHookResult = ReturnType<typeof useMaintenanceTaskViewQuery>;
export type MaintenanceTaskViewLazyQueryHookResult = ReturnType<typeof useMaintenanceTaskViewLazyQuery>;
export type MaintenanceTaskViewQueryResult = Apollo.QueryResult<MaintenanceTaskViewQuery, MaintenanceTaskViewQueryVariables>;
export const CustomAddMaintenanceRowDocument = gql`
    mutation CustomAddMaintenanceRow($maintenanceTaskId: ID!, $rowInput: MaintenanceRowInput!) {
  addMaintenanceRow(maintenanceTaskId: $maintenanceTaskId, rowInput: $rowInput) {
    ...PartialMaintenanceTask
  }
}
    ${PartialMaintenanceTaskFragmentDoc}`;
export type CustomAddMaintenanceRowMutationFn = Apollo.MutationFunction<CustomAddMaintenanceRowMutation, CustomAddMaintenanceRowMutationVariables>;

/**
 * __useCustomAddMaintenanceRowMutation__
 *
 * To run a mutation, you first call `useCustomAddMaintenanceRowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomAddMaintenanceRowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customAddMaintenanceRowMutation, { data, loading, error }] = useCustomAddMaintenanceRowMutation({
 *   variables: {
 *      maintenanceTaskId: // value for 'maintenanceTaskId'
 *      rowInput: // value for 'rowInput'
 *   },
 * });
 */
export function useCustomAddMaintenanceRowMutation(baseOptions?: Apollo.MutationHookOptions<CustomAddMaintenanceRowMutation, CustomAddMaintenanceRowMutationVariables>) {
        return Apollo.useMutation<CustomAddMaintenanceRowMutation, CustomAddMaintenanceRowMutationVariables>(CustomAddMaintenanceRowDocument, baseOptions);
      }
export type CustomAddMaintenanceRowMutationHookResult = ReturnType<typeof useCustomAddMaintenanceRowMutation>;
export type CustomAddMaintenanceRowMutationResult = Apollo.MutationResult<CustomAddMaintenanceRowMutation>;
export type CustomAddMaintenanceRowMutationOptions = Apollo.BaseMutationOptions<CustomAddMaintenanceRowMutation, CustomAddMaintenanceRowMutationVariables>;
export const CustomHandleMaintenanceRowsDocument = gql`
    mutation CustomHandleMaintenanceRows($maintenanceTaskId: ID!, $rowsInput: [UpdateMaintenanceRowsInput!]!) {
  handleMaintenanceTaskRows(
    maintenanceTaskId: $maintenanceTaskId
    rowsInput: $rowsInput
  ) {
    ...PartialMaintenanceTask
  }
}
    ${PartialMaintenanceTaskFragmentDoc}`;
export type CustomHandleMaintenanceRowsMutationFn = Apollo.MutationFunction<CustomHandleMaintenanceRowsMutation, CustomHandleMaintenanceRowsMutationVariables>;

/**
 * __useCustomHandleMaintenanceRowsMutation__
 *
 * To run a mutation, you first call `useCustomHandleMaintenanceRowsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomHandleMaintenanceRowsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customHandleMaintenanceRowsMutation, { data, loading, error }] = useCustomHandleMaintenanceRowsMutation({
 *   variables: {
 *      maintenanceTaskId: // value for 'maintenanceTaskId'
 *      rowsInput: // value for 'rowsInput'
 *   },
 * });
 */
export function useCustomHandleMaintenanceRowsMutation(baseOptions?: Apollo.MutationHookOptions<CustomHandleMaintenanceRowsMutation, CustomHandleMaintenanceRowsMutationVariables>) {
        return Apollo.useMutation<CustomHandleMaintenanceRowsMutation, CustomHandleMaintenanceRowsMutationVariables>(CustomHandleMaintenanceRowsDocument, baseOptions);
      }
export type CustomHandleMaintenanceRowsMutationHookResult = ReturnType<typeof useCustomHandleMaintenanceRowsMutation>;
export type CustomHandleMaintenanceRowsMutationResult = Apollo.MutationResult<CustomHandleMaintenanceRowsMutation>;
export type CustomHandleMaintenanceRowsMutationOptions = Apollo.BaseMutationOptions<CustomHandleMaintenanceRowsMutation, CustomHandleMaintenanceRowsMutationVariables>;