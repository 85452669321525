import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { of, EMPTY } from 'rxjs';
import { switchMap, delay } from 'rxjs/operators';

export enum FeedbackStatus {
  Success = 'success',
  Error = 'error'
}

interface Action {
  readonly type: string;
  payload?: any;
}

interface State {
  open: boolean;
  status?: FeedbackStatus | null;
  message?: string;
  extendedMessage?: string;
}

export interface feedBackRootState {
  feedback: State;
}

// Selectors
const getFeedback = ({ feedback }: feedBackRootState) => feedback;
export const hasFeedback = createSelector(
  [getFeedback],
  (feedback) => feedback
);

// Reducers
const initialState: State = {
  open: false
};

const feedbackSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    openFeedback: (
      state: State,
      action: PayloadAction<{
        status: FeedbackStatus;
        message: string;
        extendedMessage?: string;
      }>
    ) => {
      state.message = action.payload.message;
      state.extendedMessage = action.payload?.extendedMessage;
      state.status = action.payload.status;
      state.open = true;
    },
    closeFeedback: (state: State) => {
      state.open = false;
      state.status = null;
      state.message = '';
      state.extendedMessage = undefined;
    }
  }
});

export default feedbackSlice.reducer;

// Actions
export const { openFeedback, closeFeedback } = feedbackSlice.actions;

// Epics
const feedbackEpic: Epic<Action, Action> = (action$) => {
  return action$.pipe(
    ofType(openFeedback.type),
    switchMap(({ payload }) => {
      if (payload.extendedMessage) {
        return EMPTY;
      }
      return of({ type: closeFeedback.type }).pipe(delay(3000));
    })
  );
};

export const feedbackEpics = combineEpics(feedbackEpic);
