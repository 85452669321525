import React, { useCallback, useMemo } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import { Select } from 'components/ui/forms';
import { sortingOptions } from 'consts/sortingOptions';
import {
  setSorting,
  selectSortingVehicles,
  selectSortingAccessories
} from 'redux/webshopSorting';
import { WebshopSectionType } from 'routes/Webshop/sections';

import styles from './WebshopSorting.module.scss';

interface WebshopSortingProps {
  section: WebshopSectionType.VEHICLES | WebshopSectionType.ACCESSORIES;
}

const WebshopSorting = ({ section }: WebshopSortingProps) => {
  const dispatch = useDispatch();
  const sortingVehicles = useSelector(selectSortingVehicles);
  const sortingAccessories = useSelector(selectSortingAccessories);

  const defaultSorting = useMemo(() => {
    if (section === WebshopSectionType.VEHICLES) return sortingVehicles;
    if (section === WebshopSectionType.ACCESSORIES) return sortingAccessories;
  }, [section, sortingVehicles, sortingAccessories]);

  const onChange = useCallback(
    ({ target }) => {
      dispatch(setSorting({ type: section, sorting: target.value }));
    },
    [dispatch, section]
  );

  return (
    <div className={styles.root}>
      <div className={styles.text}>Sortera efter</div>
      <div className={styles.selectHolder}>
        <Select
          name="sorting"
          bordered
          defaultValue={defaultSorting}
          options={sortingOptions[section] || []}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default WebshopSorting;
