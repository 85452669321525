import React from 'react';

import { Tooltip } from 'components/ui/general';

import styles from './Cells.module.scss';

interface OperationProps {
  onClick: () => void;
  icon: string;
  tip?: string;
}

interface OperationButtonsCell {
  operations: OperationProps[];
}

export const OperationButtonsCell = ({ operations }: OperationButtonsCell) => {
  const renderOperationButton = (operation: OperationProps) => (
    <button
      key={operation.icon}
      type="button"
      onClick={operation.onClick}
      className={styles.circle}
    >
      <i className="material-icons-outlined">{operation.icon}</i>
    </button>
  );

  return (
    <div className={styles.operationContainer}>
      {operations.map((operation) =>
        operation.tip ? (
          <Tooltip trigger="hover" tip={operation.tip}>
            {renderOperationButton(operation)}
          </Tooltip>
        ) : (
          renderOperationButton(operation)
        )
      )}
    </div>
  );
};
