import React, { FC } from 'react';

import { useSelector } from 'react-redux';
import { Route, RouteProps, Redirect } from 'react-router-dom';

import { getLoggedIn, getUserRole } from 'redux/auth';
import routes from 'routes';
import { UserRole } from 'types';

interface Props extends RouteProps {
  allowedRoles?: Array<UserRole>;
}

const PrivateRoute: FC<Props> = ({ component, allowedRoles, ...rest }) => {
  const isLoggedIn = useSelector(getLoggedIn);
  const userRole = useSelector(getUserRole);

  if (
    allowedRoles?.length &&
    userRole &&
    allowedRoles.indexOf(userRole) === -1
  ) {
    return <Redirect to="/" />;
  }

  if (!isLoggedIn) {
    return <Redirect to="/" />;
  }
  const finalComponent = isLoggedIn ? component : routes.Login;

  return <Route {...rest} component={finalComponent} />;
};

export default PrivateRoute;
