import { isVolumeDiscount } from '../typeGuards';
import { productResolvers } from './productResolvers';
import { DiscountTypes } from 'consts/productRules';
import { Item } from 'routes/Admin/components/ProductReferenceSearch/ProductReferenceSearch';
import {
  ProductIdentifier,
  ProductReference,
  ProductRule,
  VolumeDiscount
} from 'types';
import { toSEK } from 'utils';

const getItem = (volumeDiscount?: VolumeDiscount) => {
  if (!volumeDiscount) return;

  switch (volumeDiscount.level) {
    case ProductIdentifier.StockVehicle: {
      const vehicle = volumeDiscount.mainProductIdentifier.stockVehicle;
      if (!vehicle) return;
      return {
        id: vehicle.id,
        title:
          vehicle.name ||
          vehicle.modelSeries?.name ||
          vehicle.model?.name ||
          vehicle.articleNumber,
        level: volumeDiscount.level,
        thumbnail: vehicle.thumbnail,
        articleNumber: vehicle.articleNumber
      };
    }
    case ProductIdentifier.Equipment: {
      const equipment = volumeDiscount.mainProductIdentifier.equipment;
      if (!equipment) return;
      return {
        id: equipment.id,
        title: equipment.name ?? equipment.articleNumber,
        level: volumeDiscount.level,
        thumbnail: equipment.thumbnail,
        articleNumber: equipment.articleNumber
      };
    }
    case ProductIdentifier.SparePart: {
      const sparePart = volumeDiscount.mainProductIdentifier.sparePart;
      if (!sparePart) return;
      return {
        id: sparePart.id,
        title: sparePart.name ?? sparePart.articleNumber,
        level: volumeDiscount.level,
        articleNumber: sparePart.articleNumber
      };
    }
    case ProductIdentifier.BillOfMaterial: {
      const billOfMaterial =
        volumeDiscount.mainProductIdentifier.billOfMaterial;
      if (!billOfMaterial) return;
      return {
        id: billOfMaterial.id,
        title: billOfMaterial.articleNumber ?? billOfMaterial.description,
        thumbnail: billOfMaterial.thumbnail,
        level: volumeDiscount.level,
        articleNumber: billOfMaterial.articleNumber
      };
    }
    case ProductIdentifier.Make: {
      const make = volumeDiscount.mainProductIdentifier.make;
      if (!make) return;
      return {
        id: make.id,
        title: make.name,
        level: volumeDiscount.level
      };
    }
    case ProductIdentifier.ModelSeries: {
      const modelSeries = volumeDiscount.mainProductIdentifier.modelSeries;
      if (!modelSeries) return;
      return {
        id: modelSeries.id,
        title: modelSeries.name ?? modelSeries.id,
        level: volumeDiscount.level
      };
    }
    case ProductIdentifier.Model: {
      const model = volumeDiscount.mainProductIdentifier.model;
      if (!model) return;
      return {
        id: model.id,
        title: model.name ?? model.id,
        level: volumeDiscount.level
      };
    }
    case ProductIdentifier.VehicleType: {
      const vehicleType = volumeDiscount.mainProductIdentifier.vehicleType;
      if (!vehicleType) return;
      return {
        id: vehicleType,
        title: vehicleType,
        vehicleType,
        level: volumeDiscount.level
      };
    }
    default:
  }
};

const getName = ({
  stockVehicle,
  equipment,
  sparePart,
  billOfMaterial,
  make,
  modelSeries,
  model,
  vehicleType
}: ProductReference) => {
  if (stockVehicle) return productResolvers.getName(stockVehicle);

  if (equipment) return productResolvers.getName(equipment);

  if (sparePart) return productResolvers.getName(sparePart);

  if (billOfMaterial) return productResolvers.getName(billOfMaterial);

  if (make) {
    return make.name;
  }

  if (modelSeries) {
    return modelSeries.name;
  }

  if (model) {
    return model.name;
  }

  if (vehicleType) {
    return vehicleType;
  }

  return undefined;
};

const getLevel = (productReference?: ProductReference) => {
  if (productReference?.stockVehicle) return 'Fordon';

  if (productReference?.equipment) return 'Tillbehör';

  if (productReference?.sparePart) return 'Reservdel';

  if (productReference?.billOfMaterial) return 'Paket';

  if (productReference?.make) {
    return 'Varumärke';
  }

  if (productReference?.modelSeries) {
    return 'Modelserie';
  }

  if (productReference?.model) {
    return 'Modell';
  }

  if (productReference?.vehicleType) {
    return 'Fordonstyp';
  }

  return undefined;
};

const getDiscountType = (item: VolumeDiscount | ProductRule) => {
  if (isVolumeDiscount(item) && item.discountOne) {
    return DiscountTypes.DISCOUNT_ONE;
  }
  if (item.discountTwo) {
    return DiscountTypes.DISCOUNT_TWO;
  }
  if (item.discountThree) {
    return DiscountTypes.DISCOUNT_THREE;
  }
};

const getDiscountAmount = (discount: number, discountType?: string) => {
  let discountThree;
  let discountTwo;
  let discountOne;

  if (discountType === DiscountTypes.DISCOUNT_ONE) {
    discountOne = (discount * 100) / 100;
  } else if (discountType === DiscountTypes.DISCOUNT_TWO) {
    discountTwo = (discount * 100) / 100;
  } else if (discountType === DiscountTypes.DISCOUNT_THREE) {
    discountThree = (discount * 100) / 100;
  }

  return { discountOne, discountTwo, discountThree };
};

const getDiscountString = (discount: VolumeDiscount) => {
  if (discount.discountOne) {
    return `${discount.discountOne}%`;
  }

  if (discount.discountTwo) {
    return `${discount.discountTwo}%`;
  }

  if (discount.discountThree) {
    return toSEK(discount.discountThree, { maximumFractionDigits: 0 });
  }
};

const getProductIdFromItem = (level: ProductIdentifier, reference: Item) => {
  switch (level) {
    case ProductIdentifier.StockVehicle:
      return { stockVehicleId: reference.id };
    case ProductIdentifier.Equipment:
      return { equipmentId: reference.id };
    case ProductIdentifier.SparePart:
      return { sparePartId: reference.id };
    case ProductIdentifier.BillOfMaterial:
      return { billOfMaterialId: reference.id };
    case ProductIdentifier.Make:
      return { makeId: reference.id };
    case ProductIdentifier.ModelSeries:
      return { modelSeriesId: reference.id };
    case ProductIdentifier.Model:
      return { modelId: reference.id };
    case ProductIdentifier.VehicleType:
      return { vehicleType: reference.vehicleType };
    default:
  }
};

export const adminResolvers = {
  ...{
    getItem,
    getName,
    getLevel,
    getDiscountType,
    getDiscountString,
    getDiscountAmount,
    getProductIdFromItem
  }
};
