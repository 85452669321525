import { InvoiceType } from '../types';

export const invoiceTypeToText = (type: InvoiceType) => {
  switch (type) {
    case InvoiceType.ProForma:
      return 'Proforma';
    case InvoiceType.CreditNote:
      return 'Kreditnota';
    case InvoiceType.Invoice:
      return 'Faktura';
    case InvoiceType.CreditNoteWarranty:
      return 'Kreditnota garanti';
    case InvoiceType.InterestInvoice:
      return 'Räntefaktura';
    default:
    case InvoiceType.NotSetInSage:
      return 'Obestämd';
  }
};
