export enum WebshopSectionType {
  ACCESSORIES = 'accessories',
  SPARE_PARTS = 'spare-parts',
  VEHICLES = 'vehicles',
  CART = 'cart',
  CHECKOUT = 'checkout',
  CHECKOUT_CONFIRM = 'checkout_confirm',
  SEARCH = 'search',
  BILL_OF_MATERIAL = 'bill-of-material',
  MY_ORDERS = 'my-orders',
  SHOPPING_LISTS = 'my-shopping-lists'
}

export const webshopSectionTypeToText = (type: WebshopSectionType): string => {
  switch (type) {
    case WebshopSectionType.ACCESSORIES:
      return 'Tillbehör';
    case WebshopSectionType.SPARE_PARTS:
      return 'Reservdelar';
    case WebshopSectionType.VEHICLES:
      return 'Fordon';
    case WebshopSectionType.CART:
      return 'Varukorg';
    case WebshopSectionType.CHECKOUT:
      return 'Checka ut';
    case WebshopSectionType.CHECKOUT_CONFIRM:
      return 'Orderbekräftelse';
    case WebshopSectionType.SEARCH:
      return 'Sök';
    case WebshopSectionType.BILL_OF_MATERIAL:
      return 'Paket';
    case WebshopSectionType.MY_ORDERS:
      return 'Mina ordrar';
    case WebshopSectionType.SHOPPING_LISTS:
      return 'Inköpslistor';
    default:
  }
  return '';
};

export const webshopSectionTypeToIdentifier = (type: WebshopSectionType) => {
  switch (type) {
    case WebshopSectionType.ACCESSORIES:
      return 'articleSearch';
    default:
      return 'stockVehicles';
  }
};
