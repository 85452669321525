import React, { FC } from 'react';

import classNames from 'classnames';

import {
  DropZoneExcelFile,
  DropZoneFiles
} from 'components/ui/forms/dropZones';
import { Button } from 'components/ui/general';
import { DealyModal, DealyModalAnchor } from 'components/ui/modals';

import styles from './UploadModal.module.scss';

export enum UploadModalType {
  EXCEL = 'uploadmodal/excel',
  FILES = 'uploadmodal/files',
  EXPLODED_VIEW_IMAGES = 'uploadmodal/explodedView',
  IMAGES = 'uploadmodal/images'
}

type UploadModalProps = {
  closeModal: Function;
  modalIsOpen: boolean;
  refetch: Function;
  type?: UploadModalType;
  submitHandler?: { handler: Function; loader: boolean };
  multiple?: boolean;
  description?: string;
};

const UploadModal: FC<UploadModalProps> = ({
  closeModal,
  modalIsOpen,
  refetch,
  type = UploadModalType.EXPLODED_VIEW_IMAGES,
  submitHandler,
  multiple = true,
  description
}) => {
  const getTitle = (): string => {
    switch (type) {
      case UploadModalType.EXPLODED_VIEW_IMAGES:
        return 'Ny sprängskiss (notera att flera filer kan väljas samtidigt)';
      case UploadModalType.EXCEL:
        return 'Ladda upp Excelfil';
      case UploadModalType.IMAGES:
        return 'Ladda upp bilder';
      case UploadModalType.FILES:
        return 'Ladda upp filer';
      default:
        return 'Ladda upp filer';
    }
  };
  return (
    <DealyModal
      className={classNames(styles.modal, styles.uploadModal)}
      anchor={DealyModalAnchor.CENTER}
      isOpen={modalIsOpen}
      onRequestClose={() => closeModal()}
    >
      <div>
        <div className={classNames(styles.row, styles.titleRow)}>
          <h4>{getTitle()}</h4>
          {description && <p>{description}</p>}
          <Button
            color="clear"
            className={styles.closeButton}
            onClick={() => closeModal()}
          >
            <i className="material-icons">close</i>
          </Button>
        </div>
        {type === UploadModalType.EXCEL ? (
          <DropZoneExcelFile
            closeModal={closeModal}
            refetch={refetch}
            submitHandler={submitHandler}
          />
        ) : (
          <DropZoneFiles
            closeModal={closeModal}
            refetch={refetch}
            uploadType={type}
            submitHandler={submitHandler}
            multiple={multiple}
          />
        )}
      </div>
    </DealyModal>
  );
};

export default UploadModal;
