import React, { FC } from 'react';

import classNames from 'classnames';

import { useTabs } from '../Tabs';

import styles from './Tab.module.scss';

export interface TabProps {
  id: string;
  label: string;
  small?: boolean;
}

export const Tab: FC<TabProps> = ({ id, label, small = true }) => {
  const { activeTab, setActiveTab } = useTabs();

  return (
    <button
      type="button"
      className={classNames(styles.root, {
        [styles.small]: small,
        [styles.selected]: activeTab === id
      })}
      onClick={() => setActiveTab(id)}
    >
      <span>{label}</span>
    </button>
  );
};
