import React from 'react';

import { CartItem } from 'redux/cart';
import { StockVehicle } from 'types';
import { productResolvers } from 'utils';

import styles from '../OrderBox.module.scss';

interface NotInStockDisclaimerProps {
  itemsNotInStock: Array<CartItem<StockVehicle>>;
}

export const NotInStockDisclaimer = ({
  itemsNotInStock
}: NotInStockDisclaimerProps) => {
  return (
    <div className={styles.notInStockDisclaimer}>
      <p>
        Varukorgen innehåller fler fordon än tillgängligt lagersaldo. Minska
        antalet tills det går att trycka på fortsätt
      </p>
      <ul>
        {itemsNotInStock.map((cartItem: CartItem<StockVehicle>) => (
          <li>
            <span>{productResolvers.getName(cartItem.item)}</span>
            <br />
            Beställningsbara:{' '}
            {productResolvers.totalStock(cartItem.item, cartItem.item.stock)}
            <br />I varukorg: {cartItem.quantity}
          </li>
        ))}
      </ul>
    </div>
  );
};
