import React from 'react';

import UploadModal, { UploadModalType } from 'components/modals/UploadModal';
import { useFeedback } from 'hooks';
import { PartialMaintenanceTask } from 'routes/MaintenanceTask/MaintenanceTask';
import { useUploadWorkshopAttachementMutation, Warranty } from 'types/graphql';
import { translateUploadAttachementError } from 'utils/translations';
import { isMaintenanceTask } from 'utils/typeGuards';

interface UploadWorkshopAttachementModalProps {
  isOpen: boolean;
  closeModal: () => void;
  task: PartialMaintenanceTask | Warranty;
}

interface FileItem {
  file: File;
  name: string;
}

export const UploadWorkshopAttachementModal = ({
  isOpen,
  closeModal,
  task
}: UploadWorkshopAttachementModalProps) => {
  const [
    UploadAttachement,
    { data, loading, error }
  ] = useUploadWorkshopAttachementMutation({
    refetchQueries: ['MaintenanceTaskView', 'CustomWarrantyById']
  });

  useFeedback(
    !!data?.uploadWorkshopAttachement,
    error ?? false,
    'Bilaga uppladdad',
    'Kunde inte ladda upp bilagan',
    undefined,
    translateUploadAttachementError
  );

  const submitUploadAttachementExcel = {
    handler: async (file: FileItem[]) => {
      await UploadAttachement({
        variables: {
          fileInput: {
            uploadFile: file[0].file
          },
          maintenanceTaskId: isMaintenanceTask(task) ? task.id : null,
          warrantyId: !isMaintenanceTask(task) ? task.id : null
        }
      });
    },
    loader: loading
  };

  return (
    <UploadModal
      modalIsOpen={isOpen}
      closeModal={closeModal}
      multiple={false}
      refetch={() => {
        closeModal();
      }}
      type={UploadModalType.FILES}
      submitHandler={submitUploadAttachementExcel}
    />
  );
};
