import React, { ReactNode, ReactType } from 'react';

import classNames from 'classnames';

import { Link } from 'components/tools';

import styles from './Button.module.scss';

interface ButtonProps<T> {
  id?: string;
  children: ReactNode;
  type?: 'submit' | 'reset' | 'button';
  href?: string;
  size?: 'sm' | 'md' | 'lg';
  className?: string;
  disabled?: boolean;
  onClick?: (event: Event) => void;
  ghost?: boolean;
  color?: string;
  target?: string;
  to?: string;
  fullWidth?: boolean;
  as?: T;
  loading?: boolean;
  dataCy?: string;
}

const Button = <T extends ReactType>({
  id,
  children,
  type = 'button',
  href,
  size = 'md',
  className,
  disabled,
  onClick,
  ghost,
  color = 'primary',
  target,
  to,
  fullWidth,
  as,
  loading = false,
  dataCy = ''
}: OverwritableType<ButtonProps<T>, T>) => {
  let ElementType: ReactType = as || 'button';
  if (to || href) ElementType = Link;

  return (
    <ElementType
      data-cy={dataCy}
      id={id}
      type={type}
      className={classNames(styles.root, className, {
        [styles.disabled]: disabled,
        [styles.ghost]: ghost,
        [styles[`${color}Color`]]: color,
        [styles[`${size}Size`]]: size,
        [styles.fullWidth]: fullWidth,
        [styles.loading]: loading
      })}
      to={to}
      href={href}
      disabled={disabled}
      onClick={onClick && !disabled ? onClick : null}
      target={target && href ? target : null}
    >
      <div className={styles.content}>{children}</div>
      <i className={styles.loader} />
    </ElementType>
  );
};

export default Button;
