import { ProductIdentifier } from 'types';

export const productIdentifierToText = (type: ProductIdentifier) => {
  switch (type) {
    case ProductIdentifier.StockVehicle:
      return 'Fordon';
    case ProductIdentifier.Equipment:
      return 'Tillbehör';
    case ProductIdentifier.SparePart:
      return 'Reservdel';
    case ProductIdentifier.BillOfMaterial:
      return 'Paket (bill of material)';
    case ProductIdentifier.Make:
      return 'Varumärke';
    case ProductIdentifier.ModelSeries:
      return 'Modellserie';
    case ProductIdentifier.Model:
      return 'Modell';
    case ProductIdentifier.VehicleType:
      return 'Fordonstyp';

    default:
      return 'Okänd';
  }
};
