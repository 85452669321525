import React, { ChangeEvent, FC, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';

import styles from './Stepper.module.scss';

interface StepperProps {
  className?: string;
  value: string | number | readonly string[] | undefined;
  onChange: (value: number) => void;
  onBlur?: () => void;
  minValue?: string | number;
  maxValue?: string | number;
  disabled?: boolean;
  inputClassName?: string;
}

const Stepper: FC<StepperProps> = ({
  className,
  value,
  onChange,
  onBlur,
  minValue,
  maxValue,
  disabled,
  inputClassName
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [currentValue, setCurrentValue] = useState<
    string | number | readonly string[] | undefined
  >(() => {
    const inc = value ? Number(value) : 0;
    return inc;
  });

  useEffect(() => {
    if (value !== currentValue) {
      setCurrentValue(value);
    }
  }, [currentValue, value, maxValue]);

  const setAndDispatch = (val: string | number) => {
    setCurrentValue(`${val}`);
    if (onChange) onChange(Number(val));
  };

  const onLocalChange = (e: ChangeEvent<HTMLInputElement>) => {
    // By default inputElement.target.value is empty string if not number
    if (e.target.value === '') return;
    const newValue = parseInt(e.target.value, 10);

    if (minValue && newValue < Number(minValue)) {
      setAndDispatch(Number(minValue));
      return;
    }

    if (maxValue && newValue > Number(maxValue)) {
      setAndDispatch(Number(maxValue));
      return;
    }

    setAndDispatch(newValue);
  };

  return (
    <div className={classNames(styles.stepper, className)} onBlur={onBlur}>
      <button
        data-cy="stepperRemove"
        type="button"
        disabled={
          (!!minValue && !!currentValue && currentValue <= minValue) || disabled
        }
        className={styles.minus}
        onClick={() => {
          const val = currentValue ? Math.max(0, Number(currentValue) - 1) : 0;
          setAndDispatch(val);
        }}
      >
        <i className="material-icons">remove</i>
      </button>
      <input
        disabled={disabled}
        ref={inputRef}
        value={currentValue}
        className={inputClassName}
        type="number"
        onChange={onLocalChange}
        min={minValue}
        max={maxValue}
      />
      <button
        data-cy="stepperAdd"
        type="button"
        className={styles.plus}
        disabled={
          (!!maxValue && !!currentValue && currentValue >= maxValue) || disabled
        }
        onClick={() => {
          const val = currentValue ? Number(currentValue) + 1 : 1;
          setAndDispatch(val);
        }}
      >
        <i className="material-icons">add</i>
      </button>
    </div>
  );
};

export default Stepper;
