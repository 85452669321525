import { Folder } from 'types';

const isBrandFolder = (folder: Folder) => {
  return !folder.parent;
};

const isModelFolder = (folder: Folder) => {
  if (!folder.parent) {
    return false;
  }

  return !folder.parent.parent;
};

const canBeDeactivated = (folder: Folder) => {
  if (!folder.active) {
    return false;
  }

  return isBrandFolder(folder) || isModelFolder(folder);
};

const canBeActivated = (folder: Folder) => {
  if (folder.active) {
    return false;
  }

  if (isBrandFolder(folder)) {
    return true;
  }

  if (isModelFolder(folder)) {
    return !folder.active && folder.parent!.active;
  }

  return false;
};

export const folderResolvers = {
  ...{
    isBrandFolder,
    isModelFolder,
    canBeDeactivated,
    canBeActivated
  }
};
