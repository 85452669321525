import React, { FC } from 'react';

import { UseFormMethods } from 'react-hook-form';
import { useSelector } from 'react-redux';

import InputTextBox from './components/InputTextBox';
import { PartialDelivery } from './WebshopCheckout';
import { Link } from 'components/tools';
import {
  Radio,
  RadioGroup,
  Text,
  TextArea,
  Checkbox
} from 'components/ui/forms';
import { CheckboxLabel } from 'components/ui/forms/Checkbox/Checkbox';
import { getActingAsRetailer, getUser } from 'redux/auth';
import { DeliveryType } from 'types';

import styles from './WebshopCheckout.module.scss';

const DHL_LINK =
  'https://www.dhl.com/se-sv/home/vara-divisioner/frakt/ledtider.html';

interface WebshopCheckoutEditProps {
  vehicleInCart: boolean;
  register: UseFormMethods['register'];
  errors: UseFormMethods['errors'];
  watch: UseFormMethods['watch'];
}

const WebshopCheckoutEdit: FC<WebshopCheckoutEditProps> = ({
  vehicleInCart,
  register,
  errors,
  watch
}) => {
  const actingAsRetailer = useSelector(getActingAsRetailer);
  const user = useSelector(getUser);

  const orderType = watch('deliveryType');

  const disclaimerPre12 = (
    <>
      Ordertypen ”DHL PRE12” innebär att DHL levererar senast kl 12:00. Denna
      tjänst är möjlig till många orter i Sverige, men tyvärr inte till alla.
      Kund ansvarar själv att kontrollera om DHL har denna tjänst mellan
      Västerås (Dealy´s lager med postnr 721 34) och sin egen leveransadress.{' '}
      <Link to={DHL_LINK} target="_blank">
        Det görs på DHL´s hemsida
      </Link>
      . Tilläggskostnad på 250kr exkl moms utöver expeditionsavgiften. Notera
      att ingen återbetalning av avgiften sker oavsett anledning (t ex
      förseningar vid kreditstopp, att DHL inte har den tjänsten på orten etc).
      PRE12-order som läggs före kl 12:00 expedieras och skickas från lagret
      samma dag.
    </>
  );

  const disclaimerExpress = `
      Ordertypen ”Flygimport” innebär att reservdel importeras via flyg. 
      Notera att speciella regler gäller, då inte alla reservdelar/tillbehör är lämpliga att flyga hem. 
      Tilläggskostnad på 950 kr exkl moms utöver expeditionsavgiften. Inkluderar ”DHL PRE12” där sådan är möjlig 
      (dock sker ingen prisreduktion om DHL PRE12 inte är möjlig). 
  `;

  const retailer = actingAsRetailer || user?.retailer;
  const showExternallyFinanced =
    vehicleInCart && retailer?.hasExternalFinancing;

  return (
    <div>
      <span className={styles.title__sub}>Ordertyp</span>
      <div className={styles.cardBox}>
        <RadioGroup required>
          <Radio
            key={DeliveryType.Standard}
            name="deliveryType"
            value={DeliveryType.Standard}
            label="Normal"
            register={register}
            validation={{ required: true }}
          />
          <Radio
            key={DeliveryType.Pre12}
            name="deliveryType"
            value={DeliveryType.Pre12}
            label="DHL PRE12"
            register={register}
            disabled={vehicleInCart}
            validation={{ required: true }}
          />
          <Radio
            key={DeliveryType.Express}
            name="deliveryType"
            value={DeliveryType.Express}
            label="Flygimport"
            register={register}
            disabled={vehicleInCart}
            validation={{ required: true }}
          />
          {vehicleInCart && (
            <span className={styles.disabledOptionText}>
              DHL PRE12 eller flyg ej möjligt med fordon i varukorgen
            </span>
          )}
          {errors.orderType && (
            <span className={styles.errorMessage}>
              Var vänlig och välj en orderTyp
            </span>
          )}
        </RadioGroup>
        {orderType === DeliveryType.Express && <div>{disclaimerExpress}</div>}
        {orderType === DeliveryType.Pre12 && <div>{disclaimerPre12}</div>}
      </div>

      <span className={styles.title__sub}>Adress</span>
      <div className={styles.cardBox}>
        <div className={styles.flexContainer__column}>
          <InputTextBox register={register} errors={errors} />
        </div>
      </div>

      <span className={styles.title__sub}>Delleverans</span>
      <div className={styles.cardBox}>
        <RadioGroup required>
          <Radio
            key="Jag vill ha fullständig order"
            name="partialDelivery"
            value={PartialDelivery.FULL_ORDER_ONLY}
            label="Jag vill ha fullständig order"
            register={register}
            disabled={vehicleInCart}
            validation={{
              required: true
            }}
          />
          {vehicleInCart && (
            <span className={styles.disabledOptionText}>
              Fullständig order ej möjligt med fordon i varukorgen
            </span>
          )}
          <Radio
            key="Jag är okej med delleverans"
            name="partialDelivery"
            value={PartialDelivery.PARTIAL_OK}
            label="Jag är okej med delleverans"
            register={register}
            validation={{
              required: true
            }}
          />
          {errors.partialDelivery && (
            <span className={styles.errorMessage}>
              Var vänlig och välj en Delleverans
            </span>
          )}
        </RadioGroup>
      </div>
      {showExternallyFinanced && (
        <>
          <span className={styles.title__sub}>Handlarfinans</span>
          <div
            className={`${styles.cardBox__small} ${styles.externallyFinanced}`}
          >
            <Checkbox
              id="externallyFinanced"
              name="externallyFinanced"
              register={register}
              labelPos={CheckboxLabel.RIGHT}
              label="Jag vill finansiera denna fordonsorder genom Handlarfinans"
            />
          </div>
        </>
      )}

      <span className={styles.title__sub}>Orderref</span>
      <div className={styles.cardBox}>
        <Text
          name="orderRemark"
          maxLength={20}
          className={styles.textField}
          register={register}
          error={errors.orderRemark}
          type="text"
          placeholder="Skriv orderref här..."
          validation={{ required: false }}
        />
        <span>Max 20 tecken.</span>
      </div>
      {actingAsRetailer && (
        <>
          <span className={styles.title__sub}>Intern meddelande</span>
          <div className={styles.cardBox}>
            <TextArea
              name="dealyInternalComment"
              maxLength={200}
              className={styles.returnModalTextArea}
              register={register}
              error={errors.dealyInternalComment}
              type="text"
              placeholder="Skriv intern kommentar här..."
              validation={{ required: false }}
            />
            <span>Max 200 tecken.</span>
          </div>
        </>
      )}
    </div>
  );
};

export default WebshopCheckoutEdit;
