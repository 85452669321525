import React from 'react';

import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import SelectRetailerToActAs from './SelectRetailerToActAs';
import { Button } from 'components/ui/general';
import { DealyModal, DealyModalAnchor } from 'components/ui/modals';
import {
  actAsRetailerMenuOpen,
  closeActAsRetailerMenu
} from 'redux/actAsRetailerMenu';
import { getActingAsRetailer } from 'redux/auth';

import styles from './ActAsRetailerModal.module.scss';

const ActAsRetailerModal = () => {
  const modalIsOpen = useSelector(actAsRetailerMenuOpen);
  const dispatch = useDispatch();

  const currentRetailer = useSelector(getActingAsRetailer);

  return (
    <DealyModal
      anchor={DealyModalAnchor.RIGHT}
      isOpen={modalIsOpen}
      onRequestClose={() => dispatch(closeActAsRetailerMenu())}
    >
      <div>
        <div className={classNames(styles.row, styles.titleRow)}>
          <h4>Välj återförsäljare</h4>
          <Button
            color="clear"
            className={styles.closeButton}
            onClick={() => dispatch(closeActAsRetailerMenu())}
          >
            <i className="material-icons">close</i>
          </Button>
        </div>
      </div>
      <SelectRetailerToActAs
        closeModal={() => dispatch(closeActAsRetailerMenu())}
        currentRetailerId={currentRetailer ? currentRetailer.id : undefined}
      />
    </DealyModal>
  );
};

export default ActAsRetailerModal;
