import { defaultOption } from 'consts/defaults';
import { Make, ModelSeries } from 'types/graphql';

type Filter =
  | Pick<Make, 'id' | 'externalId' | 'name'>[]
  | Pick<ModelSeries, 'id' | 'name'>[];

export const vehicleFilterToOptions = (
  filter: Filter | undefined
): Array<{ value: any; label: string }> => {
  if (!filter) return [defaultOption];

  const options = (filter as any).map((filterItem: any) => ({
    value: filterItem.id,
    label: filterItem.name
  }));

  return [defaultOption, ...options];
};
