import { createSelector, createSlice } from '@reduxjs/toolkit';
import { combineEpics, Epic, ofType } from 'redux-observable';
import { mapTo } from 'rxjs/operators';

import { isStockVehicle } from '../utils/typeGuards';
import { logoutSuccess } from './auth';
import {
  StockVehicle,
  CartItem as UpdatedCartItem,
  CartPrice,
  VolumeDiscount,
  OrderItemConnection,
  ReplacedProduct,
  Article
} from 'types';
import { separateVehicles, productResolvers } from 'utils';

interface Action {
  readonly type: string;
  payload?: any;
}

export type CartItem<T = Article> = {
  id: string;
  item: T;
  quantity: number;
  note: any;
  prices?: CartPrice;
  replacedItem?: ReplacedProduct;
  relatedVehicleId?: StockVehicle['id'];
  stockVehicleProductCount?: number;
  isCampaignEquipment?: boolean;
  volumeDiscount?: VolumeDiscount;
  productRuleItems?: UpdatedCartItem[];
};

type CartItemIdentifier = {
  article?: Article;
  relatedVehicleId?: string;
};

export type RelatedVehicle = {
  relatedVehicleId: StockVehicle['id'];
  stockVehicleProductCount: number;
};

export interface CartItemWithKey extends CartItem {
  internalIndex: number;
  note: string;
}

interface State {
  items: Array<CartItem>;
}

export interface CartRootState {
  cart: State;
}

interface ItemInterface {
  sp: Article;
  quantity: number;
  relatedVehicleId?: StockVehicle['id'];
  stockVehicleProductCount?: number;
  isCampaignEquipment?: boolean;
}

const createCartItemId = (itemId: string, relatedVehicleId?: string) => {
  if (!relatedVehicleId) return itemId.toString();
  return `${itemId.toString()}#${relatedVehicleId}`;
};

// Selectors
const getCartState = ({ cart }: CartRootState) => cart;

export const getCurrentNumberOfItems = createSelector([getCartState], (cart) =>
  cart.items.reduce((carry, cartItem) => {
    let itemCount = cartItem.quantity;
    if (cartItem.productRuleItems) {
      itemCount += cartItem.productRuleItems.length * cartItem.quantity;
    }
    return carry + itemCount;
  }, 0)
);

export const getCartHasItems = createSelector(
  [getCartState],
  (cart) => !!cart.items.length
);

export const getItems = createSelector([getCartState], (cart) => cart.items);

export const getFlattenedItems = createSelector([getCartState], (cart) =>
  separateVehicles(cart.items)
);

export const getPrice = createSelector([getCartState], (cart) =>
  cart.items.reduce(function (ac, cu) {
    let price = cu.item.price;
    if (cu.item.discountedPrice || cu.item.discountedPrice === 0) {
      price = cu.item.discountedPrice;
    }

    return ac + price * cu.quantity;
  }, 0)
);

export const getRetailerTotalPrice = createSelector([getCartState], (cart) => {
  if (!cart.items.every((item: any) => 'prices' in item)) {
    return 0;
  }

  return cart.items.reduce((carry, cartItem) => {
    const { discountedPrice, retailerPrice } = cartItem.prices as CartPrice;
    const lowestPrice =
      discountedPrice !== null ? discountedPrice : retailerPrice;
    let itemSum = (lowestPrice ?? 0) * cartItem.quantity;

    cartItem.productRuleItems?.forEach((productRuleItem) => {
      const prices = productRuleItem?.price;
      const nestedLowestPrice =
        prices.discountedPrice !== null
          ? prices.discountedPrice
          : prices.retailerPrice;
      itemSum += (nestedLowestPrice || 0) * cartItem.quantity;
    });
    return carry + itemSum;
  }, 0);
});

export const getVat = createSelector([getCartState], () => 0.25);
export const getSpecificItemCount = ({
  article,
  relatedVehicleId
}: CartItemIdentifier) => {
  return createSelector([getCartState], (cart) => {
    if (!article) return 0;
    const cartItemId = createCartItemId(article.id, relatedVehicleId);
    const foundItem = cart.items.find((item) => item.id === cartItemId);
    return foundItem?.quantity || 0;
  });
};

export const isVehicleInCart = createSelector([getCartState], (cart) => {
  const found = cart.items.filter(({ item }) => isStockVehicle(item));
  return found.length > 0;
});

// Reducers
const initialState: State = {
  items: []
};

const updateCampaignItemAfterStockVehicle = (
  item: CartItem,
  sp: Article,
  quantity: number
) => {
  // We have a relatedEquipment. Update the productCounts
  if (item.relatedVehicleId === sp.id && item.isCampaignEquipment) {
    if (quantity < item.quantity) {
      item.quantity = quantity;
    }

    item.stockVehicleProductCount = quantity;
  }
};

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addItem: (state: State, action) => {
      const {
        sp,
        quantity,
        relatedVehicleId,
        stockVehicleProductCount,
        isCampaignEquipment
      }: ItemInterface = action.payload;
      let found = false;
      const cartItemId = createCartItemId(sp.id, relatedVehicleId);

      state.items.forEach((storedItem) => {
        // found the correct item
        if (storedItem.id === cartItemId) {
          storedItem.quantity = quantity;
          if (isStockVehicle(sp) && quantity > storedItem.note.length) {
            storedItem.note.push(
              ...Array(quantity - storedItem.note.length).fill('')
            );
          }

          storedItem.stockVehicleProductCount = stockVehicleProductCount;
          storedItem.isCampaignEquipment = isCampaignEquipment;
          found = true;
        }

        updateCampaignItemAfterStockVehicle(storedItem, sp, quantity);
      });

      if (!found) {
        state.items.push({
          id: cartItemId,
          item: sp,
          note: isStockVehicle(sp) ? [''] : '',
          quantity,
          relatedVehicleId,
          stockVehicleProductCount,
          isCampaignEquipment
        });
      }
    },
    addItemNote: (state: State, action) => {
      const { cartItem, note } = action.payload;

      if (isStockVehicle(cartItem.item)) {
        const storedItem = state.items.find(
          (item) => item.item.id === cartItem.item.id
        );
        if (storedItem) {
          const newNotes = [...storedItem.note];
          if (note === null) {
            newNotes.splice(cartItem.internalIndex, 1);
          } else {
            newNotes[cartItem.internalIndex] = note;
          }
          storedItem.note = newNotes;
        }
        return;
      }

      state.items.forEach((storedItem, index) => {
        if (storedItem.id === cartItem.id) {
          state.items[index].note = note;
        }
      });
    },
    removeItem: (state: State, action) => {
      const { article, relatedVehicleId, quantity } = action.payload;
      const cartItemId = createCartItemId(article.id, relatedVehicleId);

      if (quantity <= 0) {
        // Remove related cartItems
        state.items = state.items.filter(
          (item) => item.relatedVehicleId !== article.id
        );

        state.items = state.items.filter((item) => item.id !== cartItemId);
        return;
      }

      const storedItem = state.items.find((item) => item.id === cartItemId);
      if (!storedItem) return;
      storedItem.quantity = quantity;
      updateCampaignItemAfterStockVehicle(
        storedItem,
        storedItem.item,
        quantity
      );
    },
    clearCart: (state: State) => {
      state.items = initialState.items;
    },
    updateCart: (state: State, { payload }) => {
      const updatedItems: UpdatedCartItem[] =
        payload.updateCartWithPrices.products;
      const storedItems: CartItem[] = state.items;

      updatedItems.forEach((updatedItem) => {
        const article = productResolvers.getArticle(updatedItem);
        if (!article) return;

        const updatedItemId = createCartItemId(
          article.id.toString(),
          updatedItem?.campaignOnVehicleId || undefined
        );

        const storedItem = storedItems.find(
          (item) => item.id === updatedItemId
        );

        if (storedItem) {
          storedItem.item = article;
          storedItem.quantity = updatedItem.quantity;
          storedItem.prices = updatedItem.price;
          storedItem.volumeDiscount = updatedItem.volumeDiscount || undefined;
          storedItem.productRuleItems =
            updatedItem.productRuleItems || undefined;
          return;
        }

        const newItem = {
          id: updatedItemId,
          item: article,
          quantity: updatedItem.quantity,
          prices: updatedItem.price,
          note: isStockVehicle(article) ? [''] : '',
          volumeDiscount: updatedItem.volumeDiscount || undefined,
          productRuleItems: updatedItem.productRuleItems || undefined,
          isCampaignEquipment:
            updatedItem.connectionType ===
            OrderItemConnection.CampaignVehicleAccessory,
          relatedVehicleId: updatedItem.campaignOnVehicleId || undefined,
          replacedItem: updatedItem.replacedProduct || undefined
        };
        state.items.push(newItem);
      });

      // Remove cart items if they have been fully replaced with other items
      const replacedItems = updatedItems
        .map((updatedItem) => updatedItem.replacedProduct)
        .filter((replacedProduct) => !!replacedProduct);

      replacedItems.forEach((replacedItem) => {
        const isPartiallyReplaced = updatedItems.some(
          (item) => productResolvers.getArticle(item)?.id === replacedItem?.id
        );
        if (isPartiallyReplaced) return; // A partially replaced item receives a new quantity

        state.items = state.items.filter(
          (item) => item.item?.id !== replacedItem?.id
        );
      });
    }
  }
});

export default cartSlice.reducer;

// Actions
export const {
  addItem,
  removeItem,
  addItemNote,
  clearCart,
  updateCart
} = cartSlice.actions;

// epics
const clearCartEpic: Epic<Action, Action> = (action$) =>
  action$.pipe(ofType(logoutSuccess.type), mapTo(clearCart()));

export const cartEpics = combineEpics(clearCartEpic);
