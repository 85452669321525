export const disableAddRelatedEquipment = (
  stockVehicleIsSelected: boolean | undefined,
  stockVehicleProductCount: number | undefined,
  quantity: number | undefined,
  isCampaignEquipment: boolean | undefined
): boolean => {
  if (isCampaignEquipment === undefined || !isCampaignEquipment) {
    return false;
  }

  if (stockVehicleIsSelected === undefined) {
    return false;
  }

  if (!stockVehicleIsSelected) {
    return true;
  }

  if (!stockVehicleProductCount) {
    return true;
  }

  if (!quantity) {
    return false;
  }

  return stockVehicleProductCount <= quantity;
};
