import React, { FC, useEffect, useRef } from 'react';

import classNames from 'classnames';
import { FieldErrors, UseFormMethods, ValidationRules } from 'react-hook-form';

import { Error } from '../index';
import { Button } from 'components/ui/general';

import styles from './ImageUpload.module.scss';

interface Props {
  register: UseFormMethods['register'];
  name: string;
  setValue: Function;
  label?: string;
  required?: boolean;
  validation?: ValidationRules;
  watch: Function;
  clearErrors?: UseFormMethods['clearErrors'];
  error?: FieldErrors;
  dataCy?: string;
}

const ImageUpload: FC<Props> = ({
  register,
  setValue,
  name,
  label,
  required = false,
  validation = {},
  watch,
  clearErrors,
  error,
  dataCy = ''
}) => {
  const isRequired = required || validation?.required;
  const fileRef = useRef<any>();

  useEffect(() => {
    register(name, validation);
  }, [name, register, validation]);

  const onImageUpload = () => {
    fileRef.current?.click();
    setValue('value');
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.files?.item(0)) {
      setValue(name, e.currentTarget.files.item?.(0));
      clearErrors?.(name);
    }
  };
  return (
    <>
      {label && (
        <label htmlFor={name}>
          {label}
          {isRequired && <span className={styles.required}>*</span>}
        </label>
      )}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
      <div className={classNames(styles.root, { [styles.error]: !!error })}>
        <Button
          ref={register({ name })}
          className={styles.singleUploadButton}
          onClick={onImageUpload}
        >
          <i className="material-icons-outlined">backup</i>
          <span>Välj fil…</span>
        </Button>
        <div className={styles.text}>{watch(name)?.name}</div>
        <input
          data-cy={dataCy}
          name={name}
          className={styles.hidden}
          ref={fileRef}
          type="file"
          id={name}
          onChange={onChange}
          accept="image/*"
        />
        <Error error={error} message={error?.message} />
      </div>
    </>
  );
};

export default ImageUpload;
