import { createSlice } from '@reduxjs/toolkit';

interface Action {
  readonly type: string;
  payload?: any;
}

interface State {
  open: boolean;
}

export interface ActAsRetailerMenuRootState {
  actAsRetailerMenu: State;
}

// Selectors
export const actAsRetailerMenuOpen = ({
  actAsRetailerMenu
}: ActAsRetailerMenuRootState) => actAsRetailerMenu.open;
// Reducers
const initialState: State = {
  open: false
};

const actAsRetailerMenuSlice = createSlice({
  name: 'actAsRetailerMenuSlice',
  initialState,
  reducers: {
    toggleActAsRetailerMenu: (state: State) => {
      state.open = !state.open;
    },
    openActAsRetailerMenu: (state: State) => {
      state.open = true;
    },
    closeActAsRetailerMenu: (state: State) => {
      state.open = false;
    }
  }
});

export default actAsRetailerMenuSlice.reducer;

// Actions
export const {
  toggleActAsRetailerMenu,
  openActAsRetailerMenu,
  closeActAsRetailerMenu
} = actAsRetailerMenuSlice.actions;
