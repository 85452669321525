import React, { FC } from 'react';

import { Helmet } from 'react-helmet';

import WebshopLandingSplash from './components/WebshopLandingSplash';
import AccessoriesImage from 'assets/images/webshop_splash_accessories.png';
import SparePartsImage from 'assets/images/webshop_splash_spare_parts.png';
import VehicleImage from 'assets/images/webshop_splash_vehicles.png';
import { Container } from 'components/ui/general';
import { Drawer } from 'components/ui/navigation';
import { WebshopLandingSelectors } from 'consts/cypress';
import { WebshopSectionType } from 'routes/Webshop/sections';

import styles from './WebshopLanding.module.scss';

const WebshopLanding: FC = () => {
  return (
    <>
      <Helmet>
        <title>Webshop landing page</title>
      </Helmet>
      <Drawer initial="closed" overlay />
      <section>
        <Container fullWidth className={styles.root}>
          <div className={styles.entrypoints}>
            <WebshopLandingSplash
              dataCy={WebshopLandingSelectors.VEHICLE_SPLASH}
              imageUrl={VehicleImage}
              type={WebshopSectionType.VEHICLES}
            />
            <WebshopLandingSplash
              dataCy={WebshopLandingSelectors.ACCESSORIES_SPLASH}
              imageUrl={AccessoriesImage}
              type={WebshopSectionType.ACCESSORIES}
              size="half"
            />
            <WebshopLandingSplash
              dataCy={WebshopLandingSelectors.SPARE_PARTS_SPLASH}
              imageUrl={SparePartsImage}
              type={WebshopSectionType.SPARE_PARTS}
              size="half"
            />
          </div>
        </Container>
      </section>
    </>
  );
};

export default WebshopLanding;
