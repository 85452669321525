import React, { Children, cloneElement, ReactNode } from 'react';

import classNames from 'classnames';

import { CheckboxLabel } from '../Checkbox/Checkbox';

import styles from './CheckboxGroup.module.scss';

interface CheckboxGroupProps {
  bordered?: boolean;
  checkboxLabelPos?: CheckboxLabel;
  readonly children: ReactNode;
  label?: string;
  required?: boolean;
}

const CheckboxGroup = ({
  bordered,
  checkboxLabelPos = CheckboxLabel.RIGHT,
  children,
  label,
  required
}: CheckboxGroupProps) => {
  return (
    <div
      className={classNames(styles.block, {
        [styles.bordered]: bordered,
        [styles.labeled]: label
      })}
    >
      {label && (
        <p className={styles.groupLabel}>
          {label}
          {required && <span className={styles.required}>*</span>}
        </p>
      )}
      {Children.toArray(children).map((child: any, index: number) =>
        cloneElement(child, {
          key: index,
          labelPos: checkboxLabelPos
        })
      )}
    </div>
  );
};

export default CheckboxGroup;
