import { useEffect } from 'react';

import { isEqual, omit } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { setLocation, setHistory, selectLocation } from 'redux/router';

const RouterListener = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const selectorLocation = useSelector(selectLocation);

  useEffect(() => {
    const locationChanged = !isEqual(
      omit(location, 'key'),
      omit(selectorLocation, 'key')
    );

    if (locationChanged) {
      dispatch(setLocation(location));
    }
  }, [dispatch, location, selectorLocation]);

  useEffect(() => {
    const { action, location: historyLocation } = history;
    dispatch(setHistory({ action, location: historyLocation }));
  }, [dispatch, history]);

  return null;
};

export default RouterListener;
