import React, { ReactNode } from 'react';

import {
  Link as RouterLink,
  NavLink as RouterNavLink,
  LinkProps as RouterLinkLink,
  NavLinkProps
} from 'react-router-dom';

interface LinkProps {
  children?: ReactNode;
  to?: string;
  activeClassName?: string;
  dataCy?: string;
  showIcon?: boolean;
}

const Link = ({
  children,
  activeClassName,
  to,
  dataCy = '',
  showIcon,
  ...props
}: LinkProps & RouterLinkLink & NavLinkProps) => {
  const internal = /^\/(?!\/)|^#/.test(to);

  if (internal) {
    if (activeClassName) {
      return (
        <RouterNavLink
          data-cy={dataCy}
          to={to}
          activeClassName={activeClassName}
          {...props}
        >
          {children}
        </RouterNavLink>
      );
    }

    return (
      <RouterLink data-cy={dataCy} to={to} {...props}>
        {children}
      </RouterLink>
    );
  }

  return (
    <a data-cy={dataCy} href={to} {...props}>
      {children}
      {showIcon && (
        <span>
          <i className="material-icons">open_in_new</i>
        </span>
      )}
    </a>
  );
};

export default Link;
