import React from 'react';

import classNames from 'classnames';

import { Button } from 'components/ui/general';
import { DealyModal, DealyModalAnchor } from 'components/ui/modals';

import styles from './ConfirmModal.module.scss';

interface ConfirmModalProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  callBack: Function | null;
  message: string;
  confirmText?: string;
}

export const ConfirmModal = ({
  modalIsOpen,
  closeModal,
  callBack,
  message,
  confirmText
}: ConfirmModalProps) => {
  return (
    <DealyModal
      className={classNames(styles.modal, styles.confirmModal)}
      anchor={DealyModalAnchor.CENTER}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
    >
      <div>
        <div className={classNames(styles.row, styles.titleRow)}>
          <h4>Bekräfta</h4>
          <Button
            color="clear"
            className={styles.closeButton}
            onClick={closeModal}
          >
            <i className="material-icons">close</i>
          </Button>
        </div>
        <div className={classNames(styles.row)}>{message}</div>
        <div className={classNames(styles.row, styles.endRow)}>
          <Button
            className={styles.button}
            color="epsilon"
            size="md"
            onClick={closeModal}
          >
            Avbryt
          </Button>
          <Button
            dataCy="confirmActionBtn"
            className={styles.button}
            color="alpha"
            type="submit"
            size="md"
            onClick={() => {
              callBack?.();
              closeModal();
            }}
          >
            {confirmText || 'Bekräfta'}
          </Button>
        </div>
      </div>
    </DealyModal>
  );
};
