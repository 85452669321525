import React, { ReactNode } from 'react';

import classNames from 'classnames';
import Modal from 'react-modal';

import { preventScroll } from 'utils';

import styles from './DealyModal.module.scss';

export enum DealyModalAnchor {
  BOTTOM = 'bottom',
  CENTER = 'center',
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top'
}

interface ModalProps {
  anchor?: DealyModalAnchor;
  children: ReactNode;
  className?: string;
  overlayClassName?: string;
  onRequestClose?: () => void;
  isOpen: boolean;
}

export const DealyModal = ({
  anchor = DealyModalAnchor.RIGHT,
  children,
  className,
  onRequestClose,
  isOpen,
  overlayClassName,
  ...rest
}: ModalProps) => {
  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      {...(onRequestClose && { onRequestClose })}
      closeTimeoutMS={400}
      contentLabel="Example Modal"
      portalClassName={styles.modal}
      className={classNames(
        styles.modalContent,
        {
          [styles[`${anchor}Anchor`]]: anchor
        },
        className
      )}
      overlayClassName={classNames(styles.overlay, overlayClassName)}
      onAfterOpen={() => {
        preventScroll(true);
      }}
      onAfterClose={() => {
        preventScroll(false);
      }}
      {...rest}
    >
      {children}
    </Modal>
  );
};
