import { OperationGroup } from 'types/graphql';

const getLevel = (operationGroup: OperationGroup) => {
  if (operationGroup.make) {
    return 'Varumärke';
  }

  if (operationGroup.modelSeries) {
    return 'Modellserie';
  }

  if (operationGroup.model) {
    return 'Modell';
  }

  return '';
};

export const operationCodeResolvers = {
  ...{ getLevel }
};
