import React from 'react';

import classNames from 'classnames';

import styles from './StatusBar.module.scss';

interface StatusBarProps {
  text: string;
  color: string;
}

const StatusBar = ({ text, color }: StatusBarProps) => {
  return (
    <div className={classNames(styles.banner, styles[color])}>
      <span>{text}</span>
    </div>
  );
};

export default StatusBar;
