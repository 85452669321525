import React, { useEffect, useState } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { Button, Tooltip } from 'components/ui/general';
import { getIsRetailerUser } from 'redux/auth';
import {
  WorkshopMessage,
  User,
  Retailer,
  useSetWorkshopMessageReadStateMutation
} from 'types';
import { formatDate } from 'utils';

import styles from './Message.module.scss';

type PartialMessage = Omit<WorkshopMessage, 'author'> & {
  author: {
    name?: User['name'];
    retailer?: Pick<Retailer, 'id'> | null;
  };
};

interface MessageProps {
  message: PartialMessage;
}

export const Message = ({ message }: MessageProps) => {
  const [hasAdjustedManually, setHasAdjustedManually] = useState(false);
  const [setReadState, { loading }] = useSetWorkshopMessageReadStateMutation();
  const isRecipient =
    useSelector(getIsRetailerUser) !== !!message.author.retailer;

  useEffect(() => {
    if (message.isRead || !isRecipient || hasAdjustedManually) return;

    const timeout = setTimeout(() => {
      setReadState({
        variables: {
          id: message.id,
          isRead: true
        }
      });
    }, 2000);

    return () => clearTimeout(timeout);
  }, [message, isRecipient, setReadState, hasAdjustedManually]);

  return (
    <div
      className={classNames(styles.message, {
        [styles.ownMessage]: message.author.retailer
      })}
    >
      <div className={styles.messageHeader}>
        <span>{message.author.name}</span>
        <span className={styles.messageDate}>
          {formatDate(message.createdAt)}
        </span>
        {isRecipient && (
          <Tooltip
            classNameTrigger={styles.readStateButton}
            tip={message.isRead ? 'Markera som oläst' : 'Markera som läst'}
            trigger="hover"
          >
            <Button
              color="clear"
              disabled={loading}
              onClick={() => {
                setHasAdjustedManually(true);
                setReadState({
                  variables: { id: message.id, isRead: !message.isRead }
                });
              }}
            >
              <i className="material-icons">
                {message.isRead ? 'drafts' : 'email'}
              </i>
            </Button>
          </Tooltip>
        )}
      </div>
      {message.text}
    </div>
  );
};
