import React, { useMemo } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { NotInStockDisclaimer } from './components';
import { Button } from 'components/ui/general';
import {
  getRetailerTotalPrice,
  getVat,
  getItems,
  CartItem as ReduxCartItem
} from 'redux/cart';
import { WebshopCheckoutSection } from 'routes/WebshopCheckout/WebshopCheckout';
import { CartItem, StockVehicle } from 'types';
import { toSEK } from 'utils';
import { isStockVehicleCartItem } from 'utils/typeGuards';

import styles from './OrderBox.module.scss';

interface OrderBoxProps {
  numberOfArticles: number;
  fees: Array<CartItem>;
  checkoutSection?: WebshopCheckoutSection;
  error?: boolean;
  loading?: boolean;
}

const OrderBox = ({
  numberOfArticles,
  fees,
  checkoutSection,
  error,
  loading
}: OrderBoxProps) => {
  const sumOfCart = useSelector(getRetailerTotalPrice);
  const vat = useSelector(getVat);
  const items = useSelector(getItems);

  const vehiclesNotInStock = useMemo(() => {
    const vehiclesExceedingStock: Array<ReduxCartItem<StockVehicle>> = [];
    items.forEach((item: ReduxCartItem) => {
      if (!isStockVehicleCartItem(item)) return;
      const { incoming, atRetailer, warehouse } = item.item.stock;
      const availableItems = incoming + atRetailer + warehouse;

      if (item.quantity > availableItems) {
        vehiclesExceedingStock.push(item);
      }
    });
    return vehiclesExceedingStock;
  }, [items]);

  const sumOfFees = fees.reduce((carry, { price }) => carry + price.price, 0);
  const totalPrice = sumOfCart + sumOfFees;
  const showShopClosedText = false;
  const shopClosed = false;

  return (
    <div className={styles.box}>
      <p className={styles.title}>Summering</p>
      <div className={styles.flexRow}>
        <p>{numberOfArticles} Artiklar</p>
        <p>{toSEK(sumOfCart)}</p>
      </div>
      {fees.map(({ fee, price }) => (
        <div className={styles.flexRow}>
          <span>{fee!.name}</span>
          <span>{toSEK(price.price)}</span>
        </div>
      ))}
      <div className={styles.totals}>
        <div className={styles.flexRow}>
          <p className={classNames(styles.price, styles.price__blue)}>
            Total netto (inkl. moms)
          </p>
          <p className={classNames(styles.price, styles.price__blue)}>
            {toSEK(totalPrice * (1 + vat))}
          </p>
        </div>
      </div>
      <div className={styles.flexRow}>
        <p className={styles.price}>Total netto (exkl. moms)</p>
        <p className={styles.price}>{toSEK(totalPrice)}</p>
      </div>
      {showShopClosedText && (
        <div>
          <p>Bäste samarbetspartner,<br/>
            Vi planerar för en inventering, vilket innebär att vi tillfälligt stänger våra system för orderläggning
            fr.o.m. 13 november.<br/>
            När inventeringen är klar så kommer systemet att öppnas upp igen.
          </p>
        </div>
      )}
      <Button
          dataCy="webshopContinueOrApproveOrderBtn"
          color="alpha"
          type="submit"
          className={styles.orderButton}
          loading={loading}
          disabled={shopClosed || loading || !!vehiclesNotInStock.length || error}
      >
        {checkoutSection === WebshopCheckoutSection.DISPLAY_SECTION
          ? 'Jag bekräftar ordern'
          : 'Fortsätt'}
      </Button>
      {!loading && !!vehiclesNotInStock.length && (
        <NotInStockDisclaimer itemsNotInStock={vehiclesNotInStock} />
      )}
    </div>
  );
};

export default OrderBox;
