import gql from 'graphql-tag';

export const getMessages = gql`
  query getMessages($id: ID!) {
    warrantyById(id: $id) {
      internalMessages {
        id
        type
        text
        createdAt
      }
      externalMessages {
        id
        type
        text
        createdAt
      }
    }
  }
`;

export const getShoppingListsCustom = gql`
  query ShoppingLists(
    $filter: BaseFilter
    $sorting: [BaseSorting!]
    $onBehalfOfRetailerId: Int
  ) {
    shoppingLists(
      filter: $filter
      sorting: $sorting
      onBehalfOfRetailerId: $onBehalfOfRetailerId
    ) {
      meta {
        total
        limit
        offset
      }
      edges {
        id
        name
        remark
        items {
          id
          amount
          vehicle {
            stock {
              warehouse
              atRetailer
              incoming
              status
              incomingDeliveries {
                deliveryDate
                quantity
              }
              updatedAt
            }
            id
            name
            brand {
              id
              externalId
              name
            }
            vehicleType
            modelSeries {
              id
              name
            }
            model {
              id
              name
              description
            }
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            images {
              id
              name
              path
              createdAt
            }
            thumbnail {
              id
              name
              path
              createdAt
            }
            equipments {
              stock {
                warehouse
                atRetailer
                incoming
                status
                incomingDeliveries {
                  deliveryDate
                  quantity
                }
                updatedAt
              }
              id
              name
              articleNumber
              vehicleType
              price
              vat
              discountedPrice
              createdAt
              vehicleCampaign
              remark
            }
            articleNumber
            factoryOptions {
              id
              description
            }
            vehicleCampaign
          }
          equipment {
            stock {
              warehouse
              atRetailer
              incoming
              status
              incomingDeliveries {
                deliveryDate
                quantity
              }
              updatedAt
            }
            id
            name
            articleNumber
            modelSeries {
              id
              name
            }
            model {
              id
              name
              description
            }
            vehicleType
            price
            vat
            discountedPrice
            brand {
              id
              externalId
              name
            }
            images {
              id
              name
              path
              createdAt
            }
            thumbnail {
              id
              name
              path
              createdAt
            }
            createdAt
            vehicleCampaign
            remark
          }
          sparePart {
            stock {
              warehouse
              atRetailer
              incoming
              status
              incomingDeliveries {
                deliveryDate
                quantity
              }
              updatedAt
            }
            id
            articleNumber
            name
            remark
            price
            discountedPrice
            vat
            modelSeries {
              id
              name
            }
            model {
              id
              name
              description
            }
            explodedViewRows {
              id
              referenceNumber
            }
            active
            vehicleCampaign
          }
          billOfMaterial {
            stock {
              warehouse
              atRetailer
              incoming
              status
              incomingDeliveries {
                deliveryDate
                quantity
              }
              updatedAt
            }
            id
            articleNumber
            active
            bomCode
            description
            fromDate
            toDate
            bomRows {
              id
              linkQuantity
              fromDate
              toDate
            }
            price
            discountedPrice
            vehicleCampaign
          }
          isCampaignEquipment
          stockVehicleProductCount
          relatedVehicle {
            stock {
              warehouse
              atRetailer
              incoming
              status
              incomingDeliveries {
                deliveryDate
                quantity
              }
              updatedAt
            }
            id
            name
            vehicleType
            color
            colorCode
            createdAt
            price
            vat
            discountedPrice
            articleNumber
            vehicleCampaign
          }
        }
        createdBy {
          id
          email
          name
          phoneNumber
          freeText
          role
          roleDescription
          retailer {
            id
            externalId
            name
            address
            postalCode
            city
            country
            telephoneNumber
            email
            hasConsignmentVehicles
            hasExhibitionVehicles
          }
          active
          createdAt
          updatedAt
          createdAt
          updatedAt
        }
        createdAt
      }
    }
  }
`;
