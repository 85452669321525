import { WebshopSectionType } from 'routes/Webshop/sections/WebshopSectionType';
import {
  StockVehicle,
  Equipment,
  SparePart,
  BillOfMaterial,
  StockStatus,
  Article,
  Fee,
  Stock
} from 'types';
import {
  isStockVehicle,
  isEquipment,
  isSparePart,
  isBillOfMaterial,
  isFee
} from 'utils/typeGuards';

interface ArticleHolder {
  stockVehicle?: StockVehicle | null;
  vehicle?: StockVehicle | null;
  equipment?: Equipment | null;
  sparePart?: SparePart | null;
  billOfMaterial?: BillOfMaterial | null;
  fee?: Fee | null;
}

interface getArticleNumberProps {
  billOfMaterial?: BillOfMaterial | null;
  equipment?: Equipment | null;
  stockVehicle?: StockVehicle | null;
  sparePart?: SparePart | null;
}

const getArticle = (parentObject?: ArticleHolder) => {
  if (parentObject?.stockVehicle) return parentObject.stockVehicle;

  if (parentObject?.vehicle) return parentObject.vehicle;

  if (parentObject?.equipment) return parentObject.equipment;

  if (parentObject?.sparePart) return parentObject.sparePart;

  if (parentObject?.billOfMaterial) return parentObject.billOfMaterial;

  if (parentObject?.fee) return parentObject.fee;

  return undefined;
};

const getName = (product?: Article) => {
  if (isStockVehicle(product))
    return product.name || product.model?.name || product.articleNumber || '';

  if (isEquipment(product)) return product.name || product.model?.name || '';

  if (isSparePart(product)) return product.name || '';

  if (isBillOfMaterial(product)) return product.description || '';

  if (isFee(product)) return product.name || '';

  return '';
};

const getSequence = (article: Article) => {
  if (isBillOfMaterial(article) || isFee(article)) {
    return article.articleNumber;
  }

  if (article.modelSeries?.name) return article.modelSeries?.name;
  if (article.model?.name) return article.model?.name;

  return article.articleNumber;
};

const outOfStock = ({ stock: { status } }: StockVehicle) => {
  if (status === StockStatus.Orderable) return true;

  return false;
};

const totalStock = (article: Article, stockObject?: Stock) => {
  if (isFee(article)) return 0;

  if (stockObject) {
    return (
      stockObject.atRetailer + stockObject.incoming + stockObject.warehouse
    );
  }

  return (
    article.stock.atRetailer + article.stock.incoming + article.stock.warehouse
  );
};

export const getArticleTypeName = (article: Article) => {
  if (isStockVehicle(article)) return 'Fordon';
  if (isEquipment(article)) return 'Tillbehör';
  if (isSparePart(article)) return 'Reservdel';
  if (isBillOfMaterial(article)) return 'Paket';
  if (isFee(article)) return 'Frakt';
};

export const getArticleNumberFromArticle = ({
  billOfMaterial,
  equipment,
  stockVehicle,
  sparePart
}: getArticleNumberProps) => {
  if (billOfMaterial) {
    return billOfMaterial.articleNumber;
  }

  if (equipment) {
    return equipment.articleNumber;
  }

  if (stockVehicle) {
    return stockVehicle.articleNumber;
  }

  if (sparePart) {
    return sparePart.articleNumber;
  }

  return '';
};

const getUrl = (article: Article) => {
  if (isStockVehicle(article)) {
    return `/webshop/${WebshopSectionType.VEHICLES}/${article.id}`;
  }

  if (isEquipment(article)) {
    return `/webshop/${WebshopSectionType.ACCESSORIES}/${article.id}`;
  }

  if (isSparePart(article)) {
    return `/webshop/${WebshopSectionType.SPARE_PARTS}?searchTerm=${article.articleNumber}`;
  }

  if (isBillOfMaterial(article)) {
    return `/webshop/${WebshopSectionType.BILL_OF_MATERIAL}/${article.id}`;
  }

  return 'webshop';
};

const getThumbnail = (article: Article) => {
  if (isSparePart(article) || isFee(article) || !article.thumbnail?.path) {
    return undefined;
  }

  return article.thumbnail.path;
};

const isInStock = (article: Article, quantity: number) => {
  if (isFee(article)) return true;

  const { warehouse, atRetailer } = article.stock;
  return (
    quantity > warehouse + atRetailer &&
    article.stock.status === StockStatus.SwedenWarehouse
  );
};

export const productResolvers = {
  ...{
    getArticle,
    getName,
    getSequence,
    outOfStock,
    totalStock,
    getArticleTypeName,
    getUrl,
    getThumbnail,
    isInStock
  }
};
