import React from 'react';

import styles from './WebshopFooter.module.scss';

const WebshopFooter = () => {
  return (
    <footer className={styles.root}>
      <div className={styles.container} />
    </footer>
  );
};

export default WebshopFooter;
